// extracted by mini-css-extract-plugin
export var alternate = "_2y-h";
export var black = "_2y-3";
export var blue = "_2y-d";
export var blueLight = "_2y-c";
export var brand = "_2y-7";
export var error = "_2y-9";
export var gray = "_2y-6";
export var grayBlue = "_2y-b";
export var iconWrapper = "_2y-4";
export var indigo = "_2y-e";
export var orange = "_2y-g";
export var pink = "_2y-f";
export var primary = "_2y-0";
export var purple = "_2y-8";
export var success = "_2y-5";
export var warning = "_2y-a";
export var white = "_2y-1";
export var whiteSuccess = "_2y-2";