import { useRequiredContext } from "@redotech/react-util/context";
import {
  ConversationMessage,
  ConversationPlatform,
  ExpandedConversation,
  getConversationStatus,
  textForDeletedFacebookComment,
} from "@redotech/redo-model/conversation";
import { getCustomerDisplayName } from "@redotech/redo-model/customer";
import { RedoBadgeSize } from "@redotech/redo-web/arbiter-components/badge/redo-badge";
import { CardListCard, CardListCardProps } from "@redotech/redo-web/card-list";
import { formatTimeAgo } from "@redotech/redo-web/date-utils";
import { Divider } from "@redotech/redo-web/divider";
import { Flex } from "@redotech/redo-web/flex";
import { SkeletonText } from "@redotech/redo-web/skeleton";
import { RedoSupportChannelBadge } from "@redotech/redo-web/support/redo-support-channel-badge";
import { RedoSupportStatusBadge } from "@redotech/redo-web/support/redo-support-status-badge";
import { Text } from "@redotech/redo-web/text";
import { formatDateTime } from "@redotech/redo-web/time";
import { Tooltip } from "@redotech/redo-web/tooltip/tooltip";
import * as classNames from "classnames";
import {
  Fragment,
  MouseEventHandler,
  ReactElement,
  memo,
  useContext,
  useEffect,
  useState,
} from "react";
import { TeamContext } from "../app/team";
import { UserContext } from "../app/user";
import { RedoMerchantClientContext } from "../client/context";
import { ActiveUsers } from "./active-users";
import * as conversationInfoCardCss from "./conversation-info-card.module.css";
import { markConversationAsRead } from "./conversations/mark-conversation-read-manager";
import { TeamConversationActivityContext } from "./support";
import { removeSimpleHTMLTags } from "./utils";

export const ConversationInfoCardRender = ({
  item,
  onClick,
  loading,
  empty,
  isActive,
  key,
}: CardListCardProps<ExpandedConversation>): ReactElement => {
  return (
    <Fragment key={key}>
      <ConversationInfoCard
        empty={empty}
        isActive={isActive}
        item={item}
        loading={loading}
        onClick={onClick}
      />
      <Divider />
    </Fragment>
  );
};

export const ConversationInfoCard: CardListCard<ExpandedConversation> = memo(
  function ConversationInfoCard({
    item,
    onClick,
    loading,
    empty,
    isActive,
  }: CardListCardProps<ExpandedConversation>) {
    const user = useRequiredContext(UserContext);
    const client = useRequiredContext(RedoMerchantClientContext);
    const team = useContext(TeamContext);
    const conversation = item;
    const lastMessage: ConversationMessage | null =
      conversation?.messages[conversation?.messages?.length - 1] || null;

    const [timeSinceMessage, setTimeSinceMessage] = useState<string>(
      formatTimeAgo(lastMessage?.sentAt),
    );
    let contentLines;
    if (lastMessage) {
      const lastMessageContent = lastMessage.facebook?.comment?.deleted
        ? textForDeletedFacebookComment
        : lastMessage?.content;
      contentLines = removeSimpleHTMLTags(lastMessageContent).split("\n");
    }
    const active = item && isActive ? isActive(item) : false;

    const teamConversationActivity = useContext(
      TeamConversationActivityContext,
    );
    const activeUsers = conversation
      ? teamConversationActivity?.[conversation._id]?.viewing.filter(
          (userId) => userId !== user._id,
        ) || []
      : [];

    useEffect(() => {
      if (conversation && !conversation.read && active && !!team) {
        void markConversationAsRead(client, conversation, team, user._id);
      }
    }, [lastMessage?._id]);

    useEffect(() => {
      if (!lastMessage) {
        return;
      }
      setTimeSinceMessage(formatTimeAgo(lastMessage.sentAt));
      const intervalId = setInterval(() => {
        setTimeSinceMessage(formatTimeAgo(lastMessage.sentAt));
      }, 60 * 1000);
      return () => clearInterval(intervalId);
    }, [lastMessage?._id]);

    return (
      <div
        className={classNames(conversationInfoCardCss.container, {
          [conversationInfoCardCss.selected]: active,
          [conversationInfoCardCss.loading]: loading,
        })}
        onClick={onClick as MouseEventHandler<HTMLDivElement> | undefined}
      >
        {conversation && item && (
          <div className={conversationInfoCardCss.info}>
            <div className={conversationInfoCardCss.text}>
              <div className={conversationInfoCardCss.personName}>
                <div className={conversationInfoCardCss.header}>
                  <Text
                    className={conversationInfoCardCss.customerTitle}
                    fontSize="xs"
                    fontWeight="semibold"
                    textColor="primary"
                  >
                    {getCustomerDisplayName(conversation.customer)}
                  </Text>
                  <div className={conversationInfoCardCss.unreadIndicator}>
                    {conversation.read ? null : (
                      <div className={conversationInfoCardCss.unread} />
                    )}
                  </div>
                </div>
                {lastMessage ? (
                  <Tooltip
                    arrow
                    title={formatDateTime(
                      Temporal.Instant.from(lastMessage!.sentAt),
                    )}
                  >
                    <Flex w="fit-content">
                      <Text as="span" fontSize="xxs" textColor="tertiary">
                        {timeSinceMessage}
                      </Text>
                    </Flex>
                  </Tooltip>
                ) : (
                  <Text fontSize="xxs" textColor="tertiary">
                    {timeSinceMessage}
                  </Text>
                )}
              </div>
              <div className={conversationInfoCardCss.content}>
                {conversation?.platform === ConversationPlatform.EMAIL &&
                  conversation.subject && (
                    <div className={conversationInfoCardCss.oneLine}>
                      <Text
                        fontSize="xs"
                        fontWeight="medium"
                        textColor="primary"
                      >
                        {conversation.subject}
                      </Text>
                    </div>
                  )}
                <div
                  className={classNames(
                    conversationInfoCardCss.twoLines,
                    conversationInfoCardCss.gray,
                  )}
                >
                  <Text fontSize="xs" textColor="tertiary">
                    {[...(contentLines || [])].join(" ")}
                  </Text>
                </div>
              </div>
            </div>
            <div className={conversationInfoCardCss.pillContainer}>
              {conversation && (
                <RedoSupportChannelBadge
                  platform={conversation.platform}
                  size={RedoBadgeSize.X_SMALL}
                />
              )}
              <RedoSupportStatusBadge
                size={RedoBadgeSize.X_SMALL}
                snoozedUntil={conversation.snoozedUntil}
                status={getConversationStatus(conversation)}
              />
            </div>

            {activeUsers.length > 0 && (
              <ActiveUsers conversation={conversation} maxAvatars={3} />
            )}
          </div>
        )}
        {empty && (
          <Text
            className={conversationInfoCardCss.empty}
            fontSize="xs"
            textColor="disabled"
          >
            No conversations
          </Text>
        )}
        {loading && (
          <div className={conversationInfoCardCss.loadingCard}>
            <SkeletonText length={20} />
            <SkeletonText length={8} />
            <SkeletonText length={30} />
          </div>
        )}
      </div>
    );
  },
);
