// extracted by mini-css-extract-plugin
export var alignCenter = "_6j-b";
export var buttonContent = "_6j-0";
export var cardList = "_6j-1";
export var centerContent = "_6j-2";
export var chevron = "_6j-3";
export var closePanelButton = "_6j-i";
export var conversationDetailContainer = "_6j-4";
export var detailWrapper = "_6j-5";
export var dropdownButton = "_6j-c";
export var dropdownTitle = "_6j-7";
export var hidden = "_6j-8";
export var icon = "_6j-9";
export var panel = "_6j-g";
export var portalButtonsWrapper = "_6j-a";
export var right = "_6j-h";
export var tableSummaryContainer = "_6j-6";
export var tableSummaryHeader = "_6j-e";
export var threeDotsButton = "_6j-d";
export var wide = "_6j-f";