import { JSXElementConstructor, memo } from "react";
import { Flex } from "../../flex";
import { UserImage, UserImageSize } from "../../user-image";
import * as avatarCss from "./avatar.module.css";

/** Arbiter-compliant Avatar. TODO: doesn't support all variants, sizes, etc. Add as you need. */
export const Avatar = memo(function Avatar({
  name,
  imageUrl,
  alt,
  imageSize = UserImageSize.SMALL,
  DefaultIcon,
}: {
  name?: string;
  imageUrl: string | null;
  imageSize?: UserImageSize;
  alt: string;
  DefaultIcon?: JSXElementConstructor<any>;
}) {
  return (
    <Flex
      align="center"
      className={avatarCss.avatarContainer}
      justify="center"
      pt="xs"
    >
      <UserImage
        alt={alt}
        DefaultIcon={DefaultIcon}
        imageUrl={imageUrl}
        name={name}
        size={imageSize}
      />
    </Flex>
  );
});
