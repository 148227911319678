/** Take a react node, and wrap it in something with a tooltip that says "Copy ..."" */

import { Tooltip } from "@redotech/redo-web/tooltip/tooltip";
import { ReactElement } from "react";
import * as copyableTooltipCss from "./copyable-tooltip.module.css";

/** undefined = no truncation */
export const TRUNCATE_COPYABLE_TEXT_PREVIEW_LENGTH: number | undefined =
  undefined;

/** Doesn't provide functionality of click events; just a standard message & tooltip. */
export const CopyableTooltip = ({
  children,
  textToCopy,
  placement = "top",
}: {
  children: ReactElement;
  textToCopy: string;
  placement?: Parameters<typeof Tooltip>[0]["placement"];
}) => {
  const truncatedText = TRUNCATE_COPYABLE_TEXT_PREVIEW_LENGTH
    ? textToCopy.slice(0, TRUNCATE_COPYABLE_TEXT_PREVIEW_LENGTH)
    : textToCopy;
  return (
    <Tooltip
      placement={placement}
      title={`${truncatedText}${
        textToCopy.length > truncatedText.length ? "..." : ""
      }`}
    >
      <span className={copyableTooltipCss.copyableItem}>{children}</span>
    </Tooltip>
  );
};
