export function useMaxAvailableHeight({
  areaRef,
  contentBelowRef,
}: {
  areaRef: React.RefObject<HTMLDivElement>;
  contentBelowRef: React.RefObject<HTMLDivElement> | undefined;
}) {
  const distFromTop = areaRef.current?.getBoundingClientRect().top || 0;
  const contentBelowHeight = contentBelowRef?.current?.offsetHeight || 0;
  const availableHeight = window.innerHeight - distFromTop - contentBelowHeight;
  return availableHeight;
}
