// extracted by mini-css-extract-plugin
export var addButton = "_8b-0";
export var border = "_8b-8";
export var check = "_8b-h";
export var danger = "_8b-6";
export var dropdownContainer = "_8b-1";
export var editModalBody = "_8b-j";
export var footerContainer = "_8b-i";
export var fullWidth = "_8b-a";
export var hide = "_8b-9";
export var icon = "_8b-3";
export var input = "_8b-7";
export var inputFlex = "_8b-b";
export var inputWrapper = "_8b-c";
export var manualFocus = "_8b-5";
export var optionButton = "_8b-4";
export var pillWrapper = "_8b-e";
export var pointer = "_8b-2";
export var showOverflow = "_8b-f";
export var tagsFlex = "_8b-d";
export var xButton = "_8b-g";