import { RedoClient } from "@redotech/redo-api-client";
import type { Stripe } from "stripe";
/**
 * POST /stripe
 */
export async function updateStripe(
  client: RedoClient,
  {
    paymentMethodID,
    primary,
    outboundLabels,
  }: {
    paymentMethodID: string;
    primary: boolean;
    outboundLabels?: { delete?: boolean };
  },
) {
  const response = await client.client.post(
    "stripe",
    {
      payment_method_id: paymentMethodID,
      primary,
      outbound_labels: outboundLabels,
    },
    { headers: client.authorization() },
  );
  return response.data;
}

/**
 * POST /stripe/setup-intent
 */
export async function createSetupIntent(client: RedoClient) {
  const response = await client.client.post(
    "stripe/setup-intent",
    {},
    { headers: client.authorization() },
  );
  return response.data as Stripe.SetupIntent;
}

/**
 * POST /stripe/retry
 * @param client
 */
export async function retryBilling(client: RedoClient, { id }: { id: string }) {
  const response = await client.client.post(
    "stripe/retry",
    { id },
    { headers: client.authorization() },
  );
  return response.data;
}

/**
 * POST /stripe/recovery-charge
 */
export async function chargeRecovery(
  client: RedoClient,
  { id, amount }: { id: string; amount: number },
) {
  const response = await client.client.post(
    "stripe/recovery-charge",
    { id, amount },
    { headers: client.authorization() },
  );
  return response.data;
}

/**
 * POST /stripe/refund-recovery-charge
 */
export async function refundRecoveryCharge(
  client: RedoClient,
  { id, amount }: { id: string; amount: number | null },
) {
  const response = await client.client.post(
    "stripe/refund-recovery-charge",
    { id, amount },
    { headers: client.authorization() },
  );
  return response.data;
}
