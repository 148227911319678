import { Currency } from "@redotech/money/currencies";
import { CURRENCY_FORMAT, DEFAULT_CURRENCY } from "@redotech/redo-model/money";
import { createContext } from "react";

export interface CurrencyEnv {
  currency: Currency;
  formatCurrency(amount: number | string): string;
  setCurrency(currency: Currency): void;
  formatCurrencyFromUSD(amount: number | string): string;
}

export const default_format_Currency = (amount: string | number) =>
  CURRENCY_FORMAT(DEFAULT_CURRENCY).format(
    typeof amount === "string" ? parseFloat(amount) : amount,
  );

export const CurrencyContext = createContext<CurrencyEnv>({
  currency: DEFAULT_CURRENCY,
  formatCurrency: (amount) =>
    CURRENCY_FORMAT(DEFAULT_CURRENCY).format(
      typeof amount === "string" ? parseFloat(amount) : amount,
    ),
  setCurrency() {},
  formatCurrencyFromUSD: (amount) =>
    CURRENCY_FORMAT(DEFAULT_CURRENCY).format(
      typeof amount === "string" ? parseFloat(amount) : amount,
    ),
});

export { CURRENCY_FORMAT, DEFAULT_CURRENCY };
