import { SchemaBooleanExpression } from ".";
import { Schema } from "../type-system/schema";

export const predicateOperators = ["some", "every"] as const;

export type ArrayPredicateOperator = (typeof predicateOperators)[number];

export interface ArrayPredicateExpression {
  type: "array_predicate";
  field: keyof Schema["fields"];
  operator: ArrayPredicateOperator;
  condition: SchemaBooleanExpression;
}
