import {
  AuthorizationField,
  credentialsFieldValueFormat,
} from "@redotech/http/semantics";
import { bearerCredentialsFormat } from "@redotech/oauth2/request";
import { MessageVisibility } from "@redotech/redo-model/conversation";
import { Attachment } from "@redotech/redo-model/create-conversation-body";
import { ConversationFileUploadError } from "@redotech/redo-model/support/conversations/conversation-file-upload-error";
import { ConversationFileUploadResponse } from "@redotech/redo-model/support/conversations/conversation-file-upload-response";
import { isEnumValue } from "@redotech/util/enum";
import { AxiosError } from "axios";
import { RedoClient } from ".";

/**
 * POST /conversations/chat
 */
export async function sendMessageCustomer(
  client: RedoClient,
  {
    conversationId,
    token,
    teamId,
    message,
    welcomeMessages,
    currentStepId,
    emailCollected,
    fourDigitCodeId,
    offeredConvertToEmail,
    signal,
  }: {
    conversationId?: string;
    token?: string;
    teamId?: string;
    message: string;
    welcomeMessages?: { content: string; htmlContent?: string }[];
    currentStepId: number | null;
    emailCollected: boolean;
    fourDigitCodeId?: string;
    offeredConvertToEmail?: boolean;
    signal?: AbortSignal;
  },
) {
  const options: {
    signal: AbortSignal | undefined;
    headers: Record<string, string>;
  } = { signal, headers: {} };
  if (token) {
    options.headers[String(AuthorizationField.name)] =
      credentialsFieldValueFormat.write(bearerCredentialsFormat.write(token));
  }
  const response = await client.client.post(
    "conversations/chat",
    {
      conversationId,
      teamId,
      message,
      welcomeMessages,
      currentStepId,
      emailCollected,
      fourDigitCodeIdCollected: fourDigitCodeId,
      offeredConvertToEmail,
    },
    options,
  );
  return response.data;
}

/**
 * POST /conversations/upload
 */
export async function uploadFile(
  client: RedoClient,
  params: any,
  { signal }: { signal?: AbortSignal } = {},
): Promise<
  | { success: true; body: ConversationFileUploadResponse }
  | { success: false; error: ConversationFileUploadError }
> {
  try {
    const response = await client.client.post(`conversations/upload`, params, {
      signal,
    });
    return {
      success: true,
      body: response.data as ConversationFileUploadResponse,
    };
  } catch (error: any) {
    if (
      error instanceof AxiosError &&
      isEnumValue(error.response?.data?.error, ConversationFileUploadError)
    ) {
      return { success: false, error: error.response?.data?.error };
    }
    throw error;
  }
}

export async function fetchAttachmentMetadata(
  client: RedoClient,
  attachment: Attachment,
): Promise<{ contentType: string; contentLength: number }> {
  const queryParameter = `?url=${encodeURIComponent(attachment.url)}`;

  const otherHeaders = await client.client.get(
    `/conversations/attachment/metadata${queryParameter}`,
  );

  return otherHeaders.data;
}

/**
 * PUT /conversations/:id/typing
 */
export async function sendTyping(
  client: RedoClient,
  {
    conversationId,
    id,
    name,
    visibility,
    token,
    signal,
  }: {
    conversationId: string;
    id?: string;
    name?: string;
    visibility: MessageVisibility;
    token?: string;
    signal?: AbortSignal;
  },
) {
  const response = await client.client.put(
    `conversations/${conversationId}/typing`,
    { id, name, visibility },
    {
      headers: token
        ? {
            [String(AuthorizationField.name)]:
              credentialsFieldValueFormat.write(
                bearerCredentialsFormat.write(token),
              ),
          }
        : client.authorization(),
      signal,
    },
  );
  return response.data;
}

/**
 * PUT /conversations/:id/cart
 */
export async function updateCustomerCart(
  client: RedoClient,
  {
    conversationId,
    cartToken,
    token,
    signal,
  }: {
    conversationId: string;
    cartToken: string;
    token: string;
    signal?: AbortSignal;
  },
) {
  const response = await client.client.put(
    `conversations/${conversationId}/cart`,
    { cartToken },
    {
      headers: {
        [String(AuthorizationField.name)]: credentialsFieldValueFormat.write(
          bearerCredentialsFormat.write(token),
        ),
      },
      signal,
    },
  );
  return response.data;
}

/**
 * POST /conversations/apply-customer
 */
export async function applyCustomerToConversations(
  client: RedoClient,
  {
    conversationIds,
    token,
    signal,
  }: { conversationIds?: string[]; token: string; signal?: AbortSignal },
) {
  const options: {
    signal: AbortSignal | undefined;
    headers: Record<string, string>;
  } = {
    signal,
    headers: {
      [String(AuthorizationField.name)]: credentialsFieldValueFormat.write(
        bearerCredentialsFormat.write(token),
      ),
    },
  };
  const response = await client.client.post(
    "conversations/apply-customer",
    { conversationIds },
    options,
  );
  return response.data;
}
