import { genericMemo } from "@redotech/react-util/component";
import { useSearch } from "@redotech/react-util/search";
import { TextMatchExpression } from "@redotech/redo-model/advanced-flow/expressions/text-expression";
import { Schema } from "@redotech/redo-model/advanced-flow/type-system/schema";
import { RedoDropdownInputSize } from "@redotech/redo-web/arbiter-components/select-dropdown/redo-single-select-dropdown-input";
import {
  RedoTagsDropdownInput,
  RedoTagsDropdownItem,
} from "@redotech/redo-web/arbiter-components/select-dropdown/redo-tags-dropdown-input";
import { DeepPartial } from "@redotech/util/type";
import Fuse from "fuse.js";
import { useEffect, useMemo, useState } from "react";

const searcher = new Fuse([], { keys: ["value"], threshold: 0.3 });

export const EnumInspector = genericMemo(function EnumInspector({
  schema,
  dataType,
  expression,
  setExpression,
}: {
  schema: Schema;
  dataType: "Enum";
  expression: DeepPartial<TextMatchExpression>;
  setExpression: (expression: DeepPartial<TextMatchExpression>) => void;
}) {
  useEffect(() => {
    if (!expression.type) {
      setExpression({ ...expression, type: "text_match", operator: "equals" });
    }
  }, [expression]);

  const allOptions: RedoTagsDropdownItem<string>[] = useMemo(() => {
    if (!expression.field) {
      return [];
    }
    return (schema.fields[expression.field].values ?? []).map((value) => ({
      id: value,
      value,
      badgeProps: { text: value },
    }));
  }, [schema.fields]);

  const selectedOptions: RedoTagsDropdownItem<string>[] = useMemo(() => {
    if (!expression.matchValues) {
      return [];
    }
    return allOptions.filter((option) =>
      expression.matchValues?.includes(option.value),
    );
  }, [expression.matchValues]);

  const [searchText, setSearchText] = useState<string>("");

  const searchResults = useSearch(searcher, allOptions, searchText);

  return (
    <RedoTagsDropdownInput<string>
      label="Value"
      options={searchResults}
      searchString={searchText}
      selectedOptions={selectedOptions}
      setSearchString={setSearchText}
      setSelectedOptions={(selectedOptions) => {
        setExpression({
          ...expression,
          matchValues: selectedOptions.map((option) => option.value),
        });
      }}
      size={RedoDropdownInputSize.SMALL}
    />
  );
});
