// extracted by mini-css-extract-plugin
export var colorSquare = "_br-0";
export var danger = "_br-5";
export var lightPink = "_br-8";
export var neutralBlue = "_br-7";
export var neutralYellow = "_br-6";
export var normal = "_br-1";
export var primary = "_br-2";
export var primaryLight = "_br-3";
export var solidBlack = "_br-g";
export var solidGreen = "_br-b";
export var solidLightBlue = "_br-d";
export var solidNavy = "_br-c";
export var solidOrange = "_br-f";
export var solidPink = "_br-e";
export var solidRed = "_br-9";
export var solidYellow = "_br-a";
export var success = "_br-4";