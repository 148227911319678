import {
  splitWeight,
  WeightUnit,
} from "@redotech/redo-model/outbound-labels/util";
import { Flex } from "@redotech/redo-web/flex";
import { Text } from "@redotech/redo-web/text";
import { memo, useMemo } from "react";

export const OrderWeightCell = memo(function OrderWeightCell({
  displayWeightGrams,
}: {
  displayWeightGrams: number;
}) {
  const text = useMemo(() => {
    const [lbs, oz] = splitWeight(
      { unit: WeightUnit.GRAM, value: displayWeightGrams },
      [WeightUnit.POUND, WeightUnit.OUNCE],
    );
    const parts = [];
    if (lbs === 1) {
      parts.push(`${lbs} lb`);
    } else if (lbs > 1) {
      parts.push(`${lbs} lbs`);
    }

    if (oz) {
      parts.push(`${oz} oz`);
    }
    return parts.join(", ");
  }, [displayWeightGrams]);

  return (
    <Flex dir="column" p="xl">
      <Text fontSize="sm">{text}</Text>
    </Flex>
  );
});
