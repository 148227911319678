import * as amplitude from "@amplitude/analytics-browser";
import { Step } from "@redotech/redo-web/concierge/onboarding";
import { TeamContextValue } from "@redotech/redo-web/context/team-context";
import {
  createContext,
  ReactNode,
  useCallback,
  useMemo,
  useState,
} from "react";
import { UserContextValue } from "../../app/user";

interface ConciergeOnboardingAnalytics {
  viewedStep: (step: Step) => void;
  completedOnboarding: () => void;
}

enum ConciergeOnboardingAnalyticsEventName {
  VIEW_CONTACT_STEP = "concierge/self-serve/view/ContactStep--v01.13.2025",
  VIEW_CHIPS_STEP = "concierge/self-serve/view/ChipsStep--v01.13.2025",
  VIEW_PERSONALITY_STEP = "concierge/self-serve/view/PersonalityStep--v01.13.2025",
  VIEW_KNOWLEDGE_STEP = "concierge/self-serve/view/KnowledgeStep--v01.13.2025",
  VIEW_CUSTOMER_WIDGET_STEP = "concierge/self-serve/view/CustomerWidgetStep--v01.13.2025",
  VIEW_STOREFRONT_SETUP_STEP = "concierge/self-serve/view/StorefrontSetupStep--v01.13.2025",
  COMPLETE_ONBOARDING = "concierge/self-serve/complete--v01.13.2025",
}

export const ConciergeOnboardingAnalyticsContext =
  createContext<ConciergeOnboardingAnalytics>({
    viewedStep: () => {},
    completedOnboarding: () => {},
  });

export function ConciergeOnboardingAnalyticsProvider({
  children,
  team,
  user,
}: {
  children?: ReactNode | ReactNode[];
  team: TeamContextValue;
  user: UserContextValue;
}) {
  const [stepsVisited, setStepsVisited] = useState<Set<Step>>(new Set());

  const viewedStep = useCallback(
    (step: Step) => {
      if (team && user && !stepsVisited.has(step)) {
        amplitude.logEvent(stepToAmplitudeEventName[step], {
          teamId: team._id,
          storeName: team.name,
          userName: user.name,
          userEmail: user.email,
        });
        setStepsVisited((prev) => {
          const newSet = new Set(prev);
          newSet.add(step);
          return newSet;
        });
      } else if (!team || !user) {
        console.error("Team or user not found");
      }
    },
    [stepsVisited, user, team],
  );

  const completedOnboarding = useCallback(() => {
    if (team && user) {
      amplitude.logEvent(
        ConciergeOnboardingAnalyticsEventName.COMPLETE_ONBOARDING,
        {
          teamId: team._id,
          storeName: team.name,
          userName: user.name,
          userEmail: user.email,
        },
      );
    } else {
      console.error("Team or user not found");
    }
  }, [team, user]);

  const value = useMemo(
    () => ({ viewedStep, completedOnboarding }),
    [viewedStep, completedOnboarding],
  );

  return (
    <ConciergeOnboardingAnalyticsContext.Provider value={value}>
      {children}
    </ConciergeOnboardingAnalyticsContext.Provider>
  );
}

const stepToAmplitudeEventName: Record<
  Step,
  ConciergeOnboardingAnalyticsEventName
> = {
  [Step.NAME]: ConciergeOnboardingAnalyticsEventName.VIEW_CONTACT_STEP,
  [Step.CHIPS]: ConciergeOnboardingAnalyticsEventName.VIEW_CHIPS_STEP,
  [Step.PERSONALITY]:
    ConciergeOnboardingAnalyticsEventName.VIEW_PERSONALITY_STEP,
  [Step.KNOWLEDGE]: ConciergeOnboardingAnalyticsEventName.VIEW_KNOWLEDGE_STEP,
  [Step.CUSTOMER_WIDGET]:
    ConciergeOnboardingAnalyticsEventName.VIEW_CUSTOMER_WIDGET_STEP,
  [Step.STOREFRONT_SETUP]:
    ConciergeOnboardingAnalyticsEventName.VIEW_STOREFRONT_SETUP_STEP,
};
