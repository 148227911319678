import { RedoClient } from "@redotech/redo-api-client";
import { createAuth, WidgetClient } from "@redotech/redo-api-client/widget";
import { buildPortalUrl } from "@redotech/redo-model/customer-portal";
import { Order } from "@redotech/redo-model/order";
import { Team } from "@redotech/redo-model/team";

export async function getCustomerPortalLink(
  client: RedoClient,
  team: Team,
  order: Order,
  fulfillmentIdx?: number,
): Promise<string> {
  const widgetClient = new WidgetClient(client, team.widget_slug);
  const email = order.shopify.email;
  const orderName = order.shopify.name;

  const auth = await createAuth(widgetClient, {
    orderNumber: orderName,
    email,
    type: "order",
  });

  const url = buildPortalUrl(team, order, auth, fulfillmentIdx);

  return String(url);
}
