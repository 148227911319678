import { ClickAwayListener } from "@mui/material";
import {
  genericForwardRefMemo,
  genericMemo,
} from "@redotech/react-util/component";
import { useHandler } from "@redotech/react-util/hook";
import ChevronDownIcon from "@redotech/redo-web/arbiter-icon/chevron-down_filled.svg";
import ChevronUpIcon from "@redotech/redo-web/arbiter-icon/chevron-up_filled.svg";
import XIcon from "@redotech/redo-web/arbiter-icon/x-close.svg";
import { EnterKey } from "@redotech/web-util/key";
import * as classNames from "classnames";
import * as merge from "lodash/merge";
import { ForwardedRef, useEffect, useMemo, useState } from "react";
import { Dropdown } from "../../dropdown";
import { Flex } from "../../flex";
import { Icon } from "../../icon";
import { Text } from "../../text";
import { TextSizeValue } from "../../theme/typography";
import {
  RedoBadge,
  RedoBadgeColor,
  RedoBadgeProps,
  RedoBadgeSize,
  RedoBadgeType,
} from "../badge/redo-badge";
import { BaseRedoInput } from "../input/base-redo-text-input";
import { RedoListItemSize, RedoListItemVariant } from "../list/redo-list-item";
import { RedoMultiselectList } from "../list/redo-multiselect-list";
import * as redoDropdownInputCss from "./redo-dropdown-input.module.css";
import { RedoDropdownInputSize } from "./redo-single-select-dropdown-input";

export interface RedoTagsDropdownItem<T> {
  id: string;
  value: T;
  badgeProps: Omit<RedoBadgeProps, "size"> & { text: string };
}

function getDefaultBadgeProps(size: RedoDropdownInputSize): RedoBadgeProps {
  return {
    size: RedoBadgeSize.X_SMALL,
    type: RedoBadgeType.PRIMARY,
    color: RedoBadgeColor.GRAY,
  };
}

/**
 * @param searchSubmitted - use this to create a tag if that is applicable to your use case
 * This could be reworked into a 'create tag strategy' that could either be
 * search submission or creates a 'create tag' list item.
 */
export interface RedoTagsDropdownInputProps<T> {
  size?: RedoDropdownInputSize;
  options: RedoTagsDropdownItem<T>[];
  selectedOptions: RedoTagsDropdownItem<T>[];
  setSelectedOptions(selectedOptions: RedoTagsDropdownItem<T>[]): void;
  searchSubmitted?: (searchString: string) => void;
  disabled?: boolean;
  className?: string;
  fitToAnchor?: boolean;
  label?: string;
  placeholder?: string;
  description?: string;
  searchString?: string;
  setSearchString?: (searchText: string) => void;
  searchPlaceholder?: string;
  loading?: boolean;
  flexGrow?: boolean;
}

export const RedoTagsDropdownInput = genericMemo(function RedoTagsDropdownInput<
  T,
>({
  options,
  selectedOptions = [],
  setSelectedOptions,
  searchSubmitted,
  size = RedoDropdownInputSize.REGULAR,
  disabled = false,
  className,
  fitToAnchor = true,
  label,
  placeholder,
  description,
  searchString,
  setSearchString,
  searchPlaceholder,
  loading,
  flexGrow = true,
}: RedoTagsDropdownInputProps<T>) {
  const populateTagDefaults = useHandler(
    (
      tag: RedoTagsDropdownItem<T>,
      size: RedoDropdownInputSize,
    ): RedoTagsDropdownItem<T> => ({
      ...tag,
      badgeProps: merge(getDefaultBadgeProps(size), tag.badgeProps),
    }),
  );

  const filledInOptions = useMemo(
    () => options.map((option) => populateTagDefaults(option, size)),
    [options, size, populateTagDefaults],
  );

  const filledInSelectedOptions = useMemo(
    () => selectedOptions.map((option) => populateTagDefaults(option, size)),
    [selectedOptions, size, populateTagDefaults],
  );

  const [dropdownButtonRef, setDropdownButtonRef] =
    useState<HTMLButtonElement | null>(null);

  const [searchBarRef, setSearchBarRef] = useState<HTMLDivElement | null>(null);

  const [dropdownOpen, setDropdownOpen] = useState(false);

  const toggleDropdownOpen = useHandler(() => {
    setDropdownOpen((prev) => !prev);
  });

  useEffect(() => {
    if (!searchBarRef || !dropdownOpen) {
      return;
    }
    searchBarRef.focus();
  }, [dropdownOpen, searchBarRef]);

  const descriptorFontSize = sizeToDescriptorTextProps[size];

  const [focusedIndex, setFocusedIndex] = useState<number | undefined>(
    undefined,
  );

  const onInputKeydown = useHandler((e: React.KeyboardEvent) => {
    if (e.key === EnterKey) {
      searchSubmitted?.(searchString ?? "");
    }
  });

  return (
    <>
      <Flex dir="column" gap="sm" grow={flexGrow ? 1 : undefined}>
        {label && (
          <Text
            fontSize={descriptorFontSize}
            fontWeight="medium"
            textColor="secondary"
          >
            {label}
          </Text>
        )}
        <RedoTagsDropdownInputButton
          className={className}
          disabled={disabled}
          isDropdownOpen={dropdownOpen}
          onClick={(e) => {
            e.stopPropagation();
            toggleDropdownOpen();
          }}
          placeholder={placeholder}
          ref={setDropdownButtonRef}
          removeItem={(item) => {
            selectedOptions &&
              setSelectedOptions(
                selectedOptions.filter((selected) => selected.id !== item.id),
              );
          }}
          selectedItems={filledInSelectedOptions}
          size={size}
        />
        {description && (
          <Text fontSize={descriptorFontSize} textColor="tertiary">
            {description}
          </Text>
        )}
      </Flex>

      <ClickAwayListener
        mouseEvent={dropdownOpen ? undefined : false}
        onClickAway={() => {
          toggleDropdownOpen();
        }}
      >
        <Dropdown
          anchor={dropdownButtonRef}
          constrainHeight
          fitToAnchor={fitToAnchor}
          flexProps={{ p: "none", gap: "none" }}
          open={dropdownOpen}
        >
          {setSearchString && (
            <Flex
              borderBottomWidth="1px"
              borderColor="primary"
              borderStyle="solid"
              px="lg"
              py="md"
            >
              <BaseRedoInput
                onKeyDown={onInputKeydown}
                placeholder={searchPlaceholder ?? placeholder}
                ref={setSearchBarRef}
                setValue={setSearchString}
                value={searchString ?? ""}
              />
            </Flex>
          )}
          <RedoMultiselectList
            focusedIndex={focusedIndex}
            keyFn={(item) => item.id || `${item.value}`}
            loading={loading}
            options={filledInOptions}
            refToListenTo={searchBarRef || dropdownButtonRef}
            selectedOptions={filledInSelectedOptions}
            selectionVariant={RedoListItemVariant.CHECKMARK}
            setFocusedIndex={setFocusedIndex}
            setSelectedOptions={setSelectedOptions}
            size={
              size === RedoDropdownInputSize.REGULAR
                ? RedoListItemSize.MEDIUM
                : RedoListItemSize.SMALL
            }
          >
            {(listItem: RedoTagsDropdownItem<T>) => (
              <RedoBadge {...listItem.badgeProps} />
            )}
          </RedoMultiselectList>
        </Dropdown>
      </ClickAwayListener>
    </>
  );
});

interface RedoTagsDropdownInputButtonProps<T> {
  size?: RedoDropdownInputSize;
  isDropdownOpen: boolean;
  selectedItems?: RedoTagsDropdownItem<T>[];
  removeItem?: (item: RedoTagsDropdownItem<T>) => void;
  disabled?: boolean;
  placeholder?: string;
  className?: string;
  onClick?: (e: React.MouseEvent) => void;
  ref?: any;
}

export const RedoTagsDropdownInputButton = genericForwardRefMemo(
  function RedoTagsDropdownInputButton<T>(
    {
      selectedItems,
      removeItem,
      isDropdownOpen,
      size = RedoDropdownInputSize.REGULAR,
      disabled = false,
      placeholder,
      className,
      onClick,
    }: RedoTagsDropdownInputButtonProps<T>,
    ref: ForwardedRef<HTMLButtonElement>,
  ) {
    const sizeClass =
      size === RedoDropdownInputSize.REGULAR
        ? redoDropdownInputCss.regular
        : redoDropdownInputCss.small;

    return (
      <button
        className={classNames(
          className,
          redoDropdownInputCss.tagsInput,
          sizeClass,
          isDropdownOpen ? redoDropdownInputCss.open : "",
          disabled ? redoDropdownInputCss.disabled : "",
        )}
        disabled={disabled}
        onClick={onClick}
        ref={ref}
        type="button"
      >
        <Flex className={redoDropdownInputCss.tagsContainer} wrap="wrap">
          {selectedItems && selectedItems.length ? (
            selectedItems.map((selectedItem) => (
              <div
                className={redoDropdownInputCss.tag}
                key={selectedItem.id}
                onClick={(e) => {
                  e.stopPropagation();
                  removeItem?.(selectedItem);
                }}
              >
                <RedoBadge
                  {...selectedItem.badgeProps}
                  iconTrailing={{ Icon: XIcon, type: "icon" }}
                />
              </div>
            ))
          ) : (
            <div className={redoDropdownInputCss.placeholder}>
              {placeholder}
            </div>
          )}
        </Flex>
        <Icon
          arbiterIconSvg={isDropdownOpen ? ChevronUpIcon : ChevronDownIcon}
          className={redoDropdownInputCss.chevron}
          color="ghost"
        />
      </button>
    );
  },
);

const sizeToDescriptorTextProps: Record<RedoDropdownInputSize, TextSizeValue> =
  {
    [RedoDropdownInputSize.SMALL]: "xs",
    [RedoDropdownInputSize.REGULAR]: "sm",
  };
