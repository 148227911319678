import { OMSUser } from "@redotech/redo-model/fulfillments/fulfillment-group";
import { RedoListItem } from "@redotech/redo-web/arbiter-components/list/redo-list";
import {
  RedoDropdownInputSize,
  RedoSingleSelectDropdownInput,
} from "@redotech/redo-web/arbiter-components/select-dropdown/redo-single-select-dropdown-input";
import { Text } from "@redotech/redo-web/text";
import { memo } from "react";

export const UserSelectDropdown = memo(function ParcelSelectDropdown({
  disabled,
  assignedUser,
  mixedUsers,
  userValues,
  onUserChange,
  label,
}: {
  disabled: boolean;
  assignedUser: OMSUser | null;
  mixedUsers: boolean;
  userValues: readonly OMSUser[];
  onUserChange: (user: OMSUser) => void;
  label?: string;
}) {
  const onOptionSelected = (user: RedoListItem<OMSUser>) => {
    onUserChange(user.value);
  };

  return (
    <RedoSingleSelectDropdownInput<OMSUser>
      disabled={disabled}
      label={label}
      options={userValues.map((user) => ({ value: user }))}
      optionSelected={onOptionSelected}
      placeholder={mixedUsers ? "{mixed}" : "Assign user"}
      selectedItem={assignedUser ? { value: assignedUser } : undefined}
      size={RedoDropdownInputSize.SMALL}
    >
      {(option) => {
        return <Text>{option.value.name}</Text>;
      }}
    </RedoSingleSelectDropdownInput>
  );
});
