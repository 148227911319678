/**
 * Weight.
 */
export class Weight {
  constructor(
    readonly value: number,
    readonly unit: WeightUnit,
  ) {}
}

/**
 * Weight unit.
 */
export enum WeightUnit {
  GRAM = "GRAM",
  OUNCE = "OUNCE",
}
