import { FulfillmentOrderData } from "@redotech/redo-model/fulfillments/fulfillment-group";
import { SortDirection } from "@redotech/redo-model/tables/table";
import { ColumnAlignment } from "@redotech/redo-web/table";
import { Column2 } from "@redotech/redo-web/table2";
import { OrderItemsCell } from "../cells/order-items-cell";

export const OrderItemsColumn: Column2<FulfillmentOrderData> = {
  key: "quantity",
  title: "Quantity",
  alignment: ColumnAlignment.LEFT,
  width: 80,
  renderNormalCell: ({ row }) => (
    <OrderItemsCell totalItemQuantity={row.totalItemQuantity} />
  ),
  sort: SortDirection.DESC,
};
