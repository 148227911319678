import { EndOfDayManifestSchema } from "@redotech/redo-model/fulfillments/scan-form";
import { z } from "zod";

export const createEodManifestForIds = {
  input: z.object({
    fulfillmentGroupIds: z.array(z.string()),
    carrier: z.string(),
  }),
  output: z.object({ manifest: EndOfDayManifestSchema }),
};
