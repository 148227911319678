import { useRequiredContext } from "@redotech/react-util/context";
import { useHandler } from "@redotech/react-util/hook";
import { toast } from "@redotech/redo-web/alert";
import {
  RedoButton,
  RedoButtonHierarchy,
  RedoButtonSize,
  RedoButtonTheme,
} from "@redotech/redo-web/arbiter-components/buttons/redo-button";
import { RedoFileUpload } from "@redotech/redo-web/arbiter-components/file-upload/redo-file-upload";
import { RedoBaseModal } from "@redotech/redo-web/arbiter-components/modal/redo-base-modal";
import { RedoModalHeader } from "@redotech/redo-web/arbiter-components/modal/redo-modal";
import ArrowRightIcon from "@redotech/redo-web/arbiter-icon/arrow-right_filled.svg";
import { Flex } from "@redotech/redo-web/flex";
import { Text } from "@redotech/redo-web/text";
import { assertNever } from "@redotech/util/type";
import * as classNames from "classnames";
import { memo, useMemo, useState } from "react";
import { RedoOutboundLabelsRpcClientContext } from "../../app/redo-outbound-labels-rpc-client-provider";
import { parseApparelMagicCsv } from "../utils/parse-apparel-magic-csv";
import * as importOrdersModalCss from "./import-orders-modal.module.css";

export enum FulfillmentImportSource {
  ETSY = "etsy",
  WOO_COMMERCE = "woo-commerce",
  BIG_COMMERCE = "big-commerce",
  WIX = "wix",
  SQUARESPACE = "squarespace",
  APPAREL_MAGIC = "apparel-magic",
}

const getFulfillmentInputSourceDisplayName = (
  fulfillmentSource: FulfillmentImportSource,
) => {
  switch (fulfillmentSource) {
    case FulfillmentImportSource.ETSY:
      return "Etsy";
    case FulfillmentImportSource.WOO_COMMERCE:
      return "WooCommerce";
    case FulfillmentImportSource.BIG_COMMERCE:
      return "BigCommerce";
    case FulfillmentImportSource.WIX:
      return "Wix";
    case FulfillmentImportSource.SQUARESPACE:
      return "Squarespace";
    case FulfillmentImportSource.APPAREL_MAGIC:
      return "ApparelMagic";
    default:
      assertNever(fulfillmentSource);
  }
};

export const ImportOrdersModal = memo(function ImportOrdersModal({
  open,
  setOpen,
  triggerReload,
}: {
  open: boolean;
  setOpen: (val: boolean) => void;
  triggerReload: () => Promise<void>;
}) {
  const [importSource, setImportSource] = useState<
    FulfillmentImportSource | undefined
  >(undefined);

  const [importLoading, setImportLoading] = useState(false);

  const cancelImport = useHandler(() => {
    setOpen(false);
  });

  const client = useRequiredContext(RedoOutboundLabelsRpcClientContext);

  const importOrders = useHandler(async () => {
    if (!file) {
      return;
    }

    setImportLoading(true);

    const fulfillmentOrders = await parseApparelMagicCsv(file);

    try {
      await client.createFulfillmentOrders({ fulfillmentOrders });
      toast("Imported orders. Refreshing...", { variant: "success" });
    } catch (error) {
      console.error(error);
      toast("Failed to import orders", { variant: "error" });
    } finally {
      await triggerReload();
      setImportLoading(false);
      setOpen(false);
    }
  });

  const [file, setFile] = useState<File | null>(null);

  const title: string = useMemo(() => {
    if (importSource) {
      return `Import orders from ${getFulfillmentInputSourceDisplayName(
        importSource,
      )}`;
    }
    return "Import orders";
  }, [importSource]);

  const handleFilesAdded = useHandler((newFiles: FileList | null) => {
    if (newFiles && newFiles.length > 0) {
      setFile(newFiles[0]);
    }
  });

  const handleFileDeleted = useHandler(() => {
    setFile(null);
  });

  return (
    <RedoBaseModal
      isOpen={open}
      onModalCloseRequested={() => {
        setOpen(false);
      }}
    >
      <RedoModalHeader cancelClicked={cancelImport} title={title} />
      {importSource ? (
        <Flex
          align="center"
          dir="column"
          grow={1}
          justify="center"
          pb="xl"
          px="3xl"
          w="full"
        >
          <RedoFileUpload
            accept=".csv"
            className={importOrdersModalCss.fileUpload}
            files={file ? [file] : []}
            handleFileDeleted={handleFileDeleted}
            handleFilesAdded={handleFilesAdded}
            nFilesToAccept={1}
          />
          <Flex dir="row" gap="md" w="full">
            <RedoButton
              className={classNames(importOrdersModalCss.modalButton)}
              hierarchy={RedoButtonHierarchy.SECONDARY}
              onClick={cancelImport}
              size={RedoButtonSize.REGULAR}
              text="Cancel"
              theme={RedoButtonTheme.NORMAL}
            />
            <RedoButton
              className={classNames(importOrdersModalCss.modalButton)}
              disabled={!file}
              hierarchy={RedoButtonHierarchy.PRIMARY}
              onClick={importOrders}
              pending={importLoading}
              size={RedoButtonSize.REGULAR}
              text="Import"
              theme={RedoButtonTheme.NORMAL}
            />
          </Flex>
        </Flex>
      ) : (
        <ImportSourceButtons setImportSource={setImportSource} />
      )}
    </RedoBaseModal>
  );
});

const ImportSourceButton = memo(function InputSourceButton({
  inputSource,
  onClick,
}: {
  inputSource: FulfillmentImportSource;
  onClick: () => void;
}) {
  return (
    <Flex flex={1} w="full">
      <RedoButton
        className={classNames(importOrdersModalCss.modalButton)}
        hierarchy={RedoButtonHierarchy.SECONDARY}
        IconTrailing={ArrowRightIcon}
        onClick={onClick}
        size={RedoButtonSize.REGULAR}
        text={getFulfillmentInputSourceDisplayName(inputSource)}
        theme={RedoButtonTheme.NORMAL}
      />
    </Flex>
  );
});

const ImportSourceButtons = memo(function InputSourceButtons({
  setImportSource,
}: {
  setImportSource: (importSource: FulfillmentImportSource) => void;
}) {
  const onChooseApparelMagic = useHandler(() => {
    setImportSource(FulfillmentImportSource.APPAREL_MAGIC);
  });
  return (
    <Flex dir="column" gap="md" grow={1} pb="xl" pt="none" px="3xl">
      <Text fontSize="sm">Where are you importing orders from?</Text>
      <Flex dir="column" gap="2xl">
        <Flex gap="2xl">
          <ImportSourceButton
            inputSource={FulfillmentImportSource.APPAREL_MAGIC}
            onClick={onChooseApparelMagic}
          />
        </Flex>
      </Flex>
    </Flex>
  );
});
