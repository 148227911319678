import type { InferRpcDefinition } from "@redotech/rpc/definition";
import { addConversationTrainingLabel } from "./schema/add-conversation-training-label";
import { completeSelfServeOnboarding } from "./schema/complete-self-serve-onboarding";
import { completeSelfServeOnboardingV2 } from "./schema/complete-self-serve-onboarding-v2";
import { createConciergeKnowledge } from "./schema/create-concierge-knowledge";
import { deleteConciergeKnowledge } from "./schema/delete-concierge-knowledge";
import { generateConciergeBehavior } from "./schema/generate-concierge-behavior";
import { getConciergeConversationById } from "./schema/get-concierge-conversation-by-id";
import { getConciergeConversations } from "./schema/get-concierge-conversations";
import { getConciergeDashboardMetrics } from "./schema/get-concierge-dashboard-metrics";
import { getConciergeDashboardProductTableData } from "./schema/get-concierge-dashboard-product-table-data";
import { getConciergeGeneralSettings } from "./schema/get-concierge-general-settings";
import { getConciergeKnowledge } from "./schema/get-concierge-knowledge";
import { getConciergeResponse } from "./schema/get-concierge-response";
import { getConciergeSkillsSettings } from "./schema/get-concierge-skills-settings";
import { getConciergeContextSettings } from "./schema/get-context-settings";
import { getConversationTrainingLabelsForTeam } from "./schema/get-conversation-training-labels-for-team";
import { getDemoConciergeKnowledge } from "./schema/get-demo-concierge-knowledge";
import { getProductSettings } from "./schema/get-product-settings";
import { getSupportDashboardMetrics } from "./schema/get-support-dashboard-metrics";
import { getTeamConciergeKnowledge } from "./schema/get-team-concierge-knowledge";
import { scrapeConciergeKnowledge } from "./schema/scrape-concierge-knowledge";
import { setConciergeContextSettings } from "./schema/set-context-settings";
import { updateConciergeGeneralSettings } from "./schema/update-concierge-general-settings";
import { updateConciergeKnowledge } from "./schema/update-concierge-knowledge";
import { updateConciergeSkillsSettings } from "./schema/update-concierge-skills-settings";
import { updateProductSettings } from "./schema/update-product-settings";
import { upsertDemoConciergeKnowledge } from "./schema/upsert-demo-concierge-knowledge";

export const rpcDefinition = {
  scrapeConciergeKnowledge,
  updateConciergeGeneralSettings,
  getConciergeGeneralSettings,
  getConciergeKnowledge,
  getConciergeSkillsSettings,
  updateConciergeSkillsSettings,
  getConciergeConversations,
  getConciergeContextSettings,
  setConciergeContextSettings,
  getConciergeDashboardMetrics,
  getConciergeDashboardProductTableData,
  getProductSettings,
  updateProductSettings,
  getConciergeConversationById,
  getSupportDashboardMetrics,
  generateConciergeBehavior,
  addConversationTrainingLabel,
  getConversationTrainingLabelsForTeam,
  createConciergeKnowledge,
  updateConciergeKnowledge,
  deleteConciergeKnowledge,
  getTeamConciergeKnowledge,
  getConciergeResponse,
  getDemoConciergeKnowledge,
  upsertDemoConciergeKnowledge,
  completeSelfServeOnboarding,
  completeSelfServeOnboardingV2,
};

export type RedoConciergeRpcDefinition = InferRpcDefinition<
  typeof rpcDefinition
>;
