import { FulfillmentOrderData } from "@redotech/redo-model/fulfillments/fulfillment-group";
import { ColumnAlignment } from "@redotech/redo-web/table";
import { Column2 } from "@redotech/redo-web/table2";
import { ShippingSelectionCell } from "../cells/shipping-selection-cell";

export const ShippingSelectionColumn: Column2<FulfillmentOrderData> = {
  key: "shippingSelection",
  title: "Shipping selection",
  alignment: ColumnAlignment.LEFT,
  renderNormalCell: ({ row }) => {
    const selectedRate =
      row.availableShipmentRates?.rates.find(
        (rate) => rate.id === row.selectedShippingRateId,
      ) ?? null;
    return <ShippingSelectionCell selectedRate={selectedRate} />;
  },
  onClick: (e: React.MouseEvent<HTMLDivElement>) => {
    e.stopPropagation();
  },
  width: 320,
};
