import { zExt } from "@redotech/rpc/ext";
import { z } from "zod";
import { SchemaType } from "./advanced-flow/schemas/schemas";
import { categories } from "./advanced-flow/triggers";

export interface EmailTemplateRow extends EmailTemplate {
  _id: any;
}

export const emailTemplateSchema = z.object({
  name: z.string(),
  subject: z.string(),
  templateType: z.enum(["transactional", "marketing", "default"]),
  category: z.enum(categories),
  schemaType: z.nativeEnum(SchemaType),
  emailPreview: z.string().optional().nullable(),
  emailBackgroundColor: z.string(),
  contentBackgroundColor: z.string(),
  address: z.object({
    businessAddress: z.string().optional().nullable(),
    legalAddress: z.string(),
    cityStateZip: z.string(),
    country: z.string(),
  }),
  sections: z.array(
    z.object({ blockId: zExt.objectId().optional() }).catchall(z.any()),
  ),
  linkColor: z.string().optional().nullable(),
  // optional because we do have some emailTemplates as subdocuments
  // on campaigns that don't have the team field
  team: zExt.objectId().optional(),
});

export type EmailTemplate = z.infer<typeof emailTemplateSchema>;
