import { ClickAwayListener } from "@mui/material";
import { genericMemo } from "@redotech/react-util/component";
import { useHandler } from "@redotech/react-util/hook";
import * as React from "react";
import { useEffect, useMemo, useState } from "react";
import DraggableList from "react-draggable-list";
import {
  RedoCheckbox,
  RedoCheckboxSize,
} from "./arbiter-components/checkbox/redo-checkbox";
import DotsReorderIcon from "./arbiter-icon/dots-reorder.svg";
import * as styles from "./column-selector.module.css";
import { Dropdown } from "./dropdown";
import { Flex } from "./flex";
import { Column2 } from "./table2";
import { Text } from "./text";

interface ColumnItemProps<T> {
  item: Column2<T>;
  commonProps: { onSelect: (selected: boolean, key: string) => void };
  dragHandleProps: any;
}

export const ColumnSelector = genericMemo(function ColumnSelection<T>({
  columns,
  dropdownButtonRef,
  displayedColumns,
  setDisplayedColumns,
}: {
  columns: Column2<T>[];
  dropdownButtonRef: HTMLButtonElement | null;
  displayedColumns: { key: string; displayed: boolean }[];
  setDisplayedColumns: (value: { key: string; displayed: boolean }[]) => void;
}) {
  const [dropdownOpen, setDropdownOpen] = useState(false);

  class ColumnItem extends React.Component<ColumnItemProps<T>> {
    handleSelect = (selected: boolean) => {
      const { item, commonProps } = this.props;
      commonProps.onSelect(selected, item.key);
    };

    override render() {
      const { item } = this.props;

      return (
        <Flex align="center" className={styles.columnItem} dir="row">
          <Flex dir="row" gap="xl" grow={1}>
            <RedoCheckbox
              setValue={this.handleSelect}
              size={RedoCheckboxSize.EXTRA_SMALL}
              value={
                displayedColumns.find((col) => col.key === item.key)
                  ?.displayed ?? false
              }
            />
            <Text fontSize="xs">{item.title}</Text>
          </Flex>
          <div className={styles.dragHandle} {...this.props.dragHandleProps}>
            <DotsReorderIcon className={styles.icon} />
          </div>
        </Flex>
      );
    }
  }

  const correctlySortedCols = useMemo(() => {
    const displayOrderMap = new Map(
      displayedColumns.map((col, index) => [col.key, index]),
    );
    return columns
      .sort((a, b) => {
        const orderA = displayOrderMap.get(a.key) ?? Infinity;
        const orderB = displayOrderMap.get(b.key) ?? Infinity;
        return orderA - orderB;
      })
      .filter((col) => !col.nonHidable);
  }, [columns, displayedColumns]);

  const toggleDropdownOpen = useHandler(() => {
    setDropdownOpen((prev) => {
      const newOpen = !prev;
      return newOpen;
    });
  });

  useEffect(() => {
    if (!dropdownButtonRef) {
      return;
    }
    dropdownButtonRef.addEventListener("click", toggleDropdownOpen);
    return () => {
      dropdownButtonRef.removeEventListener("click", toggleDropdownOpen);
    };
  }, [dropdownButtonRef, toggleDropdownOpen]);

  return (
    <>
      {dropdownOpen && (
        <ClickAwayListener onClickAway={toggleDropdownOpen}>
          <Dropdown
            anchor={dropdownButtonRef}
            className={styles.dropdown}
            fitToAnchor={false}
            flexProps={{ p: "md", gap: "none" }} // will collapse like a dying star without this min-width
            open
          >
            <Flex>
              <DraggableList<
                Column2<T>,
                { onSelect: (selected: boolean, key: string) => void },
                ColumnItem
              >
                commonProps={{
                  onSelect: (selected: boolean, key: string) => {
                    const columnIndex = displayedColumns.findIndex(
                      (col) => col.key === key,
                    );
                    if (columnIndex !== -1) {
                      const updatedColumns = [...displayedColumns];

                      if (selected) {
                        updatedColumns[columnIndex].displayed = true;
                      } else {
                        updatedColumns[columnIndex].displayed = false;
                      }

                      setDisplayedColumns(updatedColumns);
                    }
                  },
                }}
                constrainDrag
                container={() => document.getElementById("dropdown")}
                itemKey={(item: Column2<T>) => item.key}
                list={correctlySortedCols}
                onMoveEnd={(newList) => {
                  const newDisplayedColumns: {
                    key: string;
                    displayed: boolean;
                  }[] = [];
                  for (const item of newList) {
                    newDisplayedColumns.push({
                      key: item.key,
                      displayed:
                        displayedColumns.find((col) => col.key === item.key)
                          ?.displayed ?? false,
                    });
                  }

                  for (const col of columns.reverse()) {
                    if (col.nonHidable) {
                      newDisplayedColumns.unshift({
                        key: col.key,
                        displayed: true,
                      });
                    }
                  }

                  setDisplayedColumns([...newDisplayedColumns]);
                }}
                padding={8}
                template={ColumnItem}
              />
            </Flex>
          </Dropdown>
        </ClickAwayListener>
      )}
    </>
  );
});
