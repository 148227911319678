// extracted by mini-css-extract-plugin
export var alignCenter = "_54-b";
export var buttonContent = "_54-0";
export var cardList = "_54-1";
export var centerContent = "_54-2";
export var chevron = "_54-3";
export var closePanelButton = "_54-i";
export var conversationDetailContainer = "_54-4";
export var detailWrapper = "_54-5";
export var dropdownButton = "_54-c";
export var dropdownTitle = "_54-7";
export var hidden = "_54-8";
export var icon = "_54-9";
export var panel = "_54-g";
export var portalButtonsWrapper = "_54-a";
export var right = "_54-h";
export var tableSummaryContainer = "_54-6";
export var tableSummaryHeader = "_54-e";
export var threeDotsButton = "_54-d";
export var wide = "_54-f";