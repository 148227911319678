export function instantToDate(instant: Temporal.Instant): Date {
  return new Date(instant.epochMilliseconds);
}

/**
 * Add duration relative to UTC
 */
export function instantAddUtc(
  instant: Temporal.Instant,
  duration: Temporal.Duration,
) {
  return instant.toZonedDateTimeISO("UTC").add(duration).toInstant();
}

/**
 * Subtract duration relative to UTC
 */
export function instantSubtractUtc(
  instant: Temporal.Instant,
  duration: Temporal.Duration | Temporal.DurationLike,
) {
  return instant.toZonedDateTimeISO("UTC").subtract(duration).toInstant();
}

export function dateToCurrentPlainDate(
  date: Date,
  timeZoneId = Temporal.Now.timeZoneId(),
): Temporal.PlainDate {
  return date.toTemporalInstant().toZonedDateTimeISO(timeZoneId).toPlainDate();
}

export function plainDateToCurrentDate(
  plainDate: Temporal.PlainDate,
  timeZoneId = Temporal.Now.timeZoneId(),
): Date {
  return zonedDateTimeToDate(plainDate.toZonedDateTime(timeZoneId));
}

export function zonedDateTimeToDate(
  zonedDateTime: Temporal.ZonedDateTime,
): Date {
  return new Date(zonedDateTime.epochMilliseconds);
}

export function dateToCurrentPlainDateTime(
  date: Date,
  timeZoneId = Temporal.Now.timeZoneId(),
): Temporal.PlainDateTime {
  return date
    .toTemporalInstant()
    .toZonedDateTimeISO(timeZoneId)
    .toPlainDateTime();
}

export function dateToInstant(date: Date): Temporal.Instant {
  return Temporal.Instant.fromEpochMilliseconds(date.getTime());
}

export function plainDateTimeToCurrentDate(
  plainDateTime: Temporal.PlainDateTime,
  timeZoneId = Temporal.Now.timeZoneId(),
): Date {
  return zonedDateTimeToDate(plainDateTime.toZonedDateTime(timeZoneId));
}
