// extracted by mini-css-extract-plugin
export var brand = "_2p-9";
export var button = "_2p-0";
export var chevron = "_2p-v";
export var chevronButton = "_2p-w";
export var content = "_2p-n";
export var danger = "_2p-g";
export var dangerOutlined = "_2p-h";
export var dark = "_2p-l";
export var darkTheme = "_2p-c";
export var extraSmall = "_2p-4";
export var fullWidth = "_2p-8";
export var ghost = "_2p-d";
export var gray = "_2p-t";
export var icon = "_2p-p";
export var iconButton = "_2p-s";
export var iconButtonBorder = "_2p-x";
export var iconButtonBorderDark = "_2p-y";
export var iconButtonBorderLight = "_2p-z";
export var iconLeft = "_2p-q";
export var iconRight = "_2p-r";
export var large = "_2p-1";
export var light = "_2p-m";
export var medium = "_2p-2";
export var micro = "_2p-5";
export var nano = "_2p-7";
export var outlined = "_2p-k";
export var pending = "_2p-a";
export var primary = "_2p-i";
export var shadow = "_2p-o";
export var small = "_2p-3";
export var solidLight = "_2p-e";
export var solidLightBrand = "_2p-f";
export var spinner = "_2p-u";
export var transparent = "_2p-j";
export var warning = "_2p-b";
export var wideNano = "_2p-6";