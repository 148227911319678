// This is a copy of the DeliveryMethodType enum from @redotech/shopify-client/admin.graphql, but that can also be used in redo/model and therefore the frontend
export enum RedoShopifyDeliveryMethodType {
  /** The order is delivered using a local delivery service. */
  Local = "LOCAL",
  /** Non-physical items, no delivery needed. */
  None = "NONE",
  /** The order is delivered to a pickup point. */
  PickupPoint = "PICKUP_POINT",
  /** The order is picked up by the customer. */
  PickUp = "PICK_UP",
  /** In-store sale, no delivery needed. */
  Retail = "RETAIL",
  /** The order is shipped. */
  Shipping = "SHIPPING",
}
