// extracted by mini-css-extract-plugin
export var dark = "_58-8";
export var iconHighlight = "_58-0";
export var lg = "_58-6";
export var light = "_58-1";
export var md = "_58-5";
export var modern = "_58-9";
export var sm = "_58-4";
export var xl = "_58-7";
export var xs = "_58-3";
export var xxs = "_58-2";