import { SimplifiedCustomer } from "@redotech/redo-model/customer";
import {
  LocationData,
  LocationMutationRequest,
} from "@redotech/redo-model/location";
import { AuthResponse, Install } from "@redotech/redo-model/shopify";
import { RedoMerchantClient } from ".";

export async function authShopify(
  client: RedoMerchantClient,
  {
    params,
    signal,
  }: { params: { [key: string]: string }; signal?: AbortSignal },
): Promise<AuthResponse> {
  const response = await client.client.post("shopify/callback", params, {
    signal,
  });
  return response.data;
}

/**
 * GET /shopify/install
 */
export async function getShopifyInstall(
  client: RedoMerchantClient,
  {
    shop,
    requestOrigination,
    next,
    signal,
  }: {
    shop: string;
    requestOrigination: "web" | "mobile";
    next?: string | null;
    signal?: AbortSignal;
  },
): Promise<Install> {
  const params = new URLSearchParams();
  params.set("shop", shop);
  params.set("next", next || "/");
  params.set("requestOrigination", requestOrigination);
  const response = await client.client.get("shopify/install", {
    params,
    signal,
  });
  return response.data;
}

/**
 * GET /shopify/locations
 */
export async function getLocations(
  client: RedoMerchantClient,
  { storeUrl, signal }: { storeUrl: string; signal?: AbortSignal },
): Promise<{ locations: LocationData[] }> {
  const params = new URLSearchParams();
  params.set("storeUrl", storeUrl);
  const response = await client.client.get("shopify/locations", {
    params,
    signal,
    headers: client.authorization(),
  });
  return { locations: response.data.locations as LocationData[] };
}

/**
 * PUT /shopify/locations
 */
export async function putLocations(
  client: RedoMerchantClient,
  {
    storeUrl,
    locations,
    signal,
  }: {
    storeUrl: string;
    locations: LocationMutationRequest[];
    signal?: AbortSignal;
  },
): Promise<void> {
  await client.client.put(`shopify/locations?storeUrl=${storeUrl}`, {
    signal,
    headers: client.authorization(),
    body: JSON.stringify(locations),
  });
}

/**
 * GET /shopify/collections
 */
export async function getShopifyCollections(
  client: RedoMerchantClient,
  {
    searchString,
    signal,
  }: { searchString: string | undefined; signal?: AbortSignal },
): Promise<{ id: string; name: string }[]> {
  const params = new URLSearchParams();
  if (searchString) {
    params.set("search", searchString);
  }
  const response = await client.client.get(`shopify/collections`, {
    params,
    signal,
    headers: client.authorization(),
  });
  return response.data;
}

/**
 * GET /shopify/customers
 */
export async function getCustomers(
  client: RedoMerchantClient,
  { signal }: { signal?: AbortSignal },
) {
  const response = await client.client.get(`shopify/customers`, {
    signal,
    headers: client.authorization(),
  });
  return response.data;
}

/**
 * Get /shopify/customer-search
 */
export async function getCustomerSearch(
  client: RedoMerchantClient,
  { search, signal }: { search: string; signal?: AbortSignal },
): Promise<{ customers: SimplifiedCustomer[] }> {
  const params = new URLSearchParams();
  params.set("search", search);
  const response = await client.client.get(`shopify/customer-search`, {
    params,
    signal,
    headers: client.authorization(),
  });
  return response.data;
}

/**
 * Get customer tags via shopify graphql api
 */
export async function getCustomerTags(
  client: RedoMerchantClient,
  { signal }: { signal?: AbortSignal },
): Promise<{ name: string }[]> {
  const response = await client.client.get("shopify/customers/tags", {
    signal,
    headers: client.authorization(),
  });
  return response.data.tags;
}

/**
 * PUT /shopify/update-order
 */
export async function updateOrder(
  client: RedoMerchantClient,
  {
    shopifyOrderId,
    data,
    lineItemEdits,
    signal,
  }: {
    shopifyOrderId: number;
    data: any;
    lineItemEdits?: {
      lineItemTitle: string;
      lineItemVariantTitle: string;
      quantity: number;
    }[];
    signal?: AbortSignal;
  },
) {
  const response = await client.client.put(
    `shopify/update-order`,
    {
      shopifyOrderId,
      data: { order: { ...data, id: shopifyOrderId } },
      lineItemEdits,
    },
    { signal, headers: client.authorization() },
  );
  return response.data;
}

/**
 * GET /shopify/refund-value
 */
export async function getCalculatedRefundValue(
  client: RedoMerchantClient,
  {
    shopifyOrderId,
    currency,
    signal,
  }: { shopifyOrderId: number; currency?: string; signal?: AbortSignal },
) {
  const response = await client.client.get(`shopify/refund-value`, {
    params: { shopifyOrderId, currency },
    signal,
    headers: client.authorization(),
  });
  return response.data;
}

/**
 * POST /shopify/cancel-order
 */
export async function cancelOrder(
  client: RedoMerchantClient,
  {
    shopifyOrderId,
    refund,
    currency,
    restockLocationId,
    data,
    signal,
  }: {
    shopifyOrderId: number;
    refund: boolean;
    currency: string;
    restockLocationId?: number;
    data: any;
    signal?: AbortSignal;
  },
) {
  const response = await client.client.post(
    `shopify/cancel-order`,
    { shopifyOrderId, refund, currency, restockLocationId, order: { ...data } },
    { signal, headers: client.authorization() },
  );
  return response.data;
}

/**
 * GET /shopify/discount-codes
 */
export async function searchDiscountCodes(
  client: RedoMerchantClient,
  { search, signal }: { search: string; signal?: AbortSignal },
): Promise<{ discountCodes: string[] }> {
  const params = new URLSearchParams();
  params.set("search", search);
  const response = await client.client.get(`shopify/discount-codes`, {
    params,
    signal,
    headers: client.authorization(),
  });
  return response.data;
}

/**
 * GET /shopify/product-metafields
 */
export async function getProductMetafields(
  client: RedoMerchantClient,
  { signal }: { signal?: AbortSignal },
) {
  const response = await client.client.get(`shopify/product-metafields`, {
    signal,
    headers: client.authorization(),
  });
  return response.data;
}

/**
 * GET /shopify/products/search
 */
export async function searchProducts(
  client: RedoMerchantClient,
  { search, signal }: { search: string; signal?: AbortSignal },
) {
  const params = new URLSearchParams();
  params.set("search", search);
  const response = await client.client.get(`shopify/products-search`, {
    params,
    signal,
    headers: client.authorization(),
  });
  return response.data;
}

/**
 * PUT /shopify/refund-order
 */
export async function refundOrder(
  client: RedoMerchantClient,
  {
    orderId,
    notify,
    amount,
    signal,
  }: { orderId: string; notify: boolean; amount: number; signal?: AbortSignal },
) {
  const response = await client.client.put(
    `shopify/refund-order/`,
    { orderId, notify, amount },
    { signal, headers: client.authorization() },
  );
  return response.data;
}
