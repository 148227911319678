// extracted by mini-css-extract-plugin
export var danger = "_3w-6";
export var lightPink = "_3w-a";
export var medium = "_3w-j";
export var nearSquare = "_3w-m";
export var neutralBlue = "_3w-8";
export var neutralYellow = "_3w-7";
export var noCapitalize = "_3w-1";
export var normal = "_3w-2";
export var pill = "_3w-0";
export var primary = "_3w-3";
export var primaryLight = "_3w-4";
export var shadow = "_3w-n";
export var small = "_3w-k";
export var solidBlack = "_3w-i";
export var solidGreen = "_3w-d";
export var solidLightBlue = "_3w-f";
export var solidNavy = "_3w-e";
export var solidOrange = "_3w-h";
export var solidPink = "_3w-g";
export var solidRed = "_3w-b";
export var solidWhite = "_3w-9";
export var solidYellow = "_3w-c";
export var success = "_3w-5";
export var xsmall = "_3w-l";