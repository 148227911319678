// extracted by mini-css-extract-plugin
export var border = "_53-5";
export var button = "_53-g";
export var center = "_53-7";
export var centered = "_53-3";
export var closeButton = "_53-9";
export var disabledWrapper = "_53-h";
export var error = "_53-k";
export var fill = "_53-d";
export var footer = "_53-c";
export var footerAction = "_53-e";
export var footerButtons = "_53-f";
export var header = "_53-4";
export var icon = "_53-j";
export var info = "_53-n";
export var inline = "_53-6";
export var large = "_53-2";
export var main = "_53-a";
export var modal = "_53-0";
export var none = "_53-o";
export var scrollable = "_53-b";
export var small = "_53-1";
export var success = "_53-m";
export var tight = "_53-i";
export var titleIcon = "_53-8";
export var warn = "_53-l";