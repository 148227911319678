import { abortTimeout, withAbortListener } from "@redotech/util/abort";
import { Condition } from "@redotech/util/condition";
import { DYMO_VENDOR_ID, dymoReadWeight } from "./dymo";
import { withHidDevice } from "./hid";
import { Weight } from "./weight";

/**
 * Device filters for scales.
 */
export const SCALE_FILTERS: HIDDeviceFilter[] = [{ vendorId: DYMO_VENDOR_ID }];

const SCALE_TIMEOUT = Temporal.Duration.from({ seconds: 2 });

/**
 * Read the weight from a scale.
 */
export async function scaleReadWeight(device: HIDDevice): Promise<Weight> {
  const condition = new Condition<HIDInputReportEvent>();
  device.addEventListener("inputreport", condition.resolve);
  let event: HIDInputReportEvent;
  try {
    event = await withAbortListener(
      abortTimeout(SCALE_TIMEOUT),
      condition.reject,
    )(() => withHidDevice(device)(() => condition.result));
  } finally {
    device.removeEventListener("inputreport", condition.resolve);
  }
  switch (device.vendorId) {
    case DYMO_VENDOR_ID:
      return dymoReadWeight(event.data.buffer);
  }
  throw new Error(`Unknown vendor ID ${device.vendorId}`);
}
