import TinyColor, { Numberify, RGBA } from "@ctrl/tinycolor";
import { memo } from "react";
import {
  Alpha,
  EditableInput,
  Hue,
  Saturation,
} from "react-color/lib/components/common";
import * as colorPickerCSS from "./redo-color-picker.module.css";

export const RedoColorPicker = memo(function RedoColorPicker({
  value,
  onChange,
  showAlpha,
}: {
  value: string;
  onChange: (val: string) => void;
  showAlpha?: boolean;
}) {
  const tinycolor = TinyColor(value);
  const hsl = tinycolor.toHsl();
  const hsv = tinycolor.toHsv();
  const rgb = tinycolor.toRgb();

  const saturationPointer = () => {
    return (
      <div
        className={colorPickerCSS.saturationPointer}
        style={{ backgroundColor: value }}
      />
    );
  };
  const hueAndAlphaPointer = () => {
    return (
      <div
        className={colorPickerCSS.hueAndAlphaPointer}
        style={{ backgroundColor: value }}
      />
    );
  };

  const handleChange = (data: any) => {
    const color = TinyColor(data);
    const hex = showAlpha ? color.toHex8String() : color.toHexString();

    onChange(hex);
  };

  return (
    <div className={colorPickerCSS.picker}>
      <div className={colorPickerCSS.saturation}>
        <Saturation
          {...{ hsl: hsl, hsv: hsv }}
          className={colorPickerCSS.Saturation}
          color={value}
          onChange={handleChange}
          pointer={saturationPointer}
        />
      </div>
      <div className={colorPickerCSS.controls}>
        <div className={colorPickerCSS.sliders}>
          <div className={colorPickerCSS.hue}>
            <Hue
              {...{ hsl: hsl }}
              className={colorPickerCSS.Hue}
              color={value}
              onChange={handleChange}
              pointer={hueAndAlphaPointer}
            />
          </div>
          {showAlpha && (
            <div className={colorPickerCSS.alpha}>
              <Alpha
                {...{ hsl: hsl, rgb: rgb }}
                className={colorPickerCSS.Alpha}
                color={value}
                onChange={handleChange}
                pointer={hueAndAlphaPointer}
              />
            </div>
          )}
        </div>
      </div>
      <ColorPickerInputs
        onChange={onChange}
        rgb={rgb}
        showAlpha={showAlpha}
        value={value}
      />
    </div>
  );
});

const ColorPickerInputs = memo(function ColorPickerInputs({
  value,
  rgb,
  onChange,
  showAlpha,
}: {
  value: string;
  rgb: Numberify<RGBA>;
  onChange: (val: string) => void;
  showAlpha?: boolean;
}) {
  const handleChange = (data: any) => {
    const lh = String(data).charAt(0) === "#" ? 1 : 0;
    const isValidHex =
      data.length !== 4 + lh && data.length < 7 + lh && TinyColor(data).isValid;
    if (!isValidHex) return;

    const color = TinyColor(data);
    const hex = showAlpha ? color.toHex8String() : color.toHexString();

    onChange(hex);
  };

  const handleSingleChange = (r: number, g: number, b: number, a: number) => {
    const color = TinyColor({
      r: Math.min(255, Math.max(0, r)),
      g: Math.min(255, Math.max(0, g)),
      b: Math.min(255, Math.max(0, b)),
      a: Math.min(100, Math.max(0, a)) / 100,
    });
    const hex = showAlpha ? color.toHex8String() : color.toHexString();

    onChange(hex);
  };

  return (
    <div className={colorPickerCSS.controls}>
      <div className={colorPickerCSS.hex}>
        HEX
        <EditableInput onChange={handleChange} value={value} />
      </div>
      <div className={colorPickerCSS.rgbaSection}>
        <div className={colorPickerCSS.rgba}>
          R
          <EditableInput
            onChange={(data: any) =>
              handleSingleChange(data, rgb.g, rgb.b, rgb.a)
            }
            value={rgb.r}
          />
        </div>
        <div className={colorPickerCSS.rgba}>
          G
          <EditableInput
            onChange={(data: any) =>
              handleSingleChange(rgb.r, data, rgb.b, rgb.a)
            }
            value={rgb.g}
          />
        </div>
        <div className={colorPickerCSS.rgba}>
          B
          <EditableInput
            onChange={(data: any) =>
              handleSingleChange(rgb.r, rgb.g, data, rgb.a)
            }
            value={rgb.b}
          />
        </div>
        {showAlpha && (
          <div className={colorPickerCSS.rgba}>
            A
            <EditableInput
              onChange={(data: any) =>
                handleSingleChange(rgb.r, rgb.g, rgb.b, data)
              }
              value={Math.round(rgb.a * 100)}
            />
          </div>
        )}
      </div>
    </div>
  );
});
