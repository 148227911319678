import { RedoOutboundLabelsRpcClient } from "@redotech/merchant-sdk/outbound-labels-rpc/client";
import { FulfillmentOrderData } from "@redotech/redo-model/fulfillments/fulfillment-group";
import { PaginationCursor, TableSort } from "@redotech/redo-model/tables/table";
import {
  filtersAreEqual,
  getFilterBuilder,
} from "@redotech/redo-model/views/utils/util";
import { AdvancedTableFilter } from "@redotech/redo-web/advanced-filters/advanced-filter";
import { TableFetcher } from "@redotech/redo-web/table";

export class FulfillmentGroupsFetcher
  implements TableFetcher<FulfillmentOrderData>
{
  public rows: FulfillmentOrderData[] = [];
  private paginationCursor: PaginationCursor | null = null;
  private advancedFilters: AdvancedTableFilter[] = [];
  private sort: TableSort | null | undefined = null;
  private search: string | undefined;
  private pageSize: number | null = null;

  constructor(
    private readonly client: RedoOutboundLabelsRpcClient,
    private updateItems: (items: FulfillmentOrderData[]) => void,
    private overrideFilters?: AdvancedTableFilter[],
    private abortController?: AbortController,
  ) {}

  async counts(
    filters: { [key: string]: string },
    search: string | undefined,
    signal?: AbortSignal | undefined,
    appliedAdvancedFilters?: AdvancedTableFilter[],
  ): Promise<{ [key: string]: number }> {
    return await this.client.getFulfillmentGroupSummaryCount({
      advancedFiltersData: (
        this.overrideFilters ||
        appliedAdvancedFilters ||
        []
      ).map((filter) => filter.data),
      search,
    });
  }

  async *data(
    primaryFilter: string,
    filters: Record<string, string>,
    search: string | undefined,
    sort: TableSort | undefined,
    pageSize: number,
    pageNumber: number,
    signal: AbortSignal,
    passThroughValues: any,
    appliedAdvancedFilters?: AdvancedTableFilter[],
  ): AsyncIterator<{
    items: FulfillmentOrderData[];
    refresh: () => Promise<FulfillmentOrderData[]>;
  }> {
    for (;;) {
      if (this.abortController) {
        this.abortController.abort();
      }
      this.abortController = new AbortController();
      const nonPartialFilters = (
        this.overrideFilters ||
        appliedAdvancedFilters ||
        []
      ).filter(
        (filter) => !getFilterBuilder(filter.data).isPartial(filter.data),
      );

      if (
        !nonPartialFilters.every((filter) =>
          this.advancedFilters.some((existingFilter) =>
            filtersAreEqual(existingFilter.data, filter.data),
          ),
        ) ||
        nonPartialFilters.length !== this.advancedFilters.length
      ) {
        this.advancedFilters = nonPartialFilters;
        this.paginationCursor = null;
      }

      if (
        sort?.direction !== this.sort?.direction ||
        sort?.key !== this.sort?.key
      ) {
        this.sort = sort;
        this.paginationCursor = null;
      }

      if (search !== this.search) {
        this.search = search;
        this.paginationCursor = null;
      }

      if (pageSize !== this.pageSize) {
        this.pageSize = pageSize;
        this.paginationCursor = null;
      }

      try {
        const data = await this.client.getFulfillmentGroupSummaries({
          pageNumber,
          pageSize: this.pageSize,
          paginationCursor: this.paginationCursor,
          advancedFiltersData: this.advancedFilters.map(
            (filter) => filter.data,
          ),
          search: this.search,
          sort: this.sort
            ? { key: this.sort.key, direction: this.sort.direction }
            : null,
        });
        if (data.paginationCursor) {
          this.paginationCursor = data.paginationCursor;
        }

        this.rows = data.fulfillmentGroups;
        this.updateItems(data.fulfillmentGroups);
        yield {
          items: data.fulfillmentGroups,
          refresh: async () => {
            this.rows = [];
            this.updateItems([]);
            this.paginationCursor = null;
            return this.rows;
          },
        };
        break;
      } catch (error: any) {
        if (error.code === "ERR_CANCELED") {
          error.message = "Another request is in flight";
        }
        throw error;
      }
    }
  }
}
