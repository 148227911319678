import { Currency } from "@redotech/money/currencies";
import { useRequiredContext } from "@redotech/react-util/context";
import { useInput } from "@redotech/react-util/form";
import { useHandler } from "@redotech/react-util/hook";
import { useTriggerLoad } from "@redotech/react-util/load";
import { PriceRange } from "@redotech/redo-model/draft-return/draft-return";
import type { Return } from "@redotech/redo-model/return";
import {
  RedoBadge,
  RedoBadgeColor,
  RedoBadgeSize,
} from "@redotech/redo-web/arbiter-components/badge/redo-badge";
import {
  RedoButton,
  RedoButtonHierarchy,
  RedoButtonSize,
} from "@redotech/redo-web/arbiter-components/buttons/redo-button";
import { RedoInputSize } from "@redotech/redo-web/arbiter-components/input/base-redo-text-input";
import {
  RedoCurrencyInput,
  RedoCurrencyInputValue,
} from "@redotech/redo-web/arbiter-components/input/redo-currency-input";
import { RedoTextInput } from "@redotech/redo-web/arbiter-components/input/redo-text-input";
import PlusIcon from "@redotech/redo-web/arbiter-icon/plus.svg";
import TrashIcon from "@redotech/redo-web/arbiter-icon/trash-03.svg";
import { Button, ButtonSize, ButtonTheme } from "@redotech/redo-web/button";
import { Card, CardSize } from "@redotech/redo-web/card";
import { CurrencyContext } from "@redotech/redo-web/currency";
import { Divider } from "@redotech/redo-web/divider";
import { Flex } from "@redotech/redo-web/flex";
import { ExternalLink } from "@redotech/redo-web/link";
import { Modal, ModalSize } from "@redotech/redo-web/modal";
import { SkeletonText } from "@redotech/redo-web/skeleton";
import { Text } from "@redotech/redo-web/text";
import { groupInput, input, InputProvider, listInput } from "@redotech/ui/form";
import { memo, useContext, useEffect, useMemo, useState } from "react";
import { useDebounce } from "usehooks-ts";
import { RedoMerchantRpcClientContext } from "../../app/redo-merchant-rpc-client-provider";
import { TeamContext } from "../../app/team";
import { shopifyAdminDraftOrderUrl, shopifyStoreName } from "../../shopify";
import * as repairInvoiceCardStyles from "./repair-invoice-card.module.css";

export const RepairInvoiceCard = memo(function RepairInvoiceCard({
  return: return_,
}: {
  return?: Return;
}) {
  const team = useContext(TeamContext);
  const { formatCurrency } = useContext(CurrencyContext);
  const [editInvoiceOpen, setEditInvoiceOpen] = useState(false);
  const rpcClient = useRequiredContext(RedoMerchantRpcClientContext);

  const draftOrderURL = useMemo(() => {
    if (!return_ || !team) return undefined;

    const globalInvoiceId = return_.repair?.invoiceId;
    const invoiceId = globalInvoiceId?.split("/")?.pop();

    if (!invoiceId) return undefined;

    return shopifyAdminDraftOrderUrl(
      shopifyStoreName(team.storeUrl),
      invoiceId,
    ).toString();
  }, [return_]);

  const [repairInvoiceLoad, triggerRepairInvoiceLoad] = useTriggerLoad(
    async () => {
      if (!return_) return undefined;

      return await rpcClient.getRepairInvoiceDetails({ returnId: return_?.id });
    },
  );

  const [sendRepairInvoiceLoad, triggerSendRepairInvoice] = useTriggerLoad(
    async () => {
      if (!return_) return undefined;

      await rpcClient.sendRepairInvoice({ returnId: return_.id });

      return { sent: true };
    },
  );

  const handleSendRepairInvoice = useHandler(() => {
    triggerSendRepairInvoice();
  });

  const navigateToDraftOrder = () => {
    if (!draftOrderURL) return;

    window.open(draftOrderURL, "_blank");
  };

  const handleSecondaryAction = useHandler(() => {
    if (repairInvoice?.paid) {
      navigateToDraftOrder();
    } else {
      setEditInvoiceOpen(true);
    }
  });

  const handleSave = useHandler(() => {
    triggerRepairInvoiceLoad();
  });

  useEffect(() => {
    if (return_) {
      triggerRepairInvoiceLoad();
    }
  }, [return_]);

  const repairInvoice = repairInvoiceLoad.value;

  const repairInvoiceError = repairInvoiceLoad.error;
  const repairInvoiceLoading =
    repairInvoiceLoad.pending || (!repairInvoice && !repairInvoiceError);

  const sendRepairInvoiceLoading = sendRepairInvoiceLoad.pending;
  const repairInvoiceSent = sendRepairInvoiceLoad.value?.sent;

  const debounceRepairInvoiceSent = useDebounce(repairInvoiceSent, 1000);

  return (
    <>
      <Card size={CardSize.MEDIUM} title="Repair invoice">
        {repairInvoiceLoading ? (
          <SkeletonText length={30} />
        ) : repairInvoiceError ? (
          <Text color="error">{repairInvoiceError.message}</Text>
        ) : (
          <>
            <RedoBadge
              color={
                repairInvoice?.paid
                  ? RedoBadgeColor.SUCCESS
                  : RedoBadgeColor.GRAY
              }
              size={RedoBadgeSize.MEDIUM}
              text={repairInvoice?.paid ? "Paid" : "Unpaid"}
            />
            <Flex flexDirection="row">
              <ExternalLink url={draftOrderURL}>
                {repairInvoice?.name}
              </ExternalLink>
              <Text>
                {repairInvoice?.total
                  ? `- ${formatCurrency(repairInvoice?.total)}`
                  : ""}
              </Text>
            </Flex>
            <Flex flexDirection="row" gap="lg" justify="center">
              <Button
                fullWidth
                onClick={handleSecondaryAction}
                size={ButtonSize.MICRO}
                theme={ButtonTheme.OUTLINED}
              >
                {repairInvoice?.paid ? "View invoice" : "Edit invoice"}
              </Button>
              {!repairInvoice?.paid && (
                <Button
                  fullWidth
                  onClick={handleSendRepairInvoice}
                  pending={sendRepairInvoiceLoading}
                  size={ButtonSize.MICRO}
                  theme={ButtonTheme.PRIMARY}
                >
                  {repairInvoiceSent && !debounceRepairInvoiceSent
                    ? "Sent!"
                    : debounceRepairInvoiceSent
                      ? "Resend invoice"
                      : "Send invoice"}
                </Button>
              )}
            </Flex>
          </>
        )}
      </Card>
      {editInvoiceOpen && (
        <EditInvoiceModal
          fees={repairInvoice?.fees ?? []}
          onClose={() => setEditInvoiceOpen(false)}
          onSave={handleSave}
          open={editInvoiceOpen}
          repairFeeRange={[
            team?.settings.warranties?.defaultRepairMinPrice ?? 0,
            team?.settings.warranties?.defaultRepairMaxPrice ?? 0,
          ]}
          returnId={return_?.id}
        />
      )}
    </>
  );
});

// TODO: Move to modal folder
const repairFeeForm = groupInput({
  title: input<string>(),
  feeSet: groupInput({ amount: input<number>(), currency: input<Currency>() }),
});

const repairFeeDefault = (_: number) => ({
  title: "",
  feeSet: { amount: 0, currency: Currency.USD },
});

type RepairFee = InputProvider.Value<typeof repairFeeForm>;

const repairInvoiceForm = groupInput(
  {
    fees: listInput(
      () => repairFeeForm,
      repairFeeDefault,
      (_, index) => index,
    ),
  },
  {
    validator: (value) => {
      const errors: string[] = [];
      value.fees.forEach((fee) => {
        if (!fee.title) errors.push("Title is required");
        if (isNaN(fee.feeSet.amount)) errors.push("Fee must be a number");
        if (fee.feeSet.amount <= 0) errors.push("Fee must be greater than 0");
      });
      return errors;
    },
  },
);

const sanitizeFees = (fees: RepairFee[]) => {
  return fees
    .filter((fee) => fee.title && fee.feeSet.amount)
    .map((fee) => ({ title: fee.title, fee: fee.feeSet.amount.toFixed(2) }));
};

const EditInvoiceModal = memo(function EditInvoiceModal({
  onClose,
  onSave,
  open,
  fees,
  returnId,
  repairFeeRange,
}: {
  onClose: () => void;
  onSave: () => void;
  open: boolean;
  fees: { title: string; fee: string }[];
  returnId?: string;
  repairFeeRange: PriceRange;
}) {
  const rpcClient = useRequiredContext(RedoMerchantRpcClientContext);
  const { formatCurrency } = useContext(CurrencyContext);

  const input = useInput(repairInvoiceForm, {
    fees: fees.map((fee) => ({
      title: fee.title,
      feeSet: { amount: parseFloat(fee.fee), currency: Currency.USD },
    })),
  });

  const { fees: feesInput } = input.inputs;

  const [saveLoad, triggerSave] = useTriggerLoad(async () => {
    if (!returnId) return;
    if (input.changed) {
      const fees = sanitizeFees(input.value.fees);
      await rpcClient.updateRepairInvoice({ returnId, fees });
    }
    onSave();
    onClose();
  });

  const [saveAndSendLoad, triggerSaveAndSend] = useTriggerLoad(async () => {
    if (!returnId) return;
    if (input.changed) {
      const fees = sanitizeFees(input.value.fees);
      await rpcClient.updateRepairInvoice({ returnId, fees });
    }
    await rpcClient.sendRepairInvoice({ returnId });
    onSave();
    onClose();
  });

  const handleSave = useHandler(() => {
    triggerSave();
  });

  const handleSaveAndSend = useHandler(() => {
    triggerSaveAndSend();
  });

  const saveLoading = saveLoad.pending;
  const saveAndSendLoading = saveAndSendLoad.pending;

  const subtitle = useMemo(() => {
    if (repairFeeRange[0] === repairFeeRange[1]) {
      if (repairFeeRange[0] === 0) {
        return null;
      }
      return (
        <Text className={repairInvoiceCardStyles.subtitle} fontSize="sm">
          Original invoice price: {`${formatCurrency(repairFeeRange[0])}`}
        </Text>
      );
    }
    return (
      <Text className={repairInvoiceCardStyles.subtitle} fontSize="sm">
        Original invoice price:{" "}
        {`${formatCurrency(repairFeeRange[0])} - ${formatCurrency(repairFeeRange[1])}`}
      </Text>
    );
  }, [repairFeeRange]);

  if (!returnId) return null;

  return (
    <Modal
      footer={
        <Flex flexDirection="row" justify="space-between" w="full">
          <RedoButton
            hierarchy={RedoButtonHierarchy.LINK_GRAY}
            onClick={onClose}
            size={RedoButtonSize.REGULAR}
            text="Cancel"
          />
          <Flex flexDirection="row" gap="lg" justify="center">
            <RedoButton
              disabled={input.errors.length > 0}
              hierarchy={RedoButtonHierarchy.SECONDARY}
              onClick={handleSave}
              pending={saveLoading}
              size={RedoButtonSize.REGULAR}
              text="Save"
            />
            <RedoButton
              disabled={input.errors.length > 0}
              hierarchy={RedoButtonHierarchy.PRIMARY}
              onClick={handleSaveAndSend}
              pending={saveAndSendLoading}
              size={RedoButtonSize.REGULAR}
              text="Save and send"
            />
          </Flex>
        </Flex>
      }
      onClose={onClose}
      open={open}
      showHeaderBorder={false}
      size={ModalSize.SMALL}
      subtitle={subtitle}
      title="Edit invoice"
    >
      <Flex flexDirection="column" gap="lg" justify="flex-start">
        {feesInput.inputs.map((feeInput, index) => {
          const { title, feeSet } = feeInput.inputs;
          return (
            <RepairFeeInput
              fee={feeSet.value}
              feeTitle={title.value}
              key={index}
              onRemove={
                index > 0
                  ? () => {
                      feesInput.setValue(
                        feesInput.value.filter((_, i) => i !== index),
                      );
                    }
                  : undefined
              }
              setFee={(value) => {
                if (!value) return;
                feeSet.setValue(value);
              }}
              setFeeTitle={title.setValue}
            />
          );
        })}
        <RedoButton
          className={repairInvoiceCardStyles.addFeeButton}
          hierarchy={RedoButtonHierarchy.LINK_GRAY}
          IconLeading={PlusIcon}
          onClick={() => {
            feesInput.setValue([
              ...feesInput.value,
              repairFeeDefault(feesInput.value.length),
            ]);
          }}
          size={RedoButtonSize.SMALL}
          text="Add fee"
        />
        <Divider />
        <Flex flexDirection="row" justify="space-between" w="full">
          <Text fontWeight="semibold">Total</Text>
          <Text fontWeight="semibold">
            {formatCurrency(
              feesInput.value.reduce(
                (acc, feeItem) =>
                  feeItem.feeSet.amount ? acc + feeItem.feeSet.amount : acc,
                0,
              ),
            )}
          </Text>
        </Flex>
      </Flex>
    </Modal>
  );
});

const RepairFeeInput = memo(function RepairFeeInput({
  feeTitle,
  setFeeTitle,
  fee,
  setFee,
  onRemove,
}: {
  feeTitle: string;
  setFeeTitle: (value: string) => void;
  fee: RedoCurrencyInputValue;
  setFee: (value: RedoCurrencyInputValue) => void;
  onRemove?: () => void;
}) {
  return (
    <Flex
      flexDirection="row"
      justify="space-between"
      pr={onRemove ? "none" : "3xl"}
      w="full"
    >
      <RedoTextInput
        placeholder="Repair fee"
        setValue={setFeeTitle}
        size={RedoInputSize.SMALL}
        value={feeTitle}
      />
      <Flex flexDirection="row" gap="none">
        <RedoCurrencyInput
          required
          setValue={setFee}
          size={RedoInputSize.SMALL}
          value={fee}
        />
        {onRemove && (
          <RedoButton
            className={repairInvoiceCardStyles.removeFeeButton}
            hierarchy={RedoButtonHierarchy.LINK_GRAY}
            IconLeading={() => (
              <TrashIcon
                className={repairInvoiceCardStyles.removeFeeButtonIcon}
              />
            )}
            onClick={onRemove}
            size={RedoButtonSize.SMALL}
          />
        )}
      </Flex>
    </Flex>
  );
});
