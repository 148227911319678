import { MerchantAppReturn } from "@redotech/redo-model/return";
import { Text } from "@redotech/redo-web/text";
import { isDef } from "@redotech/util/checks";
import { memo, useMemo } from "react";
import * as returnCss from "../return.module.css";

const MAX_DAYS_BETWEEN_DELIVERY_AND_CARRIER_RECEIVED_RETURN = 7;

/**
 * This component is currently only rendered for Buttons Bebe (64c84223d5e3c700074d73c5).
 * It seemed like a very niche use case, which is why I did custom code for the team
 * rather than making another team setting. It is used to track the days between the
 * order delivery and when the carrier received the return. It assumes the merchant is
 * live on Order Tracking (or at least has outbound tracking) for the order delivery
 * date.
 */
export const DaysSinceDelivered = memo(function DaysSinceDelivered({
  return_,
}: {
  return_: MerchantAppReturn;
}) {
  const dateOfFirstEntryIndicatingShipmentReceivedByCarrier: string | null =
    (() => {
      const firstEntry =
        return_.shipment?._shipment?.tracker.tracking_details.find(
          (detail) => detail.status === "in_transit",
        ) ??
        return_.shipment?._shipment?.tracker.tracking_details.find(
          (detail) => detail.status === "out_for_delivery",
        ) ??
        return_.shipment?._shipment?.tracker.tracking_details.find(
          (detail) => detail.status === "delivered",
        );

      if (!firstEntry) {
        return null;
      }

      return firstEntry.datetime;
    })();

  const returnedOrder = (() => {
    const orderId = return_.orders[0].order;
    const order = return_.customerOrders.find((order) => order._id === orderId);
    return order;
  })();

  const dateOfDelivery: string | null = (() => {
    if (!returnedOrder) {
      return null;
    }

    const deliveryEntry = returnedOrder.trackingTimeline.find(
      (entry) => entry.status === "delivered",
    );
    if (!deliveryEntry) {
      return null;
    }

    return deliveryEntry.createdAt;
  })();

  const daysBetweenDeliveredAndCarrierReceived = useMemo(() => {
    if (
      !dateOfFirstEntryIndicatingShipmentReceivedByCarrier ||
      !dateOfDelivery
    ) {
      return null;
    }

    const carrierReceivedDate = Temporal.Instant.from(
      dateOfFirstEntryIndicatingShipmentReceivedByCarrier,
    );
    const deliveryDate = Temporal.Instant.from(dateOfDelivery);

    const hoursDifference = carrierReceivedDate
      .until(deliveryDate)
      .total({ unit: "hours" });

    return Math.abs(hoursDifference) / 24;
  }, [dateOfFirstEntryIndicatingShipmentReceivedByCarrier, dateOfDelivery]);

  const text = isDef(daysBetweenDeliveredAndCarrierReceived)
    ? `Days between delivery and carrier received return: ${daysBetweenDeliveredAndCarrierReceived.toFixed(1)}`
    : !isDef(dateOfDelivery) || !returnedOrder
      ? "Unknown order delivery date"
      : !isDef(dateOfFirstEntryIndicatingShipmentReceivedByCarrier)
        ? "Carrier has not received return or reception date is unknown"
        : "Unable to calculate days between delivery and carrier received return";

  return (
    <div className={returnCss.headerSubtitle}>
      <Text
        textColor={
          isDef(daysBetweenDeliveredAndCarrierReceived)
            ? daysBetweenDeliveredAndCarrierReceived >=
              MAX_DAYS_BETWEEN_DELIVERY_AND_CARRIER_RECEIVED_RETURN
              ? "error"
              : "success"
            : "warning"
        }
      >
        {text}
      </Text>
    </div>
  );
});
