import {
  ConversationPlatform,
  ConversationPlatformString,
} from "@redotech/redo-model/conversation";
import {
  ChannelFilterType,
  FilterGroupFilterOption,
  ChannelsFilter as ModelChannelsFilter,
  PendingFilter,
} from "@redotech/redo-model/conversation-filters/conversation-filters";
import {
  RedoFilterDropdownAnchor,
  RedoFilterGroup,
} from "@redotech/redo-web/arbiter-components/filter-group/redo-filter-group";
import { RedoListItem } from "@redotech/redo-web/arbiter-components/list/redo-list";
import { RedoListItemSize } from "@redotech/redo-web/arbiter-components/list/redo-list-item";
import { RedoMultiSelectDropdown } from "@redotech/redo-web/arbiter-components/select-dropdown/redo-multi-select-dropdown";
import { RedoSingleSelectDropdown } from "@redotech/redo-web/arbiter-components/select-dropdown/redo-single-select-dropdown";
import {
  ConversationFilterOptionToIcon,
  ConversationFilterOptionToName,
} from "@redotech/redo-web/conversation-filters/conversation-filter-icons";
import { Flex } from "@redotech/redo-web/flex";
import { Text } from "@redotech/redo-web/text";
import { memo, useEffect, useState } from "react";

export const ChannelsFilter = memo(function ConversationTagFilter({
  filter,
  setFilter,
  removeFilter,
  openOnRender,
}: {
  filter: ModelChannelsFilter | PendingFilter<ModelChannelsFilter>;
  setFilter(
    filter: ModelChannelsFilter | PendingFilter<ModelChannelsFilter>,
  ): void;
  removeFilter(): void;
  openOnRender: boolean;
}) {
  const [queryRef, setQueryRef] = useState<HTMLButtonElement | null>(null);
  const [valueRef, setValueRef] = useState<HTMLButtonElement | null>(null);

  const channelFilterType = filter.query;
  const selectedChannels = filter?.value;

  const query = (
    <RedoFilterDropdownAnchor
      color="secondary"
      ref={setQueryRef}
      text={channelFilterTypeToText[channelFilterType]}
    />
  );

  const valueText = filter.value
    ? filter.value.length === 1
      ? "1 Channel"
      : `${filter.value.length} Channels`
    : "...";

  useEffect(() => {
    if (openOnRender && valueRef) {
      valueRef.click();
    }
  }, [openOnRender, valueRef]);

  const queryDropdown = (
    <RedoSingleSelectDropdown
      dropdownButtonRef={queryRef}
      options={channelDropdownItems}
      optionSelected={(query: RedoListItem<ChannelFilterType>) => {
        if (filter) {
          setFilter({ ...filter, query: query.value });
        }
      }}
      selectedItem={channelDropdownItems.find(
        (item) => item.value === channelFilterType,
      )}
      size={RedoListItemSize.SMALL}
    >
      {(item) => (
        <Text
          fontSize="sm"
          overflow="hidden"
          textOverflow="ellipsis"
          whiteSpace="nowrap"
        >
          {channelFilterTypeToText[item.value]}
        </Text>
      )}
    </RedoSingleSelectDropdown>
  );

  const value = (
    <RedoFilterDropdownAnchor
      ref={setValueRef}
      text={valueText}
      tooltip={
        selectedChannels && (
          <Flex dir="column">
            {selectedChannels.map((channel) => (
              <Text key={channel}>{ConversationPlatformString[channel]}</Text>
            ))}
          </Flex>
        )
      }
      weight="semibold"
    />
  );

  const channelOptions: RedoListItem<ConversationPlatform>[] = Object.values(
    ConversationPlatform,
  ).map((channel) => {
    return { value: channel };
  });

  const selectedChannelOptions: RedoListItem<ConversationPlatform>[] = (
    selectedChannels || []
  ).map((channel) => ({ value: channel }));

  const valueDropdown = (
    <RedoMultiSelectDropdown
      dropdownButtonRef={valueRef}
      options={channelOptions}
      selectedOptions={selectedChannelOptions}
      setSelectedOptions={(channels) => {
        if (channels.length === 0) {
          setFilter({ ...filter, query: channelFilterType, value: null });
        } else {
          setFilter({
            ...filter,
            query: channelFilterType,
            value: channels.map((c) => c.value),
          });
        }
      }}
      size={RedoListItemSize.SMALL}
    >
      {(item) => (
        <Text
          fontSize="sm"
          overflow="hidden"
          textOverflow="ellipsis"
          whiteSpace="nowrap"
        >
          {ConversationPlatformString[item.value]}
        </Text>
      )}
    </RedoMultiSelectDropdown>
  );

  return (
    <>
      {queryDropdown}
      {valueDropdown}
      <RedoFilterGroup
        Icon={ConversationFilterOptionToIcon[FilterGroupFilterOption.CHANNELS]}
        propertyName={
          ConversationFilterOptionToName[FilterGroupFilterOption.CHANNELS]
        }
        query={query}
        removeFilter={removeFilter}
        value={value}
      />
    </>
  );
});

const channelFilterTypeToText: Record<ChannelFilterType, string> = {
  [ChannelFilterType.INCLUDES]: "include",
  [ChannelFilterType.EXCLUDES]: "do not include",
};

const channelDropdownItems: RedoListItem<ChannelFilterType>[] = [
  { value: ChannelFilterType.INCLUDES },
  { value: ChannelFilterType.EXCLUDES },
];
