import { CustomerActivityType } from "@redotech/redo-model/marketing/segments/segment-types";
import { z } from "zod";

export const getTokenFieldOptions = {
  input: z.object({
    dataStructureType: z.nativeEnum(CustomerActivityType),
    dataStructureField: z.string(),
    searchString: z.string().optional(),
  }),
  output: z.object({ items: z.array(z.string()) }),
};
