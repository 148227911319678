// extracted by mini-css-extract-plugin
export var defaultWrapper = "_9o-3";
export var downloadButton = "_9o-4";
export var fileContainer = "_9o-7";
export var fileInfo = "_9o-5";
export var fileInfoWidthRestricted = "_9o-6";
export var fileType = "_9o-1";
export var fileTypeName = "_9o-2";
export var image = "_9o-0";
export var link = "_9o-9";
export var overflowEllipsis = "_9o-8";