import { genericMemo } from "@redotech/react-util/component";
import {
  ArrayPredicateExpression,
  ArrayPredicateOperator,
  predicateOperators,
} from "@redotech/redo-model/advanced-flow/expressions/array-expression";
import { GenericDataType } from "@redotech/redo-model/advanced-flow/type-system/data-types/generic-types";
import { Schema } from "@redotech/redo-model/advanced-flow/type-system/schema";
import {
  RedoDropdownInputSize,
  RedoSingleSelectDropdownInput,
} from "@redotech/redo-web/arbiter-components/select-dropdown/redo-single-select-dropdown-input";
import { DeepPartial } from "@redotech/util/type";
import { useEffect, useMemo } from "react";
import { FieldInspector, camelToTitleCase } from ".";

export const ArrayInspector = genericMemo(function ArrayInspector({
  schema,
  dataType,
  expression,
  setExpression,
}: {
  schema: Schema;
  dataType: GenericDataType;
  expression: DeepPartial<ArrayPredicateExpression>;
  setExpression: (expression: DeepPartial<ArrayPredicateExpression>) => void;
}) {
  const unwrappedDataType = dataType.replace(
    "Multiple ",
    "",
  ) as GenericDataType;

  useEffect(() => {
    if (!expression.type) {
      setExpression({ ...expression, type: "array_predicate" });
    }
    if (!expression.operator) {
      setExpression({ ...expression, operator: "some" });
    }
  }, [expression]);

  const subExpresion = expression.condition ?? {};

  // kind of a hack to make sure the field is always populated
  subExpresion.field = expression.field;

  const options = useMemo(() => {
    return predicateOperators.map((operator) => ({ value: operator }));
  }, []);

  const selectedItem = expression.operator
    ? { value: expression.operator }
    : undefined;

  return (
    <>
      <RedoSingleSelectDropdownInput<ArrayPredicateOperator>
        label="Comparison type"
        options={options}
        optionSelected={(operator) => {
          setExpression({ ...expression, operator: operator.value });
        }}
        selectedItem={selectedItem}
        size={RedoDropdownInputSize.SMALL}
      >
        {({ value }) => camelToTitleCase(value)}
      </RedoSingleSelectDropdownInput>

      <FieldInspector
        dataType={unwrappedDataType}
        expression={subExpresion}
        schema={schema}
        setExpression={(condition) =>
          setExpression({ ...expression, condition })
        }
      />
    </>
  );
});
