import { ClickAwayListener, Popper } from "@mui/base";
import { useRequiredContext } from "@redotech/react-util/context";
import { Value } from "@redotech/util/type";
import ResizeModule from "@ssumo/quill-resize-module";
import * as classNames from "classnames";
import Quill from "quill";
import { Delta, Parchment, Range } from "quill/core";
import Emitter from "quill/core/emitter";
import * as React from "react";
import {
  CSSProperties,
  ForwardedRef,
  forwardRef,
  useCallback,
  useEffect,
  useLayoutEffect,
  useRef,
  useState,
} from "react";
import { RedoColorPicker } from "../arbiter-components/color-picker/redo-color-picker";
import {
  BaseEmoji,
  CustomEmoji,
  EmojiPicker,
  EmojiPickerCloseCause,
} from "../emoji-picker";
import { ThemeContext } from "../theme-provider";
import * as quillEditorCss from "./quill-editor.module.css";
import { QuillLink } from "./quill-link";

export const quillFakeCursorPadding = 2;

export type OnEditorChangeArgs =
  | [typeof Quill.events.TEXT_CHANGE, Delta, Delta, string]
  | [typeof Quill.events.SELECTION_CHANGE, Range, Range, string];

export type QuillEditorProps = {
  /** Either the toolbar element's id (without #) or an HTMLDivElement
   * referring to the toolbar directly. It's useful to pass as an element
   * to allow Quill to reinitialize the toolbar when the toolbar re-renders.
   */
  toolbar?: string | HTMLDivElement;
  readOnly?: boolean;
  defaultValue?: any;

  /** Called when the text changes. https://quilljs.com/docs/api#text-change */
  onTextChange?: (delta: Delta, oldContents: Delta, source: string) => void;

  /** Called when the selection changes. https://quilljs.com/docs/api#selection-change */
  onSelectionChange?: (
    range: { index: number; length: number } | null,
    oldRange: { index: number; length: number } | null,
    source: string,
  ) => void;

  /** Called when the selection or text changes, even if they changed silently,
   * as in the case of the selection-change event fired after a text-change.
   * https://quilljs.com/docs/api#editor-change */
  onEditorChange?: (args: OnEditorChangeArgs) => void;

  placeholder?: string;
  onQuillEditorEmptyChange?: (empty: boolean) => void;

  cursorIndexRef?: React.MutableRefObject<number | undefined>;
  setEmojiPickerOpenRef?: React.MutableRefObject<
    React.Dispatch<React.SetStateAction<boolean>> | undefined
  >;

  /** HTML element to anchor the emoji picker to. If not provided, the emoji picker will be anchored
   * to the cursor if opened via hotkey and to the emoji button if opened via click.
   */
  emojiPickerAnchorOverride?: HTMLElement | null;

  /**
   * To make font size portable, you may want the output of the editor to be inline styles instead of classes.
   * Everything except the settings -> email builder's text field works this way.
   */
  inlineFontSizeStyles?: boolean;

  /**
   * Class name to apply to the editor container.
   */
  editorClassName?: string;

  /**
   * Styles to apply to the editor container.
   */
  editorStyles?: CSSProperties;

  /**
   * Id to apply to the editor container. Useful for CSS targeting.
   */
  editorId?: string;

  /**
   * Allow pasting images and videos.
   */
  allowEmbeds?: boolean;

  /**
   * Whitelisted font families.
   */
  fontFamilies?: readonly string[];
};

// quill editor v2 internally displays all lists as <ol> elements, then converts them to <ul> when we callGetSemanticHTML()
// when we plug them back into quill after saving them to the db, we need to convert back to <ol> elements with data-list="bullet" attribute
// see https://github.com/slab/quill/issues/3957
export function convertToQuillHTML(html: string) {
  const container = document.createElement("div");
  container.innerHTML = html;
  const ulElements = container.querySelectorAll("ul");
  ulElements.forEach((ul) => {
    const ol = document.createElement("ol");
    const liElements = ul.querySelectorAll("li");
    liElements.forEach((li) => {
      const newLi = document.createElement("li");
      newLi.setAttribute("data-list", "bullet");
      newLi.innerHTML = li.innerHTML;
      ol.appendChild(newLi);
    });
    ul.replaceWith(ol);
  });
  return container.innerHTML;
}

const DEFAULT_QUILL_FONT_FAMILIES = [
  "monospace",
  "serif",
  "sans-serif",
  "arial",
  "courier-new",
  "georgia",
  "lucida-sans",
  "tahoma",
  "times-new-roman",
  "verdana",
] as const;

const QUILL_FORMATS_EXCEPT_EMBEDS = [
  "background",
  "bold",
  "color",
  "font",
  "code",
  "italic",
  "link",
  "size",
  "strike",
  "script",
  "underline",
  "blockquote",
  "header",
  "indent",
  "list",
  "align",
  "direction",
  "code-block",
];

/**
 * The text area alongside a quill instance that fired text changed callbacks.
 * Customize your editor's behavior by grabbing the quill instance from the ref.
 */
export const QuillEditor = forwardRef<Quill, QuillEditorProps>(
  function QuillEditor(
    {
      toolbar,
      readOnly,
      defaultValue,
      onTextChange,
      onSelectionChange,
      onEditorChange,
      placeholder,
      onQuillEditorEmptyChange,

      cursorIndexRef,
      setEmojiPickerOpenRef,

      inlineFontSizeStyles: inlineFontStyles = true,
      editorClassName,
      editorStyles,
      editorId,
      emojiPickerAnchorOverride,
      allowEmbeds = true,
      fontFamilies = DEFAULT_QUILL_FONT_FAMILIES,
    }: QuillEditorProps,
    ref: ForwardedRef<Quill>,
  ) {
    const containerRef = useRef(null);
    const fakeCursorRef = useRef<HTMLDivElement>(null);
    const defaultValueRef = useRef(defaultValue);

    const onTextChangeRef = useRef(onTextChange);
    const onSelectionChangeRef = useRef(onSelectionChange);
    const onEditorChangeRef = useRef(onEditorChange);

    const [emojiPickerOpen, setEmojiPickerOpen] = useState(false);
    setEmojiPickerOpenRef &&
      (setEmojiPickerOpenRef.current = setEmojiPickerOpen);
    const emojiButtonRef = useRef<HTMLButtonElement | null>(null);
    const [emojiPickerOpenedWithHotkey, setEmojiPickerOpenedWithHotkey] =
      useState(false);

    const [colorPickerOpen, setColorPickerOpen] = useState(false);
    const colorButtonRef = useRef<HTMLButtonElement | null>(null);
    const [quillColor, setQuillColor] = useState<string | undefined>();

    const { theme } = useRequiredContext(ThemeContext);

    const quillRef = useRef<Quill | null>(null);
    const [cursorIndex, setCursorIndex] = useState(0);

    useLayoutEffect(() => {
      onTextChangeRef.current = onTextChange;
      onSelectionChangeRef.current = onSelectionChange;
      onEditorChangeRef.current = onEditorChange;
    });

    useEffect(() => {
      if (containerRef.current == null) {
        return;
      }
      const container = containerRef.current as HTMLElement;

      // Register fonts
      const Font: any = Quill.import("formats/font");
      Font.whitelist = fontFamilies;
      Quill.register(Font, true);

      // Use inline styling for font size instead of classes
      if (inlineFontStyles) {
        const SizeStyle: any = Quill.import("attributors/style/size");
        SizeStyle.whitelist = ["12px", "16px", "24px", "36px"];
        Quill.register(SizeStyle, true);
      }

      Quill.register("modules/resize", ResizeModule as any);
      Quill.register(QuillLink, true);

      const quill = new Quill(container, {
        placeholder: readOnly ? "" : placeholder || "",
        theme: "snow",
        readOnly: !!readOnly,
        modules: {
          toolbar: !toolbar
            ? false
            : {
                container:
                  typeof toolbar === "string" ? `#${toolbar}` : toolbar,
                handlers: {
                  "dynamic-variables": function (value: any) {
                    const range = quill.getSelection();
                    if (range) quill.insertText(range.index, value);
                  },
                },
              },

          resize: { locale: {} },
          clipboard: {
            matchers: [
              [
                "img",
                (node: HTMLImageElement, delta: Delta) => {
                  if (node.style.width) {
                    delta.ops[0].attributes = delta.ops[0].attributes || {};
                    delta.ops[0].attributes.width = node.style.width;
                  }
                  if (node.style.height) {
                    delta.ops[0].attributes = delta.ops[0].attributes || {};
                    delta.ops[0].attributes.height = node.style.height;
                  }
                  return delta;
                },
              ],
            ],
          },
        },
        // Explicitly allowing everything but embeds implicitly disallows embeds.
        formats: allowEmbeds ? null : QUILL_FORMATS_EXCEPT_EMBEDS,
      });
      quillRef.current = quill;
      cursorIndexRef && (cursorIndexRef.current = 0);

      // Pass Quill instance to parent via forwarded ref
      if (ref) {
        if (typeof ref === "function") {
          ref(quill);
        } else {
          ref.current = quill;
        }
      }

      // Attach listeners for quill events
      const quillListeners: [
        Value<(typeof Emitter)["events"]>,
        (...args: any) => void,
      ][] = [];

      function handleSelectionChange(range: Range) {
        if (!range) return;
        cursorIndexRef && (cursorIndexRef.current = range.index);
        setCursorIndex(range.index);
        setQuillColor(quill.getFormat(range.index).color as string | undefined);
      }
      quillListeners.push(["selection-change", handleSelectionChange]);
      quillListeners.push(["editor-change", handleEditorChange]);

      if (onTextChangeRef.current) {
        quillListeners.push(["text-change", onTextChangeRef.current]);
      }
      if (onSelectionChangeRef.current) {
        quillListeners.push(["selection-change", onSelectionChangeRef.current]);
      }
      if (onEditorChangeRef.current) {
        quillListeners.push(["editor-change", onEditorChangeRef.current]);
      }
      quillListeners.push([
        "editor-change",
        (...args) => {
          onQuillEditorEmptyChange?.(quill.getLength() <= 1);
        },
      ]);

      // Attach listeners to toolbar buttons and store refs to toolbar buttons
      const toolbarListeners: [
        HTMLElement,
        keyof HTMLElementEventMap,
        EventListenerOrEventListenerObject,
      ][] = [];
      const toolbarElement =
        typeof toolbar === "string"
          ? document.querySelector(`#${toolbar}`)
          : toolbar;

      const emojiButton = toolbarElement?.querySelector(`.rql-emoji`) as
        | HTMLButtonElement
        | null
        | undefined;

      if (emojiButton) {
        emojiButtonRef.current = emojiButton;
        toolbarListeners.push([emojiButton, "click", openEmojiPickerOnClick]);
      }

      const colorButton = toolbarElement?.querySelector(
        `.rql-color`,
      ) as HTMLButtonElement;

      if (colorButton) {
        colorButtonRef.current = colorButton;
        toolbarListeners.push([colorButton, "click", openColorPickerOnClick]);
      }

      if (defaultValueRef.current) {
        quill.setContents(defaultValueRef.current);
      }

      for (const [event, callback] of quillListeners) {
        quill.on(event, callback);
      }
      for (const [element, event, callback] of toolbarListeners) {
        element.addEventListener(event, callback);
      }
      return () => {
        for (const [event, callback] of quillListeners) {
          quill.off(event, callback);
        }
        for (const [element, event, callback] of toolbarListeners) {
          element.removeEventListener(event, callback);
        }
        if (typeof ref === "function") {
          ref(null);
        } else if (ref) {
          ref.current = null;
        }
        container.innerHTML = "";
        quill.off(Quill.events.TEXT_CHANGE);
        quill.off(Quill.events.SELECTION_CHANGE);
        quill.off(Quill.events.EDITOR_CHANGE);
      };
      // FIXME
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [ref, readOnly, toolbar]);

    function openEmojiPickerOnClick(event: Event) {
      event.stopPropagation();
      setEmojiPickerOpen((emojiPickerOpen) => {
        if (!emojiPickerOpen) setEmojiPickerOpenedWithHotkey(false);
        return !emojiPickerOpen;
      });
    }

    function openColorPickerOnClick() {
      setColorPickerOpen((open) => {
        if (open) {
          quillRef.current?.focus();
        }
        return !open;
      });
    }

    function handleEditorChange([name, ...rest]: OnEditorChangeArgs) {
      if (name === Quill.events.SELECTION_CHANGE) {
        const range = rest[0] as Range;
        if (range?.index !== undefined) {
          cursorIndexRef && (cursorIndexRef.current = range.index);
          setCursorIndex(range.index);
        }
      }
    }

    function handleEmojiSelected(emoji: BaseEmoji | CustomEmoji) {
      if (!("native" in emoji)) return; // Rule out CustomEmoji

      const quill = quillRef.current;
      if (!quill) return;
      const selection = quill.getSelection();

      if (selection && selection.length) {
        quill.deleteText(cursorIndex, selection.length);
      }
      quill.insertText(cursorIndex, emoji.native, Quill.sources.USER);

      const newCursorIndex = cursorIndex + emoji.native.length;
      quill.setSelection(newCursorIndex, 0);
      cursorIndexRef && (cursorIndexRef.current = newCursorIndex);
      setCursorIndex(newCursorIndex);
    }

    function handleHotkey(event: React.KeyboardEvent) {
      if (!event.ctrlKey && !event.metaKey) {
        return;
      }
      if (event.key === "3") {
        setEmojiPickerOpenedWithHotkey(true);
        setEmojiPickerOpen(true);
        event.preventDefault();
      }
    }

    function closeEmojiPicker(cause: EmojiPickerCloseCause) {
      setEmojiPickerOpenedWithHotkey(false);
      setEmojiPickerOpen(false);
      if (cause !== "clickOutside") {
        quillRef.current?.focus();
      }
    }

    const handleColorPicked = useCallback((color: string) => {
      setQuillColor(color);
      const quill = quillRef.current;
      if (!quill) return;
      formatQuillWithoutFocusingRisky(quill, "color", color);
    }, []);

    const handleColorPickerKeyDown = useCallback(
      (event: React.KeyboardEvent<HTMLDivElement>) => {
        if (event.key === "Enter" || event.key === "Escape") {
          setColorPickerOpen(false);
          quillRef.current?.focus();
          // Don't let the enter press go through to quill
          event.preventDefault();
        }
      },
      [],
    );

    return (
      <div className={quillEditorCss.container}>
        <div
          className={classNames(quillEditorCss.fakeCursor, {
            [quillEditorCss.active]: emojiPickerOpen,
          })}
          ref={fakeCursorRef}
          style={{
            padding: quillFakeCursorPadding + "px",
            left:
              (quillRef.current?.getBounds(cursorIndex)?.left || 0) -
              quillFakeCursorPadding,
            top:
              (quillRef.current?.getBounds(cursorIndex)?.top || 0) -
              quillFakeCursorPadding,
            width: 1 + quillFakeCursorPadding * 2 + "px",
            height:
              (quillRef.current?.getBounds(cursorIndex)?.height || 0) +
              quillFakeCursorPadding * 2 +
              "px",
          }}
        />
        <EmojiPicker
          anchor={
            emojiPickerAnchorOverride ||
            (emojiPickerOpenedWithHotkey
              ? fakeCursorRef.current
              : emojiButtonRef.current)
          }
          handleEmojiSelected={handleEmojiSelected}
          onClose={closeEmojiPicker}
          open={emojiPickerOpen}
          theme={theme}
        />
        <ClickAwayListener
          mouseEvent="onMouseDown"
          onClickAway={() => setColorPickerOpen(false)}
          touchEvent="onTouchStart"
        >
          <Popper
            anchorEl={colorButtonRef.current}
            onKeyDown={handleColorPickerKeyDown}
            open={colorPickerOpen}
          >
            <RedoColorPicker
              onChange={handleColorPicked}
              value={quillColor ?? "#000000"}
            />
          </Popper>
        </ClickAwayListener>
        <div
          className={editorClassName}
          id={editorId}
          onKeyDown={handleHotkey}
          ref={containerRef}
          style={editorStyles}
        />
      </div>
    );
  },
);

export function pasteHtmlWithoutFocusing(quill: Quill, html: string) {
  const delta = quill.clipboard.convert({ html });
  quill.setContents(delta);
}

/**
 * This function is copied from quill.format but with certain
 * things changed to avoid focusing the quill editor. In particular,
 * 1. This function bypasses quill's event system. No EDITOR_CHANGE or
 *  TEXT_CHANGE events will fire as a result of this function.
 * 2. Instead of calling quill.getSelection, this function
 *  uses a variable more internal to quill that doesn't seem to be
 *  intended for public use.
 *
 * If you aren't concerned about quill grabbing focus when you
 * format, just use quill.format.
 *
 * @param quill Quill instance.
 * @param name Name of format to change. e.g. `"color"`
 * @param value Value for format. e.g. `"#deadaf"`
 * @returns
 */
function formatQuillWithoutFocusingRisky(
  quill: Quill,
  name: string,
  value: string | undefined,
) {
  // When quill is unfocused, quill.getSelection() returns null. Instead,
  // we access the last non-null selection, stored in savedRange.
  const range = quill.selection.savedRange;
  if (range == null) return;
  if (quill.scroll.query(name, Parchment.Scope.BLOCK)) {
    quill.editor.formatLine(range.index, range.length, { [name]: value });
  } else if (range.length === 0) {
    quill.selection.format(name, value);
  } else {
    quill.editor.formatText(range.index, range.length, { [name]: value });
  }
}
