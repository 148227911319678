import { useRequiredContext } from "@redotech/react-util/context";
import { useHandler } from "@redotech/react-util/hook";
import { useLoad } from "@redotech/react-util/load";
import { View } from "@redotech/redo-model/view";
import { ReactNode, createContext, memo, useContext, useState } from "react";
import { RedoMerchantClientContext } from "../client/context";
import { getTeamViews, getUserViews } from "../client/view";
import { AuthContext } from "./auth";

export const ViewsContext = createContext<
  { team?: View[]; private?: View[] } | undefined
>(undefined);

export const ReloadViewsContext = createContext<(() => void) | undefined>(
  undefined,
);

export const ViewsProvider = memo(function ViewsProvider({
  children,
}: {
  children: ReactNode | ReactNode[];
}) {
  const client = useRequiredContext(RedoMerchantClientContext);
  const auth = useContext(AuthContext);

  const [viewsTrigger, setViewsTrigger] = useState(Symbol());

  const teamViewsLoad = useLoad(
    (signal) =>
      auth ? getTeamViews(client, { signal }) : Promise.resolve(undefined),
    [auth, client, viewsTrigger],
  );

  const privateViewsLoad = useLoad(
    (signal) =>
      auth ? getUserViews(client, { signal }) : Promise.resolve(undefined),
    [auth, client, viewsTrigger],
  );

  const reloadViews = useHandler(() => setViewsTrigger(Symbol()));

  return (
    <ViewsContext.Provider
      value={{ team: teamViewsLoad.value, private: privateViewsLoad.value }}
    >
      <ReloadViewsContext.Provider value={reloadViews}>
        {children}
      </ReloadViewsContext.Provider>
    </ViewsContext.Provider>
  );
});
