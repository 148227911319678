// extracted by mini-css-extract-plugin
export var copyableField = "_ba-4";
export var customerBadge = "_ba-0";
export var customerInfoTitle = "_ba-2";
export var details = "_ba-1";
export var dropdownContainer = "_ba-5";
export var dropdownImage = "_ba-6";
export var editAssigneeForm = "_ba-a";
export var editButton = "_ba-3";
export var email = "_ba-7";
export var hidden = "_ba-d";
export var input = "_ba-8";
export var saveButton = "_ba-b";
export var shopifyIcon = "_ba-c";
export var username = "_ba-9";