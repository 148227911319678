import { dynamicGroupSchema } from "@redotech/redo-model/customers/customer-group-definition";
import { segmentSchema } from "@redotech/redo-model/marketing/segments/segment-zod-schema";
import { z } from "zod";

export const updateDynamicSegment = {
  input: z.object({
    _id: z.string(),
    name: z.string().optional(),
    conditions: segmentSchema.optional(),
  }),
  output: dynamicGroupSchema,
};
