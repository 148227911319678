import { AdvancedFilterType } from "@redotech/redo-model/views/advanced-filters/generic-advanced-filter-data";
import { NumberFilterOperator } from "@redotech/redo-model/views/advanced-filters/number-filter";
import { AdvancedTableFilter } from "@redotech/redo-web/advanced-filters/advanced-filter";
import BankNoteIcon from "@redotech/redo-web/arbiter-icon/bank-note-01.svg";

export const TotalPriceFilter: AdvancedTableFilter = {
  type: AdvancedFilterType.NUMBER,
  Icon: BankNoteIcon,
  min: 0,
  step: 0.01,
  prefix: "$",
  data: {
    name: "totalPrice",
    type: AdvancedFilterType.NUMBER,
    value: null,
    operator: NumberFilterOperator.GREATER_THAN,
  },
};
