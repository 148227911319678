import * as amplitude from "@amplitude/analytics-browser";
import { useRequiredContext } from "@redotech/react-util/context";
import { ExpandedConversation } from "@redotech/redo-model/conversation";
import { Button, ButtonTheme } from "@redotech/redo-web/button";
import { Modal, ModalSize } from "@redotech/redo-web/modal";
import { ProgressBar } from "@redotech/redo-web/progress-bar";
import { memo, useEffect, useState } from "react";
import { RedoMerchantClientContext } from "../../client/context";
import {
  bulkUpdateConversationsStatus,
  updateConversation,
} from "../../client/conversations";
import { getNextBatch } from "../utils";
import * as reopenTicketModalCss from "./reopen-ticket-modal.module.css";

export const ReopenTicketModal = memo(function ReopenTicketModal({
  open,
  setOpen,
  conversations,
  setActiveConversation,
  inDetailView,
  cleanupFn,
  selectAllInfo,
}: {
  open: boolean;
  setOpen: (val: boolean) => void;
  conversations: ExpandedConversation[];
  setActiveConversation: (val: ExpandedConversation) => void;
  inDetailView: boolean;
  cleanupFn?: (clearSelected?: boolean) => void;
  selectAllInfo?: {
    count: number;
    deselectedConversations: ExpandedConversation[];
    bulkActionIterator: AsyncIterator<{
      conversations: ExpandedConversation[];
    }>;
    setBlockRefresh: (val: boolean) => void;
  };
}) {
  const client = useRequiredContext(RedoMerchantClientContext);
  const [conversationsCanReopen, setConversationsCanReopen] = useState<
    ExpandedConversation[]
  >(conversations.filter((conversation) => conversation.status !== "open"));
  const [reopeningSelectAll, setReopeningSelectAll] = useState(false);
  const [numDone, setNumDone] = useState(0);

  const handleReopenTickets = async () => {
    if (conversations.length === 1) {
      void updateConversation(client, conversations[0], { status: "open" });
      amplitude.logEvent("reopen-conversation", {
        mode: "single",
        conversationIds: [conversations[0]._id],
        channels: [conversations[0].platform],
      });
      if (inDetailView) {
        setActiveConversation({ ...conversations[0], status: "open" });
      }
    } else if (conversations.length > 1) {
      const conversationIds = conversations
        .filter((conversation) => conversation.status !== "open")
        .map((conversation) => conversation._id);
      if (conversationIds.length > 0) {
        await bulkUpdateConversationsStatus(client, {
          conversationIds,
          status: "open",
        });
        const channels = [
          ...new Set(
            conversations.map((conversation) => conversation.platform),
          ),
        ];
        amplitude.logEvent("reopen-conversation", {
          mode: "multiple",
          conversationIds,
          channels,
        });
      }
    }
    cleanupFn && cleanupFn(true);
    setOpen(false);
  };

  const reopenBatch = async (): Promise<{
    batchSize: number;
    done: boolean;
  }> => {
    if (!selectAllInfo) return { batchSize: 0, done: true };
    const { done, value } = await getNextBatch(
      selectAllInfo.bulkActionIterator,
    );
    if (done) {
      return { batchSize: 0, done };
    }
    const conversationIds = value.conversations
      .filter(
        (conversation) =>
          conversation.status !== "open" &&
          !selectAllInfo.deselectedConversations.some(
            (deselectedConversation) =>
              deselectedConversation._id === conversation._id,
          ),
      )
      .map((conversation) => conversation._id);
    if (conversationIds.length > 0) {
      await bulkUpdateConversationsStatus(client, {
        conversationIds,
        status: "open",
      });
    }
    return { batchSize: value.conversations.length, done: false };
  };

  // This function synchronously reopens each page of conversations.
  // If speed becomes an issue, we could asynchronously reopen each page.
  const handleReopenSelectAll = async () => {
    if (!selectAllInfo) return;
    setReopeningSelectAll(true);
    selectAllInfo.setBlockRefresh(true);
    let done = false;
    do {
      const result = await reopenBatch();
      setNumDone((prevNumDone) => prevNumDone + result.batchSize);
      done = result.done;
    } while (!done);
    amplitude.logEvent("reopen-conversation", { mode: "all" });
    setReopeningSelectAll(false);
    selectAllInfo.setBlockRefresh(false);
    cleanupFn && cleanupFn(true);
    setOpen(false);
  };

  useEffect(() => {
    setConversationsCanReopen(
      conversations.filter((conversation) => conversation.status !== "open"),
    );
  }, [conversations]);

  const plural = conversationsCanReopen.length > 1 || selectAllInfo;

  const footer = (
    <div className={reopenTicketModalCss.actionButtonsContainer}>
      <Button onClick={() => setOpen(false)} theme={ButtonTheme.OUTLINED}>
        Cancel
      </Button>
      <Button
        onClick={async () => {
          selectAllInfo
            ? await handleReopenSelectAll()
            : await handleReopenTickets();
        }}
        theme={ButtonTheme.PRIMARY}
      >
        Yes, reopen ticket{plural ? "s" : ""}
      </Button>
    </div>
  );

  return (
    <Modal
      footer={reopeningSelectAll ? undefined : footer}
      onClose={() => {
        cleanupFn && cleanupFn();
        setOpen(false);
      }}
      open={open}
      showHeaderBorder={false}
      size={ModalSize.SMALL}
      title={
        reopeningSelectAll
          ? "Reopening tickets"
          : `Reopen ${
              selectAllInfo
                ? "all selected "
                : plural
                  ? conversationsCanReopen.length + " "
                  : ""
            }ticket${plural ? "s" : ""}?`
      }
    >
      {reopeningSelectAll ? (
        <ProgressBar
          value={(numDone / (selectAllInfo?.count || numDone)) * 100}
        />
      ) : (
        <div className={reopenTicketModalCss.actionModalContentContainer}>
          <div>
            {plural ? "These tickets" : "This ticket"} will show up in your open
            tickets list.
          </div>
        </div>
      )}
    </Modal>
  );
});
