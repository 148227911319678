// extracted by mini-css-extract-plugin
export var acitonButtons = "_84-0";
export var block = "_84-1";
export var blockContent = "_84-7";
export var blockDescription = "_84-8";
export var blockIcon = "_84-9";
export var blockTitle = "_84-6";
export var border = "_84-a";
export var center = "_84-k";
export var edge = "_84-b";
export var edgeLabel = "_84-c";
export var edgePath = "_84-d";
export var error = "_84-e";
export var flowchart = "_84-f";
export var flowchartContent = "_84-g";
export var highlighted = "_84-3";
export var hover = "_84-4";
export var icon = "_84-h";
export var left = "_84-l";
export var navButton = "_84-i";
export var navButtons = "_84-j";
export var right = "_84-m";
export var selected = "_84-2";
export var small = "_84-5";