import { RouteObject } from "react-router-dom";

export const conversionRoutes: RouteObject[] = [
  {
    handle: { breadcrumb: "Conversion", breadcrumbNavigate: false },
    async lazy() {
      const { Page } = await import("../app/page");
      return { element: <Page /> };
    },
    children: [
      {
        path: "live-view",
        handle: { breadcrumb: "Live view" },
        async lazy() {
          const { LiveViewPage } = await import("./live-view/live-view-page");
          return { Component: LiveViewPage };
        },
      },
      {
        path: "ab-tests",
        handle: { breadcrumb: "A/B tests" },
        async lazy() {
          const { AbTestsPage } = await import("./ab-tests/ab-tests-page");
          return { Component: AbTestsPage };
        },
      },
      {
        path: "shipping-method-collections",
        handle: { breadcrumb: "Shipping method collections" },
        async lazy() {
          const { ShippingMethodCollectionsPage } = await import(
            "./shipping-method-collections/shipping-method-collections-page"
          );
          return { Component: ShippingMethodCollectionsPage };
        },
      },
    ],
  },
];
