import { PillTheme } from "@redotech/redo-model/pill-theme";
import { Flex } from "@redotech/redo-web/flex";
import { Text } from "@redotech/redo-web/text";
import { TextInput } from "@redotech/redo-web/text-input";
import { memo } from "react";
import { PillColorPicker } from "./conversation-tag-input-color-picker";
import { ConversationTagPillThemes } from "./conversation-tag-pill";

export const TagInputFields = memo(function TagInputFields({
  name,
  setName,
  pillTheme,
  setPillTheme,
  showNameError,
  disableNameEdit = false,
}: {
  name: string;
  setName(name: string): void;
  showNameError: boolean;
  pillTheme: PillTheme;
  setPillTheme(pillTheme: PillTheme): void;
  disableNameEdit?: boolean;
}) {
  return (
    <Flex dir="column" p="sm">
      <Flex dir="column">
        <Text fontSize="md" fontWeight="semibold">
          Tag name
        </Text>
        <TextInput disabled={disableNameEdit} onChange={setName} value={name} />
        {showNameError && (
          <Text
            fontSize="xs"
            pl="sm"
            textColor="error"
          >{`A tag with the name ${name} already exists`}</Text>
        )}
      </Flex>
      <Flex dir="column" pt="md">
        <Text fontSize="md" fontWeight="semibold">
          Tag color
        </Text>
        <PillColorPicker
          options={ConversationTagPillThemes}
          setValue={setPillTheme}
          value={pillTheme}
        />
      </Flex>
    </Flex>
  );
});
