import { genericMemo } from "@redotech/react-util/component";
import { useScrolled } from "@redotech/react-util/scroll";
import * as classNames from "classnames";
import * as React from "react";
import { CSSProperties, memo, useEffect, useRef } from "react";
import { Flex } from "../../flex";
import { Spinner } from "../../spinner";
import { RowClickHandler } from "../../table";
import { Text } from "../../text";
import { RedoTableCellProps } from "./redo-table-cells";
import * as redoTableCellsCss from "./redo-table-cells.module.css";
import { RedoTableRow } from "./redo-table-row";
import * as redoTableCss from "./redo-table.module.css";

export enum RedoTableSize {
  SMALL = "small",
  MEDIUM = "medium",
}

export enum RedoTableColumnWidthType {
  FIXED = "fixed",
  HUG = "hug",
  FILL = "fill",
}

export type RedoTableColumnWidthMode =
  | { type: RedoTableColumnWidthType.FIXED; width: CSSProperties["width"] }
  | { type: RedoTableColumnWidthType.HUG }
  | {
      type: RedoTableColumnWidthType.FILL;
      /** Will map 1 -> "1fr" https://developer.mozilla.org/en-US/docs/Web/CSS/CSS_grid_layout/Basic_concepts_of_grid_layout#the_fr_unit*/
      relativeLength?: number;
      minLength?: CSSProperties["width"];
    };

export enum RedoTableColumnAlignment {
  LEFT = "left",
  RIGHT = "right",
  CENTER = "center",
}

export type RedoTableColumn<DATA> = {
  key?: string;
  widthMode?: RedoTableColumnWidthMode;
  renderHeaderCell: () => React.ReactNode;
  renderNormalCell: (props: RedoTableCellProps<DATA>) => React.ReactNode;
  alignment?: RedoTableColumnAlignment;
};

export type OnVisibleHandlers = {
  setTopVisible: (topIsVisible: boolean) => void;
  setBottomVisible: (bottomIsVisible: boolean) => void;
};

export enum HeaderColor {
  WHITE = "white",
  GREY = "grey",
}

export interface RedoTableLayout<DATA> {
  columns: RedoTableColumn<DATA>[];
  headerColor?: HeaderColor;
}

export interface RedoTableProps<DATA> {
  size?: RedoTableSize;
  layout: RedoTableLayout<DATA>;
  rowsOfData: DATA[];
  onRowClick?: (
    row: DATA,
    rowIndex: number,
    sourceEvent: React.MouseEvent,
  ) => void;
  onContextMenu?: RowClickHandler<DATA>;
  onRowHovered?(record?: DATA): void;
  rowsSelected?: boolean[];
  onVisibleHandlers?: OnVisibleHandlers;
  emptyStateContent?: React.ReactNode;
  loadingStateContent?: React.ReactNode;
  additionalClasses?: string;
}

export const tableDimensionToGridTemplateStyles = (
  dim: RedoTableColumnWidthMode,
) => {
  return dim.type === RedoTableColumnWidthType.FIXED
    ? typeof dim.width === "number"
      ? `minmax(${dim.width}px, 0fr)`
      : `minmax(${dim.width}, 0fr)`
    : dim.type === RedoTableColumnWidthType.HUG
      ? `min-content`
      : `minmax(${dim.relativeLength || 0}px, 1fr)`;
};

/**
 * Arbiter table https://www.figma.com/design/iZHj2I36zd9i8nRbWKw4ZK/%E2%9D%96-Arbiter?node-id=214-0&node-type=canvas&t=TZXhtoU5Ez2uwaXW-0
 *
 * Req. reading: https://developer.mozilla.org/en-US/docs/Web/CSS/CSS_grid_layout/Basic_concepts_of_grid_layout
 *
 * To be as presentational as possible, the table knows as little as possible.
 * Wrap with your own logical layer to manage backing row DATA, request/fetch new data upon table interactions, implement sorts, etc...
 * For extra features e.g. paginated footer, use kits that wrap this.
 */
export const RedoTable = genericMemo(function RedoTable<DATA>({
  size = RedoTableSize.SMALL,
  layout: { columns, headerColor = HeaderColor.GREY },
  rowsOfData,
  onRowClick,
  onContextMenu,
  onRowHovered,
  rowsSelected,
  onVisibleHandlers,
  emptyStateContent = null,
  loadingStateContent = null,
  additionalClasses,
}: RedoTableProps<DATA>) {
  /** req. reading: https://developer.mozilla.org/en-US/docs/Web/CSS/grid-template-columns */
  const styleForGridColTemplate = columns
    .map((column) =>
      tableDimensionToGridTemplateStyles(
        column.widthMode || { type: RedoTableColumnWidthType.FILL },
      ),
    )
    .join(" ");

  const styleForGridRow = sizeToTemplateRowHeight[size];
  const styleForHeaderRow = sizeToTemplateHeaderRowHeight[size];

  const scrollAreaRef = useRef<HTMLDivElement>(null);
  const scrolled = useScrolled(scrollAreaRef.current);
  useEffect(() => {
    if (!scrollAreaRef.current) {
      return;
    }
    if (scrolled.bottom) {
      onVisibleHandlers?.setBottomVisible(true);
    } else {
      onVisibleHandlers?.setBottomVisible(false);
    }
    if (scrolled.top) {
      onVisibleHandlers?.setTopVisible(true);
    } else {
      onVisibleHandlers?.setTopVisible(false);
    }
  }, [scrolled, onVisibleHandlers]);

  return (
    <>
      <div
        className={classNames(redoTableCss.table, additionalClasses)}
        ref={scrollAreaRef}
        role="table"
        style={{
          gridTemplateColumns: styleForGridColTemplate,
          gridTemplateRows: styleForHeaderRow,
          gridAutoRows: styleForGridRow,
          height: rowsOfData.length === 0 ? undefined : "100%",
        }}
      >
        <div
          className={classNames(redoTableCss.rowWrapper)}
          key="header"
          role="row"
        >
          {columns.map((column, columnIndex) => (
            <div
              className={classNames(
                redoTableCellsCss.cell,
                redoTableCss.headerCell,
                headerColorStyle[headerColor],
              )}
              key={`header-${column.key ?? columnIndex}`}
              role="columnheader"
              style={{ height: styleForHeaderRow }}
            >
              <column.renderHeaderCell />
            </div>
          ))}
        </div>
        {rowsOfData.length > 0 && (
          <div className={redoTableCss.rowWrapper} role="rowgroup">
            {rowsOfData.map((row, rowIndex) => (
              <RedoTableRow<DATA>
                columns={columns}
                key={`row-${rowIndex}`}
                onContextMenu={onContextMenu}
                onRowClick={onRowClick}
                onRowHovered={onRowHovered}
                row={row}
                rowIndex={rowIndex}
                rowSelected={rowsSelected?.[rowIndex]}
              />
            ))}
          </div>
        )}
      </div>
      {rowsOfData.length === 0 && emptyStateContent}
      {loadingStateContent}
    </>
  );
});

export const sizeToTemplateRowHeight: Record<
  RedoTableSize,
  CSSProperties["height"]
> = { [RedoTableSize.SMALL]: "48px", [RedoTableSize.MEDIUM]: "72px" };

export const sizeToTemplateHeaderRowHeight: Record<
  RedoTableSize,
  CSSProperties["height"]
> = { [RedoTableSize.SMALL]: "36px", [RedoTableSize.MEDIUM]: "44px" };

export const headerColorStyle: Record<HeaderColor, string | undefined> = {
  [HeaderColor.WHITE]: redoTableCss.white,
  [HeaderColor.GREY]: undefined,
};

export const RedoTableLoadingStateContent = memo(
  function RedoTableLoadingStateContent() {
    return (
      <Flex align="center" grow={1} justify="center" py="3xl">
        <Flex className={redoTableCss.loadingSpinnerContainer}>
          <Spinner />
        </Flex>
      </Flex>
    );
  },
);

export const RedoTableEmptyStateContent = memo(
  function RedoTableEmptyStateContent({ text }: { text: string }) {
    return (
      <Flex align="center" grow={1} justify="center" py="3xl">
        <Text fontSize="sm" fontWeight="regular" textColor="primary">
          {text}
        </Text>
      </Flex>
    );
  },
);
