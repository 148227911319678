import { useRequiredContext } from "@redotech/react-util/context";
import { useHandler } from "@redotech/react-util/hook";
import { Provider } from "@redotech/redo-model/order";
import { Product, Return } from "@redotech/redo-model/return";
import { Team } from "@redotech/redo-model/team";
import {
  ButtonPlacement,
  RedoModal,
  RedoModalSize,
} from "@redotech/redo-web/arbiter-components/modal/redo-modal";
import { Divider } from "@redotech/redo-web/divider";
import { Flex } from "@redotech/redo-web/flex";
import { assertNever } from "@redotech/util/type";
import { Fragment, memo, useMemo, useState } from "react";
import { RedoMerchantRpcClientContext } from "../../app/redo-merchant-rpc-client-provider";
import { EditItemDisposition } from "../edit-item-disposition/edit-item-disposition";
import {
  ProductItemSummaryRow,
  ProductItemSummaryRowRestockSwitch,
} from "./common/product-item-summary-row";
import {
  getDefaultItemsToRestock,
  ItemRestockState,
  ItemRestockUpdateParams,
  setItemRestockUpdated,
} from "./common/util";
import * as itemDispositionModalCss from "./item-disposition-modal.module.css";

type ItemDisposition = {
  grade: string | undefined;
  outcome: string | undefined;
  notes: string | undefined;
};
type ItemsWithDisposition = Record<string, ItemDisposition>;

type HandleItemDispositionUpdatedParams = {
  itemId: string;
  grade: string | undefined;
  outcome: string | undefined;
  notes: string | undefined;
};

type HandleItemRestockUpdatedParams = { itemId: string; value: boolean };

const ItemToDisposition = memo(function Item({
  gradeOptions,
  outcomeOptions,
  itemId,
  item,
  itemDisposition,
  handleItemDispositionUpdated,
  itemRestock,
  handleItemRestockUpdated,
  return_,
  itemPrice,
}: {
  gradeOptions: string[];
  outcomeOptions: string[];
  itemId: string;
  item: Product;
  itemDisposition: ItemDisposition;
  handleItemDispositionUpdated: (
    input: HandleItemDispositionUpdatedParams,
  ) => void;
  itemRestock: ItemRestockUpdateParams;
  handleItemRestockUpdated: (input: HandleItemRestockUpdatedParams) => void;
  return_: Return;
  itemPrice: number;
}) {
  const editItemDispositionGrade = useMemo(() => {
    return {
      options: gradeOptions,
      selectedOption: itemDisposition.grade,
      setSelectedOption: (grade: string | undefined) =>
        handleItemDispositionUpdated({
          itemId,
          grade,
          outcome: itemDisposition.outcome,
          notes: itemDisposition.notes,
        }),
    };
  }, [gradeOptions, itemDisposition, handleItemDispositionUpdated]);

  const editItemDispositionOutcome = useMemo(() => {
    return {
      options: outcomeOptions,
      selectedOption: itemDisposition.outcome,
      setSelectedOption: (outcome: string | undefined) =>
        handleItemDispositionUpdated({
          itemId,
          grade: itemDisposition.grade,
          outcome,
          notes: itemDisposition.notes,
        }),
    };
  }, [outcomeOptions, itemDisposition, handleItemDispositionUpdated]);

  const editItemDispositionNotes = useMemo(() => {
    return {
      value: itemDisposition.notes ?? "",
      setValue: (notes: string) =>
        handleItemDispositionUpdated({
          itemId,
          grade: itemDisposition.grade,
          outcome: itemDisposition.outcome,
          notes,
        }),
    };
  }, [itemDisposition, handleItemDispositionUpdated]);

  const productItemSummaryRowRestockSwitch: ProductItemSummaryRowRestockSwitch =
    useMemo(() => {
      switch (itemRestock.itemRestockState) {
        case ItemRestockState.ABLE_TO_RESTOCK:
          return {
            itemRestockState: ItemRestockState.ABLE_TO_RESTOCK,
            value: itemRestock.value,
            setValue: (value: boolean) =>
              handleItemRestockUpdated({ itemId, value }),
          };
        case ItemRestockState.ALREADY_RESTOCKED:
          return { itemRestockState: ItemRestockState.ALREADY_RESTOCKED };
        case ItemRestockState.NOT_RESTOCKABLE:
          return { itemRestockState: ItemRestockState.NOT_RESTOCKABLE };
        default:
          assertNever(itemRestock);
      }
    }, [itemRestock, handleItemRestockUpdated, itemId]);

  return (
    <Flex dir="column">
      <ProductItemSummaryRow
        item={item}
        refundAmount={itemPrice}
        restockSwitch={productItemSummaryRowRestockSwitch}
        return_={return_}
      />
      <EditItemDisposition
        grade={editItemDispositionGrade}
        notes={editItemDispositionNotes}
        outcome={editItemDispositionOutcome}
      />
    </Flex>
  );
});

export const ItemDispositionModal = memo(function ItemDispositionModal({
  open,
  closeModal,
  return_,
  orderProvider,
  returnItemsToPrice,
  team,
}: {
  open: boolean;
  closeModal: (_reload?: boolean) => void;
  return_: Return;
  orderProvider: Provider;
  returnItemsToPrice: Record<string, number>;
  team: Team;
}) {
  const client = useRequiredContext(RedoMerchantRpcClientContext);

  const [itemsWithDisposition, setItemsWithDisposition] =
    useState<ItemsWithDisposition>(() => {
      return return_.products.reduce<ItemsWithDisposition>((acc, item) => {
        acc[item._id] = {
          grade: item.merchant_grade,
          outcome: item.merchant_outcome,
          notes: item.merchant_notes,
        };
        return acc;
      }, {});
    });

  const handleItemDispositionUpdated = useHandler(
    ({ itemId, grade, outcome, notes }: HandleItemDispositionUpdatedParams) => {
      setItemsWithDisposition((prevItemsWithDisposition) => {
        return {
          ...prevItemsWithDisposition,
          [itemId]: { grade, outcome, notes },
        };
      });
    },
  );

  const [itemsToRestock, setItemsToRestock] = useState<
    Record<string, ItemRestockUpdateParams>
  >(() => {
    return getDefaultItemsToRestock(return_, team, orderProvider);
  });

  const handleItemRestockUpdated = useHandler(
    ({ itemId, value }: HandleItemRestockUpdatedParams) => {
      setItemsToRestock((prevItemsToRestock) =>
        setItemRestockUpdated(prevItemsToRestock, itemId, value),
      );
    },
  );

  const handleSaveItemDisposition = useHandler(async () => {
    const _itemsToRestock = Object.entries(itemsToRestock).reduce<
      Record<string, boolean>
    >((acc, [itemId, itemRestockUpdateParams]) => {
      if (
        itemRestockUpdateParams.itemRestockState ===
        ItemRestockState.ABLE_TO_RESTOCK
      ) {
        acc[itemId] = itemRestockUpdateParams.value;
      }
      return acc;
    }, {});
    await client.setReturnItemDisposition({
      returnId: return_._id,
      itemsWithDisposition,
      itemsToRestock: _itemsToRestock,
    });
    closeModal(true);
  });

  const allItemsHaveDisposition = useMemo(() => {
    return Object.values(itemsWithDisposition).every(
      (itemDisposition) => itemDisposition.grade && itemDisposition.outcome,
    );
  }, [itemsWithDisposition]);

  return (
    <RedoModal
      buttonPlacement={ButtonPlacement.TIGHT}
      contentScrollable
      footerBorder
      headerBorder
      isOpen={open}
      modalSize={RedoModalSize.SMALL}
      onModalCloseRequested={() => closeModal()}
      primaryButton={{
        text: "Save",
        onClick: handleSaveItemDisposition,
        disabled: allItemsHaveDisposition
          ? false
          : "Some items are missing disposition",
      }}
      secondaryButton={{ text: "Cancel", onClick: () => closeModal() }}
      subtitle="Add the disposition details for each item"
      title="Item disposition"
    >
      <Flex dir="column" pb="xs" pt="xs">
        {return_.products.map((item, index) => {
          return (
            <Fragment key={item._id}>
              {index !== 0 && (
                <div className={itemDispositionModalCss.divider}>
                  <Divider />
                </div>
              )}
              <ItemToDisposition
                gradeOptions={team.settings.returnItemDisposition.gradeOptions}
                handleItemDispositionUpdated={handleItemDispositionUpdated}
                handleItemRestockUpdated={handleItemRestockUpdated}
                item={item}
                itemDisposition={itemsWithDisposition[item._id]}
                itemId={item._id}
                itemPrice={returnItemsToPrice[item._id]}
                itemRestock={itemsToRestock[item._id]}
                key={item._id}
                outcomeOptions={
                  team.settings.returnItemDisposition.outcomeOptions
                }
                return_={return_}
              />
            </Fragment>
          );
        })}
      </Flex>
    </RedoModal>
  );
});
