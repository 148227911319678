// extracted by mini-css-extract-plugin
export var dark = "_6f-8";
export var iconHighlight = "_6f-0";
export var lg = "_6f-6";
export var light = "_6f-1";
export var md = "_6f-5";
export var modern = "_6f-9";
export var sm = "_6f-4";
export var xl = "_6f-7";
export var xs = "_6f-3";
export var xxs = "_6f-2";