// extracted by mini-css-extract-plugin
export var buttons = "_5b-0";
export var buttonsActive = "_5b-h";
export var cancelConfirm = "_5b-1";
export var closeButton = "_5b-2";
export var column = "_5b-3";
export var container = "_5b-4";
export var detailHeader = "_5b-6";
export var detailTitle = "_5b-7";
export var detailsContent = "_5b-5";
export var header = "_5b-8";
export var headerWithClose = "_5b-9";
export var icon = "_5b-a";
export var infoIcon = "_5b-c";
export var informationContainer = "_5b-b";
export var sideDrawer = "_5b-d";
export var sideDrawerOpen = "_5b-e";
export var stickyFooter = "_5b-f";
export var typeOption = "_5b-g";