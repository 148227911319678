import { useRequiredContext } from "@redotech/react-util/context";
import { useHandler } from "@redotech/react-util/hook";
import { useLoad } from "@redotech/react-util/load";
import { WidgetClient, getShippingFee } from "@redotech/redo-api-client/widget";
import { Order } from "@redotech/redo-model/order";
import type { Return, Shipment } from "@redotech/redo-model/return";
import { Payer } from "@redotech/redo-model/return-flow";
import { ReturnStatus } from "@redotech/redo-model/return-status";
import { ReturnTotalsCalculator } from "@redotech/redo-model/return-totals-calculator";
import { Team } from "@redotech/redo-model/team";
import { RedoBadge } from "@redotech/redo-web/arbiter-components/badge/redo-badge";
import {
  RedoButton,
  RedoButtonHierarchy,
} from "@redotech/redo-web/arbiter-components/buttons/redo-button";
import { RedoButtonDropdown } from "@redotech/redo-web/arbiter-components/buttons/redo-dropdown-button";
import { RedoCommandMenu } from "@redotech/redo-web/arbiter-components/command-menu/redo-command-menu";
import { RedoExternalLink } from "@redotech/redo-web/arbiter-components/links/redo-external-link";
import CalendarIcon from "@redotech/redo-web/arbiter-icon/calendar.svg";
import Download02 from "@redotech/redo-web/arbiter-icon/download-02.svg";
import { ButtonSize, IconButton } from "@redotech/redo-web/button";
import * as cardCss from "@redotech/redo-web/card.module.css";
import { RedoClientContext } from "@redotech/redo-web/client";
import { Flex } from "@redotech/redo-web/flex";
import ChevronLeftIcon from "@redotech/redo-web/icon-old/chevron-left.svg";
import ChevronRightIcon from "@redotech/redo-web/icon-old/chevron-right.svg";
import MailIcon from "@redotech/redo-web/icon-old/mail.svg";
import { Text } from "@redotech/redo-web/text";
import { Event, Timeline } from "@redotech/redo-web/timeline";
import { memo, useContext, useMemo, useState } from "react";
import { TeamContext } from "../../app/team";
import { RedoMerchantClientContext } from "../../client/context";
import { getOrder } from "../../client/order";
import { CancelPickupModal } from "../return-modals/cancel-pickup-modal";
import { ResendModal } from "../return-modals/resend-modal";
import { SchedulePickupModal } from "../return-modals/schedule-pickup-modal";
import { shipmentStatusName } from "../util";
import { AddressDisplay } from "./address-display";
import * as returnPageRightPanelCss from "./return-page-right-panel.module.css";
import * as shippingCss from "./shipping-card.module.css";
import { shippingStatusToBadgeColor } from "./shipping-status-badge";

enum PickupStates {
  CANCELLED = "cancelled",
  PAID_FOR = "paid_for",
  NOT_PAID_FOR_CAN_DEDUCT = "not_paid_for_can_deduct",
  NOT_PAID_FOR_CANNOT_DEDUCT = "not_paid_for_cannot_deduct",
  INELIGIBLE = "ineligible",
}

export const ShippingCard = memo(function ShippingCard({
  reload,
  return: return_,
  order,
  isRepairCard,
}: {
  reload(): void;
  return?: Return;
  order?: Order;
  isRepairCard?: boolean;
}) {
  const [resendOpen, setResendOpen] = useState(false);
  const [schedulePickupModalOpen, setSchedulePickupModalOpen] = useState(false);
  const team = useContext(TeamContext);

  const handleResend = useHandler(() => {
    setResendOpen(true);
  });
  const handleSchedulePickup = useHandler(() => {
    setSchedulePickupModalOpen(true);
  });
  const client = useRequiredContext(RedoClientContext);
  const merchantClient = useRequiredContext(RedoMerchantClientContext);
  const widgetClient = return_
    ? new WidgetClient(client, return_.team.widget_slug)
    : undefined;

  const pickupInfo = useLoad(async () => {
    if (!team?.widget_slug || !return_) {
      return { pickupState: PickupStates.INELIGIBLE }; // we can't get the rate, so say ineligible
    }
    if (return_?.pickup) {
      if (return_.pickup.status === "pending_refund") {
        return { pickupState: PickupStates.CANCELLED };
      }
      return {
        pickupState: PickupStates.PAID_FOR,
        pickupFee: return_?.pickupMetrics?.rate,
      };
    }
    const { pickupFee } = widgetClient
      ? await getShippingFee(widgetClient, {
          products: return_.products.map((product) => ({
            id: product.id,
            quantity: product.quantity,
            line_item_id: +product.line_item_id,
          })),
          address: return_.shipping_address
            ? {
                city: return_.shipping_address.city,
                country: return_.shipping_address.country,
                street1: return_.shipping_address.address1,
                street2: return_.shipping_address.address2,
                zip: return_.shipping_address.zip,
                state: return_.shipping_address.province,
              }
            : null,
          payer: Payer.CUSTOMER,
          merchantAddress: return_.merchant_address || null,
        })
      : undefined;
    if (!pickupFee) {
      return { pickupState: PickupStates.INELIGIBLE, pickupFee };
    }
    const order = await getOrder(merchantClient, return_.orders[0].order);
    const returnTotalsCalculator = new ReturnTotalsCalculator({
      return_: {
        ...return_,
        totals: { ...return_.totals, fee: return_.totals.fee + pickupFee },
      },
      team,
      order,
    });
    const canDeduct =
      returnTotalsCalculator.getTotalsForAllProducts().shippingFee === 0;
    if (canDeduct) {
      return { pickupState: PickupStates.NOT_PAID_FOR_CAN_DEDUCT, pickupFee };
    }
    return { pickupState: PickupStates.NOT_PAID_FOR_CANNOT_DEDUCT, pickupFee };
  }, [return_]);

  const handleResendLabelModalClose = useHandler(() => setResendOpen(false));
  const handleSchedulePickupModalClose = useHandler(() => {
    setSchedulePickupModalOpen(false);
    reload();
  });

  const [cancelPickupModalOpen, setCancelPickupModalOpen] = useState(false);
  const handleCancelPickupModalClose = useHandler(() =>
    setCancelPickupModalOpen(false),
  );
  const handleCancelPickup = useHandler(async () => {
    setCancelPickupModalOpen(true);
    reload();
  });

  const [shipmentIndex, setShipmentIndex] = useState(0);
  const shipments = useMemo(() => {
    if (!return_) {
      return [];
    }
    if (isRepairCard) {
      if (return_.status === ReturnStatus.OPEN) {
        return [];
      } else if (return_.status === ReturnStatus.COMPLETE) {
        return return_.reShipments ?? [];
      }
    }
    let shipments: (Shipment | undefined)[] = [];
    if (return_.shipmentGroups?.length) {
      shipments = return_.shipmentGroups.map((group) =>
        return_.shipments?.find((s) => s.shipmentGroupID === group.id),
      );
    } else if (return_.shipment) {
      shipments = [
        {
          ...return_.shipment,
          form_label: return_.form_label,
          postage_label: return_.postage_label,
        },
      ];
    }

    return shipments;
  }, [return_]);

  if (!return_) {
    return null;
  }

  return (
    <>
      {widgetClient ? (
        <Flex
          className={returnPageRightPanelCss.cardPadding}
          dir="column"
          gap="xl"
        >
          <Flex align="center" justify="space-between">
            <Text fontSize="xs" fontWeight="medium" textColor="tertiary">
              {isRepairCard ? "Re-shipping info" : "Return shipping"}
            </Text>
            {shipments.length > 1 ? (
              <Flex align="center" gap="xxs">
                <IconButton
                  disabled={shipmentIndex === 0}
                  onClick={() => setShipmentIndex(shipmentIndex - 1)}
                  size={ButtonSize.SMALL}
                >
                  <ChevronLeftIcon
                    className={shippingCss.leftRightShippingChevron}
                  />
                </IconButton>
                <Text fontSize="xs" fontWeight="medium" textColor="tertiary">
                  {shipmentIndex + 1} of {shipments.length}
                </Text>
                <IconButton
                  disabled={shipmentIndex === shipments.length - 1}
                  onClick={() => setShipmentIndex(shipmentIndex + 1)}
                  size={ButtonSize.SMALL}
                >
                  <ChevronRightIcon
                    className={shippingCss.leftRightShippingChevron}
                  />
                </IconButton>
              </Flex>
            ) : null}
          </Flex>
          {shipments.length === 0 && isRepairCard ? (
            <Text fontSize="xs" fontWeight="regular">
              This return needs to be processed before a reverse label can be
              generated.
            </Text>
          ) : (
            <ShipmentDetails
              handleCancelPickup={handleCancelPickup}
              handleResend={handleResend}
              handleSchedulePickup={handleSchedulePickup}
              happyReturnsData={return_.happyReturnsData}
              isRepairCard={isRepairCard}
              pickupState={
                pickupInfo.pending || pickupInfo.error || !pickupInfo.value
                  ? PickupStates.INELIGIBLE
                  : pickupInfo.value.pickupState
              }
              shipment={shipments[shipmentIndex]}
              zipCode={return_.shipping_address?.zip}
            />
          )}
        </Flex>
      ) : undefined}
      {resendOpen && (
        <ResendModal
          onClose={handleResendLabelModalClose}
          open={resendOpen}
          reload={reload}
          return={return_}
        />
      )}
      {schedulePickupModalOpen &&
      !!team &&
      !!order &&
      !!pickupInfo.value &&
      !isRepairCard ? (
        <SchedulePickupModal
          canDeduct={canDeduct(
            team,
            return_,
            order,
            pickupInfo.value.pickupFee,
          )}
          isReschedule={pickupInfo.value?.pickupState === PickupStates.PAID_FOR}
          onClose={handleSchedulePickupModalClose}
          open={schedulePickupModalOpen}
          pickupFee={pickupInfo.value?.pickupFee}
          return={return_}
        />
      ) : undefined}
      {cancelPickupModalOpen && !!widgetClient && !isRepairCard ? (
        <CancelPickupModal
          onClose={handleCancelPickupModalClose}
          open={cancelPickupModalOpen}
          reload={reload}
          returnId={return_._id}
          widgetClient={widgetClient}
        />
      ) : undefined}
    </>
  );
});

const canDeduct = (
  team: Team,
  return_: Return,
  order: Order,
  pickupFee: number,
) => {
  const returnTotalsCalculator = new ReturnTotalsCalculator({
    return_,
    order,
    team,
  });
  const { totalStoreCredit, totalRefund } =
    returnTotalsCalculator.getTotalsForAllProducts();
  if (totalStoreCredit > pickupFee && team.settings.deductLabelFromCredit) {
    return true;
  }
  if (totalRefund > pickupFee && team.settings.deductLabelFromRefund) {
    return true;
  }
  return false;
};

const ShipmentDetails = memo(function ShipmentDetails({
  handleResend,
  handleSchedulePickup,
  handleCancelPickup,
  shipment,
  pickupState,
  isRepairCard,
  happyReturnsData,
  zipCode,
}: {
  handleResend: () => void;
  handleSchedulePickup: () => void;
  shipment?: Shipment;
  happyReturnsData?: Return["happyReturnsData"];
  handleCancelPickup: () => void;
  pickupState: PickupStates;
  isRepairCard?: boolean;
  zipCode?: string | null;
}) {
  const handleDownload = useHandler(
    (document: "label" | "raw_label" | "qr_code" | "commercial_invoice") => {
      if (
        document === "raw_label" &&
        shipment?._shipment?.postage_label.label_url
      ) {
        open(shipment._shipment.postage_label.label_url, "_blank");
      } else if (
        document === "commercial_invoice" &&
        shipment?._shipment?.forms?.find(
          (form: any) => form.form_type === "commercial_invoice",
        )?.form_url
      ) {
        open(
          shipment?._shipment?.forms?.find(
            (form: any) => form.form_type === "commercial_invoice",
          )?.form_url,
          "_blank",
        );
      } else {
        open(
          document === "qr_code"
            ? shipment!.form_label
            : shipment!.postage_label,
          "_blank",
        );
      }
    },
  );

  const events: Event[] = useMemo(
    () =>
      (shipment?._shipment.tracker?.tracking_details || []).map((detail) => ({
        at: new Date(detail.datetime).toTemporalInstant(),
        detail: detail.tracking_location.city,
        message: detail.message,
      })),
    [shipment],
  );

  const downloadOptions = [
    ...(shipment?.postage_label
      ? [{ text: "Label", onClick: () => handleDownload("label") }]
      : []),
    ...(shipment?.form_label && pickupState !== PickupStates.PAID_FOR
      ? [{ text: "QR Code", onClick: () => handleDownload("qr_code") }]
      : []),
    ...(shipment?._shipment?.forms?.find(
      (form) => form.form_type === "commercial_invoice",
    )?.form_url
      ? [
          {
            text: "Invoice",
            onClick: () => handleDownload("commercial_invoice"),
          },
        ]
      : []),
  ];
  const [downloadDropdownButtonRef, setDownloadDropdownButtonRef] =
    useState<HTMLButtonElement | null>(null);
  const [downloadDropdownOpen, setDownloadDropdownOpen] =
    useState<boolean>(false);

  return (
    <>
      {happyReturnsData ? (
        <Flex dir="column" gap="xs">
          <Text fontSize="xs" fontWeight="medium">
            Happy Returns
          </Text>
          {happyReturnsData.qrCode ? (
            <RedoExternalLink text="QR Code" url={happyReturnsData.qrCode} />
          ) : undefined}
          {happyReturnsData.retailerId && zipCode ? (
            <RedoExternalLink
              text="Return Bar Locations"
              url={`https://locations.happyreturns.com/?address=${zipCode}&has_qr_code=true&retailer=${happyReturnsData.retailerId}`}
            />
          ) : undefined}
        </Flex>
      ) : (
        <>
          <Flex dir="column" gap="xs">
            <Text fontSize="xs" fontWeight="medium">
              Status
            </Text>
            {shipment?._shipment.tracker?.status && (
              <RedoBadge
                color={shippingStatusToBadgeColor(
                  shipment._shipment.tracker?.status,
                )}
                text={shipmentStatusName(shipment._shipment.tracker?.status)}
              />
            )}
          </Flex>
          <Flex dir="column" gap="xs">
            <Text fontSize="xs" fontWeight="medium">
              Timeline
            </Text>
            <Timeline events={events} useText />
          </Flex>
          <Flex dir="column" gap="xs">
            <Text fontSize="xs" fontWeight="medium">
              Tracking number
            </Text>
            <div className={cardCss.body}>
              {shipment?._shipment && (
                <RedoExternalLink
                  text={`${shipment._shipment.tracker?.carrier} ${shipment._shipment.tracking_code}`}
                  url={shipment._shipment.tracker?.public_url}
                />
              )}
            </div>
          </Flex>
          <Flex dir="column" gap="xs">
            <Text fontSize="xs" fontWeight="medium">
              Shipping To
            </Text>
            {shipment?.isReturn
              ? shipment?.fromAddress && (
                  <AddressDisplay address={shipment.fromAddress} />
                )
              : shipment?.toAddress && (
                  <AddressDisplay address={shipment.toAddress} />
                )}
          </Flex>
        </>
      )}
      <Flex dir="column" gap="xs">
        <Text fontSize="xs" fontWeight="medium">
          Labels
        </Text>
        <Flex dir="column">
          {(shipment?.form_label || shipment?.postage_label) && (
            <RedoButtonDropdown
              dropdownOpen={downloadDropdownOpen}
              hierarchy={RedoButtonHierarchy.SECONDARY}
              IconLeading={Download02}
              mainButtonStretchHorizontal
              onClick={() => setDownloadDropdownOpen(true)}
              ref={setDownloadDropdownButtonRef}
              refSetToContainer
              setDropdownOpen={setDownloadDropdownOpen}
              text="Download"
            >
              <RedoCommandMenu
                anchor={downloadDropdownButtonRef}
                fitToAnchor
                items={downloadOptions}
                open={downloadDropdownOpen}
                placement="bottom-end"
                setOpen={setDownloadDropdownOpen}
              />
            </RedoButtonDropdown>
          )}
          {!isRepairCard && (
            <RedoButton
              className={shippingCss.labelButton}
              hierarchy={RedoButtonHierarchy.SECONDARY}
              IconLeading={MailIcon}
              onClick={handleResend}
              text="Resend"
            />
          )}
        </Flex>
      </Flex>
      {![PickupStates.INELIGIBLE, PickupStates.CANCELLED].includes(
        pickupState,
      ) && !isRepairCard ? (
        <Flex dir="column" gap="xs">
          <Text fontSize="xs" fontWeight="medium">
            Package Pickup
          </Text>
          <RedoButton
            className={shippingCss.labelButton}
            hierarchy={RedoButtonHierarchy.SECONDARY}
            IconLeading={CalendarIcon}
            onClick={handleSchedulePickup}
            text={
              (pickupState === PickupStates.PAID_FOR
                ? "Reschedule"
                : "Schedule") + " Pickup"
            }
          />
        </Flex>
      ) : undefined}
      {pickupState === PickupStates.PAID_FOR ? (
        <RedoButton
          hierarchy={RedoButtonHierarchy.LINK_GRAY}
          onClick={handleCancelPickup}
          text="Cancel pickup"
        />
      ) : undefined}
    </>
  );
});
