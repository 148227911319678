import { z } from "zod";
import { UrlSchema } from "../common/url-parser";

export const scrapeConciergeKnowledge = {
  input: z.object({
    urls: z
      .array(UrlSchema)
      .min(1, { message: "At least one URL is required" })
      .max(5, { message: "Maximum of 5 URLs allowed per request" }),
  }),
  output: z.array(
    z.object({
      url: z.string(),
      knowledge: z.array(
        z.object({
          _id: z.string(),
          whenToUse: z.string(),
          title: z.string(),
          knowledge: z.string(),
          enabled: z.boolean(),
          teamId: z.string(),
        }),
      ),
    }),
  ),
};
