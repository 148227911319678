// extracted by mini-css-extract-plugin
export var addButton = "_3x-0";
export var border = "_3x-8";
export var check = "_3x-h";
export var danger = "_3x-6";
export var dropdownContainer = "_3x-1";
export var editModalBody = "_3x-j";
export var footerContainer = "_3x-i";
export var fullWidth = "_3x-a";
export var hide = "_3x-9";
export var icon = "_3x-3";
export var input = "_3x-7";
export var inputFlex = "_3x-b";
export var inputWrapper = "_3x-c";
export var manualFocus = "_3x-5";
export var optionButton = "_3x-4";
export var pillWrapper = "_3x-e";
export var pointer = "_3x-2";
export var showOverflow = "_3x-f";
export var tagsFlex = "_3x-d";
export var xButton = "_3x-g";