import { BooleanFilterOperator } from "@redotech/redo-model/views/advanced-filters/boolean-filter";
import { AdvancedFilterType } from "@redotech/redo-model/views/advanced-filters/generic-advanced-filter-data";
import { BooleanTableFilter } from "@redotech/redo-web/advanced-filters/advanced-filter";
import PrinterIcon from "@redotech/redo-web/arbiter-icon/printer.svg";

export const PrinterFilter = (
  name: string,
  label: string,
): BooleanTableFilter => ({
  type: AdvancedFilterType.BOOLEAN,
  data: {
    name: name,
    type: AdvancedFilterType.BOOLEAN,
    value: null,
    operator: BooleanFilterOperator.IS,
  },
  label: label,
  boolDisplay: { falseDisplay: "unprinted", trueDisplay: "printed" },
  Icon: PrinterIcon,
});
