export const humanReadableFileSize = (
  sizeInBytes: number,
  nDecimals = 2,
): string => {
  /** if it's smaller than 1KB, display it in bytes */
  if (sizeInBytes < 1024) {
    return `${sizeInBytes} bytes`;
  }
  /** If it's small than 1MB, display it in KB */
  if (sizeInBytes < 1024 * 1024) {
    return `${(sizeInBytes / 1024).toFixed(nDecimals)} KB`;
  }
  /** If it's smaller than 1GB, display it in MB */
  if (sizeInBytes < 1024 * 1024 * 1024) {
    return `${(sizeInBytes / 1024 / 1024).toFixed(nDecimals)} MB`;
  }
  /** If it's larger than 1GB, display it in GB */
  return `${(sizeInBytes / 1024 / 1024 / 1024).toFixed(nDecimals)} GB`;
};
