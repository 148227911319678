import Alert from "@mui/material/Alert";
import Snackbar from "@mui/material/Snackbar";
import {
  ConversationPlatform,
  ExpandedConversation,
  ExpandedConversationMessage,
  MessageVisibility,
  TICKET_CONVERTED_FROM_REDOCHAT_TO_EMAIL_MESSAGE,
} from "@redotech/redo-model/conversation";
import { getPrimaryCustomerEmail } from "@redotech/redo-model/customer";
import { EmailReplyType } from "@redotech/redo-model/support/conversations/email-info";
import { Flex } from "@redotech/redo-web/flex";
import { memo, useEffect, useState } from "react";
import { ErrorMessageSection } from "../app/error-page";
import { ConversationEmailMessagesView } from "./conversation-email-view/conversation-email-messages-view";
import { ConversationHeader } from "./conversation-header";
import { ConversationMessages } from "./conversation-messages";
import { MessageInput } from "./message-input/message-input";
import { OfferVoiceConversion } from "./offer-voice-conversion";

export const ConversationContent = memo(function ConversationContent({
  conversation,
  setActiveConversation,
  actionButtons,
  leftPanelOpen,
  rightPanelOpen,
  handleSetLeftPanelOpen,
  handleSetRightPanelOpen,
  onConversationsViewed,
  removeConversationFromProximity,
  nextConversationInList,
  prevConversationInList,
  pendingNextConversation,
}: {
  conversation: ExpandedConversation;
  setActiveConversation: (
    conversation: ExpandedConversation | undefined,
  ) => void;
  actionButtons: React.ReactNode;
  leftPanelOpen: boolean;
  rightPanelOpen: boolean;
  handleSetLeftPanelOpen: (value: boolean) => void;
  handleSetRightPanelOpen: (value: boolean) => void;
  onConversationsViewed: (
    conversation: ExpandedConversation,
  ) => ExpandedConversation | undefined;
  removeConversationFromProximity: (
    conversationToExclude: ExpandedConversation,
  ) => void;
  nextConversationInList?: ExpandedConversation;
  prevConversationInList?: ExpandedConversation;
  pendingNextConversation?: boolean;
}) {
  const [errorMessage, setErrorMessage] = useState<string>("");
  const [showErrorMessage, setShowErrorMessage] = useState(false);
  const [typing, setTyping] = useState<Record<string, Date>>({});
  const [showFullCommentThread, setShowFullCommentThread] = useState(true);

  const [
    lastCustomerMessageIdBeforeConversion,
    setLastCustomerMessageIdBeforeConversion,
  ] = useState<string | undefined>(undefined);
  const [messageAfterConversionMessage, setMessageAfterConversionMessage] =
    useState<ExpandedConversationMessage | null>(null);

  // Only to be used for the first message after a platform conversion
  const getDraftContent = () => {
    const msg = conversation.messages.find(
      (otherMessage) =>
        otherMessage.draftInfo?.isDraft &&
        otherMessage.visibility === MessageVisibility.PUBLIC,
    );
    return msg?.content || "";
  };

  useEffect(() => {
    if (
      conversation.originalPlatform &&
      conversation.platform !== conversation.originalPlatform
    ) {
      const lastConversionMessage = conversation.messages
        .slice()
        .reverse()
        .find(
          (message) =>
            message.content ===
              TICKET_CONVERTED_FROM_REDOCHAT_TO_EMAIL_MESSAGE &&
            message.type === "system",
        );

      if (lastConversionMessage) {
        const lastCustomerMessageBeforeConversion = conversation.messages
          .reverse()
          .find((msg) => {
            return (
              msg.createdAt < lastConversionMessage.createdAt &&
              msg.type === "customer" &&
              msg.visibility === MessageVisibility.PUBLIC
            );
          });
        setLastCustomerMessageIdBeforeConversion(
          lastCustomerMessageBeforeConversion?._id,
        );
        const firstMessageAfterConversionMessage = conversation.messages.find(
          (message) => {
            return (
              message.createdAt > lastConversionMessage.createdAt &&
              !message.draftInfo?.isDraft &&
              message.visibility === MessageVisibility.PUBLIC
            );
          },
        );
        setMessageAfterConversionMessage(
          firstMessageAfterConversionMessage || null,
        );
      }
    } else {
      setLastCustomerMessageIdBeforeConversion(undefined);
      setMessageAfterConversionMessage(null);
    }
  }, [conversation._id, conversation.messages.length]);

  return (
    <>
      <ConversationHeader
        actionButtons={actionButtons}
        conversation={conversation}
        handleSetLeftPanelOpen={handleSetLeftPanelOpen}
        handleSetRightPanelOpen={handleSetRightPanelOpen}
        leftPanelOpen={leftPanelOpen}
        nextConversationInList={nextConversationInList}
        onConversationsViewed={onConversationsViewed}
        pendingNextConversation={pendingNextConversation}
        prevConversationInList={prevConversationInList}
        rightPanelOpen={rightPanelOpen}
        setActiveConversation={setActiveConversation}
      />
      {conversation.messages.length === 0 ? (
        <Flex align="center" height="full" justify="center" px="lg">
          <ErrorMessageSection
            details={`This conversation (id ${conversation._id}) has no messages. Please contact Redo support.`}
          />
        </Flex>
      ) : (
        <>
          {conversation.platform === ConversationPlatform.EMAIL ? (
            <ConversationEmailMessagesView
              conversation={conversation}
              nextConversationInList={nextConversationInList}
              prevConversationInList={prevConversationInList}
              removeConversationFromProximity={removeConversationFromProximity}
              setActiveConversation={setActiveConversation}
              setErrorMessage={setErrorMessage}
              setShowErrorMessage={setShowErrorMessage}
              setShowFullCommentThread={setShowFullCommentThread}
              setTyping={setTyping}
              showFullCommentThread={showFullCommentThread}
              typing={typing}
            />
          ) : (
            <ConversationMessages
              conversation={conversation}
              setActiveConversation={setActiveConversation}
              setErrorMessage={setErrorMessage}
              setShowErrorMessage={setShowErrorMessage}
              showFullCommentThread={showFullCommentThread}
              typing={typing}
            />
          )}
          {conversation.platform === ConversationPlatform.VOICE && (
            <OfferVoiceConversion conversation={conversation} />
          )}
          <MessageInput
            conversation={conversation}
            // if the last message is a convert from chat to email we want to fill in draft props here
            emailDraftProps={
              !messageAfterConversionMessage &&
              lastCustomerMessageIdBeforeConversion
                ? {
                    draftInfo: {
                      status: "drafting",
                      mode: EmailReplyType.REPLY,
                      draft: {
                        recipientsInfo: {
                          to: getPrimaryCustomerEmail(conversation.customer)
                            ? [
                                {
                                  email: getPrimaryCustomerEmail(
                                    conversation.customer,
                                  )!,
                                },
                              ]
                            : [],
                          cc: [],
                          bcc: [],
                          inReplyToMongoId:
                            lastCustomerMessageIdBeforeConversion,
                        },
                        subject: `Re: ${conversation.subject}`,
                        content: getDraftContent(),
                      },
                    },
                    // Only reply is offered for conversion messages so we don't need these 2 functions
                    handleSetReplyDraft: () => {},
                    setTriggerReinitalizeDraft: () => {},
                    // Forward button is disabled for first message after conversion
                    shouldPopupRecipientsModalBecauseForwardButtonClicked:
                      false,
                    setShouldPopupRecipientsModalBecauseForwardButtonClicked:
                      () => {},
                  }
                : undefined
            }
            isFirstMessageAfterPlatformConversion={
              !messageAfterConversionMessage
            }
            key={conversation._id}
            messageIdForKey={lastCustomerMessageIdBeforeConversion}
            nextConversationInList={nextConversationInList}
            prevConversationInList={prevConversationInList}
            removeConversationFromProximity={removeConversationFromProximity}
            rightPanelOpen={rightPanelOpen}
            setActiveConversation={setActiveConversation}
            setErrorMessage={setErrorMessage}
            setShowErrorMessage={setShowErrorMessage}
            setShowFullCommentThread={setShowFullCommentThread}
            setTyping={setTyping}
            showFullCommentThread={showFullCommentThread}
            typing={typing}
          />
        </>
      )}
      {showErrorMessage && (
        <Snackbar
          autoHideDuration={10000}
          onClose={() => setShowErrorMessage(false)}
          open={showErrorMessage}
        >
          <Alert onClose={() => setShowErrorMessage(false)} severity="error">
            {errorMessage}
          </Alert>
        </Snackbar>
      )}
    </>
  );
});
