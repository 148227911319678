import {
  IEmailTemplate,
  TemplateType,
} from "@redotech/redo-model/email-builder";
import { RedoMerchantClient } from ".";

/**
 * POST /emailTemplate
 */
export async function createEmailTemplate(
  client: RedoMerchantClient,
  {
    template,
    signal,
  }: { template: Omit<IEmailTemplate, "_id">; signal?: AbortSignal },
) {
  const response = await client.client.post(
    "emailTemplate",
    { template },
    { headers: client.authorization(), signal },
  );
  return response.data;
}

/**
 * GET /emailTemplate/:templateId
 */
export async function getEmailTemplate(
  client: RedoMerchantClient,
  { templateId, signal }: { templateId: string; signal?: AbortSignal },
) {
  const response = await client.client.get(`emailTemplate/${templateId}`, {
    headers: client.authorization(),
    signal,
  });
  return response.data;
}

/**
 * GET /emailTemplate/default
 */
export async function getDefaultEmailTemplate(
  client: RedoMerchantClient,
  { signal }: { signal?: AbortSignal },
) {
  const response = await client.client.get(`emailTemplate/default`, {
    headers: client.authorization(),
    signal,
  });
  return response.data;
}

/**
 * GET /emailTemplate
 */
export async function getEmailTemplates(
  client: RedoMerchantClient,
  { signal }: { signal?: AbortSignal },
) {
  const response = await client.client.get("emailTemplate", {
    headers: client.authorization(),
    signal,
  });
  return response.data as IEmailTemplate[];
}

/**
 * PUT /emailTemplate/:templateId
 */
export async function updateEmailTemplate(
  client: RedoMerchantClient,
  {
    templateId,
    template,
    signal,
  }: {
    templateId: string;
    // schemaType and category cannot be changed
    template: Omit<IEmailTemplate, "_id" | "schemaType" | "category">;
    signal?: AbortSignal;
  },
) {
  const response = await client.client.put(
    `emailTemplate/${templateId}`,
    { template },
    { headers: client.authorization(), signal },
  );
  return response.data;
}

/**
 * PUT /emailTemplate/default
 */
export async function updateDefaultEmailTemplate(
  client: RedoMerchantClient,
  { template, signal }: { template: IEmailTemplate; signal?: AbortSignal },
) {
  const response = await client.client.put(
    `emailTemplate/default`,
    { template },
    { headers: client.authorization(), signal },
  );
  return response.data;
}

/**
 * PUT /emailTemplate/update-sections
 */
export async function updateSections(
  client: RedoMerchantClient,
  { type, signal }: { type: TemplateType; signal?: AbortSignal },
) {
  const response = await client.client.put(
    `emailTemplate/update-sections`,
    { type },
    { headers: client.authorization(), signal },
  );
  return response.data;
}

/**
 * DELETE /emailTemplate/:templateId
 */
export async function deleteEmailTemplate(
  client: RedoMerchantClient,
  { templateId, signal }: { templateId: string; signal?: AbortSignal },
) {
  const response = await client.client.delete(`emailTemplate/${templateId}`, {
    headers: client.authorization(),
    signal,
  });
  return response.data;
}

/**
 * POST /emailTemplate/send-test-email
 */
export async function sendTestEmail(
  client: RedoMerchantClient,
  {
    email,
    template,
    orderNumber,
    signal,
  }: {
    email: string;
    template: Omit<IEmailTemplate, "_id">;
    orderNumber?: string;
    signal?: AbortSignal;
  },
) {
  const response = await client.client.post(
    "emailTemplate/send-test-email",
    { email, template, orderNumber },
    { headers: client.authorization(), signal },
  );
  return (response.data?.success as boolean) ?? false;
}
