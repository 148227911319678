// extracted by mini-css-extract-plugin
export var addDetails = "_15-l";
export var addEditButton = "_15-b";
export var addressFields = "_15-2";
export var animationContainer = "_15-0";
export var closeButton = "_15-p";
export var customerDetails = "_15-x";
export var customerInformationWrapper = "_15-6";
export var dropdowns = "_15-4";
export var error = "_15-k";
export var fields = "_15-n";
export var flex = "_15-m";
export var grow = "_15-3";
export var icon = "_15-d";
export var imageButton = "_15-q";
export var imagesWrapper = "_15-s";
export var itemDetails = "_15-i";
export var itemSelectionHeader = "_15-9";
export var label = "_15-w";
export var lineItemImage = "_15-j";
export var lineItemReturnedWrapper = "_15-h";
export var lineItemWrapper = "_15-g";
export var modalContent = "_15-f";
export var modalFooter = "_15-1";
export var multipleChoiceAnswer = "_15-u";
export var multipleChoiceAnswersWrapper = "_15-t";
export var orderButton = "_15-z";
export var orderLabel = "_15-10";
export var orderSelection = "_15-y";
export var question = "_15-v";
export var search = "_15-e";
export var selectCheckbox = "_15-a";
export var selectionWrapper = "_15-8";
export var spinner = "_15-c";
export var subheader = "_15-5";
export var title = "_15-7";
export var uploadedImage = "_15-o";
export var uploadedImageWrapper = "_15-r";