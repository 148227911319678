import { ArrayStringFilterOperator } from "@redotech/redo-model/views/advanced-filters/array-string-filter";
import { AdvancedFilterType } from "@redotech/redo-model/views/advanced-filters/generic-advanced-filter-data";
import {
  AdvancedTableFilter,
  ArrayStringTableFilter,
} from "@redotech/redo-web/advanced-filters/advanced-filter";
import BookIcon from "@redotech/redo-web/arbiter-icon/book-open-01.svg";

export const ProductVariantFilter = (
  fetcher: (
    { filters }: { filters: AdvancedTableFilter[] },
    signal: AbortSignal,
  ) => Promise<string[]>,
): ArrayStringTableFilter<string> => ({
  type: AdvancedFilterType.ARRAY_TO_STRING,
  data: {
    name: "productVariant",
    type: AdvancedFilterType.ARRAY_TO_STRING,
    value: [],
    operator: ArrayStringFilterOperator.INCLUDES,
  },
  itemLabel: "product variant",
  Icon: BookIcon,
  valueFetcher: fetcher,
});
