// extracted by mini-css-extract-plugin
export var arrows = "_7w-5";
export var decrementer = "_7w-9";
export var disabled = "_7w-3";
export var error = "_7w-1";
export var hiddenText = "_7w-a";
export var incrementer = "_7w-8";
export var input = "_7w-7";
export var inputWrapper = "_7w-0";
export var readonly = "_7w-2";
export var regular = "_7w-6";
export var small = "_7w-4";