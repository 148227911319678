import { FulfillmentOrderUpdate } from "@redotech/merchant-sdk/outbound-labels-rpc/schema/update-fulfillment-groups";
import { useRequiredContext } from "@redotech/react-util/context";
import { FulfillmentOrderData } from "@redotech/redo-model/fulfillments/fulfillment-group";
import { FulfillmentGroupStatus } from "@redotech/redo-model/fulfillments/fulfillment-group-status";
import { PrintKind } from "@redotech/redo-model/fulfillments/fullfilment-print-status";
import { RedoBadgeSize } from "@redotech/redo-web/arbiter-components/badge/redo-badge";
import PrintIcon from "@redotech/redo-web/arbiter-icon/printer.svg";
import { Flex } from "@redotech/redo-web/flex";
import { PillTheme } from "@redotech/redo-web/pill";
import { TagPill } from "@redotech/redo-web/tags/tag-pill";
import { Tooltip } from "@redotech/redo-web/tooltip/tooltip";
import { RedoOutboundLabelsRpcClientContext } from "../../../app/redo-outbound-labels-rpc-client-provider";
import { printKindToTitle } from "../columns/is-printed-column";
import * as isPrintedCellCss from "./is-printed-cell.module.css";

const canPrint = (kind: PrintKind, status?: FulfillmentGroupStatus) => {
  return kind !== PrintKind.LABEL || status === FulfillmentGroupStatus.Closed;
};

export function IsPrintedCell({
  row,
  kind,
  updateRowData,
}: {
  row: FulfillmentOrderData;
  kind: PrintKind;
  updateRowData: (row: FulfillmentOrderUpdate[]) => void;
}) {
  const rpcClient = useRequiredContext(RedoOutboundLabelsRpcClientContext);

  const doPrint = {
    [PrintKind.PICK_LIST]: async () => {
      const { url } = await rpcClient.printPickList({
        fulfillmentGroupIds: [row._id],
        selectedStatus: row.status ?? FulfillmentGroupStatus.Open,
      });
      if (!row.printStatus?.[kind]) {
        updateRowData([
          {
            fulfillmentGroupId: row._id,
            printStatus: { ...row.printStatus, pickListPrinted: true },
          },
        ]);
      }
      window.open(url, "_blank");
    },
    [PrintKind.PACKING_SLIP]: async () => {
      const { url } = await rpcClient.printPackingSlip({
        fulfillmentGroupIds: [row._id],
      });
      if (!row.printStatus?.[kind]) {
        updateRowData([
          {
            fulfillmentGroupId: row._id,
            printStatus: { ...row.printStatus, packingSlipPrinted: true },
          },
        ]);
      }
      window.open(url, "_blank");
    },
    [PrintKind.LABEL]: async () => {
      const { url } = await rpcClient.printLabels({
        fulfillmentGroupIds: [row._id],
      });
      window.open(url, "_blank");
    },
  };

  const tooltipTitle = () => {
    if (
      kind === PrintKind.LABEL &&
      row.status !== FulfillmentGroupStatus.Closed
    ) {
      return null;
    }
    return `${row.printStatus?.[kind] ? "Reprint" : "Print"} ${printKindToTitle[kind]}`;
  };
  return (
    <Tooltip title={tooltipTitle()}>
      <div
        className={isPrintedCellCss.isPrintedCell}
        onClick={async (e) => {
          if (canPrint(kind, row.status ?? undefined)) {
            e.stopPropagation();
            await doPrint[kind]();
          }
        }}
      >
        <Flex align="center" gap="sm" justify="space-between" p="lg">
          {row.printStatus?.[kind] ? (
            <TagPill
              badgeSize={RedoBadgeSize.X_SMALL}
              tag={{ name: "Yes", pillTheme: PillTheme.SUCCESS }}
            />
          ) : (
            <TagPill
              badgeSize={RedoBadgeSize.X_SMALL}
              tag={{ name: "No", pillTheme: PillTheme.NORMAL }}
            />
          )}
          {canPrint(kind, row.status ?? undefined) && (
            <PrintIcon className={isPrintedCellCss.printerIcon} />
          )}
        </Flex>
      </div>
    </Tooltip>
  );
}
