import { Alert, Snackbar } from "@mui/material";
import { useRequiredContext } from "@redotech/react-util/context";
import { useInput } from "@redotech/react-util/form";
import { useHandler } from "@redotech/react-util/hook";
import { useLoad, useTriggerLoad } from "@redotech/react-util/load";
import {
  createAuth,
  getProduct,
  WidgetClient,
} from "@redotech/redo-api-client/widget";
import { REDO_RETURNS_APP_URL_LOCAL } from "@redotech/redo-model/customer-portal";
import type { Fulfillment } from "@redotech/redo-model/order";
import { Order, Provider } from "@redotech/redo-model/order";
import { PillTheme } from "@redotech/redo-model/pill-theme";
import { VariantInfo } from "@redotech/redo-model/product";
import {
  claimIsBeingAutoprocessed,
  getReturner,
  NULL_ORDER_ID,
  Product,
  RejectTypes,
  ReturnedProductStatus,
  ReturnTypeEnum,
  type MerchantAppReturn,
  type NewItem,
  type Return,
} from "@redotech/redo-model/return";
import { ProvisionType } from "@redotech/redo-model/return-flow";
import { ReturnStatus } from "@redotech/redo-model/return-status";
import {
  ProductTotals,
  ReturnTotals,
} from "@redotech/redo-model/return-totals-calculator";
import { canRefundInstantExchangeRecoveryCharge } from "@redotech/redo-model/returns/exchange-recovery";
import { DiscountDistributionMethod, Team } from "@redotech/redo-model/team";
import { Permission, permitted } from "@redotech/redo-model/user";
import { alertOnFailure } from "@redotech/redo-web/alert";
import {
  RedoBadge,
  RedoBadgeColor,
  RedoBadgeSize,
} from "@redotech/redo-web/arbiter-components/badge/redo-badge";
import {
  RedoButton,
  RedoButtonHierarchy,
  RedoButtonSize,
} from "@redotech/redo-web/arbiter-components/buttons/redo-button";
import {
  ButtonPlacement,
  RedoModal,
  RedoModalSize,
  RedoModalTheme,
} from "@redotech/redo-web/arbiter-components/modal/redo-modal";
import TrashIcon from "@redotech/redo-web/arbiter-icon/trash-01.svg";
import {
  Button,
  ButtonSize,
  ButtonTheme,
  IconButton,
} from "@redotech/redo-web/button";
import { ButtonDropdown } from "@redotech/redo-web/button-dropdown";
import { Card } from "@redotech/redo-web/card";
import * as cardCss from "@redotech/redo-web/card.module.css";
import { Checkbox } from "@redotech/redo-web/checkbox";
import CircleSpinner from "@redotech/redo-web/circle-spinner.svg";
import { RedoClientContext } from "@redotech/redo-web/client";
import { CURRENCY_FORMAT, CurrencyContext } from "@redotech/redo-web/currency";
import { getDateTimeString } from "@redotech/redo-web/date-utils";
import { DropdownOption } from "@redotech/redo-web/dropdown";
import { Flex } from "@redotech/redo-web/flex";
import { Icon } from "@redotech/redo-web/icon";
import ChevronDownIcon from "@redotech/redo-web/icon-old/chevron-down.svg";
import ChevronRightIcon from "@redotech/redo-web/icon-old/chevron-right.svg";
import EditPencil from "@redotech/redo-web/icon-old/edit-pencil.svg";
import { LabelTheme } from "@redotech/redo-web/labeled-input";
import { ExternalLink } from "@redotech/redo-web/link";
import {
  MessageBubble,
  MessageBubbleAlignment,
  MessageBubbleTheme,
} from "@redotech/redo-web/message-bubble/message-bubble";
import { Pill, PillSize } from "@redotech/redo-web/pill";
import { SkeletonBlock, SkeletonText } from "@redotech/redo-web/skeleton";
import { Text } from "@redotech/redo-web/text";
import { FormTextInput } from "@redotech/redo-web/text-input";
import { formatDateTime } from "@redotech/redo-web/time";
import { groupInput, input } from "@redotech/ui/form";
import { newWindow } from "@redotech/web-util/window";
import * as classNames from "classnames";
import { memo, useContext, useEffect, useMemo, useState } from "react";
import { RedoMerchantRpcClientContext } from "../../app/redo-merchant-rpc-client-provider";
import { TeamContext } from "../../app/team";
import { UserContext } from "../../app/user";
import { RedoMerchantClientContext } from "../../client/context";
import {
  reopenReturn,
  resendInvoiceEmail,
  updateFiledWithCarrier,
} from "../../client/return";
import { REDO_MERCHANT_SERVER_URL } from "../../config";
import { shopifyAdminProductUrl, shopifyStoreName } from "../../shopify";
import { ProductSelectModal } from "../../support/product-select-modal";
import { shopifyAddressString } from "../return";
import { ApproveModal } from "../return-modals/approve-modal";
import { CancelModal } from "../return-modals/cancel-modal";
import { ChangeNewOrderAddressModal } from "../return-modals/change-new-order-address-modal";
import { ChangeReturnTypeModal } from "../return-modals/change-return-type-modal";
import { ChargeRecoveryModal } from "../return-modals/charge-recovery-modal";
import { CloseModal } from "../return-modals/close-modal";
import { getPriceDetailsForProduct } from "../return-modals/common/util";
import { DisapproveModal } from "../return-modals/disapprove-modal";
import { DropshipConfirmationModal } from "../return-modals/dropship-confirmation-modal";
import { EditItemModal } from "../return-modals/edit-item-modal";
import { EditSingleItemDispositionModal } from "../return-modals/edit-single-item-disposition-modal";
import { FlagModal } from "../return-modals/flag-modal";
import { ItemDispositionModal } from "../return-modals/item-disposition-modal";
import { ProcessModal } from "../return-modals/process-modal";
import { RefundRecoveryChargeModal } from "../return-modals/refund-recovery-charge-modal";
import {
  DO_NOT_SHOW_REOPEN_PROCESSED_MODAL_KEY,
  ReopenModal,
} from "../return-modals/reopen-modal";
import { ResetModal } from "../return-modals/reset-modal";
import { RestockItemsModal } from "../return-modals/restock-items-modal";
import * as returnCss from "../return.module.css";
import {
  getProductsByShippingGroupTitle,
  hasEditReturnPermissions,
  isExchangeOrderEditable,
  isVariantExchange,
  itemStatusName,
  returnKindNameProduct,
  returnKindNameReturn,
  returnStatusName,
  returnTypeName,
} from "../util";
import { DaysSinceDelivered } from "./days-since-delivered";
import * as detailsCardCss from "./details-card.module.css";

type ItemDispositionModalOpen =
  | { open: true; item: Return["products"][number] }
  | { open: false };

export const DetailCard = memo(function DetailCard({
  return: return_,
  orders,
  pending,
  returnTotals,
  reload,
}: {
  return?: MerchantAppReturn;
  orders?: Order[];
  pending: boolean;
  returnTotals?: ReturnTotals;
  reload(): void;
}) {
  const team = useContext(TeamContext);
  const user = useContext(UserContext);
  const client = useRequiredContext(RedoMerchantClientContext);
  const redoClient = useRequiredContext(RedoClientContext);
  const rpcClient = useRequiredContext(RedoMerchantRpcClientContext);

  const [isProcessing, setIsProcessing] = useState<boolean>(false);
  const [filedWithCarrierPending, setFiledWithCarrierPending] =
    useState<boolean>(false);
  const claimIsManaged =
    !!return_ &&
    claimIsBeingAutoprocessed({
      returnType: return_.type,
      packageProtectionPlusEnabled:
        !!team?.settings?.packageProtection?.packageProtectionPlusEnabled,
      reportedClaims: return_.reportedClaims ?? [],
    });
  const hasEligibleShippingStatus =
    !!return_ &&
    !claimIsManaged &&
    [ReturnStatus.DELIVERED, ReturnStatus.IN_TRANSIT].includes(return_.status);
  const canManageReturn =
    !!user && permitted(user.permissions, Permission.MANAGE_RETURN);
  const canCancel =
    !!return_ &&
    [ReturnStatus.OPEN, ReturnStatus.FLAGGED].includes(return_.status);
  const canFlag =
    !!return_ &&
    (hasEligibleShippingStatus || return_.status === ReturnStatus.OPEN);
  const canUnflag = return_?.status === ReturnStatus.FLAGGED;
  const canProcess =
    !!return_ &&
    (hasEligibleShippingStatus ||
      [ReturnStatus.OPEN, ReturnStatus.FLAGGED].includes(return_.status));
  const canDisposition =
    team?.settings.returnItemDisposition.enabled &&
    !!return_ &&
    return_.status === ReturnStatus.COMPLETE &&
    !return_.allProductsDispositionCompleted;
  const canReject =
    !!return_ &&
    (ReturnStatus.NEEDS_REVIEW === return_.status ||
      (!claimIsManaged && return_.status === ReturnStatus.DELIVERED));
  const canApprove = return_?.status === ReturnStatus.NEEDS_REVIEW;

  const canRestockBeforeProcessing =
    team?.settings.inventory.allowRestockBeforeProcess &&
    !!return_ &&
    return_.status !== ReturnStatus.COMPLETE;

  const canReset = !!return_ && return_.status !== ReturnStatus.COMPLETE;

  const canReapprove =
    return_ &&
    return_?.status !== ReturnStatus.COMPLETE &&
    return_?.rejectType === RejectTypes.MANUAL_REVIEW;

  // Similar to Reset, this has the potential to allow merchants to resubmit a claim that's been processed by our insurance provider.
  // We want to prevent it in this case because some actions are irreversible.
  const canReopen =
    return_ &&
    !claimIsManaged &&
    return_?.status !== ReturnStatus.COMPLETE &&
    return_?.status !== ReturnStatus.OPEN &&
    return_?.products.some(
      (item) =>
        item.status !== ReturnedProductStatus.REJECTED &&
        item.strategy !== "none",
    ) &&
    !canReapprove &&
    !canApprove;

  const canClose =
    return_ &&
    return_?.status !== ReturnStatus.COMPLETE &&
    return_?.status !== ReturnStatus.REJECTED;

  const canReopenProcessed =
    !!return_ &&
    return_.status === ReturnStatus.COMPLETE &&
    !!team?.settings.returns?.allowReopenAfterProcessing;

  const containsDropshippedItem = !!return_?.products?.some(
    (product) => !!product?.dropshipSupplierName,
  );
  const EmailInput = useInput(groupInput({ email: input<string>() }), {
    email: (return_ && getReturner(return_).email) || "",
  });

  const canChargeRecovery = useMemo(() => {
    if (!return_) {
      return false;
    }

    const recoveryCharge = return_.paymentIntents?.find(
      (paymentIntent) => paymentIntent.type === "recovery",
    );

    return return_.provision === ProvisionType.INSTANT && !recoveryCharge;
  }, [return_]);

  const canRefund = useMemo(() => {
    if (!return_) {
      return false;
    }

    return canRefundInstantExchangeRecoveryCharge({
      paymentIntents: return_.paymentIntents || [],
      provision: return_.provision,
    });
  }, [return_]);

  const needsDraftOrder = !return_?.draftOrderId && return_?.sentInvoice;

  const [cancelOpen, setCancelOpen] = useState(false);
  const handleCancel = useHandler(() => {
    setCancelOpen(true);
  });
  const handleCancelClose = useHandler((refresh: boolean = false) => {
    setCancelOpen(false);
    if (refresh) {
      window.location.reload();
    }
  });

  const [chargeRecoveryOpen, setRecoveryChargeOpen] = useState(false);
  const handleRecoveryCharge = useHandler(() => {
    setRecoveryChargeOpen(true);
  });
  const handleRecoveryChargeClose = useHandler((refresh: boolean = false) => {
    setRecoveryChargeOpen(false);
    if (refresh) {
      window.location.reload();
    }
  });

  const [restockItemsModalOpen, setRestockItemsModalOpen] = useState(false);
  const handleRestockBeforeProcessing = useHandler(() => {
    setRestockItemsModalOpen(true);
  });

  const [itemBeingEdited, setItemBeingEdited] = useState<any>(); // TODO type

  // This can be used for actions that aren't done from a modal, for example,
  // if "Don't show again" has been checked in that modal and the action is taken.
  // So far, only reopening a return uses this.
  const [actionPending, setActionPending] = useState(false);

  const [processOpen, setProcessOpen] = useState(false);
  const handleProcess = useHandler(() => {
    setProcessOpen(true);
  });
  const handleProcessClose = useHandler((refresh: boolean = false) => {
    if (!isProcessing) {
      setProcessOpen(false);
      if (refresh) {
        window.location.reload();
      }
    }
    setItemBeingEdited(undefined);
  });
  const [editItemModalOpen, setEditItemModalOpen] = useState(false);
  const [
    editSingleItemDispositionModalOpen,
    setEditSingleItemDispositionModalOpen,
  ] = useState<ItemDispositionModalOpen>({ open: false });
  const [itemDispositionModalOpen, setItemDispositionModalOpen] =
    useState(false);
  const handleEditItemModalClose = useHandler((refresh: boolean = false) => {
    if (refresh) {
      window.location.reload();
    }
    setEditItemModalOpen(false);
  });

  const [resendInvoiceModalOpen, setResendInvoiceModalOpen] = useState(false);

  const [dropshipConfirmModalOpen, setDropshipConfirmModalOpen] =
    useState(false);
  const [approveOpen, setApproveOpen] = useState(false);

  const handleApprove = useHandler(() => {
    setDropshipConfirmModalOpen(containsDropshippedItem);
    setApproveOpen(true);
  });
  const handleApproveClose = useHandler(() => {
    setApproveOpen(false);
  });

  const handleDropshipApprove = () => {
    setDropshipConfirmModalOpen(false);
  };

  const handleDropshipClose = () => {
    setApproveOpen(false);
    setDropshipConfirmModalOpen(false);
  };

  const [reapproveOpen, setReapproveOpen] = useState(false);
  const handleReapprove = useHandler(() => {
    setReapproveOpen(true);
  });
  const handleReapproveClose = useHandler(() => {
    setReapproveOpen(false);
  });

  const [resetOpen, setResetOpen] = useState(false);
  const handleReset = useHandler(() => {
    setResetOpen(true);
  });
  const handleResetClose = useHandler(() => {
    setResetOpen(false);
  });

  const [reopenOpen, setReopenOpen] = useState(false);
  const handleReopen = useHandler(async () => {
    if (
      localStorage.getItem(DO_NOT_SHOW_REOPEN_PROCESSED_MODAL_KEY) === "true" &&
      return_
    ) {
      setActionPending(true);
      await reopenReturn(client, { returnId: return_.id });
      reload();
      setActionPending(false);
      location.reload();
      return;
    }
    setReopenOpen(true);
  });
  const handleReopenClose = useHandler(() => {
    setReopenOpen(false);
  });

  const [closeOpen, setCloseOpen] = useState(false);
  const handleClose = useHandler(() => {
    setCloseOpen(true);
  });
  const handleCloseModalClose = useHandler(() => {
    setCloseOpen(false);
  });

  const [refundOpen, setRefundOpen] = useState(false);
  const handleRefund = useHandler(() => {
    setRefundOpen(true);
  });
  const handleRefundClose = useHandler(() => {
    setRefundOpen(false);
  });

  const [disapproveOpen, setDisapproveOpen] = useState(false);
  const handleDisapprove = useHandler(() => {
    setDisapproveOpen(true);
  });
  const handleDisapproveClose = useHandler(() => {
    setDisapproveOpen(false);
  });

  const [productToUpdate, setProductToUpdate] =
    useState<Return["products"][0]>();
  const [newType, setNewType] = useState<string>("");
  const [returnTypeModalOpen, setReturnTypeModalOpen] = useState(false);
  const handleChangeReturnType = (
    newType: string,
    product: Return["products"][0],
  ) => {
    setProductToUpdate(product);
    setNewType(newType);
    setReturnTypeModalOpen(true);
  };
  const handleChangeReturnTypeClose = (refresh: boolean = false) => {
    setReturnTypeModalOpen(false);
    if (refresh) {
      window.location.reload();
    }
  };

  const [flagModalOpen, setFlagModalOpen] = useState(false);
  const handleFlagModalOpen = useHandler(() => {
    setFlagModalOpen(true);
  });
  const handleFlagModalClose = useHandler(() => {
    setFlagModalOpen(false);
  });

  const [unflagModalOpen, setUnflagModalOpen] = useState(false);
  const [editNewItemsModalOpen, setEditNewItemsModalOpen] = useState(false);

  const handleUnflagModalOpen = useHandler(() => {
    setUnflagModalOpen(true);
  });
  const handleUnflagModalClose = useHandler(() => {
    setUnflagModalOpen(false);
  });
  // const order = return_?.products[0]?.order || null;
  const discountCodes = orders
    ? orders.flatMap((order) =>
        order.shopify.discount_codes.map((code: { code: string }) => code.code),
      )
    : [];

  const [changeNewOrderAddressModalOpen, setChangeNewOrderAddressModalOpen] =
    useState(false);
  const handleChangeNewOrderAddressModalOpen = useHandler(() => {
    setChangeNewOrderAddressModalOpen(true);
  });
  const handleChangeNewOrderAddressModalClose = useHandler(() => {
    setChangeNewOrderAddressModalOpen(false);
  });

  const [resentInvoiceSuccessMessageOpen, setResentInvoiceSuccessMessageOpen] =
    useState(false);
  const handleResendDraftEmail = useHandler(async () => {
    if (return_) {
      await alertOnFailure(
        "Email failed to send. Check the email address and try again",
        true,
      )(async () => {
        await resendInvoiceEmail(client, {
          returnId: return_._id,
          emailOverride: EmailInput.value.email,
        });
        setResentInvoiceSuccessMessageOpen(true);
      });
    }
  });
  const handleResentInvoiceSuccessMessageClose = useHandler(() => {
    setResentInvoiceSuccessMessageOpen(false);
  });

  const [viewingOriginal, setViewingOriginal] = useState(false);

  const exchangeOrderModified =
    return_ &&
    (return_.products.some(
      (product) =>
        product.originalVariantOptions?.exchangeGroupItem ||
        product.originalVariantOptions?.exchange_for,
    ) ||
      return_.originalReturnOptions?.advancedExchangeItems);

  const newItemsFromVariantExchange = return_?.products
    .map((product) => {
      return {
        ...product,
        exchangeGroupItem: viewingOriginal
          ? product.originalVariantOptions?.exchangeGroupItem
          : product.exchangeGroupItem,
        exchange_for: viewingOriginal
          ? product.originalVariantOptions?.exchange_for
          : product.exchange_for,
        outOfStockDuringProcessing: product.outOfStockDuringProcessing,
      };
    })
    .filter((product) => isVariantExchange(product));

  const newItemsFromAdvancedExchange = viewingOriginal
    ? return_?.originalReturnOptions?.advancedExchangeItems
    : return_?.advancedExchangeItems;

  const returnLineItems = useMemo(
    () => return_?.products.map((product) => product.line_item_id),
    [return_?.products],
  );

  const numNewItems = () =>
    (newItemsFromVariantExchange?.length || 0) +
    (newItemsFromAdvancedExchange?.length || 0);

  const showNewItems = (return_: Return, team?: Team) => {
    return (
      return_.products.some(isVariantExchange) ||
      numNewItems() ||
      isExchangeOrderEditable(return_, team, user)
    );
  };

  const handleFiledWithCarrierChange = async (markFiled: boolean) => {
    if (!return_) {
      return;
    }
    setFiledWithCarrierPending(true);
    await updateFiledWithCarrier(client, {
      returnId: return_._id,
      filedWithCarrier: markFiled,
    });
    reload();
    setFiledWithCarrierPending(false);
  };

  const moreActionsDropdown = (
    <>
      {canCancel && (
        <DropdownOption action={handleCancel}>Cancel</DropdownOption>
      )}
      {canFlag && (
        <DropdownOption action={handleFlagModalOpen}>Flag</DropdownOption>
      )}
      {canUnflag && (
        <DropdownOption action={handleUnflagModalOpen}>Unflag</DropdownOption>
      )}
      {canRestockBeforeProcessing && (
        <DropdownOption action={handleRestockBeforeProcessing}>
          Restock
        </DropdownOption>
      )}
      {canReject && (
        <DropdownOption action={handleDisapprove}>
          {return_?.status === ReturnStatus.NEEDS_REVIEW ? (
            <>Deny</>
          ) : (
            <>Reject</>
          )}
        </DropdownOption>
      )}
      {canReset && <DropdownOption action={handleReset}>Reset</DropdownOption>}
      {canReapprove && (
        <DropdownOption action={handleReapprove}>Approve</DropdownOption>
      )}
      {canReopen && (
        <DropdownOption action={handleReopen}>Re-open</DropdownOption>
      )}
      {needsDraftOrder && (
        <DropdownOption
          action={() => {
            setResendInvoiceModalOpen(true);
            EmailInput.setValue({ email: getReturner(return_).email || "" });
          }}
        >
          Resend invoice
        </DropdownOption>
      )}
      {canReopenProcessed && (
        <DropdownOption action={handleReopen}>Re-open</DropdownOption>
      )}
      {canClose && <DropdownOption action={handleClose}>Close</DropdownOption>}
      {canChargeRecovery && (
        <DropdownOption action={handleRecoveryCharge}>Charge</DropdownOption>
      )}
      {canRefund && (
        <DropdownOption action={handleRefund}>Refund</DropdownOption>
      )}
    </>
  );
  const numActions = moreActionsDropdown.props.children.filter(Boolean).length;

  const hasMultipleShipments =
    return_?.shipmentGroups?.length && return_.shipmentGroups.length > 1;

  let productsByGroupName: { groupName: string; products: Product[] }[] = [];
  if (hasMultipleShipments && return_) {
    productsByGroupName = getProductsByShippingGroupTitle(return_);
  }

  const openCustomerPortal = useHandler((event: React.MouseEvent) => {
    if (!team || !return_ || !orders || !orders[0]) {
      return;
    }
    const widgetClient = new WidgetClient(redoClient, team.widget_slug);
    newWindow(async () => {
      event.stopPropagation();
      const auth = await createAuth(widgetClient, {
        orderNumber: orders[0].shopify.name,
        email: orders[0].shopify.email,
        type: "order",
      });

      const domain = team.portal.domain || team.storeUrl;
      const path = team.portal.pathPrefix || "/apps/redo";

      const url =
        process.env.NODE_ENV === "production"
          ? new URL(`https://${domain}${path}/returns-portal`)
          : new URL(
              `${REDO_RETURNS_APP_URL_LOCAL}/widget_id/${team.widget_slug}/returns-portal`,
            );
      const address = {
        street1: orders[0].shopify.shipping_address?.address1,
        street2: orders[0].shopify.shipping_address?.address2 || "",
        city: orders[0].shopify.shipping_address?.city,
        state: orders[0].shopify.shipping_address?.province,
        country: orders[0].shopify.shipping_address?.country_code,
        zip: orders[0].shopify.shipping_address?.zip,
      };
      url.searchParams.set(
        "customer",
        JSON.stringify({ ...auth.customer, address: JSON.stringify(address) }),
      );
      url.searchParams.set("token", auth.token);
      url.searchParams.set(
        "next",
        `/returns-portal/orders/return/${return_._id}`,
      );
      return String(url);
    });
  });

  const canRemoveExchangeItems =
    !!return_ &&
    canManageReturn &&
    !viewingOriginal &&
    isExchangeOrderEditable(return_, team, user);

  const handleRemoveExchangeItem = useHandler(async (variantId: string) => {
    if (!team || !return_) {
      return false;
    }
    const items = return_.advancedExchangeItems.map((item) => ({
      quantity: item.quantity || 1,
      variantId: item.variantId || "",
    }));
    // Remove first occurrence of item with matching variantId
    const itemIndex = items.findIndex((item) => item.variantId === variantId);
    if (itemIndex === -1) {
      return false;
    }
    items.splice(itemIndex, 1);
    await rpcClient.updateNewOrderItems({
      teamId: team._id,
      returnId: return_._id,
      items,
    });
    return true;
  });

  const [addExchangeItemsLoad, setAddExchangeItemsLoad] = useState(false);

  const handleAddExchangeItems = useHandler(async (newVariantIds: string[]) => {
    if (!team || !return_) {
      return;
    }
    setAddExchangeItemsLoad(true);
    const items = return_.advancedExchangeItems.map((item) => ({
      quantity: item.quantity || 1,
      variantId: item.variantId || "",
    }));
    newVariantIds.forEach((variantId) => {
      items.push({ quantity: 1, variantId });
    });
    await rpcClient.updateNewOrderItems({
      teamId: team._id,
      returnId: return_._id,
      items,
    });
    setAddExchangeItemsLoad(false);
    reload();
  });

  const returnProductBeingEdited = useMemo(() => {
    if (itemBeingEdited) {
      return return_?.products.find((item) => {
        return item._id === itemBeingEdited._id;
      });
    }
    return undefined;
  }, [return_, itemBeingEdited]);

  const returnItemsToPrice = useMemo(() => {
    if (return_ && returnTotals) {
      const returnItemsToPrice: Record<string, number> = {};
      for (const product of return_.products) {
        const { price } = getPriceDetailsForProduct(product, returnTotals);
        returnItemsToPrice[product._id] = price;
      }
      return returnItemsToPrice;
    }
    return undefined;
  }, [return_, returnTotals]);

  /**
   * Although we support multiple orders in a return, we assume all orders are
   * from the same provider.
   */
  const orderProvider = useMemo(() => {
    return orders?.[0]?.provider;
  }, [orders]);

  return (
    <>
      <Card
        headerExtra={
          <div className={returnCss.processButtons}>
            <span className={returnCss.itemPrice} />
            {canManageReturn &&
              (canCancel ||
                canProcess ||
                canDisposition ||
                canReject ||
                canApprove ||
                canReset ||
                canReapprove ||
                canReopen ||
                needsDraftOrder ||
                canRefund ||
                canReopenProcessed ||
                canChargeRecovery ||
                canRestockBeforeProcessing ||
                (return_?.status === ReturnStatus.COMPLETE &&
                  return_?.barcodes?.[0] &&
                  team?.settings?.createReturnBarcodes)) && (
                <div className={returnCss.buttonBar}>
                  {canProcess && (
                    <Button
                      className={returnCss.actionButton}
                      onClick={handleProcess}
                      theme={ButtonTheme.PRIMARY}
                    >
                      Process
                    </Button>
                  )}
                  {canDisposition && (
                    <Button
                      className={returnCss.actionButton}
                      onClick={() => setItemDispositionModalOpen(true)}
                      pending={pending}
                      theme={ButtonTheme.PRIMARY}
                    >
                      Disposition
                    </Button>
                  )}
                  {canApprove && (
                    <Button
                      className={returnCss.actionButton}
                      onClick={handleApprove}
                      theme={ButtonTheme.PRIMARY}
                    >
                      Approve
                    </Button>
                  )}
                  {numActions > 0 && (
                    <ButtonDropdown
                      dropdown={moreActionsDropdown}
                      pending={actionPending}
                      theme={ButtonTheme.OUTLINED}
                    >
                      <div className={returnCss.buttonContent}>
                        {canProcess || canApprove ? "More actions" : "Actions"}
                        <ChevronDownIcon className={returnCss.icon} />
                      </div>
                    </ButtonDropdown>
                  )}
                </div>
              )}
          </div>
        }
        subtitle={
          return_ && orders ? (
            <>
              <div className={returnCss.headerSubtitle}>
                Created {getDateTimeString(new Date(return_.createdAt))}
              </div>
              {orders.map((order) => (
                <div
                  className={returnCss.headerSubtitle}
                  key={order.shopify.name}
                >
                  {orders.length > 1
                    ? `${order.shopify.name} ordered`
                    : "Ordered"}{" "}
                  {getDateTimeString(new Date(order.shopifyCreatedAt))}
                </div>
              ))}
              <div className={returnCss.headerSubtitle}>
                {discountCodes.map((code: string) => (
                  <span className={returnCss.code} key={code}>
                    {code}
                  </span>
                ))}
              </div>
              <div className={returnCss.headerSubtitle}>
                <ExternalLink onClick={openCustomerPortal}>
                  Return confirmation
                </ExternalLink>
              </div>
              {/* Custom component for Buttons Bebe, see explanation in the component */}
              {return_.team._id === "64c84223d5e3c700074d73c5" && (
                <DaysSinceDelivered return_={return_} />
              )}
            </>
          ) : (
            <SkeletonText length={20} />
          )
        }
        title={
          return_ && orders ? (
            <div className={returnCss.cardTitleContainer}>
              <div className={returnCss.orderLink}>
                {orders.map((order) => (
                  <div key={order.shopify.name}>
                    {order.shopify.name !== NULL_ORDER_ID && (
                      <div className={returnCss.external}>
                        {order && orderExternalUrl(return_.team, order) ? (
                          <ExternalLink
                            url={orderExternalUrl(return_.team, order)}
                          >
                            {order.shopify.name}
                          </ExternalLink>
                        ) : (
                          <span>{order.shopify.name}</span>
                        )}
                      </div>
                    )}
                  </div>
                ))}
              </div>

              <span className={returnCss.status}>
                <Pill size={PillSize.SMALL}>
                  {returnStatusName(return_.status)}
                </Pill>
                <Pill size={PillSize.SMALL}>
                  {returnKindNameReturn(return_, false)}
                </Pill>
                {((return_?.type === "claim" &&
                  orders.some((order) => order?.packageProtected)) ||
                  (return_?.type === "return" &&
                    orders.some((order) => order?.protected))) && (
                  <Pill size={PillSize.SMALL} theme={PillTheme.PRIMARY_LIGHT}>
                    Protected
                  </Pill>
                )}
                {return_.inStoreReturn && (
                  <Pill size={PillSize.SMALL}>In-Store Return</Pill>
                )}
              </span>
            </div>
          ) : (
            <SkeletonText length={8} />
          )
        }
      >
        {!return_ ||
        return_.type !== "claim" ? null : filedWithCarrierPending || pending ? (
          <CircleSpinner className={returnCss.icon} />
        ) : (
          <Checkbox
            onChange={handleFiledWithCarrierChange}
            value={!!return_.filedWithCarrier}
          >
            Filed with carrier
            {return_.filedWithCarrier
              ? ` (${formatDateTime(
                  Temporal.Instant.from(return_.filedWithCarrier.toString()),
                )})`
              : ""}
          </Checkbox>
        )}
      </Card>
      <Card title={`${returnTypeName(return_?.type, true)} items`}>
        <div className={returnCss.card}>
          <section className={cardCss.section}>
            {/* change this to handle one product multiple labels */}
            {hasMultipleShipments
              ? productsByGroupName.map((productGroup, index) => (
                  <div key={index}>
                    <h3>{productGroup.groupName}</h3>
                    {productGroup.products.map((item, index) => (
                      <Item
                        handleChangeReturnType={handleChangeReturnType}
                        itemToDisplay={item}
                        key={index}
                        order={orders?.find(
                          (order) =>
                            order._id.toString() === item.order._id.toString(),
                        )}
                        productTotals={(returnTotals?.productTotals || []).find(
                          (productTotals: ProductTotals) =>
                            productTotals.product._id === item._id,
                        )}
                        return_={return_}
                        returnLoadPending={pending}
                        setEditItemModalOpen={setEditItemModalOpen}
                        setEditSingleItemDispositionModalOpen={
                          setEditSingleItemDispositionModalOpen
                        }
                        setItemBeingEdited={setItemBeingEdited}
                      />
                    ))}
                    <span />
                  </div>
                ))
              : return_?.products.map((item, index) => (
                  <Item
                    handleChangeReturnType={handleChangeReturnType}
                    itemToDisplay={item}
                    key={index}
                    order={orders?.find(
                      (order) =>
                        order._id.toString() === item.order._id.toString(),
                    )}
                    productTotals={(returnTotals?.productTotals || []).find(
                      (productTotals: ProductTotals) =>
                        productTotals.product._id === item._id,
                    )}
                    return_={return_}
                    returnLoadPending={pending}
                    setEditItemModalOpen={setEditItemModalOpen}
                    setEditSingleItemDispositionModalOpen={
                      setEditSingleItemDispositionModalOpen
                    }
                    setItemBeingEdited={setItemBeingEdited}
                  />
                ))}
          </section>
        </div>
        {return_ && showNewItems(return_, team) && (
          <>
            <div className={returnCss.newItemHeader}>
              <div>
                <h3>New items</h3>
                {!!exchangeOrderModified && (
                  <ExternalLink
                    className={returnCss.originalPrice}
                    newTab={false}
                    onClick={() => setViewingOriginal(!viewingOriginal)}
                    showIcon={false}
                  >
                    {viewingOriginal ? "View Actual" : "View Original"}
                  </ExternalLink>
                )}
              </div>
            </div>
            {!!numNewItems() && (
              <div className={returnCss.newAddress}>
                {!return_ ? (
                  <h4>
                    <SkeletonText length={15} />
                  </h4>
                ) : (
                  <h4>Shipping to</h4>
                )}
                {canManageReturn &&
                  isExchangeOrderEditable(return_, team, user) && (
                    <ExternalLink
                      onClick={handleChangeNewOrderAddressModalOpen}
                      showIcon={false}
                    >
                      <EditPencil className={returnCss.editIcon} />
                      {"  "}Edit
                    </ExternalLink>
                  )}
                {!return_ ? (
                  <p>
                    <SkeletonText length={30} />
                  </p>
                ) : (
                  <p>
                    {return_.newOrderAddress?.name || getReturner(return_).name}
                  </p>
                )}
                <address className={cardCss.body}>
                  {return_ ? (
                    shopifyAddressString(
                      return_.newOrderAddress
                        ? return_.newOrderAddress
                        : return_.shipping_address
                          ? return_.shipping_address
                          : return_.products[0].order.shopify.shipping_address,
                    )
                  ) : (
                    <SkeletonText length={28} />
                  )}
                </address>
              </div>
            )}

            <section
              className={classNames(cardCss.section, returnCss.newItemsSection)}
            >
              {/* Variant exchange items */}
              {!!newItemsFromVariantExchange &&
                newItemsFromVariantExchange.map((item, index) => (
                  <NewItem
                    deleteExchangeItem={handleRemoveExchangeItem}
                    editable={canRemoveExchangeItems}
                    item={{
                      isVariantExchange: true,
                      images: item.exchangeGroupItem
                        ? [item.exchangeGroupItem.imageSrc]
                        : item.exchange_for
                          ? item.exchange_for.images
                          : item.images,
                      price:
                        (returnTotals?.productTotals || [])
                          ?.find(
                            (productTotals: ProductTotals) =>
                              productTotals.product._id === item._id,
                          )
                          ?.finalPrice.toString() || "",
                      title:
                        item.exchangeGroupItem?.title || item.product_title,
                      variantTitle:
                        item.exchangeGroupItem?.variantTitle ||
                        item.exchange_for?.variant_title ||
                        "",
                      variantId:
                        item.exchangeGroupItem?.variantId ||
                        item.exchange_for?.variant_id ||
                        "",
                      shopifyVariant: {
                        sku: item.exchange_for?.sku || "",
                        product_id: parseFloat(
                          item.exchangeGroupItem?.productId || item.product_id,
                        ),
                      },
                      quantity: item.quantity,
                      outOfStock: item.outOfStockDuringProcessing,
                    }}
                    key={index}
                    reload={reload}
                    return={return_}
                  />
                ))}
              {/* Advanced exchange items */}
              {!!newItemsFromAdvancedExchange &&
                newItemsFromAdvancedExchange.map((item, index) => (
                  <NewItem
                    deleteExchangeItem={handleRemoveExchangeItem}
                    editable={canRemoveExchangeItems}
                    item={{ ...item, isVariantExchange: false }}
                    key={index}
                    reload={reload}
                    return={return_}
                  />
                ))}
              {/* Currently only Shopify merchants are able to do advance exchanges */}
              {canManageReturn &&
                team?.platform === "shopify" &&
                isExchangeOrderEditable(return_, team, user) && (
                  <Flex>
                    <Button
                      onClick={() => setEditNewItemsModalOpen(true)}
                      pending={addExchangeItemsLoad}
                      size={ButtonSize.MICRO}
                      theme={ButtonTheme.OUTLINED}
                    >
                      <Flex align="center" dir="row" gap="sm">
                        Add new items
                      </Flex>
                    </Button>
                  </Flex>
                )}
            </section>
          </>
        )}
        <ProductSelectModal
          open={editNewItemsModalOpen}
          provider={orders?.[0]?.provider || Provider.SHOPIFY}
          selectedVariants={[]}
          setOpen={setEditNewItemsModalOpen}
          setSelectedVariants={(products: VariantInfo[]) => {
            void handleAddExchangeItems(products.map((product) => product.id));
          }}
        />
      </Card>
      {return_?.type === "claim" && (
        <Card title="Claim shipments">
          <div className={returnCss.card}>
            <section className={cardCss.section}>
              {orders?.map((order) =>
                order?.shopify.fulfillments
                  .filter((fulfillment) =>
                    fulfillment.line_items.find((li) =>
                      returnLineItems?.includes(String(li.id)),
                    ),
                  )
                  .map((fulfillment, index) => (
                    <Fulfillment fulfillment={fulfillment} key={index} />
                  )),
              )}
            </section>
          </div>
        </Card>
      )}
      {return_ && returnTotals && (
        <>
          {true && productToUpdate && (
            <ChangeReturnTypeModal
              newType={newType}
              onClose={handleChangeReturnTypeClose}
              open={returnTypeModalOpen}
              product={productToUpdate}
              reload={reload}
              return={return_}
            />
          )}
          {resetOpen && (
            <ResetModal
              onClose={handleResetClose}
              open={resetOpen}
              return={return_}
            />
          )}
          {reopenOpen && (
            <ReopenModal
              onClose={handleReopenClose}
              open={reopenOpen}
              reload={reload}
              return={return_}
            />
          )}
          {reapproveOpen && (
            <ApproveModal
              onClose={handleReapproveClose}
              open={reapproveOpen}
              orders={orders}
              reapprove
              reload={reload}
              return={return_}
            />
          )}
          {closeOpen && (
            <CloseModal
              onClose={handleCloseModalClose}
              open={closeOpen}
              reload={reload}
              return={return_}
            />
          )}
          {chargeRecoveryOpen && (
            <ChargeRecoveryModal
              onClose={handleRecoveryChargeClose}
              open={chargeRecoveryOpen}
              reload={reload}
              return={return_}
            />
          )}
          {restockItemsModalOpen &&
            team &&
            returnItemsToPrice &&
            orderProvider && (
              <RestockItemsModal
                closeModal={(_reload: boolean = false) => {
                  setRestockItemsModalOpen(false);
                  _reload && reload();
                }}
                open={restockItemsModalOpen}
                orderProvider={orderProvider}
                return_={return_}
                returnItemsToPrice={returnItemsToPrice}
                team={team}
              />
            )}
          {approveOpen &&
            (dropshipConfirmModalOpen ? (
              <DropshipConfirmationModal
                onAccept={handleDropshipApprove}
                onClose={handleDropshipClose}
                open={approveOpen}
              />
            ) : (
              <ApproveModal
                onClose={handleApproveClose}
                open={approveOpen}
                orders={orders}
                reapprove={false}
                reload={reload}
                return={return_}
              />
            ))}

          {disapproveOpen && (
            <DisapproveModal
              onClose={handleDisapproveClose}
              open={disapproveOpen}
              reload={reload}
              return={return_}
            />
          )}
          {cancelOpen && (
            <CancelModal
              onClose={handleCancelClose}
              open={cancelOpen}
              reload={reload}
              return={return_}
            />
          )}
          {processOpen && (
            <ProcessModal
              isProcessing={isProcessing}
              onClose={handleProcessClose}
              open={processOpen}
              orders={orders || []}
              reload={reload}
              return={return_}
              returnTotals={returnTotals}
              setIsProcessing={setIsProcessing}
            />
          )}
          {editItemModalOpen && returnProductBeingEdited && (
            <EditItemModal
              item={returnProductBeingEdited}
              onClose={handleEditItemModalClose}
              open={editItemModalOpen}
              reload={reload}
              return_={return_}
              returnTotals={returnTotals}
            />
          )}
          {editSingleItemDispositionModalOpen.open && (
            <EditSingleItemDispositionModal
              closeModal={(_reload: boolean = false) => {
                setEditSingleItemDispositionModalOpen({ open: false });
                _reload && reload();
              }}
              item={editSingleItemDispositionModalOpen.item}
              open={editSingleItemDispositionModalOpen.open}
              return_={return_}
            />
          )}
          {itemDispositionModalOpen &&
            team &&
            returnItemsToPrice &&
            orderProvider && (
              <ItemDispositionModal
                closeModal={(_reload: boolean = false) => {
                  setItemDispositionModalOpen(false);
                  _reload && reload();
                }}
                open={itemDispositionModalOpen}
                orderProvider={orderProvider}
                return_={return_}
                returnItemsToPrice={returnItemsToPrice}
                team={team}
              />
            )}
          {flagModalOpen && (
            <FlagModal
              flag
              onClose={handleFlagModalClose}
              open={flagModalOpen}
              reload={reload}
              return={return_}
            />
          )}
          {unflagModalOpen && (
            <FlagModal
              flag={false}
              onClose={handleUnflagModalClose}
              open={unflagModalOpen}
              reload={reload}
              return={return_}
            />
          )}
          {changeNewOrderAddressModalOpen && (
            <ChangeNewOrderAddressModal
              onClose={handleChangeNewOrderAddressModalClose}
              open={changeNewOrderAddressModalOpen}
              reload={reload}
              return={return_}
            />
          )}
          {resentInvoiceSuccessMessageOpen && (
            <ResentInvoiceSuccessMessage
              onClose={handleResentInvoiceSuccessMessageClose}
              open={resentInvoiceSuccessMessageOpen}
            />
          )}
          {refundOpen && (
            <RefundRecoveryChargeModal
              onClose={handleRefundClose}
              open={refundOpen}
              reload={reload}
              returnItem={return_}
            />
          )}
        </>
      )}
      <RedoModal
        buttonPlacement={ButtonPlacement.TIGHT}
        isOpen={resendInvoiceModalOpen}
        modalSize={RedoModalSize.SMALL}
        onModalCloseRequested={() => {
          setResendInvoiceModalOpen(false);
        }}
        primaryButton={{
          text: "Send",
          onClick: async () => {
            await handleResendDraftEmail();
            setResendInvoiceModalOpen(false);
          },
          disabled: false,
        }}
        secondaryButton={{
          text: "Cancel",
          onClick: () => {
            setResendInvoiceModalOpen(false);
          },
          disabled: false,
        }}
        subtitle="This will send an email with the invoice to the customer"
        theme={RedoModalTheme.SUCCESS}
        title="Resend Invoice"
      >
        <FormTextInput
          input={EmailInput.inputs.email}
          label="Email Address"
          labelTheme={LabelTheme.THIN}
        />
      </RedoModal>
    </>
  );
});

const Fulfillment = memo(function Fulfillment({
  fulfillment,
}: {
  fulfillment: Fulfillment;
}) {
  return (
    <div className={returnCss.item}>
      <div className={returnCss.itemMain}>
        <h4>Fulfillment name: {fulfillment.name}</h4>
        <p>Shipping carrier: {fulfillment.tracking_company}</p>
        <p>Shipment status: {fulfillment.shipment_status ?? "Unknown"}</p>
        {fulfillment.tracking_numbers?.length ? (
          <>
            <p>Tracking number(s)</p>
            <ul className={returnCss.trackingNumbers}>
              {fulfillment.tracking_numbers.map((trackingNumber, index) => (
                <li key={trackingNumber}>
                  {fulfillment.tracking_urls?.[index] ? (
                    <ExternalLink url={fulfillment.tracking_urls[index]}>
                      {trackingNumber}
                    </ExternalLink>
                  ) : (
                    trackingNumber
                  )}
                </li>
              ))}
            </ul>
          </>
        ) : null}
      </div>
    </div>
  );
});

function itemHasSharedDiscount(item: any, order?: Order) {
  if (!order) {
    return false;
  }
  const shopifyLineItem = order?.shopify?.line_items?.find(
    (lineItem) => lineItem.id === +item.line_item_id,
  );
  const associatedDiscounts = shopifyLineItem?.discount_allocations.map(
    (discount: any) => discount.discount_application_index,
  );
  let hasSharedDiscount = false;

  for (const lineItem of order?.shopify?.line_items || []) {
    if (lineItem.id === +item.line_item_id) {
      continue;
    }
    hasSharedDiscount = !!lineItem?.discount_allocations?.find(
      (discount: any) =>
        associatedDiscounts.includes(discount.discount_application_index),
    );
    if (hasSharedDiscount) {
      return true;
    }
  }
  return hasSharedDiscount;
}

const Item = memo(function Item({
  return_,
  returnLoadPending,
  itemToDisplay,
  productTotals,
  handleChangeReturnType,
  setEditItemModalOpen,
  setItemBeingEdited,
  setEditSingleItemDispositionModalOpen,
  order,
}: {
  itemToDisplay: Return["products"][number];
  return_: Return;
  returnLoadPending: boolean;
  productTotals?: ProductTotals;
  handleChangeReturnType: (
    newType: string,
    product: Return["products"][number],
  ) => void;
  setEditItemModalOpen: (open: boolean) => void;
  setEditSingleItemDispositionModalOpen: (
    open: ItemDispositionModalOpen,
  ) => void;
  setItemBeingEdited: (item: any) => void; // TODO type
  order?: Order;
}) {
  const [variantImage, setVariantImage] = useState(undefined);
  const [loading, setLoading] = useState(true);
  const team = useContext(TeamContext);
  const user = useContext(UserContext);
  const client = useRequiredContext(RedoClientContext);
  const merchantClient = useRequiredContext(RedoMerchantRpcClientContext);
  const [detailsExpanded, setDetailsExpanded] = useState(false);
  const [viewingOriginal, setViewingOriginal] = useState(false);
  const [item, setItem] = useState<Return["products"][number]>({
    ...itemToDisplay,
  });
  const [returnTypeModalOpen, setReturnTypeModalOpen] = useState(false);

  const { formatCurrency } = useContext(CurrencyContext);

  const warehouseLocationLoad = useLoad(async () => {
    if (!team || !team.settings.returns?.warehouseLocationEnabled) {
      return undefined;
    }
    return (
      await merchantClient.getWarehouseForProduct({
        variantId: itemToDisplay.variant_id,
      })
    ).warehouseName;
  }, [team]);

  useEffect(() => {
    setItem({ ...itemToDisplay });
  }, [itemToDisplay]);

  useEffect(() => {
    if (team) {
      const widgetClient = new WidgetClient(client, team.widget_slug);
      const lineItem = (order?.shopify?.line_items || []).find(
        (line_item: any) => line_item.id.toString() === item.line_item_id,
      );
      if (lineItem) {
        void getProduct(widgetClient, {
          productId: String(item.product_id),
        }).then((res: any) => {
          const product = res;
          const variant = product.variants.find(
            (variant: any) => variant.id.toString() === item.variant_id,
          );
          if (variant && variant.image_id) {
            const image = product.images.find(
              (image: any) => image.id === variant.image_id,
            );
            setVariantImage(image.src);
          }
          setLoading(false);
        });
      }
    }
  }, [team]);

  useEffect(() => {
    if (viewingOriginal && item.originalVariantOptions) {
      item.strategy = item.originalVariantOptions.strategy;
      item.exchange_for = item.originalVariantOptions.exchange_for;
      item.merchant_adjustment = undefined;
      item.price_adjustment =
        item.originalVariantOptions.price_adjustment || "0";
      setItem({ ...item });
    } else {
      setItem({ ...itemToDisplay });
    }
  }, [viewingOriginal]);

  if (!item.sku) {
    const shopifyProduct =
      return_.products[0]?.order?.shopify?.line_items?.find(
        (lineItem: any) => lineItem.id == item.line_item_id,
      );
    if (shopifyProduct?.sku) {
      item.sku = shopifyProduct?.sku;
    }
  }

  /*
    let exchange: string | undefined;
    if (item.exchange_for) {
      exchange = item.exchange_for.product_title;
      if (item.exchange_for.option1) {
        exchange += ` ${item.exchange_for.option1}`;
      }
      if (item.exchange_for.option2) {
        exchange += ` ${item.exchange_for.option2}`;
      }
      if (item.exchange_for.option3) {
        exchange += ` ${item.exchange_for.option3}`;
      }
      if (item.exchange_for.sku) {
        exchange += ` (${item.exchange_for.sku})`;
      }
    }
    */

  const canEditReturnType =
    hasEditReturnPermissions(team, user) &&
    !viewingOriginal &&
    ![
      ReturnedProductStatus.COMPLETE,
      ReturnedProductStatus.PENDING,
      ReturnedProductStatus.REJECTED,
    ].includes(item.status) &&
    !return_.draftOrderId &&
    !return_.exchangeOrder.length &&
    !return_.instantRefund;

  const originalPrice =
    (productTotals?.finalPrice || 0) -
    (productTotals?.merchantAdjustment || 0) -
    (productTotals?.priceAdjustment || 0) +
    parseFloat(item.originalVariantOptions?.price_adjustment || "0");
  const priceWithMerchantAdjustment = productTotals?.finalPrice || 0;
  const returnType = isVariantExchange(item)
    ? "exchange"
    : item.strategy === "store_credit"
      ? "store_credit"
      : "refund";
  const openModal = () => {
    if (canEditReturnType) {
      setReturnTypeModalOpen(true);
    }
  };

  const canAdjustReturnValue =
    hasEditReturnPermissions(team, user) &&
    ![ReturnStatus.COMPLETE, ReturnStatus.REJECTED].includes(return_.status) &&
    ![ReturnedProductStatus.COMPLETE, ReturnedProductStatus.PENDING].includes(
      item.status,
    ) &&
    !["repair"].includes(item.strategy);

  const closeModal = () => {
    setReturnTypeModalOpen(false);
  };

  const nonZeroValueExchange =
    team?.settings?.exchanges?.nonZeroValueExchange &&
    return_.type === "claim" &&
    return_.advancedExchangeItems.length > 0;

  const getItemType = () => {
    let itemType = returnKindNameProduct(item, return_.provision, return_.type);
    if (itemType !== "Exchange" && return_.advancedExchangeItems?.length) {
      itemType = `Exchange + ${itemType}`;
    }

    if (nonZeroValueExchange) {
      itemType = "Exchange";
    }

    return itemType;
  };
  const itemType = getItemType();
  const bonusType = itemType.includes("Exchange") ? "Shop now" : itemType;
  let discountType;

  if (
    team?.settings.discountDistributionMethod ===
    DiscountDistributionMethod.ORDER
  ) {
    discountType = "Discount distributed across order";
  } else if (
    team?.settings.discountDistributionMethod ===
      DiscountDistributionMethod.FAMILY &&
    order &&
    itemHasSharedDiscount(item, order)
  ) {
    discountType = "Discount distributed across discount family";
  } else {
    discountType = "Discount";
  }

  const totalReturnPrice = viewingOriginal
    ? originalPrice
    : priceWithMerchantAdjustment;

  const questionAnswers = item.multipleChoiceAnswers || [];

  const shouldShowItemDisposition = useMemo(
    () =>
      team?.settings.returnItemDisposition.enabled ||
      item.merchant_grade ||
      item.merchant_outcome ||
      item.merchant_notes,
    [team, item],
  );

  const itemDispositionEmptyValue = "-";
  const isItemDropshipped = !!item?.dropshipSupplierName;

  return (
    <div className={returnCss.item}>
      {loading ? (
        <div className={returnCss.itemImage}>
          <SkeletonBlock />
        </div>
      ) : (
        <img
          className={returnCss.itemImage}
          src={variantImage || item.images[0]}
        />
      )}
      <div className={returnCss.itemMain}>
        <div className={returnCss.itemHeader}>
          <div className={returnCss.product}>
            <div className={returnCss.itemTitle}>
              {team?.platform === "shopify" ? (
                <ExternalLink
                  url={
                    team &&
                    shopifyAdminProductUrl(
                      shopifyStoreName(team.storeUrl),
                      item.product_id,
                    ).toString()
                  }
                >
                  {item.product_title}
                </ExternalLink>
              ) : (
                <span>{item.product_title}</span>
              )}

              <div className={returnCss.itemType}>
                <Pill size={PillSize.SMALL}>{itemStatusName(item.status)}</Pill>
              </div>

              <div>
                {returnTypeModalOpen && (
                  <>
                    <div
                      className={returnCss.clickAwayListener}
                      onClick={closeModal}
                    />
                    <div className={returnCss.returnTypeModal}>
                      <div className={returnCss.returnTypeModalHeader}>
                        <div>
                          {return_.type === "claim"
                            ? "Edit Fulfillment Method"
                            : `Edit ${returnTypeName(return_.type, true)} Type`}
                        </div>
                      </div>
                      {returnType !== "store_credit" && (
                        <button
                          className={returnCss.returnTypeModalButton}
                          onClick={() => {
                            handleChangeReturnType("store_credit", item);
                            closeModal();
                          }}
                        >
                          {!!return_.advancedExchangeItems?.length &&
                            "Exchange + "}
                          Store Credit
                        </button>
                      )}
                      {returnType !== "refund" && (
                        <button
                          className={returnCss.returnTypeModalButton}
                          onClick={() => {
                            handleChangeReturnType("refund", item);
                            closeModal();
                          }}
                        >
                          {!!return_.advancedExchangeItems?.length &&
                            "Exchange + "}
                          Refund
                        </button>
                      )}
                    </div>
                  </>
                )}
                {item.strategy !== "none" && (
                  <div
                    className={
                      canEditReturnType
                        ? classNames(returnCss.itemType, returnCss.clickable)
                        : returnCss.itemType
                    }
                    onClick={openModal}
                  >
                    <Pill size={PillSize.SMALL}>
                      {getItemType()}
                      {canEditReturnType && (
                        <EditPencil className={returnCss.editIcon} />
                      )}
                    </Pill>
                  </div>
                )}
              </div>
              {item.green_return && return_.type !== "claim" && (
                <div className={returnCss.itemType}>
                  <Pill size={PillSize.SMALL}>Green Return</Pill>
                </div>
              )}

              {isItemDropshipped && return_.type !== ReturnTypeEnum.CLAIM && (
                <div className={returnCss.itemType}>
                  <Pill size={PillSize.SMALL} theme={PillTheme.NEUTRAL_YELLOW}>
                    Dropshipped
                  </Pill>
                </div>
              )}
            </div>
          </div>

          <span className={returnCss.itemPrice}>
            <div className={returnCss.itemPriceContainer}>
              <div className={returnCss.editItem}>
                {canAdjustReturnValue && (
                  <div className={returnCss.itemPriceEdit}>
                    <ExternalLink
                      onClick={() => {
                        setItemBeingEdited(item);
                        setEditItemModalOpen(true);
                      }}
                      showIcon={false}
                    >
                      <EditPencil className={returnCss.editIcon} />
                      {"  "}Edit
                    </ExternalLink>
                  </div>
                )}
                <div className={returnCss.itemValue}>
                  {viewingOriginal
                    ? formatCurrency(originalPrice)
                    : formatCurrency(priceWithMerchantAdjustment)}
                </div>
              </div>
              {item.originalVariantOptions && (
                <ExternalLink
                  className={classNames(
                    returnCss.originalPrice,
                    returnCss.right,
                  )}
                  onClick={() => setViewingOriginal(!viewingOriginal)}
                  showIcon={false}
                >
                  {viewingOriginal ? "Actual: " : "Original: "}
                  {viewingOriginal
                    ? formatCurrency(priceWithMerchantAdjustment)
                    : formatCurrency(originalPrice)}
                </ExternalLink>
              )}
            </div>
          </span>
        </div>
        {(item.sku || team?.settings.products.barcode) && (
          <div className={returnCss.itemOptions}>
            {item.sku && <span>{item.sku}</span>}
            {item.sku && team?.settings.products.barcode && <>|</>}
            {team?.settings.products.barcode && (
              <ExternalLink
                className={returnCss.itemOptionsText}
                url={`${REDO_MERCHANT_SERVER_URL}/returns2/${return_._id}/items/${item._id}/barcode`}
              >
                Barcode
              </ExternalLink>
            )}
          </div>
        )}
        <div className={returnCss.itemOptions}>
          {/*Todo i would like to use replaceAll here but am getting a typescript error*/}
          {/*{item.variant_title.replaceAll("/", " | "}*/}
          {item.variant_title}
        </div>
        <dl className={returnCss.itemProperties}>
          {warehouseLocationLoad.value && (
            <div className={returnCss.itemProperty}>
              <dt className={returnCss.itemPropertyName}>Warehouse</dt>
              <dd className={returnCss.itemPropertyValue}>
                {warehouseLocationLoad.value}
              </dd>
            </div>
          )}
          {(!!item.rejectMessage || !!item.reason) && (
            <div className={returnCss.itemProperty}>
              <dt className={returnCss.itemPropertyName}>
                {item.rejectMessage ? "Reject reason" : "Reason"}
              </dt>
              <dd className={returnCss.itemPropertyValue}>
                {item.rejectMessage || item.reason}
              </dd>
            </div>
          )}
          {!!item.flaggedReason && (
            <div className={returnCss.itemProperty}>
              <dt className={returnCss.itemPropertyName}>Flagged reason</dt>
              <dd className={returnCss.itemPropertyValue}>
                {item.flaggedReason}
              </dd>
            </div>
          )}
          {!!item.manualReviewReason && (
            <div className={returnCss.itemProperty}>
              <dt className={returnCss.itemPropertyName}>
                Manual review reason
              </dt>
              <dd className={returnCss.itemPropertyValue}>
                {item.manualReviewReason}
              </dd>
            </div>
          )}
          {questionAnswers.map((answer, index) => (
            <div className={returnCss.itemExtra} key={index}>
              {answer.questionText} &mdash;{" "}
              {answer.answer[0].toUpperCase() + answer.answer.slice(1)}
            </div>
          ))}
        </dl>
        <Flex
          className={classNames(detailsCardCss.itemDetailsContainer, {
            [detailsCardCss.detailsExpanded]: detailsExpanded,
          })}
          dir="column"
          gap="sm"
        >
          <div
            className={returnCss.priceSummaryTitle}
            onClick={() => {
              setDetailsExpanded(!detailsExpanded);
            }}
          >
            <ChevronRightIcon className={detailsCardCss.detailsCaret} />
            Details
          </div>

          <Flex dir="column" gap="xs">
            <Text
              fontSize="xs"
              fontWeight="semibold"
              mb="sm"
              textColor="primary"
            >
              Price summary
            </Text>
            <div className={returnCss.itemProperty}>
              {productTotals &&
                productTotals.originalPrice >= productTotals.discountPrice && (
                  <div className={classNames(returnCss.itemExtra)}>
                    {formatCurrency(productTotals.originalPrice || 0)} Retail
                    Price
                  </div>
                )}
            </div>
            {productTotals &&
              productTotals.discountPrice < productTotals.originalPrice && (
                <div className={returnCss.itemExtra}>
                  {`${formatCurrency(productTotals ? productTotals?.discountPrice - productTotals?.originalPrice : 0)} ${discountType}`}
                </div>
              )}
            <div className={returnCss.itemExtra}>
              <Adjustment isGreenReturn={false} itemType={bonusType}>
                {viewingOriginal
                  ? parseFloat(
                      item.originalVariantOptions?.price_adjustment || "0",
                    )
                  : productTotals?.priceAdjustment || 0}
              </Adjustment>
            </div>
            {!!productTotals?.tax && (
              <div className={returnCss.itemProperty}>
                <div className={returnCss.itemExtra}>
                  {formatCurrency(productTotals?.tax || 0)} tax
                </div>
              </div>
            )}
            {productTotals &&
              productTotals.merchantAdjustment !== 0 &&
              !viewingOriginal && (
                <div className={returnCss.itemProperty}>
                  <div className={returnCss.itemExtra}>
                    {productTotals?.merchantAdjustment > 0 && <>+</>}
                    {formatCurrency(productTotals.merchantAdjustment)} merchant
                    adjustment
                  </div>
                </div>
              )}
            {return_.settlement?.accepted && (
              <div className={returnCss.itemProperty}>
                <div className={returnCss.itemExtra}>
                  {formatCurrency(
                    -(
                      totalReturnPrice -
                      totalReturnPrice * return_.settlement?.refund
                    ),
                  )}{" "}
                  settlement
                </div>
              </div>
            )}
            <div className={returnCss.itemSummaryLine} />
            <div className={returnCss.itemExtra}>
              {return_.settlement?.accepted
                ? formatCurrency(totalReturnPrice * return_.settlement.refund)
                : formatCurrency(totalReturnPrice)}{" "}
              {item.strategy === "refund"
                ? "refund"
                : isVariantExchange(item)
                  ? "exchange value"
                  : "store credit"}{" "}
            </div>
          </Flex>
          {shouldShowItemDisposition && (
            <Flex dir="column" gap="lg" mt="lg">
              <Flex align="center" justify="space-between">
                <Text fontSize="xs" fontWeight="semibold" textColor="primary">
                  Item disposition
                </Text>
                {team?.settings.returnItemDisposition.enabled && (
                  <RedoButton
                    hierarchy={RedoButtonHierarchy.LINK_GRAY}
                    onClick={() =>
                      setEditSingleItemDispositionModalOpen({
                        open: true,
                        item,
                      })
                    }
                    size={RedoButtonSize.EXTRA_SMALL}
                    text="Edit"
                  />
                )}
              </Flex>
              <Flex dir="column" gap="xl">
                <Flex dir="column">
                  <Text fontSize="xs" fontWeight="medium" textColor="primary">
                    Grade
                  </Text>
                  {returnLoadPending ? (
                    <SkeletonText length={16} />
                  ) : (
                    <Text
                      fontSize="xs"
                      fontWeight="regular"
                      textColor="secondary"
                    >
                      {item.merchant_grade
                        ? item.merchant_grade
                        : itemDispositionEmptyValue}
                    </Text>
                  )}
                </Flex>
                <Flex dir="column">
                  <Text fontSize="xs" fontWeight="medium" textColor="primary">
                    Outcome
                  </Text>
                  {returnLoadPending ? (
                    <SkeletonText length={16} />
                  ) : (
                    <Text
                      fontSize="xs"
                      fontWeight="regular"
                      textColor="secondary"
                    >
                      {item.merchant_outcome
                        ? item.merchant_outcome
                        : itemDispositionEmptyValue}
                    </Text>
                  )}
                </Flex>
                <Flex dir="column">
                  <Text fontSize="xs" fontWeight="medium" textColor="primary">
                    Notes
                  </Text>
                  {returnLoadPending ? (
                    <SkeletonText length={16} />
                  ) : item.merchant_notes ? (
                    <MessageBubble
                      alignment={MessageBubbleAlignment.LEFT}
                      theme={MessageBubbleTheme.LIGHT}
                    >
                      <Text
                        fontSize="sm"
                        fontWeight="regular"
                        textColor="primary"
                      >
                        {item.merchant_notes}
                      </Text>
                    </MessageBubble>
                  ) : (
                    <Text
                      fontSize="sm"
                      fontWeight="regular"
                      textColor="secondary"
                    >
                      {itemDispositionEmptyValue}
                    </Text>
                  )}
                </Flex>
              </Flex>
            </Flex>
          )}
        </Flex>
      </div>
    </div>
  );
});

const Adjustment = memo(function Adjustment({
  children: adjustmentValue,
  isGreenReturn,
  itemType,
}: {
  children: number;
  isGreenReturn: boolean;
  itemType?: string;
}) {
  const showAdjustment = adjustmentValue !== 0;
  return showAdjustment || isGreenReturn ? (
    isGreenReturn ? (
      <div>{CURRENCY_FORMAT().format(adjustmentValue)}</div>
    ) : (
      <div
        className={
          isGreenReturn
            ? returnCss.greenReturnAdjustment
            : adjustmentValue > 0
              ? returnCss.adjustmentBonus
              : returnCss.adjustmentFee
        }
      >
        {adjustmentValue != 0 && (
          <div>
            {adjustmentValue > 0 && <>+</>}
            {CURRENCY_FORMAT().format(adjustmentValue)}
            {adjustmentValue > 0 && !isGreenReturn && itemType && (
              <> {` ${itemType} bonus`}</>
            )}
          </div>
        )}
      </div>
    )
  ) : null;
});

const ResentInvoiceSuccessMessage = memo(function ResentInvoiceSuccessMessage({
  open,
  onClose,
}: {
  open: boolean;
  onClose(): void;
}) {
  return (
    <Snackbar autoHideDuration={6000} onClose={onClose} open={open}>
      <Alert onClose={onClose} severity="success">
        Invoice email successfully sent
      </Alert>
    </Snackbar>
  );
});

const NewItem = memo(function NewItem({
  item,
  return: return_,
  reload,
  editable,
  deleteExchangeItem,
}: {
  item: NewItem;
  return: Return;
  reload: () => void;
  editable: boolean;
  deleteExchangeItem: (variantId: string) => Promise<boolean>;
}) {
  const [detailsExpanded, setDetailsExpanded] = useState(false);

  const team = useContext(TeamContext);
  const rpcClient = useRequiredContext(RedoMerchantRpcClientContext);
  const [removeItemLoad, doRemoveItem] = useTriggerLoad((signal) =>
    alertOnFailure("Failed to remove item")(async () => {
      if (!team || !item.variantId) {
        throw new Error("Missing team or variant ID");
      }
      let success = false;
      if (item.isVariantExchange) {
        const response = await rpcClient.removeVariantExchangeItem(
          {
            teamId: team._id,
            returnId: return_._id,
            variantId: item.variantId,
          },
          { signal },
        );
        success = response.success;
      } else {
        success = await deleteExchangeItem(item.variantId);
      }
      if (!success) {
        throw new Error("Failed to remove item");
      }
      reload();
    }),
  );

  const { formatCurrency } = useContext(CurrencyContext);

  return (
    <div className={returnCss.item}>
      <img className={returnCss.itemImage} src={item.images?.[0]} />
      <div className={returnCss.itemMain}>
        <div className={returnCss.itemHeader}>
          <div className={returnCss.product}>
            <Flex justify="space-between" style={{ width: "100%" }}>
              <div className={returnCss.itemTitle}>
                {item.shopifyVariant ? (
                  <ExternalLink
                    url={
                      team &&
                      shopifyAdminProductUrl(
                        shopifyStoreName(team.storeUrl),
                        item.shopifyVariant.product_id.toString(),
                      ).toString()
                    }
                  >
                    {item.title}
                  </ExternalLink>
                ) : (
                  <span>{item.title}</span>
                )}
                {!!item.outOfStock && (
                  <RedoBadge
                    color={RedoBadgeColor.ERROR}
                    size={RedoBadgeSize.SMALL}
                    text="Out of stock"
                  />
                )}
              </div>
              {editable && (
                <div className={returnCss.deleteIconContainer}>
                  {removeItemLoad.pending ? (
                    <CircleSpinner />
                  ) : (
                    <IconButton
                      onClick={() => {
                        doRemoveItem();
                      }}
                    >
                      <Icon
                        arbiterIconSvg={TrashIcon}
                        className={returnCss.deleteIcon}
                        color="ghost"
                        style={{ height: 18, width: 18 }}
                      />
                    </IconButton>
                  )}
                </div>
              )}
            </Flex>
            {item.shopifyVariant?.sku && (
              <div className={returnCss.itemSubtitle}>
                {item.shopifyVariant.sku}
              </div>
            )}
          </div>
        </div>
        <div className={returnCss.itemOptions}>{item.variantTitle}</div>
        <div className={returnCss.itemSubtitle}>Quantity: {item.quantity}</div>
        {item.attributes?.map((attribute, index) => (
          <div className={returnCss.itemOptions} key={index}>
            {attribute.key}: {attribute.value}
          </div>
        ))}
        <Flex
          className={classNames(detailsCardCss.itemDetailsContainer, {
            [detailsCardCss.detailsExpanded]: detailsExpanded,
          })}
          dir="column"
          gap="sm"
        >
          <div
            className={returnCss.priceSummaryTitle}
            onClick={() => {
              setDetailsExpanded(!detailsExpanded);
            }}
          >
            <ChevronRightIcon className={detailsCardCss.detailsCaret} />
            Details
          </div>

          <Flex dir="column" gap="xs">
            <Text
              fontSize="xs"
              fontWeight="semibold"
              mb="sm"
              textColor="primary"
            >
              Price summary
            </Text>
            <div className={returnCss.itemProperty}>
              {!!item.price && (
                <div className={classNames(returnCss.itemExtra)}>
                  {formatCurrency(item.price || 0)}{" "}
                  {item.isVariantExchange ? "Exchange value" : "Retail Price"}
                </div>
              )}
            </div>
            <div className={returnCss.itemProperty} />
            {!!item.itemValue &&
              !!item.price &&
              item.itemValue < item.price && (
                <div className={returnCss.itemExtra}>
                  {`${formatCurrency(parseFloat(item.itemValue) - parseFloat(item.price))} Discount`}
                </div>
              )}
            <div className={returnCss.itemSummaryLine} />
            <div className={returnCss.itemExtra}>
              {item.itemValue && item.price
                ? formatCurrency(
                    Math.min(
                      parseFloat(item.itemValue),
                      parseFloat(item.price),
                    ),
                  )
                : formatCurrency(item.itemValue || item.price || 0)}{" "}
            </div>
          </Flex>
        </Flex>
      </div>
    </div>
  );
});

export function orderExternalUrl(team: Team, order: Order): string | undefined {
  switch (order.provider) {
    case Provider.COMMENTSOLD: {
      const url = new URL("https://commentsold.com/admin/orders");
      url.searchParams.set("order", order.shopify.name.replace("CS-", ""));
      url.searchParams.set("scanned", "false");
      return url.toString();
    }
    case Provider.SHOPIFY:
      return `https://${team.storeUrl}/admin/orders/${order.shopify_id || ""}`;
  }
  return undefined;
}

// const Adjustment = memo(function Adjustment
//   ({
//     children: adjustmentValue,
//     isGreenReturn,
//   }: {
//     children: number;
//     isGreenReturn: boolean;
//   }) {//     const showAdjustment = adjustmentValue !== 0;
//     return showAdjustment || isGreenReturn ? (
//       isGreenReturn ? (
//         <div>{CURRENCY_FORMAT().format(adjustmentValue)}</div>
//       ) : (
//         <div
//           className={
//             isGreenReturn
//               ? returnCss.greenReturnAdjustment
//               : adjustmentValue > 0
//                 ? returnCss.adjustmentBonus
//                 : returnCss.adjustmentFee
//           }
//         >
//           {adjustmentValue != 0 && (
//             <div>
//               {adjustmentValue > 0 && <>+</>}
//               {CURRENCY_FORMAT().format(adjustmentValue)}
//             </div>
//           )}
//         </div>
//       )
//     ) : null;
//   },
// );
