import { FulfillmentOrderData } from "@redotech/redo-model/fulfillments/fulfillment-group";
import { SortDirection } from "@redotech/redo-model/tables/table";
import { RedoTableTextCell } from "@redotech/redo-web/arbiter-components/tables/redo-table-cells";
import { ColumnAlignment } from "@redotech/redo-web/table";
import { Column2 } from "@redotech/redo-web/table2";

export const UniqueItemsColumn: Column2<FulfillmentOrderData> = {
  key: "total-sku-count",
  title: "Number of line items",
  alignment: ColumnAlignment.LEFT,
  renderNormalCell: ({ row }) => {
    return <RedoTableTextCell text={`${row.totalSkuCount}`} />;
  },
  width: 120,
  sort: SortDirection.DESC,
};
