import { Drawer } from "@mui/material";
import { FulfillmentOrderUpdate } from "@redotech/merchant-sdk/outbound-labels-rpc/schema/update-fulfillment-groups";
import { useRequiredContext } from "@redotech/react-util/context";
import { useWindowEventListener } from "@redotech/react-util/event";
import { useHandler } from "@redotech/react-util/hook";
import { useLoad, useTriggerLoad } from "@redotech/react-util/load";
import { PaymentSchedule } from "@redotech/redo-model/balance/balances";
import {
  FulfillmentOrderData,
  getDisplayWeightGrams,
  getWeightWithParcelGrams,
  OMSUser,
} from "@redotech/redo-model/fulfillments/fulfillment-group";
import {
  canPurchaseLabelStatus,
  FulfillmentGroupStatus,
} from "@redotech/redo-model/fulfillments/fulfillment-group-status";
import { canVoidLabel } from "@redotech/redo-model/fulfillments/fulfillment-group-utils";
import { FulfillmentOrderLineItem } from "@redotech/redo-model/fulfillments/fulfillment-order-line-item";
import { OutboundRate } from "@redotech/redo-model/outbound-labels/outbound-labels";
import {
  Parcel,
  ParcelType,
} from "@redotech/redo-model/outbound-labels/parcel";
import { Preset } from "@redotech/redo-model/outbound-labels/presets";
import {
  convertWeight,
  Weight,
  WeightUnit,
} from "@redotech/redo-model/outbound-labels/util";
import { toast } from "@redotech/redo-web/alert";
import {
  RedoButton,
  RedoButtonHierarchy,
  RedoButtonSize,
  RedoButtonTheme,
} from "@redotech/redo-web/arbiter-components/buttons/redo-button";
import {
  RedoCheckbox,
  RedoCheckboxSize,
} from "@redotech/redo-web/arbiter-components/checkbox/redo-checkbox";
import { RedoDatePicker } from "@redotech/redo-web/arbiter-components/date-picker/redo-date-picker";
import { RedoListItem } from "@redotech/redo-web/arbiter-components/list/redo-list";
import { RedoBaseModal } from "@redotech/redo-web/arbiter-components/modal/redo-base-modal";
import {
  RedoModalHeader,
  RedoModalHeaderIconAlignment,
} from "@redotech/redo-web/arbiter-components/modal/redo-modal";
import {
  RedoDropdownInputSize,
  RedoSingleSelectDropdownInput,
} from "@redotech/redo-web/arbiter-components/select-dropdown/redo-single-select-dropdown-input";
import PackageX from "@redotech/redo-web/arbiter-icon/package-x.svg";
import XIcon from "@redotech/redo-web/arbiter-icon/x-close.svg";
import { IconButton } from "@redotech/redo-web/button";
import { Flex } from "@redotech/redo-web/flex";
import { Text } from "@redotech/redo-web/text";
import { memo, useContext, useEffect, useMemo, useState } from "react";
import { RedoOutboundLabelsRpcClientContext } from "../../app/redo-outbound-labels-rpc-client-provider";
import { TeamContext } from "../../app/team";
import { UserContext } from "../../app/user";
import {
  CreateAndPrintButton,
  CreateAndPrintButtonActions,
} from "../common/create-and-print-button";
import { ItemsTable } from "../common/items-table";
import { OrderFulfillmentAddress } from "../common/order-fulfillment-address";
import { ParcelSelectDropdown } from "../common/parcel-select-dropdown";
import { ParcelSizeWidget } from "../common/parcel-size-widget";
import {
  OutboundRateOption,
  ShipmentRateSelectionDropdown,
} from "../common/shipment-rate-selection-dropdown";
import { TotalWeightWidget } from "../common/total-weight-widget";
import { UserSelectDropdown } from "../common/user-select-dropdown";
import * as ordersCss from "./outbound-labels.module.css";

const TOMORROW = new Date();
TOMORROW.setDate(TOMORROW.getDate() + 1);

export const OrderFulfillmentSlideout = memo(function OrderFulfillmentSlideout({
  fulfillmentGroups,
  deselectComplete,
  onClose,
  parcelValues,
  removeRows,
  updateRows,
  updatesPending,
  balance,
  balancePaymentSchedule,
  availableUsers,
  reloadBalance,
  resetSelections,
  triggerRefresh,
}: {
  fulfillmentGroups: readonly FulfillmentOrderData[];
  deselectComplete: () => void;
  onClose: () => void;
  removeRows: (rows: string[]) => void;
  parcelValues: readonly Parcel[];
  updateRows: (updates: FulfillmentOrderUpdate[]) => void;
  updatesPending: boolean;
  balance: string | undefined;
  balancePaymentSchedule: PaymentSchedule | undefined;
  availableUsers: OMSUser[];
  reloadBalance: () => void;
  resetSelections: () => void;
  triggerRefresh: () => void;
}) {
  // TODO: Make sure all shipping rates are based on the current parcel / weight / address / date info.
  // If not, we need to prevent buying labels until we automatically refresh the rates.
  const client = useRequiredContext(RedoOutboundLabelsRpcClientContext);
  const team = useContext(TeamContext);
  const user = useContext(UserContext);

  const [fulfillmentDate, setFulfillmentDate] = useState<Date | null>(null);

  const availableRates: readonly OutboundRateOption[] = useMemo(
    () => summarizeOutboundRateOptions(fulfillmentGroups),
    [fulfillmentGroups],
  );

  const presets = useLoad(async () => {
    const result = (await client.getPresets({})).presets;
    return result;
  }, []);

  const [selectedPreset, setSelectedPreset] = useState<Preset | undefined>(
    undefined,
  );

  function getCheapestMatchingRate({
    carrier,
    service,
    group,
  }: {
    carrier: string;
    service: string;
    group: FulfillmentOrderData;
  }): OutboundRate | undefined {
    const rates = group.availableShipmentRates?.rates.filter(
      (rate) => rate.carrier === carrier && rate.service === service,
    );
    if (rates?.length) {
      return rates.reduce((prev, curr) =>
        Number(prev.rate) < Number(curr.rate) ? curr : prev,
      );
    }
    return undefined;
  }

  const onSelectedRateChange = useHandler((option: OutboundRateOption) => {
    const updates: FulfillmentOrderUpdate[] = [];
    for (const group of fulfillmentGroups) {
      const rate = getCheapestMatchingRate({
        service: option.service,
        carrier: option.carrier,
        group,
      });
      if (rate) {
        updates.push({
          fulfillmentGroupId: group._id,
          selectedShippingRateId: rate.id,
        });
      }
    }
    setSelectedPreset(undefined);
    updateRows(updates);
  });

  const onParcelChange = useHandler((parcel: Parcel | null) => {
    updateRows(
      fulfillmentGroups.map((group) => {
        const totalWeightWithParcelGrams = getWeightWithParcelGrams(
          group.totalWeightGrams,
          parcel,
        ).value;
        const displayWeightGrams = getDisplayWeightGrams(
          group.overrideWeight,
          totalWeightWithParcelGrams,
        );
        return {
          fulfillmentGroupId: group._id,
          selectedParcel: parcel,
          totalWeightWithParcelGrams,
          displayWeightGrams,
        };
      }),
    );
    setSelectedPreset(undefined);
  });

  const onUserChange = useHandler((user: OMSUser | null) => {
    updateRows(
      fulfillmentGroups.map(({ _id }) => ({
        fulfillmentGroupId: _id,
        assignedUser: user,
      })),
    );
  });

  const onWeightReset = useHandler(() => {
    updateRows(
      fulfillmentGroups.map(
        ({ _id, totalWeightWithParcelGrams, totalWeightGrams }) => ({
          fulfillmentGroupId: _id,
          overrideWeight: {
            value: totalWeightWithParcelGrams ?? totalWeightGrams,
            unit: WeightUnit.GRAM,
          },
          displayWeightGrams: totalWeightWithParcelGrams ?? totalWeightGrams,
        }),
      ),
    );
    setSelectedPreset(undefined);
  });

  const onWeightChange = useHandler((weight: Weight) => {
    updateRows(
      fulfillmentGroups.map(({ _id }) => ({
        fulfillmentGroupId: _id,
        overrideWeight: weight,
        displayWeightGrams: convertWeight(weight, WeightUnit.GRAM).value,
      })),
    );
    setSelectedPreset(undefined);
  });

  const onPresetSelect = useHandler((presetListItem: RedoListItem<Preset>) => {
    const preset = presetListItem.value;
    const updates: FulfillmentOrderUpdate[] = [];
    setSelectedPreset(preset);
    for (const group of fulfillmentGroups) {
      const update: FulfillmentOrderUpdate = { fulfillmentGroupId: group._id };
      if (preset.service && preset.carrier) {
        update.selectedShippingRateId =
          getCheapestMatchingRate({
            service: preset.service,
            carrier: preset.carrier,
            group,
          })?.id || null;
      }
      if (preset.totalWeightInOunces) {
        update.overrideWeight = {
          value: preset.totalWeightInOunces,
          unit: WeightUnit.OUNCE,
        };
      }
      if (preset.parcel) {
        update.selectedParcel = preset.parcel;
      }
      updates.push(update);
    }
    updateRows(updates);
  });

  useWindowEventListener(window, "keydown", (e) => {
    if (e.ctrlKey || e.metaKey) {
      presets.value?.forEach((preset) => {
        const presetKey = preset.hotkey.replace("⌘", "");
        if (e.key === presetKey) {
          onPresetSelect({ value: preset });
        }
      });
    }
  });

  const { selectedStatus, mixedStatuses } = useMemo(() => {
    const allStatuses = fulfillmentGroups.map((group) => group.status);
    if (!allStatuses.every((status) => allStatuses[0] === status)) {
      return { selectedStatus: null, mixedStatuses: true };
    }
    return { selectedStatus: allStatuses[0], mixedStatuses: false };
  }, [fulfillmentGroups]);

  const [dropdownValue, setDropdownValue] = useState(
    canPurchaseLabelStatus(selectedStatus)
      ? CreateAndPrintButtonActions.CREATE_AND_PRINT
      : CreateAndPrintButtonActions.PRINT_LABEL,
  );

  useEffect(() => {
    if (selectedStatus === FulfillmentGroupStatus.Closed) {
      setDropdownValue(CreateAndPrintButtonActions.PRINT_LABEL);
    } else {
      setDropdownValue(CreateAndPrintButtonActions.CREATE_AND_PRINT);
    }
  }, [selectedStatus]);

  const [actionLoad, doAction] = useTriggerLoad(
    async (signal?: AbortSignal) => {
      if (dropdownValue === CreateAndPrintButtonActions.PRINT_PICK_LIST) {
        const { url } = await client.printPickList({
          fulfillmentGroupIds: fulfillmentGroups.map((g) => g._id),
          selectedStatus: selectedStatus ?? FulfillmentGroupStatus.Open,
        });
        // TODO: this won't work in Safari because you can only open tabs in direct response to a user event
        window.open(url, "_blank");
        updateRows(
          fulfillmentGroups.map((g) => ({
            fulfillmentGroupId: g._id,
            printStatus: { ...g.printStatus, pickListPrinted: true },
          })),
        );
        return;
      } else if (dropdownValue === CreateAndPrintButtonActions.PRINT_LABEL) {
        const { url, successIds } = await client.printLabels({
          fulfillmentGroupIds: fulfillmentGroups.map((g) => g._id),
        });
        const numFailed = fulfillmentGroups.length - successIds.length;

        toast(
          `Printed ${successIds.length} labels ${
            numFailed > 0 ? `(${numFailed} shipment had no label to print)` : ""
          }`,
          { variant: "success" },
        );

        window.open(url, "_blank");
        updateRows(
          fulfillmentGroups.map((g) => ({
            fulfillmentGroupId: g._id,
            printStatus: { ...g.printStatus, labelPrinted: true },
          })),
        );
        return;
      } else if (
        dropdownValue === CreateAndPrintButtonActions.PRINT_PACKING_SLIP
      ) {
        const { url } = await client.printPackingSlip({
          fulfillmentGroupIds: fulfillmentGroups.map((g) => g._id),
        });
        window.open(url, "_blank");
        updateRows(
          fulfillmentGroups.map((g) => ({
            fulfillmentGroupId: g._id,
            printStatus: { ...g.printStatus, packingSlipPrinted: true },
          })),
        );
        return;
      } else if (
        dropdownValue === CreateAndPrintButtonActions.COMMERCIAL_INVOICE
      ) {
        const { url } = await client.printCommercialInvoices({
          fulfillmentGroupIds: fulfillmentGroups.map((g) => g._id),
        });
        window.open(url, "_blank");
      }

      // TODO: make sure we're ready to buy
      if (!user) {
        throw new Error("User not found - cannot buy labels");
      }

      let toastMsg = "";
      let toastVariant: "success" | "error" | "warning" = "success";
      try {
        if (fulfillmentDate) {
          // Fulfill at 9 AM local time for now
          fulfillmentDate.setHours(9);
          fulfillmentDate.setMinutes(0);
          fulfillmentDate.setSeconds(0);
        }
        const response = await client.buyLabels(
          {
            buyRequests: fulfillmentGroups.map((fulfillmentGroup) => ({
              fulfillmentGroupId: fulfillmentGroup._id,
              shipmentRateId: fulfillmentGroup.selectedShippingRateId!,
              lineItems: fulfillmentGroup.items.map((item) => ({
                id: item.externalId,
                quantity: item.unfulfilledQuantity,
              })),
            })),
            userId: user._id,
            print:
              dropdownValue === CreateAndPrintButtonActions.CREATE_AND_PRINT,
            insured:
              insured &&
              team?.settings?.outboundLabels?.insuranceRatePercentage != null,
            fulfillmentDate,
          },
          { signal },
        );
        if ("timeout" in response) {
          toastMsg = `Labels being purchasing in batch #${response.batchNumber}. Please refresh batch history to check status.`;
          toastVariant = "warning";
        } else {
          toastMsg = "Labels successfully purchased";
          if (
            dropdownValue === CreateAndPrintButtonActions.CREATE_AND_PRINT &&
            response.url
          ) {
            window.open(response.url, "_blank");
          }
        }
      } catch (e) {
        toastMsg = "Error purchasing labels";
        toastVariant = "error";
      } finally {
        resetSelections();
        reloadBalance();
        triggerRefresh();
        toast(toastMsg, { variant: toastVariant });
      }
    },
  );

  const onEditAddress = () => {
    console.log("edit address");
  };

  const { selectedRate, mixedRates, allSelectedRates } = useMemo(() => {
    const allSelectedRates = fulfillmentGroups.map(
      (group) =>
        group.availableShipmentRates?.rates.find(
          (rate) => rate.id === group.selectedShippingRateId,
        ) ?? null,
    );
    const selectedRateKeys = allSelectedRates.map((rate) =>
      rate ? `${rate.carrier}-${rate.service}` : null,
    );
    if (!selectedRateKeys.every((key) => selectedRateKeys[0] === key)) {
      return { selectedRate: null, mixedRates: true, allSelectedRates };
    }
    const key = selectedRateKeys[0];
    const rateOption = availableRates.find((rate) => rate.key === key) ?? null;
    return { selectedRate: rateOption, mixedRates: false, allSelectedRates };
  }, [fulfillmentGroups, availableRates]);

  const { selectedParcel, mixedParcels } = useMemo(() => {
    const defaultParcel = parcelValues.find((parcel) => parcel.default);
    const parcels = fulfillmentGroups.map(
      (group) => group.selectedParcel ?? defaultParcel ?? null,
    );
    if (parcels.length === 1) {
      return { selectedParcel: parcels[0] ?? null, mixedParcels: false };
    }
    const firstParcel = parcels[0] ?? null;
    if (parcels.every((parcel) => parcelsEqual(firstParcel, parcel))) {
      return { selectedParcel: firstParcel, mixedParcels: false };
    }
    return { selectedParcel: null, mixedParcels: true };
  }, [parcelValues, fulfillmentGroups]);

  const { assignedUser, mixedUsers } = useMemo(() => {
    const users = fulfillmentGroups.map((group) => group.assignedUser);

    if (users.every((user) => user == null)) {
      return { assignedUser: null, mixedUsers: false };
    }

    if (users.length === 1 && users[0]) {
      return { assignedUser: users[0] ?? null, mixedUsers: false };
    }
    const firstUser = users[0] ?? null;
    if (users.every((user) => firstUser?._id === user?._id)) {
      return { assignedUser: firstUser, mixedUsers: false };
    }
    return { assignedUser: null, mixedUsers: true };
  }, [availableUsers, fulfillmentGroups]);

  // Total weight of each package, not all of them combined.
  // Null if mixed weights.
  const totalWeight = useMemo<Weight | null>(() => {
    const weights = fulfillmentGroups.map((group) => {
      return { value: group.displayWeightGrams ?? 0, unit: WeightUnit.GRAM };
    });

    // TODO: consider "equivalent" weights of different units equal.
    // "Equivalent" meaning we don't care to split ounces.
    if (
      weights.every(
        (other) =>
          other.unit === weights[0].unit && other.value === weights[0].value,
      )
    ) {
      return weights[0];
    }
    return null;
  }, [fulfillmentGroups]);

  const [voidShipmentLoad, triggerVoidShipmentLoad] = useTriggerLoad(
    async (signal) => {
      try {
        const res = await client.voidShipments(
          { fulfillmentGroupIds: fulfillmentGroups.map((fg) => fg._id) },
          { signal },
        );
        if (res.success) {
          const msg =
            fulfillmentGroups.length > 1
              ? `Successfully voided ${fulfillmentGroups.length} labels`
              : "Successfully voided label";
          toast(msg, { variant: "success" });
        }
      } catch (err) {
        const msg =
          fulfillmentGroups.length > 1
            ? `Failed to void ${fulfillmentGroups.length} labels`
            : "Failed to void label";
        toast(msg, { variant: "error" });
      } finally {
        triggerRefresh();
      }

      return;
    },
  );

  const voidLabelButtonText =
    fulfillmentGroups.length > 1
      ? `Void ${fulfillmentGroups.length} labels`
      : "Void label";

  const estimatedDeliveryDate = useMemo(() => {
    return selectedRate && selectedRate.deliveryDays
      ? `${selectedRate.deliveryDays} days`
      : "-";
  }, [selectedRate]);

  // If all the fulfillment groups have the same shipping title, use that.
  // Otherwise, show "-"
  const requestedShippingService = useMemo(() => {
    return fulfillmentGroups.reduce((commonName, fulfillmentGroup) => {
      if (
        commonName === "" ||
        commonName === fulfillmentGroup.shippingMethodNames[0]
      ) {
        return commonName;
      }
      return "-";
    }, fulfillmentGroups[0]?.shippingMethodNames[0] || "-");
  }, [fulfillmentGroups]);

  const [insured, setInsured] = useState<boolean>(false);
  const insuranceCost = useMemo(() => {
    if (!team?.settings?.outboundLabels?.insuranceRatePercentage) {
      return 0;
    }

    return fulfillmentGroups.reduce((sum, group) => {
      return (
        sum +
        Number(group.totalPrice.amount) *
          ((team?.settings?.outboundLabels?.insuranceRatePercentage ?? 0) / 100)
      );
    }, 0);
  }, [allSelectedRates, insured]);

  const totalRatePrice = useMemo(() => {
    if (allSelectedRates.some((rate) => !rate)) {
      return null;
    }
    const uninsuredRate = allSelectedRates.reduce(
      (sum, rate) => sum + Number(rate?.rate),
      0,
    );
    if (insured && team?.settings?.outboundLabels?.insuranceRatePercentage) {
      return uninsuredRate + insuranceCost;
    }
    return uninsuredRate;
  }, [allSelectedRates, insured, insuranceCost]);

  const needsFunds = calculateNeedsFunds(
    balance,
    totalRatePrice,
    selectedStatus,
    balancePaymentSchedule,
  );

  const { errorMessage, warningMessage } = actionMessages(
    dropdownValue,
    fulfillmentGroups,
    selectedParcel,
    mixedParcels,
    totalRatePrice,
    needsFunds,
    balance,
    selectedPreset,
    selectedRate,
  );

  const showVoidLabelButton = fulfillmentGroups.every((group) =>
    canVoidLabel(group),
  );

  const lineItems = useMemo(() => {
    const reducedLineItems: FulfillmentOrderLineItem[] = [];
    const reducedLineItemsRec: Record<string, FulfillmentOrderLineItem> = {};

    for (const group of fulfillmentGroups) {
      for (const item of group.items) {
        if (!item.variantId || !item.unitDiscountedPrice.amount) {
          reducedLineItems.push(item);
          continue;
        }

        const key = `${item.variantId}-${item.unitDiscountedPrice.amount}`;
        if (!reducedLineItemsRec[key]) {
          reducedLineItemsRec[key] = { ...item };
        } else {
          reducedLineItemsRec[key].unfulfilledQuantity +=
            item.unfulfilledQuantity;
          reducedLineItemsRec[key].totalQuantity += item.totalQuantity;
        }
      }
    }

    return [...reducedLineItems, ...Object.values(reducedLineItemsRec)];
  }, [fulfillmentGroups]);

  const customAttributes = useMemo(
    () =>
      fulfillmentGroups
        .flatMap(({ customAttributes }) => customAttributes)
        .sort((a, b) => a.key.localeCompare(b.key)),
    [fulfillmentGroups],
  );

  return (
    <Drawer
      anchor="right"
      onClose={onClose}
      open={!!fulfillmentGroups.length}
      style={{ filter: mixedStatuses ? "blur(4px)" : "none" }}
      variant="persistent"
    >
      <RedoBaseModal
        isOpen={mixedStatuses}
        onModalCloseRequested={() => {
          onClose();
        }}
        otherClasses={[ordersCss.slideoutModal]}
      >
        <RedoModalHeader
          cancelClicked={onClose}
          centerContent
          headerIconAlignment={RedoModalHeaderIconAlignment.INLINE}
          hideCloseButton
          title="Cannot fulfill orders"
          TitleIcon={PackageX}
        />
        <Text fontSize="sm" pl="2xl" pr="2xl" textColor="secondary">
          You have selected some orders that have already been fulfilled. To
          continue fulfilling orders you will need to deselect any previously
          fulfilled orders.
        </Text>
        <Flex justify="flex-end" p="2xl">
          <button onClick={() => deselectComplete()}>
            <Text textColor="secondary">Deselect fulfilled orders</Text>
          </button>
        </Flex>
      </RedoBaseModal>
      {fulfillmentGroups?.length ? (
        <Flex
          className={ordersCss.drawerContent}
          dir="column"
          gap="none"
          overflowY="auto"
        >
          <Flex
            align="flex-start"
            borderBottomWidth="1px"
            borderColor="primary"
            borderStyle="solid"
            className={ordersCss.slideoutHeaderContainer}
            dir="row"
            justify="space-between"
            pb="2xl"
            pl="3xl"
            pr="3xl"
            pt="2xl"
          >
            <Flex dir="column" gap="xs">
              <Text fontSize="xl" fontWeight="semibold">
                Create label
              </Text>
            </Flex>
            <IconButton onClick={onClose}>
              <XIcon />
            </IconButton>
          </Flex>
          <Flex
            align="flex-start"
            borderBottomWidth="1px"
            borderColor="primary"
            borderStyle="solid"
            dir="row"
            justify="space-between"
          >
            <Flex
              bgColor="tertiary"
              dir="column"
              gap="2xl"
              pb="xl"
              pl="3xl"
              pr="3xl"
              pt="3xl"
              w="full"
            >
              <Flex>
                {presets.value && presets.value.length > 0 ? (
                  <RedoSingleSelectDropdownInput<Preset>
                    label="Shipment preset"
                    options={presets.value.map((preset) => ({ value: preset }))}
                    optionSelected={onPresetSelect}
                    placeholder="Select a preset"
                    selectedItem={
                      selectedPreset ? { value: selectedPreset } : undefined
                    }
                    size={RedoDropdownInputSize.SMALL}
                  >
                    {(option) => {
                      return <Text>{option.value.name}</Text>;
                    }}
                  </RedoSingleSelectDropdownInput>
                ) : undefined}
              </Flex>
              <Flex dir="row" justify="space-between">
                <Flex dir="column" gap="none">
                  <Text color="tertiary" fontSize="sm">
                    Total rate
                  </Text>
                  <Text fontSize="sm" fontWeight="semibold">
                    ${totalRatePrice?.toFixed(2) ?? "-"}
                  </Text>
                </Flex>

                <Flex>
                  <CreateAndPrintButton
                    dropdownValue={dropdownValue}
                    errorMessage={errorMessage}
                    fulfillmentGroups={fulfillmentGroups}
                    loading={actionLoad.pending || updatesPending}
                    onClick={() => {
                      void doAction();
                    }}
                    selectedStatus={selectedStatus}
                    setDropdownValue={setDropdownValue}
                    theme={RedoButtonTheme.SUCCESS}
                    warningMessage={warningMessage}
                  />
                  {showVoidLabelButton && (
                    <RedoButton
                      hierarchy={RedoButtonHierarchy.SECONDARY}
                      onClick={() => triggerVoidShipmentLoad()}
                      pending={voidShipmentLoad.pending}
                      size={RedoButtonSize.REGULAR}
                      text={voidLabelButtonText}
                      theme={RedoButtonTheme.DESTRUCTIVE}
                    />
                  )}
                </Flex>
              </Flex>
            </Flex>
          </Flex>
          <Flex dir="column" gap="xl" justify="space-between" w="full">
            <Flex
              borderBottomWidth="1px"
              borderColor="primary"
              borderStyle="solid"
              dir="column"
              gap="xl"
              pb="xl"
              pl="3xl"
              pr="3xl"
              pt="3xl"
            >
              {fulfillmentGroups.length === 1 ? (
                <>
                  <Text color="secondary" fontSize="md" fontWeight="medium">
                    Shipping information
                  </Text>

                  <OrderFulfillmentAddress
                    address={fulfillmentGroups[0].destinationAddress}
                    onEdit={onEditAddress}
                  />
                </>
              ) : (
                <Text color="secondary" fontSize="md" fontWeight="medium">
                  Bulk update
                </Text>
              )}
              <Text
                as="span"
                color="secondary"
                fontSize="sm"
                fontWeight="medium"
              >
                <Text fontSize="xs">Requested shipping service</Text>
                <Text fontSize="sm" fontWeight="medium">
                  {requestedShippingService}
                </Text>
              </Text>
              <ShipmentRateSelectionDropdown
                label="Service"
                loading={selectedStatus === FulfillmentGroupStatus.Closed}
                mixedRates={mixedRates}
                options={availableRates}
                selectedRate={selectedRate}
                setSelectedRate={onSelectedRateChange}
              />
              {(dropdownValue ===
                CreateAndPrintButtonActions.CREATE_AND_PRINT ||
                dropdownValue === CreateAndPrintButtonActions.CREATE) &&
                team?.settings?.outboundLabels?.insuranceRatePercentage && (
                  <Flex justify="flex-start">
                    <Flex>
                      <RedoCheckbox
                        description={`${team?.settings?.outboundLabels?.insuranceRatePercentage}% of package value`}
                        label="Add additional insurance"
                        setValue={() => setInsured(!insured)}
                        size={RedoCheckboxSize.EXTRA_SMALL}
                        value={insured}
                      />
                    </Flex>
                  </Flex>
                )}
              <Flex>
                <Flex>
                  <RedoCheckbox
                    description={
                      <Flex onClick={(e) => e.preventDefault()} pt="md">
                        <RedoDatePicker
                          buttonSize={RedoButtonSize.SMALL}
                          date={fulfillmentDate ?? undefined}
                          disabled={!fulfillmentDate}
                          minDate={TOMORROW}
                          setDate={setFulfillmentDate}
                        />
                      </Flex>
                    }
                    label="Delay fulfillment until..."
                    setValue={(v) => {
                      if (v) {
                        setFulfillmentDate(TOMORROW);
                      } else {
                        setFulfillmentDate(null);
                      }
                    }}
                    size={RedoCheckboxSize.EXTRA_SMALL}
                    value={!!fulfillmentDate}
                  />
                </Flex>
              </Flex>
              <Text
                as="span"
                color="secondary"
                fontSize="sm"
                fontWeight="medium"
              >
                <Text fontSize="xs">Estimated delivery date/time</Text>
                <Text fontSize="sm" fontWeight="medium">
                  {estimatedDeliveryDate}
                </Text>
              </Text>
            </Flex>
            <Flex
              borderBottomWidth="1px"
              borderColor="primary"
              borderStyle="solid"
              dir="column"
              gap="xl"
              pb="xl"
              pl="3xl"
              pr="3xl"
            >
              <Text color="secondary" fontSize="sm" fontWeight="medium">
                Parcel settings
              </Text>
              <TotalWeightWidget
                disabled={selectedStatus === FulfillmentGroupStatus.Closed}
                onWeightChange={onWeightChange}
                onWeightReset={onWeightReset}
                weight={totalWeight}
              />
              <ParcelSelectDropdown
                disabled={selectedStatus === FulfillmentGroupStatus.Closed}
                label="Package"
                mixedParcels={mixedParcels}
                onParcelChange={onParcelChange}
                parcelValues={parcelValues}
                selectedParcel={selectedParcel}
              />
              {selectedParcel ? (
                <ParcelSizeWidget
                  disabled={selectedStatus === FulfillmentGroupStatus.Closed}
                  selectedParcel={selectedParcel}
                  updateParcel={onParcelChange}
                />
              ) : null}
            </Flex>
            <Flex
              borderBottomWidth="1px"
              borderColor="primary"
              borderStyle="solid"
              dir="column"
              gap="xl"
              pb="xl"
              pl="3xl"
              pr="3xl"
            >
              {fulfillmentGroups.length === 1 && (
                <Text color="secondary" fontSize="md" fontWeight="medium">
                  Items
                </Text>
              )}
              <ItemsTable
                items={lineItems}
                selectedStatus={selectedStatus ?? FulfillmentGroupStatus.Open}
                team={team}
              />
            </Flex>
            <Flex
              borderBottomWidth="1px"
              borderColor="primary"
              borderStyle="solid"
              dir="column"
              gap="xl"
              pb="xl"
              pl="3xl"
              pr="3xl"
            >
              <Text color="secondary" fontSize="sm" fontWeight="medium">
                Assigned user
              </Text>
              <UserSelectDropdown
                assignedUser={assignedUser}
                disabled={selectedStatus === FulfillmentGroupStatus.Closed}
                mixedUsers={mixedUsers}
                onUserChange={onUserChange}
                userValues={availableUsers}
              />
            </Flex>
            {customAttributes?.length ? (
              <Flex
                borderBottomWidth="1px"
                borderColor="primary"
                borderStyle="solid"
                dir="column"
                gap="xl"
                pb="xl"
                pl="3xl"
                pr="3xl"
              >
                <Text color="secondary" fontSize="sm" fontWeight="medium">
                  Additional details
                </Text>
                {customAttributes.map(({ key, value }, i) => (
                  <Flex dir="column" gap="md" key={i}>
                    <Text fontSize="xs" fontWeight="medium">
                      {key}
                    </Text>
                    <Text fontSize="xs" fontWeight="thin">
                      {value}
                    </Text>
                  </Flex>
                ))}
              </Flex>
            ) : null}
          </Flex>
        </Flex>
      ) : null}
    </Drawer>
  );
});

function calculateNeedsFunds(
  balance: string | undefined,
  totalRatePrice: number | null,
  selectedStatus: FulfillmentGroupStatus | undefined | null,
  balancePaymentSchedule: PaymentSchedule | undefined,
): boolean {
  const isOpen = selectedStatus === FulfillmentGroupStatus.Open;

  // Not open => we don't need funds because we're not trying to buy labels
  if (!isOpen) {
    return false;
  }

  // No balance => we need funds
  if (!balance) {
    return true;
  }

  // If we don't have a rate to buy, we don't need funds
  if (totalRatePrice == null) {
    return false;
  }

  // If the balance is less than the rate, we need funds
  const rateTooHigh = Number(balance) < Number(totalRatePrice);

  return rateTooHigh && balancePaymentSchedule === PaymentSchedule.PREPAID;
}

/*
 * An error message is meant to be shown under the button and prevent the user from taking the action
 * A warning message is meant to be shown under the button while allowing the user to take the action
 */
function actionMessages(
  currentValue: CreateAndPrintButtonActions,
  fulfillmentGroups: readonly FulfillmentOrderData[],
  selectedParcel: Parcel | null,
  mixedParcels: boolean,
  totalRatePrice: number | null,
  needsFunds: boolean,
  balance: string | undefined,
  selectedPreset: Preset | undefined,
  selectedRate: OutboundRateOption | null,
): { errorMessage: string; warningMessage: string } {
  const noParcel = !selectedParcel && !mixedParcels;
  const noRate = totalRatePrice == null;

  const isSingleUnprintableSelection =
    fulfillmentGroups.length === 1 &&
    !fulfillmentGroups[0].shipment?.label?.url &&
    currentValue === CreateAndPrintButtonActions.PRINT_LABEL;

  const allSelectionUnprintable =
    fulfillmentGroups.length > 0 &&
    fulfillmentGroups.every((fg) => !fg.shipment?.label?.url) &&
    currentValue === CreateAndPrintButtonActions.PRINT_LABEL;

  const hasUnprintableSelection =
    fulfillmentGroups.length > 0 &&
    fulfillmentGroups.some((fg) => !fg.shipment?.label?.url) &&
    currentValue === CreateAndPrintButtonActions.PRINT_LABEL;

  const isCreateAction =
    currentValue === CreateAndPrintButtonActions.CREATE ||
    currentValue === CreateAndPrintButtonActions.CREATE_AND_PRINT;

  let errorMessage = "";
  let warningMessage = "";

  if (isCreateAction) {
    if (noRate) {
      errorMessage = "No rate selected";
    }
    if (noParcel) {
      errorMessage = "No parcel selected";
    }
    if (needsFunds) {
      const msg = `Insufficient funds: $${balance || "0"} remaining`;
      errorMessage = msg;
    }
  }

  if (hasUnprintableSelection) {
    if (isSingleUnprintableSelection) {
      errorMessage =
        "No label to print (this may happen if the order was fulfilled outside of Redo)";
    } else if (allSelectionUnprintable) {
      errorMessage =
        "No labels to print (this may happen if the orders was fulfilled outside of Redo)";
    } else {
      warningMessage =
        "Some orders have no labels to print (this may happen if the orders was fulfilled outside of Redo)";
    }
  }

  if (
    selectedPreset &&
    selectedPreset?.carrier &&
    selectedPreset?.service &&
    selectedRate &&
    (selectedRate?.carrier !== selectedPreset?.carrier ||
      selectedRate?.service !== selectedPreset?.service)
  ) {
    warningMessage =
      "The shipping service specified in the preset is not available";
  }

  const someVoided = fulfillmentGroups.some(
    (fg) => fg.status === FulfillmentGroupStatus.Voided,
  );

  const allVoided = fulfillmentGroups.every(
    (fg) => fg.status === FulfillmentGroupStatus.Voided,
  );

  const oneVoided = fulfillmentGroups.length === 1 && someVoided;

  if (oneVoided) {
    errorMessage = "This order has been voided";
  } else if (allVoided) {
    errorMessage = "All orders have been voided";
  } else if (someVoided) {
    errorMessage = "Some orders have been voided";
  }

  return { errorMessage, warningMessage };
}

function parcelsEqual(a: Parcel | null, b: Parcel | null): boolean {
  if (a === null || b === null) {
    return false;
  }
  if (a.parcelType !== b.parcelType) {
    return false;
  }
  if (
    a.parcelType === ParcelType.CUSTOM &&
    b.parcelType === ParcelType.CUSTOM
  ) {
    return (
      a.weightUnit === b.weightUnit &&
      a.lengthUnit === b.lengthUnit &&
      a.weight === b.weight &&
      a.width === b.width &&
      a.height === b.height &&
      a.length === b.length
    );
  }

  if (
    a.parcelType === ParcelType.CARRIER &&
    b.parcelType === ParcelType.CARRIER
  ) {
    return a.carrier === b.carrier && a.name === b.name;
  }
  return false;
}

function summarizeOutboundRateOptions(
  fulfillmentGroups: readonly FulfillmentOrderData[],
): readonly OutboundRateOption[] {
  if (fulfillmentGroups.length === 0) {
    return [];
  }
  if (fulfillmentGroups.length === 1) {
    return (
      fulfillmentGroups[0].availableShipmentRates?.rates.map((rate) => ({
        key: `${rate.carrier}-${rate.service}`,
        carrier: rate.carrier,
        service: rate.service,
        rate: Number(rate.rate),
        currency: rate.currency,
        deliveryDays: rate.delivery_days?.toString(),
      })) || []
    );
  }
  // Collect the cheapest rates for all service levels
  const rateMaps = fulfillmentGroups.map((group) =>
    (group.availableShipmentRates?.rates || []).reduce((map, rate) => {
      const key = `${rate.carrier}-${rate.service}`;
      const existingRate = map.get(key);
      if (!existingRate || Number(existingRate.rate) > Number(rate.rate)) {
        map.set(key, rate);
      }
      return map;
    }, new Map<string, OutboundRate>()),
  );
  // Summarize the rates across all selected fulfillment groups

  const rates: OutboundRateOption[] = [];
  for (const [key, value] of rateMaps[0]!.entries()) {
    if (!rateMaps.every((map) => map.has(key))) {
      continue;
    }
    rates.push(
      rateMaps.reduce(
        (option: OutboundRateOption, map) => {
          if (!option.rate) {
            option.rate = 0;
          }
          option.rate += Number(map.get(key)!.rate);
          return option;
        },
        {
          key,
          carrier: value.carrier,
          service: value.service,
          rate: Number(0),
          currency: value.currency,
        },
      ),
    );
  }
  return rates;
}
