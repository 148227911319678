import { Parcel } from "@redotech/redo-model/outbound-labels/parcel";
import { RedoListItem } from "@redotech/redo-web/arbiter-components/list/redo-list";
import {
  RedoDropdownInputSize,
  RedoSingleSelectDropdownInput,
} from "@redotech/redo-web/arbiter-components/select-dropdown/redo-single-select-dropdown-input";
import { Text } from "@redotech/redo-web/text";
import { memo } from "react";
import { formatParcelName } from "../../setting/parcels";

export const ParcelSelectDropdown = memo(function ParcelSelectDropdown({
  disabled,
  selectedParcel,
  mixedParcels,
  parcelValues,
  onParcelChange,
  label,
}: {
  disabled: boolean;
  selectedParcel: Parcel | null;
  mixedParcels: boolean;
  parcelValues: readonly Parcel[];
  onParcelChange: (parcel: Parcel) => void;
  label?: string;
}) {
  const onOptionSelected = (parcel: RedoListItem<Parcel>) => {
    onParcelChange(parcel.value);
  };

  return (
    <RedoSingleSelectDropdownInput<Parcel>
      disabled={disabled}
      label={label}
      options={parcelValues.map((parcel) => ({
        value: parcel,
        label: formatParcelName(parcel.name),
      }))}
      optionSelected={onOptionSelected}
      placeholder={mixedParcels ? "{mixed}" : "Select parcel"}
      selectedItem={selectedParcel ? { value: selectedParcel } : undefined}
      size={RedoDropdownInputSize.SMALL}
    >
      {(option) => {
        return <Text>{formatParcelName(option.value.name)}</Text>;
      }}
    </RedoSingleSelectDropdownInput>
  );
});
