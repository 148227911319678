import { useHandler } from "@redotech/react-util/hook";
import { WidgetClient, cancelPickup } from "@redotech/redo-api-client/widget";
import { alertOnFailure } from "@redotech/redo-web/alert";
import {
  RedoModal,
  RedoModalSize,
  RedoModalTheme,
} from "@redotech/redo-web/arbiter-components/modal/redo-modal";
import alertTriangleFilled from "@redotech/redo-web/arbiter-icon/alert-triangle.svg";
import { memo } from "react";

export const CancelPickupModal = memo(function CancelPickupModal({
  open,
  onClose,
  returnId,
  widgetClient,
  reload,
}: {
  open: boolean;
  onClose(): void;
  reload?(): void;
  returnId: string;
  widgetClient: WidgetClient;
}) {
  const handleCancelPickup = useHandler(() =>
    alertOnFailure("Resending label failed")(async () => {
      await cancelPickup(widgetClient, { returnId });
      reload && reload();
    }),
  );

  return (
    <RedoModal
      isOpen={open}
      modalSize={RedoModalSize.SMALL}
      onModalCloseRequested={onClose}
      primaryButton={{
        onClick: handleCancelPickup,
        text: "Yes, cancel pickup",
      }}
      secondaryButton={{ onClick: onClose, text: "No, don't cancel" }}
      subtitle="Are you sure you want to cancel the scheduled package pickup? This action cannot be undone."
      theme={RedoModalTheme.WARN}
      title="Cancel package pickup"
      TitleIcon={alertTriangleFilled}
    />
  );
});
