// extracted by mini-css-extract-plugin
export var boldText = "_19-4";
export var checkboxContainer = "_19-6";
export var clearButton = "_19-c";
export var container = "_19-0";
export var drawerContent = "_19-8";
export var extraSmallText = "_19-5";
export var fulfillmentGroupTable = "_19-d";
export var largeText = "_19-3";
export var mediumText = "_19-2";
export var narrow = "_19-e";
export var packageCheckIconContainer = "_19-7";
export var slideoutHeaderContainer = "_19-9";
export var slideoutModal = "_19-a";
export var tableContainer = "_19-b";
export var tertiaryText = "_19-1";