import { FulfillmentOrderData } from "@redotech/redo-model/fulfillments/fulfillment-group";
import { RedoTableTextCell } from "@redotech/redo-web/arbiter-components/tables/redo-table-cells";
import { ColumnAlignment } from "@redotech/redo-web/table";
import { Column2 } from "@redotech/redo-web/table2";

export const RateColumn: Column2<FulfillmentOrderData> = {
  key: "rate",
  title: "Rate",
  alignment: ColumnAlignment.LEFT,
  renderNormalCell: ({ row }) => {
    const rate =
      row.availableShipmentRates?.rates.find(
        (rate) => rate.id === row.selectedShippingRateId,
      )?.rate ?? "";
    return <RedoTableTextCell text={`$${rate}`} />;
  },
  width: 80,
};
