// extracted by mini-css-extract-plugin
export var actions = "_2x-0";
export var actionsContainer = "_2x-3";
export var border = "_2x-1";
export var childFullHeight = "_2x-9";
export var collapse = "_2x-2";
export var compact = "_2x-e";
export var content = "_2x-4";
export var contentChild = "_2x-8";
export var fullscreen = "_2x-6";
export var header = "_2x-a";
export var headerContainer = "_2x-g";
export var heightAuto = "_2x-c";
export var hideHeaderBorder = "_2x-f";
export var hidePadding = "_2x-5";
export var noScrollX = "_2x-h";
export var noScrollY = "_2x-i";
export var optOutTopPadding = "_2x-7";
export var profile = "_2x-j";
export var profileContainer = "_2x-k";
export var profileImage = "_2x-l";
export var profileMain = "_2x-m";
export var profileSubtitle = "_2x-o";
export var profileTitle = "_2x-n";
export var scrolledTop = "_2x-d";
export var tabsWrapper = "_2x-q";
export var title = "_2x-p";
export var withTabs = "_2x-b";