import { FulfillmentOrderData } from "@redotech/redo-model/fulfillments/fulfillment-group";

import { Column2 } from "@redotech/redo-web/table2";

import { FulfillmentGroupTagSource, TagKind } from "@redotech/redo-model/tag";
import { PillTheme } from "@redotech/redo-web/pill";
import { ColumnAlignment } from "@redotech/redo-web/table";
import { TagList } from "../../common/tag-list";

export const ShopifyOrderTagsColumn: Column2<FulfillmentOrderData> = {
  key: "shopify-order-tags",
  title: "Shopify order tags",
  alignment: ColumnAlignment.LEFT,
  renderNormalCell: ({ row }) => {
    const renderedTags = [
      ...new Set(
        row.orders
          .map((order) => order.tags)
          .flat()
          .map((tag) => ({
            name: tag,
            pillTheme: PillTheme.NORMAL,
            source: FulfillmentGroupTagSource.MERCHANT,
            kind: TagKind.FULFILLMENT_GROUP,
          })),
      ),
    ];
    return <TagList hoverToShow tags={renderedTags} />;
  },
  width: 120,
};
