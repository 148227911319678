// extracted by mini-css-extract-plugin
export var animationContainer = "_bn-0";
export var footer = "_bn-1";
export var gray = "_bn-2";
export var lineItem = "_bn-3";
export var lineItemImage = "_bn-4";
export var lineItemInfo = "_bn-5";
export var lineItemText = "_bn-6";
export var lineItems = "_bn-7";
export var modalContent = "_bn-8";
export var options = "_bn-9";
export var orderName = "_bn-a";
export var refundAmount = "_bn-b";