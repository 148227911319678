// extracted by mini-css-extract-plugin
export var box = "_4v-1";
export var checked = "_4v-5";
export var descriptionSpacer = "_4v-9";
export var disabled = "_4v-4";
export var icon = "_4v-6";
export var input = "_4v-0";
export var labelSpacer = "_4v-8";
export var medium = "_4v-3";
export var small = "_4v-2";
export var text = "_4v-7";