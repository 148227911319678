import { withAbortListener } from "./abort";
import { Queue } from "./queue";

export class Condition<T> {
  resolve!: (value: T) => void;

  reject!: (e: any) => void;

  readonly result = new Promise<T>((resolve, reject) => {
    this.resolve = resolve;
    this.reject = reject;
  });

  use(promise: Promise<T>) {
    promise.then(this.resolve, this.reject);
  }
}

export class WaitQueue<T> {
  readonly #waiters = new Queue<Condition<T>>();

  release(value: T): void {
    if (this.#waiters.empty()) {
      return;
    }
    this.#waiters.pop().resolve(value);
  }

  async wait(signal?: AbortSignal): Promise<T> {
    const waiter = new Condition<T>();
    const remove = this.#waiters.push(waiter);
    const result = waiter.result;
    if (signal) {
      return await withAbortListener(signal, (reason) => {
        remove();
        waiter.reject(reason);
      })(() => result);
    }
    return result;
  }
}
