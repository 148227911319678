import { z } from "zod";
import { DraftReturnSchema } from "../draft-return/draft-return";
import { ReturnProductSchema } from "../draft-return/draft-return-items";

export const submitWarrantyRegistrationSchema = {
  input: z.object({
    draftReturnId: z.string().optional(),
    notifyCustomer: z.boolean().default(false),
    product: ReturnProductSchema,
    responses: z
      .array(
        z.object({
          type: z.enum(["images", "text_area", "multiple_choice", "videos"]),
          value: z.union([z.string(), z.array(z.string())]),
          message: z.string(),
        }),
      )
      .readonly()
      .nullish(),
    account: z.object({
      firstName: z.string(),
      lastName: z.string(),
      email: z.string(),
      address: z.object({
        street1: z.string(),
        street2: z.string(),
        state: z.string(),
        city: z.string(),
        country: z.string(),
        zip: z.string(),
      }),
    }),
  }),
  output: z.object({
    success: z.boolean(),
    customerToken: z.string().nullish(),
    draftReturn: DraftReturnSchema.nullish(),
    warrantyRegistrationId: z.string().nullish(),
    customer: z
      .object({
        name: z.string(),
        email: z.string(),
        customerId: z.string().nullish(),
        firstName: z.string().nullish(),
        lastName: z.string().nullish(),
      })
      .nullish(),
  }),
};

export type SubmitWarrantyRegistrationInput = z.infer<
  (typeof submitWarrantyRegistrationSchema)["input"]
>;

export type SubmitWarrantyRegistrationOutput = z.infer<
  (typeof submitWarrantyRegistrationSchema)["output"]
>;
