import { RouteObject } from "react-router-dom";

const isOnboardRoute = (url: string): boolean => {
  return /concierge\/onboard/.test(url);
};

const isConversationsRoute = (url: string): boolean => {
  return /concierge\/conversations/.test(url);
};

export const conciergeRoutes: RouteObject[] = [
  {
    handle: { breadcrumb: "Concierge", breadcrumbNavigate: false },
    async lazy() {
      const { Page } = await import("../app/page");
      return {
        element: (
          <Page
            hideHeader={isOnboardRoute}
            hideNavbar={isOnboardRoute}
            hidePadding={isConversationsRoute}
          />
        ),
      };
    },
    children: [
      {
        path: "dashboard",
        handle: { breadcrumb: "Dashboard" },
        async lazy() {
          const { ConciergeDashboardPage } = await import(
            "./dashboards/concierge-dashboard-page"
          );
          return { Component: ConciergeDashboardPage };
        },
      },
      {
        path: "conversations",
        handle: { breadcrumb: "Conversations" },
        async lazy() {
          const { ConciergeConversationsPage } = await import(
            "./conversations/concierge-conversations-page"
          );
          return { Component: ConciergeConversationsPage };
        },
      },
      {
        path: "about",
        async lazy() {
          const { AboutConcierge } = await import("./about");
          return { Component: AboutConcierge };
        },
      },
      {
        path: "onboard",
        async lazy() {
          const { SelfServePage } = await import(
            "./self-serve/self-serve-page"
          );
          return { Component: SelfServePage };
        },
      },
      {
        path: "preview",
        async lazy() {
          const { PreviewPage } = await import("./preview/preview-page");
          return { Component: PreviewPage };
        },
      },
    ],
  },
];
