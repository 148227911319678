import { Resource } from "@redotech/util/resource";

/**
 * Open a HID device.
 */
export function withHidDevice(device: HIDDevice): Resource<void> {
  return async (fn) => {
    if (device.opened) {
      return await fn();
    }
    await device.open();
    try {
      return await fn();
    } finally {
      try {
        await device.close();
      } catch {
        // empty
      }
    }
  };
}
