// extracted by mini-css-extract-plugin
export var brand = "_56-4";
export var compact = "_56-7";
export var extraSmall = "_56-8";
export var icon = "_56-0";
export var large = "_56-1";
export var medium = "_56-2";
export var outline = "_56-9";
export var primaryDark = "_56-5";
export var primaryLight = "_56-3";
export var primaryModern = "_56-6";