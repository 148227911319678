import { zExt } from "@redotech/rpc/ext";
import { z } from "zod";

export const refreshSegmentCount = {
  input: zExt.objectId(),
  output: z.object({
    allCount: z.number(),
    emailCount: z.number(),
    smsCount: z.number(),
  }),
};
