import { useHandler } from "@redotech/react-util/hook";
import XIcon from "@redotech/redo-web/arbiter-icon/x-close.svg";
import { filterTruthy, remove } from "@redotech/util/array";
import { BackspaceKey, EnterKey } from "@redotech/web-util/key";
import * as classNames from "classnames";
import {
  ForwardedRef,
  forwardRef,
  memo,
  useEffect,
  useMemo,
  useState,
} from "react";
import { TextMeasureBox } from "../../common/text-measure-box";
import { Flex } from "../../flex";
import { Text } from "../../text";
import { textClasses } from "../../theme/typography";
import { RedoBadge, RedoBadgeProps } from "../badge/redo-badge";
import * as redoTextInputCss from "./base-redo-input.module.css";
import {
  RedoInputSize,
  RedoInputState,
  sizeStyles,
  sizeToDescriptorTextProps,
  sizeToInputTextProps,
  sizeToPx,
} from "./base-redo-text-input";
import { BaseRedoInputInfoTooltip } from "./base-redo-text-input-info-tooltip";
import * as redoTagInputFieldCss from "./redo-tag-input-field.module.css";

type RedoTagInputTag = Omit<RedoBadgeProps, "size" | "iconTrailing"> & {
  text: string;
};

export enum RedoTagDelimeter {
  NEWLINE = "newline",
  WHITESPACE = "whitespace",
  BOTH = "both",
}

interface RedoTagInputProps {
  size?: RedoInputSize;
  placeholder?: string;
  tags: RedoTagInputTag[];
  setTags: (tags: string[]) => void;
  infoTooltip?: string;
  label?: string;
  description?: string;
  state?: RedoInputState;
  delimeter?: RedoTagDelimeter;
  inputValue: string;
  setInputValue: (value: string) => void;
}

export const RedoTagInputField = memo(
  forwardRef(function RedoTagInputField(
    {
      size = RedoInputSize.SMALL,
      placeholder,
      tags,
      setTags,
      inputValue,
      setInputValue,
      infoTooltip,
      label,
      description,
      delimeter = RedoTagDelimeter.NEWLINE,
      state = RedoInputState.DEFAULT,
    }: RedoTagInputProps,
    ref: ForwardedRef<HTMLInputElement>,
  ) {
    const processInput = useHandler((text: string) => {
      const uniqueValues = extractValues(text, delimeter);
      setTags([...tags.map((t) => t.text), ...uniqueValues]);
      setInputValue("");
    });

    const handlePaste = useHandler(
      (e: React.ClipboardEvent<HTMLInputElement>) => {
        e.preventDefault();
        const pastedData = e.clipboardData.getData("text");
        processInput(pastedData);
      },
    );

    const handleKeydown = useHandler(
      (e: React.KeyboardEvent<HTMLInputElement>) => {
        if (e.key === EnterKey && inputValue.trim()) {
          e.preventDefault();
        } else if (e.key === BackspaceKey) {
          if (inputValue === "") {
            setTags(tags.slice(0, -1).map((t) => t.text));
          }
        }
      },
    );

    const handleKeyUp = useHandler(
      (e: React.KeyboardEvent<HTMLInputElement>) => {
        if (e.key === EnterKey) {
          processInput(e.currentTarget.value);
        } else if (
          (delimeter === RedoTagDelimeter.WHITESPACE ||
            delimeter === RedoTagDelimeter.BOTH) &&
          /\s/.test(e.key)
        ) {
          processInput(e.currentTarget.value);
        }
      },
    );

    const descriptorFontSize = sizeToDescriptorTextProps[size];

    const [textWidth, setTextWidth] = useState(0);

    const error = state === RedoInputState.ERROR;
    const disabled = state === RedoInputState.DISABLED;
    const readonly = state === RedoInputState.READONLY;

    // create a unique html ID for the input element
    const inputId = useMemo(
      () => `redo-tag-input-field-${Math.random().toString(36).substr(2, 9)}`,
      [],
    );

    const [wrapperRef, setWrapperRef] = useState<HTMLDivElement | null>(null);

    useEffect(() => {
      wrapperRef?.style.setProperty("--input-width", `${textWidth}px`);
    }, [wrapperRef, textWidth]);

    const measureText = useMemo(() => {
      const realPlaceholder = placeholder || "";
      return inputValue.length > realPlaceholder.length
        ? inputValue
        : realPlaceholder;
    }, [placeholder, inputValue]);

    return (
      <>
        <Flex dir="column" gap="sm" grow={1} ref={setWrapperRef} wrap="wrap">
          {label && (
            <Text
              fontSize={descriptorFontSize}
              textColor={error ? "error" : "tertiary"}
            >
              {label}
            </Text>
          )}
          <Flex
            align="center"
            as="label"
            bgColor="primary"
            className={classNames(
              redoTagInputFieldCss.growableInput,
              disabled && redoTextInputCss.disabled,
              redoTextInputCss.inputWrapper,
              error && redoTextInputCss.error,
              sizeStyles[size],
              readonly && redoTextInputCss.readonly,
            )}
            dir="row"
            justify="flex-start"
            labelProps={{ htmlFor: inputId }}
            overflow="hidden"
            px={readonly ? undefined : sizeToPx[size]}
          >
            <Flex
              dir="row"
              gap="xs"
              grow={readonly ? undefined : 1}
              overflow="hidden"
              py="xxs"
              wrap="wrap"
            >
              {tags.map((tag, index) => {
                return (
                  <div
                    className={classNames(
                      (disabled || readonly) && redoTagInputFieldCss.disabled,
                      redoTagInputFieldCss.tagContainer,
                    )}
                    key={index}
                    onClick={() => {
                      if (disabled || readonly) {
                        return;
                      }
                      setTags(
                        remove(
                          tags.map((t) => t.text),
                          index,
                        ),
                      );
                    }}
                  >
                    <RedoBadge
                      iconTrailing={
                        disabled || readonly
                          ? undefined
                          : { Icon: XIcon, type: "icon" }
                      }
                      {...tag}
                    />
                  </div>
                );
              })}
              {!readonly && (
                <input
                  autoComplete="off"
                  className={classNames(
                    textClasses(sizeToInputTextProps[size]),
                    redoTextInputCss.input,
                    redoTagInputFieldCss.textInput,
                  )}
                  disabled={disabled}
                  id={inputId}
                  name="off"
                  onChange={(e) => setInputValue(e.target.value)}
                  onKeyDown={handleKeydown}
                  onKeyUp={handleKeyUp}
                  onPaste={handlePaste}
                  placeholder={placeholder}
                  ref={ref}
                  value={inputValue}
                />
              )}
            </Flex>
            {infoTooltip && (
              <BaseRedoInputInfoTooltip
                infoTooltip={infoTooltip}
                size={size}
                state={state}
              />
            )}
          </Flex>

          {description && (
            <Text
              fontSize={descriptorFontSize}
              textColor={error ? "error" : "tertiary"}
            >
              {description}
            </Text>
          )}
        </Flex>
        <TextMeasureBox
          setTextWidth={setTextWidth}
          text={measureText}
          textWidth={textWidth}
          {...sizeToInputTextProps[size]}
        />
      </>
    );
  }),
);

function extractValues(text: string, delimeter: RedoTagDelimeter): string[] {
  switch (delimeter) {
    case RedoTagDelimeter.NEWLINE:
      return filterTruthy(text.split("\n").map((value) => value.trim()));
    case RedoTagDelimeter.WHITESPACE:
      return filterTruthy(text.split(/\s/).map((value) => value.trim()));
    case RedoTagDelimeter.BOTH:
      return filterTruthy(text.split(/\s|\n/).map((value) => value.trim()));
  }
}
