// extracted by mini-css-extract-plugin
export var baseCustomerActivityCardArrowRight = "_9m-f";
export var baseCustomerActivityCardArrowRightContainer = "_9m-e";
export var baseCustomerActivityCardContainer = "_9m-c";
export var baseCustomerActivityCardContainerHovered = "_9m-d";
export var conversationActivityDataConversationIconContainer = "_9m-5";
export var customerActivityDataClaimIcon = "_9m-8";
export var customerActivityDataClaimIconContainer = "_9m-7";
export var customerActivityDataConversationIcon = "_9m-6";
export var customerActivityDataConversationIconContainer = "_9m-4";
export var customerActivityDataOrderIcon = "_9m-1";
export var customerActivityDataOrderIconContainer = "_9m-0";
export var customerActivityDataReturnIcon = "_9m-3";
export var customerActivityDataReturnIconContainer = "_9m-2";
export var customerActivityShoppingIconContainer = "_9m-9";
export var messageBubble = "_9m-a";
export var messageBubbleText = "_9m-b";