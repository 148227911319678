import DotsHorizontal from "@redotech/redo-web/arbiter-icon/dots-horizontal.svg";
import * as classNames from "classnames";
import { memo, useEffect, useState } from "react";
import { Flex } from "../../flex";
import { Text } from "../../text";
import { SpacingValue } from "../../theme/box";
import { Tooltip } from "../../tooltip/tooltip";
import { RedoBadge, RedoBadgeColor, RedoBadgeProps } from "../badge/redo-badge";
import { RedoButton } from "../buttons/redo-button";
import { RedoCheckbox, RedoCheckboxSize } from "../checkbox/redo-checkbox";
import {
  RedoCommandMenu,
  RedoCommandMenuItem,
} from "../command-menu/redo-command-menu";
import * as redoTableCellsCss from "./redo-table-cells.module.css";

export type RedoTableCellProps<DATA> = { row: DATA; rowIndex: number };

/** Takes a way to convert rows into the usable data for the text cell, and returns a way to create a text cell */
type TextCellProps = { text: string; subtext?: string; leading?: boolean };

// export function RedoTableTextCell<DATA>(
//   rowTypeToCellProps: (p: DATA) => TextCellProps,
// ) {
export const RedoTableTextCell = memo(function RedoTableTextCell({
  text,
  subtext,
  leading,
}: TextCellProps) {
  // const { text, subtext, leading } = rowTypeToCellProps(row);
  const styleToArgs = isLeadingToArgsMap(leading, subtext);
  return (
    <Flex
      className={classNames(
        redoTableCellsCss.overflowableTextContainer,
        redoTableCellsCss.cellContainer,
      )}
      dir="column"
      gap="xxs"
    >
      <Text
        className={redoTableCellsCss.overflowableText}
        fontSize={styleToArgs.mainFontSize}
        fontWeight={styleToArgs.mainFontWeight}
        textColor={styleToArgs.mainTextColor}
      >
        {text}
      </Text>
      {subtext && (
        <Text
          className={redoTableCellsCss.overflowableText}
          fontSize={styleToArgs.subFontSize}
          textColor={styleToArgs.subTextColor}
        >
          {subtext}
        </Text>
      )}
    </Flex>
  );
});
//   return RedoTableTextCell;
// }

const isLeadingToArgsMap = (leading?: boolean, subtext?: string): any => {
  if (leading) {
    return {
      mainFontSize: "xs",
      mainFontWeight: "medium",
      mainTextColor: "primary",
      subFontSize: "xxs",
      subFontWeight: "regular",
      subTextColor: "tertiary",
    };
  }
  return {
    mainFontSize: "xs",
    mainFontWeight: "regular",
    mainTextColor: subtext ? "primary" : "tertiary",
    subFontSize: "xxs",
    subFontWeight: "regular",
    subTextColor: "tertiary",
  };
};

export function RedoTableCheckboxCell({
  rowSelected,
  selectRow,
}: {
  rowSelected: boolean;
  selectRow: () => void;
}) {
  return (
    <Flex
      align="center"
      className={redoTableCellsCss.rowCheckboxCell}
      justify="center"
      p="xl"
    >
      <RedoCheckbox
        setValue={() => {
          selectRow();
        }}
        size={RedoCheckboxSize.SMALL}
        value={rowSelected}
      />
    </Flex>
  );
}

export const RedoTableCommandMenuCell = memo(function RedoTableCommandMenuCell({
  items,
}: {
  items: RedoCommandMenuItem[];
}) {
  const [menuOpen, setMenuOpen] = useState(false);

  const [menuAnchor, setMenuAnchor] = useState<HTMLButtonElement | null>(null);

  return (
    <>
      <Flex px="3xl">
        <RedoButton
          IconLeading={DotsHorizontal}
          onClick={(e) => {
            e.stopPropagation();
            setMenuOpen((prev) => !prev);
          }}
          ref={setMenuAnchor}
        />
      </Flex>
      <RedoCommandMenu
        anchor={menuAnchor}
        items={items}
        open={menuOpen}
        setOpen={setMenuOpen}
      />
    </>
  );
});

/** A cell that displays a list of tags, with a +N badge if there are more tags than can fit.
 * Designed to be used with RedoTableWidthMode.FIXED columns.
 **/
export const RedoTableBadgeCell = memo(function RedoTableBadgeCell({
  badges,
  cellProps,
}: {
  badges: RedoBadgeProps[];
  cellProps?: { padding?: SpacingValue };
}) {
  const [cellRef, setCellRef] = useState<HTMLHeadingElement | null>(null);

  const [tagsToDisplay, setTagsToDisplay] = useState(badges);

  const [hiddenBadges, getHiddenBadges] = useState<RedoBadgeProps[]>([]);

  useEffect(() => {
    if (!cellRef) {
      return;
    }

    const actualWidth = cellRef.getBoundingClientRect().width || 0;
    const maxWidth =
      cellRef.parentElement?.getBoundingClientRect().width || Infinity;

    if (actualWidth > maxWidth) {
      getHiddenBadges((prev) => [
        ...prev,
        tagsToDisplay[tagsToDisplay.length - 1],
      ]);
      setTagsToDisplay((prevTags) => prevTags.slice(0, prevTags.length - 1));
    }
  }, [cellRef, tagsToDisplay]);

  return (
    <Flex p={cellProps?.padding ?? "xl"} ref={setCellRef}>
      {tagsToDisplay.map((tag, idx) => (
        <RedoBadge
          {...tag}
          color={tag.color || RedoBadgeColor.BLUE_LIGHT}
          key={`${idx}`}
        />
      ))}
      {hiddenBadges.length > 0 && (
        <Tooltip title={hiddenBadges.map((badge) => badge.text).join(", ")}>
          {/* This div is needed to make the tooltip work */}
          <div>
            <RedoBadge
              color={RedoBadgeColor.GRAY}
              text={`+${hiddenBadges.length}`}
            />
          </div>
        </Tooltip>
      )}
    </Flex>
  );
});

export function RedoTablePaddedCell({
  children,
}: {
  children: React.ReactNode;
}) {
  return <Flex p="xl">{children}</Flex>;
}
