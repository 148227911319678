import type { RedoMarketingRpcClient } from "@redotech/merchant-sdk/marketing-rpc/client";
import { createRedoMarketingRpcClient } from "@redotech/merchant-sdk/marketing-rpc/client";
import { createContext, ReactNode, useMemo } from "react";

export const RedoMarketingRpcClientContext = createContext<
  RedoMarketingRpcClient | undefined
>(undefined);
RedoMarketingRpcClientContext.displayName = "RedoMarketingRpcClientContext";

export function RedoMarketingRpcClientProvider({
  children,
  baseUrl,
  authToken,
}: {
  children: ReactNode | ReactNode[];
  baseUrl: string;
  authToken?: string;
}) {
  const redoMarketingRpcClient = useMemo(() => {
    if (!authToken) {
      return undefined;
    }
    return createRedoMarketingRpcClient({
      baseURL: new URL(`${baseUrl}/marketing-rpc`),
      headers: { Authorization: authToken },
    });
  }, [baseUrl, authToken]);
  return (
    <RedoMarketingRpcClientContext.Provider value={redoMarketingRpcClient}>
      {children}
    </RedoMarketingRpcClientContext.Provider>
  );
}
