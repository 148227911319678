import { PillTheme } from "@redotech/redo-model/pill-theme";
import { RedoBaseModal } from "@redotech/redo-web/arbiter-components/modal/redo-base-modal";
import { RedoModalHeader } from "@redotech/redo-web/arbiter-components/modal/redo-modal";
import { Button, ButtonTheme } from "@redotech/redo-web/button";
import { Flex } from "@redotech/redo-web/flex";
import TrashIcon from "@redotech/redo-web/icon-old/trash.svg";
import { memo, useState } from "react";

import { LoadState } from "@redotech/react-util/load";
import * as conversationTagInputCss from "./conversation-tag-input.module.css";
import { TagWithId } from "./create-tag-modal";
import { DeleteTagModal } from "./delete-tag-modal";
import { TagInputFields } from "./tag-fields";

export const EditTagModal = memo(function EditTagModal({
  handleEdit,
  tag,
  tags,
  resolved,
  handleDelete,
  disableNameEdit = false,
}: {
  handleEdit: ({
    tagId,
    name,
    pillTheme,
  }: {
    tagId: string;
    name: string;
    pillTheme: PillTheme;
  }) => Promise<void>;
  tag: TagWithId;
  tags: LoadState<TagWithId[]>;
  resolved(): void;
  handleDelete?: ({ tagId }: { tagId: string }) => Promise<void>;
  disableNameEdit?: boolean;
}) {
  const existingTagNames = new Set(
    tags.value?.map((tag) => tag.name.toLowerCase()),
  );
  existingTagNames.delete(tag.name.toLowerCase());

  const [pillTheme, setPillTheme] = useState<PillTheme>(
    tag.pillTheme || PillTheme.PRIMARY_LIGHT,
  );

  const [name, setName] = useState(tag.name);

  const [deleteModalOpen, setDeleteModalOpen] = useState(false);

  const nameConflict = existingTagNames.has(name.toLowerCase());

  async function handleSave() {
    if (name === tag.name && pillTheme === tag.pillTheme) {
      resolved();
      return;
    }
    await handleEdit({ tagId: tag.tagId, name, pillTheme });

    resolved();
  }

  const footerAction = (
    <footer className={conversationTagInputCss.footerContainer}>
      {handleDelete && (
        <Button
          icon={(props) => (
            <TrashIcon className={conversationTagInputCss.icon} {...props} />
          )}
          onClick={() => setDeleteModalOpen(true)}
          theme={ButtonTheme.DANGER_OUTLINED}
        >
          Delete tag
        </Button>
      )}
      <Flex ml="auto">
        <Button onClick={resolved} theme={ButtonTheme.OUTLINED}>
          Cancel
        </Button>
        <Button
          disabled={!name || nameConflict}
          onClick={handleSave}
          theme={ButtonTheme.PRIMARY}
        >
          Save
        </Button>
      </Flex>
    </footer>
  );

  if (deleteModalOpen && handleDelete) {
    return (
      <DeleteTagModal
        cancelled={() => setDeleteModalOpen(false)}
        handleDelete={handleDelete}
        tag={tag}
      />
    );
  } else {
    return (
      <RedoBaseModal isOpen onModalCloseRequested={resolved}>
        <RedoModalHeader cancelClicked={resolved} title="Manage tag" />
        <section className={conversationTagInputCss.editModalBody}>
          <TagInputFields
            disableNameEdit={disableNameEdit}
            name={name}
            pillTheme={pillTheme}
            setName={setName}
            setPillTheme={setPillTheme}
            showNameError={nameConflict}
          />
        </section>
        {footerAction}
      </RedoBaseModal>
    );
  }
});
