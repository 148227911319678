import { FulfillmentOrderData } from "@redotech/redo-model/fulfillments/fulfillment-group";
import { RedoTableTextCell } from "@redotech/redo-web/arbiter-components/tables/redo-table-cells";
import { ColumnAlignment } from "@redotech/redo-web/table";
import { Column2 } from "@redotech/redo-web/table2";
import { getOrderAge } from "../../utils/outbound-labels-utils";

export const OrderAgeColumn: Column2<FulfillmentOrderData> = {
  key: "order-age",
  title: "Order age",
  alignment: ColumnAlignment.LEFT,
  renderNormalCell: ({ row }) => <RedoTableTextCell text={getOrderAge(row)} />,
  width: 100,
};
