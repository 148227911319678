import { z } from "zod";
import { upsellPageDataSchema } from "./upsell-page-data";

export const createUpsellPage = {
  input: z.object({
    teamId: z.string(),
    upsellPageData: upsellPageDataSchema,
    id: z.string().optional(),
  }),
  output: z.null(),
};
