// This is a copy of the CountryCode enum from @redotech/shopify-client/admin.graphql, but that can also be used in redo/model and therefore the frontend
export enum RedoShopifyCountryCode {
  /** Ascension Island. */
  Ac = "AC",
  /** Andorra. */
  Ad = "AD",
  /** United Arab Emirates. */
  Ae = "AE",
  /** Afghanistan. */
  Af = "AF",
  /** Antigua & Barbuda. */
  Ag = "AG",
  /** Anguilla. */
  Ai = "AI",
  /** Albania. */
  Al = "AL",
  /** Armenia. */
  Am = "AM",
  /** Netherlands Antilles. */
  An = "AN",
  /** Angola. */
  Ao = "AO",
  /** Argentina. */
  Ar = "AR",
  /** Austria. */
  At = "AT",
  /** Australia. */
  Au = "AU",
  /** Aruba. */
  Aw = "AW",
  /** Åland Islands. */
  Ax = "AX",
  /** Azerbaijan. */
  Az = "AZ",
  /** Bosnia & Herzegovina. */
  Ba = "BA",
  /** Barbados. */
  Bb = "BB",
  /** Bangladesh. */
  Bd = "BD",
  /** Belgium. */
  Be = "BE",
  /** Burkina Faso. */
  Bf = "BF",
  /** Bulgaria. */
  Bg = "BG",
  /** Bahrain. */
  Bh = "BH",
  /** Burundi. */
  Bi = "BI",
  /** Benin. */
  Bj = "BJ",
  /** St. Barthélemy. */
  Bl = "BL",
  /** Bermuda. */
  Bm = "BM",
  /** Brunei. */
  Bn = "BN",
  /** Bolivia. */
  Bo = "BO",
  /** Caribbean Netherlands. */
  Bq = "BQ",
  /** Brazil. */
  Br = "BR",
  /** Bahamas. */
  Bs = "BS",
  /** Bhutan. */
  Bt = "BT",
  /** Bouvet Island. */
  Bv = "BV",
  /** Botswana. */
  Bw = "BW",
  /** Belarus. */
  By = "BY",
  /** Belize. */
  Bz = "BZ",
  /** Canada. */
  Ca = "CA",
  /** Cocos (Keeling) Islands. */
  Cc = "CC",
  /** Congo - Kinshasa. */
  Cd = "CD",
  /** Central African Republic. */
  Cf = "CF",
  /** Congo - Brazzaville. */
  Cg = "CG",
  /** Switzerland. */
  Ch = "CH",
  /** Côte d’Ivoire. */
  Ci = "CI",
  /** Cook Islands. */
  Ck = "CK",
  /** Chile. */
  Cl = "CL",
  /** Cameroon. */
  Cm = "CM",
  /** China. */
  Cn = "CN",
  /** Colombia. */
  Co = "CO",
  /** Costa Rica. */
  Cr = "CR",
  /** Cuba. */
  Cu = "CU",
  /** Cape Verde. */
  Cv = "CV",
  /** Curaçao. */
  Cw = "CW",
  /** Christmas Island. */
  Cx = "CX",
  /** Cyprus. */
  Cy = "CY",
  /** Czechia. */
  Cz = "CZ",
  /** Germany. */
  De = "DE",
  /** Djibouti. */
  Dj = "DJ",
  /** Denmark. */
  Dk = "DK",
  /** Dominica. */
  Dm = "DM",
  /** Dominican Republic. */
  Do = "DO",
  /** Algeria. */
  Dz = "DZ",
  /** Ecuador. */
  Ec = "EC",
  /** Estonia. */
  Ee = "EE",
  /** Egypt. */
  Eg = "EG",
  /** Western Sahara. */
  Eh = "EH",
  /** Eritrea. */
  Er = "ER",
  /** Spain. */
  Es = "ES",
  /** Ethiopia. */
  Et = "ET",
  /** Finland. */
  Fi = "FI",
  /** Fiji. */
  Fj = "FJ",
  /** Falkland Islands. */
  Fk = "FK",
  /** Faroe Islands. */
  Fo = "FO",
  /** France. */
  Fr = "FR",
  /** Gabon. */
  Ga = "GA",
  /** United Kingdom. */
  Gb = "GB",
  /** Grenada. */
  Gd = "GD",
  /** Georgia. */
  Ge = "GE",
  /** French Guiana. */
  Gf = "GF",
  /** Guernsey. */
  Gg = "GG",
  /** Ghana. */
  Gh = "GH",
  /** Gibraltar. */
  Gi = "GI",
  /** Greenland. */
  Gl = "GL",
  /** Gambia. */
  Gm = "GM",
  /** Guinea. */
  Gn = "GN",
  /** Guadeloupe. */
  Gp = "GP",
  /** Equatorial Guinea. */
  Gq = "GQ",
  /** Greece. */
  Gr = "GR",
  /** South Georgia & South Sandwich Islands. */
  Gs = "GS",
  /** Guatemala. */
  Gt = "GT",
  /** Guinea-Bissau. */
  Gw = "GW",
  /** Guyana. */
  Gy = "GY",
  /** Hong Kong SAR. */
  Hk = "HK",
  /** Heard & McDonald Islands. */
  Hm = "HM",
  /** Honduras. */
  Hn = "HN",
  /** Croatia. */
  Hr = "HR",
  /** Haiti. */
  Ht = "HT",
  /** Hungary. */
  Hu = "HU",
  /** Indonesia. */
  Id = "ID",
  /** Ireland. */
  Ie = "IE",
  /** Israel. */
  Il = "IL",
  /** Isle of Man. */
  Im = "IM",
  /** India. */
  In = "IN",
  /** British Indian Ocean Territory. */
  Io = "IO",
  /** Iraq. */
  Iq = "IQ",
  /** Iran. */
  Ir = "IR",
  /** Iceland. */
  Is = "IS",
  /** Italy. */
  It = "IT",
  /** Jersey. */
  Je = "JE",
  /** Jamaica. */
  Jm = "JM",
  /** Jordan. */
  Jo = "JO",
  /** Japan. */
  Jp = "JP",
  /** Kenya. */
  Ke = "KE",
  /** Kyrgyzstan. */
  Kg = "KG",
  /** Cambodia. */
  Kh = "KH",
  /** Kiribati. */
  Ki = "KI",
  /** Comoros. */
  Km = "KM",
  /** St. Kitts & Nevis. */
  Kn = "KN",
  /** North Korea. */
  Kp = "KP",
  /** South Korea. */
  Kr = "KR",
  /** Kuwait. */
  Kw = "KW",
  /** Cayman Islands. */
  Ky = "KY",
  /** Kazakhstan. */
  Kz = "KZ",
  /** Laos. */
  La = "LA",
  /** Lebanon. */
  Lb = "LB",
  /** St. Lucia. */
  Lc = "LC",
  /** Liechtenstein. */
  Li = "LI",
  /** Sri Lanka. */
  Lk = "LK",
  /** Liberia. */
  Lr = "LR",
  /** Lesotho. */
  Ls = "LS",
  /** Lithuania. */
  Lt = "LT",
  /** Luxembourg. */
  Lu = "LU",
  /** Latvia. */
  Lv = "LV",
  /** Libya. */
  Ly = "LY",
  /** Morocco. */
  Ma = "MA",
  /** Monaco. */
  Mc = "MC",
  /** Moldova. */
  Md = "MD",
  /** Montenegro. */
  Me = "ME",
  /** St. Martin. */
  Mf = "MF",
  /** Madagascar. */
  Mg = "MG",
  /** North Macedonia. */
  Mk = "MK",
  /** Mali. */
  Ml = "ML",
  /** Myanmar (Burma). */
  Mm = "MM",
  /** Mongolia. */
  Mn = "MN",
  /** Macao SAR. */
  Mo = "MO",
  /** Martinique. */
  Mq = "MQ",
  /** Mauritania. */
  Mr = "MR",
  /** Montserrat. */
  Ms = "MS",
  /** Malta. */
  Mt = "MT",
  /** Mauritius. */
  Mu = "MU",
  /** Maldives. */
  Mv = "MV",
  /** Malawi. */
  Mw = "MW",
  /** Mexico. */
  Mx = "MX",
  /** Malaysia. */
  My = "MY",
  /** Mozambique. */
  Mz = "MZ",
  /** Namibia. */
  Na = "NA",
  /** New Caledonia. */
  Nc = "NC",
  /** Niger. */
  Ne = "NE",
  /** Norfolk Island. */
  Nf = "NF",
  /** Nigeria. */
  Ng = "NG",
  /** Nicaragua. */
  Ni = "NI",
  /** Netherlands. */
  Nl = "NL",
  /** Norway. */
  No = "NO",
  /** Nepal. */
  Np = "NP",
  /** Nauru. */
  Nr = "NR",
  /** Niue. */
  Nu = "NU",
  /** New Zealand. */
  Nz = "NZ",
  /** Oman. */
  Om = "OM",
  /** Panama. */
  Pa = "PA",
  /** Peru. */
  Pe = "PE",
  /** French Polynesia. */
  Pf = "PF",
  /** Papua New Guinea. */
  Pg = "PG",
  /** Philippines. */
  Ph = "PH",
  /** Pakistan. */
  Pk = "PK",
  /** Poland. */
  Pl = "PL",
  /** St. Pierre & Miquelon. */
  Pm = "PM",
  /** Pitcairn Islands. */
  Pn = "PN",
  /** Palestinian Territories. */
  Ps = "PS",
  /** Portugal. */
  Pt = "PT",
  /** Paraguay. */
  Py = "PY",
  /** Qatar. */
  Qa = "QA",
  /** Réunion. */
  Re = "RE",
  /** Romania. */
  Ro = "RO",
  /** Serbia. */
  Rs = "RS",
  /** Russia. */
  Ru = "RU",
  /** Rwanda. */
  Rw = "RW",
  /** Saudi Arabia. */
  Sa = "SA",
  /** Solomon Islands. */
  Sb = "SB",
  /** Seychelles. */
  Sc = "SC",
  /** Sudan. */
  Sd = "SD",
  /** Sweden. */
  Se = "SE",
  /** Singapore. */
  Sg = "SG",
  /** St. Helena. */
  Sh = "SH",
  /** Slovenia. */
  Si = "SI",
  /** Svalbard & Jan Mayen. */
  Sj = "SJ",
  /** Slovakia. */
  Sk = "SK",
  /** Sierra Leone. */
  Sl = "SL",
  /** San Marino. */
  Sm = "SM",
  /** Senegal. */
  Sn = "SN",
  /** Somalia. */
  So = "SO",
  /** Suriname. */
  Sr = "SR",
  /** South Sudan. */
  Ss = "SS",
  /** São Tomé & Príncipe. */
  St = "ST",
  /** El Salvador. */
  Sv = "SV",
  /** Sint Maarten. */
  Sx = "SX",
  /** Syria. */
  Sy = "SY",
  /** Eswatini. */
  Sz = "SZ",
  /** Tristan da Cunha. */
  Ta = "TA",
  /** Turks & Caicos Islands. */
  Tc = "TC",
  /** Chad. */
  Td = "TD",
  /** French Southern Territories. */
  Tf = "TF",
  /** Togo. */
  Tg = "TG",
  /** Thailand. */
  Th = "TH",
  /** Tajikistan. */
  Tj = "TJ",
  /** Tokelau. */
  Tk = "TK",
  /** Timor-Leste. */
  Tl = "TL",
  /** Turkmenistan. */
  Tm = "TM",
  /** Tunisia. */
  Tn = "TN",
  /** Tonga. */
  To = "TO",
  /** Türkiye. */
  Tr = "TR",
  /** Trinidad & Tobago. */
  Tt = "TT",
  /** Tuvalu. */
  Tv = "TV",
  /** Taiwan. */
  Tw = "TW",
  /** Tanzania. */
  Tz = "TZ",
  /** Ukraine. */
  Ua = "UA",
  /** Uganda. */
  Ug = "UG",
  /** U.S. Outlying Islands. */
  Um = "UM",
  /** United States. */
  Us = "US",
  /** Uruguay. */
  Uy = "UY",
  /** Uzbekistan. */
  Uz = "UZ",
  /** Vatican City. */
  Va = "VA",
  /** St. Vincent & Grenadines. */
  Vc = "VC",
  /** Venezuela. */
  Ve = "VE",
  /** British Virgin Islands. */
  Vg = "VG",
  /** Vietnam. */
  Vn = "VN",
  /** Vanuatu. */
  Vu = "VU",
  /** Wallis & Futuna. */
  Wf = "WF",
  /** Samoa. */
  Ws = "WS",
  /** Kosovo. */
  Xk = "XK",
  /** Yemen. */
  Ye = "YE",
  /** Mayotte. */
  Yt = "YT",
  /** South Africa. */
  Za = "ZA",
  /** Zambia. */
  Zm = "ZM",
  /** Zimbabwe. */
  Zw = "ZW",
  /** Unknown Region. */
  Zz = "ZZ",
}

export function isRedoShopifyCountryCode(
  countryCode: string,
): countryCode is RedoShopifyCountryCode {
  return Object.values(RedoShopifyCountryCode).includes(
    countryCode as RedoShopifyCountryCode,
  );
}
