import type { infer as zInfer } from "zod";
import { object, string } from "zod";

export const getRivoIntegration = {
  input: object({}),
  output: object({
    rivoIntegration: object({ rewardsPageUrl: string() }).optional(),
  }),
};

export type RivoIntegration = zInfer<typeof getRivoIntegration.output>;
