import { DateRange } from "@redotech/redo-model/concierge-conversation/analytics";
import { productWithConciergeMetricsSchema } from "@redotech/redo-model/concierge-conversation/rpc/product-with-concierge-metrics";
import { z } from "zod";

export const getConciergeDashboardProductTableData = {
  input: z.object({
    dateRange: z.nativeEnum(DateRange),
    pagination: z.object({ token: z.string().optional() }),
    searchText: z.string().optional(),
  }),
  output: z.object({
    productsWithConciergeMetrics: z.array(productWithConciergeMetricsSchema),
    pagination: z.object({ token: z.string().optional() }),
  }),
};
