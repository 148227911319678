import * as classNames from "classnames";
import { MouseEventHandler } from "react";
import XIcon from "../arbiter-icon/x-close.svg";
import * as closeXCss from "./redo-button-close-x.module.css";

export enum ButtonSize {
  XSMALL = "xs",
  SMALL = "sm",
  MEDIUM = "md",
  LARGE = "lg",
}

/** NOTE: THIS COMPONENT IS UNTESTED AND I THINK THE STYLES ARE OFF. IT NEEDS A STORYBOOK AND TO BE CLEANED UP*/
export function RedoButtonCloseX({
  onDarkBackground,
  size,
  onclick,
  style,
}: {
  onDarkBackground: boolean;
  size: ButtonSize;
  onclick?: MouseEventHandler<HTMLDivElement>;
  style?: any;
}) {
  return (
    <div
      className={classNames(
        closeXCss.button,
        onDarkBackground
          ? closeXCss.hasDarkBackground
          : closeXCss.hasLightBackground,
        (() => {
          switch (size) {
            case ButtonSize.XSMALL:
              return closeXCss.xsmall;
            case ButtonSize.SMALL:
              return closeXCss.small;
            case ButtonSize.MEDIUM:
              return closeXCss.medium;
            case ButtonSize.LARGE:
              return closeXCss.large;
          }
        })(),
      )}
      onClick={onclick}
      style={style}
    >
      <div className={closeXCss.iconWrapper}>
        <XIcon />
      </div>
    </div>
  );
}
