// extracted by mini-css-extract-plugin
export var actionButton = "_b9-0";
export var bubble = "_b9-2";
export var bubbleContext = "_b9-6";
export var bubbleItems = "_b9-7";
export var customer = "_b9-3";
export var divider = "_b9-8";
export var fileList = "_b9-9";
export var infoAbove = "_b9-a";
export var internal = "_b9-4";
export var lightActionButton = "_b9-1";
export var link = "_b9-b";
export var merchant = "_b9-5";
export var message = "_b9-c";
export var profilePicture = "_b9-e";
export var quillToolbarContainer = "_b9-f";
export var senderDate = "_b9-g";
export var senderDateCustomer = "_b9-h";
export var senderName = "_b9-d";
export var time = "_b9-i";
export var uploadedImage = "_b9-j";
export var username = "_b9-k";
export var xsmall = "_b9-l";