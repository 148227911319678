import type { SchemaType } from "./advanced-flow/schemas/schemas";
import type { Category } from "./advanced-flow/triggers";
import type {
  Schema,
  SchemaInstance,
} from "./advanced-flow/type-system/schema";
import { DiscountGroupType, DiscountProvider } from "./discount";
import type { Team, UpsellProductSection, UpsellProductSource } from "./team";
import { TimeUnit } from "./time";
import { UtmParameters } from "./utm";
export { widthPixelsToPercentage } from "./email-builder/email-sizing";

// Josh - I have no idea what any of this is doing.
// I extracted this from the customer return generation process
// and inferred the types from the context.
export interface CustomerReturnCreatedAttachmentGenerationParameters {
  shipments: {
    postage_label?: string;
    form_label?: string;
    _shipment: { forms?: { form_type?: string; form_url?: string }[] };
  }[];
  variables: {
    postage_label?: string;
    form_label?: string;
    commercialInvoice?: string;
  };
  pickup_details: boolean; // all we care about is truthiness, apparently
}

export enum EmailFormat {
  STATIC = "static",
  AMP = "amp",
  // Apple Mail should NOT be added here.
  // It should instead be rendered inside of static emails, conditionally rendered via @supports queries
}

export enum ProductSelectionType {
  DYNAMIC = "dynamic",
  STATIC = "static",
}

export enum CartLayoutType {
  ROWS = "rows",
  COLUMNS = "columns",
}

export function getLayoutTypeFromColumnCount(
  columnCount: number,
): CartLayoutType {
  return columnCount > 1 ? CartLayoutType.COLUMNS : CartLayoutType.ROWS;
}

export namespace ButtonSize {
  export const SMALL = 0.8;
  export const MEDIUM = 1;
  export const LARGE = 1.2;
  export const DEFAULT_HEIGHT = 40;
  export const DEFAULT_WIDTH = 200;
}

export namespace PaddingSize {
  export const SMALL = 8;
  export const MEDIUM = 16;
  export const LARGE = 32;
}

export enum FontWeight {
  NORMAL = "normal",
  BOLD = "bold",
}

export type Padding = {
  top: number;
  right: number;
  bottom: number;
  left: number;
};

export enum TemplateType {
  TRANSACTIONAL = "transactional",
  MARKETING = "marketing",
  DEFAULT = "default",
}

export enum ProductRecommendationType {
  PROVIDER_RECOMMENDATIONS = "shopify_recommendations",
  COLLECTION = "collection",
  METAFIELD = "metafield",
  TAG = "tag",
  BEST_SELLERS = "best_sellers",
  MOST_VIEWED = "most_viewed",
  NEWEST_PRODUCTS = "newest_products",
  RANDOM_PRODUCTS = "random_products",
  VIEWED_PRODUCTS = "viewed_products",
  PRODUCTS_ADDED_TO_CART = "products_added_to_cart",
}

export interface Discount {
  _id: string;
  title: string;
  provider: DiscountProvider;
  groupType: DiscountGroupType;
  discountId: string;
}

export interface BaseRecommendedProductFilter {
  productRecommendationType: ProductRecommendationType;
  name: string;
  _id: string;
  additionalProductFilters?: AdditionalProductFilter[];
}

export function isCollectionRecommendedProductFilter(
  filter: RecommendedProductFilter | undefined,
): filter is CollectionRecommendedProductFilter {
  return (
    filter?.productRecommendationType === ProductRecommendationType.COLLECTION
  );
}

export interface CollectionRecommendedProductFilter
  extends BaseRecommendedProductFilter {
  productRecommendationType: ProductRecommendationType.COLLECTION;
  collectionId: string;
}

export function isMetafieldRecommendedProductFilter(
  filter: RecommendedProductFilter | undefined,
): filter is MetafieldRecommendedProductFilter {
  return (
    filter?.productRecommendationType === ProductRecommendationType.METAFIELD
  );
}

export interface MetafieldRecommendedProductFilter
  extends BaseRecommendedProductFilter {
  productRecommendationType: ProductRecommendationType.METAFIELD;
  metafield: { key: string; namespace: string };
}

export function isViewedProductsRecommendedProductFilter(
  filter: RecommendedProductFilter | undefined,
): filter is ViewedProductsRecommendedProductFilter {
  return (
    filter?.productRecommendationType ===
    ProductRecommendationType.VIEWED_PRODUCTS
  );
}

export interface ViewedProductsRecommendedProductFilter
  extends BaseRecommendedProductFilter {
  productRecommendationType: ProductRecommendationType.VIEWED_PRODUCTS;
  unit: TimeUnit;
  value: number;
}

export function isProductsAddedToCartRecommendedProductFilter(
  filter: RecommendedProductFilter | undefined,
): filter is ProductsAddedToCartRecommendedProductFilter {
  return (
    filter?.productRecommendationType ===
    ProductRecommendationType.PRODUCTS_ADDED_TO_CART
  );
}

export interface ProductsAddedToCartRecommendedProductFilter
  extends BaseRecommendedProductFilter {
  productRecommendationType: ProductRecommendationType.PRODUCTS_ADDED_TO_CART;
  unit: TimeUnit;
  value: number;
}

export interface BasicRecommendedProductFilter
  extends BaseRecommendedProductFilter {
  productRecommendationType: ProductRecommendationType.PROVIDER_RECOMMENDATIONS;
}

export type RecommendedProductFilter =
  | CollectionRecommendedProductFilter
  | MetafieldRecommendedProductFilter
  | ViewedProductsRecommendedProductFilter
  | ProductsAddedToCartRecommendedProductFilter
  | BasicRecommendedProductFilter;

export type AdditionalProductFilter =
  | UniqueProductFilterCollection
  | UniqueProductFilterMetafield
  | UniqueProductFilterTag;

export interface UniqueProductFilterCollection {
  type: UniqueProductFilterType.COLLECTION;
  collectionIds: string[];
}

export interface UniqueProductFilterMetafield {
  type: UniqueProductFilterType.METAFIELD;
  metafields: { metafieldKey: string; namespace: string }[];
}

export interface UniqueProductFilterTag {
  type: UniqueProductFilterType.TAG;
  tags: string[];
}

export enum UniqueProductFilterType {
  COLLECTION = "collection",
  METAFIELD = "metafield",
  TAG = "tag",
}

/**
 * Templates are nested inside of various other objects
 */
export enum TemplateSource {
  TEMPLATE_COLLECTION = "template_collection",
  CAMPAIGN = "campaign",
  // AUTOMATION might go here in the future
}

export function templateTypeLabel(templateType: TemplateType): string {
  switch (templateType) {
    case TemplateType.TRANSACTIONAL:
      return "Transactional";
    case TemplateType.MARKETING:
      return "Marketing";
    case TemplateType.DEFAULT:
      return "Default";
  }
}

export enum Alignment {
  LEFT = "left",
  CENTER = "center",
  RIGHT = "right",
}

export enum VerticalAlignment {
  TOP = "top",
  CENTER = "center",
  BOTTOM = "bottom",
}

export enum EmailHeaderType {
  IMAGE = "image",
  LOGO = "logo",
  TEXT = "text",
}

export enum Size {
  SMALL = "small",
  MEDIUM = "medium",
  LARGE = "large",
  CUSTOM = "custom",
}

export enum ButtonLinkType {
  WEB_PAGE = "web-page",
  DYNAMIC_VARIABLE = "dynamic-variable",
}

export type Address = {
  businessAddress: string;
  legalAddress: string;
  cityStateZip: string;
  country: string;
};

export enum FontFamily {
  ARIAL = "Arial",
  COURIER_NEW = "Courier New",
  GEORGIA = "Georgia",
  LUCIDA_SANS_UNICODE = "Lucida Sans Unicode",
  TAHOMA = "Tahoma",
  TIMES_NEW_ROMAN = "Times New Roman",
  TREBUCHET_MS = "Trebuchet MS",
  VERDANA = "Verdana",
}

export const FONT_FAMILIES = [
  FontFamily.ARIAL,
  FontFamily.COURIER_NEW,
  FontFamily.GEORGIA,
  FontFamily.LUCIDA_SANS_UNICODE,
  FontFamily.TAHOMA,
  FontFamily.TIMES_NEW_ROMAN,
  FontFamily.TREBUCHET_MS,
  FontFamily.VERDANA,
] as const;

export enum EmailBlockType {
  TRACKABLE_SUMMARY = "order-summary",
  TRACKING_INFO = "tracking-info",
  BUTTON = "button",
  INTERACTIVE_CART = "interactive-cart",
  HEADER = "header",
  FOOTER = "footer",
  IMAGE = "image",
  TEXT = "text",
  SPACER = "spacer",
  LINE = "line",
  REVIEW_REQUEST = "review-request",
  COLUMN = "column",
  MENU = "menu",
  SOCIALS = "socials",
  DISCOUNT = "discount",
}

export enum SocialPlatform {
  APPLE = "apple",
  DISCORD = "discord",
  FACEBOOK = "facebook",
  GITHUB = "github",
  GOOGLE = "google",
  INSTAGRAM = "instagram",
  LINKEDIN = "linkedin",
  PINTEREST = "pinterest",
  REDDIT = "reddit",
  SNAPCHAT = "snapchat",
  TIKTOK = "tiktok",
  TWITTER = "twitter",
  YOUTUBE = "youtube",
}

export type MenuItem = { id: string; label: string };

export type SocialItem = { id: string; platform: SocialPlatform; url: string };
export namespace Section {
  interface BaseSection {
    schemaFieldName?: string;
    blockId?: string;
    duplicate?: (section: Section | undefined) => Section;
    sectionPadding: Padding;
  }

  export interface Discount extends BaseSection {
    type: EmailBlockType.DISCOUNT;
    discountId?: string;
    alignment: Alignment;
    fontFamily: FontFamily;
    fontWeight: FontWeight;
    fontSize: number;
    textColor: string;
    sectionColor: string;
    blockBackgroundColor: string;
  }

  export interface Column extends BaseSection {
    type: EmailBlockType.COLUMN;
    columns: (Section | null)[];
    columnCount: number;
    sectionColor: string;
    gap: number;
    stackOnMobile: boolean;
    alignment: VerticalAlignment;
  }

  export interface Menu extends BaseSection {
    type: EmailBlockType.MENU;
    menuItems: MenuItem[];
    sectionColor: string;
    alignment: Alignment;
    fontFamily: FontFamily;
    fontSize: number;
    linkColor: string;
    textColor: string;
  }

  export interface Socials extends BaseSection {
    type: EmailBlockType.SOCIALS;
    socialLinks: SocialItem[];
    iconColor: string;
    iconPadding: number;
    alignment: Alignment;
    sectionColor: string;
  }

  export interface Header extends BaseSection {
    type: EmailBlockType.HEADER;
    headerType: EmailHeaderType;
    layout: Alignment;
    sectionColor: string;
    padding: number;
    imageUrl: string;
    text: string;
    textColor: string;
    fontSize: number;
    fontFamily: FontFamily;
    logoHeight: number;
    imageHeight: number;
    clickthroughUrl?: string;
    altText?: string;
  }

  export interface Footer extends BaseSection {
    type: EmailBlockType.FOOTER;
    padding: Padding;
    horizontalPadding: Size;
    verticalPadding: Size;
    sectionColor: string;
    textColor: string;
    alignment: Alignment;
    fontSize?: number;
    fontFamily?: FontFamily;
  }

  export interface Text extends BaseSection {
    type: EmailBlockType.TEXT;
    padding: number;
    textColor: string;
    fontSize: number;
    fontFamily: FontFamily;
    sectionColor: string;
    linkColor: string;
    text: string;
  }

  export interface Button extends BaseSection {
    type: EmailBlockType.BUTTON;
    width: number;
    height: number;
    size: Size;
    alignment: Alignment;
    cornerRadius: number;
    buttonText: string;
    horizontalPadding: Size;
    verticalPadding: Size;
    padding: Padding;
    /** hardcoded button link as opposed to dynamic variable from schema */
    buttonLink?: string;
    fillColor: string;
    strokeColor: string;
    textColor: string;
    strokeWeight: number;
    fontFamily: FontFamily;
    fontSize: number;
    sectionColor: string;
    as?: "button" | "a";
    buttonType?: "button" | "submit" | "reset";
    fullWidth?: boolean;
    linkType?: ButtonLinkType;
  }

  export interface ReviewRequest extends BaseSection {
    type: EmailBlockType.REVIEW_REQUEST;
    padding: number;
    sectionColor: string;
    sectionHeader: string;
    imageCornerRadius: number;
    imageSize: Size;
    buttonHeight: number;
    buttonWidth: number;
    buttonColor: string;
    buttonTextColor: string;
    buttonSize: Size;
    buttonCornerRadius: number;
    textColor: string;
    fontFamily: FontFamily;
  }

  export interface Line extends BaseSection {
    type: EmailBlockType.LINE;
    color: string;
    padding: Padding;
    horizontalPadding: Size;
    verticalPadding: Size;
    sectionColor: string;
  }

  export interface Spacer extends BaseSection {
    type: EmailBlockType.SPACER;
    sectionColor: string;
    height: number;
  }

  export interface Image extends BaseSection {
    type: EmailBlockType.IMAGE;
    imageUrl: string;
    padding: Padding;
    horizontalPadding: Size;
    verticalPadding: Size;
    sectionColor: string;
    showCaption: boolean;
    caption?: string;
    altText?: string;
    clickthroughUrl?: string;
    aspectRatio?: number; // needed for amp images
  }

  export interface TrackingInfo extends BaseSection {
    type: EmailBlockType.TRACKING_INFO;
    padding: number;
    sectionColor: string;
    textColor: string;
    buttonVisible: boolean;
    buttonHeight: number;
    buttonWidth: number;
    buttonColor: string;
    buttonTextColor: string;
    buttonSize: Size;
    buttonCornerRadius: number;
    statusIndicatorColor: string;
    statusIndicatorInnerColor: string;
    fontFamily: FontFamily;
  }

  export interface TrackableSummary extends BaseSection {
    type: EmailBlockType.TRACKABLE_SUMMARY;
    showOrderInformation: boolean;
    showCostSummary: boolean;
    showPaymentInformation: boolean;
    showCustomerInformation: boolean;
    padding: number;
    sectionColor: string;
    textColor: string;
    fontFamily: FontFamily;
    imageSize: Size;
    imageCornerRadius: number;
  }

  // TODO: don't extend UpsellProductSection. Instead, give it a productSelectionStrategies: UpsellProductSection[] field
  // or something along those lines.
  export interface InteractiveCart extends BaseSection, UpsellProductSection {
    type: EmailBlockType.INTERACTIVE_CART;
    padding: number;
    sectionColor: string;
    textColor: string;
    fontFamily: FontFamily;
    imageCornerRadius: number;
    checkoutButton: Button;
    lineItemButtons: Button;
    /**
     * Only used when productSelectionType is dynamic,
     * otherwise it's inferred by the manually-specified products.
     */
    numberOfProducts: number;
    imageSize: Size;
    /**
     * The high-level product selection strategy: manually-specified or dynamic products
     */
    productSelectionType: ProductSelectionType;
    /**
     * TODO: change `source` to a list of product selection strategies that
     * that is used only when productSelectionType is "dynamic".
     */
    source: UpsellProductSource;
    showImage?: boolean;
    showTitle?: boolean;
    showPrice?: boolean;
    showButton?: boolean;
    showQuantity?: boolean;
    collectionId?: string;
    metafield?: { key: string; namespace: string };
    /**
     * Currently does nothing, since layout type can be inferred from column count.
     * Waiting to hear from designers what to do about this.
     * TODO: if this comment hasn't been removed and it's past January 2024, delete this field.
     */
    layoutType?: CartLayoutType;
    /**
     * Controls whether the text, buttons, etc. of a line item are aligned to the left, center, or right.
     * Only used when layoutType is column.
     */
    alignment: Alignment;
    /**
     * Only used when layoutType is column, although required
     * so that its value is preserved if layoutType is changed later.
     */
    columns: number;
    stackOnMobile: boolean;
    recommendedProductFilterId?: string;
  }
}

export type Section =
  | Section.Header
  | Section.Footer
  | Section.Text
  | Section.Button
  | Section.Line
  | Section.Spacer
  | Section.Image
  | Section.TrackingInfo
  | Section.TrackableSummary
  | Section.InteractiveCart
  | Section.ReviewRequest
  | Section.Column
  | Section.Menu
  | Section.Socials
  | Section.Discount;

export interface SharedProps {
  format: EmailFormat;
  team: Team;
  template: Omit<IEmailTemplate, "schemaType" | "sections" | "team">;
  schemaInstance: SchemaInstance<Schema>;
  isBuilder: boolean;
  blockId?: string;
  utm: UtmParameters;
}

/**
 * TODO: remove this in favor of the Zod schema `EmailTemplate`
 */
export interface IEmailTemplate {
  _id: string;
  name: string;
  templateType: TemplateType;
  subject: string;
  emailPreview?: string | null;
  sections: Section[];
  emailBackgroundColor: string;
  contentBackgroundColor: string;
  linkColor?: string | null;
  address: Address;
  team: any;
  schemaType: SchemaType;
  category: Category;
}

export const getButtonSize = (
  size: Size,
  customWidth: number,
  customHeight: number,
) => {
  switch (size) {
    case Size.SMALL:
      return {
        width: ButtonSize.DEFAULT_WIDTH,
        height: ButtonSize.DEFAULT_HEIGHT * ButtonSize.SMALL,
      };
    case Size.MEDIUM:
      return {
        width: ButtonSize.DEFAULT_WIDTH,
        height: ButtonSize.DEFAULT_HEIGHT * ButtonSize.MEDIUM,
      };
    case Size.LARGE:
      return {
        width: ButtonSize.DEFAULT_WIDTH,
        height: ButtonSize.DEFAULT_HEIGHT * ButtonSize.LARGE,
      };
    case Size.CUSTOM:
      return { width: customWidth, height: customHeight };
  }
};

export const getPadding = (
  horizontalPadding: Size,
  verticalPadding: Size,
  customPadding: Padding,
) => {
  let top = customPadding.top,
    right = customPadding.right,
    bottom = customPadding.bottom,
    left = customPadding.left;
  switch (horizontalPadding) {
    case Size.SMALL:
      left = right = PaddingSize.SMALL;
      break;
    case Size.MEDIUM:
      left = right = PaddingSize.MEDIUM;
      break;
    case Size.LARGE:
      left = right = PaddingSize.LARGE;
      break;
  }
  switch (verticalPadding) {
    case Size.SMALL:
      top = bottom = PaddingSize.SMALL;
      break;
    case Size.MEDIUM:
      top = bottom = PaddingSize.MEDIUM;
      break;
    case Size.LARGE:
      top = bottom = PaddingSize.LARGE;
      break;
  }
  return { top, right, bottom, left };
};

export class EmailUtmParameters extends UtmParameters {
  constructor(campaign?: string, contact?: string) {
    super({ source: "redo", medium: "email", campaign, contact });
  }

  override getSource(): string {
    return super.getSource() ?? "";
  }

  override getMedium(): string {
    return super.getMedium() ?? "";
  }
}

export enum ScreenSize {
  DESKTOP = "desktop",
  MOBILE = "mobile",
}

/**
 * How big the gap is between columns in a column section.
 * TODO: make this a setting in the column section itself.
 */
export const COLUMN_GAP = 24;
