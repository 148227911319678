// extracted by mini-css-extract-plugin
export var animationContainer = "_bp-0";
export var footer = "_bp-1";
export var gray = "_bp-2";
export var lineItem = "_bp-3";
export var lineItemImage = "_bp-4";
export var lineItemInfo = "_bp-5";
export var lineItemText = "_bp-6";
export var lineItems = "_bp-7";
export var modalContent = "_bp-8";
export var options = "_bp-9";
export var orderName = "_bp-a";
export var refundAmount = "_bp-b";