// extracted by mini-css-extract-plugin
export var attachment = "_bf-a";
export var emailContainer = "_bf-4";
export var emailContentPaddedContainer = "_bf-b";
export var emailHeader = "_bf-6";
export var emailInfoIcon = "_bf-9";
export var emailShortenedSummary = "_bf-e";
export var infoDropdown = "_bf-0";
export var infoRowBoundedContent = "_bf-d";
export var internal = "_bf-5";
export var merchant = "_bf-8";
export var messageInputContainer = "_bf-h";
export var normal = "_bf-7";
export var replyCard = "_bf-f";
export var shadowDomWrapper = "_bf-3";
export var subtleButton = "_bf-1";
export var subtleButtonIconContainer = "_bf-2";
export var toggleViewRepliesButton = "_bf-c";
export var wrappingText = "_bf-g";