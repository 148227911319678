import { Currency } from "@redotech/money/currencies";
import { useHandler } from "@redotech/react-util/hook";
import { FulfillmentOrderLineItem } from "@redotech/redo-model/fulfillments/fulfillment-order-line-item";
import { WeightUnit } from "@redotech/redo-model/outbound-labels/util";
import {
  RedoButton,
  RedoButtonHierarchy,
  RedoButtonSize,
  RedoButtonTheme,
} from "@redotech/redo-web/arbiter-components/buttons/redo-button";
import { RedoIncrementDecrement } from "@redotech/redo-web/arbiter-components/increment-decrement/redo-increment-decrement";
import { RedoInputSize } from "@redotech/redo-web/arbiter-components/input/base-redo-text-input";
import XIcon from "@redotech/redo-web/arbiter-icon/x-close.svg";
import { ButtonSize, IconButton } from "@redotech/redo-web/button";
import { Flex } from "@redotech/redo-web/flex";
import { Text } from "@redotech/redo-web/text";
import { memo, useState } from "react";
import * as styles from "./item-table.module.css";
import { ProductSearchInput } from "./product-search-input";

const DEFAULT_ITEM: FulfillmentOrderLineItem = {
  externalId: "",
  customAttributes: [],
  sku: "",
  title: "",
  productTitle: "",
  variantTitle: "",
  totalQuantity: 1,
  unfulfilledQuantity: 1,
  weight: { unit: WeightUnit.GRAM, value: 0 },
  lineItemId: "",
  unitPrice: { amount: "0", currency: Currency.USD },
  unitDiscountedPrice: { amount: "0", currency: Currency.USD },
  totalPrice: { amount: "0", currency: Currency.USD },
  totalDiscountedPrice: { amount: "0", currency: Currency.USD },
  totalTax: { amount: "0", currency: Currency.USD },
  hsCode: null,
} as const;

export const ItemTable = memo(function ItemTable({
  onItemsChange,
}: {
  onItemsChange: (items: FulfillmentOrderLineItem[]) => void;
}) {
  const [items, setItems] = useState<FulfillmentOrderLineItem[]>([
    DEFAULT_ITEM,
  ]);

  const updateItemProperty = useHandler(
    (index: number, value: Partial<FulfillmentOrderLineItem>) => {
      const updatedItem = { ...items[index], ...value };
      setItems((prev) => {
        const newItems = [
          ...prev.map((existingItem, i) =>
            i === index ? updatedItem : existingItem,
          ),
        ];
        onItemsChange(newItems);
        return newItems;
      });
    },
  );

  const updateItem = useHandler(
    (index: number, item: FulfillmentOrderLineItem) => {
      setItems((prev) => {
        const newItems = [
          ...prev.map((existingItem, i) => (i === index ? item : existingItem)),
        ];
        onItemsChange(newItems);
        return newItems;
      });
    },
  );

  const removeItem = useHandler((index: number) => {
    setItems((prev) => {
      const newItems = prev.filter((_, i) => i !== index);
      onItemsChange(newItems);
      return newItems;
    });
  });

  const addOrderItemClick = useHandler(() => {
    setItems((prev) => {
      const newItems = [...prev, DEFAULT_ITEM];
      onItemsChange(newItems);
      return newItems;
    });
  });

  return (
    <Flex dir="column" gap="none">
      <Flex
        align="center"
        borderBottomWidth="1px"
        borderColor="primary"
        borderStyle="solid"
        borderTopWidth="1px"
        dir="row"
        justify="space-between"
        p="3xl"
      >
        <Flex dir="column" gap="xs">
          <Text fontSize="md" fontWeight="semibold">
            Order items
          </Text>
        </Flex>
        <RedoButton
          hierarchy={RedoButtonHierarchy.SECONDARY}
          onClick={addOrderItemClick}
          size={RedoButtonSize.REGULAR}
          text="+ Add an item"
          theme={RedoButtonTheme.NORMAL}
        />
      </Flex>
      <Flex
        align="center"
        borderBottomWidth="1px"
        borderColor="primary"
        borderStyle="solid"
        dir="row"
        justify="space-between"
        w="full"
      >
        <table className={styles.table}>
          <colgroup>
            <col className={styles.sku} />
            <col className={styles.item} />
            <col className={styles.quantity} />
            <col className={styles.price} />
            <col className={styles.actions} />
          </colgroup>
          <thead>
            <tr>
              <th>
                <Text fontSize="xs" textColor="tertiary">
                  SKU
                </Text>
              </th>
              <th>
                <Text fontSize="xs" textColor="tertiary">
                  Item
                </Text>
              </th>
              <th>
                <Text fontSize="xs" textColor="tertiary">
                  Quantity
                </Text>
              </th>
              <th>
                <Text fontSize="xs" textColor="tertiary">
                  Unit Price (USD)
                </Text>
              </th>
              <th />
            </tr>
          </thead>
          <tbody>
            {items.map((item, index) => (
              <tr key={index}>
                <td>
                  <ProductSearchInput
                    index={index}
                    placeholder="Enter a SKU to search"
                    updateItemProperty={updateItemProperty}
                    value={item.sku ?? ""}
                    valueKey="sku"
                  />
                </td>
                <td>
                  <ProductSearchInput
                    index={index}
                    placeholder="Enter an item name to search"
                    updateItemProperty={updateItemProperty}
                    value={item.title}
                    valueKey="title"
                  />
                </td>
                <td>
                  <RedoIncrementDecrement
                    fullWidth
                    min={1}
                    setValue={(value) => {
                      updateItem(index, {
                        ...item,
                        totalQuantity: value,
                        unfulfilledQuantity: value,
                      });
                    }}
                    size={RedoInputSize.SMALL}
                    value={item.totalQuantity}
                  />
                </td>
                <td>
                  <RedoIncrementDecrement
                    fullWidth
                    min={0}
                    setValue={(value) => {
                      updateItem(index, {
                        ...item,
                        unitPrice: {
                          amount: value.toString(),
                          currency: Currency.USD,
                        },
                      });
                    }}
                    size={RedoInputSize.SMALL}
                    value={Number(item.unitPrice.amount)}
                  />
                </td>
                <td>
                  <IconButton
                    onClick={() => removeItem(index)}
                    pt="sm"
                    size={ButtonSize.SMALL}
                  >
                    <XIcon />
                  </IconButton>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </Flex>
    </Flex>
  );
});
