// extracted by mini-css-extract-plugin
export var actionButton = "_77-t";
export var buttonContainer = "_77-0";
export var buttonContainer2 = "_77-1";
export var buttonSection = "_77-2";
export var details = "_77-3";
export var dropdownItem = "_77-5";
export var dropdownItemsContainer = "_77-4";
export var fileInput = "_77-r";
export var headerButton = "_77-6";
export var icon = "_77-7";
export var input = "_77-8";
export var macroListItem = "_77-9";
export var mainContent = "_77-a";
export var microText = "_77-b";
export var modal = "_77-w";
export var optionsButton = "_77-c";
export var paperclip = "_77-s";
export var pillContent = "_77-d";
export var preview = "_77-e";
export var preview2 = "_77-f";
export var quillEditor = "_77-q";
export var selectedMacro = "_77-g";
export var sidebar = "_77-h";
export var sidebarHeader = "_77-j";
export var sidebarList = "_77-i";
export var sidebarListItem = "_77-k";
export var sidebarSearch = "_77-l";
export var snoozeDurationWrapper = "_77-v";
export var statusPillContainer = "_77-m";
export var tagsContainer = "_77-n";
export var unselectedMacro = "_77-o";
export var variable = "_77-u";
export var wrapper = "_77-p";