// extracted by mini-css-extract-plugin
export var defaultWrapper = "_9w-3";
export var downloadButton = "_9w-4";
export var fileContainer = "_9w-7";
export var fileInfo = "_9w-5";
export var fileInfoWidthRestricted = "_9w-6";
export var fileType = "_9w-1";
export var fileTypeName = "_9w-2";
export var image = "_9w-0";
export var link = "_9w-9";
export var overflowEllipsis = "_9w-8";