import type { RedoOutboundLabelsRpcClient } from "@redotech/merchant-sdk/outbound-labels-rpc/client";
import { createRedoOutboundLabelsRpcClient } from "@redotech/merchant-sdk/outbound-labels-rpc/client";
import { createContext, ReactNode, useMemo } from "react";

export const RedoOutboundLabelsRpcClientContext = createContext<
  RedoOutboundLabelsRpcClient | undefined
>(undefined);
RedoOutboundLabelsRpcClientContext.displayName =
  "RedoOutboundLabelsRpcClientContext";

export function RedoOutboundLabelsRpcClientProvider({
  children,
  baseUrl,
  authToken,
}: {
  children: ReactNode | ReactNode[];
  baseUrl: string;
  authToken?: string;
}) {
  const redoOutboundLabelsRpcClient = useMemo(() => {
    if (!authToken) {
      return undefined;
    }
    return createRedoOutboundLabelsRpcClient({
      baseURL: new URL(`${baseUrl}/outbound-labels-rpc`),
      headers: { Authorization: authToken },
    });
  }, [baseUrl, authToken]);
  return (
    <RedoOutboundLabelsRpcClientContext.Provider
      value={redoOutboundLabelsRpcClient}
    >
      {children}
    </RedoOutboundLabelsRpcClientContext.Provider>
  );
}
