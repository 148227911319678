import { FulfillmentOrderData } from "@redotech/redo-model/fulfillments/fulfillment-group";

import { Column2 } from "@redotech/redo-web/table2";

import { FulfillmentGroupStatus } from "@redotech/redo-model/fulfillments/fulfillment-group-status";
import {
  RedoBadge,
  RedoBadgeColor,
  RedoBadgeSize,
} from "@redotech/redo-web/arbiter-components/badge/redo-badge";
import { ColumnAlignment } from "@redotech/redo-web/table";

const statusBadgeText = {
  [FulfillmentGroupStatus.Open]: "Open",
  [FulfillmentGroupStatus.Closed]: "Closed",
  [FulfillmentGroupStatus.Cancelled]: "Cancelled",
  [FulfillmentGroupStatus.Pending]: "Pending",
  [FulfillmentGroupStatus.OnHold]: "On Hold",
  [FulfillmentGroupStatus.Scheduled]: "Scheduled",
  [FulfillmentGroupStatus.Incomplete]: "Incomplete",
  [FulfillmentGroupStatus.Voided]: "Voided",
};
const statusBadgeColor = {
  [FulfillmentGroupStatus.Open]: RedoBadgeColor.SUCCESS,
  [FulfillmentGroupStatus.Closed]: RedoBadgeColor.BLUE,
  [FulfillmentGroupStatus.Cancelled]: RedoBadgeColor.ERROR,
  [FulfillmentGroupStatus.Pending]: RedoBadgeColor.WARNING,
  [FulfillmentGroupStatus.OnHold]: RedoBadgeColor.WARNING,
  [FulfillmentGroupStatus.Scheduled]: RedoBadgeColor.SUCCESS,
  [FulfillmentGroupStatus.Incomplete]: RedoBadgeColor.WARNING,
  [FulfillmentGroupStatus.Voided]: RedoBadgeColor.ERROR,
};
export const StatusColumn: Column2<FulfillmentOrderData> = {
  alignment: ColumnAlignment.LEFT,
  key: "status",
  title: "Status",
  renderNormalCell: ({ row }) => (
    <RedoBadge
      color={statusBadgeColor[row.status || FulfillmentGroupStatus.Open]}
      size={RedoBadgeSize.X_SMALL}
      text={statusBadgeText[row.status || FulfillmentGroupStatus.Open]}
    />
  ),
  width: 120,
};
