export enum FulfillmentGroupStatus {
  Cancelled = "CANCELLED",
  Closed = "CLOSED",
  Incomplete = "INCOMPLETE",
  Open = "OPEN",
  OnHold = "ON_HOLD",
  Scheduled = "SCHEDULED",
  Pending = "PENDING",
  Voided = "VOIDED",
}

export function canPurchaseLabelStatus(
  status: FulfillmentGroupStatus | undefined | null,
): boolean {
  if (!status) {
    return false;
  }
  return [FulfillmentGroupStatus.Open].includes(status);
}
