import { FulfillmentOrderData } from "@redotech/redo-model/fulfillments/fulfillment-group";
import { SortDirection } from "@redotech/redo-model/tables/table";
import { ColumnAlignment } from "@redotech/redo-web/table";
import { Column2 } from "@redotech/redo-web/table2";
import { ItemNameCell } from "../cells/item-name-cell";

export const ItemNameColumn: Column2<FulfillmentOrderData> = {
  alignment: ColumnAlignment.LEFT,
  key: "item-name",
  title: "Item name",
  renderNormalCell: ({ row }: { row: FulfillmentOrderData }) => (
    <ItemNameCell lineItems={row.items ?? []} />
  ),
  width: 160,
  sort: SortDirection.DESC,
  hidden: true,
};
