// extracted by mini-css-extract-plugin
export var container = "_7e-0";
export var content = "_7e-3";
export var customerTitle = "_7e-4";
export var empty = "_7e-5";
export var gray = "_7e-6";
export var header = "_7e-7";
export var info = "_7e-8";
export var loading = "_7e-2";
export var loadingCard = "_7e-9";
export var oneLine = "_7e-a";
export var personName = "_7e-e";
export var pillContainer = "_7e-b";
export var selected = "_7e-1";
export var text = "_7e-c";
export var twoLines = "_7e-d";
export var unread = "_7e-f";
export var unreadIndicator = "_7e-g";