// extracted by mini-css-extract-plugin
export var animationContainer = "_92-0";
export var conversationContainer = "_92-1";
export var detailsContent = "_92-2";
export var message = "_92-3";
export var modalContent = "_92-4";
export var modalFooter = "_92-6";
export var modalHeader = "_92-5";
export var name = "_92-7";
export var optionContainer = "_92-8";
export var optionTitle = "_92-9";
export var pillContainer = "_92-a";
export var selectedConversationsContainer = "_92-b";
export var selectedOptionContainer = "_92-c";