import { Step as ModelStep } from "@redotech/redo-model/return-flow";
import ShieldTickIcon from "@redotech/redo-web/arbiter-icon/shield-tick.svg";
import { BlockLayout } from "@redotech/redo-web/flowchart";
import { memo } from "react";
import { StepType, StepTypeDetailsProps } from "./step";

export interface State {
  customTitle?: string;
}

const Details = memo(function Details({
  state,
  setState,
}: StepTypeDetailsProps<State>) {
  return null;
});

export const SUBMIT_WARRANTY_REGISTRATION: StepType<
  State,
  ModelStep.SubmitWarrantyRegistration
> = {
  Details,
  customTitle: (state) => state.customTitle,
  title: "Submit Warranty Registration",
  valid: () => true,
  Icon: ShieldTickIcon,
  description(state: State) {
    return "Submit warranty registration";
  },
  downstream() {
    return [];
  },
  empty: {},
  layout() {
    return BlockLayout.FULL;
  },
  stepDeleted(state) {
    return state;
  },
  toModel(state: State): ModelStep.SubmitWarrantyRegistration {
    return {
      customTitle: state.customTitle,
      type: ModelStep.SUBMIT_WARRANTY_REGISTRATION,
    };
  },
  fromModel(model: ModelStep.SubmitWarrantyRegistration): State {
    return { customTitle: model.customTitle };
  },
};
