import { z } from "zod";
import { fontWeights } from "./brand-kit";
import {
  ButtonElementSchema,
  ImageElementSchema,
  InputElementSchema,
  RichTextElementSchema,
} from "./content-builder/element-schema";
import { FONT_FAMILIES } from "./email-builder";
import {
  alignmentHorizontalValues,
  alignmentVerticalValues,
  audienceTypes,
  deviceOptions,
  directions,
  discountTypes,
  layoutTypes,
  signupFormSiteTypes,
  targetingTypes,
  teaserVisibility,
} from "./signup-form";

const BorderStrokeStyleDefinitionSchema = z.object({
  color: z.string(),
  weightPx: z.number(),
});

const BorderStyleDefinitionSchema = z.object({
  cornerRadiusPx: z.number(),
  stroke: BorderStrokeStyleDefinitionSchema,
});

const elementStyleSchema = z.object({
  font: z.object({
    fontFamily: z.enum(FONT_FAMILIES),
    fontWeight: z.enum(fontWeights),
    fontSizePx: z.number(),
  }),
  textColor: z.string(),
  backgroundColor: z.optional(z.string()),
  border: z.object({
    cornerRadiusPx: z.number(),
    stroke: BorderStrokeStyleDefinitionSchema,
  }),
  paddingPx: z.number(),
});

const inputStyleSchema = z
  .object({ errorColor: z.string() })
  .merge(elementStyleSchema);

const buttonStyleSchema = z.object({}).merge(elementStyleSchema);

const TeaserSchema = z.object({
  enabled: z.boolean(),
  text: z.string(),
  fontFamily: z.enum(FONT_FAMILIES),
  fontWeight: z.enum(fontWeights),
  fontSizePx: z.number(),
  positionHorizontal: z.enum(alignmentHorizontalValues),
  positionVertical: z.enum(alignmentVerticalValues),
  backgroundColor: z.string(),
  textColor: z.string(),
  cornerRadiusPx: z.number(),
  borderStroke: BorderStrokeStyleDefinitionSchema,
  iconVisible: z.boolean(),
  visibility: z.enum(teaserVisibility),
});

const ElementSchema = z.union([
  RichTextElementSchema,
  ButtonElementSchema,
  InputElementSchema,
  ImageElementSchema,
]);

const FormStepSchema = z.object({ elements: z.array(ElementSchema) });

export const signupFormSchema = z.object({
  name: z.string(),
  theme: z.object({
    overrides: z.object({
      buttons: z.object({
        enabled: z.boolean(),
        primary: buttonStyleSchema,
        secondary: buttonStyleSchema,
        tertiary: buttonStyleSchema,
      }),
      inputs: z.object({ enabled: z.boolean() }).merge(inputStyleSchema),
    }),
    border: BorderStyleDefinitionSchema,
    closeButton: z.object({
      color: z.string(),
      background: z.object({ enabled: z.boolean(), color: z.string() }),
    }),
    overlay: z.object({ color: z.string(), opacityPercent: z.number() }),
  }),
  layout: z.object({
    type: z.enum(layoutTypes),
    backgroundColor: z.string(),
    image: z.object({
      style: z.enum(directions),
      url: z.string(),
      hideOnMobile: z.boolean(),
    }),
  }),
  discount: z.object({
    enabled: z.boolean(),
    type: z.enum(discountTypes),
    amountUsd: z.number(),
    percentage: z.number(),
    excludeOnSale: z.boolean(),
    specifyCollections: z.boolean().optional(),
    collectionIds: z.array(z.string()).optional(),
  }),
  behavior: z.object({
    appearsOn: z.enum(deviceOptions),
    appearsOnSite: z.enum(signupFormSiteTypes),
    subscriberTags: z.array(z.string()),
    display: z.object({
      timeOnPage: z.object({ enabled: z.boolean(), seconds: z.number() }),
      exitIntent: z.object({ enabled: z.boolean() }),
    }),
    audience: z.enum(audienceTypes),
    campaignUtms: z.array(z.string()),
    targeting: z.enum(targetingTypes),
  }),
  teaser: TeaserSchema,
  steps: z.array(FormStepSchema),
});

export const signupFormWithAnalyticsSchema = z.object({
  _id: z.string(),
  team: z.string(),
  form: signupFormSchema,
  analytics: z.object({ views: z.number(), submissions: z.number() }),
  publishedAt: z.date().nullable(),
});
