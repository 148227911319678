import { Currency } from "@redotech/money/currencies";
import { useRequiredContext } from "@redotech/react-util/context";
import { useHandler } from "@redotech/react-util/hook";
import { FulfillmentOrderLineItem } from "@redotech/redo-model/fulfillments/fulfillment-order-line-item";
import { Provider } from "@redotech/redo-model/order";
import { VariantInfo } from "@redotech/redo-model/product";
import { RedoInputSize } from "@redotech/redo-web/arbiter-components/input/base-redo-text-input";
import { RedoTextInput } from "@redotech/redo-web/arbiter-components/input/redo-text-input";
import { RedoListItem } from "@redotech/redo-web/arbiter-components/list/redo-list";
import { RedoListItemSize } from "@redotech/redo-web/arbiter-components/list/redo-list-item";
import { RedoSingleSelectDropdown } from "@redotech/redo-web/arbiter-components/select-dropdown/redo-single-select-dropdown";
import { Text } from "@redotech/redo-web/text";
import { OverflowTooltip } from "@redotech/redo-web/tooltip/overflow-tooltip";
import { memo, useEffect, useState } from "react";
import { useDebounce } from "usehooks-ts";
import { RedoMerchantRpcClientContext } from "../../app/redo-merchant-rpc-client-provider";
import * as styles from "./product-search-input.module.css";

export const ProductSearchInput = memo(function ProductSearchInput({
  index,
  placeholder,
  value,
  updateItemProperty,
  valueKey,
}: {
  index: number;
  placeholder: string;
  value: string;
  valueKey: keyof FulfillmentOrderLineItem;
  updateItemProperty: (
    index: number,
    value: Partial<FulfillmentOrderLineItem>,
  ) => void;
}) {
  const merchantClient = useRequiredContext(RedoMerchantRpcClientContext);

  const [dropdownButtonRef, setDropdownButtonRef] =
    useState<HTMLElement | null>(null);
  const [products, setProducts] = useState<VariantInfo[]>([]);
  const [debounceSymbol, setDebounceSymbol] = useState(Symbol());
  const debouncedSearchProducts = useDebounce(debounceSymbol, 200);
  const [itemsLoading, setItemsLoading] = useState(false);
  const [emptyListMessage, setEmptyListMessage] = useState("Type to search");

  const searchProducts = useHandler(async (searchString: string) => {
    setItemsLoading(true);
    const products = await merchantClient.searchProducts({
      searchString,
      provider: Provider.SHOPIFY,
    });
    setProducts(products.products.flatMap((product) => product.variants));
    setItemsLoading(false);
    setEmptyListMessage(
      searchString.length === 0 ? "Type to search" : "No products found",
    );
  });

  useEffect(() => {
    void searchProducts(value);
  }, [debouncedSearchProducts]);

  return (
    <>
      <RedoTextInput
        onFocus={() => {
          if (value) {
            void searchProducts(value);
          }
        }}
        placeholder={placeholder}
        ref={setDropdownButtonRef}
        setValue={(value) => {
          setDebounceSymbol(Symbol());
          updateItemProperty(index, { [valueKey]: value });
        }}
        size={RedoInputSize.SMALL}
        value={value ?? ""}
      />
      <RedoSingleSelectDropdown
        dropdownButtonRef={dropdownButtonRef}
        emptyListMessage={emptyListMessage}
        fitToAnchor
        itemsLoading={itemsLoading}
        options={products.map((product) => ({
          value: product,
          label: product.name,
        }))}
        optionSelected={(selectedOption: RedoListItem<VariantInfo>) => {
          updateItemProperty(index, {
            externalId: selectedOption.value.id,
            imageUrl: selectedOption.value.image.url,
            variantId: selectedOption.value.id,
            productId: selectedOption.value.productId,
            title: selectedOption.value.name,
            productTitle: selectedOption.value.productName,
            variantTitle: selectedOption.value.name,
            sku: selectedOption.value.sku,
            unitPrice: {
              amount: selectedOption.value.price,
              currency: Currency.USD,
            },
          });
          setProducts([]);
        }}
        size={RedoListItemSize.SMALL}
      >
        {(item: RedoListItem<VariantInfo>) => (
          <ProductSearchInputOption item={item} />
        )}
      </RedoSingleSelectDropdown>
    </>
  );
});

const ProductSearchInputOption = memo(function ProductSearchInputOption({
  item,
}: {
  item: RedoListItem<VariantInfo>;
}) {
  const [overflowRef, setOverflowRef] = useState<HTMLDivElement | null>(null);
  return (
    <OverflowTooltip
      overflowRef={overflowRef}
      tooltipProps={{ title: `${item.value.productName} - ${item.value.name}` }}
    >
      <div className={styles.overflowTooltip}>
        <Text
          overflow="hidden"
          ref={setOverflowRef}
          textOverflow="ellipsis"
          whiteSpace="nowrap"
        >
          {item.value.productName} - {item.value.name}
        </Text>
      </div>
    </OverflowTooltip>
  );
});
