import { PillTheme } from "@redotech/redo-model/pill-theme";
import {
  RedoModal,
  RedoModalSize,
} from "@redotech/redo-web/arbiter-components/modal/redo-modal";
import { memo, useState } from "react";

import { TagInputFields } from "./tag-fields";

// Tag like object, less restrictive on "source" so we can reuse this component
export type TagWithId = {
  name: string;
  pillTheme: PillTheme;
  tagId: string;
  source: string;
};

export const CreateTagModal = memo(function CreateTagModal({
  cancelClicked,
  handleCreateTag,
}: {
  cancelClicked(): void;
  handleCreateTag: ({
    name,
    pillTheme,
  }: {
    name: string;
    pillTheme: PillTheme;
  }) => Promise<void>;
}) {
  const [pillTheme, setPillTheme] = useState<PillTheme>(PillTheme.NORMAL);
  const [name, setName] = useState("");

  async function handleSubmit() {
    await handleCreateTag({ name, pillTheme });
  }

  return (
    <RedoModal
      isOpen
      modalSize={RedoModalSize.SMALL}
      onModalCloseRequested={cancelClicked}
      primaryButton={{ text: "Create", onClick: handleSubmit, disabled: !name }}
      secondaryButton={{ text: "Cancel", onClick: cancelClicked }}
      title="Create tag"
    >
      <TagInputFields
        name={name}
        pillTheme={pillTheme}
        setName={setName}
        setPillTheme={setPillTheme}
        showNameError={false}
      />
    </RedoModal>
  );
});
