import { countries } from "@redotech/locale/countries";
import { useRequiredContext } from "@redotech/react-util/context";
import { useLoad } from "@redotech/react-util/load";
import {
  DiscountCollection,
  DiscountCollectionsSelectionType,
  DiscountMinRequirementType,
  DiscountProduct,
  DiscountProductType,
  DiscountType,
  DiscountValueType,
  ExpirationType,
} from "@redotech/redo-model/discount";
import { FlowType, Step as ModelStep } from "@redotech/redo-model/return-flow";
import { Autocomplete } from "@redotech/redo-web/autocomplete";
import { Checkbox } from "@redotech/redo-web/checkbox";
import { Flex } from "@redotech/redo-web/flex";
import { BlockLayout } from "@redotech/redo-web/flowchart";
import InfoIcon from "@redotech/redo-web/icon-old/info.svg";
import { LabeledInput } from "@redotech/redo-web/labeled-input";
import { RadioGroup, RadioGroupLayout } from "@redotech/redo-web/radio";
import {
  MultiSelectDropdown,
  SelectDropdown,
} from "@redotech/redo-web/select-dropdown";
import { Switch } from "@redotech/redo-web/switch";
import { TextInput } from "@redotech/redo-web/text-input";
import {
  convertShopifyIdToGid,
  ShopifyIdType,
} from "@redotech/util/shopify-id";
import { produce } from "immer";
import { ReactElement, useState } from "react";
import { RedoMerchantClientContext } from "../../client/context";
import { getShopifyCollections, searchProducts } from "../../client/shopify";
import {
  StepDownstream,
  StepId,
  StepType,
  StepTypeDetailsProps,
} from "../return-flow/step";
import * as discountCss from "./discount.module.css";

export interface DiscountState {
  enabled: boolean;
  discountType: DiscountType;
  discountValueType: DiscountValueType;
  discountPercentageValue: number;
  discountAmountValue: number;
  freeShippingCountryCodes: string[];
  discountProductType?: DiscountProductType;
  discountProducts?: DiscountProduct[];
  discountCollections?: DiscountCollection[];
  discountCollectionsSelectionType?: DiscountCollectionsSelectionType;
  minimumRequirement: { type: DiscountMinRequirementType; minimum: number };
  maximumShippingPrice: number;
  combinesWith: {
    orderDiscounts: boolean;
    productDiscounts: boolean;
    shippingDiscount: boolean;
  };
  expirationType: ExpirationType;
  expirationDays?: number;
}

export namespace State {
  export function valid(state: DiscountState, flowType: FlowType) {
    return state.enabled !== undefined || flowType === FlowType.DISCOUNT;
  }
}

export const DISCOUNT: StepType<DiscountState, ModelStep.Discount> = {
  title: "Discount",
  customTitle: (state: DiscountState): string | undefined => {
    return "Discount";
  },
  description: function (state: DiscountState): string {
    return "Discount";
  },
  Details: ({
    state,
    setState,
  }: StepTypeDetailsProps<DiscountState>): ReactElement | null => {
    const redoMerchantClient = useRequiredContext(RedoMerchantClientContext);
    const [collectionsSearchString, setCollectionsSearchString] =
      useState<string>();
    const [collectionsLoading, setCollectionsLoading] = useState(false);
    const collectionsOptionsLoad = useLoad(
      async (signal) => {
        const collections = await getShopifyCollections(redoMerchantClient, {
          searchString: collectionsSearchString,
          signal,
        });
        setCollectionsLoading(false);

        return {
          collections: collections.map((c) => ({
            ...c,
            id: convertShopifyIdToGid(c.id, ShopifyIdType.Collection),
          })),
        };
      },
      [collectionsSearchString, redoMerchantClient],
    );
    const onCollectionsInputChange = (event: any, value: string) => {
      setCollectionsSearchString(value);
      setCollectionsLoading(true);
    };

    const [productsSearchString, setProductsSearchString] = useState<string>();
    const [productsLoading, setProductsLoading] = useState(false);
    const productsOptionsLoad = useLoad(
      async (signal) => {
        const products = await searchProducts(redoMerchantClient, {
          search: productsSearchString || "",
          signal,
        });
        setProductsLoading(false);

        const productObjects: DiscountProduct[] = products.products.map(
          (product: { id: string; title: string }) => {
            return product;
          },
        );

        return { products: productObjects };
      },
      [productsSearchString, redoMerchantClient],
    );
    const onProductsInputChange = (event: any, value: string) => {
      setProductsSearchString(value);
      setProductsLoading(true);
    };

    return (
      <div className={discountCss.customizeColumn}>
        <LabeledInput label="Enabled">
          <Switch
            onChange={(value) => setState({ ...state, enabled: value })}
            value={state.enabled}
          />
        </LabeledInput>
        {state.enabled && (
          <>
            <LabeledInput label="Discount Type">
              <RadioGroup
                layout={RadioGroupLayout.VERTICAL}
                optionLabel={(option) =>
                  ({
                    product: "Product Discount",
                    freeShipping: "Free Shipping",
                    order: "Order Discount",
                  })[option]
                }
                options={[
                  DiscountType.PRODUCT,
                  DiscountType.FREE_SHIPPING,
                  DiscountType.ORDER,
                ]}
                value={state.discountType}
                valueChange={(value) =>
                  setState({ ...state, discountType: value })
                }
              />
            </LabeledInput>

            {state.discountType === DiscountType.ORDER ? (
              <LabeledInput label="Discount Value">
                <Flex>
                  <Flex
                    className={discountCss.discountValueTypeDropdown}
                    grow="1"
                  >
                    <SelectDropdown
                      options={[
                        DiscountValueType.PERCENTAGE,
                        DiscountValueType.AMOUNT,
                      ]}
                      value={state.discountValueType}
                      valueChange={(value) =>
                        setState({
                          ...state,
                          discountValueType:
                            value || DiscountValueType.PERCENTAGE,
                        })
                      }
                    >
                      {(val) => {
                        return val === DiscountValueType.PERCENTAGE
                          ? "Percentage"
                          : "Fixed Amount";
                      }}
                    </SelectDropdown>
                  </Flex>
                  {state.discountValueType === DiscountValueType.PERCENTAGE ? (
                    <TextInput
                      max={100}
                      min={0}
                      onChange={(value) => {
                        setState({
                          ...state,
                          discountPercentageValue: Number(value),
                        });
                      }}
                      suffix="%"
                      type="number"
                      value={String(state.discountPercentageValue)}
                    />
                  ) : (
                    <TextInput
                      min={0}
                      onChange={(value) => {
                        setState({
                          ...state,
                          discountAmountValue: Number(value),
                        });
                      }}
                      prefix="$"
                      type="number"
                      value={String(state.discountAmountValue)}
                    />
                  )}
                </Flex>
              </LabeledInput>
            ) : state.discountType === DiscountType.FREE_SHIPPING ? (
              <LabeledInput label="Countries">
                <MultiSelectDropdown
                  display={(value) => {
                    if (value.length === 0) {
                      return "All";
                    }
                    if (value.length === countries.length) {
                      return "All";
                    }
                    const names = value.map(
                      (code) =>
                        countries.find((country) => country.code === code)
                          ?.name,
                    );
                    return names.join(", ");
                  }}
                  options={countries.map((country: any) => country.code)}
                  value={state.freeShippingCountryCodes}
                  valueChange={(value: string[]) =>
                    setState({ ...state, freeShippingCountryCodes: value })
                  }
                >
                  {(option) =>
                    countries.find((country) => country.code === option)?.name
                  }
                </MultiSelectDropdown>
              </LabeledInput>
            ) : (
              <LabeledInput label="Applies to">
                <RadioGroup
                  layout={RadioGroupLayout.VERTICAL}
                  optionLabel={(option) =>
                    ({ product: "Products", collection: "Collections" })[option]
                  }
                  options={[
                    DiscountProductType.PRODUCT,
                    DiscountProductType.COLLECTION,
                  ]}
                  value={
                    state.discountProductType || DiscountProductType.PRODUCT
                  }
                  valueChange={(value) =>
                    setState({ ...state, discountProductType: value })
                  }
                />
                {state.discountProductType === DiscountProductType.PRODUCT ? (
                  <LabeledInput
                    description="Begin typing to search for products"
                    label="Products"
                  >
                    <Autocomplete
                      filterOptions={(x) => x}
                      getLabel={(product) => product.title || ""}
                      keyFn={(product) => product.title || ""}
                      multiple
                      noOptionsText={
                        productsLoading ? "Loading..." : "No options"
                      }
                      onInputChange={onProductsInputChange}
                      options={productsOptionsLoad.value?.products || []}
                      value={state.discountProducts}
                      valueChange={(value) => {
                        setState({ ...state, discountProducts: value });
                      }}
                    >
                      {(product) => product.title}
                    </Autocomplete>
                  </LabeledInput>
                ) : (
                  <LabeledInput
                    description="The first 100 collections are shown by default. To access more, search by title."
                    label="Collections"
                  >
                    <RadioGroup
                      layout={RadioGroupLayout.HORIZONTAL}
                      optionLabel={(option) =>
                        ({ include: "Include", exclude: "Exclude" })[option]
                      }
                      options={[
                        DiscountCollectionsSelectionType.INCLUDE,
                        DiscountCollectionsSelectionType.EXCLUDE,
                      ]}
                      value={
                        state.discountCollectionsSelectionType ||
                        DiscountCollectionsSelectionType.INCLUDE
                      }
                      valueChange={(value) =>
                        setState({
                          ...state,
                          discountCollectionsSelectionType: value,
                        })
                      }
                    />
                    <Autocomplete
                      filterOptions={(x) => x}
                      getLabel={(collection) => collection.name || ""}
                      keyFn={(collection) => collection.name || ""}
                      multiple
                      noOptionsText={
                        collectionsLoading ? "Loading..." : "No options"
                      }
                      onInputChange={onCollectionsInputChange}
                      options={collectionsOptionsLoad.value?.collections || []}
                      value={state.discountCollections}
                      valueChange={(value) => {
                        setState({ ...state, discountCollections: value });
                      }}
                    >
                      {(collection) => collection.name}
                    </Autocomplete>
                  </LabeledInput>
                )}
                <LabeledInput label="Discount Value">
                  <Flex>
                    <Flex
                      className={discountCss.discountValueTypeDropdown}
                      grow="1"
                    >
                      <SelectDropdown
                        options={[
                          DiscountValueType.PERCENTAGE,
                          DiscountValueType.AMOUNT,
                        ]}
                        value={state.discountValueType}
                        valueChange={(value) =>
                          setState({
                            ...state,
                            discountValueType:
                              value || DiscountValueType.PERCENTAGE,
                          })
                        }
                      >
                        {(val) => {
                          return val === DiscountValueType.PERCENTAGE
                            ? "Percentage"
                            : "Fixed Amount";
                        }}
                      </SelectDropdown>
                    </Flex>
                    {state.discountValueType ===
                    DiscountValueType.PERCENTAGE ? (
                      <TextInput
                        max={100}
                        min={0}
                        onChange={(value) => {
                          setState({
                            ...state,
                            discountPercentageValue: Number(value),
                          });
                        }}
                        suffix="%"
                        type="number"
                        value={String(state.discountPercentageValue)}
                      />
                    ) : (
                      <TextInput
                        min={0}
                        onChange={(value) => {
                          setState({
                            ...state,
                            discountAmountValue: Number(value),
                          });
                        }}
                        prefix="$"
                        type="number"
                        value={String(state.discountAmountValue)}
                      />
                    )}
                  </Flex>
                </LabeledInput>
              </LabeledInput>
            )}
            <LabeledInput label="Minimum Requirement">
              <RadioGroup
                layout={RadioGroupLayout.VERTICAL}
                optionLabel={(option) =>
                  ({
                    minimumSubtotal: "Minimum purchase amount ($)",
                    minimumQuantity: "Minimum quantity of items",
                  })[option]
                }
                options={[
                  DiscountMinRequirementType.MINIMUM_SUBTOTAL,
                  DiscountMinRequirementType.MINIMUM_QUANTITY,
                ]}
                optionSubcontent={(option) =>
                  ({
                    minimumSubtotal: (
                      <LabeledInput
                        description="Applies to all products"
                        label=""
                      >
                        <TextInput
                          min={0}
                          onChange={(value) => {
                            setState({
                              ...state,
                              minimumRequirement: {
                                type: DiscountMinRequirementType.MINIMUM_SUBTOTAL,
                                minimum: Number(value),
                              },
                            });
                          }}
                          prefix="$"
                          type="number"
                          value={String(state.minimumRequirement.minimum)}
                        />
                      </LabeledInput>
                    ),
                    minimumQuantity: (
                      <LabeledInput
                        description="Applies to all products"
                        label=""
                      >
                        <TextInput
                          min={1}
                          onChange={(value) => {
                            setState({
                              ...state,
                              minimumRequirement: {
                                type: DiscountMinRequirementType.MINIMUM_QUANTITY,
                                minimum: Number(value),
                              },
                            });
                          }}
                          type="number"
                          value={String(state.minimumRequirement.minimum)}
                        />
                      </LabeledInput>
                    ),
                  })[option]
                }
                value={state.minimumRequirement.type}
                valueChange={(value) =>
                  setState({
                    ...state,
                    minimumRequirement: {
                      type: value,
                      minimum: state.minimumRequirement.minimum,
                    },
                  })
                }
              />
            </LabeledInput>

            {state.discountType === DiscountType.FREE_SHIPPING && (
              <LabeledInput label="Maximum Shipping Price">
                <TextInput
                  min={0}
                  onChange={(value) =>
                    setState({ ...state, maximumShippingPrice: Number(value) })
                  }
                  prefix="$"
                  type="number"
                  value={String(state.maximumShippingPrice)}
                />
              </LabeledInput>
            )}
            <LabeledInput label="Combinations">
              <div className={discountCss.labelSmall}>
                This order discount can be combined with:
              </div>
              <Checkbox
                onChange={(value) =>
                  setState({
                    ...state,
                    combinesWith: {
                      ...state.combinesWith,
                      productDiscounts: value,
                    },
                  })
                }
                value={state.combinesWith?.productDiscounts || false}
              >
                Product discounts
              </Checkbox>
              <Checkbox
                onChange={(value) =>
                  setState({
                    ...state,
                    combinesWith: {
                      ...state.combinesWith,
                      orderDiscounts: value,
                    },
                  })
                }
                value={state.combinesWith?.orderDiscounts || false}
              >
                Order discounts
              </Checkbox>
              {state.discountType !== DiscountType.FREE_SHIPPING && (
                <Checkbox
                  onChange={(value) =>
                    setState({
                      ...state,
                      combinesWith: {
                        ...state.combinesWith,
                        shippingDiscount: value,
                      },
                    })
                  }
                  value={state.combinesWith?.shippingDiscount || false}
                >
                  Shipping discounts
                </Checkbox>
              )}
            </LabeledInput>

            <LabeledInput label="Expiration Type">
              <RadioGroup
                layout={RadioGroupLayout.VERTICAL}
                optionLabel={(option) =>
                  ({ delivery: "Delivery", expiration: "Days" })[option]
                }
                options={[
                  ExpirationType.DELIVERY,
                  ExpirationType.EXPIRATION_DAYS,
                ]}
                value={state.expirationType}
                valueChange={(value) =>
                  setState({ ...state, expirationType: value })
                }
              />
            </LabeledInput>

            {state.expirationType === ExpirationType.EXPIRATION_DAYS && (
              <LabeledInput label="Expiration Days">
                <TextInput
                  min={1}
                  onChange={(value) =>
                    setState({ ...state, expirationDays: Number(value) })
                  }
                  type="number"
                  value={String(state.expirationDays)}
                />
              </LabeledInput>
            )}
          </>
        )}
      </div>
    );
  },
  downstream: function (state: DiscountState): StepDownstream[] {
    const result: StepDownstream[] = [];
    return result;
  },
  fromModel(model) {
    return {
      type: ModelStep.DISCOUNT,
      enabled: model.enabled,
      discountType: model.discountType,
      discountValueType: model.discountValueType,
      discountPercentageValue: model.discountPercentageValue * 100,
      discountAmountValue: model.discountAmountValue,
      freeShippingCountryCodes: model.freeShippingCountryCodes,
      discountProductType:
        model.discountProductType || DiscountProductType.PRODUCT,
      discountProducts: model.discountProducts,
      discountCollections: model.discountCollections,
      discountCollectionsSelectionType: model.discountCollectionsSelectionType,
      minimumRequirement: model.minimumRequirement,
      maximumShippingPrice: model.maximumShippingPrice,
      combinesWith: model.combinesWith,
      expirationType: model.expirationType,
      expirationDays: model.expirationDays,
    };
  },
  Icon: InfoIcon,
  layout: function (state: DiscountState): BlockLayout {
    return BlockLayout.FULL;
  },
  stepDeleted: function (state: DiscountState, step: StepId): DiscountState {
    return produce(state, (state) => {});
  },
  toModel: function (state): ModelStep.Discount {
    return {
      type: ModelStep.DISCOUNT,
      enabled: state.enabled,
      discountType: state.discountType,
      discountValueType: state.discountValueType,
      discountPercentageValue: state.discountPercentageValue * 0.01,
      discountAmountValue: state.discountAmountValue,
      freeShippingCountryCodes: state.freeShippingCountryCodes,
      discountProductType: state.discountProductType,
      discountProducts: state.discountProducts,
      discountCollections: state.discountCollections,
      discountCollectionsSelectionType: state.discountCollectionsSelectionType,
      minimumRequirement: state.minimumRequirement,
      maximumShippingPrice: state.maximumShippingPrice,
      combinesWith: state.combinesWith,
      expirationType: state.expirationType,
      expirationDays: state.expirationDays,
    };
  },
  valid: State.valid,
  empty: {
    enabled: false,
    discountType: DiscountType.ORDER,
    discountValueType: DiscountValueType.PERCENTAGE,
    discountPercentageValue: 0,
    discountAmountValue: 0,
    freeShippingCountryCodes: [],
    discountProductType: DiscountProductType.PRODUCT,
    discountProducts: [],
    discountCollections: [],
    discountCollectionsSelectionType: DiscountCollectionsSelectionType.INCLUDE,
    minimumRequirement: {
      type: DiscountMinRequirementType.MINIMUM_SUBTOTAL,
      minimum: 0,
    },
    maximumShippingPrice: 0,
    expirationType: ExpirationType.DELIVERY,
    expirationDays: undefined,
    combinesWith: {
      orderDiscounts: false,
      productDiscounts: false,
      shippingDiscount: false,
    },
  },
};
