import { Drawer } from "@mui/material";
import { MarketingChannel } from "@redotech/redo-model/marketing";
import {
  RedoButton,
  RedoButtonHierarchy,
  RedoButtonSize,
  RedoButtonTheme,
} from "@redotech/redo-web/arbiter-components/buttons/redo-button";
import { RedoCheckboxCard } from "@redotech/redo-web/arbiter-components/checkbox/redo-checkbox-card";
import { RedoDatePicker } from "@redotech/redo-web/arbiter-components/date-picker/redo-date-picker";
import {
  RedoButtonCloseX,
  ButtonSize as XButtonSize,
} from "@redotech/redo-web/arbiter-components/redo-button-close-x";
import {
  RedoDropdownInputSize,
  RedoSingleSelectDropdownInput,
} from "@redotech/redo-web/arbiter-components/select-dropdown/redo-single-select-dropdown-input";
import CalendarPlusIcon from "@redotech/redo-web/arbiter-icon/calendar-plus-02.svg";
import ClockIcon from "@redotech/redo-web/arbiter-icon/clock.svg";
import SendIcon from "@redotech/redo-web/arbiter-icon/send-01.svg";
import { Divider } from "@redotech/redo-web/divider";
import { Flex } from "@redotech/redo-web/flex";
import { LabeledInput, LabelSize } from "@redotech/redo-web/labeled-input";
import { Text } from "@redotech/redo-web/text";
import { memo, useState } from "react";
import { EmailSummaryCard } from "../email-summary-card";
import { SmsSummaryCard } from "../sms-summary-card";
import {
  CampaignWizardPage,
  CampaignWizardPageProps,
  CampaignWizardStep,
} from "./base";
import * as previewCss from "./preview.module.css";

export const PreviewCampaign = memo(function PreviewCampaign() {
  const isAlreadyScheduled = (props: CampaignWizardPageProps) =>
    !!props.unmodifiedCampaign.scheduledAt;

  const [isDrawerOpen, setIsDrawerOpen] = useState(false);

  return (
    <CampaignWizardPage
      actionButton={(props) => ({
        text: isAlreadyScheduled(props)
          ? "Already scheduled to send"
          : "Schedule or send campaign",
        onClick: () => {
          setIsDrawerOpen(true);
        },
        hierarchy: RedoButtonHierarchy.PRIMARY,
        disabled: props.pending || !!props.unmodifiedCampaign.scheduledAt,
        theme: RedoButtonTheme.SUCCESS,
      })}
    >
      {(props) => (
        <>
          <Flex mx="auto" my="3xl">
            {props.campaign?.channel === MarketingChannel.EMAIL ? (
              <EmailSummaryCard campaign={props.campaign} />
            ) : props.campaign?.channel === MarketingChannel.SMS ? (
              <SmsSummaryCard campaign={props.campaign} />
            ) : null}
          </Flex>

          <ScheduleCampaignDrawer
            channel={props.campaign?.channel}
            isOpen={isDrawerOpen}
            scheduleCampaignAndAdvance={async (date) => {
              await props.saveAndContinue(CampaignWizardStep.PREVIEW, {
                ...props.campaign,
                scheduledAt: date,
              });
            }}
            setIsOpen={setIsDrawerOpen}
          />
        </>
      )}
    </CampaignWizardPage>
  );
});

const ScheduleCampaignDrawer = memo(function ScheduleCampaignDrawer({
  isOpen,
  setIsOpen,
  scheduleCampaignAndAdvance,
  channel,
}: {
  isOpen: boolean;
  setIsOpen: (isOpen: boolean) => void;
  scheduleCampaignAndAdvance: (date: Date) => void;
  channel: MarketingChannel;
}) {
  const [isScheduled, setIsScheduled] = useState(false);
  const [sendDate, setSendDate] = useState<Date>(new Date());
  const [sendDateWithTime, setSendDateWithTime] = useState<Date | undefined>(
    undefined,
  );

  const timeOptions = getTimeOptions(sendDate, channel);

  const canSubmit =
    (isScheduled && sendDate && sendDateWithTime) || !isScheduled;

  const handleSubmit = async () => {
    let scheduledAt: Date | undefined;
    if (isScheduled) {
      scheduledAt = sendDateWithTime;
    } else {
      scheduledAt = new Date();
    }
    await scheduleCampaignAndAdvance(scheduledAt!);
  };

  return (
    <Drawer
      anchor="right"
      onClose={() => setIsOpen(false)}
      open={isOpen}
      PaperProps={{ className: previewCss.drawer }}
    >
      <Flex
        dir="column"
        height="full"
        justify="space-between"
        textColor="primary"
      >
        <Flex className={previewCss.inputs} dir="column" gap="lg">
          <Flex justify="space-between">
            <Text fontSize="xl" fontWeight="bold">
              Schedule or send campaign
            </Text>
            <RedoButtonCloseX
              onclick={() => setIsOpen(false)}
              onDarkBackground={false}
              size={XButtonSize.SMALL}
            />
          </Flex>

          <LabeledInput label="Channel" size={LabelSize.SMALL}>
            <RedoCheckboxCard
              checked={!isScheduled}
              icon={<SendIcon />}
              inputType="radio"
              onClick={() => setIsScheduled(false)}
              title="Send now"
            >
              Start sending this campaign immediately.
            </RedoCheckboxCard>
            <RedoCheckboxCard
              checked={isScheduled}
              icon={<CalendarPlusIcon />}
              inputType="radio"
              onClick={() => setIsScheduled(true)}
              title="Schedule campaign"
            >
              Choose a future date and time to send this campaign.
            </RedoCheckboxCard>
          </LabeledInput>

          {isScheduled && (
            <>
              <LabeledInput label="Date" size={LabelSize.SMALL}>
                <RedoDatePicker
                  buttonSize={RedoButtonSize.SMALL}
                  date={sendDate}
                  minDate={new Date()}
                  setDate={setSendDate}
                />
              </LabeledInput>

              <LabeledInput label="Time" size={LabelSize.SMALL}>
                <RedoSingleSelectDropdownInput
                  icon={ClockIcon}
                  options={timeOptions}
                  optionSelected={(time) => setSendDateWithTime(time.value)}
                  placeholder="Select time"
                  selectedItem={timeOptions.find(
                    (item) =>
                      item.value.getTime() === sendDateWithTime?.getTime(),
                  )}
                  size={RedoDropdownInputSize.SMALL}
                >
                  {(time) =>
                    time.value.toLocaleTimeString([], {
                      hour: "numeric",
                      minute: "2-digit",
                    })
                  }
                </RedoSingleSelectDropdownInput>
              </LabeledInput>
            </>
          )}
        </Flex>

        <Flex dir="column">
          <Divider />
          <Flex className={previewCss.buttons} justify="flex-end">
            <RedoButton
              hierarchy={RedoButtonHierarchy.SECONDARY}
              onClick={() => setIsOpen(false)}
              size={RedoButtonSize.SMALL}
              text="Cancel"
            />
            <RedoButton
              disabled={!canSubmit}
              hierarchy={RedoButtonHierarchy.PRIMARY}
              onClick={handleSubmit}
              size={RedoButtonSize.SMALL}
              text={isScheduled ? "Schedule campaign" : "Send campaign"}
            />
          </Flex>
        </Flex>
      </Flex>
    </Drawer>
  );
});

function getTimeOptions(sendDate: Date, channel: MarketingChannel) {
  const options: { value: Date }[] = [];
  const now = new Date();
  const isToday = sendDate.toDateString() === now.toDateString();

  // Set start/end hours based on channel
  let startHour = channel === MarketingChannel.SMS ? 9 : 0;
  const endHour = channel === MarketingChannel.SMS ? 20 : 24;

  // If today, start from current time
  if (isToday) {
    startHour = now.getHours();
    const currentMinutes = now.getMinutes();
    const startMinutes = Math.ceil(currentMinutes / 15) * 15;

    // Add first option if within same hour
    if (startMinutes < 60) {
      const date = new Date(sendDate);
      date.setHours(startHour, startMinutes, 0, 0);
      options.push({ value: date });
    }

    // Adjust start hour and skip to next 15-min interval
    startHour = startMinutes >= 60 ? startHour + 1 : startHour;
    const firstMin = startMinutes >= 60 ? 0 : startMinutes + 15;

    // Generate remaining times for current hour
    for (let min = firstMin; min < 60; min += 15) {
      const date = new Date(sendDate);
      date.setHours(startHour, min, 0, 0);
      options.push({ value: date });
    }
    startHour++;
  }

  // Generate remaining times in 15 min intervals for subsequent hours
  for (let hour = startHour; hour < endHour; hour++) {
    for (let min = 0; min < 60; min += 15) {
      const date = new Date(sendDate);
      date.setHours(hour, min, 0, 0);
      options.push({ value: date });
    }
  }

  return options;
}
