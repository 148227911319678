// extracted by mini-css-extract-plugin
export var colorSquare = "_bt-0";
export var danger = "_bt-5";
export var lightPink = "_bt-8";
export var neutralBlue = "_bt-7";
export var neutralYellow = "_bt-6";
export var normal = "_bt-1";
export var primary = "_bt-2";
export var primaryLight = "_bt-3";
export var solidBlack = "_bt-g";
export var solidGreen = "_bt-b";
export var solidLightBlue = "_bt-d";
export var solidNavy = "_bt-c";
export var solidOrange = "_bt-f";
export var solidPink = "_bt-e";
export var solidRed = "_bt-9";
export var solidYellow = "_bt-a";
export var success = "_bt-4";