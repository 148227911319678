import { z } from "zod";

export const manuallyConvertChatToEmail = {
  input: z.object({
    conversationId: z.string(),
    customerEmail: z
      .string()
      .min(1, { message: "Required field" })
      .email("Invalid email"),
    emailToSendAs: z.object({
      email: z
        .string()
        .min(1, { message: "Required field" })
        .email("Invalid email"),
      name: z.string(),
      integrationKind: z.enum(["custom", "gmail", "outlook"]),
    }),
  }),
  output: z.object({}),
};
