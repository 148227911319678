// extracted by mini-css-extract-plugin
export var colorSquare = "_as-0";
export var container = "_as-h";
export var danger = "_as-5";
export var lightPink = "_as-8";
export var neutralBlue = "_as-7";
export var neutralYellow = "_as-6";
export var normal = "_as-1";
export var primary = "_as-2";
export var primaryLight = "_as-3";
export var selected = "_as-i";
export var solidBlack = "_as-g";
export var solidGreen = "_as-b";
export var solidLightBlue = "_as-d";
export var solidNavy = "_as-c";
export var solidOrange = "_as-f";
export var solidPink = "_as-e";
export var solidRed = "_as-9";
export var solidYellow = "_as-a";
export var success = "_as-4";