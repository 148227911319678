import { z } from "zod";

export const removeVariantExchangeItem = {
  input: z.object({
    teamId: z.string(),
    returnId: z.string(),
    variantId: z.string(),
  }),
  output: z.object({ success: z.boolean() }),
};
