// extracted by mini-css-extract-plugin
export var alpha = "_6r-4";
export var badge = "_6r-b";
export var controls = "_6r-5";
export var hex = "_6r-6";
export var hue = "_6r-3";
export var hueAndAlphaPointer = "_6r-a";
export var picker = "_6r-0";
export var rgba = "_6r-8";
export var rgbaSection = "_6r-7";
export var saturation = "_6r-1";
export var saturationPointer = "_6r-9";
export var sliders = "_6r-2";