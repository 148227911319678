import * as amplitude from "@amplitude/analytics-browser";
import { ClickAwayListener } from "@mui/material";
import { useHandler } from "@redotech/react-util/hook";
import { isControlKeyPressed } from "@redotech/react-util/html-events";
import { getSelectionDelta } from "@redotech/react-util/table-select";
import {
  ConversationPlatform,
  ConversationStatus,
  ExpandedConversation,
  getConversationStatus,
  textForDeletedFacebookComment,
} from "@redotech/redo-model/conversation";
import { ConversationFiltersV3 } from "@redotech/redo-model/conversation-filters/conversation-filters";
import { getCustomerDisplayName } from "@redotech/redo-model/customer";
import { SortableConversationTableColumn } from "@redotech/redo-model/tables/conversations-table-sort";
import { SortDirection, TableSort } from "@redotech/redo-model/tables/table";
import { View } from "@redotech/redo-model/view";
import {
  RedoBadge,
  RedoBadgeColor,
  RedoBadgeSize,
} from "@redotech/redo-web/arbiter-components/badge/redo-badge";
import {
  RedoButton,
  RedoButtonHierarchy,
} from "@redotech/redo-web/arbiter-components/buttons/redo-button";
import { RedoCommandMenu } from "@redotech/redo-web/arbiter-components/command-menu/redo-command-menu";
import {
  RedoTableColumnWidthType,
  RedoTableSize,
} from "@redotech/redo-web/arbiter-components/tables/redo-table";
import { RedoTableTextCell } from "@redotech/redo-web/arbiter-components/tables/redo-table-cells";
import AlertIcon from "@redotech/redo-web/arbiter-icon/alert-triangle.svg";
import { CardClickHandler } from "@redotech/redo-web/card-list";
import { Checkbox } from "@redotech/redo-web/checkbox";
import { formatTimeAgo } from "@redotech/redo-web/date-utils";
import { Dropdown, DropdownOption } from "@redotech/redo-web/dropdown";
import { Flex } from "@redotech/redo-web/flex";
import ThreeDotsHorizontalIcon from "@redotech/redo-web/icon-old/three-dots-horizontal.svg";
import { RedoSupportChannelBadge } from "@redotech/redo-web/support/redo-support-channel-badge";
import { RedoSupportStatusBadge } from "@redotech/redo-web/support/redo-support-status-badge";
import {
  ColumnAlignment,
  RowClickHandler,
  TableRef,
} from "@redotech/redo-web/table";
import { Column2, Table2 } from "@redotech/redo-web/table2";
import { Text } from "@redotech/redo-web/text";
import { assertNever } from "@redotech/util/type";
import * as classNames from "classnames";
import { memo, useContext, useMemo, useState } from "react";
import { ActiveUsers } from "../active-users";
import { ConversationFetcher } from "../conversation-fetcher";
import { ConversationTagPill } from "../conversation-tags/conversation-tag-pill";
import { getFirstDraftFromConversation } from "../message-input-utils";
import { ACTIVE_CONVERSATION_QUERY_PARAMETER } from "../query-parameters";
import { removeSimpleHTMLTags } from "../utils";
import {
  ConversationsTableSelection,
  ConversationsTableSelectionContext,
} from "./conversations-table-selection";
import * as conversationsTableCss from "./conversations-table.module.css";

export interface ConversationTableDropdownAction {
  show: boolean;
  commandMenuItem: {
    Icon: (props?: any) => React.ReactElement;
    text: string;
    onClick: (e: React.MouseEvent<HTMLElement>) => void;
  };
}

export type ConversationTableDropdownActions = (
  conversation: ExpandedConversation,
  setDropdownOpen: (open: boolean) => void,
) => ConversationTableDropdownAction[];

export const ConversationsTable = memo(function ConversationsTable({
  view,
  handleCardClick,
  fetcher,
  filters,
  tableRef,
  dropdownActions,
  onConversationsViewed,
  scrollAreaRef,
  showCustomerColumn = true,
  showMenuColumn = true,
  externalSetSort,
  externalSort,
}: {
  view?: View | undefined; // details for custom views
  handleCardClick?:
    | ((conversation: ExpandedConversation | undefined) => void)
    | undefined;
  fetcher: ConversationFetcher;
  filters: ConversationFiltersV3;
  tableRef: React.MutableRefObject<TableRef<ExpandedConversation>>;
  dropdownActions?: ConversationTableDropdownActions | undefined;
  onConversationsViewed: (
    conversation: ExpandedConversation,
  ) => Promise<ExpandedConversation | undefined>;
  scrollAreaRef: React.MutableRefObject<any>;
  showCustomerColumn?: boolean | undefined;
  showMenuColumn?: boolean | undefined;
  externalSetSort?: (sort: TableSort<any>) => void;
  externalSort?: TableSort<any>;
}) {
  const sortDefault: TableSort<SortableConversationTableColumn> = view?.filters
    .sort || { direction: SortDirection.DESC, key: "lastResponse" };

  const conversationsTableSelection = useContext(
    ConversationsTableSelectionContext,
  );

  const onSelectAllCheckboxChange = (checked: boolean) => {
    if (!conversationsTableSelection) {
      return;
    }
    setSelectionSliceStart(undefined);
    setSelectionSliceEnd(undefined);
    if (checked) {
      amplitude.logEvent("selectAll-conversation", { view });
      conversationsTableSelection.selectAll();
    } else {
      amplitude.logEvent("deselectAll-conversation", { view });
      if (conversationsTableSelection.deselectedIds.length === 0) {
        conversationsTableSelection.clearSelection();
      }
    }
  };

  const [selectionSliceStart, setSelectionSliceStart] = useState<
    number | undefined
  >();

  const [selectionSliceEnd, setSelectionSliceEnd] = useState<
    number | undefined
  >();

  const [rightClickMenuVisible, setRightClickMenuVisible] = useState(false);
  const [rightClickMenuAnchor, setRightClickMenuAnchor] =
    useState<HTMLElement | null>(null);
  const [conversationToNavigateTo, setConversationToNavigateTo] =
    useState<ExpandedConversation | null>(null);

  const onContextMenu = useHandler<RowClickHandler<ExpandedConversation>>(
    (conversation, event) => {
      event.preventDefault();
      setRightClickMenuVisible(true);
      setRightClickMenuAnchor(event.target as HTMLElement);
      setConversationToNavigateTo(conversation);
    },
  );

  const navigateToConversation = () => {
    if (conversationToNavigateTo?._id) {
      const searchParams = new URLSearchParams(location.search);
      searchParams.set(
        ACTIVE_CONVERSATION_QUERY_PARAMETER,
        conversationToNavigateTo._id,
      );
      setRightClickMenuVisible(false);
      setConversationToNavigateTo(null);
      setRightClickMenuAnchor(null);
      window.open(`${location.pathname}?${searchParams}`, "_blank");
    }
  };

  const onRowClick = useHandler<CardClickHandler<ExpandedConversation>>(
    async (record, mouseEvent, idx) => {
      if (!conversationsTableSelection) {
        const newRecord = await onConversationsViewed(record);
        handleCardClick?.(newRecord);
        return;
      }

      if (isControlKeyPressed(mouseEvent) || mouseEvent.shiftKey) {
        mouseEvent.stopPropagation();
      }

      if (!isControlKeyPressed(mouseEvent) && !mouseEvent.shiftKey) {
        const newRecord = await onConversationsViewed(record);
        handleCardClick?.(newRecord);
        return;
      }

      const { itemsToAdd, itemsToRemove } = getSelectionDelta({
        mouseEvent: mouseEvent,
        idx,
        items: tableRef.current.items,
        selectedItems: conversationsTableSelection.selectedRecords,
        deselectedItems: conversationsTableSelection.deselectedRecords,
        itemsEqual: (item1, item2) => item1._id === item2._id,
        multiSelectAnchorStart: selectionSliceStart,
        multiSelectAnchorEnd: selectionSliceEnd,
        setMultiSelectAnchorStart: setSelectionSliceStart,
        setMultiSelectAnchorEnd: setSelectionSliceEnd,
        selectAllMode: conversationsTableSelection.selectAllMode,
      });

      conversationsTableSelection.addToSelection(
        Array.from(itemsToAdd.map((item) => item._id)),
      );
      conversationsTableSelection.removeFromSelection(
        Array.from(itemsToRemove.map((item) => item._id)),
      );
    },
  );

  // conversation ID
  const [currentConversationHovered, setCurrentConversationHovered] = useState<
    string | undefined
  >();

  const onRowHovered = (conversation?: ExpandedConversation) => {
    setCurrentConversationHovered(conversation?._id);
  };

  const isIndeterminate = () => {
    if (!conversationsTableSelection) {
      return false;
    }

    const selectedIdsCount = conversationsTableSelection.selectedIds.length;

    const isIndeterminateOutsideOfSelectAllMode =
      !conversationsTableSelection.selectAllMode &&
      selectedIdsCount > 0 &&
      selectedIdsCount < conversationsTableSelection.totalConversations;

    const isIndeterminateInSelectAllMode =
      conversationsTableSelection.selectAllMode &&
      conversationsTableSelection.deselectedIds.length > 0;

    return (
      isIndeterminateOutsideOfSelectAllMode || isIndeterminateInSelectAllMode
    );
  };

  const selectionColumn: Column2<ExpandedConversation> | undefined =
    conversationsTableSelection
      ? {
          alignment: ColumnAlignment.LEFT,
          key: "select",
          title:
            conversationsTableSelection.totalConversations !== 0 ? (
              <div className={conversationsTableCss.headerCheckboxContainer}>
                <Checkbox
                  clickStopPropagation
                  indeterminate={isIndeterminate()}
                  onChange={onSelectAllCheckboxChange}
                  value={
                    (conversationsTableSelection.selectAllMode &&
                      conversationsTableSelection.deselectedIds.length === 0) ||
                    conversationsTableSelection.selectedIds?.length ===
                      conversationsTableSelection.totalConversations
                  }
                />
              </div>
            ) : (
              ""
            ),
          renderNormalCell: ({ row: conversation, rowIndex }) => {
            return (
              <div
                onClick={(event) => {
                  event.stopPropagation();
                  const checkbox = document.getElementById(
                    `${conversation._id}-checkbox`,
                  );
                  checkbox?.click();
                }}
              >
                <div className={conversationsTableCss.cellContainer}>
                  <CheckboxColumn
                    conversation={conversation}
                    conversationsTableSelection={conversationsTableSelection}
                    currentConversationHovered={currentConversationHovered}
                    idx={rowIndex}
                    setSelectionSliceEnd={setSelectionSliceEnd}
                    setSelectionSliceStart={setSelectionSliceStart}
                  />
                </div>
              </div>
            );
          },
          widthMode: { type: RedoTableColumnWidthType.FIXED, width: "40px" },
          width: 40,
        }
      : {
          alignment: ColumnAlignment.LEFT,
          key: "viewed",
          title: "",
          renderNormalCell: ({ row: conversation }) => {
            return conversation.read ? null : (
              <div className={conversationsTableCss.unread} />
            );
          },
          width: 40,
        };
  const customerColumn: Column2<ExpandedConversation> | undefined =
    showCustomerColumn
      ? {
          alignment: ColumnAlignment.LEFT,
          key: "customer",
          title: "Customer",
          renderNormalCell: ({ row: conversation }) => {
            return (
              <RedoTableTextCell
                text={getCustomerDisplayName(conversation.customer)}
              />
            );
          },
          width: 140,
        }
      : undefined;

  const menuColumn: Column2<ExpandedConversation> | undefined = showMenuColumn
    ? {
        alignment: ColumnAlignment.LEFT,
        key: "menu",
        title: "",
        renderNormalCell: ({ row: conversation }) => {
          return (
            <MenuColumn
              conversation={conversation}
              dropdownActions={dropdownActions}
            />
          );
        },
        width: 60,
      }
    : undefined;

  const fullColumns: Column2<ExpandedConversation>[] = useMemo(() => {
    return [
      ...(selectionColumn ? [selectionColumn] : []),
      ...(customerColumn ? [customerColumn] : []),
      {
        alignment: ColumnAlignment.LEFT,
        key: "active-users",
        title: "",
        renderNormalCell: ({ row: conversation }) => {
          return <ActiveUsers conversation={conversation} maxAvatars={2} />;
        },
        width: 75,
      },
      {
        alignment: ColumnAlignment.LEFT,
        key: "summary",
        title: "Summary",
        renderNormalCell: ({ row: conversation }) => {
          let latestMessageContent = "No content";
          if (conversation.messages?.length > 0) {
            const lastMessage = conversation.messages.at(-1)!;
            latestMessageContent = lastMessage.content;
            if (lastMessage.facebook?.comment?.deleted) {
              latestMessageContent = textForDeletedFacebookComment;
            }
          }
          const contentLines =
            removeSimpleHTMLTags(latestMessageContent).split("\n");

          let latestDraftContent = "No content";
          const latestDraft = getFirstDraftFromConversation(conversation);
          if (latestDraft) {
            latestDraftContent = latestDraft.content;
          }
          const latestDraftLines =
            removeSimpleHTMLTags(latestDraftContent).split("\n");

          const mostRecentSentMessage = conversation.messages
            .slice()
            .reverse()
            .find(
              (m) => m.type !== "customer" && m.sentAt && !m.draftInfo?.isDraft,
            );

          const showMessageFailedToSendBadge = mostRecentSentMessage?.error;

          const status = getConversationStatus(conversation);

          return (
            <div className={conversationsTableCss.summaryCell}>
              {conversation.platform === ConversationPlatform.EMAIL && (
                <Flex gap="sm">
                  <Text fontSize="xs" fontWeight="bold" textColor="primary">
                    Subject:
                  </Text>
                  <Text
                    fontSize="xs"
                    fontWeight="regular"
                    overflow="hidden"
                    textColor="primary"
                    textOverflow="ellipsis"
                    whiteSpace="nowrap"
                  >
                    {conversation.subject}
                  </Text>
                </Flex>
              )}
              <div
                className={classNames(conversationsTableCss.gray, {
                  [conversationsTableCss.oneLine]:
                    conversation.platform === ConversationPlatform.EMAIL,
                  [conversationsTableCss.twoLines]:
                    conversation.platform !== ConversationPlatform.EMAIL,
                })}
              >
                <Flex align="center" gap="none">
                  {showMessageFailedToSendBadge && (
                    <span>
                      <div
                        className={
                          conversationsTableCss.draftReplyBadgeContainer
                        }
                      >
                        <RedoBadge
                          color={RedoBadgeColor.ERROR}
                          size={RedoBadgeSize.X_SMALL}
                          text="Message failed to send"
                        />
                      </div>
                    </span>
                  )}
                  {status === ConversationStatus.SNOOZED &&
                    conversation.snoozedUntil && (
                      <span>
                        <div
                          className={
                            conversationsTableCss.draftReplyBadgeContainer
                          }
                        >
                          <RedoSupportStatusBadge
                            size={RedoBadgeSize.X_SMALL}
                            snoozedUntil={conversation.snoozedUntil}
                            status={status}
                          />
                        </div>
                      </span>
                    )}
                  {latestDraft && (
                    <span>
                      <div
                        className={
                          conversationsTableCss.draftReplyBadgeContainer
                        }
                      >
                        <RedoBadge
                          color={RedoBadgeColor.GRAY}
                          size={RedoBadgeSize.X_SMALL}
                          text="Draft"
                        />
                      </div>
                    </span>
                  )}
                  {latestDraft ? (
                    <Text
                      fontSize="xs"
                      fontStyle="italic"
                      fontWeight="regular"
                      overflow="hidden"
                      style={{ marginTop: "3px" }}
                      textColor="primary"
                      textOverflow="ellipsis"
                      whiteSpace="nowrap"
                    >
                      {[...latestDraftLines].join(" ")}
                    </Text>
                  ) : (
                    <Text
                      fontSize="xs"
                      fontWeight="regular"
                      overflow="hidden"
                      textColor="primary"
                      textOverflow="ellipsis"
                      whiteSpace="nowrap"
                    >
                      {[...contentLines].join(" ")}
                    </Text>
                  )}
                </Flex>
              </div>
            </div>
          );
        },
        width: 210,
      },
      {
        alignment: ColumnAlignment.LEFT,
        key: "assignee",
        title: "Assignee",
        renderNormalCell: ({ row: conversation }) => {
          return (
            <RedoTableTextCell text={conversation.assignee?.name || "-"} />
          );
        },
        width: 140,
      },
      {
        alignment: ColumnAlignment.LEFT,
        key: "platform",
        title: "Platform",
        renderNormalCell: ({ row: conversation }) => (
          <div className={conversationsTableCss.cellContainer}>
            <RedoSupportChannelBadge platform={conversation.platform} />
          </div>
        ),
        width: 122,
      },
      {
        alignment: ColumnAlignment.LEFT,
        key: "lastResponse",
        title: "Last response",
        renderNormalCell: ({ row: conversation }) => {
          if (conversation.lastResponseAt) {
            return (
              <div className={conversationsTableCss.cellContainer}>
                <Text fontSize="xs" fontWeight="regular" textColor="primary">
                  {formatTimeAgo(conversation.lastResponseAt)}
                </Text>
              </div>
            );
          }
          return undefined;
        },
        width: 120,
        sort: SortDirection.DESC,
      },
      {
        alignment: ColumnAlignment.LEFT,
        key: "number",
        title: "Ticket #",
        renderNormalCell: ({ row: conversation }) => {
          return <RedoTableTextCell text={`#${conversation.ticketNumber}`} />;
        },
        width: 86,
      },
      {
        alignment: ColumnAlignment.LEFT,
        key: "tags",
        title: "Tags",
        renderNormalCell: ({ row: conversation }) => {
          return (
            <div className={conversationsTableCss.tagContainer}>
              {conversation.tagIds?.map((tag) => (
                <ConversationTagPill key={tag.name} showOverflow tag={tag} />
              ))}
            </div>
          );
        },
        width: 220,
      },
      ...(menuColumn ? [menuColumn] : []),
    ];
  }, [selectionColumn, customerColumn, dropdownActions]);

  const EmptyContentMessage = (
    <Flex
      align="center"
      dir="column"
      gap="xs"
      grow={1}
      justify="flex-start"
      px="4xl"
      py="8xl"
      style={{ width: "100%" }}
    >
      <Text className={conversationsTableCss.noMoreTicketsIcon}>🎉</Text>
      <Text fontSize="md" fontWeight="semibold" textColor="primary">
        No more tickets in this view.
      </Text>
      <Text
        className={conversationsTableCss.noMoreTicketsSubText}
        fontSize="sm"
        textColor="tertiary"
      >
        You go! As new tickets are created they will show up here.
      </Text>
    </Flex>
  );
  return (
    <span className={conversationsTableCss.conversationsTableWrapper}>
      <Table2
        columns={fullColumns}
        EmptyContentMessage={EmptyContentMessage}
        externalSetSort={externalSetSort}
        externalSort={externalSort}
        fetcher={fetcher}
        onContextMenu={onContextMenu}
        onRowClick={onRowClick}
        onRowHovered={onRowHovered}
        passThroughValues={filters}
        ref={tableRef}
        scrollAreaRef={scrollAreaRef}
        searchEnabled={false}
        size={RedoTableSize.SMALL}
        sortDefault={sortDefault}
      />
      {rightClickMenuAnchor && (
        <ClickAwayListener onClickAway={() => setRightClickMenuVisible(false)}>
          <Dropdown
            anchor={rightClickMenuAnchor}
            fitToAnchor={false}
            open={rightClickMenuVisible}
          >
            <DropdownOption action={navigateToConversation}>
              Open in new tab
            </DropdownOption>
          </Dropdown>
        </ClickAwayListener>
      )}
    </span>
  );
});

enum CheckboxColumnState {
  CHECKBOX = "checkbox",
  UNREAD = "unread",
  EMPTY = "empty",
  ERROR = "error",
}

const CheckboxColumn = memo(function CheckboxColumn({
  conversation,
  idx,
  conversationsTableSelection,
  setSelectionSliceStart,
  setSelectionSliceEnd,
  currentConversationHovered,
}: {
  conversation: ExpandedConversation;
  idx: number;
  conversationsTableSelection: ConversationsTableSelection;
  setSelectionSliceStart: (idx: number | undefined) => void;
  setSelectionSliceEnd: (idx: number | undefined) => void;
  currentConversationHovered: string | undefined;
}) {
  const onCheckboxChange = useHandler((checked: boolean) => {
    setSelectionSliceStart(idx);
    setSelectionSliceEnd(undefined);
    if (checked) {
      conversationsTableSelection.addToSelection([conversation._id]);
    } else {
      conversationsTableSelection.removeFromSelection([conversation._id]);
    }
  });

  const shouldShowCheckbox = useMemo(() => {
    if (
      conversationsTableSelection &&
      (currentConversationHovered === conversation._id ||
        conversationsTableSelection.selectedIds.length > 0 ||
        conversationsTableSelection.selectAllMode)
    ) {
      return true;
    }
    return false;
  }, [conversationsTableSelection, currentConversationHovered, conversation]);

  const isChecked = conversationsTableSelection.selectAllMode
    ? !conversationsTableSelection.deselectedIds.some(
        (deselectedId) => deselectedId === conversation._id,
      )
    : conversationsTableSelection.selectedIds.some(
        (selectedId) => selectedId === conversation._id,
      );

  const state = useMemo(() => {
    if (shouldShowCheckbox) {
      return CheckboxColumnState.CHECKBOX;
    }
    if (conversation.platform === ConversationPlatform.SMS) {
      const mostRecentSentMessage = conversation.messages
        .slice()
        .reverse()
        .find(
          (m) => m.type !== "customer" && m.sentAt && !m.draftInfo?.isDraft,
        );

      const messageHasError = mostRecentSentMessage?.error;

      if (messageHasError) {
        return CheckboxColumnState.ERROR;
      }
    }
    if (!conversation.read) {
      return CheckboxColumnState.UNREAD;
    }
    return CheckboxColumnState.EMPTY;
  }, [shouldShowCheckbox, conversation]);

  const renderContents = useHandler(() => {
    switch (state) {
      case CheckboxColumnState.CHECKBOX:
        return (
          <div
            className={conversationsTableCss.checkboxContainer}
            onClick={() => {
              const checkbox = document.getElementById(
                `${conversation._id}-checkbox`,
              );
              onCheckboxChange(
                (checkbox as HTMLInputElement)?.checked || false,
              );
            }}
          >
            <Checkbox
              clickStopPropagation
              id={`${conversation._id}-checkbox`}
              onChange={onCheckboxChange}
              value={isChecked}
            />
          </div>
        );
      case CheckboxColumnState.UNREAD:
        return <div className={conversationsTableCss.unread} />;
      case CheckboxColumnState.ERROR:
        return (
          <div className={conversationsTableCss.errorIcon}>
            <AlertIcon />
          </div>
        );
      case CheckboxColumnState.EMPTY:
        return null;
      default:
        assertNever(state);
    }
  });

  return <div>{renderContents()}</div>;
});

const MenuColumn = memo(function MenuColumn({
  conversation,
  dropdownActions,
}: {
  conversation: ExpandedConversation;
  dropdownActions?: ConversationTableDropdownActions;
}) {
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const [actionsDropdownAnchor, setActionsDropdownAnchor] =
    useState<HTMLElement | null>(null);

  return (
    <ClickAwayListener onClickAway={() => setDropdownOpen(false)}>
      <div className={conversationsTableCss.menuContainer}>
        {/* Because we need to use stopPropagation to not move to the next page when menu is clicked we need to manually hide the menu */}
        <RedoButton
          hierarchy={RedoButtonHierarchy.SECONDARY}
          IconLeading={ThreeDotsHorizontalIcon}
          onClick={(e) => {
            e.stopPropagation();
            setDropdownOpen((prev) => !prev);
          }}
          ref={setActionsDropdownAnchor}
        />
        <RedoCommandMenu
          anchor={actionsDropdownAnchor}
          items={
            dropdownActions
              ? dropdownActions(conversation, setDropdownOpen)
                  .filter((actionButton) => actionButton.show)
                  .map((actionButton) => actionButton.commandMenuItem)
              : []
          }
          open={dropdownOpen}
          setOpen={setDropdownOpen}
        />
      </div>
    </ClickAwayListener>
  );
});
