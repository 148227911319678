import { RouteObject } from "react-router-dom";
import { ACTIVE_CONVERSATION_QUERY_PARAMETER } from "./query-parameters";
import { Support } from "./support";

const inAboutView = (url: string) => {
  return (
    url.includes(ACTIVE_CONVERSATION_QUERY_PARAMETER) ||
    /support\/about/.test(url)
  );
};

export const SUPPORT_VIEW_NAME = "Support View";

export const supportRoutes: RouteObject[] = [
  {
    handle: { breadcrumb: "Support", breadcrumbNavigate: false },
    async lazy() {
      const { Page } = await import("../app/page");
      return {
        element: (
          <Page
            hideHeader={inAboutView}
            hidePadding={(url: string) => !inAboutView(url)}
            outletChildFullHeight={() => true}
          />
        ),
      };
    },
    children: [
      {
        path: "about",
        async lazy() {
          const { AboutSupport } = await import("./about-support");
          return { element: <AboutSupport /> };
        },
      },
      {
        handle: { breadcrumb: SUPPORT_VIEW_NAME },
        path: "*",
        element: <Support />,
      },
      // Add back in if we do create order inside Redo
      // {
      //   handle: { breadcrumb: "Create order" },
      //   path: "create-order",
      //   element: <CreateOrder />,
      // },
    ],
  },
];
