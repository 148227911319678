// extracted by mini-css-extract-plugin
export var container = "_7r-0";
export var content = "_7r-3";
export var customerTitle = "_7r-4";
export var empty = "_7r-5";
export var gray = "_7r-6";
export var header = "_7r-7";
export var info = "_7r-8";
export var loading = "_7r-2";
export var loadingCard = "_7r-9";
export var oneLine = "_7r-a";
export var personName = "_7r-e";
export var pillContainer = "_7r-b";
export var selected = "_7r-1";
export var text = "_7r-c";
export var twoLines = "_7r-d";
export var unread = "_7r-f";
export var unreadIndicator = "_7r-g";