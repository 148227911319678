import { FulfillmentOrderData } from "@redotech/redo-model/fulfillments/fulfillment-group";
import { SortDirection } from "@redotech/redo-model/tables/table";
import { RedoTableTextCell } from "@redotech/redo-web/arbiter-components/tables/redo-table-cells";
import { ColumnAlignment } from "@redotech/redo-web/table";
import { Column2 } from "@redotech/redo-web/table2";
import { getCustomerName } from "../../utils/outbound-labels-utils";

export const CustomerNameColumn: Column2<FulfillmentOrderData> = {
  alignment: ColumnAlignment.LEFT,
  key: "customer",
  title: "Customer",
  renderNormalCell: ({ row }: { row: FulfillmentOrderData }) => (
    <RedoTableTextCell text={getCustomerName(row)} />
  ),
  width: 160,
  sort: SortDirection.DESC,
};
