import { CallDirection } from "@redotech/redo-model/support/voice/voice-types";
import { z } from "zod";

const transcriptItemSchema = z.object({
  id: z.number(),
  type: z.string(),
  alternatives: z.array(
    z.object({ confidence: z.string(), content: z.string() }),
  ),
  start_time: z.optional(z.string()),
  end_time: z.optional(z.string()),
  speaker_label: z.string(),
});

const audioSegmentSchema = z.object({
  id: z.number(),
  transcript: z.string(),
  start_time: z.string(),
  end_time: z.string(),
  speaker_label: z.string(),
  items: z.array(z.number()),
});

const callRecordSchema = z.object({
  _id: z.string(),
  team: z.string(),
  customer: z.object({
    _id: z.string(),
    name: z.optional(z.string()),
    email: z.optional(z.string()),
    phone: z.optional(z.string()),
  }),
  users: z.array(z.string()),
  recordingUrl: z.optional(z.string()),
  deleted: z.boolean(),
  deletedAt: z.optional(z.date()),
  deletedBy: z.optional(z.object({ _id: z.string(), name: z.string() })),
  createdAt: z.date(),
  updatedAt: z.date(),
  transcriptUrl: z.optional(z.string()),
  callStartedAt: z.date(),
  callConnectedAt: z.optional(z.date()),
  callDuration: z.optional(z.number()),
  callEndedAt: z.date(),
  callDirection: z.nativeEnum(CallDirection),
  holdStartedAt: z.optional(z.date()),
  holdEndedAt: z.optional(z.date()),
});

export const getCallRecordWithTranscript = {
  input: z.object({ callRecordId: z.string() }),
  output: z.object({
    status: z.number(),
    data: z.object({
      callRecord: callRecordSchema,
      transcript: z.optional(
        z.object({
          jobName: z.string(),
          accountId: z.string(),
          status: z.string(),
          results: z.object({
            transcripts: z.array(z.object({ transcript: z.string() })),
            items: z.array(transcriptItemSchema),
            audio_segments: z.array(audioSegmentSchema),
          }),
        }),
      ),
    }),
  }),
};

export const GetCallRecordWithTranscriptDbParser = z.object({
  callRecord: callRecordSchema,
  transcript: z.optional(
    z.object({
      jobName: z.string(),
      accountId: z.string(),
      status: z.string(),
      results: z.object({
        transcripts: z.array(z.object({ transcript: z.string() })),
        items: z.array(transcriptItemSchema),
        audio_segments: z.array(audioSegmentSchema),
      }),
    }),
  ),
});

export type IAudioSegment = z.infer<typeof audioSegmentSchema>;
