import { FulfillmentOrderData } from "@redotech/redo-model/fulfillments/fulfillment-group";
import { FulfillmentGroupStatus } from "@redotech/redo-model/fulfillments/fulfillment-group-status";
import {
  RedoButtonHierarchy,
  RedoButtonSize,
  RedoButtonTheme,
} from "@redotech/redo-web/arbiter-components/buttons/redo-button";
import { RedoButtonDropdown } from "@redotech/redo-web/arbiter-components/buttons/redo-dropdown-button";
import {
  RedoCommandMenu,
  RedoCommandMenuItem,
} from "@redotech/redo-web/arbiter-components/command-menu/redo-command-menu";
import { Flex } from "@redotech/redo-web/flex";
import { Text } from "@redotech/redo-web/text";
import { DisabledTooltip } from "@redotech/redo-web/tooltip/disabled-tooltip";
import { memo, useMemo, useState } from "react";

export enum CreateAndPrintButtonActions {
  CREATE = "Create label",
  CREATE_AND_PRINT = "Create & print label",
  PRINT_LABEL = "Print label",
  PRINT_PACKING_SLIP = "Print packing slip",
  PRINT_PICK_LIST = "Print pick list",
  COMMERCIAL_INVOICE = "Print commercial invoice",
}

const incompleteDropdownOptions = [
  CreateAndPrintButtonActions.CREATE_AND_PRINT,
  CreateAndPrintButtonActions.CREATE,
  CreateAndPrintButtonActions.PRINT_PACKING_SLIP,
  CreateAndPrintButtonActions.PRINT_PICK_LIST,
];

const completeDropdownOptions = [
  CreateAndPrintButtonActions.PRINT_LABEL,
  CreateAndPrintButtonActions.PRINT_PACKING_SLIP,
  CreateAndPrintButtonActions.PRINT_PICK_LIST,
];

export const CreateAndPrintButton = memo(function CreateAndPrintButton({
  onClick,
  fulfillmentGroups,
  loading,
  dropdownValue,
  setDropdownValue,
  theme,
  selectedStatus,
  buttonText,
  errorMessage,
  warningMessage,
}: {
  onClick: (value: CreateAndPrintButtonActions) => void;
  loading: boolean;
  fulfillmentGroups: readonly FulfillmentOrderData[];
  dropdownValue: CreateAndPrintButtonActions;
  setDropdownValue: (value: CreateAndPrintButtonActions) => void;
  theme?: RedoButtonTheme;
  selectedStatus: FulfillmentGroupStatus | null | undefined;
  buttonText?: string;
  errorMessage?: string;
  warningMessage?: string;
}) {
  const [dropdownRef, setDropdownRef] = useState<HTMLElement | null>(null);
  const [dropdownOpen, setDropdownOpen] = useState(false);

  const isClosed = selectedStatus === FulfillmentGroupStatus.Closed;

  const dropdownItems: RedoCommandMenuItem[] = useMemo(() => {
    const options = isClosed
      ? completeDropdownOptions
      : incompleteDropdownOptions;

    if (
      fulfillmentGroups.some((fg) => fg.shipment?.label?.commercialInvoiceUrl)
    ) {
      options.push(CreateAndPrintButtonActions.COMMERCIAL_INVOICE);
    }

    return options.map((option) => ({
      text: option,
      onClick: () => {
        setDropdownValue(option);
      },
    }));
  }, [isClosed, setDropdownValue]);

  return (
    <Flex dir="column" gap="md">
      <DisabledTooltip disabledMessage={errorMessage}>
        <RedoButtonDropdown
          disabled={!!errorMessage}
          dropdownOpen={dropdownOpen}
          hierarchy={
            isClosed
              ? RedoButtonHierarchy.SECONDARY
              : RedoButtonHierarchy.PRIMARY
          }
          onClick={() => onClick(dropdownValue)}
          pending={loading}
          ref={setDropdownRef}
          setDropdownOpen={setDropdownOpen}
          size={isClosed ? RedoButtonSize.REGULAR : RedoButtonSize.LARGE}
          text={buttonText ?? dropdownValue}
          theme={isClosed ? RedoButtonTheme.NORMAL : RedoButtonTheme.SUCCESS}
        >
          <RedoCommandMenu
            anchor={dropdownRef}
            items={dropdownItems}
            open={dropdownOpen}
            setOpen={setDropdownOpen}
          />
        </RedoButtonDropdown>
      </DisabledTooltip>
      <Flex>
        {errorMessage ? (
          <Text fontSize="xs" textColor="error">
            {errorMessage}
          </Text>
        ) : null}
        {warningMessage ? (
          <Text fontSize="xs" textColor="warning">
            {warningMessage}
          </Text>
        ) : null}
      </Flex>
    </Flex>
  );
});
