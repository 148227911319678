/**
 * Arbiter textarea input field https://www.figma.com/design/iZHj2I36zd9i8nRbWKw4ZK/%E2%9D%96-Arbiter?node-id=1238-278&t=erSoAzUBb9k4GD7O-4
 */

import * as classNames from "classnames";
import { memo } from "react";
import { Flex } from "../../flex";
import { Text } from "../../text";
import { SpacingValue } from "../../theme/box";
import {
  textClasses,
  TextColorValue,
  TextProps,
  TextSizeValue,
} from "../../theme/typography";
import * as RedoTextareaInputFieldCss from "./redo-textarea-input-field.module.css";

export enum RedoTextareaInputFieldSize {
  SMALL = "small",
  REGULAR = "regular",
}

export enum RedoTextareaInputFieldTheme {
  DEFAULT = "default",
  DESTRUCTIVE = "destructive",
}

export interface RedoTextareaInputFieldProps {
  size?: RedoTextareaInputFieldSize;
  theme?: RedoTextareaInputFieldTheme;
  disabled?: boolean;
  placeholder?: string;
  value: string;
  setValue(value: string): void;
  label?: string;
  className?: string;
  hintText?: string;
  dangerousStyleThatShouldOnlyBeUsedForMerchantBranding?: React.CSSProperties;
  required?: boolean;
  restrictWidth?: boolean;
}

/**
 * This component is incomplete. Feel free to use, but be aware it is not as polished as other redo components
 */
export const RedoTextareaInputField = memo(function RedoTextareaInputField({
  size = RedoTextareaInputFieldSize.SMALL,
  theme = RedoTextareaInputFieldTheme.DEFAULT,
  value,
  placeholder,
  setValue,
  disabled,
  label,
  className,
  hintText,
  dangerousStyleThatShouldOnlyBeUsedForMerchantBranding,
  required,
  restrictWidth,
}: RedoTextareaInputFieldProps) {
  function handleInputContentChange(
    event: React.ChangeEvent<HTMLTextAreaElement>,
  ) {
    setValue(event.target.value);
  }

  return (
    <Flex
      className={classNames(
        restrictWidth && RedoTextareaInputFieldCss.containerWidth,
        RedoTextareaInputFieldCss.container,
      )}
      dir="column"
      gap="xs"
    >
      <>
        {label !== undefined && (
          <Text
            fontSize={sizeToLabelTextProps[size]}
            fontWeight="medium"
            textColor="secondary"
          >
            {label}
          </Text>
        )}
        <Flex
          align="stretch"
          as="label"
          className={classNames(
            disabled && RedoTextareaInputFieldCss.disabled,
            RedoTextareaInputFieldCss.inputWrapper,
            sizeStyles[size],
            className,
          )}
          grow={1}
          justify="flex-start"
          px={sizeToPx[size]}
          py={sizeToPy[size]}
          style={dangerousStyleThatShouldOnlyBeUsedForMerchantBranding}
        >
          <textarea
            className={classNames(
              textClasses(sizeToTextProps[size]),
              RedoTextareaInputFieldCss.textarea,
            )}
            disabled={disabled}
            onChange={handleInputContentChange}
            placeholder={placeholder}
            required={required}
            value={value}
          />
        </Flex>
        {hintText !== undefined && (
          <Text
            fontSize={sizeToLabelTextProps[size]}
            fontWeight="regular"
            textColor={themeToHintTextColor[theme]}
          >
            {hintText}
          </Text>
        )}
      </>
    </Flex>
  );
});

const sizeStyles: Record<RedoTextareaInputFieldSize, string> = {
  [RedoTextareaInputFieldSize.SMALL]: RedoTextareaInputFieldCss.small,
  [RedoTextareaInputFieldSize.REGULAR]: RedoTextareaInputFieldCss.medium,
};

const sizeToTextProps: Record<RedoTextareaInputFieldSize, TextProps> = {
  [RedoTextareaInputFieldSize.SMALL]: { fontSize: "xs" },
  [RedoTextareaInputFieldSize.REGULAR]: { fontSize: "md" },
};

const sizeToLabelTextProps: Record<RedoTextareaInputFieldSize, TextSizeValue> =
  {
    [RedoTextareaInputFieldSize.SMALL]: "xs",
    [RedoTextareaInputFieldSize.REGULAR]: "sm",
  };

const sizeToPx: Record<RedoTextareaInputFieldSize, SpacingValue> = {
  [RedoTextareaInputFieldSize.SMALL]: "md",
  [RedoTextareaInputFieldSize.REGULAR]: "lg",
};

const sizeToPy: Record<RedoTextareaInputFieldSize, SpacingValue> = {
  [RedoTextareaInputFieldSize.SMALL]: "lg",
  [RedoTextareaInputFieldSize.REGULAR]: "lg",
};

const themeToHintTextColor: Record<
  RedoTextareaInputFieldTheme,
  TextColorValue
> = {
  [RedoTextareaInputFieldTheme.DEFAULT]: "tertiary",
  [RedoTextareaInputFieldTheme.DESTRUCTIVE]: "error",
};
