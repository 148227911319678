import { z } from "zod";

export enum PrintKind {
  PICK_LIST = "pickListPrinted",
  PACKING_SLIP = "packingSlipPrinted",
  LABEL = "labelPrinted",
}

export const PrintStatusSchema = z.object({
  [PrintKind.PICK_LIST]: z.boolean().nullish(),
  [PrintKind.PACKING_SLIP]: z.boolean().nullish(),
  [PrintKind.LABEL]: z.boolean().nullish(),
});

export type PrintStatus = z.infer<typeof PrintStatusSchema>;
