// extracted by mini-css-extract-plugin
export var bubbleContent = "_7i-0";
export var dark = "_7i-2";
export var dot = "_7i-7";
export var firstDot = "_7i-9";
export var left = "_7i-3";
export var light = "_7i-1";
export var loadingBubble = "_7i-5";
export var loadingDot = "_7i-c";
export var loadingDotContainer = "_7i-6";
export var moveUpDown = "_7i-8";
export var right = "_7i-4";
export var secondDot = "_7i-a";
export var thirdDot = "_7i-b";