export enum ResponseLength {
  EXTREMELY_BRIEF = "EXTREMELY_BRIEF",
  CONCISE = "CONCISE",
  MODERATE = "MODERATE",
  DETAILED = "DETAILED",
  COMPREHENSIVE = "COMPREHENSIVE",
}

export enum SenderType {
  CUSTOMER = "CUSTOMER",
  CONCIERGE = "CONCIERGE",
  TOOL = "TOOL",
}

export enum ContentType {
  TOOL_CALL = "TOOL_CALL",
  TEXT = "TEXT",
  CART_ITEMS = "CART_ITEMS",
  PRODUCT_DISPLAY = "PRODUCT_DISPLAY",
  DISPLAY_ORDER_TRACKING_INFO = "DISPLAY_ORDER_TRACKING_INFO",
  TRACK_ORDER_UI = "TRACK_ORDER_UI",
  TOOL_CALL_PRODUCT_INFO = "PRODUCTS",
  TOOL_CALL_RELEVANT_PRODUCTS = "RELEVANT_PRODUCTS",
  SUBSCRIBE_TO_BACK_IN_STOCK_NOTIFICATION = "SUBSCRIBE_TO_BACK_IN_STOCK_NOTIFICATION",
  SUBSCRIBE_TO_BACK_IN_STOCK_NOTIFICATION_UI = "SUBSCRIBE_TO_BACK_IN_STOCK_NOTIFICATION_UI",
  EMAIL_INPUT = "EMAIL_INPUT",
  DISCOUNT_CODE = "DISCOUNT_CODE",
  RESEND_RETURN_LABEL_UI = "RESEND_RETURN_LABEL_UI",
  TOOL_CALL_TRACK_ORDER_AVAILABLE = "TOOL_CALL_TRACK_ORDER_AVAILABLE",
  TOOL_CALL_ERROR = "TOOL_CALL_ERROR",
}

export enum ActionType {
  READ = "READ",
  // UPDATE = "UPDATE",
  CREATE = "CREATE",
  // DELETE = "DELETE"
  UNSPECIFIED = "UNSPECIFIED",
}

export enum IntentType {
  RECOMMEND = "RECOMMEND",
  PURCHASE = "PURCHASE",
  // Add other intent types as needed
  // INFORM = "INFORM",
  // CONFIRM = "CONFIRM"
}

export const CONCIERGE_CONVERSATION_CONSTANTS = {
  // Message structure keys
  CONTENT_TYPE_KEY: "contentType",
  SENDER_TYPE_KEY: "senderType",
  CONTENT_KEY: "content",
  RAW_CONTENT_KEY: "rawContent",

  // Tag attribute keys
  VARIANT_ID_KEY: "variantId",
  PRODUCT_ID_KEY: "productId",
  PRODUCT_TITLE_KEY: "productTitle",
  QUANTITY_KEY: "quantity",
  ACTION_KEY: "action",
  INTENT_KEY: "intent",
} as const;

export enum BillingStatus {
  FREE_TRIAL = "freeTrial",
  BILLED = "billed",
}
