import { z } from "zod";
import { TimeframeConditionType, TimeframeUnit } from "./segment-timeframe";

const timeframeUnitSchema = z.nativeEnum(TimeframeUnit);

const afterTimeframeSchema = z.object({
  type: z.literal(TimeframeConditionType.AFTER),
  options: z.object({ date: z.string().date() }),
});

const beforeTimeframeSchema = z.object({
  type: z.literal(TimeframeConditionType.BEFORE),
  options: z.object({ date: z.string().date() }),
});

const todayTimeframeSchema = z.object({
  type: z.literal(TimeframeConditionType.TODAY),
  options: z.null(),
});

const automationStartTimeframeSchema = z.object({
  type: z.literal(TimeframeConditionType.AUTOMATION_START),
  options: z.object({ automationStartTime: z.string().datetime() }),
});

const allTimeTimeframeSchema = z.object({
  type: z.literal(TimeframeConditionType.ALL_TIME),
  options: z.null(),
});

const betweenRelativeTimeframeSchema = z.object({
  type: z.literal(TimeframeConditionType.BETWEEN_RELATIVE),
  options: z
    .object({ start: z.number(), end: z.number(), units: timeframeUnitSchema })
    .refine(({ start, end }) => start < end, {
      message: "Start value must be less than end value",
      path: ["options"],
    }),
});

const betweenTimeframeSchema = z.object({
  type: z.literal(TimeframeConditionType.BETWEEN_DATES),
  options: z
    .object({ range: z.tuple([z.string().date(), z.string().date()]) })
    .refine(({ range }) => new Date(range[0]) < new Date(range[1]), {
      message: "Start date must be before end date",
      path: ["options"],
    }),
});

const beforeRelativeTimeframeSchema = z.object({
  type: z.literal(TimeframeConditionType.BEFORE_RELATIVE),
  options: z.object({ value: z.number(), units: timeframeUnitSchema }),
});

const beforeNowRelativeTimeframeSchema = z.object({
  type: z.literal(TimeframeConditionType.BEFORE_NOW_RELATIVE),
  options: z.object({ value: z.number(), units: timeframeUnitSchema }),
});

const onTimeframeSchema = z.object({
  type: z.literal(TimeframeConditionType.ON),
  options: z.object({ date: z.string().date() }),
});

// Combined timeframe schema
export const timeframeSchema = z.discriminatedUnion("type", [
  afterTimeframeSchema,
  beforeTimeframeSchema,
  allTimeTimeframeSchema,
  betweenRelativeTimeframeSchema,
  betweenTimeframeSchema,
  beforeRelativeTimeframeSchema,
  beforeNowRelativeTimeframeSchema,
  onTimeframeSchema,
  todayTimeframeSchema,
  automationStartTimeframeSchema,
]);
