import { assertNever } from "@redotech/util/type";
import { ArrayStringFilterBuilder } from "../advanced-filters/array-string-filter";
import { BooleanFilterBuilder } from "../advanced-filters/boolean-filter";
import { DateFilterBuilder } from "../advanced-filters/date-filter";
import { ExistsFilterBuilder } from "../advanced-filters/exists-filter";
import {
  AdvancedFilterType,
  GenericFilterBuilder,
} from "../advanced-filters/generic-advanced-filter-data";
import { NumberFilterBuilder } from "../advanced-filters/number-filter";
import { StringFilterBuilder } from "../advanced-filters/string-filter";
import { AdvancedFilterData } from "../views";

export function getFilterBuilder(
  filter: AdvancedFilterData | undefined,
): GenericFilterBuilder<any, any, any> {
  if (!filter) {
    throw new Error("filter is undefined");
  }

  switch (filter.type) {
    case AdvancedFilterType.DATE:
      return DateFilterBuilder;
    case AdvancedFilterType.ARRAY_TO_STRING:
      return ArrayStringFilterBuilder;
    case AdvancedFilterType.NUMBER:
      return NumberFilterBuilder;
    case AdvancedFilterType.STRING:
      return StringFilterBuilder;
    case AdvancedFilterType.EXISTS:
      return ExistsFilterBuilder;
    case AdvancedFilterType.BOOLEAN:
      return BooleanFilterBuilder;
    default:
      assertNever(filter.type);
  }
}

export function filtersAreEqual(
  filter1: AdvancedFilterData,
  filter2: AdvancedFilterData,
): boolean {
  if (filter1.type !== filter2.type) {
    return false;
  }
  const filterBuilder = getFilterBuilder(filter1);
  return (
    filterBuilder.writeToString(filter1 as any) ===
    filterBuilder.writeToString(filter2 as any)
  );
}
