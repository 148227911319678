import {
  DiscountGroupType,
  DiscountSettings,
} from "@redotech/redo-model/discount";
import {
  Discount,
  IEmailTemplate,
  RecommendedProductFilter,
  ScreenSize,
} from "@redotech/redo-model/email-builder";
import { EmailBuilderMetafield } from "@redotech/redo-model/metafield";
import {
  ProductInfo,
  ShopifyGraphQLProduct,
} from "@redotech/redo-model/product";
import { Team } from "@redotech/redo-model/team";
import { OmitUnion } from "@redotech/util/type";
import { createContext } from "react";

export interface EmailBuilderContext {
  team: Team;
  template?: IEmailTemplate;
  setTemplate: (template: IEmailTemplate) => void;
  getCollections: () => Promise<{ id: string; name: string }[]>;
  getMetafields: () => Promise<EmailBuilderMetafield[]>;
  getProduct: (productId: string) => Promise<ShopifyGraphQLProduct | undefined>;
  searchProducts: ({
    search,
    signal,
  }: {
    search: string;
    signal: AbortSignal;
  }) => Promise<ProductInfo[] | undefined>;
  sendTestEmail: (
    email: string,
    template: IEmailTemplate,
    orderNumber?: string,
  ) => Promise<boolean>;
  createProductFilter: (
    productFilter: OmitUnion<RecommendedProductFilter, "_id">,
  ) => Promise<string>;
  updateProductFilter: (
    productFilter: RecommendedProductFilter,
  ) => Promise<string>;
  deleteProductFilter: (id: string) => Promise<void>;
  getProductFilters: () => Promise<RecommendedProductFilter[]>;
  getProductFilter: (
    id: string,
  ) => Promise<RecommendedProductFilter | undefined>;
  getDiscounts: (groupType?: DiscountGroupType) => Promise<Discount[]>;
  getDiscount: (id: string) => Promise<Discount | undefined>;
  createDiscount: ({
    discountInput,
    discountSettings,
  }: {
    discountInput: OmitUnion<Discount, "_id" | "discountId">;
    discountSettings: DiscountSettings;
  }) => Promise<string>;
  deleteDiscount: (id: string) => Promise<void>;
}

export const EmailBuilderContext = createContext<
  EmailBuilderContext | undefined
>(undefined);

export const ScreenSizeContext = createContext<ScreenSize>(ScreenSize.DESKTOP);
