import { z } from "zod";

export const updateNewOrderItems = {
  input: z.object({
    teamId: z.string(),
    returnId: z.string(),
    items: z.array(z.object({ quantity: z.number(), variantId: z.string() })),
  }),
  output: z.object({ success: z.boolean() }),
};
