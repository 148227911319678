import { FulfillmentOrderData } from "@redotech/redo-model/fulfillments/fulfillment-group";

import { Column2 } from "@redotech/redo-web/table2";

import { SortDirection } from "@redotech/redo-model/tables/table";
import { ColumnAlignment } from "@redotech/redo-web/table";
import { StringListCell } from "../cells/string-list-cell";

export const ProductVariantColumn: Column2<FulfillmentOrderData> = {
  key: "product-variant",
  title: "Product variant",
  alignment: ColumnAlignment.LEFT,
  renderNormalCell: ({ row }) => {
    const productVariants = row.items.map((item) => item.variantTitle);
    return <StringListCell displayName="variant" strings={productVariants} />;
  },
  width: 100,
  sort: SortDirection.ASC,
};
