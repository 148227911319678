import { useHandler } from "@redotech/react-util/hook";
import {
  CustomParcelType,
  Parcel,
  ParcelType,
} from "@redotech/redo-model/outbound-labels/parcel";
import { RedoIncrementDecrement } from "@redotech/redo-web/arbiter-components/increment-decrement/redo-increment-decrement";
import {
  RedoDropdownInputSize,
  RedoSingleSelectDropdownInput,
} from "@redotech/redo-web/arbiter-components/select-dropdown/redo-single-select-dropdown-input";
import { Flex } from "@redotech/redo-web/flex";
import { Text } from "@redotech/redo-web/text";
import { assertNever } from "@redotech/util/type";
import { memo, useMemo } from "react";

type ParcelDimension = "length" | "width" | "height";

export const ParcelSizeWidget = memo(function ParcelSizeWidget({
  disabled,
  selectedParcel,
  updateParcel,
}: {
  disabled: boolean;
  selectedParcel: Parcel | null;
  updateParcel: (parcel: Parcel) => void;
}) {
  const dimensionUnit = useMemo(() => {
    if (selectedParcel?.parcelType !== ParcelType.CUSTOM) {
      return "";
    }
    return selectedParcel.lengthUnit;
  }, [selectedParcel]);

  const handleParcelUpdate = useHandler(
    (value: number | undefined, dimension: ParcelDimension) => {
      if (
        selectedParcel?.parcelType !== ParcelType.CUSTOM ||
        value == undefined
      ) {
        return;
      }
      updateParcel({
        ...selectedParcel,
        default: false,
        name: "Custom",
        [dimension]: value,
      });
    },
  );

  const customParcelTypeLabel = (type: CustomParcelType) => {
    switch (type) {
      case CustomParcelType.BOX:
        return "Box";
      case CustomParcelType.SOFT_PACK:
        return "Soft Pack";
      case CustomParcelType.ENVELOPE:
        return "Envelope";
      default:
        assertNever(type);
    }
  };

  return (
    <Flex
      bgColor="tertiary"
      borderColor="primary"
      borderStyle="solid"
      borderWidth="1px"
      dir="column"
      gap="xs"
      p="lg"
      radius="md"
    >
      <Flex dir="column" flex="1" gap="md">
        {selectedParcel && (
          <>
            <Text fontSize="xs">Size {dimensionUnit}</Text>
            {selectedParcel.parcelType === ParcelType.CUSTOM ? (
              <>
                <RedoIncrementDecrement
                  disabled={disabled}
                  fullWidth
                  label="Length"
                  min={0}
                  placeholder="0"
                  setValue={(value) => handleParcelUpdate(value, "length")}
                  size="small"
                  suffix="in"
                  value={getParcelDimension(selectedParcel, "length")}
                />
                <RedoIncrementDecrement
                  disabled={disabled}
                  fullWidth
                  label="Width"
                  min={0}
                  placeholder="0"
                  setValue={(value) => handleParcelUpdate(value, "width")}
                  size="small"
                  suffix="in"
                  value={getParcelDimension(selectedParcel, "width")}
                />
                <RedoIncrementDecrement
                  disabled={disabled}
                  fullWidth
                  label="Height"
                  min={0}
                  placeholder="0"
                  setValue={(value) => handleParcelUpdate(value, "height")}
                  size="small"
                  suffix="in"
                  value={getParcelDimension(selectedParcel, "height")}
                />
                <RedoSingleSelectDropdownInput<CustomParcelType>
                  label="Type"
                  options={Object.values(CustomParcelType).map((type) => ({
                    label: customParcelTypeLabel(type),
                    value: type,
                  }))}
                  optionSelected={(value) =>
                    updateParcel({ ...selectedParcel, type: value.value })
                  }
                  selectedItem={{ value: selectedParcel.type }}
                  size={RedoDropdownInputSize.SMALL}
                >
                  {({ value }) => customParcelTypeLabel(value)}
                </RedoSingleSelectDropdownInput>
              </>
            ) : (
              <Text fontSize="xs">
                {selectedParcel.dimensions || "Unknown "}
              </Text>
            )}
          </>
        )}
      </Flex>
    </Flex>
  );
});

const getParcelDimension = (
  parcel: Parcel,
  dimension: ParcelDimension,
): number => {
  if (parcel.parcelType === ParcelType.CUSTOM) {
    return parcel[dimension];
  }
  return 0;
};
