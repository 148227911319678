import { z } from "zod";
import { ParcelSchema } from "./parcel";
import { WeightSchema } from "./util";

export const RateSchema = z.object({
  id: z.string(),
  shipment_id: z.string(),
  rate: z.string(),
  currency: z.string(),
  carrier: z.string(),
  service: z.string(),
  delivery_date: z.string().nullish(),
  delivery_days: z.number().nullish(),
  delivery_date_guaranteed: z.boolean().nullish(),
  created_at: z.string(),
  updated_at: z.string(),
  carrier_account_id: z.string(),
  upcharge: z.string(),
});

export type OutboundRate = z.infer<typeof RateSchema>;

export const OutboundLabelShippingAddressSchema = z.object({
  name: z.string().nullish(),
  address1: z.string(),
  address2: z.string().nullish(),
  city: z.string(),
  country: z.string().nullish(),
  country_code: z.string().nullish(),
  province: z.string().nullish(),
  province_code: z.string().nullish(),
  zip: z.string().nullish(),
  first_name: z.string().nullish(),
  last_name: z.string().nullish(),
  company: z.string().nullish(),
  email: z.string().nullish(),
  phone: z.string(),
});

export const ShipmentRatesSchema = z.object({
  easypostShipmentId: z.string().nullish(),
  parcel: ParcelSchema,
  mode: z.string(),
  rates: z.array(RateSchema),
  weight: WeightSchema,
});
export type ShipmentRates = z.infer<typeof ShipmentRatesSchema>;
