// extracted by mini-css-extract-plugin
export var border = "_5v-5";
export var button = "_5v-g";
export var center = "_5v-7";
export var centered = "_5v-3";
export var closeButton = "_5v-9";
export var disabledWrapper = "_5v-h";
export var error = "_5v-k";
export var fill = "_5v-d";
export var footer = "_5v-c";
export var footerAction = "_5v-e";
export var footerButtons = "_5v-f";
export var header = "_5v-4";
export var icon = "_5v-j";
export var info = "_5v-n";
export var inline = "_5v-6";
export var large = "_5v-2";
export var main = "_5v-a";
export var modal = "_5v-0";
export var none = "_5v-o";
export var scrollable = "_5v-b";
export var small = "_5v-1";
export var success = "_5v-m";
export var tight = "_5v-i";
export var titleIcon = "_5v-8";
export var warn = "_5v-l";