import { breadcrumbSlot } from "@redotech/redo-web/breadcrumb";
import { Navigate, RouteObject } from "react-router-dom";
import { urlIsOnlyOnMainTable } from "../return/route";
import { LabelsLoad } from "./awaiting-shipment-page/order-fulfillment-group-page";

const fullscreen = (url: string) => {
  return (
    url.includes("/packing-slips/") &&
    url
      .split("/")
      .pop()
      ?.split("?")[0]
      ?.match(/^[0-9a-fA-F]{24}$/) !== null
  );
};

const orderBreadcrumb = breadcrumbSlot();

export const orderRoutes: RouteObject[] = [
  {
    handle: { breadcrumb: "Orders" },
    async lazy() {
      const { Page } = await import("../app/page");
      return {
        element: (
          <Page
            hideHeader={fullscreen}
            hideNavbar={fullscreen}
            hidePadding={(url) =>
              urlIsOnlyOnMainTable(url, [
                "orders",
                "batch-history",
                "eod-manifest",
              ]) || url.includes("outbound-labels")
            }
          />
        ),
      };
    },
    children: [
      {
        index: true,
        async lazy() {
          const { Orders } = await import("./orders");
          return {
            element: (
              <Orders
                filterParams={(filters) => ({
                  dateRange: filters.dateRange,
                  shipmentStatus: filters.shipmentStatus,
                })}
              />
            ),
          };
        },
      },
      {
        path: ":orderId",
        handle: { breadcrumb: orderBreadcrumb },
        async lazy() {
          const { Order } = await import("./order");
          return { element: <Order breadcrumb={orderBreadcrumb} /> };
        },
      },
      {
        path: "fulfillment-group/:fulfillmentGroupId",
        handle: { breadcrumb: orderBreadcrumb },
        async lazy() {
          const { FulfillmentGroupDetails } = await import(
            "./fulfillment-order-group-details/fulfillment-group-details"
          );
          return {
            element: <FulfillmentGroupDetails breadcrumb={orderBreadcrumb} />,
          };
        },
      },
      {
        path: "outbound-labels",
        handle: { breadcrumb: "Outbound Labels" },
        children: [
          {
            path: "orders/:orderId",
            handle: { breadcrumb: orderBreadcrumb },
            async lazy() {
              const { OutboundOrder } = await import("./outbound-order");
              return {
                element: <OutboundOrder breadcrumb={orderBreadcrumb} />,
              };
            },
          },
          { path: "", element: <Navigate replace to="awaiting-shipment" /> },
          {
            handle: { breadcrumb: "Order view" },
            path: "*",
            element: <LabelsLoad />,
          },
        ],
      },
      {
        path: "batch-history",
        handle: { breadcrumb: "Batch history" },
        async lazy() {
          const { BatchHistoryPage } = await import("./batch-history");
          return { element: <BatchHistoryPage /> };
        },
      },
      {
        path: "eod-manifest",
        handle: { breadcrumb: "EOD Manifest" },
        async lazy() {
          const { EodManifestPage } = await import(
            "./end-of-day-manifest/eod-manifest-page"
          );
          return { element: <EodManifestPage /> };
        },
      },
      {
        path: "packing-slips",
        handle: { breadcrumb: "Packing slips" },
        children: [
          {
            index: true,
            async lazy() {
              const { PackingSlipsPage } = await import(
                "./packing-slips/packing-slips-page"
              );
              return { element: <PackingSlipsPage /> };
            },
          },
          {
            path: ":packingSlipId",
            handle: { breadcrumb: "Packing slip builder" },
            async lazy() {
              const { PackingSlipBuilder } = await import(
                "./packing-slips/packing-slip-builder"
              );
              return { Component: PackingSlipBuilder };
            },
          },
        ],
      },
    ],
  },
];
