import { reviewBodySchema } from "@redotech/redo-model/review/review-body-schema";
import {
  ReviewRating,
  ReviewStatus,
} from "@redotech/redo-model/review/review-field-schema";
import { SortDirection } from "@redotech/redo-model/tables/table";
import { z } from "zod";

const sortSchema = z.object({
  direction: z.nativeEnum(SortDirection),
  key: z.string(),
});

export const getReviews = {
  input: z.object({
    teamId: z.string(),
    statuses: z.optional(z.array(z.nativeEnum(ReviewStatus))),
    ratings: z.optional(z.array(z.nativeEnum(ReviewRating))),
    verifiedPurchase: z.optional(z.boolean()),
    shopifyProductId: z.optional(z.string()),
    sort: z.optional(sortSchema),
  }),
  output: z.array(reviewBodySchema),
};
