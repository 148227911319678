import { AdvancedTableFilter } from "@redotech/redo-web/advanced-filters/advanced-filter";

import { BooleanFilterOperator } from "@redotech/redo-model/views/advanced-filters/boolean-filter";
import { AdvancedFilterType } from "@redotech/redo-model/views/advanced-filters/generic-advanced-filter-data";
import ShieldTickIcon from "@redotech/redo-web/arbiter-icon/shield-tick_thick.svg";

export const ShippingInsuranceFilter: AdvancedTableFilter = {
  type: AdvancedFilterType.BOOLEAN,
  data: {
    name: "shippingInsurance",
    type: AdvancedFilterType.BOOLEAN,
    value: null,
    operator: BooleanFilterOperator.IS,
  },
  label: "Shipping",
  boolDisplay: { falseDisplay: "uninsured", trueDisplay: "insured" },
  Icon: ShieldTickIcon,
};
