import { Currency } from "@redotech/money/currencies";

import { z } from "zod";

export const addOutboundFunds = {
  input: z.object({
    amount: z.number(),
    alertThreshold: z.number(),
    currency: z.nativeEnum(Currency),
    paymentMethodId: z.string(),
  }),
  output: z.object({ balance: z.string() }),
};
