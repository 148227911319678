// extracted by mini-css-extract-plugin
export var centered = "_2s-6";
export var flexible = "_2s-5";
export var footer = "_2s-k";
export var footerBorder = "_2s-l";
export var header = "_2s-c";
export var headerBorder = "_2s-d";
export var headerDefault = "_2s-g";
export var headerMedium = "_2s-h";
export var headerPadding = "_2s-e";
export var iconAndTitle = "_2s-f";
export var large = "_2s-4";
export var largePadding = "_2s-a";
export var medium = "_2s-3";
export var mediumPadding = "_2s-9";
export var modal = "_2s-0";
export var modalContainer = "_2s-b";
export var noPadding = "_2s-7";
export var skinny = "_2s-1";
export var small = "_2s-2";
export var smallPadding = "_2s-8";
export var subtitle = "_2s-j";
export var title = "_2s-i";