import { FulfillmentGroup } from "./fulfillment-group";
import { FulfillmentGroupStatus } from "./fulfillment-group-status";

export function canVoidLabel(
  row: Pick<FulfillmentGroup, "shipment" | "status" | "fulfillments">,
) {
  const alreadyVoided = row.shipment?.voided;
  const isClosed = row.status === FulfillmentGroupStatus.Closed;
  const hasShipped = row.fulfillments?.some((f) => !!f.inTransitAt);
  return !alreadyVoided && isClosed && !hasShipped;
}
