import { genericMemo } from "@redotech/react-util/component";
import { useLoad } from "@redotech/react-util/load";
import { ArrayStringFilterOperator } from "@redotech/redo-model/views/advanced-filters/array-string-filter";
import { useEffect, useState } from "react";
import {
  RedoFilterDropdownAnchor,
  RedoFilterGroup,
} from "../../arbiter-components/filter-group/redo-filter-group";
import { RedoInputState } from "../../arbiter-components/input/base-redo-text-input";
import { RedoTextInput } from "../../arbiter-components/input/redo-text-input";
import { RedoListItem } from "../../arbiter-components/list/redo-list";
import { RedoListItemSize } from "../../arbiter-components/list/redo-list-item";
import { RedoSingleSelectDropdown } from "../../arbiter-components/select-dropdown/redo-single-select-dropdown";
import ChevronDown from "../../arbiter-icon/chevron-down_filled.svg";
import { Flex } from "../../flex";
import { ItemDropdownMultiSelect } from "../../item-dropdown-multi-select";
import { Text } from "../../text";
import { variableToSemanticDisplay } from "../../utils/display-code-variables";
import {
  AdvancedTableFilter,
  ArrayStringTableFilter,
} from "../advanced-filter";
import * as styles from "./filter.module.css";

export const ArrayStringFilterGroup = genericMemo(
  function ArrayStringFilterGroup<T = string>({
    filter,
    setFilter,
    removeFilter,
    openOnRender,
    filters,
  }: {
    filter: ArrayStringTableFilter;
    setFilter(filter: ArrayStringTableFilter): void;
    removeFilter(): void;
    openOnRender: boolean;
    filters: AdvancedTableFilter[];
  }) {
    const [operatorRef, setOperatorRef] = useState<HTMLButtonElement | null>(
      null,
    );
    const [valueRef, setValueRef] = useState<HTMLButtonElement | null>(null);

    const { name, value, operator } = filter.data;

    function getStringValue(item: any): string {
      return filter.stringify && item
        ? filter.stringify(item)
        : item
          ? item.toString()
          : "";
    }

    function getDisplayString(item: any): string {
      return filter.display && item
        ? filter.display(item)
        : item
          ? item.toString()
          : "";
    }

    const valueOptions = useLoad(
      async (signal) => {
        if (filter.valueFetcher) {
          return await filter.valueFetcher(
            {
              filters: filters.filter(
                (otherFilter) => filter.data.name !== otherFilter.data.name,
              ),
            },
            signal,
          );
        }
        return filter.values ?? [];
      },
      [filter],
    );

    const options = valueOptions.value || [];

    useEffect(() => {
      if (openOnRender && valueRef) {
        valueRef.click();
      }
    }, [openOnRender, valueRef]);

    const operatorAnchor = (
      <RedoFilterDropdownAnchor
        color="secondary"
        IconTrailing={ChevronDown}
        ref={setOperatorRef}
        text={arrayStringTypeToText[operator]}
      />
    );

    const operatorDropdown = (
      <RedoSingleSelectDropdown
        dropdownButtonRef={operatorRef}
        options={
          filter.operators && filter.operators.length > 0
            ? arrayStringDropdownItems.filter((item) =>
                filter?.operators?.includes(item.value),
              )
            : arrayStringDropdownItems
        }
        optionSelected={(operator: RedoListItem<ArrayStringFilterOperator>) => {
          setFilter({
            ...filter,
            data: { ...filter.data, operator: operator.value },
          });
        }}
        selectedItem={arrayStringDropdownItems.find(
          (item) => item.value === operator,
        )}
        size={RedoListItemSize.SMALL}
      >
        {(item) => (
          <Text
            fontSize="sm"
            overflow="hidden"
            textOverflow="ellipsis"
            whiteSpace="nowrap"
          >
            {arrayStringTypeToText[item.value]}
          </Text>
        )}
      </RedoSingleSelectDropdown>
    );

    const pluralLabel = filter.itemLabelPlural ?? `${filter.itemLabel}s`;

    const valueText = value
      ? value.length === 1
        ? `1 ${filter.itemLabel}`
        : ` ${value.length} ${pluralLabel}`
      : "...";

    const valueInput = (
      <RedoTextInput
        className={styles.stringInput}
        fullWidth={false}
        placeholder={value?.[0] ? getDisplayString(value[0]) : "..."}
        setValue={(value) => {
          setFilter({
            ...filter,
            data: { ...filter.data, value: [getStringValue(value)] },
          });
        }}
        state={RedoInputState.DEFAULT}
        value={value?.[0] ? getStringValue(value[0]) : ""}
      />
    );

    const [selectDropdownOpen, setSelectDropdownOpen] = useState(false);
    const valueDropdownAnchor = (
      <div onClick={(e) => e.stopPropagation()}>
        <RedoFilterDropdownAnchor
          color="secondary"
          onClick={() => setSelectDropdownOpen(!selectDropdownOpen)}
          ref={setValueRef}
          text={valueText}
          tooltip={
            value && (
              <Flex dir="column">
                {value.map((item) => (
                  <Text key={item}>{item}</Text>
                ))}
              </Flex>
            )
          }
        />
      </div>
    );

    const selectedItems = options
      .filter((item) => value?.includes(getStringValue(item)))
      .map(getStringValue);

    const valueDropdown = (
      <ItemDropdownMultiSelect
        dropdownAnchor={valueRef}
        isItemSelected={(item) => selectedItems.includes(getStringValue(item))}
        itemLabel={filter.itemLabel}
        itemLabelPlural={filter.itemLabelPlural}
        items={options}
        onItemClick={(item) => {
          const stringValue = getStringValue(item);
          if (selectedItems.includes(stringValue)) {
            selectedItems.splice(selectedItems.indexOf(stringValue), 1);
            setFilter({
              ...filter,
              data: { ...filter.data, value: selectedItems },
            });
          } else {
            setFilter({
              ...filter,
              data: { ...filter.data, value: [...selectedItems, stringValue] },
            });
          }
        }}
        openOnRender={selectDropdownOpen}
        setClosedOnRender={() => {
          setSelectDropdownOpen(false);
        }}
      >
        {(item) =>
          filter.ItemRenderer ? (
            filter.ItemRenderer({ item })
          ) : (
            <Text
              fontSize="sm"
              overflow="hidden"
              textOverflow="ellipsis"
              whiteSpace="nowrap"
            >
              {getDisplayString(item)}
            </Text>
          )
        }
      </ItemDropdownMultiSelect>
    );

    return (
      <>
        {operatorDropdown}
        {inputOperators.includes(operator) ? undefined : valueDropdown}
        <RedoFilterGroup
          Icon={filter.Icon}
          propertyName={variableToSemanticDisplay(name) ?? name}
          query={operatorAnchor}
          removeFilter={removeFilter}
          value={
            inputOperators.includes(operator) ? valueInput : valueDropdownAnchor
          }
        />
      </>
    );
  },
);

const arrayStringTypeToText: Record<ArrayStringFilterOperator, string> = {
  [ArrayStringFilterOperator.INCLUDES]: "include",
  [ArrayStringFilterOperator.EXCLUDES]: "do not include",
  [ArrayStringFilterOperator.CONTAINS]: "contains",
  [ArrayStringFilterOperator.ANY_OF]: "include any of",
  [ArrayStringFilterOperator.NONE_OF]: "include none of",
  [ArrayStringFilterOperator.ALL_OF]: "have all of",
};

const arrayStringDropdownItems: RedoListItem<ArrayStringFilterOperator>[] = [
  { value: ArrayStringFilterOperator.INCLUDES },
  { value: ArrayStringFilterOperator.EXCLUDES },
  { value: ArrayStringFilterOperator.CONTAINS },
  { value: ArrayStringFilterOperator.ALL_OF },
  { value: ArrayStringFilterOperator.ANY_OF },
  { value: ArrayStringFilterOperator.NONE_OF },
];

const inputOperators = [ArrayStringFilterOperator.CONTAINS];
