import {
  ActionType,
  SetStatus,
  SetStatusActionStatus,
  SetStatusSnoozePeriod,
} from "@redotech/redo-model/return-flow/action";
import { LabeledInput } from "@redotech/redo-web/labeled-input";
import { SelectDropdown } from "@redotech/redo-web/select-dropdown";
import * as selectCss from "../return-flow/select.module.css";
import { ActionTypeSetup } from "./multiple-actions";

const statusOptions = [
  SetStatusActionStatus.ARCHIVED,
  SetStatusActionStatus.CLOSED,
  SetStatusActionStatus.OPEN,
  SetStatusActionStatus.SNOOZED,
  SetStatusActionStatus.SPAM,
];

const snoozePeriodOptions = [
  SetStatusSnoozePeriod.NEXT_WEEK,
  SetStatusSnoozePeriod.TODAY,
  SetStatusSnoozePeriod.TOMORROW,
  SetStatusSnoozePeriod.WEEKEND,
];

const getSnoozePeriodLabel = (snoozePeriod: SetStatusSnoozePeriod) => {
  switch (snoozePeriod) {
    case SetStatusSnoozePeriod.TODAY:
      return "Later the same day";
    case SetStatusSnoozePeriod.TOMORROW:
      return "The next day";
    case SetStatusSnoozePeriod.WEEKEND:
      return "The next Saturday";
    case SetStatusSnoozePeriod.NEXT_WEEK:
      return "The next Monday";
  }
};

export const SET_STATUS: ActionTypeSetup<
  { status: SetStatusActionStatus; snoozePeriod?: SetStatusSnoozePeriod },
  SetStatus
> = {
  name: "Set status",
  description() {
    return "Change the status of the ticket";
  },
  Details({ state, setState, disabled }) {
    return (
      <>
        <LabeledInput label="Status">
          <SelectDropdown
            disabled={disabled}
            options={statusOptions}
            value={state.status}
            valueChange={(e) => {
              if (e) {
                setState((state) => ({ ...state, status: e }));
              }
            }}
          >
            {(status) => (
              <div className={selectCss.value}>
                <div className={selectCss.title}>{status}</div>
              </div>
            )}
          </SelectDropdown>
        </LabeledInput>
        {state.status === SetStatusActionStatus.SNOOZED && (
          <LabeledInput label="Until">
            <SelectDropdown
              disabled={disabled}
              options={snoozePeriodOptions}
              value={state.snoozePeriod}
              valueChange={(e) => {
                if (e) {
                  setState((state) => ({ ...state, snoozePeriod: e }));
                }
              }}
            >
              {(snoozePeriod) => (
                <div className={selectCss.value}>
                  <div className={selectCss.title}>
                    {getSnoozePeriodLabel(snoozePeriod)}
                  </div>
                </div>
              )}
            </SelectDropdown>
          </LabeledInput>
        )}
      </>
    );
  },
  empty: { status: SetStatusActionStatus.OPEN },
  fromModel(model) {
    return { status: model.status, snoozePeriod: model.snoozePeriod };
  },
  toModel(state) {
    return {
      type: ActionType.SetStatus,
      status: state.status,
      snoozePeriod: state.snoozePeriod,
    };
  },
  valid(state) {
    if (state.status === SetStatusActionStatus.SNOOZED && !state.snoozePeriod) {
      return false;
    }
    return true;
  },
};
