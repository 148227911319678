import { useRequiredContext } from "@redotech/react-util/context";
import { useHandler } from "@redotech/react-util/hook";
import { useLoad, useTriggerLoad } from "@redotech/react-util/load";
import { Order } from "@redotech/redo-model/order";
import {
  getReturner,
  ReturnedProductStatus,
  shouldBeAManagedClaim,
  type Product,
  type Return,
  type ReturnType,
} from "@redotech/redo-model/return";
import {
  ReturnTotals,
  ReturnTotalsCalculator,
} from "@redotech/redo-model/return-totals-calculator";
import { returnToTrackable } from "@redotech/redo-model/trackable";
import { BreadcrumbSlot, useBreadcrumb } from "@redotech/redo-web/breadcrumb";
import { Card } from "@redotech/redo-web/card";
import { ActionPortalContext } from "@redotech/redo-web/page";
import { memo, useContext, useEffect, useLayoutEffect, useState } from "react";
import { createPortal } from "react-dom";
import { useParams } from "react-router-dom";
import { RedoMerchantRpcClientContext } from "../app/redo-merchant-rpc-client-provider";
import { TeamContext } from "../app/team";
import { RedoMerchantClientContext } from "../client/context";
import { getReturn } from "../client/return";
import { ActivityTimeline } from "../order/activity-timeline";
import { ReturnActivities } from "./activities";
import { CommentsCard } from "./return-detail-cards/comments-card";
import { CustomerCard } from "./return-detail-cards/customer-card";
import { CustomerConversationsCard } from "./return-detail-cards/customer-conversations-card";
import { CustomerHistoryCard } from "./return-detail-cards/customer-history-card";
import { CustomerResponseCard } from "./return-detail-cards/customer-response-card";
import { DetailCard } from "./return-detail-cards/details-card";
import { ManagedClaimStatusCard } from "./return-detail-cards/managed-claim-status-card";
import { RepairInvoiceCard } from "./return-detail-cards/repair-invoice-card";
import { ReturnerCard } from "./return-detail-cards/returner-card";
import { SummaryCard } from "./return-detail-cards/summary-card";
import { TransactionsCard } from "./return-detail-cards/transactions-card";
import { YofiDetailsModal } from "./return-modals/yofi-details-modal";
import { ShippingCard } from "./return-page-right-panel/shipping-card";
import * as returnCss from "./return.module.css";

export const ReturnPage = memo(function ReturnPage({
  breadcrumb,
  returnType,
}: {
  breadcrumb: BreadcrumbSlot;
  returnType: ReturnType;
}) {
  const params = useParams();
  const client = useRequiredContext(RedoMerchantClientContext);
  const redoMerchantRpcClient = useContext(RedoMerchantRpcClientContext);
  const team = useContext(TeamContext);

  const [returnTotals, setReturnTotals] = useState<ReturnTotals>();

  const [returnLoad, doReturn] = useTriggerLoad(async (signal) => {
    if (!team || !redoMerchantRpcClient) {
      return null;
    }

    const return_ = await getReturn(client, { id: params.returnId!, signal });
    const res = await redoMerchantRpcClient.getOrders({
      teamId: team._id,
      orderIds: return_.orders.map((order) => order.order),
    });

    const orders = res.orders as Order[];

    const nonZeroValueExchange =
      team.settings.exchanges?.nonZeroValueExchange &&
      return_.type === "claim" &&
      return_.advancedExchangeItems.length > 0;

    try {
      const returnTotalsCalculator = new ReturnTotalsCalculator({
        return_,
        order: orders[0],
        orders,
        team,
      });

      const totals = returnTotalsCalculator.getTotalsForProducts(
        return_.products.filter(
          (product) => product.status !== ReturnedProductStatus.REJECTED,
        ),
        false,
        nonZeroValueExchange,
      );

      setReturnTotals(totals);
    } catch (error) {
      console.error(error);
    }
    return { return_, orders, nonZeroValueExchange };
  });

  const emailTemplateMapLoad = useLoad(
    async (signal) => {
      if (!team || !redoMerchantRpcClient) {
        return undefined;
      }
      const [emailTemplateIdToNameMap] = await Promise.allSettled([
        redoMerchantRpcClient.getEmailTemplateNames(
          { teamId: team._id },
          { signal },
        ),
      ]);
      return emailTemplateIdToNameMap.status === "fulfilled"
        ? emailTemplateIdToNameMap.value?.emailTemplateIdToNameMap
        : undefined;
    },
    [team, redoMerchantRpcClient],
  );

  const actionsPortal = useContext(ActionPortalContext);

  const showManagedClaimCard = shouldBeAManagedClaim({
    returnType,
    packageProtectionPlusEnabled:
      !!team?.settings?.packageProtection?.packageProtectionPlusEnabled,
  });

  useLayoutEffect(() => {
    doReturn();
  }, [params.returnId!]);
  useBreadcrumb(
    breadcrumb,
    (returnLoad.value?.return_?.orders || [])
      .map((order) => order.name)
      .join(" & "),
  );

  useEffect(() => {
    doReturn();
  }, [team, redoMerchantRpcClient, client]);

  const hasNonGreenReturn = (return_?: Return) => {
    return return_?.products?.some(
      (product: Product) => product.green_return == false,
    );
  };

  const hasRepairProduct = (return_?: Return) => {
    return return_?.products?.some(
      (product: Product) => product.strategy === "repair",
    );
  };

  const [yofiOpen, setYofiOpen] = useState(false);
  const handleYofiClose = useHandler(() => {
    setYofiOpen(false);
  });

  return (
    <div className={returnCss.container}>
      {actionsPortal &&
        createPortal(
          <ReturnActivities returnId={params.returnId!} />,
          actionsPortal,
        )}
      <div className={returnCss.column1}>
        <DetailCard
          orders={returnLoad.value?.orders}
          pending={returnLoad.pending}
          reload={doReturn}
          return={returnLoad.value?.return_}
          returnTotals={returnTotals}
        />
        <SummaryCard
          nonZeroValueExchange={returnLoad.value?.nonZeroValueExchange}
          return={returnLoad.value?.return_}
          returnTotals={returnTotals}
        />
        <TransactionsCard return={returnLoad.value?.return_} />
        <CommentsCard reload={doReturn} return={returnLoad.value?.return_} />
        {team?.settings?.returnTracking?.enabled &&
          returnLoad.value?.return_ && (
            <ActivityTimeline
              emailTemplateIdToNameMap={emailTemplateMapLoad.value}
              trackable={returnToTrackable(
                returnLoad.value?.return_,
                returnLoad.value?.orders[0],
              )}
            />
          )}
      </div>
      <div className={returnCss.column2}>
        <ReturnerCard return={returnLoad.value?.return_} />
        <CustomerCard return={returnLoad.value?.return_} />
        <CustomerResponseCard
          order={returnLoad.value?.orders?.[0]}
          return={returnLoad.value?.return_}
          setYofiModalOpen={setYofiOpen}
        />
        {hasRepairProduct(returnLoad.value?.return_) && (
          <>
            <RepairInvoiceCard return={returnLoad.value?.return_} />
            <Card>
              <ShippingCard
                isRepairCard
                order={returnLoad.value?.orders?.[0]}
                reload={doReturn}
                return={returnLoad.value?.return_}
              />
            </Card>
          </>
        )}
        {!returnLoad.value?.return_.inStoreReturn &&
          hasNonGreenReturn(returnLoad.value?.return_) && (
            <Card>
              <ShippingCard
                order={returnLoad.value?.orders?.[0]}
                reload={doReturn}
                return={returnLoad.value?.return_}
              />
            </Card>
          )}
        {team?.settings.support && (
          <CustomerConversationsCard
            customerEmail={
              (returnLoad.value &&
                getReturner(returnLoad.value.return_).email) ||
              undefined
            }
            returnType={returnLoad.value?.return_.type}
          />
        )}
        {showManagedClaimCard && (
          <ManagedClaimStatusCard return={returnLoad.value?.return_} />
        )}
        <CustomerHistoryCard return={returnLoad.value?.return_} />
      </div>
      {yofiOpen && (
        <YofiDetailsModal
          customerId={returnLoad.value?.orders?.[0].shopify.customer.id}
          onClose={handleYofiClose}
          open={yofiOpen}
        />
      )}
    </div>
  );
});

export function shopifyAddressString(address: any) {
  if (!address) {
    return "";
  }
  let result = address.address1;
  if (address.address2) {
    result += " ";
    result += address.address2;
  }
  result += ", ";
  result += address.city;
  result += " ";
  result += address.province_code || address.province;
  if (address.zip) {
    result += " ";
    result += address.zip;
  }
  if (address.country_code) {
    result += " ";
    result += address.country_code;
  }
  if (address.phone) {
    result += " ";
    result += address.phone;
  }
  return result;
}

// const ReturnPageNew = memo(function ReturnPageNew({
//   breadcrumb,
//   returnType,
// }: {
//   breadcrumb: BreadcrumbSlot;
//   returnType: ReturnType;
// }) {
//   const params = useParams();
//   const client = useRequiredContext(RedoMerchantClientContext);
//   const redoMerchantRpcClient = useContext(RedoMerchantRpcClientContext);
//   const team = useContext(TeamContext);

//   const [returnTotals, setReturnTotals] = useState<ReturnTotals>();

//   const [returnLoad, doReturn] = useTriggerLoad(async (signal) => {
//     if (!team || !redoMerchantRpcClient) {
//       return null;
//     }

//     const return_ = await getReturn(client, { id: params.returnId!, signal });
//     const res = await redoMerchantRpcClient.getOrders({
//       teamId: team._id,
//       orderIds: return_.orders.map((order) => order.order),
//     });

//     const orders = res.orders as Order[];

//     const nonZeroValueExchange =
//       team.settings.exchanges?.nonZeroValueExchange &&
//       return_.type === "claim" &&
//       return_.advancedExchangeItems.length > 0;

//     try {
//       const returnTotalsCalculator = new ReturnTotalsCalculator({
//         return_,
//         order: orders[0],
//         orders,
//         team,
//       });

//       const totals = returnTotalsCalculator.getTotalsForProducts(
//         return_.products.filter(
//           (product) => product.status !== ReturnedProductStatus.REJECTED,
//         ),
//         false,
//         nonZeroValueExchange,
//       );

//       setReturnTotals(totals);
//     } catch (error) {
//       console.error(error);
//     }
//     return { return_, orders, nonZeroValueExchange };
//   });

//   const emailTemplateMapLoad = useLoad(
//     async (signal) => {
//       if (!team || !redoMerchantRpcClient) {
//         return undefined;
//       }
//       const [emailTemplateIdToNameMap] = await Promise.allSettled([
//         redoMerchantRpcClient.getEmailTemplateNames(
//           { teamId: team._id },
//           { signal },
//         ),
//       ]);
//       return emailTemplateIdToNameMap.status === "fulfilled"
//         ? emailTemplateIdToNameMap.value?.emailTemplateIdToNameMap
//         : undefined;
//     },
//     [team, redoMerchantRpcClient],
//   );

//   const actionsPortal = useContext(ActionPortalContext);

//   const isManagedClaim =
//     returnType === "claim" &&
//     team?.settings?.packageProtection?.packageProtectionPlusEnabled;

//   useLayoutEffect(() => {
//     doReturn();
//   }, [params.returnId!]);
//   useBreadcrumb(
//     breadcrumb,
//     (returnLoad.value?.return_?.orders || [])
//       .map((order) => order.name)
//       .join(" & "),
//   );

//   useEffect(() => {
//     doReturn();
//   }, [team, redoMerchantRpcClient, client]);

//   const hasNonGreenReturn = (return_?: Return) => {
//     return return_?.products?.some(
//       (product: Product) => product.green_return == false,
//     );
//   };

//   const hasRepairProduct = (return_?: Return) => {
//     return return_?.products?.some(
//       (product: Product) => product.strategy === "repair",
//     );
//   };

//   return (
//     <div className={returnNewCss.container}>
//       <div className={returnNewCss.leftPanel}>WIP</div>

//       <div className={returnNewCss.rightPanel}>
//         <SummaryCard
//           nonZeroValueExchange={returnLoad.value?.nonZeroValueExchange}
//           return={returnLoad.value?.return_}
//           returnTotals={returnTotals}
//         />
//         <Divider />
//         <CustomerTabsCard return={returnLoad.value?.return_} />
//         <Divider />
//         {hasRepairProduct(returnLoad.value?.return_) && (
//           <>
//             <RepairInvoiceCard return={returnLoad.value?.return_} />
//             <ShippingCard
//               isRepairCard
//               order={returnLoad.value?.orders?.[0]}
//               reload={doReturn}
//               return={returnLoad.value?.return_}
//             />
//           </>
//         )}
//         {!returnLoad.value?.return_.inStoreReturn &&
//           hasNonGreenReturn(returnLoad.value?.return_) && (
//             <ShippingCard
//               order={returnLoad.value?.orders?.[0]}
//               reload={doReturn}
//               return={returnLoad.value?.return_}
//             />
//           )}
//       </div>
//     </div>
//   );
// });
