// extracted by mini-css-extract-plugin
export var boldText = "_n-4";
export var checkboxContainer = "_n-6";
export var clearButton = "_n-c";
export var container = "_n-0";
export var drawerContent = "_n-8";
export var extraSmallText = "_n-5";
export var fulfillmentGroupTable = "_n-d";
export var largeText = "_n-3";
export var mediumText = "_n-2";
export var narrow = "_n-e";
export var packageCheckIconContainer = "_n-7";
export var slideoutHeaderContainer = "_n-9";
export var slideoutModal = "_n-a";
export var tableContainer = "_n-b";
export var tertiaryText = "_n-1";