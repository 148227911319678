import { z, ZodType } from "zod";

export enum AdvancedFilterType {
  DATE = "date",
  ARRAY_TO_STRING = "array_to_string",
  NUMBER = "number",
  STRING = "string",
  EXISTS = "exists",
  BOOLEAN = "boolean",
  // ARRAY = "array",
}

export const createAdvancedFilterDataSchema = <VALUE, OPERATOR extends string>(
  type: AdvancedFilterType,
  valueSchema: ZodType<VALUE>,
  operatorSchema: ZodType<OPERATOR>,
) =>
  z.object({
    type: z.literal(type),
    name: z.string(),
    value: valueSchema.nullish(),
    operator: operatorSchema,
  });

type CreateAdvancedFilterDataSchema<
  VALUE,
  OPERATOR extends string,
> = ReturnType<typeof createAdvancedFilterDataSchema<VALUE, OPERATOR>>;

export type GenericAdvancedFilterData<VALUE, OPERATOR extends string> = z.infer<
  CreateAdvancedFilterDataSchema<VALUE, OPERATOR>
>;

// TODO use builder type
export type SearchCompound = {
  filter: any[];
  must: any[];
  mustNot: any[];
  should: any[];
  minimumShouldMatch?: number;
};

export interface GenericFilterBuilder<
  VALUE,
  OPERATOR extends string,
  DATA extends GenericAdvancedFilterData<VALUE, OPERATOR>,
> {
  type: AdvancedFilterType;
  valueSchema: ZodType<VALUE>;
  operatorSchema: ZodType<OPERATOR>;
  schema: CreateAdvancedFilterDataSchema<VALUE, OPERATOR>;
  buildAtlasSearchQuery: (
    data: DATA,
    atlasPath: string,
    searchCompound: SearchCompound,
    useObjectId?: boolean,
  ) => SearchCompound;
  readFromString: (
    name: string,
    operatorValue: string,
    defaultFilter: DATA,
  ) => DATA | undefined;
  writeToString: (filter: DATA | undefined) => string;
  isPartial: (filter: DATA) => boolean;
}
