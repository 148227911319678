// extracted by mini-css-extract-plugin
export var actionButtonsContainer = "_bp-0";
export var footer = "_bp-2";
export var infoSection = "_bp-3";
export var infoSectionContainer = "_bp-4";
export var infoSectionTitleRow = "_bp-5";
export var itemDetails = "_bp-6";
export var line = "_bp-7";
export var lineItemImage = "_bp-1";
export var lineItems = "_bp-8";
export var modalContent = "_bp-9";
export var multiLineInfo = "_bp-a";
export var orderName = "_bp-b";
export var strong = "_bp-c";