// extracted by mini-css-extract-plugin
export var actionButtonsContainer = "_bq-0";
export var footer = "_bq-2";
export var infoSection = "_bq-3";
export var infoSectionContainer = "_bq-4";
export var infoSectionTitleRow = "_bq-5";
export var itemDetails = "_bq-6";
export var line = "_bq-7";
export var lineItemImage = "_bq-1";
export var lineItems = "_bq-8";
export var modalContent = "_bq-9";
export var multiLineInfo = "_bq-a";
export var orderName = "_bq-b";
export var strong = "_bq-c";