import { parse, ParseResult } from "papaparse";

export type CSVRow = { [key: string]: string };

export interface CSVFile {
  rows: CSVRow[];
}

export async function processCSV(file: File): Promise<CSVFile> {
  return new Promise((resolve, reject) => {
    parse(file, {
      header: true,
      skipEmptyLines: true,
      complete: (results: ParseResult<Record<string, string>>) => {
        resolve({ rows: results.data });
      },
      error: (error) => reject(error),
    });
  });
}
