import { isEnumValue } from "@redotech/util/enum";
import { assertNever } from "@redotech/util/type";

export enum Carriers {
  FEDEX = "FedEx",
  UPS = "UPS",
  USPS = "USPS",
  USPS_RETURNS = "USPSReturns",
  FEDEX_SMART_POST = "FedExSmartPost",
}

export function isCarrier(carrier: string): carrier is Carriers {
  return isEnumValue(carrier, Carriers);
}

/**
 * Includes both FedEx and FedExSmartPost
 */
export enum FedexServices {
  FEDEX_INTERNATIONAL_PRIORITY_EXPRESS = "FEDEX_INTERNATIONAL_PRIORITY_EXPRESS",
  FEDEX_INTERNATIONAL_CONNECT_PLUS = "FEDEX_INTERNATIONAL_CONNECT_PLUS",
  INTERNATIONAL_FIRST = "INTERNATIONAL_FIRST",
  FEDEX_GROUND = "FEDEX_GROUND",
  FEDEX_2_DAY = "FEDEX_2_DAY",
  PRIORITY_OVERNIGHT = "PRIORITY_OVERNIGHT",
  FEDEX_FIRST_FREIGHT = "FEDEX_FIRST_FREIGHT",
  FEDEX_EXPRESS_SAVER = "FEDEX_EXPRESS_SAVER",
  FEDEX_3_DAY_FREIGHT = "FEDEX_3_DAY_FREIGHT",
  FEDEX_2_DAY_AM = "FEDEX_2_DAY_AM",
  FEDEX_2_DAY_FREIGHT = "FEDEX_2_DAY_FREIGHT",
  INTERNATIONAL_ECONOMY = "INTERNATIONAL_ECONOMY",
  FIRST_OVERNIGHT = "FIRST_OVERNIGHT",
  STANDARD_OVERNIGHT = "STANDARD_OVERNIGHT",
  SMART_POST = "SMART_POST",
  FEDEX_1_DAY_FREIGHT = "FEDEX_1_DAY_FREIGHT",
  INTERNATIONAL_PRIORITY = "INTERNATIONAL_PRIORITY",
  FEDEX_INTERNATIONAL_PRIORITY = "FEDEX_INTERNATIONAL_PRIORITY",
}

export function isFedexService(
  service: FedexServices | UpsServices | UspsServices | string,
): service is FedexServices {
  return isEnumValue(service, FedexServices);
}

/**
 * Includes both USPS and USPSReturns
 */
export enum UspsServices {
  GroundAdvantageReturn = "GroundAdvantageReturn",
  ExpressMailInternational = "ExpressMailInternational",
  PriorityMailInternational = "PriorityMailInternational",
  FirstClassPackageInternationalService = "FirstClassPackageInternationalService",
  GroundAdvantage = "GroundAdvantage",
  PriorityMailReturn = "PriorityMailReturn",
  Priority = "Priority",
  Express = "Express",
  PriorityMailExpressReturn = "PriorityMailExpressReturn",
}

export function isUspsService(
  service: FedexServices | UpsServices | UspsServices | string,
): service is UspsServices {
  return isEnumValue(service, UspsServices);
}

export enum UpsServices {
  Ground = "Ground",
  NextDayAir = "NextDayAir",
  ThreeDaySelect = "3DaySelect",
  SecondDayAirAM = "2ndDayAirAM",
  UPSSaver = "UPSSaver",
  NextDayAirEarlyAM = "NextDayAirEarlyAM",
  ExpressPlus = "ExpressPlus",
  Express = "Express",
  NextDayAirSaver = "NextDayAirSaver",
  UPSStandard = "UPSStandard",
  SecondDayAir = "2ndDayAir",
  Expedited = "Expedited",
}

export function isUpsService(
  service: FedexServices | UpsServices | UspsServices | string,
): service is UpsServices {
  return isEnumValue(service, UpsServices);
}

export enum ServiceLevel {
  STANDARD = "Standard",
  EXPRESS = "Express",
  OVERNIGHT = "Overnight",
}

const fedexServiceToServiceLevel: Record<FedexServices, ServiceLevel> = {
  // FedEx
  [FedexServices.FEDEX_INTERNATIONAL_PRIORITY_EXPRESS]: ServiceLevel.EXPRESS,
  [FedexServices.FEDEX_INTERNATIONAL_CONNECT_PLUS]: ServiceLevel.STANDARD,
  [FedexServices.INTERNATIONAL_FIRST]: ServiceLevel.EXPRESS,
  [FedexServices.FEDEX_GROUND]: ServiceLevel.STANDARD,
  [FedexServices.FEDEX_2_DAY]: ServiceLevel.EXPRESS,
  [FedexServices.PRIORITY_OVERNIGHT]: ServiceLevel.OVERNIGHT,
  [FedexServices.FEDEX_FIRST_FREIGHT]: ServiceLevel.OVERNIGHT, // or EXPRESS?
  [FedexServices.FEDEX_EXPRESS_SAVER]: ServiceLevel.EXPRESS,
  [FedexServices.FEDEX_3_DAY_FREIGHT]: ServiceLevel.STANDARD,
  [FedexServices.FEDEX_2_DAY_AM]: ServiceLevel.EXPRESS,
  [FedexServices.FEDEX_2_DAY_FREIGHT]: ServiceLevel.EXPRESS,
  [FedexServices.INTERNATIONAL_ECONOMY]: ServiceLevel.STANDARD,
  [FedexServices.FIRST_OVERNIGHT]: ServiceLevel.OVERNIGHT,
  [FedexServices.STANDARD_OVERNIGHT]: ServiceLevel.OVERNIGHT,
  [FedexServices.SMART_POST]: ServiceLevel.STANDARD,
  [FedexServices.FEDEX_1_DAY_FREIGHT]: ServiceLevel.OVERNIGHT,
  [FedexServices.INTERNATIONAL_PRIORITY]: ServiceLevel.EXPRESS,
  [FedexServices.FEDEX_INTERNATIONAL_PRIORITY]: ServiceLevel.EXPRESS,
};

const upsServiceToServiceLevel: Record<UpsServices, ServiceLevel> = {
  [UpsServices.Ground]: ServiceLevel.STANDARD,
  [UpsServices.NextDayAir]: ServiceLevel.OVERNIGHT,
  [UpsServices.ThreeDaySelect]: ServiceLevel.STANDARD,
  [UpsServices.SecondDayAirAM]: ServiceLevel.EXPRESS,
  [UpsServices.UPSSaver]: ServiceLevel.EXPRESS,
  [UpsServices.NextDayAirEarlyAM]: ServiceLevel.OVERNIGHT,
  [UpsServices.ExpressPlus]: ServiceLevel.OVERNIGHT,
  [UpsServices.Express]: ServiceLevel.EXPRESS,
  [UpsServices.NextDayAirSaver]: ServiceLevel.OVERNIGHT,
  [UpsServices.UPSStandard]: ServiceLevel.STANDARD,
  [UpsServices.SecondDayAir]: ServiceLevel.EXPRESS,
  [UpsServices.Expedited]: ServiceLevel.STANDARD,
};

const uspsServiceToServiceLevel: Record<UspsServices, ServiceLevel> = {
  [UspsServices.GroundAdvantageReturn]: ServiceLevel.STANDARD,
  [UspsServices.ExpressMailInternational]: ServiceLevel.EXPRESS,
  [UspsServices.PriorityMailInternational]: ServiceLevel.EXPRESS,
  [UspsServices.FirstClassPackageInternationalService]: ServiceLevel.STANDARD,
  [UspsServices.GroundAdvantage]: ServiceLevel.STANDARD,
  [UspsServices.PriorityMailReturn]: ServiceLevel.EXPRESS,
  [UspsServices.Priority]: ServiceLevel.EXPRESS,
  [UspsServices.Express]: ServiceLevel.EXPRESS,
  [UspsServices.PriorityMailExpressReturn]: ServiceLevel.OVERNIGHT,
};

export function carrierAndServiceToServiceLevel(
  carrier: Carriers,
  service: FedexServices | UpsServices | UspsServices,
) {
  switch (carrier) {
    case Carriers.FEDEX:
    case Carriers.FEDEX_SMART_POST: {
      if (isFedexService(service)) {
        return fedexServiceToServiceLevel[service];
      }
      break;
    }
    case Carriers.UPS: {
      if (isUpsService(service)) {
        return upsServiceToServiceLevel[service];
      }
      break;
    }
    case Carriers.USPS:
    case Carriers.USPS_RETURNS: {
      if (isUspsService(service)) {
        return uspsServiceToServiceLevel[service];
      }
      break;
    }
    default:
      assertNever(carrier);
  }

  throw new Error(
    `Unknown carrier-service combination: ${carrier} and ${service}`,
  );
}

export function getAllServicesArray() {
  return [
    ...Object.values(FedexServices),
    ...Object.values(UpsServices),
    ...Object.values(UspsServices),
  ];
}

export type Service = FedexServices | UpsServices | UspsServices;

export type CarrierAndService = { carrier: Carriers; service: Service };

export function getCarriersAndServices() {
  const carriersAndServices: CarrierAndService[] = [];
  for (const service of Object.values(FedexServices)) {
    carriersAndServices.push({ service, carrier: Carriers.FEDEX });
  }
  for (const service of Object.values(UpsServices)) {
    carriersAndServices.push({ service, carrier: Carriers.UPS });
  }
  for (const service of Object.values(UspsServices)) {
    carriersAndServices.push({ service, carrier: Carriers.USPS });
  }
  return carriersAndServices;
}
