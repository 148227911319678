import { z } from "zod";

export const updateConciergeKnowledge = {
  input: z.object({
    _id: z.string(),
    whenToUse: z.string().optional(),
    title: z.string().optional(),
    knowledge: z.string().optional(),
    enabled: z.boolean().optional(),
  }),
  output: z.object({
    _id: z.string(),
    whenToUse: z.string(),
    title: z.string(),
    knowledge: z.string(),
    enabled: z.boolean(),
    teamId: z.string(),
  }),
};
