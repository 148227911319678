import { COUNTRIES_BY_CODE } from "@redotech/locale/countries";
import { ArrayStringFilterOperator } from "@redotech/redo-model/views/advanced-filters/array-string-filter";
import { AdvancedFilterType } from "@redotech/redo-model/views/advanced-filters/generic-advanced-filter-data";
import {
  AdvancedTableFilter,
  ArrayStringTableFilter,
} from "@redotech/redo-web/advanced-filters/advanced-filter";
import GlobeIcon from "@redotech/redo-web/arbiter-icon/globe-06.svg";

export const CountriesFilter = (
  fetcher: (
    { filters }: { filters: AdvancedTableFilter[] },
    signal: AbortSignal,
  ) => Promise<string[]>,
): ArrayStringTableFilter<string> => ({
  type: AdvancedFilterType.ARRAY_TO_STRING,
  data: {
    name: "countries",
    type: AdvancedFilterType.ARRAY_TO_STRING,
    value: [],
    operator: ArrayStringFilterOperator.ANY_OF,
  },
  itemLabel: "country",
  itemLabelPlural: "countries",
  Icon: GlobeIcon,
  operators: [
    ArrayStringFilterOperator.NONE_OF,
    ArrayStringFilterOperator.ALL_OF,
    ArrayStringFilterOperator.ANY_OF,
  ],
  valueFetcher: fetcher,
  display(item: string) {
    return COUNTRIES_BY_CODE[item] ?? item;
  },
});
