import { ConversationPlatform } from "@redotech/redo-model/conversation";
import {
  CsatSortableField,
  SatisfactionResponseStatusTypes,
} from "@redotech/redo-model/support/csat-response";
import { SortDirection } from "@redotech/redo-model/tables/table";
import { zExt } from "@redotech/rpc/ext";
import { z } from "zod";

export type CsatResponse = z.infer<typeof csatResponseSchema>;

export const csatResponseSchema = z.object({
  _id: zExt.objectId(),
  team: zExt.objectId(),
  conversation: zExt.objectId(),
  rating: z.number(),
  comment: z.string(),
  channel: z.nativeEnum(ConversationPlatform),
  status: z.nativeEnum(SatisfactionResponseStatusTypes),
  createdAt: z.date(),
  updatedAt: z.date(),
});

export const getCsatResponsesForConversation = {
  input: z.object({ conversationId: z.string() }),
  output: z.array(csatResponseSchema),
};

export type GetCsatResponsesForConversationInput = z.infer<
  typeof getCsatResponsesForConversation.input
>;
export type GetCsatResponsesForConversationOutput = z.infer<
  typeof getCsatResponsesForConversation.output
>;

const analyticsCsatResponseSchema = csatResponseSchema.extend({
  customer: z.any(),
  assignee: zExt.objectId().optional(),
});

const csatAnalyticsFilters = z.object({
  platform: z.nativeEnum(ConversationPlatform).optional(),
  dateRangeStart: z.date(),
  dateRangeEnd: z.date(),
});

export const getCsatResponsesForTeam = {
  input: z.object({
    pageContinue: z.string().optional(),
    pageSize: z.number(),
    pageStop: z.string().optional(),
    sort: z.object({
      direction: z.nativeEnum(SortDirection),
      key: z.nativeEnum(CsatSortableField),
    }),
    filters: csatAnalyticsFilters,
  }),
  output: z.object({
    data: z.array(analyticsCsatResponseSchema),
    pageNext: z.string().optional(),
  }),
};

export type GetCsatResponsesForTeamInput = z.infer<
  typeof getCsatResponsesForTeam.input
>;
export type GetCsatResponsesForTeamOutput = z.infer<
  typeof getCsatResponsesForTeam.output
>;

export const getCsatResponsesForTeamCount = {
  input: z.object({ filters: csatAnalyticsFilters }),
  output: z.object({ count: z.number() }),
};

export type GetCsatResponsesForTeamCountInput = z.infer<
  typeof getCsatResponsesForTeamCount.input
>;
