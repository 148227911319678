import { Gid } from "@redotech/util/shopify-id";
import { z } from "zod";
import { zExt } from "../common/zod-util";
import {
  DiscountGroupType,
  DiscountMinRequirementType,
  DiscountProductType,
  DiscountProvider,
  DiscountType,
  DiscountValueType,
  ExpirationType,
} from "../discount";

const GidSchema = z.custom<Gid>(
  (val): val is Gid => {
    return (
      typeof val === "string" && /^gid:\/\/shopify\/(\w+)\/(\d+)$/.test(val)
    );
  },
  { message: "Invalid Shopify GID format. Must be gid://shopify/TYPE/NUMBER" },
);

export const baseSchema = {
  title: z.string(),
  provider: z.nativeEnum(DiscountProvider),
  groupType: z.nativeEnum(DiscountGroupType),
};

export const shopifyDiscountSchema = z.object({
  ...baseSchema,
  discountId: z.string(),
});

export const baseDiscountSchema = z.discriminatedUnion("provider", [
  shopifyDiscountSchema,
]);

export const DiscountDbParser = z.intersection(
  baseDiscountSchema,
  z.object({ _id: zExt.objectId(), teamId: zExt.objectId() }),
);

export const baseSettingsSchema = {
  discountType: z.nativeEnum(DiscountType),
  expirationType: z.nativeEnum(ExpirationType),
  expirationDays: z.number().optional(),
  combinesWith: z
    .object({
      orderDiscounts: z.boolean().optional(),
      productDiscounts: z.boolean().optional(),
      shippingDiscount: z.boolean().optional(),
    })
    .optional(),
  minimumRequirement: z.object({
    type: z.nativeEnum(DiscountMinRequirementType),
    minimum: z.number(),
  }),
  defaultText: z.string().optional(),
};

export const discountOrderSettingsSchema = z.object({
  ...baseSettingsSchema,
  discountType: z.literal(DiscountType.ORDER),
  discountValueType: z.nativeEnum(DiscountValueType),
  discountAmountValue: z.number().optional(),
  discountPercentageValue: z.number().optional(),
});

export const discountFreeShippingSettingsSchema = z.object({
  ...baseSettingsSchema,
  discountType: z.literal(DiscountType.FREE_SHIPPING),
  freeShippingCountryCodes: z.array(z.string()).optional(),
  maximumShippingPrice: z.number(),
});

export const discountProductSettingsSchema = z.object({
  ...baseSettingsSchema,
  discountType: z.literal(DiscountType.PRODUCT),
  discountProductType: z.nativeEnum(DiscountProductType),
  discountProducts: z
    .array(
      z.object({ id: z.string().optional(), title: z.string().optional() }),
    )
    .optional(),
  discountCollections: z
    .array(z.object({ id: GidSchema.optional(), name: z.string().optional() }))
    .optional(),
  discountValueType: z.nativeEnum(DiscountValueType),
  discountAmountValue: z.number().optional(),
  discountPercentageValue: z.number().optional(),
});

export const discountSettingsSchema = z.discriminatedUnion("discountType", [
  discountOrderSettingsSchema,
  discountFreeShippingSettingsSchema,
  discountProductSettingsSchema,
]);
