// extracted by mini-css-extract-plugin
export var aboveInput = "_ai-0";
export var actionButton = "_ai-5";
export var actionButtonContainer = "_ai-1";
export var actionIcon = "_ai-6";
export var aiInfoContainer = "_ai-2";
export var attachmentWrapper = "_ai-7";
export var bold = "_ai-3";
export var checkIcon = "_ai-8";
export var clickingRecipientBar = "_ai-11";
export var commentThreadSwitch = "_ai-a";
export var draftImage = "_ai-b";
export var draftImageContainer = "_ai-c";
export var draftImages = "_ai-e";
export var editButton = "_ai-g";
export var editor = "_ai-f";
export var editorContainer = "_ai-y";
export var editorErrorTooltipWrapper = "_ai-9";
export var headerDivider = "_ai-h";
export var icon = "_ai-i";
export var info = "_ai-j";
export var internal = "_ai-n";
export var internalDivider = "_ai-k";
export var internalMessage = "_ai-l";
export var messageInputCard = "_ai-m";
export var messageInputForm = "_ai-o";
export var messageInputFormInternal = "_ai-p";
export var options = "_ai-q";
export var publicMessageCustomer = "_ai-s";
export var publicMessageMerchant = "_ai-r";
export var removeImageButton = "_ai-d";
export var replyButtons = "_ai-t";
export var showSignatureButton = "_ai-u";
export var spinner = "_ai-v";
export var verticalButtonDivider = "_ai-4";
export var visibilitySelector = "_ai-w";
export var warning = "_ai-x";
export var wrappingText = "_ai-z";
export var writingInternalNoteTopBorder = "_ai-10";