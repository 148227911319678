import {
  DynamicShippingRateTableSchema,
  FixedShippingRateTableSchema,
} from "@redotech/redo-model/integration/redo-in-shipping/ris";
import { DeliveryZoneSchema } from "@redotech/redo-model/integration/shopify/delivery-profile";
import { z } from "zod";

const CreateFixedShippingRateTableInputSchema =
  FixedShippingRateTableSchema.omit({
    _id: true,
    code: true,
    originLocations: true,
    destinationLocations: true,
  });
export type CreateFixedShippingRateTableInput = z.infer<
  typeof CreateFixedShippingRateTableInputSchema
>;

const CreateDynamicShippingRateTableInputSchema =
  DynamicShippingRateTableSchema.omit({
    originLocations: true,
    destinationLocations: true,
  });

const ShippingRateTableSchemaWithoutLocations = z.discriminatedUnion("type", [
  CreateFixedShippingRateTableInputSchema,
  CreateDynamicShippingRateTableInputSchema,
]);

export const createShippingMethod = {
  input: z.object({
    shippingRateTable: ShippingRateTableSchemaWithoutLocations,
    shippingZone: DeliveryZoneSchema,
  }),
  output: z.null(),
};
