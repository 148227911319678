import {
  ObjectParam,
  Param,
  StringParam,
  useParam,
} from "@redotech/react-router-util/param";
import { IterableMap, genericMemo } from "@redotech/react-util/component";
import { useHandler } from "@redotech/react-util/hook";
import { useLoad, useTriggerLoad } from "@redotech/react-util/load";
import { useScrolled } from "@redotech/react-util/scroll";
import { SortDirection, TableSort } from "@redotech/redo-model/tables/table";
import { Equal, objectEqual, stringEqual } from "@redotech/util/equal";
import { downloadBlob } from "@redotech/web-util/download";
import * as classnames from "classnames";
import * as classNames from "classnames";
import { stringify } from "csv-stringify/browser/esm/sync";
import * as sumBy from "lodash/sumBy";
import {
  ForwardedRef,
  MouseEvent,
  MutableRefObject,
  ReactElement,
  ReactNode,
  RefObject,
  forwardRef,
  memo,
  useEffect,
  useImperativeHandle,
  useMemo,
  useRef,
  useState,
} from "react";
import { useDebounce, useElementSize } from "usehooks-ts";
import { AdvancedTableFilter } from "./advanced-filters/advanced-filter";
import LeftArrowIcon from "./arbiter-icon/arrow-left_filled.svg";
import RightArrowIcon from "./arbiter-icon/arrow-right_filled.svg";
import { Button, ButtonSize, ButtonTheme } from "./button";
import { Flex } from "./flex";
import ChevronDown from "./icon-old/chevron-down.svg";
import ArrowDown from "./icon-old/down-arrow.svg";
import DownloadIcon from "./icon-old/download.svg";
import SearchIcon from "./icon-old/search.svg";
import XIcon from "./icon-old/x.svg";
import * as tableCss from "./table.module.css";
import { Text } from "./text";
import { TextInput } from "./text-input";
import { Tooltip } from "./tooltip/tooltip";

export const DEFAULT_PAGE_SIZE = 25;

export namespace TableTheme {
  export const SEAMLESS = Symbol("Seamless");
  export const WIDE = Symbol("Wide");
  export const COMPACT = Symbol("Compact");
}

export type TableTheme =
  | typeof TableTheme.SEAMLESS
  | typeof TableTheme.WIDE
  | typeof TableTheme.COMPACT;

export const tableThemeClasses = {
  [TableTheme.SEAMLESS]: tableCss.seamless,
  [TableTheme.WIDE]: tableCss.wide,
  [TableTheme.COMPACT]: tableCss.compact,
};

export enum ColumnAlignment {
  CENTER = 0,
  LEFT = 1,
  RIGHT = 2,
}

export interface Column<T> {
  alignment: ColumnAlignment;
  // React component names must start with an uppercase letter
  Cell(datum: T, idx: number): ReactNode | ReactNode[];
  onClick?(event: MouseEvent, datum: T): void;
  onHoverText?(datum: T): ReactNode | undefined;
  key: string;
  title?: string | ReactElement;
  headerTooltip?: string | undefined;
  /** Default sort */
  sort?: SortDirection;
  width: number;
}

export interface RowClickHandler<T> {
  (record: T, event: MouseEvent<HTMLTableRowElement>, idx: number): void;
}

export interface RowCounts {
  [key: string]: number;
}

export interface TableFetcher<T> {
  rows?: T[];
  counts(
    filters: { [name: string]: any },
    search: string | undefined,
    signal?: AbortSignal,
    appliedAdvancedFilters?: AdvancedTableFilter[],
  ): Promise<RowCounts>;
  data(
    primaryFilter: string,
    filters: { [name: string]: any },
    search: string | undefined,
    sort: TableSort | undefined,
    pageSize?: number,
    pageNumber?: number,
    signal?: AbortSignal,
    passThroughValues?: any,
    appliedAdvancedFilters?: AdvancedTableFilter[],
  ): AsyncIterator<{
    items: T[];
    refresh?: (signal: AbortSignal) => Promise<T[]>;
  }>;
  updateRow?(newRow: T): void;
}

export interface Filter {
  name: string;
  key: string;
}

export namespace Filter {
  export function param(default_: string): Param<string> {
    return new StringParam("filter", default_);
  }
}

export namespace Search {
  export function param(): Param<string> {
    return new StringParam("search", "");
  }
}

export const sortEqual: Equal<TableSort> = objectEqual({
  direction: stringEqual,
  key: stringEqual,
});

export function sortParam(
  default_: TableSort,
  sortParamPrefix?: string,
): Param<TableSort> {
  return new ObjectParam({
    key: new StringParam(
      sortParamPrefix ? sortParamPrefix + "_sort" : "sort",
      default_.key,
    ),
    direction: new StringParam(
      sortParamPrefix ? sortParamPrefix + "_direction" : "direction",
      default_.direction,
    ),
  });
}

const alignClasses = new Map<ColumnAlignment, string>();
alignClasses.set(ColumnAlignment.CENTER, tableCss.center);
alignClasses.set(ColumnAlignment.LEFT, tableCss.left);
alignClasses.set(ColumnAlignment.RIGHT, tableCss.right);

export interface CsvColumn<T> {
  cell(record: T): string;
  header: string;
}

export interface FilterComponent<T = string> {
  name: string;
  param: Param<string>;
  render(props: { value: T; setValue(value: T): void }): ReactElement;
}

export type TableProps<T> = {
  onRowClick?: RowClickHandler<T>;
  onRowHovered?: (record?: T) => void;
  fetcher: TableFetcher<T>;
  csv?: CsvColumn<T>[];
  actionButton?: ReactElement;
  columns: Column<T>[];
  filename?: string;
  filterComponents?: FilterComponent[];
  dynamicFilters?: boolean;
  primaryFilterOptions?: Filter[];
  primaryFilterDefault?: string;
  searchEnabled?: boolean;
  searchButton?: ReactNode;
  sortDefault: TableSort;
  passThroughValues?: any;
  paramPrefix?: string;
  alwaysLoadTable?: boolean;
  onQuickDownload?: () => void;
  pageNumber?: number;
  setPageNumber?: (pageNumber: number) => void;
  downloadTriggerRef?: MutableRefObject<() => void>;
  onDownloadStatusChange?: (pending: boolean) => void;
  title?: string;
  subtitle?: string;
  scrollable?: boolean;
  theme?: TableTheme;
  scrollAreaRef: RefObject<HTMLElement | null>;
  header?: ReactElement;
  hideExportButton?: boolean;
  rightAlignExportButton?: boolean;
  externalSort?: TableSort;
  externalSetSort?: (sort: TableSort) => void;
  pageSize?: number;
  EmptyContentMessage?: ReactElement;
  onContextMenu?: RowClickHandler<T>;
};

function TableComponent<T>(
  {
    csv,
    actionButton,
    onRowClick,
    onRowHovered,
    primaryFilterDefault,
    sortDefault,
    fetcher,
    filterComponents = [],
    dynamicFilters = false,
    filename,
    columns,
    primaryFilterOptions,
    searchEnabled = false,
    searchButton,
    passThroughValues = null,
    paramPrefix,
    alwaysLoadTable = false,
    onQuickDownload,
    pageNumber,
    setPageNumber,
    downloadTriggerRef,
    onDownloadStatusChange,
    title,
    subtitle,
    scrollable = false,
    theme = TableTheme.COMPACT,
    scrollAreaRef,
    header,
    hideExportButton = false,
    rightAlignExportButton = false,
    externalSetSort,
    externalSort,
    pageSize,
    EmptyContentMessage,
    onContextMenu,
  }: TableProps<T>,
  ref: ForwardedRef<TableRef<T>>,
) {
  const [filters, setFilters] = useParam<{ [name: string]: string }>(
    new ObjectParam(
      Object.fromEntries(
        filterComponents.map((component) => [component.name, component.param]),
      ),
    ),
    objectEqual(
      Object.fromEntries(
        filterComponents.map((component) => [component.name, stringEqual]),
      ),
    ),
  );
  const [primaryFilter, setPrimaryFilter] = useParam(
    Filter.param(primaryFilterDefault || ""),
  );
  const [search, setSearch] = useParam(Search.param());
  const [internalSort, internalSetSort] = useParam(
    sortParam(sortDefault, paramPrefix),
    sortEqual,
  );

  const sort = useMemo(
    () => externalSort || internalSort,
    [externalSort, internalSort],
  );
  const setSort = useMemo(
    () => externalSetSort || internalSetSort,
    [externalSetSort, internalSetSort],
  );

  const debouncedSearch = useDebounce(search, 500);

  const countLoad = useLoad(
    (signal) => {
      return fetcher.counts(filters, debouncedSearch || undefined, signal);
    },
    [fetcher, debouncedSearch, filters],
  );

  return (
    <section
      className={classnames(tableCss.container, tableThemeClasses[theme])}
    >
      <div
        className={classnames(tableCss.options, tableThemeClasses[theme])}
        style={rightAlignExportButton ? { alignSelf: "flex-end" } : undefined}
      >
        <div className={tableCss.filterContainer}>
          <IterableMap
            items={filterComponents}
            keyFn={(component) => component.name}
          >
            {(component) => {
              function setValue(value: string) {
                setFilters({ ...filters, [component.name]: value });
              }
              return component.render({
                value: filters[component.name],
                setValue,
              });
            }}
          </IterableMap>
        </div>
        {csv && !hideExportButton && (
          <ExportCsv
            csv={csv}
            downloadTriggerRef={downloadTriggerRef}
            fetcher={fetcher}
            filename={filename || "Redo"}
            filters={filters}
            onDownloadStatusChange={onDownloadStatusChange}
            passThroughValues={passThroughValues}
            primaryFilter={primaryFilter}
            search={search}
            sort={sort}
          />
        )}
        {actionButton}
        <div className={tableCss.spacer} />
        {searchEnabled && (
          <TextInput
            button={searchButton}
            icon={
              search ? <XIcon onClick={() => setSearch("")} /> : <SearchIcon />
            }
            onChange={setSearch}
            placeholder="Search"
            value={search}
          />
        )}
      </div>
      <div className={classnames(tableCss.main, tableThemeClasses[theme])}>
        {header && <div className={tableCss.textHeader}>{header}</div>}
        {primaryFilterOptions && (
          <Filters
            dynamicFilters={dynamicFilters}
            filter={primaryFilter}
            filterCounts={countLoad.value}
            filters={primaryFilterOptions}
            setFilter={setPrimaryFilter}
          />
        )}
        {title && typeof title === "string" && (
          <h3 className={tableCss.title}>{title}</h3>
        )}
        {subtitle && typeof subtitle === "string" && (
          <Text mb="2xl">{subtitle}</Text>
        )}
        <TableContent
          alwaysLoadTable={alwaysLoadTable}
          columns={columns}
          EmptyContentMessage={EmptyContentMessage}
          fetcher={fetcher}
          filters={filters}
          header={header}
          onContextMenu={onContextMenu}
          onQuickDownload={onQuickDownload}
          onRowClick={onRowClick}
          onRowHovered={onRowHovered}
          pageNumber={pageNumber !== undefined ? pageNumber : undefined}
          pageSize={pageSize}
          passThroughValues={passThroughValues}
          primaryFilter={primaryFilter}
          ref={ref}
          scrollable={scrollable}
          scrollAreaRef={scrollAreaRef}
          search={debouncedSearch}
          setSort={setSort}
          sort={sort}
          theme={theme}
        />
        {pageNumber !== undefined && setPageNumber && (
          <PageControl
            hasNext={
              countLoad.value
                ? countLoad.value.total >
                  (pageNumber + 1) * (pageSize || DEFAULT_PAGE_SIZE)
                : false
            }
            numPages={
              countLoad.value
                ? Math.ceil(
                    countLoad.value.total / (pageSize || DEFAULT_PAGE_SIZE),
                  )
                : 0
            }
            pageNumber={pageNumber}
            setPageNumber={setPageNumber}
          />
        )}
      </div>
    </section>
  );
}

const PageButton = memo(function PageButton({
  index,
  currentPage,
  setPage,
  isEllipses = false,
}: {
  index: number;
  currentPage: number;
  setPage: (index: number) => void;
  isEllipses?: boolean;
}) {
  return (
    <Button
      disabled={index === currentPage}
      onClick={() => setPage(index)}
      size={ButtonSize.MICRO}
      theme={
        index === currentPage ? ButtonTheme.SOLID_LIGHT : ButtonTheme.GHOST
      }
    >
      {isEllipses ? "..." : index + 1}
    </Button>
  );
});

const PageControl = memo(function PageControl({
  pageNumber,
  numPages,
  setPageNumber,
  hasNext,
}: {
  pageNumber: number;
  numPages: number;
  setPageNumber: (pageNumber: number) => void;
  hasNext: boolean;
}) {
  const renderPageNumbers = (numPages: number, page: number, setPage: any) => {
    const pageNumbers = [];
    for (let i = 0; i < numPages; i++) {
      if (page < 3 && i < 4) {
        pageNumbers.push(
          <PageButton currentPage={page} index={i} setPage={setPage} />,
        );
      } else if (page > numPages - 3 && i > numPages - 5) {
        pageNumbers.push(
          <PageButton currentPage={page} index={i} setPage={setPage} />,
        );
      }
      // Last page/first page
      else if (i === numPages - 1 || i === 0) {
        pageNumbers.push(
          <PageButton currentPage={page} index={i} setPage={setPage} />,
        );
      }
      // Current page/adjacent pages
      else if (i === page - 1 || i === page + 1 || i === page) {
        pageNumbers.push(
          <PageButton currentPage={page} index={i} setPage={setPage} />,
        );
      }
    }

    if (numPages > 5) {
      if (page > 2) {
        const middleIndex = Math.floor(page / 2);
        pageNumbers.splice(
          1,
          0,
          <PageButton
            currentPage={page}
            index={middleIndex}
            isEllipses
            setPage={setPage}
          />,
        );
      }

      if (page < numPages - 3) {
        const offset = page > 2 ? 1 : 3;
        const middleIndex = Math.floor((numPages + page + offset) / 2);
        pageNumbers.splice(
          pageNumbers.length - 1,
          0,
          <PageButton
            currentPage={page}
            index={middleIndex}
            isEllipses
            setPage={setPage}
          />,
        );
      }
    }
    return pageNumbers;
  };

  return (
    <Flex className={tableCss.pageControl} dir="row">
      <Button
        className={tableCss.pageChangeButton}
        disabled={pageNumber === 0}
        icon={LeftArrowIcon}
        onClick={() => setPageNumber(pageNumber - 1)}
        size={ButtonSize.MICRO}
        theme={ButtonTheme.OUTLINED}
      >
        Previous
      </Button>
      <div className={tableCss.pageNumbers}>
        {renderPageNumbers(numPages, pageNumber, setPageNumber)}
      </div>
      <Button
        className={tableCss.pageChangeButton}
        disabled={!hasNext}
        icon={RightArrowIcon}
        iconAlign="right"
        onClick={() => setPageNumber(pageNumber + 1)}
        size={ButtonSize.MICRO}
        theme={ButtonTheme.OUTLINED}
      >
        Next
      </Button>
    </Flex>
  );
});

export interface TableRef<T> {
  refresh: (signal?: AbortSignal) => Promise<void>;
  externalRefresh?: (refreshCallback: (items: T[]) => void) => Promise<void>;
  externalSetItems?: (items: T[]) => void;
  items: T[];
  removeItem: (
    currentItem: T,
    areEqual: (item1: T, item2: T) => boolean,
  ) => void;
}

export const Table = genericMemo(
  forwardRef(TableComponent) as <T>(
    props: TableProps<T> & { ref?: React.ForwardedRef<TableRef<T>> },
  ) => ReturnType<typeof TableComponent>,
);

export const ExportCsv = genericMemo(function ExportCsv<T>({
  csv,
  filename,
  filters,
  primaryFilter,
  fetcher,
  search,
  sort,
  passThroughValues,
  downloadTriggerRef,
  onDownloadStatusChange,
}: {
  csv: CsvColumn<T>[];
  filename: string;
  primaryFilter: string;
  filters: { [name: string]: string };
  fetcher: TableFetcher<T>;
  search: string;
  sort: TableSort;
  passThroughValues: any;
  downloadTriggerRef?: MutableRefObject<() => void>;
  onDownloadStatusChange?: (pending: boolean) => void;
}) {
  const [load, trigger] = useTriggerLoad(async (signal) => {
    const result = [csv.map((column) => column.header)];
    const data = fetcher.data(
      primaryFilter,
      filters,
      search || undefined,
      search ? undefined : sort,
      undefined,
      undefined,
      signal,
      passThroughValues,
    );
    while (true) {
      const results = await data.next();
      if (results?.value?.items?.length) {
        for (const record of results.value.items) {
          result.push(csv.map((column) => column.cell(record)));
        }
      }
      if (results.done) {
        break;
      }
    }
    const blob = new Blob([stringify(result)], { type: "text/csv" });
    await downloadBlob(blob, `${filename}.csv`);
  });

  const onClick = useHandler(() => trigger());
  if (downloadTriggerRef) {
    downloadTriggerRef.current = trigger;
  }

  useEffect(() => {
    if (onDownloadStatusChange) {
      onDownloadStatusChange(load.pending);
    }
    // FIXME
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [load.pending]);

  return !downloadTriggerRef ? (
    <Button
      onClick={onClick}
      pending={load.pending}
      theme={ButtonTheme.PRIMARY}
    >
      Export CSV
    </Button>
  ) : null;
});

const Filters = memo(function Filters({
  filter,
  filterCounts,
  setFilter,
  filters,
  dynamicFilters = false,
}: {
  filter: string;
  filterCounts?: { [key: string]: number };
  setFilter(key: string): void;
  filters: Filter[];
  dynamicFilters?: boolean;
}) {
  if (dynamicFilters && filterCounts) {
    filters = filters.filter((f) => filterCounts[f.key] > 0);
  }
  return (
    <div className={tableCss.filters}>
      <IterableMap items={filters} keyFn={(f) => f.key}>
        {(f) => {
          return (
            <FilterButton
              action={() => setFilter(f.key)}
              active={f.key === filter}
              count={filterCounts && filterCounts[f.key]}
            >
              {f.name}
            </FilterButton>
          );
        }}
      </IterableMap>
    </div>
  );
});

export type TableContentProps<T> = {
  columns: Column<T>[];
  fetcher: TableFetcher<T>;
  primaryFilter: string;
  filters: { [name: string]: string };
  onRowClick?: RowClickHandler<T>;
  onRowHovered?: (record?: T) => void;
  search?: string;
  sort: TableSort;
  pageNumber?: number;
  setSort(value: TableSort): void;
  passThroughValues: any;
  alwaysLoadTable?: boolean;
  onQuickDownload?: () => void;
  scrollable?: boolean;
  theme?: TableTheme;
  scrollAreaRef: RefObject<HTMLElement | null>;
  header?: ReactElement;
  pageSize?: number;
  EmptyContentMessage?: ReactElement;
  onContextMenu?: RowClickHandler<T>;
  refreshSymbol?: symbol;
};

export type YieldType<T> = {
  items: T[];
  refresh?: ((signal: AbortSignal) => Promise<T[]>) | undefined;
  aborted?: boolean;
};
function TableContentComponent<T>(
  {
    columns,
    fetcher,
    primaryFilter,
    filters,
    onRowClick,
    onRowHovered,
    sort,
    search,
    pageNumber,
    setSort,
    passThroughValues,
    alwaysLoadTable = false,
    onQuickDownload,
    scrollable = false,
    theme = TableTheme.COMPACT,
    scrollAreaRef,
    header,
    pageSize,
    EmptyContentMessage,
    onContextMenu,
  }: TableContentProps<T>,
  ref: ForwardedRef<TableRef<T>>,
) {
  const [sizeRef, size] = useElementSize();
  const loadingRows = Math.max(
    1,
    Math.min(Math.floor((size.height - 50) / 42), 25),
  );

  const scrolled = useScrolled(scrollAreaRef.current);
  const [items, setItems] = useState<T[]>([]);
  const [iterator, setIterator] = useState<AsyncIterator<YieldType<T>>>();
  const [started, setStarted] = useState(false);
  const [pending, setPending] = useState(false);
  const [finished, setFinished] = useState(false);
  const [refresh, setRefresh] =
    useState<(signal: AbortSignal) => Promise<T[]>>();

  const abortControllerRef = useRef<AbortController>(new AbortController());

  /**
   * This exists to fix the following race condition:
   * 1. Iterator1 is created
   * 2. Iterator1 is aborted before being called for the first time, and Iterator2 is created
   * 3. The second `useEffect` runs with Iterator1, and it returns "done" even though it was
   *    aborted. As a result, `finished` is set to `true`
   * 4. The second `useEffect` runs with Iterator2, which is more up-to-date,
   *    but its execution is skipped because `finished === true`
   *
   * To solve this, check if the iterator is the latest iterator before trying to fetch more
   * data from it.
   *
   * This fix is a bandaid. I believe the root of the problem is calling async code in
   * `useEffect` that sets shared state (a recipe for race conditions). If you know a better
   * fix, have at it.
   */
  const latestIteratorRef = useRef(iterator);

  useEffect(() => {
    abortControllerRef.current?.abort();
    const newAbort = new AbortController();
    const signal = newAbort.signal;
    const newIterator = fetcher.data(
      primaryFilter,
      filters,
      search || undefined,
      search ? undefined : sort,
      pageSize || DEFAULT_PAGE_SIZE,
      pageNumber,
      signal,
      passThroughValues,
    );
    latestIteratorRef.current = newIterator;
    setIterator(newIterator);
    setPending(false);
    setFinished(false);
    setItems([]);
    abortControllerRef.current = newAbort;
    if (iterator?.return) {
      void iterator.return();
    }
    // FIXME
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    fetcher,
    primaryFilter,
    search,
    sort,
    filters,
    passThroughValues,
    pageNumber,
  ]);

  useEffect(() => {
    async function loadMoreIfNecessary() {
      if (
        (scrolled.bottom || alwaysLoadTable || !started) &&
        !pending &&
        !finished &&
        iterator &&
        iterator === latestIteratorRef.current
      ) {
        setStarted(true);
        setPending(true);
        let done: boolean;
        let value: YieldType<T>;
        let aborted: boolean;
        try {
          const results = await iterator.next();
          done = results.done || false;
          value = results.value;
          aborted = value?.aborted || false;
          if (aborted) return; // Pending has already been restored to false
        } catch (e) {
          setPending(false);
          throw e;
        }
        setPending(false);
        if (done) {
          if (iterator.return) {
            await iterator.return();
          }
          if (!aborted) {
            setFinished(true);
          }
          return;
        }
        setRefresh(() => value.refresh);
        setItems((items) => [...items, ...value.items]);
      }
    }
    void loadMoreIfNecessary();
    // FIXME
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [iterator, scrolled.bottom, pending, finished, started]);

  const refreshData = async () => {
    if (refresh) {
      const reloadedItems = await refresh(abortControllerRef.current.signal);
      setItems(reloadedItems);
    }
  };

  const removeItem = (
    currentItem: T,
    areEqual: (item1: T, item2: T) => boolean,
  ) => {
    const index = items.findIndex((item) => areEqual(item, currentItem));
    if (index === -1) {
      return;
    }
    setItems((items) => {
      const newItems = [...items];
      newItems.splice(index, 1);
      return newItems;
    });
  };

  const externalRefresh = async (setItemsCallback: (items: T[]) => void) => {
    if (refresh) {
      const reloadedItems = await refresh(abortControllerRef.current.signal);
      setItemsCallback(reloadedItems);
    }
  };

  const externalSetItems = (items: T[]) => {
    setItems(items);
  };

  useImperativeHandle<TableRef<T>, TableRef<T>>(ref, () => ({
    items,
    refresh: refreshData,
    removeItem,
    externalRefresh,
    externalSetItems,
  }));

  const itemsToShow = items || [];

  const shouldShowAnEmptyMessage = !pending && !itemsToShow.length;

  const width = sumBy(columns, (column) => column.width);
  return (
    <div className={tableCss.tableContainer} ref={sizeRef}>
      {header && <div className={tableCss.headerSpacer} />}
      {!EmptyContentMessage || !shouldShowAnEmptyMessage ? (
        <table
          className={classnames(
            tableCss.table,
            scrollable ? tableCss.tableFitToParent : undefined,
          )}
          style={{ minWidth: `${width}px` }}
        >
          <thead
            className={classnames(tableCss.headers, tableThemeClasses[theme])}
          >
            <tr className={tableCss.headerRow}>
              <IterableMap items={columns} keyFn={(column) => column.key}>
                {(column) => {
                  function onSort(direction: SortDirection) {
                    setSort({ direction, key: column.key });
                  }
                  return (
                    <ColumnHeader
                      defaultDirection={column.sort}
                      direction={
                        sort.key === column.key ? sort.direction : null
                      }
                      sort={column.sort !== undefined ? onSort : undefined}
                      theme={theme}
                      width={column.width}
                    >
                      {column}
                    </ColumnHeader>
                  );
                }}
              </IterableMap>
            </tr>
          </thead>
          {!!onQuickDownload && (
            <button onClick={onQuickDownload}>
              <DownloadIcon className={tableCss.downloadIcon} />
            </button>
          )}
          <tbody className={tableCss.data}>
            <IterableMap items={itemsToShow} keyFn={(_, index) => index}>
              {(item, idx) => {
                function onClick(event: MouseEvent<HTMLTableRowElement>) {
                  onRowClick?.(item, event, idx);
                }
                function onContextMenuHandler(
                  event: MouseEvent<HTMLTableRowElement>,
                ) {
                  onContextMenu?.(item, event, idx);
                }
                return (
                  <TableRow
                    columns={columns}
                    datum={item}
                    idx={idx}
                    onClick={onClick}
                    onContextMenu={onContextMenuHandler}
                    onRowHovered={onRowHovered}
                    theme={theme}
                  />
                );
              }}
            </IterableMap>
            {pending &&
              [...Array(loadingRows)].map((_, index) => (
                <LoadingRow columns={columns} key={index} />
              ))}
            {!EmptyContentMessage && shouldShowAnEmptyMessage && (
              <Empty columns={columns} />
            )}
          </tbody>
        </table>
      ) : (
        <div style={{ minWidth: `${width}px` }}>{EmptyContentMessage}</div>
      )}
    </div>
  );
}

const TableContent = genericMemo(
  forwardRef(TableContentComponent) as <T>(
    props: TableContentProps<T> & { ref?: React.ForwardedRef<TableRef<T>> },
  ) => ReturnType<typeof TableContentComponent>,
);

const Empty = memo(function Empty({ columns }: { columns: Column<unknown>[] }) {
  return (
    <tr className={tableCss.dataRow}>
      <td
        className={classnames(tableCss.center, tableCss.empty)}
        colSpan={columns.length}
      >
        No matching records
      </td>
    </tr>
  );
});

const LoadingRow = memo(function LoadingRow({
  columns,
}: {
  columns: Column<any>[];
}) {
  return (
    <tr className={tableCss.dataRow}>
      <td
        className={classnames(tableCss.cell, tableCss.compact)}
        colSpan={columns.length}
      >
        <div className={tableCss.cellLoading} />
      </td>
    </tr>
  );
});

interface ColumnHeaderProps {
  defaultDirection?: SortDirection;
  direction: SortDirection | null;
  children: Column<any>;
  sort?(direction: SortDirection): void;
  width: number;
  theme?: TableTheme;
}

const ColumnHeader = memo(function ColumnHeader({
  defaultDirection = SortDirection.ASC,
  direction,
  children,
  sort,
  width,
  theme = TableTheme.COMPACT,
}: ColumnHeaderProps) {
  const className = classnames(
    tableCss.header,
    tableThemeClasses[theme],
    alignClasses.get(children.alignment),
    { [tableCss.active]: direction !== null },
  );
  const sortClassName = classnames(
    tableCss.headerSort,
    alignClasses.get(children.alignment),
    (direction !== undefined ? direction : defaultDirection) ===
      SortDirection.DESC
      ? tableCss.headerSortDesc
      : tableCss.headerSortAsc,
  );

  const onClick = useHandler(() => {
    sort &&
      sort(
        direction == null
          ? defaultDirection
          : direction === SortDirection.ASC
            ? SortDirection.DESC
            : SortDirection.ASC,
      );
  });

  let content: ReactNode;
  if (typeof children.title === "string") {
    const label = (
      <span className={tableCss.headerLabel}>{children.title}</span>
    );
    const ArrowType = theme === TableTheme.WIDE ? ArrowDown : ChevronDown;
    const sort_ = sort && (
      <span className={tableCss.headerSortContainer}>
        <ArrowType className={sortClassName} />
      </span>
    );

    if (children.alignment === ColumnAlignment.RIGHT) {
      content = (
        <>
          {sort_}
          {label}
        </>
      );
    } else {
      content = (
        <>
          {label}
          {sort_}
        </>
      );
    }
  }

  return typeof children.title === "string" ? (
    <th
      className={className}
      style={{ width: `${width}px`, minWidth: `${width}px` }}
    >
      {sort ? (
        <button
          className={classnames(
            tableCss.headerButton,
            tableCss.headerContent,
            tableThemeClasses[theme],
          )}
          onClick={onClick}
        >
          {content}
        </button>
      ) : (
        <div
          className={classNames(
            tableCss.headerContent,
            tableThemeClasses[theme],
          )}
        >
          {content}
        </div>
      )}
    </th>
  ) : (
    <th
      className={tableCss.header}
      style={{ width: `${width}px`, minWidth: `${width}px` }}
    >
      {children.title}
    </th>
  );
});

const FilterButton = memo(function FilterButton({
  action,
  active,
  children,
  count,
}: {
  action: () => void;
  active: boolean;
  children: string;
  count?: number;
}) {
  const ref = useRef<HTMLButtonElement | null>(null);

  const className = classnames(tableCss.filter, { [tableCss.active]: active });
  return (
    <button className={className} onClick={action} ref={ref}>
      <span className={tableCss.filterLabel}>{children}</span>
      {count !== undefined && (
        <span className={tableCss.filterCount}>{count}</span>
      )}
    </button>
  );
});

const TableRow = genericMemo(function TableRow<T>({
  datum,
  columns,
  onClick,
  onContextMenu,
  onRowHovered,
  idx,
  theme = TableTheme.COMPACT,
}: {
  datum: T;
  columns: Column<T>[];
  onClick?(event: MouseEvent<HTMLTableRowElement>): void;
  onContextMenu?(event: MouseEvent<HTMLTableRowElement>): void;
  onRowHovered?(record?: T): void;
  idx: number;
  theme?: TableTheme;
}) {
  return (
    <tr
      className={classnames(tableCss.dataRow, {
        [tableCss.clickable]: onClick,
      })}
      onClick={onClick}
      onContextMenu={onContextMenu}
      onMouseEnter={() => onRowHovered && onRowHovered(datum)}
      onMouseLeave={() => onRowHovered && onRowHovered(undefined)}
    >
      {columns.map((column) => (
        <TableCell
          column={column}
          datum={datum}
          key={column.key}
          rowIdx={idx}
          theme={theme}
        />
      ))}
    </tr>
  );
});

const TableCell = genericMemo(function TableCell<T>({
  datum,
  column,
  rowIdx,
  theme = TableTheme.COMPACT,
}: {
  datum: T;
  column: Column<T>;
  rowIdx: number;
  theme?: TableTheme;
}) {
  const className = classnames(
    tableCss.cell,
    tableThemeClasses[theme],
    alignClasses.get(column.alignment),
  );
  return (
    <td
      className={className}
      onClick={(event) => column.onClick && column.onClick(event, datum)}
    >
      {!!column.onHoverText && !!column.onHoverText(datum) ? (
        <Tooltip placement="top" title={column.onHoverText(datum)}>
          <div>{column.Cell(datum, rowIdx) as ReactElement}</div>
        </Tooltip>
      ) : (
        column.Cell(datum, rowIdx)
      )}
    </td>
  );
});
