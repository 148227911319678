import { BooleanFilterOperator } from "@redotech/redo-model/views/advanced-filters/boolean-filter";
import { memo, useEffect, useState } from "react";
import {
  RedoFilterDropdownAnchor,
  RedoFilterGroup,
} from "../../arbiter-components/filter-group/redo-filter-group";
import { RedoListItem } from "../../arbiter-components/list/redo-list";
import { RedoListItemSize } from "../../arbiter-components/list/redo-list-item";
import { RedoSingleSelectDropdown } from "../../arbiter-components/select-dropdown/redo-single-select-dropdown";
import ChevronDown from "../../arbiter-icon/chevron-down_filled.svg";
import { Flex } from "../../flex";
import { Text } from "../../text";
import { variableToSemanticDisplay } from "../../utils/display-code-variables";
import { BooleanTableFilter } from "../advanced-filter";

export const BooleanFilterGroup = memo(function BooleanFilterGroup({
  filter,
  setFilter,
  removeFilter,
  openOnRender,
}: {
  filter: BooleanTableFilter;
  setFilter(filter: BooleanTableFilter): void;
  removeFilter(): void;
  openOnRender: boolean;
}) {
  const [operatorRef, setOperatorRef] = useState<HTMLButtonElement | null>(
    null,
  );
  const [valueRef, setValueRef] = useState<HTMLButtonElement | null>(null);

  const { name, value, operator } = filter.data;
  const boolDisplay = filter.boolDisplay;

  function getDisplayString(value: boolean | undefined): string {
    if (!boolDisplay) {
      return value ? "true" : "false";
    }
    if (value === true) {
      return boolDisplay.trueDisplay;
    } else if (value === false) {
      return boolDisplay.falseDisplay;
    }
    return "...";
  }

  useEffect(() => {
    if (openOnRender && valueRef) {
      valueRef.click();
    }
  }, [openOnRender, valueRef]);

  const operatorAnchor = (
    <RedoFilterDropdownAnchor
      color="secondary"
      IconTrailing={ChevronDown}
      ref={setOperatorRef}
      text={booleanTypeToText[operator]}
    />
  );

  const operatorDropdown = (
    <RedoSingleSelectDropdown
      dropdownButtonRef={operatorRef}
      options={
        filter.operators && filter.operators.length > 0
          ? booleanDropdownItems.filter((item) =>
              filter?.operators?.includes(item.value),
            )
          : booleanDropdownItems
      }
      optionSelected={(operator: RedoListItem<BooleanFilterOperator>) => {
        setFilter({
          ...filter,
          data: { ...filter.data, operator: operator.value },
        });
      }}
      selectedItem={booleanDropdownItems.find(
        (item) => item.value === operator,
      )}
      size={RedoListItemSize.SMALL}
    >
      {(item) => (
        <Text
          fontSize="sm"
          overflow="hidden"
          textOverflow="ellipsis"
          whiteSpace="nowrap"
        >
          {booleanTypeToText[item.value]}
        </Text>
      )}
    </RedoSingleSelectDropdown>
  );

  const valueText = value == null ? "..." : getDisplayString(value);

  const valueDropdownAnchor = (
    <RedoFilterDropdownAnchor
      color="secondary"
      ref={setValueRef}
      text={valueText}
      tooltip={
        value != null ? (
          <Flex dir="column">
            <Text>{valueText}</Text>
          </Flex>
        ) : undefined
      }
    />
  );

  const itemListOptions: RedoListItem<boolean>[] = [
    { value: true },
    { value: false },
  ];

  const valueDropdown = (
    <RedoSingleSelectDropdown
      dropdownButtonRef={valueRef}
      options={itemListOptions}
      optionSelected={(option) => {
        setFilter({ ...filter, data: { ...filter.data, value: option.value } });
      }}
      selectedItem={value !== null ? { value } : undefined}
      size={RedoListItemSize.SMALL}
    >
      {(item) => (
        <Text
          fontSize="sm"
          overflow="hidden"
          textOverflow="ellipsis"
          whiteSpace="nowrap"
        >
          {getDisplayString(item.value)}
        </Text>
      )}
    </RedoSingleSelectDropdown>
  );

  return (
    <>
      {operatorDropdown}
      {valueDropdown}
      <RedoFilterGroup
        Icon={filter.Icon}
        propertyName={
          filter?.label
            ? filter.label
            : (variableToSemanticDisplay(name) ?? name)
        }
        query={operatorAnchor}
        removeFilter={removeFilter}
        value={valueDropdownAnchor}
      />
    </>
  );
});

const booleanTypeToText: Record<BooleanFilterOperator, string> = {
  [BooleanFilterOperator.IS]: "is",
  [BooleanFilterOperator.IS_NOT]: "is not",
};

const booleanDropdownItems: RedoListItem<BooleanFilterOperator>[] = [
  { value: BooleanFilterOperator.IS },
  { value: BooleanFilterOperator.IS_NOT },
];
