import { ColumnAlignment } from "@redotech/redo-web/table";

import { FulfillmentOrderData } from "@redotech/redo-model/fulfillments/fulfillment-group";
import {
  RedoBadge,
  RedoBadgeColor,
  RedoBadgeSize,
} from "@redotech/redo-web/arbiter-components/badge/redo-badge";
import AlertTriangleIcon from "@redotech/redo-web/arbiter-icon/alert-triangle.svg";
import { Flex } from "@redotech/redo-web/flex";
import { Column2 } from "@redotech/redo-web/table2";
import { Tooltip } from "@redotech/redo-web/tooltip/tooltip";

export const FraudRiskColumn: Column2<FulfillmentOrderData> = {
  alignment: ColumnAlignment.LEFT,
  key: "fraud-risk",
  title: "Fraud Risk",
  renderNormalCell: ({ row }: { row: FulfillmentOrderData }) => {
    const riskFacts = row.risk?.assessments
      .flatMap((assessment) => assessment.facts.map((fact) => fact))
      .filter((fact) => fact?.sentiment === "NEGATIVE")
      .map((fact) => fact?.description)
      .join("\n");

    if (!row.risk) {
      return null;
    }

    return (
      <Flex pl="lg">
        <Tooltip title={riskFacts}>
          <div>
            {row.risk?.assessments.some(
              (assessment) => assessment.riskLevel === "HIGH",
            ) ? (
              <RedoBadge
                color={RedoBadgeColor.ERROR}
                segmentLeading={{ type: "icon", Icon: AlertTriangleIcon }}
                size={RedoBadgeSize.X_SMALL}
                text="High"
              />
            ) : row.risk?.assessments.some(
                (assessment) => assessment.riskLevel === "MEDIUM",
              ) ? (
              <RedoBadge
                color={RedoBadgeColor.WARNING}
                size={RedoBadgeSize.X_SMALL}
                text="Medium"
              />
            ) : null}
          </div>
        </Tooltip>
      </Flex>
    );
  },
  width: 120,
};
