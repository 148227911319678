import * as amplitude from "@amplitude/analytics-browser";
import { ScreenType, useScreenType } from "@redotech/react-util/screen";
import {
  PickupLocation,
  PickupPayer,
  pickupLocationOptions,
} from "@redotech/redo-model/return-flow";
import {
  RedoTimePicker,
  TimeOfDay,
} from "@redotech/redo-web/arbiter-components/time/redo-time-picker";
import { Button, ButtonTheme } from "@redotech/redo-web/button";
import { FormCheckbox } from "@redotech/redo-web/checkbox";
import { CurrencyContext } from "@redotech/redo-web/currency";
import { DateInput } from "@redotech/redo-web/date-picker";
import { getDateString } from "@redotech/redo-web/date-utils";
import PickupTruckIcon from "@redotech/redo-web/icon-old/pickup-truck.svg";
import {
  LabelPosition,
  LabelTheme,
  LabeledInput,
} from "@redotech/redo-web/labeled-input";
import { Modal, ModalSize } from "@redotech/redo-web/modal";
import { SelectDropdown } from "@redotech/redo-web/select-dropdown";
import { InputLines, TextInput } from "@redotech/redo-web/text-input";
import {
  InputProvider,
  groupInput,
  input,
  optionalInput,
} from "@redotech/ui/form";
import { optionalEqual } from "@redotech/util/equal";
import {
  dateToCurrentPlainDate,
  plainDateToCurrentDate,
} from "@redotech/util/temporal";
import * as classNames from "classnames";
import { memo, useContext, useEffect, useMemo } from "react";
import { PickupContext } from "../../../contexts/PickupContext";
import * as review from "../review.module.css";

// TODO: Change on 03/18/2025
const FEDERAL_HOLIDAYS = [
  { month: 1, day: 1 },
  { month: 1, day: 20 },
  { month: 2, day: 17 },
  { month: 5, day: 27 },
  { month: 6, day: 19 },
  { month: 7, day: 4 },
  { month: 9, day: 2 },
  { month: 10, day: 14 },
  { month: 11, day: 11 },
  { month: 11, day: 28 },
  { month: 12, day: 25 },
];

const pickupDetailsForm = groupInput(
  {
    location: input<PickupLocation | undefined>({
      validator: (location) => (location ? [] : ["Required"]),
    }),
    date: input<Temporal.PlainDate | undefined>({
      equal: optionalEqual((a, b) => a.equals(b)),
      validator: (date) => (date !== undefined ? [] : ["Required"]),
    }),
    specialInstructions: input<string>(),
    textReminder: input<boolean>(),
    pickupPayer: input<PickupPayer | undefined>(),
    pickupReadyTime: input<TimeOfDay | undefined>(),
  },
  {
    validator: ({ location, specialInstructions }) =>
      location !== PickupLocation.OTHER || specialInstructions
        ? []
        : ["Instructions required"],
  },
);

type PickupDetails = InputProvider.Value<typeof pickupDetailsForm>;

export const pickupForm = groupInput({
  finalized: input<boolean>({
    validator: (value) => (value ? [] : ["Required"]),
  }),
  pickup: optionalInput(
    pickupDetailsForm,
    (): PickupDetails => ({
      location: undefined,
      date: undefined,
      specialInstructions: "",
      textReminder: false,
      pickupPayer: undefined,
      pickupReadyTime: undefined,
    }),
  ),
});

export type PickupForm = InputProvider.Form<typeof pickupForm>;

const getValidDate = (date: Temporal.PlainDate, days: number) => {
  date = date.add({ days });

  while (!isValidDate(date)) {
    date = date.add({ days: 1 });
  }

  return date;
};

// FedEx does not allow pickups on weekends or holidays
const isValidDate = (date: Temporal.PlainDate) => {
  return (
    date.dayOfWeek !== 7 && date.dayOfWeek !== 6 && !isFederalHoliday(date)
  );
};

const isFederalHoliday = (date: Temporal.PlainDate) => {
  return FEDERAL_HOLIDAYS.some(
    (holiday) => date.day === holiday.day && date.month === holiday.month,
  );
};

const textReminderEligible = (pickupDate: Temporal.PlainDate | undefined) => {
  // We only want to allow users to opt into reminder texts if the pickup is at least 2 days away
  if (!pickupDate) {
    return false;
  }

  const today = Temporal.Now.plainDateISO();
  return today.until(pickupDate).days > 1;
};

export const PickupFormCard = memo(function PickupFormCard({
  upsell,
  labelDeducted,
  setLoading,
  isReviewStep,
  isMerchantDashboard,
  isStatusPage,
  input,
  pending,
  setPending,
  errorMessage,
  showPayer,
  showPickupReadyTimeSelector,
}: {
  upsell?: number | undefined;
  labelDeducted?: boolean;
  setLoading?: (loading?: boolean) => void;
  isReviewStep?: boolean;
  isMerchantDashboard?: boolean;
  isStatusPage?: boolean;
  input?: PickupForm;
  pending?: boolean;
  setPending?: (pending: boolean) => void;
  errorMessage?: string;
  showPayer?: boolean;
  showPickupReadyTimeSelector?: boolean;
}) {
  const { formatCurrency } = useContext(CurrencyContext);
  const today = useMemo(() => Temporal.Now.plainDateISO(), []);

  const minDate = getValidDate(today, 1);
  // FedEx can be scheduled up to 14 days in advance
  const maxDate = getValidDate(today, 14);

  const newInput = useContext(PickupContext).input;
  input = input ?? newInput;

  const { finalized, pickup } = input.inputs;
  const {
    date,
    location,
    specialInstructions,
    textReminder,
    pickupPayer,
    pickupReadyTime,
  } = pickup.input?.inputs ?? {};

  useEffect(() => {
    if (pickup.value?.date === undefined) {
      date.setValue(minDate);
    }
    if (pickup.value?.location === undefined) {
      location.setValue(PickupLocation.FRONT_DOOR);
    }
  }, []);

  return (
    <div className={review.pickupForm}>
      {!isMerchantDashboard && (
        <div className={review.summaryCardItem}>
          <div className={review.summaryText}>
            Your package will be picked up from the pickup address on{" "}
            {isReviewStep ? "the next page." : "this page."}{" "}
            {upsell &&
              (labelDeducted ? (
                <>
                  An additional charge of{" "}
                  <span className={review.price}>{formatCurrency(upsell)}</span>{" "}
                  will be deducted from your refund.
                </>
              ) : (
                <>
                  You will be charged an additional charge of{" "}
                  <span className={review.price}>{formatCurrency(upsell)}</span>
                  .
                </>
              ))}
          </div>
        </div>
      )}
      <div className={classNames(review.summaryCardItem, review.center)}>
        <LabeledInput
          label="Pickup date"
          position={LabelPosition.ABOVE}
          theme={LabelTheme.THIN_BOLD}
        >
          <DateInput
            error={date.allErrors.length > 0}
            filterDate={({ date }) => {
              return isValidDate(dateToCurrentPlainDate(date));
            }}
            includeYearNav={false}
            maxDate={plainDateToCurrentDate(maxDate)}
            minDate={plainDateToCurrentDate(minDate)}
            minDetail="year"
            value={date.value ? plainDateToCurrentDate(date.value) : undefined}
            valueChange={(value) => {
              if (value && isValidDate(dateToCurrentPlainDate(value))) {
                date.setValue(dateToCurrentPlainDate(value));
              } else {
                date.setValue(undefined);
              }
            }}
          />
        </LabeledInput>
      </div>
      {date.allErrors.length > 0 && (
        <div className={classNames(review.summaryCardItem, review.left)}>
          <div className={review.error}>{date.allErrors[0]}</div>
        </div>
      )}
      {showPickupReadyTimeSelector && (
        <LabeledInput
          description="Leave blank if you'd like to use the default pickup ready time for the customer's local service center."
          label="Pickup ready time"
          position={LabelPosition.ABOVE}
          theme={LabelTheme.THIN_BOLD}
        >
          <RedoTimePicker
            setValue={(value: TimeOfDay | null) =>
              pickupReadyTime.setValue(value ?? undefined)
            }
            value={pickupReadyTime.value ?? null}
          />
        </LabeledInput>
      )}
      <LabeledInput
        description={
          <div className={review.summaryCardItem}>
            <div className={review.pickupInputDescription}>
              If {isMerchantDashboard ? "the customer lives" : "you live"} in a
              multi-story building, select an option which is ground level and
              secure.
            </div>
          </div>
        }
        label="Pickup location"
        position={LabelPosition.ABOVE}
        theme={LabelTheme.THIN_BOLD}
      >
        <SelectDropdown
          className={review.pickupLocationDropdown}
          options={[...pickupLocationOptions.keys()]}
          value={location.value}
          valueChange={(value) => location.setValue(value)}
        >
          {(option) => pickupLocationOptions.get(option).label}
        </SelectDropdown>
      </LabeledInput>
      {location.value === PickupLocation.OTHER && (
        <TextInput
          fullwidth
          lines={InputLines.MULTI}
          maxLength={200}
          onChange={(val) => specialInstructions.setValue(val)}
          placeholder="Special instructions"
          value={specialInstructions.value}
        />
      )}
      {errorMessage && (
        <div className={review.summaryCardItem}>
          <div className={review.error}>{errorMessage}</div>
        </div>
      )}
      {isMerchantDashboard && showPayer ? (
        <LabeledInput
          description={
            <div className={review.summaryCardItem}>
              <div className={review.pickupInputDescription}>
                This is who will pay for the pickup fee.
              </div>
            </div>
          }
          label="Payer"
          position={LabelPosition.ABOVE}
          theme={LabelTheme.THIN_BOLD}
        >
          <SelectDropdown
            className={review.pickupLocationDropdown}
            options={[PickupPayer.CUSTOMER_DEDUCT, PickupPayer.MERCHANT]}
            value={pickupPayer.value}
            valueChange={(value) => pickupPayer.setValue(value)}
          >
            {(option) => {
              switch (option) {
                case PickupPayer.CUSTOMER_DEDUCT:
                  return "Customer";
                case PickupPayer.MERCHANT:
                  return "Merchant";
              }
              return undefined;
            }}
          </SelectDropdown>
        </LabeledInput>
      ) : null}
      {!isMerchantDashboard && textReminderEligible(date.value) && (
        <div className={review.reminderTextItem}>
          <FormCheckbox input={textReminder} />
          <span>Send me text reminders and updates about my pickup.</span>
        </div>
      )}
      {!isMerchantDashboard && (
        <Button
          disabled={!!pickup.allErrors.length}
          onClick={() => {
            if (!isMerchantDashboard && !isStatusPage) {
              amplitude.logEvent("pickup-saved", {
                date: date.toString(),
                location: location.value,
                specialInstructions: specialInstructions.value,
                textReminder: pickup.value?.textReminder,
              });
            }
            if (setLoading) {
              setLoading(true);
            }
            if (setPending) {
              setPending(true);
            }
            finalized.setValue(true);
          }}
          pending={pending}
          theme={ButtonTheme.BRAND}
        >
          {`${isStatusPage ? "Reschedule" : "Add"} package pickup`}
        </Button>
      )}
    </div>
  );
});

const BannerUpsale = memo(function BannerUpsale({
  upsell,
}: {
  upsell?: number;
}) {
  const size = useScreenType();
  const { formatCurrency, formatCurrencyFromUSD } = useContext(CurrencyContext);
  const { input } = useContext(PickupContext);

  useEffect(() => {
    amplitude.logEvent("pickup-available", { upsell: upsell });
  }, []);

  return (
    <>
      <div
        className={
          size === ScreenType.MOBILE
            ? review.summaryCardItem
            : review.summaryCardItemHorizontal
        }
      >
        <div className={review.summaryHeader}>
          Leave it on your doorstep. We'll handle the rest.
        </div>
        <div className={review.summaryText}>
          Pack up your return and leave it at your door. We'll take care of it
          for only{" "}
          <span className={review.price}>{formatCurrencyFromUSD(upsell)}</span>.
        </div>
      </div>
      <div className={review.summaryCardItem}>
        <Button
          className={review.pickupButton}
          onClick={() => {
            amplitude.logEvent("pickup-add");
            input.setValue({
              finalized: false,
              pickup: {
                location: undefined,
                date: undefined,
                specialInstructions: "",
                textReminder: false,
                pickupPayer: undefined,
                pickupReadyTime: undefined,
              },
            });
          }}
          theme={ButtonTheme.BRAND}
        >
          Add package pickup
        </Button>
      </div>
    </>
  );
});

export const PickupCard = memo(function PickupCard({
  labelDeducted,
  upsell,
  setLoading,
}: {
  labelDeducted: boolean;
  upsell?: number;
  setLoading: (loading: boolean) => void;
}) {
  const { input } = useContext(PickupContext);

  const { finalized, pickup } = input.inputs;
  const { specialInstructions } = pickup.input?.inputs ?? {};

  const size = useScreenType();

  useEffect(() => {
    if (pickup.value?.location !== PickupLocation.OTHER) {
      specialInstructions?.setValue("");
    }
  }, [pickup.value?.location]);

  if (!pickup.value && finalized.value) {
    return null;
  }

  return (
    <div
      className={
        size === ScreenType.MOBILE
          ? review.summaryCardPickup
          : review.summaryCardHorizonal
      }
    >
      <div className={review.summaryCardItem}>
        <PickupTruckIcon />
      </div>
      {pickup.value && finalized.value && (
        <div
          className={
            size === ScreenType.MOBILE
              ? review.summaryCardItem
              : review.summaryCardItemHorizontal
          }
        >
          <div className={review.summaryTextBold}>Package pickup is added!</div>
          <div className={review.summaryText}>
            Your package will be picked up from the pickup address on this page
            on {getDateString(plainDateToCurrentDate(pickup.value.date))}{" "}
            {pickupLocationOptions.get(pickup.value.location).detail}.
          </div>
          <div className={review.summaryText}>
            You will need to pack up your return and print your shipping label.
          </div>
        </div>
      )}
      {!pickup.value && <BannerUpsale upsell={upsell} />}
      {pickup.value &&
        !finalized.value &&
        {
          [ScreenType.DESKTOP]: (
            <Modal
              onClose={() =>
                input.setValue({ finalized: false, pickup: undefined })
              }
              open
              size={ModalSize.MEDIUM}
              title="Add details to your package pickup"
            >
              <PickupFormCard
                labelDeducted={labelDeducted}
                setLoading={setLoading}
                upsell={upsell}
              />
            </Modal>
          ),
          [ScreenType.MOBILE]: (
            <>
              <div className={review.summaryHeader}>
                Add details to your package pickup
              </div>
              <PickupFormCard
                labelDeducted={labelDeducted}
                setLoading={setLoading}
                upsell={upsell}
              />
              <Button
                onClick={() => {
                  amplitude.logEvent("pickup-cancel");
                  input.setValue({ finalized: false, pickup: undefined });
                }}
              >
                Cancel
              </Button>
            </>
          ),
        }[size]}
    </div>
  );
});
