import { FulfillmentOrderUpdate } from "@redotech/merchant-sdk/outbound-labels-rpc/schema/update-fulfillment-groups";
import { FulfillmentOrderData } from "@redotech/redo-model/fulfillments/fulfillment-group";
import { ColumnAlignment } from "@redotech/redo-web/table";
import { Column2 } from "@redotech/redo-web/table2";
import { NoteCell } from "../cells/note-cell";

export const InternalNotesColumn = (
  handleUpdates: (updates: FulfillmentOrderUpdate[]) => void,
): Column2<FulfillmentOrderData> => ({
  key: "internal-notes",
  title: "Internal Notes",
  renderNormalCell: ({ row }) => (
    <NoteCell
      handleUpdates={handleUpdates}
      isEditable
      kind="internalNote"
      row={row}
    />
  ),
  width: 310,
  alignment: ColumnAlignment.LEFT,
});
