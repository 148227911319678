import { NonIndexRouteObject } from "react-router-dom";

export function inConciergeKnowledgeView(url: string) {
  return url.includes("/concierge/knowledge");
}

export const conciergeSettingsRoutes: NonIndexRouteObject = {
  handle: { breadcrumb: "Concierge", breadcrumbNavigate: false },
  path: "concierge",
  children: [
    {
      handle: { breadcrumb: "Data sources" },
      path: "data-sources",
      lazy: async () => {
        const { ContextSettingsPage } = await import("./context-settings");
        return { Component: ContextSettingsPage };
      },
    },
    {
      path: "general",
      handle: { breadcrumb: "General" },
      lazy: async () => {
        const { GeneralSettingsPage } = await import("./general");
        return { Component: GeneralSettingsPage };
      },
    },
    {
      path: "skills",
      handle: { breadcrumb: "Skills" },
      lazy: async () => {
        const { SkillsSettingsPage } = await import("./skills");
        return { Component: SkillsSettingsPage };
      },
    },
    {
      path: "knowledge",
      handle: { breadcrumb: "Knowledge" },
      lazy: async () => {
        const { ConciergeKnowledgePage } = await import("./knowledge/page");
        return { Component: ConciergeKnowledgePage };
      },
    },
  ],
};
