import Alert from "@mui/material/Alert";
import { useRequiredContext } from "@redotech/react-util/context";
import { useInput } from "@redotech/react-util/form";
import { useHandler } from "@redotech/react-util/hook";
import { useTriggerLoad } from "@redotech/react-util/load";
import type { Return } from "@redotech/redo-model/return";
import { alertOnFailure } from "@redotech/redo-web/alert";
import { Button, ButtonTheme } from "@redotech/redo-web/button";
import { CollapseSubsection } from "@redotech/redo-web/card";
import { RedoClientContext } from "@redotech/redo-web/client";
import { CurrencyContext } from "@redotech/redo-web/currency";
import { Modal, ModalSize } from "@redotech/redo-web/modal";
import { FormTextInput } from "@redotech/redo-web/text-input";
import { groupInput, input } from "@redotech/ui/form";
import { memo, useContext, useState } from "react";
import { refundRecoveryCharge } from "../../client/stripe";
import * as returnCss from "../return.module.css";

export const RefundRecoveryChargeModal = memo(
  function RefundRecoveryChargeModal({
    open,
    onClose,
    returnItem,
    reload,
  }: {
    open: boolean;
    onClose: () => void;
    returnItem: Return;
    reload(): void;
  }) {
    const client = useRequiredContext(RedoClientContext);
    const [error, setError] = useState<string | undefined>(undefined);

    const [refundLoad, doRefund] = useTriggerLoad(() =>
      alertOnFailure("Refunding recovery charge failed")(async () => {
        try {
          await refundRecoveryCharge(client, {
            id: returnItem.id,
            amount: parseFloat(refundAmount.value),
          });
          onClose();
          reload();
          return true;
        } catch (e) {
          setError((e as Error).message);
          return false;
        }
      }),
    );

    const handleRefund = useHandler(() => doRefund());
    const { formatCurrency } = useContext(CurrencyContext);

    const input_ = useInput(
      groupInput({
        refundAmount: input<string>({
          validator: (value) => {
            // Empty string is a valid value - refunds full recovery charge
            if (value === "") {
              return [];
            }

            const errors: string[] = [];
            if (isNaN(+value)) {
              errors.push("Must be a number");
            } else if (+value <= 0) {
              errors.push("Must be greater than $0");
            } else if (
              returnItem.totals.returnCollectionHoldAmount &&
              Math.round(returnItem.totals.returnCollectionHoldAmount * 100) /
                100 <
                +value
            ) {
              errors.push(
                `Must not be greater than ${formatCurrency(
                  returnItem.totals.returnCollectionHoldAmount,
                )}`,
              );
            }
            return errors;
          },
        }),
      }),
      { refundAmount: "" },
    );
    const { refundAmount } = input_.inputs;

    const footer = (
      <>
        <Button
          className={returnCss.modalButton}
          onClick={() => {
            onClose();
          }}
          theme={ButtonTheme.OUTLINED}
        >
          No, go back
        </Button>
        <Button
          className={returnCss.modalButton}
          disabled={!!input_.allErrors.length || returnItem === undefined}
          onClick={handleRefund}
          pending={refundLoad.pending}
          theme={ButtonTheme.DANGER}
        >
          Yes, proceed
        </Button>
      </>
    );

    return (
      <Modal
        footer={footer}
        hideCloseButton={refundLoad.pending}
        onClose={onClose}
        open={open}
        size={ModalSize.SMALL}
        title="Refund Customer for Recovery Charge"
      >
        <p>
          Are you sure you want to refund the customer for the recovery charge?
        </p>

        <CollapseSubsection title="Custom Refund Amount">
          <FormTextInput
            disabled={refundLoad.pending}
            input={refundAmount}
            label=""
            placeholder="Leave empty to refund full recovery charge"
            prefix="$"
            type="number"
          />
        </CollapseSubsection>

        {error && <Alert severity="error">{error}</Alert>}
      </Modal>
    );
  },
);
