import { z } from "zod";

export const getReviewsAverageByProject = {
  input: z.object({
    teamId: z.string(),
    sort: z.object({ direction: z.string(), key: z.string() }).optional(),
    search: z.string().optional(),
  }),
  output: z.array(
    z.object({
      productName: z.string(),
      productDescription: z.string(),
      productImageSrc: z.string(),
      team: z.string(),
      shopifyProductId: z.string(),
      numberOfReviews: z.number(),
      numberOfStars: z.number(),
    }),
  ),
};
