// extracted by mini-css-extract-plugin
export var chevron = "_4e-a";
export var disabled = "_4e-9";
export var icon = "_4e-b";
export var input = "_4e-0";
export var open = "_4e-7";
export var placeholder = "_4e-8";
export var regular = "_4e-3";
export var singleSelectInput = "_4e-1 _4e-0";
export var small = "_4e-2";
export var spinnerContainer = "_4e-c";
export var tag = "_4e-5";
export var tagsContainer = "_4e-6";
export var tagsInput = "_4e-4 _4e-0";