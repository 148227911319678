import { useMaxAvailableHeight } from "@redotech/react-util/available-height";
import { genericMemo } from "@redotech/react-util/component";
import { useVirtualizer } from "@tanstack/react-virtual";
import * as classNames from "classnames";
import { useRef } from "react";
import {
  HeaderColor,
  headerColorStyle,
  RedoTableColumnWidthType,
  RedoTableProps,
  RedoTableSize,
  sizeToTemplateHeaderRowHeight,
  sizeToTemplateRowHeight,
  tableDimensionToGridTemplateStyles,
} from "./redo-table";
import * as redoTableCellsCss from "./redo-table-cells.module.css";
import { RedoTableRow } from "./redo-table-row";
import * as redoTableCss from "./redo-virtual-table.module.css";

// TODO: joe - make this the default table behavior
// A virtual table is just a table that only renders the rows in view + a few rows above and below
export const RedoVirtualTable = genericMemo(function RedoVirtualTable<DATA>({
  size = RedoTableSize.SMALL,
  layout: { columns, headerColor = HeaderColor.GREY },
  rowsOfData,
  onRowClick,
  onContextMenu,
  onRowHovered,
  rowsSelected,
  onVisibleHandlers,
  emptyStateContent = null,
  loadingStateContent = null,
  additionalClasses,
  footerRef,
}: RedoTableProps<DATA> & { footerRef?: React.RefObject<HTMLDivElement> }) {
  const styleForGridColTemplate = columns
    .map((column) =>
      tableDimensionToGridTemplateStyles(
        column.widthMode || { type: RedoTableColumnWidthType.FILL },
      ),
    )
    .join(" ");

  const styleForGridRow = sizeToTemplateRowHeight[size];
  const styleForHeaderRow = sizeToTemplateHeaderRowHeight[size];

  const scrollAreaRef = useRef<HTMLDivElement>(null);
  // TODO @joe - we need to get this working before we migrate other tables to RedoVirtualTable - but nobody is using this feature for now
  // const scrolled = useScrolled(scrollAreaRef.current);
  // useEffect(() => {
  //   if (!scrollAreaRef.current) {
  //     return;
  //   }
  //   if (scrolled.bottom) {
  //     onVisibleHandlers?.setBottomVisible(true);
  //   } else {
  //     onVisibleHandlers?.setBottomVisible(false);
  //   }
  //   if (scrolled.top) {
  //     onVisibleHandlers?.setTopVisible(true);
  //   } else {
  //     onVisibleHandlers?.setTopVisible(false);
  //   }
  // }, [scrolled, onVisibleHandlers]);

  const rowHeightPx = parseInt(styleForGridRow?.toString() || "48");
  const headerRowHeightPx = parseInt(styleForHeaderRow?.toString() || "36");

  const maxAvailableHeight = useMaxAvailableHeight({
    areaRef: scrollAreaRef,
    contentBelowRef: footerRef,
  });

  // We want our container to take up the full available height if there is enough content in it
  const contentHeight = rowHeightPx * rowsOfData.length + headerRowHeightPx;
  const availableHeight = Math.min(contentHeight, maxAvailableHeight);

  // https://tanstack.com/virtual/latest/docs/introduction
  const rowVirtualizer = useVirtualizer({
    count: rowsOfData.length,
    getScrollElement: () => scrollAreaRef.current,
    estimateSize: () => rowHeightPx,
    overscan: 5,
    paddingStart: headerRowHeightPx,
  });

  return (
    <div
      className={classNames(redoTableCss.virtualTableContainer)}
      ref={scrollAreaRef}
      style={{ height: `${availableHeight}px` }}
    >
      <div
        className={classNames(redoTableCss.table, additionalClasses)}
        role="table"
        style={{
          gridTemplateColumns: styleForGridColTemplate,
          gridTemplateRows: styleForHeaderRow,
          gridAutoRows: styleForGridRow,
          height: `${rowVirtualizer.getTotalSize()}px`,
        }}
      >
        <div
          className={classNames(redoTableCss.rowWrapper)}
          key="header"
          role="row"
        >
          {columns.map((column, columnIndex) => (
            <div
              className={classNames(
                redoTableCellsCss.cell,
                redoTableCss.headerCell,
                headerColorStyle[headerColor],
              )}
              key={`header-${column.key ?? columnIndex}`}
              role="columnheader"
              style={{ height: styleForHeaderRow }}
            >
              <column.renderHeaderCell />
            </div>
          ))}
        </div>
        {rowVirtualizer.getVirtualItems().map((virtualRow) => {
          return (
            <div
              className={classNames(redoTableCss.virtualRowWrapper)}
              key={`row-${virtualRow.index}`}
              style={{
                transform: `translateY(${virtualRow.start}px)`,
                gridTemplateColumns: styleForGridColTemplate,
                height: `${rowHeightPx}px`,
              }}
            >
              <RedoTableRow<DATA>
                columns={columns}
                key={`row-${virtualRow.index}`}
                onContextMenu={onContextMenu}
                onRowClick={onRowClick}
                onRowHovered={onRowHovered}
                row={rowsOfData[virtualRow.index]}
                rowIndex={virtualRow.index}
                rowSelected={rowsSelected?.[virtualRow.index]}
              />
            </div>
          );
        })}
      </div>
      {emptyStateContent}
      {loadingStateContent}
    </div>
  );
});
