import { FulfillmentOrderData } from "@redotech/redo-model/fulfillments/fulfillment-group";
import { SortDirection } from "@redotech/redo-model/tables/table";
import { RedoTableTextCell } from "@redotech/redo-web/arbiter-components/tables/redo-table-cells";
import { ColumnAlignment } from "@redotech/redo-web/table";
import { Column2 } from "@redotech/redo-web/table2";

export const StateColumn: Column2<FulfillmentOrderData> = {
  key: "state",
  title: "State",
  renderNormalCell: ({ row }: { row: FulfillmentOrderData }) => (
    <RedoTableTextCell text={row.destinationAddress.province ?? ""} />
  ),
  width: 100,
  sort: SortDirection.DESC,
  alignment: ColumnAlignment.LEFT,
};
