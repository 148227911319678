// extracted by mini-css-extract-plugin
export var brand = "_4q-4";
export var compact = "_4q-7";
export var extraSmall = "_4q-8";
export var icon = "_4q-0";
export var large = "_4q-1";
export var medium = "_4q-2";
export var outline = "_4q-9";
export var primaryDark = "_4q-5";
export var primaryLight = "_4q-3";
export var primaryModern = "_4q-6";