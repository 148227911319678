import { assertNever } from "@redotech/util/type";

export enum WebRTCCallState {
  NEW = "new",
  TRYING = "trying",
  REQUESTING = "requesting",
  RECOVERING = "recovering",
  RINGING = "ringing",
  ANSWERING = "answering",
  EARLY = "early",
  ACTIVE = "active",
  HELD = "held",
  HANGUP = "hangup",
  DESTROY = "destroy",
  PURGE = "purge",
}

export enum CallDirection {
  INBOUND = "inbound",
  OUTBOUND = "outbound",
}

export enum RingingStrategy {
  ROUND_ROBIN = "round-robin",
  RING_TEAM = "ring-team",
}

interface DayAvailability {
  enabled?: boolean | null;
  start?: string | null;
  end?: string | null;
}

export enum SupportVoiceCustomAudioType {
  TTS = "tts",
  URL = "url",
}
export interface SupportVoiceCustomAudio {
  type: SupportVoiceCustomAudioType;
  ttsMessage: string;
  audioUrl: string;
  audioName: string;
}

export interface TeamVoiceConfiguration {
  enabled: boolean;
  ringingStrategy: RingingStrategy;
  sipConnectionId?: string | null;
  sipConnectionCredential?: string | null;
  outboundVoiceProfileId?: string | null;
  billingGroupId?: string | null;
  playAutomatedMessageWhenAllAgentsAreOffline: boolean;
  availableHoursEnabled?: boolean | null;
  availableHoursTimezone?: string | null;
  availableHours?: {
    sunday?: DayAvailability | null;
    monday?: DayAvailability | null;
    tuesday?: DayAvailability | null;
    wednesday?: DayAvailability | null;
    thursday?: DayAvailability | null;
    friday?: DayAvailability | null;
    saturday?: DayAvailability | null;
  } | null;
  inboundGreetingMessage: SupportVoiceCustomAudio;
  outboundGreetingMessage: SupportVoiceCustomAudio;
  offHoursMessage: SupportVoiceCustomAudio;
  voicemailMessage: SupportVoiceCustomAudio;
  holdMusicUrl: string;
  holdMusicName: string;
}

export interface CustomerCallData {
  customerId: string;
  customerCallControlId: string;
  customerNumber: string;
}

// ===== moved from redo/web/src/voice/types.ts =====

export enum UserCallAvailability {
  AVAILABLE = "available",
  IN_CALL = "in-call",
  UNAVAILABLE = "unavailable",
}

export enum CallState {
  RINGING = "ringing",
  ACTIVE = "active",
  CONNECTING = "connecting",
  HANGUP = "hangup",
}

export const displayUserCallAvailability = (
  availability: UserCallAvailability,
) => {
  switch (availability) {
    case UserCallAvailability.AVAILABLE:
      return "Available";
    case UserCallAvailability.IN_CALL:
      return "In call";
    case UserCallAvailability.UNAVAILABLE:
      return "Unavailable";
    default:
      assertNever(availability);
  }
};

export type UserAvailabilities = {
  userId: string;
  availability: UserCallAvailability;
}[];

export interface VoiceAgent {
  name: string;
  imageUrl: string | null;
}

export interface ActiveCall {
  state: CallState.ACTIVE;
  direction: CallDirection;
  startTime: Date;
  remoteCallerNumber: string;
  remoteCallerName?: string;
  agents: [VoiceAgent, ...VoiceAgent[]];
}

export interface RingingCall {
  state: CallState.RINGING;
  direction: CallDirection;
  remoteCallerNumber: string;
  remoteCallerName?: string;
  agents: [VoiceAgent, ...VoiceAgent[]];
}

export interface ConnectingCall {
  state: CallState.CONNECTING;
  direction: CallDirection;
  remoteCallerNumber: string;
  remoteCallerName?: string;
  agents: [VoiceAgent, ...VoiceAgent[]];
}

export interface HangingUpCall {
  state: CallState.HANGUP;
  direction: CallDirection;
  remoteCallerNumber: string;
  remoteCallerName?: string;
  agents: [VoiceAgent, ...VoiceAgent[]];
}

export enum SearchByOption {
  STATE_PROVINCE = "stateProvince",
  AREA_CODE = "areaCode",
  CITY_REGION = "cityRegion",
}

export type TelnyxPhoneNumberRecord = {
  best_effort: boolean;
  cost_information: {
    currency: string;
    monthly_cost: string;
    upfront_cost: string;
  };
  features: { name: string }[];
  phone_number: string;
  phone_number_type: string;
  quickship: boolean;
  record_type: string;
  region_information: { region_name: string; region_type: string }[];
  reservable: boolean;
  vanity_format: string | null;
};

// WIP
export type Call = RingingCall | ActiveCall | ConnectingCall | HangingUpCall;

export type NonActiveCall = Exclude<Call, ActiveCall>;
