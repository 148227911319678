import { zExt } from "@redotech/rpc/ext";
import { z } from "zod";
import {
  FONT_FAMILIES,
  OrderItemSort,
  OrderItemTableColumnType,
  PackingSlipBlockType,
} from "./packing-slip-builder";

const baseSectionSchema = z.object({
  sectionPadding: z.object({
    top: z.number(),
    right: z.number(),
    bottom: z.number(),
    left: z.number(),
  }),
  blockId: zExt.objectId(),
});

const billingAddressSchema = baseSectionSchema.extend({
  type: z.literal(PackingSlipBlockType.BILLING_ADDRESS),
  fontFamily: z.enum(FONT_FAMILIES),
  fontSize: z.number(),
  textColor: z.string(),
});

const companyAddressSchema = baseSectionSchema.extend({
  type: z.literal(PackingSlipBlockType.COMPANY_ADDRESS),
  fontFamily: z.enum(FONT_FAMILIES),
  fontSize: z.number(),
  textColor: z.string(),
});

const contactInformationSchema = baseSectionSchema.extend({
  type: z.literal(PackingSlipBlockType.CONTACT_INFORMATION),
  fontFamily: z.enum(FONT_FAMILIES),
  fontSize: z.number(),
  textColor: z.string(),
  showName: z.boolean(),
  showAddress: z.boolean(),
  showPhone: z.boolean(),
  showEmail: z.boolean(),
});

const orderBarcodeSchema = baseSectionSchema.extend({
  type: z.literal(PackingSlipBlockType.ORDER_BARCODE),
});

const orderDateSchema = baseSectionSchema.extend({
  type: z.literal(PackingSlipBlockType.ORDER_DATE),
  fontFamily: z.enum(FONT_FAMILIES),
  fontSize: z.number(),
  textColor: z.string(),
});

const orderItemsSchema = baseSectionSchema.extend({
  type: z.literal(PackingSlipBlockType.ORDER_ITEMS),
  sort: z.nativeEnum(OrderItemSort),
  tableColumns: z.array(
    z.object({
      type: z.nativeEnum(OrderItemTableColumnType),
      enabled: z.boolean(),
    }),
  ),
});

const orderDetailsSchema = baseSectionSchema.extend({
  type: z.literal(PackingSlipBlockType.ORDER_DETAILS),
  showRecipientInformation: z.boolean(),
  showOrderNumber: z.boolean(),
  showOrderDate: z.boolean(),
  showShippingService: z.boolean(),
});

const paymentBreakdownSchema = baseSectionSchema.extend({
  type: z.literal(PackingSlipBlockType.PAYMENT_BREAKDOWN),
  fontFamily: z.enum(FONT_FAMILIES),
  fontSize: z.number(),
  textColor: z.string(),
});

const paymentMethodSchema = baseSectionSchema.extend({
  type: z.literal(PackingSlipBlockType.PAYMENT_METHOD),
  showSubtotal: z.boolean(),
  showPaymentMethod: z.boolean(),
  showPaymentDate: z.boolean(),
});

const packageWeightSchema = baseSectionSchema.extend({
  type: z.literal(PackingSlipBlockType.PACKAGE_WEIGHT),
  fontFamily: z.enum(FONT_FAMILIES),
  fontSize: z.number(),
  textColor: z.string(),
});

const returnPolicySchema = baseSectionSchema.extend({
  type: z.literal(PackingSlipBlockType.RETURN_POLICY),
  fontFamily: z.enum(FONT_FAMILIES),
  fontSize: z.number(),
  textColor: z.string(),
});

const shippingAddressSchema = baseSectionSchema.extend({
  type: z.literal(PackingSlipBlockType.SHIPPING_ADDRESS),
  fontFamily: z.enum(FONT_FAMILIES),
  fontSize: z.number(),
  textColor: z.string(),
});

const shippingMethodSchema = baseSectionSchema.extend({
  type: z.literal(PackingSlipBlockType.SHIPPING_METHOD),
  fontFamily: z.enum(FONT_FAMILIES),
  fontSize: z.number(),
  textColor: z.string(),
});

const trackingNumberSchema = baseSectionSchema.extend({
  type: z.literal(PackingSlipBlockType.TRACKING_NUMBER),
  fontFamily: z.enum(FONT_FAMILIES),
  fontSize: z.number(),
  textColor: z.string(),
});

const textSchema = baseSectionSchema.extend({
  type: z.literal(PackingSlipBlockType.TEXT),
  textColor: z.string(),
  fontSize: z.number(),
  fontFamily: z.enum(FONT_FAMILIES),
  sectionColor: z.string(),
  linkColor: z.string(),
  text: z.string(),
});

const lineSchema = baseSectionSchema.extend({
  type: z.literal(PackingSlipBlockType.LINE),
  color: z.string(),
  sectionColor: z.string(),
});

const spacerSchema = baseSectionSchema.extend({
  type: z.literal(PackingSlipBlockType.SPACER),
  sectionColor: z.string(),
  height: z.number(),
});

const imageSchema = baseSectionSchema.extend({
  type: z.literal(PackingSlipBlockType.IMAGE),
  imageUrl: z.string(),
  sectionColor: z.string(),
  showCaption: z.boolean(),
  caption: z.string().optional(),
  altText: z.string().optional(),
  clickthroughUrl: z.string().optional(),
  aspectRatio: z.number().optional(),
});

const columnSchema = baseSectionSchema.extend({
  type: z.literal(PackingSlipBlockType.COLUMN),
  columns: z.array(z.lazy(() => sectionWithoutColumnSchema)),
  columnCount: z.number(),
  sectionColor: z.string(),
  gap: z.number(),
});

const sectionWithoutColumnSchema = z.discriminatedUnion("type", [
  billingAddressSchema,
  companyAddressSchema,
  contactInformationSchema,
  orderBarcodeSchema,
  orderDateSchema,
  orderItemsSchema,
  orderDetailsSchema,
  paymentBreakdownSchema,
  paymentMethodSchema,
  packageWeightSchema,
  returnPolicySchema,
  shippingAddressSchema,
  shippingMethodSchema,
  trackingNumberSchema,
  textSchema,
  lineSchema,
  spacerSchema,
  imageSchema,
]);

const sectionSchema = z.discriminatedUnion("type", [
  ...sectionWithoutColumnSchema.options,
  columnSchema,
]);

export const packingSlipSchema = z.object({
  _id: zExt.objectId(),
  name: z.string(),
  sections: z.array(sectionSchema),
  team: zExt.objectId(),
});

export type PackingSlip = z.infer<typeof packingSlipSchema>;
