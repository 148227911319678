import { zExt } from "@redotech/redo-model/common/zod-util";
import { baseDiscountSchema } from "@redotech/redo-model/discount/discount-db-parser";
import { z } from "zod";

export const getDiscount = {
  input: z.object({ _id: zExt.objectId() }),
  output: z.intersection(
    z.object({ _id: zExt.objectId() }),
    baseDiscountSchema,
  ),
};
