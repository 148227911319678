/**
 * @file
 * @see {@link https://steventsnyder.com/reading-a-dymo-usb-scale-using-python/ Reading a Dymo USB scale using Python}
 */

import { Weight, WeightUnit } from "./weight";

export const DYMO_VENDOR_ID = 0x0922;

const DATA_MODE_GRAMS = 0x02;
const DATA_MODE_OUNCES = 0x0b;

export function dymoReadWeight(buffer: ArrayBuffer): Weight {
  if (buffer.byteLength !== 5) {
    throw new Error(`Expected byte length ${buffer.byteLength}`);
  }
  const array = new Uint8Array(buffer);
  const value = array[3]! + (array[4]! << 8);
  switch (array[1]) {
    case DATA_MODE_GRAMS:
      return new Weight(value, WeightUnit.GRAM);
    case DATA_MODE_OUNCES:
      return new Weight(value / 10, WeightUnit.OUNCE);
  }
  throw new Error(`Unknown data mode ${array[1]}`);
}
