import { Order } from "./order";
import { Team } from "./team";

export const REDO_RETURNS_APP_URL_LOCAL = "http://localhost:3002";

interface CustomerAuth {
  token?: string;
  customer?: { email?: string; name?: string };
}

export function buildPortalUrl(
  team: Team,
  order: Order,
  auth: CustomerAuth,
  fulfillmentIdx?: number,
): URL {
  const domain = team.portal.domain || team.storeUrl;
  const path = team.portal.pathPrefix || "/apps/redo";

  const url =
    process.env.NODE_ENV === "production"
      ? new URL(`https://${domain}${path}/returns-portal`)
      : new URL(
          `${REDO_RETURNS_APP_URL_LOCAL}/widget_id/${team.widget_slug}/returns-portal`,
        );

  const address = {
    street1: order.shopify.shipping_address?.address1,
    street2: order.shopify.shipping_address?.address2 || "",
    city: order.shopify.shipping_address?.city,
    state: order.shopify.shipping_address?.province,
    country: order.shopify.shipping_address?.country_code,
    zip: order.shopify.shipping_address?.zip,
  };

  url.searchParams.set(
    "customer",
    JSON.stringify({ ...auth.customer, address: JSON.stringify(address) }),
  );
  if (auth.token) {
    url.searchParams.set("token", auth.token);
  }

  let finalDestination = `/returns-portal/orders/${order._id}`;
  if (fulfillmentIdx !== undefined) {
    finalDestination += `/track/${fulfillmentIdx}`;
  }
  url.searchParams.set("next", finalDestination);

  return url;
}
