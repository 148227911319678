import { z } from "zod";

export const upsertDemoConciergeKnowledge = {
  input: z.object({
    _id: z.string().optional(),
    title: z.string(),
    content: z.string(),
  }),
  output: z.object({ _id: z.string() }),
};
