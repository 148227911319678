import CheckCircleIcon from "@redotech/redo-web/arbiter-icon/check-circle.svg";
import { Button, ButtonTheme } from "@redotech/redo-web/button";
import { Modal, ModalSize } from "@redotech/redo-web/modal";
import { Text } from "@redotech/redo-web/text";
import { memo } from "react";
import * as returnCss from "../return.module.css";

export const DropshipConfirmationModal = memo(
  function DropshipConfirmationModal({
    open,
    onAccept,
    onClose,
  }: {
    open: boolean;
    onAccept(): void;
    onClose(): void;
  }) {
    const footer = (
      <>
        <Button
          className={returnCss.modalButton}
          onClick={onClose}
          theme={ButtonTheme.OUTLINED}
        >
          Cancel
        </Button>
        <Button
          className={returnCss.modalButton}
          onClick={onAccept}
          theme={ButtonTheme.PRIMARY}
        >
          I have an RMA
        </Button>
      </>
    );
    return (
      <Modal
        footer={footer}
        onClose={onClose}
        open={open}
        size={ModalSize.SMALL}
        title=""
        TitleIcon={CheckCircleIcon}
      >
        <Text fontSize="xl" fontWeight="bold">
          Dropshipper return
        </Text>

        <Text fontWeight="regular" textColor="secondary">
          This return contains item(s) that will be shipped by a dropshipper. Do
          you have an RMA for the return?
        </Text>
      </Modal>
    );
  },
);
