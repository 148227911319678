import { zExt } from "@redotech/rpc/ext";
import { z } from "zod";
import { productFilterSchema } from "./create-product-filter";

export const getProductFiltersByTeam = {
  input: z.object({ teamId: z.string() }),
  output: z.array(
    z.intersection(z.object({ _id: zExt.objectId() }), productFilterSchema),
  ),
};
