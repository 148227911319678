import { GetUser } from "@redotech/redo-model/user";
import { UserNotificationSettings } from "@redotech/redo-model/user/user-notification-settings";
import { RedoMerchantClient } from ".";

/**
 * GET /user
 */
export async function getUser(
  client: RedoMerchantClient,
  { signal }: { signal?: AbortSignal },
): Promise<GetUser> {
  const response = await client.client.get("user", {
    headers: client.authorization(),
    signal,
  });
  return response.data;
}

/**
 * GET /user/:id
 */
export async function getUserById(
  client: RedoMerchantClient,
  { id, signal }: { id: string; signal?: AbortSignal },
): Promise<GetUser> {
  const response = await client.client.get(`user/${id}`, {
    headers: client.authorization(),
    signal,
  });
  return response.data;
}

/**
 * Gets a list of users by their IDs.
 * GET /user/users
 * @returns
 */
export async function getUsers(
  client: RedoMerchantClient,
  { userIds, signal }: { userIds: string[]; signal?: AbortSignal },
): Promise<GetUser[]> {
  const response = await client.client.get("user/users", {
    headers: client.authorization(),
    params: { userIds },
    signal,
  });
  return response.data;
}

/**
 * PUT /user
 */
export async function putUserProfile(
  client: RedoMerchantClient,
  { user, signal }: { user: GetUser; signal?: AbortSignal },
) {
  const response = await client.client.put("user", user, {
    headers: client.authorization(),
    signal,
  });
  return response.data;
}

/**
 * PUT /user/:id/notifications
 */
export async function setUserNotificationSettings(
  client: RedoMerchantClient,
  {
    userId,
    notifications,
    signal,
  }: {
    userId: string;
    notifications: UserNotificationSettings;
    signal?: AbortSignal;
  },
) {
  const response = await client.client.put(
    `user/${userId}/notifications`,
    notifications,
    { headers: client.authorization(), signal },
  );
  return response.data;
}

/**
 * PUT /user/:id/support-view-ordering
 */
export async function updateUserViewOrdering(
  client: RedoMerchantClient,
  {
    userId,
    customViewOrdering,
    signal,
  }: {
    userId: string;
    customViewOrdering: { viewName?: string; view?: string }[] | null;
    signal?: AbortSignal;
  },
) {
  const response = await client.client.put(
    `user/${userId}/support-view-ordering`,
    { customViewOrdering },
    { headers: client.authorization(), signal },
  );
  return response.data;
}
