import {
  ConversationPlatform,
  ExpandedConversation,
  MessageVisibility,
} from "@redotech/redo-model/conversation";
import { RedoButton } from "@redotech/redo-web/arbiter-components/buttons/redo-button";
import PaperclipIcon from "@redotech/redo-web/arbiter-icon/paperclip_filled.svg";
import { Flex } from "@redotech/redo-web/flex";
import EnvelopeIcon from "@redotech/redo-web/icon-old/mail.svg";
import PercentIcon from "@redotech/redo-web/icon-old/percent.svg";
import ShoppingBagIcon from "@redotech/redo-web/icon-old/shopping-bag.svg";
import { QuillToolbarOptions } from "@redotech/redo-web/quill/quill-toolbar-options";
import { QuillToolbarUploadFile } from "@redotech/redo-web/quill/quill-toolbar-upload-file";
import { Tooltip } from "@redotech/redo-web/tooltip/tooltip";
import { isUserOnMac } from "@redotech/util/browser-agent";
import { ChangeEvent, memo, useState } from "react";
import { ConvertChatToEmailModal } from "../convert-chat-to-email-modal";
import { AutocompleteType } from "../support-message-autocomplete";
import { getHotkeyTooltip } from "./message-input-footer";
import * as messageInputCss from "./message-input.module.css";

export const MessageInputToolbarHeader = memo(
  function MessageInputToolbarHeader({
    conversation,
    visibility,
    metaSendType,
    toolbarSelector,
    handleUpload,
    setTriggerOpenAutocompleteMenu,
  }: {
    conversation: ExpandedConversation;
    visibility: MessageVisibility;
    metaSendType: string;
    toolbarSelector: string;
    handleUpload: (data: {
      event?: ChangeEvent<HTMLInputElement>;
      file?: File;
    }) => Promise<void>;
    setTriggerOpenAutocompleteMenu: (type: AutocompleteType) => void;
  }) {
    const [convertChatToEmailModalOpen, setConvertChatToEmailModalOpen] =
      useState(false);
    const isMac = isUserOnMac();

    const clearInput = (e: any) => {
      // This makes the onChange always get triggered.
      e.target.value = "";
    };

    return (
      <>
        <Flex
          align="center"
          className={messageInputCss.wrappingtext}
          flex="1 1 0%"
          gap="xs"
        >
          {(visibility === MessageVisibility.INTERNAL ||
            metaSendType !== "forbidden") && (
            <QuillToolbarOptions
              additionalToolbarItems={[
                {
                  icon: PaperclipIcon,
                  buttonTitle: "Attach file",
                  identifier: "attach-file",
                  element: (
                    <QuillToolbarUploadFile
                      clearInput={clearInput}
                      handleUpload={handleUpload}
                    />
                  ),
                },
                {
                  icon: PercentIcon,
                  buttonTitle: "Discount code",
                  identifier: "discount-code",
                  tooltipWhenCollapsed: getHotkeyTooltip(
                    AutocompleteType.DISCOUNT_CODE,
                    isMac,
                  ),
                  element: (
                    <Tooltip
                      arrow
                      title={getHotkeyTooltip(
                        AutocompleteType.DISCOUNT_CODE,
                        isMac,
                      )}
                    >
                      <div>
                        <RedoButton
                          buttonId="discount-code"
                          IconLeading={PercentIcon}
                          onClick={() => {
                            setTriggerOpenAutocompleteMenu(
                              AutocompleteType.DISCOUNT_CODE,
                            );
                          }}
                        />
                      </div>
                    </Tooltip>
                  ),
                },
                {
                  icon: ShoppingBagIcon,
                  buttonTitle: "Add product",
                  identifier: "add-product",
                  tooltipWhenCollapsed: getHotkeyTooltip(
                    AutocompleteType.PRODUCT,
                    isMac,
                  ),
                  element: (
                    <Tooltip
                      arrow
                      title={getHotkeyTooltip(AutocompleteType.PRODUCT, isMac)}
                    >
                      <div>
                        <RedoButton
                          buttonId="add-product"
                          IconLeading={ShoppingBagIcon}
                          onClick={() => {
                            setTriggerOpenAutocompleteMenu(
                              AutocompleteType.PRODUCT,
                            );
                          }}
                        />
                      </div>
                    </Tooltip>
                  ),
                },
                ...(conversation.platform === ConversationPlatform.REDO_CHAT
                  ? [
                      {
                        icon: EnvelopeIcon,
                        buttonTitle: "Convert chat to email",
                        identifier: "chat-to-email",
                        tooltipWhenCollapsed: "Convert chat to email",
                        element: (
                          <Tooltip arrow title="Convert chat to email">
                            <div>
                              <RedoButton
                                buttonId="chat-to-email"
                                IconLeading={EnvelopeIcon}
                                onClick={() => {
                                  setConvertChatToEmailModalOpen(true);
                                }}
                              />
                            </div>
                          </Tooltip>
                        ),
                      },
                    ]
                  : []),
              ]}
              emailMode={conversation.platform === ConversationPlatform.EMAIL}
              toolbarId={toolbarSelector}
            />
          )}
        </Flex>
        <ConvertChatToEmailModal
          conversation={conversation}
          open={convertChatToEmailModalOpen}
          setOpen={(val: boolean) => setConvertChatToEmailModalOpen(val)}
        />
      </>
    );
  },
);
