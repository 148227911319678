// extracted by mini-css-extract-plugin
export var arrows = "_7c-5";
export var decrementer = "_7c-9";
export var disabled = "_7c-3";
export var error = "_7c-1";
export var hiddenText = "_7c-a";
export var incrementer = "_7c-8";
export var input = "_7c-7";
export var inputWrapper = "_7c-0";
export var readonly = "_7c-2";
export var regular = "_7c-6";
export var small = "_7c-4";