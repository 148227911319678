import { z } from "zod";

export const getBundlesData = {
  input: z.object({ teamId: z.string() }),
  output: z.object({
    bundles: z.array(
      z.object({
        parentProductName: z.string(),
        parentProductImageSrc: z.string(),
        parentVariantId: z.string(),
        parts: z.array(
          z.object({
            variantId: z.string(),
            returnable: z.boolean(),
            imageSrc: z.string(),
            productName: z.string(),
          }),
        ),
      }),
    ),
  }),
};
