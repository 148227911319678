import { z } from "zod";

export const addConversationTrainingLabel = {
  input: z.object({
    trainingSetId: z.string(),
    conversationId: z.string(),
    messageId: z.string(),
    label: z.string(),
    notes: z.string(),
  }),
  output: z.null(),
};
