import type { InferRpcDefinition } from "@redotech/rpc/definition";
import {
  getCampaignRecipientsCount,
  paginateCampaignRecipients,
} from "./schema/campaigns/campaign-recipient";
import { createCampaign } from "./schema/campaigns/create-campaign";
import { deleteCampaign } from "./schema/campaigns/delete-campaign";
import { duplicateCampaign } from "./schema/campaigns/duplicate-campaign";
import { getCampaignById } from "./schema/campaigns/get-campaign-by-id";
import { getCustomerGroups } from "./schema/campaigns/get-customer-groups";
import { getTeamCampaigns } from "./schema/campaigns/get-team-campaigns";
import { previewEmailTemplate } from "./schema/campaigns/preview-email-template";
import { sendTestCampaignSMS } from "./schema/campaigns/send-test-campaign-sms";
import { updateCampaign } from "./schema/campaigns/update-campaign";
import { createDiscount } from "./schema/discounts/create-discount";
import { deleteDiscount } from "./schema/discounts/delete-discount";
import { getDiscount } from "./schema/discounts/get-discount";
import { getDiscountsByTeam } from "./schema/discounts/get-discounts-by-team";
import { getOutreachAnalytics } from "./schema/get-outreach-analytics";
import { getTeamPhoneNumber } from "./schema/get-team-phone-number-schema";
import { createProductFilter } from "./schema/product-filters/create-product-filter";
import { deleteProductFilter } from "./schema/product-filters/delete-product-filter";
import { getProductFilterById } from "./schema/product-filters/get-product-filter-by-id";
import { getProductFiltersByTeam } from "./schema/product-filters/get-product-filters-by-team";
import { updateProductFilter } from "./schema/product-filters/update-product-filter";
import { createDynamicSegment } from "./schema/segments/create-dynamic-segment";
import { deleteSegment } from "./schema/segments/delete-segment";
import { fetchSegmentById } from "./schema/segments/fetch-segment-by-id";
import { fetchTeamSegments } from "./schema/segments/fetch-team-segments";
import { getEstimatedCampaignRecipientCount } from "./schema/segments/get-estimated-campaign-recipient-count";
import { getSegmentCollections } from "./schema/segments/get-segment-collections";
import { getSegmentCount } from "./schema/segments/get-segment-count";
import { getSegmentProductTags } from "./schema/segments/get-segment-product-tags";
import { getTokenFieldOptions } from "./schema/segments/get-token-field-options";
import { refreshSegmentCount } from "./schema/segments/refresh-segment-count";
import { updateDynamicSegment } from "./schema/segments/update-dynamic-segment";
import { createSignupForm } from "./schema/signup-form/create-signup-form";
import { deleteSignupForm } from "./schema/signup-form/delete-signup-form";
import { getMarketingSubscriberCounts } from "./schema/signup-form/get-marketing-subscriber-counts";
import { getSignupFormById } from "./schema/signup-form/get-signup-form-by-id";
import { getSignupFormsByTeamId } from "./schema/signup-form/get-signup-forms-by-team-id";
import { unpublishSignupForm } from "./schema/signup-form/unpublish-signup-form";
import { updatePublishedAt } from "./schema/signup-form/update-published-at";
import { updateSignupForm } from "./schema/signup-form/update-signup-form";
import { verifyOrCreateNotOnSaleCollection } from "./schema/signup-form/verify-or-create-not-on-sale-collection";
import { getSmsTemplateById } from "./schema/sms-templates/get-sms-template-by-id";
import { sendTestSMSById } from "./schema/sms-templates/send-test-sms-by-id";
import { updateSmsTemplate } from "./schema/sms-templates/update-sms-template";

const signupForm = {
  createSignupForm,
  deleteSignupForm,
  getMarketingSubscriberCounts,
  getSignupFormById,
  getSignupFormsByTeamId,
  unpublishSignupForm,
  updatePublishedAt,
  updateSignupForm,
  verifyOrCreateNotOnSaleCollection,
};

const segmentMetadata = {
  refreshSegmentCount,
  getEstimatedCampaignRecipientCount,
  getTokenFieldOptions,
};

const productFilter = {
  createProductFilter,
  updateProductFilter,
  deleteProductFilter,
  getProductFiltersByTeam,
  getProductFilterById,
};

const fetchSegments = { fetchSegmentById, fetchTeamSegments };

const segmentFields = { getSegmentCollections, getSegmentProductTags };

const segments = {
  getSegmentCount,
  createDynamicSegment,
  updateDynamicSegment,
  deleteSegment,
};

const discounts = {
  createDiscount,
  deleteDiscount,
  getDiscountsByTeam,
  getDiscount,
};

const rest = {
  getOutreachAnalytics,
  getTeamPhoneNumber,
  createCampaign,
  updateCampaign,
  deleteCampaign,
  duplicateCampaign,
  getCampaignById,
  getTeamCampaigns,
  getCustomerGroups,
  getCampaignRecipientsCount,
  paginateCampaignRecipients,
  previewEmailTemplate,
  getSmsTemplateById,
  updateSmsTemplate,

  sendTestSMSById,
  sendTestCampaignSMS,
};

type RpcDefinition = typeof signupForm &
  typeof rest &
  typeof segments &
  typeof segmentMetadata &
  typeof fetchSegments &
  typeof productFilter &
  typeof discounts &
  typeof segmentFields;

export const rpcDefinition: RpcDefinition = {
  ...signupForm,
  ...rest,
  ...segments,
  ...segmentMetadata,
  ...fetchSegments,
  ...productFilter,
  ...discounts,
  ...segmentFields,
};

export type RedoMarketingRpcDefinition = InferRpcDefinition<
  typeof rpcDefinition
>;
