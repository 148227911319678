import * as amplitude from "@amplitude/analytics-browser";
import {
  useLazyContext,
  useRequiredContext,
} from "@redotech/react-util/context";
import { useSearch } from "@redotech/react-util/search";
import {
  ConversationTagWithId,
  ExpandedConversation,
} from "@redotech/redo-model/conversation";
import { PillTheme } from "@redotech/redo-model/pill-theme";
import { Permission, permitted } from "@redotech/redo-model/user";
import {
  RedoButton,
  RedoButtonSize,
} from "@redotech/redo-web/arbiter-components/buttons/redo-button";
import { RedoList } from "@redotech/redo-web/arbiter-components/list/redo-list";
import { RedoListItemVariant } from "@redotech/redo-web/arbiter-components/list/redo-list-item";
import { RedoModal } from "@redotech/redo-web/arbiter-components/modal/redo-modal";
import { Divider } from "@redotech/redo-web/divider";
import { Flex } from "@redotech/redo-web/flex";
import PlusIcon from "@redotech/redo-web/icon-old/plus.svg";
import { ProgressBar } from "@redotech/redo-web/progress-bar";
import { TextInput } from "@redotech/redo-web/text-input";
import Fuse from "fuse.js";
import { memo, useContext, useEffect, useMemo, useState } from "react";
import { TeamContext } from "../../app/team";
import { UserContext } from "../../app/user";
import { RedoMerchantClientContext } from "../../client/context";
import { postConversationTag } from "../../client/conversation-tags";
import {
  bulkAddTags,
  bulkRemoveTags,
  updateConversation,
} from "../../client/conversations";
import { ConversationTagsContext } from "../../services/support/conversation-tags-service";
import { ConversationTagPill } from "../conversation-tags/conversation-tag-pill";
import { CreateTagModal } from "../conversation-tags/create-tag-modal";
import { getNextBatch } from "../utils";
import * as tagsModalCss from "./tags-modal.module.css";

const searcher = new Fuse<ConversationTagWithId>([], {
  keys: ["name"],
  threshold: 0.3,
});

export const TagsModal = memo(function TagsModal({
  open,
  setOpen,
  conversations,
  mode = "add",
  cleanupFn,
  selectAllInfo,
}: {
  open: boolean;
  setOpen: (val: boolean) => void;
  conversations: ExpandedConversation[];
  mode: "add" | "remove";
  cleanupFn?: (clearSelected?: boolean) => void;
  selectAllInfo?: {
    count: number;
    deselectedConversations: ExpandedConversation[];
    bulkActionIterator: AsyncIterator<{
      conversations: ExpandedConversation[];
    }>;
    setBlockRefresh: (val: boolean) => void;
  };
}) {
  const client = useRequiredContext(RedoMerchantClientContext);

  const [createTagModalOpen, setCreateTagModalOpen] = useState(false);

  const user = useContext(UserContext);
  const team = useRequiredContext(TeamContext);

  const [conversationTagsContext, reloadConversationTags] = useLazyContext(
    ConversationTagsContext,
  );

  const conversationTags = useMemo(
    () => conversationTagsContext.value || [],
    [conversationTagsContext],
  );

  const canCreateTags =
    !!user && permitted(user.permissions, Permission.MANAGE_TAG);
  const [updatingSelectAll, setUpdatingSelectAll] = useState(false);
  const [numDone, setNumDone] = useState(0);
  // If this is a bulk or select all action, this only adds tags. If it's a single conversation, it edits tags.
  const [selectedTags, setSelectedTags] = useState<ConversationTagWithId[]>(
    conversations.length === 1 ? conversations[0].tagIds || [] : [],
  );

  const [searchText, setSearchText] = useState<string>("");
  const tagsBeingShown = useSearch(searcher, conversationTags, searchText);

  const [hasChanged, setHasChanged] = useState(false);

  const handleConfirm = async () => {
    if (conversations.length === 1) {
      if (mode === "add") {
        // Add tags
        await updateConversation(client, conversations[0], {
          tags: selectedTags.map((tag) => ({ name: tag.name })),
        });
        amplitude.logEvent("assign-conversationTag", {
          mode: "single",
          conversationIds: [conversations[0]._id],
          channels: [conversations[0].platform],
          tags: selectedTags,
        });
      } else {
        // Remove tags
        const tagsToRemove = conversations[0].tagIds?.filter(
          (tag) =>
            !selectedTags.some((selectedTag) => selectedTag.name === tag.name),
        );
        await updateConversation(client, conversations[0], {
          tags: tagsToRemove,
        });
        amplitude.logEvent("remove-conversationTag", {
          mode: "single",
          conversationIds: [conversations[0]._id],
          channels: [conversations[0].platform],
          tags: tagsToRemove,
        });
      }
    } else if (conversations.length > 1) {
      const conversationIds = conversations.map(
        (conversation) => conversation._id,
      );
      if (conversationIds.length > 0) {
        const channels = [
          ...new Set(
            conversations.map((conversation) => conversation.platform),
          ),
        ];
        if (mode === "add") {
          await bulkAddTags(client, {
            conversationIds,
            tags: selectedTags.map((tag) => ({ name: tag.name })),
          });
          amplitude.logEvent("assign-conversationTag", {
            mode: "multiple",
            conversationIds,
            channels,
            tags: selectedTags,
          });
        } else {
          // Remove tags
          await bulkRemoveTags(client, {
            conversationIds,
            tags: selectedTags.map((tag) => ({ name: tag.name })),
          });
          amplitude.logEvent("remove-conversationTag", {
            mode: "multiple",
            conversationIds,
            channels,
            tags: selectedTags,
          });
        }
      }
    }
    cleanupFn && cleanupFn(true);
    setOpen(false);
  };

  const addTagsToBatch = async (): Promise<{
    batchSize: number;
    done: boolean;
  }> => {
    if (!selectAllInfo) return { batchSize: 0, done: true };
    const { done, value } = await getNextBatch(
      selectAllInfo.bulkActionIterator,
    );
    if (done) {
      return { batchSize: 0, done };
    }
    const conversationIds = value.conversations
      .filter(
        (conversation) =>
          !selectAllInfo.deselectedConversations.some(
            (deselectedConversation) =>
              deselectedConversation._id === conversation._id,
          ),
      )
      .map((conversation) => conversation._id);
    if (conversationIds.length > 0) {
      if (mode === "add") {
        await bulkAddTags(client, {
          conversationIds,
          tags: selectedTags.map((tag) => ({ name: tag.name })),
        });
      } else {
        await bulkRemoveTags(client, {
          conversationIds,
          tags: selectedTags.map((tag) => ({ name: tag.name })),
        });
      }
    }
    return { batchSize: value.conversations.length, done: false };
  };

  // This function synchronously adds tags to each page of conversations.
  // If speed becomes an issue, we could asynchronously add tags to each page.
  const handleAddTagsSelectAll = async () => {
    if (!selectAllInfo) return;
    setUpdatingSelectAll(true);
    selectAllInfo.setBlockRefresh(true);
    let done = false;
    do {
      const result = await addTagsToBatch();
      setNumDone((prevNumDone) => prevNumDone + result.batchSize);
      done = result.done;
    } while (!done);
    if (mode === "add") {
      amplitude.logEvent("assign-conversationTag", {
        mode: "all",
        tags: selectedTags,
      });
    } else {
      amplitude.logEvent("remove-conversationTag", {
        mode: "all",
        tags: selectedTags,
      });
    }
    setUpdatingSelectAll(false);
    selectAllInfo.setBlockRefresh(false);
    cleanupFn && cleanupFn(true);
    setOpen(false);
  };

  useEffect(() => {
    if (plural) {
      setHasChanged(selectedTags.length > 0);
    } else {
      setHasChanged(
        selectedTags.length !== conversations[0].tagIds?.length ||
          selectedTags.some(
            (tag) =>
              !conversations[0].tagIds?.some(
                (conversationTag) => conversationTag.name === tag.name,
              ),
          ),
      );
    }
  }, [selectedTags]);

  const plural = conversations.length > 1 || !!selectAllInfo;
  const showCreateTagButton = canCreateTags && mode === "add";

  function addTagToConversation(tag: ConversationTagWithId) {
    if (selectedTags.find((usedTag) => usedTag.name === tag.name)) {
      return;
    }
    // Eagerly update
    const newTags = [...selectedTags, tag];
    setSelectedTags(newTags);
  }

  function removeTagFromConversation(tag: ConversationTagWithId) {
    // Eagerly update
    const updatedTags = selectedTags.filter(
      (current) => current.name !== tag.name,
    );
    setSelectedTags(updatedTags);
  }

  function tagIsSelected(tag: ConversationTagWithId) {
    return selectedTags.some((current) => current.name === tag.name);
  }

  function onItemSelected(tag: ConversationTagWithId) {
    if (tagIsSelected(tag)) {
      removeTagFromConversation(tag);
    } else {
      addTagToConversation(tag);
    }
  }

  async function handleSubmit({
    name,
    pillTheme,
  }: {
    name: string;
    pillTheme: PillTheme;
  }) {
    await postConversationTag(
      client,
      { name: name, pillTheme: pillTheme, teamId: team._id },
      reloadConversationTags,
    );
    setCreateTagModalOpen(false);
  }

  const [focusedIndex, setFocusedIndex] = useState<number | undefined>();
  const tagsModal = (
    <RedoModal
      headerBorder={false}
      isOpen={open}
      onModalCloseRequested={() => {
        cleanupFn && cleanupFn();
        setOpen(false);
      }}
      primaryButton={{
        onClick: async () => {
          selectAllInfo
            ? await handleAddTagsSelectAll()
            : await handleConfirm();
        },
        text: plural
          ? `${mode === "add" ? "Add" : "Remove"} tag${selectedTags.length > 1 ? "s" : ""}`
          : "Confirm",
        disabled: !hasChanged,
      }}
      secondaryButton={{ onClick: () => setOpen(false), text: "Cancel" }}
      title={
        plural
          ? updatingSelectAll
            ? `${mode === "add" ? "Adding tags" : "Removing tags"}`
            : `${mode === "add" ? "Add tags" : "Remove tags"}`
          : "Edit tags"
      }
    >
      {updatingSelectAll ? (
        <div className={tagsModalCss.actionModalContentContainer}>
          <ProgressBar
            value={(numDone / (selectAllInfo?.count || numDone)) * 100}
          />
        </div>
      ) : (
        <div className={tagsModalCss.actionModalContentContainer}>
          <Flex dir="column" gap="md">
            <TextInput
              hideFocus
              onChange={setSearchText}
              placeholder={
                !plural
                  ? "Add or remove tags..."
                  : mode === "add"
                    ? "Add tags..."
                    : "Remove tags..."
              }
              showBorder={false}
              value={searchText}
            />
            <div className={tagsModalCss.fullWidthDivider}>
              <Divider />
            </div>
            <div className={tagsModalCss.tagsModalContentContainer}>
              <RedoList
                focusedIndex={focusedIndex}
                isItemSelected={(item) => {
                  return tagIsSelected(item.value);
                }}
                items={tagsBeingShown.map((tag) => ({ value: tag }))}
                itemSelected={(item) => onItemSelected(item.value)}
                keyFn={(tag) => tag.value.name}
                refToListenTo={null}
                selectionVariant={RedoListItemVariant.CHECKBOX}
                setFocusedIndex={setFocusedIndex}
              >
                {(item) => {
                  return <ConversationTagPill tag={item.value} />;
                }}
              </RedoList>
              {showCreateTagButton && (
                <RedoButton
                  centerItems={false}
                  className={tagsModalCss.createTagButton}
                  IconLeading={PlusIcon}
                  onClick={() => setCreateTagModalOpen(true)}
                  size={RedoButtonSize.REGULAR}
                  text="Create tag"
                />
              )}
            </div>
          </Flex>
        </div>
      )}
    </RedoModal>
  );

  return createTagModalOpen ? (
    <CreateTagModal
      cancelClicked={() => setCreateTagModalOpen(false)}
      handleCreateTag={({
        name,
        pillTheme,
      }: {
        name: string;
        pillTheme: PillTheme;
      }) => handleSubmit({ name, pillTheme })}
      tagsLoad={conversationTagsContext}
    />
  ) : (
    tagsModal
  );
});
