// extracted by mini-css-extract-plugin
export var active = "_be-b";
export var arrow = "_be-8";
export var arrowContainer = "_be-4";
export var container = "_be-0";
export var disabled = "_be-5";
export var dot = "_be-a";
export var dotContainer = "_be-9";
export var leftArrowContainer = "_be-6 _be-4";
export var rightArrowContainer = "_be-7 _be-4";
export var slide = "_be-3";
export var sliderContainer = "_be-1";
export var sliderOverflow = "_be-2";