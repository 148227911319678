// extracted by mini-css-extract-plugin
export var actionButton = "_bs-1";
export var actions = "_bs-0";
export var copyableField = "_bs-2";
export var detailColumn = "_bs-3";
export var detailRow = "_bs-4";
export var dropdownTitle = "_bs-5";
export var fulfillmentDetails = "_bs-7";
export var fulfillments = "_bs-6";
export var icon = "_bs-8";
export var imgContainer = "_bs-9";
export var lineItems = "_bs-a";
export var link = "_bs-p";
export var orderCard = "_bs-k";
export var orderCollapseButton = "_bs-l";
export var orderCollapseButtonIcon = "_bs-m";
export var orderDetails = "_bs-b";
export var orderPillsContainer = "_bs-c";
export var pillWrapper = "_bs-d";
export var product = "_bs-e";
export var productDetails = "_bs-g";
export var productTopDetails = "_bs-h";
export var products = "_bs-f";
export var strong = "_bs-i";
export var tightText = "_bs-o";
export var tooltipHidden = "_bs-q";
export var variantQuantityRow = "_bs-j";
export var wrappingText = "_bs-n";