// extracted by mini-css-extract-plugin
export var actions = "_30-0";
export var actionsContainer = "_30-3";
export var border = "_30-1";
export var childFullHeight = "_30-9";
export var collapse = "_30-2";
export var compact = "_30-e";
export var content = "_30-4";
export var contentChild = "_30-8";
export var fullscreen = "_30-6";
export var header = "_30-a";
export var headerContainer = "_30-g";
export var heightAuto = "_30-c";
export var hideHeaderBorder = "_30-f";
export var hidePadding = "_30-5";
export var noScrollX = "_30-h";
export var noScrollY = "_30-i";
export var optOutTopPadding = "_30-7";
export var profile = "_30-j";
export var profileContainer = "_30-k";
export var profileImage = "_30-l";
export var profileMain = "_30-m";
export var profileSubtitle = "_30-o";
export var profileTitle = "_30-n";
export var scrolledTop = "_30-d";
export var tabsWrapper = "_30-q";
export var title = "_30-p";
export var withTabs = "_30-b";