import { z } from "zod";

export const getConciergeSkillsSettings = {
  input: z.null(),
  output: z.object({
    settings: z.object({
      backInStockNotifications: z.object({ enabled: z.boolean() }),
      orderTracking: z.object({ enabled: z.boolean() }),
    }),
  }),
};
