import { useRequiredContext } from "@redotech/react-util/context";
import { ExpandedConversation } from "@redotech/redo-model/conversation";
import { allowSupportAiCopilot } from "@redotech/redo-model/team";
import { toast } from "@redotech/redo-web/alert";
import PaperclipSvg from "@redotech/redo-web/arbiter-icon/paperclip_filled.svg";
import StarsSvg from "@redotech/redo-web/arbiter-icon/stars-01_filled.svg";
import { Button, ButtonSize, ButtonTheme } from "@redotech/redo-web/button";
import { copyToClipboard } from "@redotech/redo-web/clipboard";
import { Flex } from "@redotech/redo-web/flex";
import { Icon } from "@redotech/redo-web/icon";
import { Modal, ModalSize } from "@redotech/redo-web/modal";
import { Text } from "@redotech/redo-web/text";
import { memo, useState } from "react";
import { TeamContext } from "../app/team";
import { RedoMerchantClientContext } from "../client/context";
import { getSupportAISummary as getSupportAiSummary } from "../client/support-ai";
import * as generateAiSummaryButtonCss from "./generate-ai-summary-button.module.css";

export const GenerateAiSummaryButton = memo(function GenerateAiSummaryButton({
  conversation,
}: {
  conversation: ExpandedConversation;
}) {
  const merchantClient = useRequiredContext(RedoMerchantClientContext);
  const team = useRequiredContext(TeamContext);
  const [isGeneratingAiSummary, setIsGeneratingAiSummary] =
    useState<boolean>(false);
  const handleGenerateAiSummary = async () => {
    setIsGeneratingAiSummary(true);
    setAiSummary("This is a summary of the conversation");
    const aiSuggestion = await getSupportAiSummary(merchantClient, {
      type: "conversationSummary",
      conversationId: conversation._id,
    });
    if (aiSuggestion === "error" || !aiSuggestion?.text) {
      toast("Couldn't summarize conversation.", { variant: "error" });
    } else {
      setAiSummary(aiSuggestion.text);
      setIsShowingAiSummary(true);
    }
    setIsGeneratingAiSummary(false);
  };
  const [isShowingAiSummary, setIsShowingAiSummary] = useState<boolean>(false);
  const handleCloseAiSummmary = () => {
    setIsShowingAiSummary(false);
  };
  const [aiSummary, setAiSummary] = useState<string>("");
  const handleCopySummaryToClipboard = async () => {
    await copyToClipboard(aiSummary);
    handleCloseAiSummmary();
  };

  return (
    <>
      {allowSupportAiCopilot(team) && (
        <>
          <Button
            className={generateAiSummaryButtonCss.generateAiSummaryButton}
            onClick={handleGenerateAiSummary}
            pb="md"
            pending={isGeneratingAiSummary}
            pl="lg"
            pr="lg"
            pt="md"
            size={ButtonSize.MICRO}
            theme={ButtonTheme.PRIMARY}
          >
            <Flex align="center" gap="xs" justify="center">
              <Flex
                align="center"
                className={generateAiSummaryButtonCss.starIconBox}
                justify="center"
              >
                <StarsSvg />
              </Flex>
              <Text fontSize="xs" fontWeight="medium">
                Summarize conversation
              </Text>
            </Flex>
          </Button>
          <Modal
            footer={
              <Flex basis="100%" reverse>
                <Button
                  icon={(props) => <PaperclipSvg {...props} />}
                  onClick={handleCopySummaryToClipboard}
                  size={ButtonSize.EXTRA_SMALL}
                  theme={ButtonTheme.OUTLINED}
                >
                  Copy Summary
                </Button>
              </Flex>
            }
            onClose={handleCloseAiSummmary}
            open={isShowingAiSummary}
            showHeaderBorder={false}
            size={ModalSize.SMALL}
            title=""
            TitleIcon={() => <Icon arbiterIconSvg={StarsSvg} size="medium" />}
          >
            <Flex basis="100%" dir="column">
              <Flex>
                <span className={generateAiSummaryButtonCss.summaryTitle}>
                  Conversation Summary
                </span>
              </Flex>
              <Flex>
                <span className={generateAiSummaryButtonCss.summaryBodyText}>
                  {aiSummary}
                </span>
              </Flex>
            </Flex>
          </Modal>
        </>
      )}
    </>
  );
});
