import { Provider } from "@redotech/redo-model/order";
import { AdvancedFilterType } from "@redotech/redo-model/views/advanced-filters/generic-advanced-filter-data";
import { StringFilterOperator } from "@redotech/redo-model/views/advanced-filters/string-filter";
import { StringTableFilter } from "@redotech/redo-web/advanced-filters/advanced-filter";
import RouteIcon from "@redotech/redo-web/arbiter-icon/route.svg";
import { Text } from "@redotech/redo-web/text";
import { titleCase } from "@redotech/web-util/strings";

export const OrderSourceFilter: StringTableFilter<string> = {
  type: AdvancedFilterType.STRING,
  Icon: RouteIcon,
  operators: [StringFilterOperator.EQUALS],
  values: Object.values(Provider),
  ItemRenderer: ({ item }) => (
    <Text
      fontSize="sm"
      overflow="hidden"
      textOverflow="ellipsis"
      whiteSpace="nowrap"
    >
      {titleCase(item ?? "")}
    </Text>
  ),
  display: titleCase,
  data: {
    name: "source",
    type: AdvancedFilterType.STRING,
    value: null,
    operator: StringFilterOperator.EQUALS,
  },
};
