import { RedoOutboundLabelsRpcClient } from "@redotech/merchant-sdk/outbound-labels-rpc/client";
import { FulfillmentOrderUpdate } from "@redotech/merchant-sdk/outbound-labels-rpc/schema/update-fulfillment-groups";
import { getTeamOMSUsers } from "@redotech/merchant-sdk/outbound-labels-rpc/util/util";
import {
  NumberParam,
  SetParam,
  useParam,
} from "@redotech/react-router-util/param";
import {
  useLazyContext,
  useRequiredContext,
} from "@redotech/react-util/context";
import { useHandler } from "@redotech/react-util/hook";
import { LoadState, useTriggerLoad } from "@redotech/react-util/load";
import { PaymentSchedule } from "@redotech/redo-model/balance/balances";
import {
  FulfillmentOrderData,
  OMSUser,
} from "@redotech/redo-model/fulfillments/fulfillment-group";
import { canPurchaseLabelStatus } from "@redotech/redo-model/fulfillments/fulfillment-group-status";
import { PrintKind } from "@redotech/redo-model/fulfillments/fullfilment-print-status";
import { Tag, TagKind } from "@redotech/redo-model/tag";
import { Team } from "@redotech/redo-model/team";
import { OutboundSystemView } from "@redotech/redo-model/views/default-outbound-views";
import {
  DEFAULT_PAGE_SIZE,
  viewTypes,
  ViewTypesNames,
} from "@redotech/redo-model/views/views";
import { AdvancedTableFilter } from "@redotech/redo-web/advanced-filters/advanced-filter";
import {
  RedoButton,
  RedoButtonHierarchy,
  RedoButtonSize,
  RedoButtonTheme,
} from "@redotech/redo-web/arbiter-components/buttons/redo-button";
import {
  RedoCheckbox,
  RedoCheckboxSize,
} from "@redotech/redo-web/arbiter-components/checkbox/redo-checkbox";
import {
  RedoTableColumnWidthType,
  RedoTableSize,
} from "@redotech/redo-web/arbiter-components/tables/redo-table";
import ChevronDown from "@redotech/redo-web/arbiter-icon/chevron-down_filled.svg";
import XIcon from "@redotech/redo-web/arbiter-icon/x-close.svg";
import { Flex } from "@redotech/redo-web/flex";
import { Link } from "@redotech/redo-web/link";
import {
  ActionPortalContext,
  PageMainContentRefContext,
} from "@redotech/redo-web/page";
import {
  ColumnAlignment,
  RowClickHandler,
  TableFetcher,
  TableRef,
} from "@redotech/redo-web/table";
import { Column2, SaveViewBody, Table2 } from "@redotech/redo-web/table2";
import * as classNames from "classnames";
import { memo, useContext, useEffect, useMemo, useRef, useState } from "react";
import { createPortal } from "react-dom";
import { useLocation, useNavigate } from "react-router-dom";
import {
  OutboundViewsContext,
  ReloadOutboundViewsContext,
} from "../../app/outbound-views";
import { RedoMerchantRpcClientContext } from "../../app/redo-merchant-rpc-client-provider";
import { RedoOutboundLabelsRpcClientContext } from "../../app/redo-outbound-labels-rpc-client-provider";
import { TeamContext } from "../../app/team";
import { ParcelContext } from "../../services/parcel-service";
import { CreateOrderSlideout } from "../common/create-order-slideout";
import { ImportOrdersModal } from "../common/import-orders-modal";
import {
  ActiveOutboundViewContext,
  EditingOutboundViewContext,
  OutboundActiveViewContextProvider,
  SetEditingOutboundViewContext,
  sortDefault,
} from "../outbound-active-view-context";
import { isSplit } from "../utils/outbound-labels-utils";
import { TagsCell } from "./cells/tags-cell";
import { AddressVerificationColumn } from "./columns/address-verification-column";
import { AssignedUserColumn } from "./columns/assigned-user-column";
import { CustomerNameColumn } from "./columns/customer-name-column";
import { DestinationCountryColumn } from "./columns/destination-country-column";
import { FraudRiskColumn } from "./columns/fraud-risk-column";
import { FulfillmentLocationColumn } from "./columns/fulfillment-location-column";
import { InternalNotesColumn } from "./columns/internal-notes-column";
import { isPrintedColumn } from "./columns/is-printed-column";
import { ItemNameColumn } from "./columns/item-name-column";
import { ItemSKUColumn } from "./columns/item-sku-column";
import { NoteFromBuyerColumn } from "./columns/note-from-buyer";
import { NoteToBuyerColumn } from "./columns/note-to-buyer";
import { OrderAgeColumn } from "./columns/order-age-column";
import { OrderDateColumn } from "./columns/order-date-column";
import { OrderItemsColumn } from "./columns/order-items-column";
import { OrderNameColumn } from "./columns/order-name-column";
import { OrderTotalColumn } from "./columns/order-total-column";
import { OrderWeightColumn } from "./columns/order-weight-column";
import { ProductVariantColumn } from "./columns/product-variant-column";
import { RateColumn } from "./columns/rate-column";
import { RequestedServiceColumn } from "./columns/requested-service-column";
import { ParcelSelectionColumn } from "./columns/selected-parcel-column";
import { ShippedDateColumn } from "./columns/shipped-date-column";
import { ShippingPaidColumn } from "./columns/shipping-paid-column";
import { ShippingSelectionColumn } from "./columns/shipping-selection-column";
import { ShopifyOrderTagsColumn } from "./columns/shopify-order-tags-column";
import { StateColumn as DestinationStateColumn } from "./columns/state-column";
import { StatusColumn } from "./columns/status-column";
import { UniqueItemsColumn } from "./columns/unique-items-column";
import { CountriesFilter } from "./filters/countries-filter";
import { CreationDateFilter } from "./filters/create-date-filter";
import { ItemQuantityFilter } from "./filters/item-quantity-filter";
import { ItemSkusFilter } from "./filters/item-skus-filter";
import { ItemTitleFilter } from "./filters/item-title-filter";
import { OMSUserFilter } from "./filters/oms-user-filter";
import { OriginAddressNameFilter } from "./filters/origin-address-name-filter";
import { PrinterFilter } from "./filters/printer-filter";
import { ProductVariantFilter } from "./filters/product-variant-filter";
import { RequestedServiceFilter } from "./filters/requested-service-filter";
import { ShippingInsuranceFilter } from "./filters/shipping-insurance-filter";
import { ShopifyTagFilter } from "./filters/shopify-tag-filter";
import { OrderSourceFilter } from "./filters/source-filter";
import { StatusFilter } from "./filters/status-filter";
import { TagFilter } from "./filters/tag-filter";
import { TotalPriceFilter } from "./filters/total-price-filter";
import { WeightFilter } from "./filters/weight-filter";
import { FulfillmentGroupsFetcher } from "./order-fulfillment-group-fetcher";
import { OrderFulfillmentSlideout } from "./order-fulfillment-slideout";
import * as ordersCss from "./outbound-labels.module.css";

export const LabelsLoad = memo(function LabelsLoad() {
  const team = useContext(TeamContext);
  if (!team) {
    return null;
  }

  return (
    <OutboundActiveViewContextProvider>
      <Labels team={team} />
    </OutboundActiveViewContextProvider>
  );
});

const Labels = memo(function Labels({ team }: { team: Team }) {
  const rpcClient = useRequiredContext(RedoOutboundLabelsRpcClientContext);
  const rpcMerchantClient = useRequiredContext(RedoMerchantRpcClientContext);

  const [rows, setRows] = useState<FulfillmentOrderData[]>([]);
  const [selectedRows, setSelectedRows] = useParam(new SetParam("selected"));
  const [checkedRows, setCheckedRows] = useState<Set<string>>(new Set());

  const isFirstRender = useRef(true);

  useEffect(() => {
    if (isFirstRender.current) {
      isFirstRender.current = false;
      return;
    }
    setSelectedRows(checkedRows);
  }, [checkedRows]);

  useEffect(() => {
    if (selectedRows.size > 1) {
      setCheckedRows(selectedRows);
    }
  }, [selectedRows.size]);

  const [lastActionRowId, setLastActionRowId] = useState<string | null>(null);
  const [createOrderSlideoutOpen, setCreateOrderSlideoutOpen] = useState(false);

  const [tagWindowOpen, setTagWindowOpen] = useState<string | null>(null);
  const [availableTagsLoad, getAvailableTags] = useTriggerLoad(
    async (signal: AbortSignal) => {
      try {
        const { tags } = await rpcMerchantClient.getTeamTags(
          { kind: TagKind.FULFILLMENT_GROUP },
          { signal },
        );
        return tags;
      } catch (e) {
        return [];
      }
    },
  );

  useEffect(() => {
    getAvailableTags();
  }, []);

  const activeView = useContext(ActiveOutboundViewContext);
  const viewBeingEdited = useContext(EditingOutboundViewContext);
  const setViewBeingEdited = useContext(SetEditingOutboundViewContext);
  const reloadViews = useContext(ReloadOutboundViewsContext);
  const views = useContext(OutboundViewsContext);

  const reservedViewNames = useMemo<Set<string>>(() => {
    const filterOutActiveView = Object.values(views?.teamViews ?? {}).filter(
      (view) => view.name !== activeView?.name,
    );
    const lowerCaseNames = filterOutActiveView.map((view) =>
      view.name.toLowerCase(),
    );
    const systemViewNames = Object.values(OutboundSystemView).map((view) =>
      view.toLowerCase(),
    );
    return new Set([...lowerCaseNames, ...systemViewNames]);
  }, [views, activeView?.name]);

  // If we load the page with selected rows, set the last action row id to the last selected row
  if (selectedRows.size > 0 && !lastActionRowId) {
    const lastSelectedRow = Array.from(selectedRows).at(-1) ?? null;
    setLastActionRowId(lastSelectedRow);
  }

  const fetcher = useMemo(
    () => new FulfillmentGroupsFetcher(rpcClient, setRows),
    [rpcClient],
  );

  const actionsPortal = useContext(ActionPortalContext);
  const [importOrdersModalOpen, setImportOrdersModalOpen] = useState(false);
  const openImportOrdersModal = useHandler(() => {
    setImportOrdersModalOpen(true);
  });

  const tableRef = useRef<TableRef<FulfillmentOrderData>>(null);

  useEffect(() => {
    fetcher.rows = rows;
    tableRef.current?.externalSetItems?.(rows);
  }, [rows]);

  const pendingUpdatesRef = useRef<{
    updates: FulfillmentOrderUpdate[];
    currentPromise: Promise<void> | null;
  }>({ updates: [], currentPromise: null });
  const [updatesPending, setUpdatesPending] = useState(false);
  const [pageNumber, setPageNumber] = useParam(new NumberParam("page", 0));
  const [pageSize, setPageSize] = useParam(
    new NumberParam("page-size", activeView?.pageSize ?? DEFAULT_PAGE_SIZE),
  );

  const [__, doUpdates] = useTriggerLoad(async () => {
    // Wait to obtain "lock" on the pending updates
    while (pendingUpdatesRef.current.currentPromise) {
      try {
        await pendingUpdatesRef.current.currentPromise;
      } finally {
        // noop
      }
    }
    if (pendingUpdatesRef.current.updates.length === 0) {
      return;
    }
    setUpdatesPending(true);
    pendingUpdatesRef.current.currentPromise = (async () => {
      const numUpdates = pendingUpdatesRef.current.updates.length;
      await new Promise((resolve) => setTimeout(resolve, 500));
      // If updates have come in since the request was made, debounce
      if (numUpdates !== pendingUpdatesRef.current.updates.length) {
        return;
      }
      const fullUpdates = pendingUpdatesRef.current.updates.splice(0);
      const updateMap = fullUpdates.reduce(
        (map: Record<string, FulfillmentOrderUpdate>, update) => {
          if (!map[update.fulfillmentGroupId]) {
            map[update.fulfillmentGroupId] = update;
          } else {
            Object.assign(map[update.fulfillmentGroupId], update);
          }
          return map;
        },
        {},
      );
      const updates = Object.values(updateMap);
      const updatedGroups = await rpcClient.updateFulfillmentGroups({
        updates,
      });
      const newRows: FulfillmentOrderData[] = [...fetcher.rows];

      for (const groupId in updatedGroups) {
        // If no more updates have come in for this row,
        // then the server is the current source of truth.
        // Obey it 🙇.
        if (
          !pendingUpdatesRef.current.updates.find(
            (u) => u.fulfillmentGroupId === groupId,
          )
        ) {
          const index = newRows.findIndex((row) => row._id === groupId);
          newRows[index] = updatedGroups[groupId];
        }
      }
      setRows(newRows);
    })();

    try {
      await pendingUpdatesRef.current.currentPromise;
    } finally {
      pendingUpdatesRef.current.currentPromise = null;
      if (pendingUpdatesRef.current.updates.length === 0) {
        setUpdatesPending(false);
      }
    }
  });

  const handleUpdates = useHandler((updates: FulfillmentOrderUpdate[]) => {
    const newRows: FulfillmentOrderData[] = [...rows];
    for (const { fulfillmentGroupId, ...rest } of updates) {
      const index = newRows.findIndex((row) => row._id === fulfillmentGroupId);
      if (index < 0) {
        continue;
      }
      newRows[index] = { ...newRows[index], ...rest };
    }
    setRows(newRows);
    pendingUpdatesRef.current.updates.push(...updates);
    doUpdates();
  });

  const handleRemoveRows = useHandler((ids: string[]) => {
    const newRows: FulfillmentOrderData[] = [...rows];
    for (const id of ids) {
      const index = newRows.findIndex((row) => row._id === id);
      newRows.splice(index, 1);
    }
    setRows(newRows);
  });

  const [parcelsLoad, _] = useLazyContext(ParcelContext);
  const parcelValues = parcelsLoad.value ?? [];

  const viewRef = useRef<viewTypes["fulfillment-groups"] | null>(null);
  const navigate = useNavigate();
  const currentLocation = useLocation();

  const [saveViewLoad, doSaveView] = useTriggerLoad(
    async (signal: AbortSignal) => {
      if (viewRef.current) {
        await rpcClient.saveView({ view: viewRef.current }, { signal });
        setViewBeingEdited(false);
        await reloadViews?.();
        const targetUrl = `/stores/${team._id}/orders/outbound-labels/${encodeURIComponent(viewRef.current.name)}`;

        if (currentLocation.pathname !== targetUrl) {
          navigate(targetUrl);
        }
      }
    },
  );

  const onDeleteView = useHandler(
    async (view: viewTypes["fulfillment-groups"]) => {
      await rpcClient.deleteFulfillmentGroupView({ name: view.name });
      viewRef.current = null;
      void reloadViews?.();
      navigate(`/stores/${team._id}/orders/outbound-labels/awaiting-shipment`);
    },
  );

  const saveView = useHandler(
    ({ name, filters, columns, sort }: SaveViewBody) => {
      viewRef.current = {
        table: ViewTypesNames.FULFILLMENT_GROUPS,
        name,
        filters,
        columns,
        sort,
        pageSize,
      };
      doSaveView();
    },
  );

  const [balanceLoad, reloadBalance] = useTriggerLoad(
    async (signal: AbortSignal) => {
      try {
        return await rpcClient.getOutboundBalance(null, { signal });
      } catch (e) {
        return { balance: "0", paymentSchedule: PaymentSchedule.PREPAID };
      }
    },
  );

  useEffect(() => {
    reloadBalance();
  }, []);

  const teamUsers = getTeamOMSUsers(team);

  const toggleRowSelection = useHandler(
    (
      id: string,
      e: React.MouseEvent<HTMLDivElement>,
      selected: boolean = !checkedRows.has(id),
    ) => {
      if (e.shiftKey) {
        shiftSelection(id);
      } else {
        const newSelections = new Set(checkedRows);
        if (selected) {
          newSelections.add(id);
        } else {
          newSelections.delete(id);
        }
        setSelectedRows(newSelections);
        setCheckedRows(newSelections);
        setLastActionRowId(id);
      }
    },
  );

  const toggleAllSelection = useHandler((selected: boolean) => {
    if (selected) {
      setCheckedRows(new Set(rows.map((row) => row._id)));
    } else {
      setCheckedRows(new Set());
    }
  });

  const shiftSelection = useHandler((rowId: string) => {
    const indexOfLastAction = rows.findIndex((r) => r._id === lastActionRowId);
    const rowAlreadySelected = selectedRows.has(rowId);
    const newSelections = new Set(selectedRows);

    if (rowAlreadySelected) {
      newSelections.delete(rowId);
    } else {
      newSelections.add(rowId);
    }

    if (indexOfLastAction !== -1 && selectedRows.size > 0) {
      const indexOfSelected = rows.findIndex((r) => r._id === rowId);
      const min = Math.min(indexOfSelected, indexOfLastAction);
      const max = Math.max(indexOfSelected, indexOfLastAction);

      if (rowAlreadySelected) {
        for (let i = min; i <= max; i++) {
          newSelections.delete(rows[i]._id);
        }
      } else {
        for (let i = min; i <= max; i++) {
          newSelections.add(rows[i]._id);
        }
      }
    }

    setSelectedRows(newSelections);
    setCheckedRows(newSelections);
    setLastActionRowId(rowId);
  });

  const handleRowClick = useHandler<RowClickHandler<FulfillmentOrderData>>(
    (record, e) => {
      if (checkedRows.size > 0 || e.shiftKey) {
        toggleRowSelection(record._id, e);
      } else {
        setLastActionRowId(record._id);
        setSelectedRows(new Set([record._id]));
      }
      // In the future, we will navigate to the order fulfillment group page
      // navigate(`/stores/${team._id}/orders/fulfillment-group/${record._id}`);
    },
  );

  const handleClearSelection = useHandler(() => {
    setSelectedRows(new Set());
    setCheckedRows(new Set());
  });

  const handleCheckClick = useHandler(
    (id: string, e: React.MouseEvent<HTMLDivElement>) => {
      toggleRowSelection(id, e);
    },
  );

  const [refreshSymbol, setRefreshSymbol] = useState<symbol>(Symbol());

  const refreshTable = () => {
    void tableRef.current?.refresh();
    setRefreshSymbol(Symbol());
  };

  const waitForRefresh = async () => {
    await tableRef.current?.refresh();
    setRefreshSymbol(Symbol());
  };

  const selectedFulfillmentGroups = useMemo(
    () =>
      rows.filter((row) =>
        selectedRows.has(row._id),
      ) as readonly FulfillmentOrderData[],
    [rows, selectedRows],
  );

  if (!team.settings?.outboundLabels?.newOmsEnabled || !rows) {
    return null;
  }

  if (parcelsLoad.value !== undefined && parcelValues.length === 0) {
    return (
      <div>
        <div className={classNames(ordersCss.boldText, ordersCss.largeText)}>
          Cannot load labels without a default package set
        </div>
        <div
          className={classNames(ordersCss.tertiaryText, ordersCss.smallText)}
        >
          <span>Click</span> <Link to="../../../settings/parcels">here</Link>
          <span> to set up your default package.</span>
        </div>
      </div>
    );
  }

  return (
    <>
      <div className={ordersCss.container}>
        <LabelsTable
          activeView={activeView}
          allChecked={rows.length > 0 && checkedRows.size >= rows.length}
          availableTagsLoad={availableTagsLoad}
          checkedRows={checkedRows}
          clearSelection={handleClearSelection}
          fetcher={fetcher}
          getAvailableTags={getAvailableTags}
          handleCheckClick={handleCheckClick}
          handleRowClick={handleRowClick}
          onDeleteView={onDeleteView}
          pageNumber={pageNumber}
          pageSize={pageSize}
          refreshSymbol={refreshSymbol}
          reservedViewNames={reservedViewNames}
          rpcClient={rpcClient}
          saveView={saveView}
          saveViewLoad={saveViewLoad}
          selectedRows={selectedRows}
          setPageNumber={setPageNumber}
          setPageSize={setPageSize}
          setTagWindowOpen={setTagWindowOpen}
          setViewBeingEdited={setViewBeingEdited}
          slideoutOpen={selectedFulfillmentGroups.length > 0}
          someChecked={rows.length > 0 && checkedRows.size > 0}
          tableRef={tableRef}
          tagWindowOpen={tagWindowOpen}
          toggleAllSelection={toggleAllSelection}
          updateRowData={handleUpdates}
          users={teamUsers}
          viewBeingEdited={viewBeingEdited}
        />
      </div>
      <OrderFulfillmentSlideout
        availableUsers={teamUsers}
        balance={balanceLoad?.value?.balance}
        balancePaymentSchedule={balanceLoad?.value?.paymentSchedule}
        deselectComplete={() =>
          setSelectedRows(
            new Set(
              Array.from(selectedRows).filter((id) => {
                const row = rows.find((row) => row._id === id);
                return row && canPurchaseLabelStatus(row.status);
              }),
            ),
          )
        }
        fulfillmentGroups={selectedFulfillmentGroups}
        onClose={() => {
          setSelectedRows(new Set());
          setCheckedRows(new Set());
        }}
        parcelValues={parcelValues}
        reloadBalance={() => reloadBalance()}
        removeRows={handleRemoveRows}
        resetSelections={() => {
          setSelectedRows(new Set());
          setCheckedRows(new Set());
        }}
        triggerRefresh={refreshTable}
        updateRows={handleUpdates}
        updatesPending={updatesPending}
      />
      {actionsPortal &&
        createPortal(
          <Flex>
            <RedoButton
              hierarchy={RedoButtonHierarchy.TERTIARY}
              onClick={openImportOrdersModal}
              size={RedoButtonSize.SMALL}
              text="Import orders"
              theme={RedoButtonTheme.NORMAL}
            />
            <RedoButton
              hierarchy={RedoButtonHierarchy.SECONDARY}
              onClick={() => setCreateOrderSlideoutOpen(true)}
              size={RedoButtonSize.SMALL}
              text="+ Create new order"
              theme={RedoButtonTheme.NORMAL}
            />
          </Flex>,
          actionsPortal,
        )}
      <CreateOrderSlideout
        onClose={() => setCreateOrderSlideoutOpen(false)}
        open={createOrderSlideoutOpen}
        triggerRefresh={refreshTable}
      />
      {importOrdersModalOpen && (
        <ImportOrdersModal
          open={importOrdersModalOpen}
          setOpen={setImportOrdersModalOpen}
          triggerReload={waitForRefresh}
        />
      )}
    </>
  );
});

function createValueFetcher(
  filterName: string,
  rpcClient: RedoOutboundLabelsRpcClient,
): (
  { filters }: { filters: AdvancedTableFilter[] },
  signal: AbortSignal,
) => Promise<string[]> {
  return async ({ filters }, signal) => {
    const { values } = await rpcClient.getFulfillmentGroupUniqueValues(
      { advancedFiltersData: filters.map((filter) => filter.data), filterName },
      { signal },
    );
    return values;
  };
}

const LabelsTable = memo(function LabelsTable({
  allChecked,
  someChecked,
  fetcher,
  pageNumber,
  checkedRows,
  selectedRows,
  clearSelection,
  setPageNumber,
  tableRef,
  tagWindowOpen,
  setTagWindowOpen,
  availableTagsLoad,
  getAvailableTags,
  toggleAllSelection,
  handleCheckClick,
  handleRowClick,
  refreshSymbol,
  saveView,
  saveViewLoad,
  rpcClient,
  updateRowData,
  viewBeingEdited,
  setViewBeingEdited,
  activeView,
  reservedViewNames,
  onDeleteView,
  slideoutOpen,
  users,
  pageSize,
  setPageSize,
}: {
  allChecked: boolean;
  someChecked: boolean;
  fetcher: TableFetcher<FulfillmentOrderData>;
  handleRowClick: RowClickHandler<FulfillmentOrderData>;
  pageNumber: number;
  checkedRows: ReadonlySet<string>;
  selectedRows: ReadonlySet<string>;
  clearSelection: () => void;
  setPageNumber: (pageNumber: number) => void;
  tableRef: React.RefObject<TableRef<FulfillmentOrderData>>;
  tagWindowOpen: string | null;
  setTagWindowOpen: (tagWindowOpen: string | null) => void;
  availableTagsLoad: LoadState<Tag[] | undefined>;
  getAvailableTags: (signal?: AbortSignal) => void;
  toggleAllSelection: (selected: boolean) => void;
  handleCheckClick: (id: string, e: React.MouseEvent<HTMLDivElement>) => void;
  refreshSymbol: symbol;
  updateRowData: (rowUpdates: FulfillmentOrderUpdate[]) => void;
  saveView: ({ name, filters, columns, sort, pageSize }: SaveViewBody) => void;
  saveViewLoad: LoadState<void>;
  rpcClient: RedoOutboundLabelsRpcClient;
  setViewBeingEdited?: (viewBeingEdited: boolean) => void;
  viewBeingEdited?: boolean;
  activeView: viewTypes["fulfillment-groups"];
  reservedViewNames: Set<string>;
  onDeleteView: (view: viewTypes["fulfillment-groups"]) => void;
  slideoutOpen: boolean;
  users: OMSUser[];
  pageSize: number;
  setPageSize: (size: number) => void;
}) {
  const rpcMerchantClient = useRequiredContext(RedoMerchantRpcClientContext);
  const advancedFilters = useMemo(
    (): AdvancedTableFilter[] => [
      CreationDateFilter,
      OrderSourceFilter,
      StatusFilter,
      ItemQuantityFilter,
      TotalPriceFilter,
      WeightFilter,
      ItemSkusFilter(createValueFetcher("itemSkus", rpcClient)),
      CountriesFilter(createValueFetcher("countries", rpcClient)),
      RequestedServiceFilter(createValueFetcher("requestedService", rpcClient)),
      OMSUserFilter(createValueFetcher("assignees", rpcClient), users),
      ItemTitleFilter(createValueFetcher("itemTitle", rpcClient)),
      ProductVariantFilter(createValueFetcher("productVariant", rpcClient)),
      OriginAddressNameFilter(
        createValueFetcher("originAddressName", rpcClient),
      ),
      TagFilter(
        async () =>
          (
            await rpcMerchantClient.getTeamTags({
              kind: TagKind.FULFILLMENT_GROUP,
            })
          ).tags,
      ),
      ShopifyTagFilter(createValueFetcher("shopifyOrderTags", rpcClient)),
      ShippingInsuranceFilter,
      PrinterFilter("labelPrinted", "Label"),
      PrinterFilter("pickListPrinted", "Pick list"),
      PrinterFilter("packingSlipPrinted", "Packing slip"),
    ],
    [],
  );

  const pageMainContentRef = useRequiredContext(PageMainContentRefContext);

  return (
    <div
      className={ordersCss.tableContainer}
      onMouseDown={(e) => e.shiftKey && e.preventDefault()}
    >
      {selectedRows.size ? (
        <RedoButton
          buttonClassName={ordersCss.clearButton}
          hierarchy={RedoButtonHierarchy.SECONDARY}
          IconTrailing={XIcon}
          onClick={clearSelection}
          size={RedoButtonSize.SMALL}
          text={
            selectedRows.size === 1
              ? "1 order selected"
              : `${selectedRows.size} orders selected`
          }
        />
      ) : null}
      <Table2
        activeView={activeView}
        adjustableColumnWidths
        advancedFilters={advancedFilters}
        columns={LabelColumns({
          allChecked,
          someChecked,
          toggleAllSelection,
          checkedRows,
          handleCheckClick,
          selectedRows,
          availableTagsLoad,
          getAvailableTags,
          tagWindowOpen,
          setTagWindowOpen,
          updateRowData,
        })}
        columnSelection
        fetcher={fetcher}
        isRowSelected={(item: FulfillmentOrderData) =>
          selectedRows.has(item._id)
        }
        onDeleteView={onDeleteView}
        onRowClick={handleRowClick}
        pageNumber={pageNumber}
        pageSize={pageSize}
        ref={tableRef}
        refreshSymbol={refreshSymbol}
        reservedViewNames={reservedViewNames}
        saveView={saveView}
        saveViewLoad={saveViewLoad}
        scrollAreaRef={pageMainContentRef}
        searchEnabled
        setPageNumber={setPageNumber}
        setPageSize={setPageSize}
        setViewBeingEdited={setViewBeingEdited}
        size={RedoTableSize.SMALL}
        sortDefault={sortDefault}
        stickyPageControl
        tableContentClassName={classNames({
          [ordersCss.fulfillmentGroupTable]: true,
          [ordersCss.narrow]: slideoutOpen,
        })}
        useVirtualTable
        viewBeingEdited={viewBeingEdited}
      />
    </div>
  );
});

function LabelColumns({
  allChecked,
  someChecked,
  toggleAllSelection,
  handleCheckClick,
  checkedRows,
  selectedRows,
  tagWindowOpen,
  availableTagsLoad,
  getAvailableTags,
  setTagWindowOpen,
  updateRowData,
}: {
  allChecked: boolean;
  someChecked: boolean;
  toggleAllSelection: (selected: boolean) => void;
  handleCheckClick: (id: string, e: React.MouseEvent<HTMLDivElement>) => void;
  checkedRows: ReadonlySet<string>;
  selectedRows: ReadonlySet<string>;
  tagWindowOpen: string | null;
  availableTagsLoad: LoadState<Tag[] | undefined>;
  getAvailableTags: (signal?: AbortSignal) => void;
  setTagWindowOpen: (tagWindowOpen: string | null) => void;
  updateRowData: (rowUpdates: FulfillmentOrderUpdate[]) => void;
}): Column2<FulfillmentOrderData>[] {
  return [
    {
      alignment: ColumnAlignment.CENTER,
      key: "split-accordian",
      title: "",
      renderNormalCell: ({ row }) => {
        if (isSplit(row)) {
          return (
            <div
              onClick={(event) => {
                event.stopPropagation();
              }}
            >
              <ChevronDown />
              {/*  todo: somehow open another row when this is clicked :'(*/}
            </div>
          );
        } else {
          return;
        }
      },
      nonHidable: true,
      width: 15,
      widthMode: { type: RedoTableColumnWidthType.FIXED, width: "15px" },
    },
    {
      alignment: ColumnAlignment.LEFT,
      key: "selected",
      title: (
        <div className={ordersCss.checkboxContainer}>
          <RedoCheckbox
            setValue={toggleAllSelection}
            size={RedoCheckboxSize.SMALL}
            value={someChecked && !allChecked ? "indeterminate" : allChecked}
          />
        </div>
      ),
      renderNormalCell: ({ row }) => {
        return (
          <div
            className={ordersCss.checkboxContainer}
            onClick={(event) => {
              event.stopPropagation();
            }}
          >
            <RedoCheckbox
              onClick={(e) => {
                handleCheckClick(row._id, e);
              }}
              setValue={() => {}}
              size={RedoCheckboxSize.SMALL}
              value={checkedRows.has(row._id)}
            />
          </div>
        );
      },
      width: 24,
      widthMode: { type: RedoTableColumnWidthType.FIXED, width: "24px" },
      nonHidable: true,
    },
    OrderNameColumn,
    CustomerNameColumn,
    OrderDateColumn,
    OrderTotalColumn,
    OrderItemsColumn,
    OrderWeightColumn,
    {
      alignment: ColumnAlignment.LEFT,
      key: "tag",
      title: "Tags",
      renderNormalCell: ({ row }) => {
        return (
          <TagsCell
            availableTagsLoad={availableTagsLoad}
            getAvailableTags={getAvailableTags}
            rowData={row}
            setTagWindowOpen={setTagWindowOpen}
            tagWindowOpen={tagWindowOpen}
            updateRowData={updateRowData}
          />
        );
      },
      width: 150,
    },
    ShopifyOrderTagsColumn,
    ParcelSelectionColumn,
    ShippingSelectionColumn,
    OrderAgeColumn,
    AssignedUserColumn,
    DestinationStateColumn,
    DestinationCountryColumn,
    RateColumn,
    ShippedDateColumn,
    AddressVerificationColumn,
    FraudRiskColumn,
    ItemNameColumn,
    ItemSKUColumn,
    ProductVariantColumn,
    UniqueItemsColumn,
    RequestedServiceColumn,
    ShippingPaidColumn,
    NoteFromBuyerColumn(updateRowData),
    NoteToBuyerColumn(updateRowData),
    InternalNotesColumn(updateRowData),
    FulfillmentLocationColumn,
    StatusColumn,
    isPrintedColumn(PrintKind.PICK_LIST, updateRowData),
    isPrintedColumn(PrintKind.PACKING_SLIP, updateRowData),
    isPrintedColumn(PrintKind.LABEL, updateRowData),
  ];
}
