import { TagPill } from "@redotech/redo-web/tags/tag-pill";

import { ArrayStringFilterOperator } from "@redotech/redo-model/views/advanced-filters/array-string-filter";

import { AdvancedTableFilter } from "@redotech/redo-web/advanced-filters/advanced-filter";

import { Tag } from "@redotech/redo-model/tag";
import { AdvancedFilterType } from "@redotech/redo-model/views/advanced-filters/generic-advanced-filter-data";
import { ArrayStringTableFilter } from "@redotech/redo-web/advanced-filters/advanced-filter";
import TagIcon from "@redotech/redo-web/arbiter-icon/tag-01.svg";

export const TagFilter = (
  fetcher: (
    { filters }: { filters: AdvancedTableFilter[] },
    signal: AbortSignal,
  ) => Promise<Tag[]>,
): ArrayStringTableFilter<Tag> => ({
  type: AdvancedFilterType.ARRAY_TO_STRING,
  itemLabel: "tag",
  operators: [
    ArrayStringFilterOperator.INCLUDES,
    ArrayStringFilterOperator.EXCLUDES,
    ArrayStringFilterOperator.NONE_OF,
    ArrayStringFilterOperator.ALL_OF,
    ArrayStringFilterOperator.ANY_OF,
  ],
  data: {
    name: "tagName",
    type: AdvancedFilterType.ARRAY_TO_STRING,
    value: [],
    operator: ArrayStringFilterOperator.ALL_OF,
  },
  Icon: TagIcon,
  valueFetcher: fetcher,
  ItemRenderer: ({ item }: { item: Tag | null }) => {
    if (!item) return null;
    return <TagPill tag={{ name: item.name, pillTheme: item.pillTheme }} />;
  },
  stringify: (item: Tag) => item.name,
  display: (item: Tag) => item.name,
});
