import { z } from "zod";

export const BuyRequestSchema = z.object({
  fulfillmentGroupId: z.string(),
  lineItems: z.array(z.object({ id: z.string(), quantity: z.number() })),
  shipmentRateId: z.string(),
});
export type BuyRequest = z.infer<typeof BuyRequestSchema>;

export const BuyResponseSchema = z.object({
  success: z.boolean(),
  errorMessage: z.string().nullish(),
});
export type BuyResponse = z.infer<typeof BuyResponseSchema>;

export const buyLabels = {
  input: z.object({
    buyRequests: z.array(BuyRequestSchema),
    userId: z.string(),
    print: z.boolean(),
    insured: z.boolean().nullish(),
    fulfillmentDate: z.date().nullish(),
  }),
  output: z.union([
    z.object({ url: z.string().nullish() }),
    z.object({
      timeout: z.literal(true),
      batchId: z.string(),
      batchNumber: z.string(),
    }),
  ]),
};
