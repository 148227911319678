import { FulfillmentOrderData } from "@redotech/redo-model/fulfillments/fulfillment-group";
import { SortDirection } from "@redotech/redo-model/tables/table";
import { ColumnAlignment } from "@redotech/redo-web/table";
import { Column2 } from "@redotech/redo-web/table2";
import { OrderWeightCell } from "../cells/order-weight-cell";

export const OrderWeightColumn: Column2<FulfillmentOrderData> = {
  key: "weight",
  title: "Weight",
  alignment: ColumnAlignment.LEFT,
  width: 80,
  renderNormalCell: ({ row }) => (
    <OrderWeightCell displayWeightGrams={row.displayWeightGrams ?? 0} />
  ),
  sort: SortDirection.DESC,
};
