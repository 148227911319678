export enum RedoShopifyFulfillmentDisplayStatus {
  /** Displayed as **Attempted delivery**. */
  AttemptedDelivery = "ATTEMPTED_DELIVERY",
  /** Displayed as **Canceled**. */
  Canceled = "CANCELED",
  /** Displayed as **Confirmed**. */
  Confirmed = "CONFIRMED",
  /** Displayed as **Delivered**. */
  Delivered = "DELIVERED",
  /** Displayed as **Failure**. */
  Failure = "FAILURE",
  /** Displayed as **Fulfilled**. */
  Fulfilled = "FULFILLED",
  /** Displayed as **In transit**. */
  InTransit = "IN_TRANSIT",
  /** Displayed as **Label printed**. */
  LabelPrinted = "LABEL_PRINTED",
  /** Displayed as **Label purchased**. */
  LabelPurchased = "LABEL_PURCHASED",
  /** Displayed as **Label voided**. */
  LabelVoided = "LABEL_VOIDED",
  /** Displayed as **Marked as fulfilled**. */
  MarkedAsFulfilled = "MARKED_AS_FULFILLED",
  /** Displayed as **Not delivered**. */
  NotDelivered = "NOT_DELIVERED",
  /** Displayed as **Out for delivery**. */
  OutForDelivery = "OUT_FOR_DELIVERY",
  /** Displayed as **Picked up**. */
  PickedUp = "PICKED_UP",
  /** Displayed as **Ready for pickup**. */
  ReadyForPickup = "READY_FOR_PICKUP",
  /** Displayed as **Submitted**. */
  Submitted = "SUBMITTED",
}
