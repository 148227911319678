import { Navigate, RouteObject } from "react-router-dom";

export const summaryRoutes: RouteObject[] = [
  {
    handle: { breadcrumb: "Summary", breadcrumbNavigate: false },
    async lazy() {
      const { Page } = await import("../app/page");
      return { Component: Page };
    },
    children: [
      {
        index: true,
        lazy: async () => {
          const { Summary } = await import("./summary");
          return { Component: Summary };
        },
      },
      {
        handle: { breadcrumb: "Returns" },
        path: "returns",
        lazy: async () => {
          const { ReturnsSummary } = await import("./returns-summary");
          return { Component: ReturnsSummary };
        },
      },
      { path: "claims", element: <Navigate to="/analytics/claims-summary" /> },
    ],
  },
];
