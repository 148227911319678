import * as z from "zod";
import { ExternalShipmentTracker } from "./trackable";

export const AddressZodSchema = z.object({
  company: z.string().nullish(),
  name: z.string().nullish(),
  phone: z.string().nullish(),
  email: z.string().nullish(),
  street1: z.string().nullish(),
  street2: z.string().nullish(),
  city: z.string().nullish(),
  state: z.string().nullish(),
  zip: z.string(),
  country: z.string().nullish(),
  residential: z.boolean().nullish(),
});
// If you update this, make sure to update the AddressZodSchema
export interface Address {
  company: string | undefined | null;
  name: string | undefined | null;
  phone: string | undefined | null;
  email: string | undefined | null;
  street1: string | undefined | null;
  street2: string | undefined | null;
  city: string | undefined | null;
  state: string | undefined | null;
  zip: string;
  country: string;
  residential: boolean;
}

export const ShipmentParcelZodSchema = z.object({
  length: z.number().nullish(),
  width: z.number().nullish(),
  height: z.number().nullish(),
  weight: z.number(),
});
export interface ShipmentParcel {
  length: number | undefined | null;
  width: number | undefined | null;
  height: number | undefined | null;
  weight: number;
}

export const FormZodSchema = z.object({
  id: z.string().nullish(),
  form_type: z.string().nullish(),
  form_url: z.string().url().nullish(),
});
export interface Form {
  id: string;
  form_type: string;
  form_url: string;
}

export const RateZodSchema = z.object({
  id: z.string(),
  shipment_id: z.string(),
  rate: z.union([z.number(), z.string()]).nullish(),
  currency: z.union([z.number(), z.string()]).nullish(),
  carrier: z.string(),
  service: z.string(),
  delivery_date: z.string().nullish(),
  delivery_days: z.number().nullish(),
  delivery_date_guaranteed: z.boolean().nullish(),
  upcharge: z.string(),
  created_at: z.string(),
  updated_at: z.string(),
  carrier_account_id: z.string().nullish(),
});
export interface Rate {
  id: string;
  shipment_id: string;
  rate: string;
  currency: string;
  carrier: string;
  service: string;
  delivery_date?: string;
  delivery_days?: number;
  delivery_date_guaranteed?: boolean;
  upcharge: string;
  created_at: string;
  updated_at: string;
  carrier_account_id: string;
}

export const LabelZodSchema = z.object({
  id: z.string(),
  label_url: z.union([z.string(), z.string().url()]),
});
export interface Label {
  id: string;
  label_url: string;
  label_date?: string;
}

export interface AddressVerification {
  verifications: {
    delivery: { success: boolean; errors: { message: string }[] };
  };
}

export const ShipmentZodSchema = z.object({
  id: z.string(),
  to_address: AddressZodSchema,
  from_address: AddressZodSchema,
  status: z.string().nullish(),
  parcel: ShipmentParcelZodSchema,
  customs_info: z.any(),
  forms: z.array(FormZodSchema),
  rates: z.array(RateZodSchema),
  selected_rate: RateZodSchema,
  postage_label: LabelZodSchema,
  insurance: z.string().nullish(),
  tracking_code: z.string(),
  tracker: z.any(),
  created_at: z.string(),
  updated_at: z.string(),
});
export interface Shipment {
  id: string;
  to_address: Address;
  from_address: Address;
  status: string;
  parcel: ShipmentParcel;
  customs_info: any;
  forms: Form[];
  rates: Rate[];
  selected_rate: Rate;
  postage_label: Label;
  insurance: string;
  tracking_code: string;
  tracker: ExternalShipmentTracker;
  created_at: string;
  updated_at: string;
}

export interface FulfillmentTracker {
  _tracker: ExternalShipmentTracker;
  fulfillmentID: string;
  locationId?: string;
}

export interface TrackingDetail {
  datetime: string;
  message: string;
  tracking_location: {
    city: string;
    state: string;
    country: string;
    zip: string;
  };
}
