import { PillTheme } from "@redotech/redo-model/pill-theme";
import { RedoModal } from "@redotech/redo-web/arbiter-components/modal/redo-modal";
import { memo, useState } from "react";

import { LoadState } from "@redotech/react-util/load";
import { TagInputFields } from "./tag-fields";

// Tag like object, less restrictive on "source" so we can reuse this component
export type TagWithId = {
  name: string;
  pillTheme: PillTheme;
  tagId: string;
  source: string;
};

export const CreateTagModal = memo(function CreateTagModal({
  cancelClicked,
  tagsLoad,
  handleCreateTag,
}: {
  cancelClicked(): void;
  tagsLoad: LoadState<TagWithId[]>;
  handleCreateTag: ({
    name,
    pillTheme,
  }: {
    name: string;
    pillTheme: PillTheme;
  }) => Promise<void>;
}) {
  const existingTagNames = new Set(
    tagsLoad.value?.map((tag) => tag.name.toLowerCase()),
  );

  const [pillTheme, setPillTheme] = useState<PillTheme>(
    PillTheme.PRIMARY_LIGHT,
  );
  const [name, setName] = useState("");

  async function handleSubmit() {
    await handleCreateTag({ name, pillTheme });
  }

  const nameConflict = existingTagNames.has(name.toLowerCase());

  return (
    <RedoModal
      isOpen
      onModalCloseRequested={cancelClicked}
      primaryButton={{
        text: "Create",
        onClick: handleSubmit,
        disabled: !name || nameConflict,
      }}
      secondaryButton={{ text: "Cancel", onClick: cancelClicked }}
      title="Create tag"
    >
      <TagInputFields
        name={name}
        pillTheme={pillTheme}
        setName={setName}
        setPillTheme={setPillTheme}
        showNameError={nameConflict}
      />
    </RedoModal>
  );
});
