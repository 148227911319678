// extracted by mini-css-extract-plugin
export var accent = "_2r-4";
export var add = "_2r-j";
export var addIcon = "_2r-k";
export var deleteIcon = "_2r-i";
export var disabled = "_2r-9";
export var ellipsis = "_2r-f";
export var error = "_2r-3";
export var form = "_2r-5";
export var hideFocus = "_2r-2";
export var icon = "_2r-7";
export var input = "_2r-e";
export var listItem = "_2r-h";
export var mediumHeight = "_2r-b";
export var noBorder = "_2r-8";
export var noRadius = "_2r-1";
export var shortHeight = "_2r-a";
export var tallHeight = "_2r-c";
export var textInput = "_2r-0";
export var vertical = "_2r-g";
export var veryTallHeight = "_2r-d";
export var widget = "_2r-6";