import { Parcel } from "@redotech/redo-model/outbound-labels/parcel";
import { Flex } from "@redotech/redo-web/flex";
import { Text } from "@redotech/redo-web/text";
import { memo, useMemo } from "react";
import { getParcelName } from "../../utils/outbound-labels-utils";

export const SelectedParcelCell = memo(function SelectedParcelCell({
  selectedParcel,
}: {
  selectedParcel: Parcel | null;
}) {
  const name = useMemo(() => {
    return selectedParcel
      ? getParcelName(selectedParcel)
      : "No parcel selected";
  }, [selectedParcel]);

  return (
    <Flex align="center" p="xl">
      <Text fontSize="xs">{name}</Text>
    </Flex>
  );
});
