import { z } from "zod";
import {
  convertWeight,
  LengthUnit,
  LengthUnitSchema,
  Weight,
  WeightUnit,
  WeightUnitSchema,
} from "./util";

export enum CustomParcelType {
  BOX = "box",
  ENVELOPE = "envelope",
  SOFT_PACK = "soft_pack",
}
export const CustomParcelTypeSchema = z.nativeEnum(CustomParcelType);

export enum ParcelType {
  CUSTOM = "custom",
  CARRIER = "carrier",
}
export const ParcelTypeSchema = z.nativeEnum(ParcelType);

export const BaseParcel = z.object({
  name: z.string(),
  parcelType: ParcelTypeSchema,
  default: z.boolean().nullish(),
});

export const CustomParcelSchema = BaseParcel.extend({
  parcelType: z.literal(ParcelType.CUSTOM),
  type: CustomParcelTypeSchema,
  length: z.number(),
  width: z.number(),
  height: z.number(),
  lengthUnit: LengthUnitSchema,
  weight: z.number(),
  weightUnit: WeightUnitSchema,
});
export type CustomParcel = z.infer<typeof CustomParcelSchema>;

export const CarrierParcelSchema = BaseParcel.extend({
  parcelType: z.literal(ParcelType.CARRIER),
  carrier: z.string(),
  description: z.string().nullable(),
  dimensions: z.array(z.string()),
  human_readable: z.string().nullable(),
  max_weight: z.number().nullable(),
});
export type CarrierParcel = z.infer<typeof CarrierParcelSchema>;

export const ParcelSchema = z.discriminatedUnion("parcelType", [
  CustomParcelSchema,
  CarrierParcelSchema,
]);
export type Parcel = CarrierParcel | CustomParcel;

export function getPackageWeightInOunces(parcel: CustomParcel): number {
  return convertWeight(
    { value: parcel.weight, unit: parcel.weightUnit },
    WeightUnit.OUNCE,
  ).value;
}

export function getPackageDimensionInInches(parcel: CustomParcel): {
  height: number;
  width: number;
  length: number;
} {
  return {
    height:
      parcel?.lengthUnit === LengthUnit.INCH
        ? parcel.height
        : parcel.height / 2.54,
    width:
      parcel?.lengthUnit === LengthUnit.INCH
        ? parcel.width
        : parcel.width / 2.54,
    length:
      parcel?.lengthUnit === LengthUnit.INCH
        ? parcel.length
        : parcel.length / 2.54,
  };
}

export function standardizePackageDimensions(parcel: Parcel): Parcel {
  if (parcel.parcelType === ParcelType.CARRIER) {
    return parcel;
  } else {
    const { height, width, length } = getPackageDimensionInInches(
      parcel as CustomParcel,
    );
    return {
      ...parcel,
      weight: getPackageWeightInOunces(parcel as CustomParcel),
      height,
      width,
      length,
      lengthUnit: LengthUnit.INCH,
      weightUnit: WeightUnit.OUNCE,
    };
  }
}

// https://developer.fedex.com/api/en-us/catalog/rate/v1/docs.html
export enum FedexOneRateParcel {
  FEDEX_ENVELOPE = "FEDEX_ENVELOPE",
  FEDEX_SMALL_BOX = "FEDEX_SMALL_BOX",
  FEDEX_MEDIUM_BOX = "FEDEX_MEDIUM_BOX",
  FEDEX_LARGE_BOX = "FEDEX_LARGE_BOX",
  FEDEX_PAK = "FEDEX_PAK",
  FEDEX_EXTRA_LARGE_BOX = "FEDEX_EXTRA_LARGE_BOX",
}

export function predefinedPackageToOneRateParcel(
  predefinedPackage: string | null | undefined,
): FedexOneRateParcel | null {
  if (!predefinedPackage) {
    return null;
  }
  switch (predefinedPackage) {
    case "FedExPak":
      return FedexOneRateParcel.FEDEX_PAK;
    case "FedExSmallBox":
      return FedexOneRateParcel.FEDEX_SMALL_BOX;
    case "FedExMediumBox":
      return FedexOneRateParcel.FEDEX_MEDIUM_BOX;
    case "FedExLargeBox":
      return FedexOneRateParcel.FEDEX_LARGE_BOX;
    case "FedExExtraLargeBox":
      return FedexOneRateParcel.FEDEX_EXTRA_LARGE_BOX;
    case "FedExEnvelope":
      return FedexOneRateParcel.FEDEX_ENVELOPE;
    default:
      return null;
  }
}

type ParcelDimension = {
  length: number | null | undefined;
  width: number | null | undefined;
  height: number | null | undefined;
  lengthUnit: LengthUnit;
  parcelType: CustomParcelType | undefined;
};

//https://docs.easypost.com/carriers/fedex-guide#predefined-packages
const oneRateFedexParcelDimensions: Record<
  FedexOneRateParcel,
  ParcelDimension[]
> = {
  FEDEX_ENVELOPE: [
    {
      length: 9.5,
      width: 12.5,
      height: 0,
      lengthUnit: LengthUnit.INCH,
      parcelType: CustomParcelType.ENVELOPE,
    },
  ],
  FEDEX_PAK: [
    {
      length: 12.5,
      width: 15.5,
      height: 0,
      lengthUnit: LengthUnit.INCH,
      parcelType: CustomParcelType.SOFT_PACK,
    },
  ],
  FEDEX_SMALL_BOX: [
    {
      length: 12.25,
      width: 10.9,
      height: 1.5,
      lengthUnit: LengthUnit.INCH,
      parcelType: CustomParcelType.BOX,
    },
    {
      length: 8.75,
      width: 2.63,
      height: 11.25,
      lengthUnit: LengthUnit.INCH,
      parcelType: CustomParcelType.BOX,
    },
  ],
  FEDEX_MEDIUM_BOX: [
    {
      length: 13.25,
      width: 11.5,
      height: 2.38,
      lengthUnit: LengthUnit.INCH,
      parcelType: CustomParcelType.BOX,
    },
    {
      length: 8.75,
      width: 4.38,
      height: 11.25,
      lengthUnit: LengthUnit.INCH,
      parcelType: CustomParcelType.BOX,
    },
  ],
  FEDEX_LARGE_BOX: [
    {
      length: 17.88,
      width: 12.38,
      height: 3,
      lengthUnit: LengthUnit.INCH,
      parcelType: CustomParcelType.BOX,
    },
    {
      length: 8.75,
      width: 7.75,
      height: 11.25,
      lengthUnit: LengthUnit.INCH,
      parcelType: CustomParcelType.BOX,
    },
  ],
  FEDEX_EXTRA_LARGE_BOX: [
    {
      length: 11.88,
      width: 10.75,
      height: 11,
      lengthUnit: LengthUnit.INCH,
      parcelType: CustomParcelType.BOX,
    },
    {
      length: 15.25,
      width: 14.13,
      height: 6,
      lengthUnit: LengthUnit.INCH,
      parcelType: CustomParcelType.BOX,
    },
  ],
};

// Finds the smallest FedexOneRateParcel that can fit the custom parcel
// If the custom parcel is two dimensional, we let it fit any of the one rate parcels
// if the parcel is not two dimensional, we skip any one rate parcels that are not two dimensional
export function getMatchingOneRateParcel(
  parcel: ParcelDimension,
): FedexOneRateParcel | null {
  const inputVolume = getVolume(parcel);

  for (const [packageName, dimensionOptions] of Object.entries(
    oneRateFedexParcelDimensions,
  )) {
    for (const dimensionOption of dimensionOptions) {
      if (dimensionOption.parcelType !== parcel.parcelType) {
        continue;
      }
      const dimensionOptionVolume = getVolume(dimensionOption);
      if (inputVolume <= dimensionOptionVolume) {
        return packageName as FedexOneRateParcel;
      }
    }
  }
  return null;
}

function getVolume(parcel: ParcelDimension): number {
  return (parcel.width || 1) * (parcel.height || 1) * (parcel.length || 1);
}

export function getParcelWeightGrams(parcel: Parcel | null): Weight {
  // If we want to do this for carrier parcels, we will have to go figure out the weight of each type of parcel
  // Easypost does not provide that information via the API
  if (!parcel || parcel.parcelType === ParcelType.CARRIER) {
    return { value: 0, unit: WeightUnit.GRAM };
  }
  return convertWeight(
    { value: parcel.weight, unit: parcel.weightUnit },
    WeightUnit.GRAM,
  );
}

export function areParcelsEqual(a: Parcel, b: Parcel) {
  if (
    a.parcelType === ParcelType.CARRIER &&
    b.parcelType === ParcelType.CARRIER
  ) {
    return a.carrier === b.carrier && a.name === b.name;
  } else if (
    a.parcelType === ParcelType.CUSTOM &&
    b.parcelType === ParcelType.CUSTOM
  ) {
    return (
      a.weight === b.weight &&
      a.width === b.width &&
      a.height === b.height &&
      a.length === b.length &&
      a.lengthUnit === b.lengthUnit &&
      a.weightUnit === b.weightUnit
    );
  } else {
    return false;
  }
}
