import { Text } from "@redotech/redo-web/text";
import { Tooltip } from "@redotech/redo-web/tooltip/tooltip";
import { sinkPromise } from "@redotech/util/promise";
import {
  ForwardedRef,
  forwardRef,
  memo,
  useEffect,
  useRef,
  useState,
} from "react";
import { copyToClipboard } from "./clipboard";
import { CopyableTooltip } from "./copyable-tooltip";
import * as copyableTooltipCss from "./copyable-tooltip.module.css";
/**
 * Backed by regular redo-web Text component, but gives a helper to copy text on click.
 * Adds an extra constraint that the children must be a string.
 */
export const CopyableText = memo(
  forwardRef(function CopyableText(
    {
      children,
      placement,
      /** if you want to copy a different string than you see */
      altTextToCopy,
      preventEventPropagation = false,
      ...otherTextProps
    }: {
      children: string;
      placement?: Parameters<typeof Tooltip>[0]["placement"];
      altTextToCopy?: string;
      preventEventPropagation?: boolean;
    } & Parameters<typeof Text>[0],
    ref: ForwardedRef<HTMLDivElement>,
  ) {
    const originalTextToCopy = `Click to copy ${altTextToCopy ?? children}`;
    const postCopiedText = "Copied!";
    const [isInCopiedState, setIsInCopiedState] = useState(false);
    const timeoutRef = useRef<NodeJS.Timeout | null>(null);

    useEffect(() => {
      setIsInCopiedState(false);
    }, [altTextToCopy, children, originalTextToCopy]);

    useEffect(() => {
      // Cleanup timeout if the component unmounts or `children`/`altTextToCopy` change
      return () => {
        if (timeoutRef.current) {
          clearTimeout(timeoutRef.current);
        }
      };
    }, [children, altTextToCopy]);

    const handleClick = (e: React.MouseEvent) => {
      if (preventEventPropagation) {
        e.preventDefault();
        e.stopPropagation();
      }

      if (!isInCopiedState) {
        setIsInCopiedState(true);
        sinkPromise(copyToClipboard(altTextToCopy ?? children));

        if (timeoutRef.current) {
          clearTimeout(timeoutRef.current);
        }

        timeoutRef.current = setTimeout(() => {
          setIsInCopiedState(false);
        }, 3000);
      }
    };

    return (
      <CopyableTooltip
        textToCopy={isInCopiedState ? postCopiedText : originalTextToCopy}
      >
        <Text
          {...otherTextProps}
          className={copyableTooltipCss.underlined}
          onClick={handleClick}
          ref={ref}
        >
          {children}
        </Text>
      </CopyableTooltip>
    );
  }),
);
