import { ClickAwayListener } from "@mui/material";
import { isUserOnMac } from "@redotech/util/browser-agent";
import * as classnames from "classnames";
import {
  JSXElementConstructor,
  memo,
  ReactElement,
  useEffect,
  useLayoutEffect,
  useState,
} from "react";
import { RedoButton } from "../arbiter-components/buttons/redo-button";
import { RedoList } from "../arbiter-components/list/redo-list";
import { RedoListItemSize } from "../arbiter-components/list/redo-list-item";
import BoldIcon from "../arbiter-icon/bold-01.svg";
import DotsHorizontal from "../arbiter-icon/dots-horizontal.svg";
import FaceSmile from "../arbiter-icon/face-smile.svg";
import ImageIcon from "../arbiter-icon/image-03_filled.svg";
import ItalicIcon from "../arbiter-icon/italic-01_filled.svg";
import AddIndentIcon from "../arbiter-icon/left-indent-01_filled.svg";
import LinkIcon from "../arbiter-icon/link-03_filled.svg";
import BulletedListIcon from "../arbiter-icon/list_filled.svg";
import OrderedListIcon from "../arbiter-icon/numbered-list_filled.svg";
import RemoveIndentIcon from "../arbiter-icon/right-indent-01_filled.svg";
import StrikeIcon from "../arbiter-icon/strikethrough-01_filled.svg";
import UnderlineIcon from "../arbiter-icon/underline-01_filled.svg";
import { Dropdown } from "../dropdown";
import { Flex } from "../flex";
import { Tooltip } from "../tooltip/tooltip";
import * as quillEditorCss from "./quill-editor.module.css";
import * as quillToolbarOptionsCss from "./quill-toolbar-options.module.css";

const INITIAL_HORIZONTAL_PADDING = 24;
// If element is being currently being hidden and we are resizing use the default button width
const DEFAULT_BUTTON_WIDTH = 28;
const SPACE_FOR_OVERFLOW_BUTTON = 28;
const PADDING_BETWEEN_ITEMS = 8;

// Icon and button title are used when button is in collapsed menu
export type QuillToolbarItem = {
  identifier: string;
  element: ReactElement;
  buttonTitle: string;
  icon: JSXElementConstructor<any> | undefined;
  tooltipWhenCollapsed?: string;
};
/**
 * Across the usage of our quill editors, we commonly provide these options. Any that are specific
 * can be passed in as additional toolbar items.
 */
export const QuillToolbarOptions = memo(function QuillToolbarOptions({
  emailMode,
  toolbarId,
  additionalToolbarItems = [],
}: {
  emailMode: boolean;
  toolbarId: string;
  additionalToolbarItems?: QuillToolbarItem[];
}) {
  const isMac = isUserOnMac();

  const noncollapsibleItems: QuillToolbarItem[] = [
    {
      icon: undefined,
      identifier: "ql-formats",
      buttonTitle: "Font",
      element: (
        <span className="ql-formats">
          <select
            className={classnames(
              "ql-font",
              quillToolbarOptionsCss.select,
              quillToolbarOptionsCss.formatSelect,
            )}
          >
            <option value="sans-serif">Sans Serif</option>
            <option value="serif">Serif</option>
            <option value="monospace">Monospace</option>
            <option value="arial">Arial</option>
            <option value="courier-new">Courier New</option>
            <option value="georgia">Georgia</option>
            <option value="lucida-sans">Lucida Sans</option>
            <option value="tahoma">Tahoma</option>
            <option value="times-new-roman">Times New Roman</option>
            <option value="verdana">Verdana</option>
          </select>
        </span>
      ),
    },
    {
      icon: undefined,
      identifier: "ql-size",
      buttonTitle: "Font size",
      element: (
        <select
          className={classnames(
            "ql-size",
            quillToolbarOptionsCss.select,
            quillToolbarOptionsCss.fontSizeSelect,
          )}
          defaultValue="16px"
        >
          <option value="12px">Small</option>
          <option value="16px">Normal</option>
          <option value="24px">Large</option>
          <option value="36px">Huge</option>
        </select>
      ),
    },
    {
      icon: undefined,
      identifier: "ql-color",
      buttonTitle: "Text color",
      element: (
        <select
          className={classnames("ql-color", quillToolbarOptionsCss.select)}
        />
      ),
    },
    {
      icon: undefined,
      identifier: "align",
      buttonTitle: "Text alignment",
      element: (
        <select
          className={classnames("ql-align", quillToolbarOptionsCss.select)}
        >
          <option value="" />
          <option value="center" />
          <option value="right" />
          <option value="justify" />
        </select>
      ),
    },
    {
      icon: FaceSmile,
      identifier: "emoji",
      buttonTitle: "Emoji",
      element: (
        <Tooltip arrow title={`Insert emoji (${isMac ? "⌘" : "Ctrl"}+3)`}>
          <div className={quillToolbarOptionsCss.buttonContainer}>
            <RedoButton
              buttonClassName="rql-emoji"
              buttonId="emoji"
              IconLeading={FaceSmile}
            />
          </div>
        </Tooltip>
      ),
    },
  ];

  const allToolbarItems: QuillToolbarItem[] = [
    ...noncollapsibleItems,
    {
      icon: BoldIcon,
      identifier: "ql-bold",
      buttonTitle: "Bold",
      element: (
        <Tooltip arrow title="Bold">
          <div className={quillToolbarOptionsCss.buttonContainer}>
            <RedoButton
              buttonClassName={classnames(
                "ql-bold",
                quillToolbarOptionsCss.bold,
              )}
              buttonId="ql-bold"
            />
          </div>
        </Tooltip>
      ),
    },
    {
      icon: ItalicIcon,
      identifier: "ql-italic",
      buttonTitle: "Italic",
      element: (
        <Tooltip arrow title="Italic">
          <div className={quillToolbarOptionsCss.buttonContainer}>
            <RedoButton buttonClassName="ql-italic" buttonId="ql-italic" />
          </div>
        </Tooltip>
      ),
    },
    {
      icon: UnderlineIcon,
      identifier: "ql-underline",
      buttonTitle: "Underline",
      element: (
        <Tooltip arrow title="Underline">
          <div className={quillToolbarOptionsCss.buttonContainer}>
            <RedoButton
              buttonClassName="ql-underline"
              buttonId="ql-underline"
            />
          </div>
        </Tooltip>
      ),
    },
    {
      icon: StrikeIcon,
      identifier: "ql-strike",
      buttonTitle: "Strikethrough",
      element: (
        <Tooltip arrow title="Strikethrough">
          <div className={quillToolbarOptionsCss.buttonContainer}>
            <RedoButton buttonClassName="ql-strike" buttonId="ql-strike" />
          </div>
        </Tooltip>
      ),
    },

    {
      icon: LinkIcon,
      identifier: "ql-link",
      buttonTitle: "Link",
      element: (
        <Tooltip arrow title="Link">
          <div className={quillToolbarOptionsCss.buttonContainer}>
            <RedoButton buttonClassName="ql-link" buttonId="ql-link" />
          </div>
        </Tooltip>
      ),
    },
    {
      icon: OrderedListIcon,
      identifier: "ordered-list",
      buttonTitle: "Ordered list",
      element: (
        <Tooltip arrow title="Ordered list">
          <div className={quillToolbarOptionsCss.buttonContainer}>
            <RedoButton
              buttonClassName="ql-list"
              buttonId="ordered-list"
              buttonValue="ordered"
            />
          </div>
        </Tooltip>
      ),
    },
    {
      icon: BulletedListIcon,
      identifier: "bulleted-list",
      buttonTitle: "Bulleted list",
      element: (
        <Tooltip arrow title="Bulleted list">
          <div className={quillToolbarOptionsCss.buttonContainer}>
            <RedoButton
              buttonClassName="ql-list"
              buttonId="bulleted-list"
              buttonValue="bullet"
            />
          </div>
        </Tooltip>
      ),
    },
    {
      icon: RemoveIndentIcon,
      identifier: "remove-indent",
      buttonTitle: "Remove indent",
      element: (
        <Tooltip arrow title="Remove indent">
          <div className={quillToolbarOptionsCss.buttonContainer}>
            <RedoButton
              buttonClassName="ql-indent"
              buttonId="remove-indent"
              buttonValue="-1"
            />
          </div>
        </Tooltip>
      ),
    },
    {
      icon: AddIndentIcon,
      identifier: "add-indent",
      buttonTitle: "Add indent",
      element: (
        <Tooltip arrow title="Add indent">
          <div className={quillToolbarOptionsCss.buttonContainer}>
            <RedoButton
              buttonClassName="ql-indent"
              buttonId="add-indent"
              buttonValue="+1"
            />
          </div>
        </Tooltip>
      ),
    },
    {
      icon: ImageIcon,
      identifier: "image",
      buttonTitle: "Image",
      element: (
        <Tooltip arrow title="Image">
          <div className={quillToolbarOptionsCss.buttonContainer}>
            <RedoButton buttonClassName="ql-image" buttonId="image" />
          </div>
        </Tooltip>
      ),
    },
    ...additionalToolbarItems,
  ];

  const [menuItemsBeingShown, setMenuItemsBeingShown] =
    useState<QuillToolbarItem[]>(allToolbarItems);
  const [overflowMenuItems, setOverflowMenuItems] = useState<
    QuillToolbarItem[]
  >([]);

  const getRenderedItems = () => {
    if (!menuItemsBeingShown.length) {
      setMenuItemsBeingShown(allToolbarItems);
    }
    let usedWidth = INITIAL_HORIZONTAL_PADDING;
    const toolbarOptions = document.getElementById(toolbarId);
    if (toolbarOptions) {
      const maxToolbarWidth = toolbarOptions.offsetWidth;
      const toolbarOptionsElement = document.getElementById("toolbar-options");
      if (toolbarOptionsElement) {
        let indexOfLastVisibleItem = 0;
        for (
          ;
          indexOfLastVisibleItem < toolbarOptionsElement.children.length;
          indexOfLastVisibleItem++
        ) {
          const element =
            toolbarOptionsElement.children[indexOfLastVisibleItem];
          const newWidthAdded =
            (element.clientWidth || DEFAULT_BUTTON_WIDTH) +
            PADDING_BETWEEN_ITEMS;
          // Make room for overflow button
          if (
            usedWidth + newWidthAdded >
            maxToolbarWidth - SPACE_FOR_OVERFLOW_BUTTON
          ) {
            break;
          }
          usedWidth += newWidthAdded;
        }
        if (indexOfLastVisibleItem < allToolbarItems.length - 1) {
          setMenuItemsBeingShown(
            allToolbarItems.slice(0, indexOfLastVisibleItem),
          );
          setOverflowMenuItems(allToolbarItems.slice(indexOfLastVisibleItem));
        } else {
          setMenuItemsBeingShown(allToolbarItems);
          setOverflowMenuItems([]);
        }
      }
    }
  };

  /* eslint-disable react-hooks/exhaustive-deps */
  useLayoutEffect(() => {
    const observer = new ResizeObserver(getRenderedItems);
    observer.observe(document.getElementById(toolbarId) as HTMLElement);
    return () => observer.disconnect();
  }, []);

  useEffect(() => {
    getRenderedItems();
  }, []);
  /* eslint-enable react-hooks/exhaustive-deps */

  return (
    <div className={quillEditorCss.quillToolbar} id={toolbarId}>
      <Flex align="center" id="toolbar-options">
        {emailMode ? (
          <>
            {allToolbarItems.map((item, index) => (
              <div
                key={index}
                style={{
                  display:
                    overflowMenuItems.some(
                      (item_) => item_.identifier === item.identifier,
                    ) &&
                    !noncollapsibleItems.some(
                      (item_) => item_.identifier === item.identifier,
                    )
                      ? "none"
                      : "inherit",
                }}
              >
                {item.element}
              </div>
            ))}
            {overflowMenuItems.length > 0 && (
              <CollapsedActions
                collapsedItems={overflowMenuItems}
                toolbarId={toolbarId}
              />
            )}
          </>
        ) : (
          <>
            <Tooltip arrow title={`Insert emoji (${isMac ? "⌘" : "Ctrl"}+3)`}>
              <div className={quillToolbarOptionsCss.buttonContainer}>
                <RedoButton
                  buttonClassName="rql-emoji"
                  buttonId="emoji"
                  IconLeading={FaceSmile}
                />
              </div>
            </Tooltip>
            {additionalToolbarItems &&
              additionalToolbarItems.map((child) => child.element)}
          </>
        )}
      </Flex>
    </div>
  );
});

export const CollapsedActions = memo(function CollapsedActions({
  collapsedItems,
  toolbarId,
}: {
  collapsedItems: QuillToolbarItem[];
  toolbarId: string;
}) {
  const [menuOpen, setMenuOpen] = useState(false);
  const [dropdownRef, setDropdownRef] = useState<HTMLDivElement | null>(null);
  const [focusedIndex, setFocusedIndex] = useState<number | undefined>();

  const handleClick = (identifier: string) => {
    const toolbarElement = document.getElementById(toolbarId);
    const buttonToPress = toolbarElement?.querySelector(
      `#${identifier}`,
    ) as HTMLButtonElement;
    if (buttonToPress) {
      buttonToPress.click();
    }
    setMenuOpen(false);
  };

  return (
    <ClickAwayListener onClickAway={() => setMenuOpen(false)}>
      <div>
        <div ref={setDropdownRef}>
          <RedoButton
            IconLeading={DotsHorizontal}
            onClick={() => {
              setMenuOpen(!menuOpen);
            }}
          />
        </div>
        <Dropdown anchor={dropdownRef} fitToAnchor={false} open={menuOpen}>
          <Flex dir="column" gap="sm">
            <RedoList
              focusedIndex={focusedIndex}
              isItemSelected={() => false}
              items={collapsedItems.map((item) => ({ value: item }))}
              itemSelected={(item) => {
                handleClick(item.value.identifier);
              }}
              refToListenTo={null}
              setFocusedIndex={setFocusedIndex}
              size={RedoListItemSize.SMALL}
            >
              {(option) => {
                const menuItem = (
                  <Flex align="center" gap="md" w="full">
                    {option.value.icon && (
                      <option.value.icon
                        className={quillToolbarOptionsCss.icon}
                      />
                    )}
                    <span>{option.value.buttonTitle}</span>
                  </Flex>
                );
                if (option.value.tooltipWhenCollapsed) {
                  return (
                    <Tooltip
                      arrow
                      placement="top"
                      title={option.value.tooltipWhenCollapsed}
                    >
                      {menuItem}
                    </Tooltip>
                  );
                } else {
                  return menuItem;
                }
              }}
            </RedoList>
          </Flex>
        </Dropdown>
      </div>
    </ClickAwayListener>
  );
});
