// extracted by mini-css-extract-plugin
export var chip = "_2j-9";
export var chipLabel = "_2j-a";
export var close = "_2j-7";
export var closeIcon = "_2j-8";
export var error = "_2j-6";
export var infoTooltip = "_2j-2";
export var innerContainer = "_2j-1";
export var input = "_2j-b";
export var medium = "_2j-5";
export var outerContainer = "_2j-0";
export var small = "_2j-4";
export var xSmall = "_2j-3";