import { Team } from "./team";

export enum MarketingChannel {
  EMAIL = "email",
  SMS = "sms",
}

export const SMS_SUFFIX = "\nSTOP to stop";

export function getSmsPrefix(team: Pick<Team, "settings" | "name">): string {
  const name = team.settings.marketing?.smsStoreName || team.name;
  return name ? `${name}: ` : "";
}
