// extracted by mini-css-extract-plugin
export var bubble = "_32-0";
export var container = "_32-3";
export var contrastBorder = "_32-4";
export var greyOut = "_32-2";
export var initialsWrapper = "_32-1";
export var large = "_32-9";
export var medium = "_32-8";
export var mediumSmall = "_32-7";
export var micro = "_32-5";
export var small = "_32-6";
export var tiny = "_32-b";
export var xTiny = "_32-a";