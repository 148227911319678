import { LineItem } from "@redotech/redo-model/order";
import { PillTheme } from "@redotech/redo-model/pill-theme";
import { Text } from "@redotech/redo-web/text";
import { memo } from "react";
import { ButtonSize, IconButton } from "./button";
import { CURRENCY_FORMAT } from "./currency";
import { Divider } from "./divider";
import { Flex } from "./flex";
import EditPencilIcon from "./icon-old/edit-pencil2.svg";
import XIcon from "./icon-old/x-big.svg";
import * as orderLineItemCss from "./order-line-item.module.css";
import { Pill, PillSize } from "./pill";

export const OrderLineItem = memo(function OrderLineItem({
  index,
  lineItem,
  amountRefundable,
  handleLineItemRemoval,
  onEditClick,
  useFulfillableQuantity = false,
  editedQuantity,
}: {
  index: number;
  lineItem: LineItem;
  amountRefundable?: string;
  handleLineItemRemoval?: (lineItem: LineItem) => void;
  onEditClick?: () => void;
  useFulfillableQuantity?: boolean;
  editedQuantity?: number;
}) {
  return (
    <>
      <div className={orderLineItemCss.lineItem} key={index}>
        <div className={orderLineItemCss.lineItemInfo}>
          <img
            alt={lineItem.title}
            className={orderLineItemCss.lineItemImage}
            src={lineItem.image?.src}
          />
          <div className={orderLineItemCss.lineItemText}>
            <div className={orderLineItemCss.titleRow}>
              {lineItem.title}
              {useFulfillableQuantity &&
                lineItem.fulfillable_quantity === 0 &&
                lineItem.fulfillment_status === null && (
                  <Pill size={PillSize.NEAR_SQUARE} theme={PillTheme.DANGER}>
                    Removed
                  </Pill>
                )}
            </div>
            <Flex className={orderLineItemCss.gray}>
              {lineItem.quantity}
              {useFulfillableQuantity &&
              lineItem.fulfillable_quantity !== lineItem.quantity
                ? ` (${lineItem.fulfillable_quantity} fulfillable)`
                : ""}
              {editedQuantity || editedQuantity === 0 ? (
                <span
                  className={orderLineItemCss.warning}
                >{` (change to ${editedQuantity})`}</span>
              ) : (
                ""
              )}
              {lineItem.variant_title ? "  |  " + lineItem.variant_title : ""}
              {amountRefundable && (
                <Text>
                  | Max amount refundable:{" "}
                  {CURRENCY_FORMAT().format(Number(amountRefundable))}
                </Text>
              )}
            </Flex>
          </div>
        </div>
        {handleLineItemRemoval && lineItem.fulfillable_quantity > 0 && (
          <div className={orderLineItemCss.lineItemActions}>
            <IconButton onClick={onEditClick} size={ButtonSize.SMALL}>
              <EditPencilIcon />
            </IconButton>
            <IconButton
              onClick={() => handleLineItemRemoval(lineItem)}
              size={ButtonSize.SMALL}
            >
              <XIcon />
            </IconButton>
          </div>
        )}
      </div>
      <Divider />
    </>
  );
});
