import { ExpandedConversation } from "@redotech/redo-model/conversation";
import { filterTruthy, unique } from "@redotech/util/array";
import { useObservableState } from "observable-hooks";
import {
  createContext,
  memo,
  ReactNode,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react";
import { ConversationFetcher } from "../conversation-fetcher";
import { ActiveViewContext } from "../conversations-table-filters/active-view-context";
import { ActiveViewConversationCountsContext } from "../conversations-table-filters/conversation-counts-context";
import { UniqueFiltersContext } from "../conversations-table-filters/filters-context";

export interface ConversationsTableSelection {
  selectedIds: string[];
  deselectedIds: string[];
  selectedRecords: ExpandedConversation[];
  deselectedRecords: ExpandedConversation[];
  addToSelection: (ids: string[]) => void;
  removeFromSelection: (ids: string[]) => void;
  clearSelection: () => void;
  selectAll: () => void;
  selectAllMode: boolean;
  totalConversations: number;
}

export const ConversationsTableSelectionContext = createContext<
  ConversationsTableSelection | undefined
>(undefined);
export const ConversationsTableSelection = memo(
  function ConversationsTableSelection({
    children,
    fetcher,
  }: {
    children: ReactNode;
    fetcher: ConversationFetcher;
  }) {
    const conversationCounts = useContext(ActiveViewConversationCountsContext);
    const view = useContext(ActiveViewContext);
    const filters = useContext(UniqueFiltersContext);

    const conversationRecordsMap = useObservableState(
      fetcher.conversationIdToConversation,
      new Map<string, ExpandedConversation>(),
    );

    useEffect(() => {
      clearSelection();
    }, [filters]);

    const [selectedIds, setSelectedIds] = useState<string[]>([]);

    const [deselectedIds, setDeselectedIds] = useState<string[]>([]);

    const [selectAllMode, setSelectAllMode] = useState(false);

    const selectedRecords: ExpandedConversation[] = useMemo(() => {
      return filterTruthy(
        selectedIds.map((id) => conversationRecordsMap.get(id)),
      );
    }, [conversationRecordsMap, selectedIds]);

    const deselectedRecords: ExpandedConversation[] = useMemo(() => {
      return filterTruthy(
        deselectedIds.map((id) => conversationRecordsMap.get(id)),
      );
    }, [conversationRecordsMap, deselectedIds]);

    const totalConversations = useMemo(() => {
      return conversationCounts
        ? (conversationCounts[`${view.name}-${filters.status}`] ?? 0)
        : 0;
    }, [conversationCounts, view, filters]);

    const clearSelection = useMemo(() => {
      return () => {
        setSelectedIds([]);
        setDeselectedIds([]);
        setSelectAllMode(false);
      };
    }, []);

    useEffect(() => {
      if (selectAllMode && deselectedIds.length === totalConversations) {
        clearSelection();
      }
    }, [deselectedIds, totalConversations, selectAllMode]);

    const selection: ConversationsTableSelection = useMemo(() => {
      return {
        selectedIds,
        deselectedIds,
        selectedRecords,
        deselectedRecords,
        addToSelection: (ids: string[]) => {
          setSelectedIds((prev) => unique([...prev, ...ids]));
          setDeselectedIds((prev) => prev.filter((id) => !ids.includes(id)));
        },
        removeFromSelection: (ids: string[]) => {
          setSelectedIds((prev) => prev.filter((id) => !ids.includes(id)));
          setDeselectedIds((prev) => unique([...prev, ...ids]));
        },
        clearSelection,
        selectAll: () => {
          setSelectedIds([]);
          setDeselectedIds([]);
          setSelectAllMode(true);
        },
        selectAllMode,
        totalConversations,
      };
    }, [
      selectedIds,
      selectedRecords,
      selectAllMode,
      totalConversations,
      clearSelection,
      deselectedIds,
    ]);

    return (
      <ConversationsTableSelectionContext.Provider value={selection}>
        {children}
      </ConversationsTableSelectionContext.Provider>
    );
  },
);
