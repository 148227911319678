import {
  AddressSchema,
  NewOrderDataSchema,
} from "@redotech/redo-model/draft-return/draft-return";
import { z } from "zod";

export const calculateNewOrderValue = {
  input: z.object({
    orderId: z.string(),
    discountCodes: z.array(z.string()),
    useAutomaticDiscounts: z.boolean(),
    address: AddressSchema.optional(),
    items: z.array(z.object({ quantity: z.number(), variantId: z.string() })),
  }),
  output: z.object({ newOrderData: NewOrderDataSchema }),
};
