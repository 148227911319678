export const campaignRecipientColumnKeys = [
  "contact",
  "name",
  "opens",
  "clicks",
  "upsellCount",
  "upsellRevenue",
] as const;
export type CampaignRecipientColumnKey =
  (typeof campaignRecipientColumnKeys)[number];
