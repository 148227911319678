import { ConciergeProductKnowledgeParser } from "@redotech/redo-model/concierge-settings/product-knowledge";
import { z } from "zod";

export const getProductSettings = {
  input: z.object({ identifier: z.string() }),
  output: z.object({
    productName: z.string(),
    hidden: z.boolean(),
    knowledge: ConciergeProductKnowledgeParser.optional(),
  }),
};
