import { ArrayStringFilterOperator } from "@redotech/redo-model/views/advanced-filters/array-string-filter";
import { AdvancedFilterType } from "@redotech/redo-model/views/advanced-filters/generic-advanced-filter-data";
import {
  AdvancedTableFilter,
  ArrayStringTableFilter,
} from "@redotech/redo-web/advanced-filters/advanced-filter";
import BuildingIcon from "@redotech/redo-web/arbiter-icon/building-01.svg";

export const OriginAddressNameFilter = (
  fetcher: (
    { filters }: { filters: AdvancedTableFilter[] },
    signal: AbortSignal,
  ) => Promise<string[]>,
): ArrayStringTableFilter<string> => ({
  type: AdvancedFilterType.ARRAY_TO_STRING,
  itemLabel: "origin",
  operators: [
    ArrayStringFilterOperator.ANY_OF,
    ArrayStringFilterOperator.NONE_OF,
  ],
  data: {
    name: "fulfillmentLocation",
    type: AdvancedFilterType.ARRAY_TO_STRING,
    value: [],
    operator: ArrayStringFilterOperator.ANY_OF,
  },
  Icon: BuildingIcon,
  valueFetcher: fetcher,
});
