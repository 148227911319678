import { zExt } from "@redotech/rpc/ext";
import { z } from "zod";
import { productFilterSchema } from "./create-product-filter";

export const updateProductFilter = {
  input: z.object({ _id: zExt.objectId(), updatedFilter: productFilterSchema }),
  output: z.intersection(
    z.object({ _id: zExt.objectId() }),
    productFilterSchema,
  ),
};
