import { zExt } from "@redotech/rpc/ext";
import { z } from "zod";

export const ConversationTrainingLabelSchema = z.object({
  _id: zExt.objectId(),
  trainingSetId: z.string(),
  teamId: z.string(),
  conversationId: z.string(),
  messageId: z.string(),
  label: z.string(),
  notes: z.string(),
  createdAt: z.date(),
  updatedAt: z.date(),
});

export type ConversationTrainingLabel = z.infer<
  typeof ConversationTrainingLabelSchema
>;

export enum TrainingSetId {
  CONCIERGE_GUARDRAILS_12_20_2024 = "concierge-guardrails-12_20_2024",
}
