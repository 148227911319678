import { ArrayStringFilterOperator } from "@redotech/redo-model/views/advanced-filters/array-string-filter";
import { AdvancedFilterType } from "@redotech/redo-model/views/advanced-filters/generic-advanced-filter-data";
import {
  AdvancedTableFilter,
  ArrayStringTableFilter,
} from "@redotech/redo-web/advanced-filters/advanced-filter";
import BookIcon from "@redotech/redo-web/arbiter-icon/book-open-01.svg";

export const ItemTitleFilter = (
  fetcher: (
    { filters }: { filters: AdvancedTableFilter[] },
    signal: AbortSignal,
  ) => Promise<string[]>,
): ArrayStringTableFilter<string> => ({
  type: AdvancedFilterType.ARRAY_TO_STRING,
  Icon: BookIcon,
  itemLabel: "title",
  operators: [
    ArrayStringFilterOperator.NONE_OF,
    ArrayStringFilterOperator.ALL_OF,
    ArrayStringFilterOperator.ANY_OF,
    ArrayStringFilterOperator.CONTAINS,
  ],
  data: {
    name: "itemTitle",
    type: AdvancedFilterType.ARRAY_TO_STRING,
    value: [],
    operator: ArrayStringFilterOperator.CONTAINS,
  },
  valueFetcher: fetcher,
});
