import { FulfillmentOrderData } from "@redotech/redo-model/fulfillments/fulfillment-group";
import { SortDirection } from "@redotech/redo-model/tables/table";
import { RedoTableTextCell } from "@redotech/redo-web/arbiter-components/tables/redo-table-cells";
import { ColumnAlignment } from "@redotech/redo-web/table";
import { Column2 } from "@redotech/redo-web/table2";
import {
  formatDateString,
  getOrderDate,
} from "../../utils/outbound-labels-utils";

export const OrderDateColumn: Column2<FulfillmentOrderData> = {
  alignment: ColumnAlignment.LEFT,
  key: "order-date",
  title: "Order date",
  renderNormalCell: ({ row }: { row: FulfillmentOrderData }) => (
    <RedoTableTextCell
      text={formatDateString(getOrderDate(row).toISOString())}
    />
  ),
  width: 120,
  sort: SortDirection.DESC,
};
