import {
  JsonFormat,
  jsonSerializer,
  numberJsonFormat,
  objectJsonFormat,
} from "@redotech/json/format";
import { HidContext } from "@redotech/react-util/hid";
import { useHandler } from "@redotech/react-util/hook";
import { useLoader } from "@redotech/react-util/load";
import {
  splitWeight,
  Weight,
  WeightUnit,
} from "@redotech/redo-model/outbound-labels/util";
import { alertOnFailure } from "@redotech/redo-web/alert";
import {
  RedoButton,
  RedoButtonHierarchy,
  RedoButtonSize,
} from "@redotech/redo-web/arbiter-components/buttons/redo-button";
import { RedoButtonDropdown } from "@redotech/redo-web/arbiter-components/buttons/redo-dropdown-button";
import { RedoCommandMenu } from "@redotech/redo-web/arbiter-components/command-menu/redo-command-menu";
import { RedoIncrementDecrement } from "@redotech/redo-web/arbiter-components/increment-decrement/redo-increment-decrement";
import CheckIcon from "@redotech/redo-web/arbiter-icon/check.svg";
import PlusIcon from "@redotech/redo-web/arbiter-icon/plus.svg";
import { Flex } from "@redotech/redo-web/flex";
import { Text } from "@redotech/redo-web/text";
import { SCALE_FILTERS, scaleReadWeight } from "@redotech/scale/scale";
import { WeightUnit as ScaleWeightUnit } from "@redotech/scale/weight";
import {
  memo,
  useContext,
  useLayoutEffect,
  useMemo,
  useRef,
  useState,
} from "react";

export const TotalWeightWidget = memo(function TotalWeightWidget({
  weight,
  onWeightChange,
  onWeightReset,
  disabled,
}: {
  weight: Weight | null;
  onWeightChange: (weight: Weight) => void;
  onWeightReset: () => void;
  disabled: boolean;
}) {
  const ref = useRef<HTMLDivElement>(null);

  const [lb, setLb] = useState<number | undefined>();
  const [oz, setOz] = useState<number | undefined>();

  useLayoutEffect(() => {
    // update if not focused
    if (ref.current?.contains(document.activeElement)) {
      return;
    }
    if (weight) {
      const [lb, oz] = splitWeight(weight, [
        WeightUnit.POUND,
        WeightUnit.OUNCE,
      ]);
      setLb(lb);
      setOz(oz);
    } else {
      setLb(undefined);
      setOz(undefined);
    }
  }, [weight?.unit, weight?.value]);

  const updateLb = useHandler((lb: number) => {
    const newOz = oz !== undefined ? oz : 0; // if mixed, set oz to 0
    setLb(lb);
    setOz(newOz);
    onWeightChange({ value: lb * 16 + newOz, unit: WeightUnit.OUNCE });
  });

  const updateOz = useHandler((oz: number) => {
    const newLb = lb !== undefined ? lb : 0; // if mixed, set lb to 0
    setLb(newLb);
    setOz(oz);
    onWeightChange({ value: newLb * 16 + oz, unit: WeightUnit.OUNCE });
  });

  const handleLbBlur = useHandler((text: string) => {
    if (!weight) {
      return;
    }
    if (text.includes(".")) {
      // clear oz and normalize
      const weight = { value: +text || 0, unit: WeightUnit.POUND };
      const [lb, oz] = splitWeight(weight, [
        WeightUnit.POUND,
        WeightUnit.OUNCE,
      ]);
      setLb(lb);
      setOz(oz);
      onWeightChange(weight);
    } else {
      // normalize
      const [lb, oz] = splitWeight(weight, [
        WeightUnit.POUND,
        WeightUnit.OUNCE,
      ]);
      setLb(lb);
      setOz(oz);
    }
  });

  const handleOzBlur = useHandler(() => {
    if (!weight) {
      return;
    }
    // normalize
    const [lb, oz] = splitWeight(weight, [WeightUnit.POUND, WeightUnit.OUNCE]);
    setLb(lb);
    setOz(oz);
  });

  return (
    <Flex
      bgColor="tertiary"
      borderColor="primary"
      borderStyle="solid"
      borderWidth="1px"
      dir="column"
      gap="xs"
      p="lg"
      radius="md"
    >
      <Text fontSize="xs">Total weight</Text>
      <Flex dir="row" flex="1" gap="xs" ref={ref}>
        <Flex flex={1}>
          <RedoIncrementDecrement
            disabled={disabled}
            fullWidth
            min={0}
            onBlur={handleLbBlur}
            placeholder={!weight ? "{mixed}" : "0"}
            selectOnFocus
            setValue={updateLb}
            size="small"
            step="any"
            suffix="lbs"
            value={lb}
          />
        </Flex>
        <Flex flex={1}>
          <RedoIncrementDecrement
            disabled={disabled}
            fullWidth
            min={0}
            onBlur={handleOzBlur}
            placeholder={!weight ? "{mixed}" : "0"}
            selectOnFocus
            setValue={updateOz}
            size="small"
            step="any"
            suffix="oz"
            value={oz}
          />
        </Flex>
      </Flex>
      <Flex dir="row" justify="space-between" mt="sm">
        <ScaleButton onWeightChange={onWeightChange} />
        <span />
        <RedoButton
          onClick={onWeightReset}
          size={RedoButtonSize.EXTRA_SMALL}
          text="Reset"
        />
      </Flex>
    </Flex>
  );
});

interface DeviceId {
  productId: number;
  vendorId: number;
}

const deviceIdFormat: JsonFormat<DeviceId> = objectJsonFormat(
  { productId: numberJsonFormat, vendorId: numberJsonFormat },
  {},
);

const SCALE_KEY = "redo.scale";

const ScaleButton = memo(function ScaleButton({
  onWeightChange,
}: {
  onWeightChange: (weight: Weight) => void;
}) {
  const devices = useContext(HidContext);

  const deviceIdData = localStorage.getItem(SCALE_KEY);
  const deviceId = useMemo(() => {
    if (!deviceIdData) {
      return undefined;
    }
    try {
      return jsonSerializer(deviceIdFormat).read(deviceIdData);
    } catch (error) {
      console.error("Error reading device ID", error);
    }
    return undefined;
  }, [deviceIdData]);
  const device =
    deviceId &&
    devices.find(
      (device) =>
        device.productId === deviceId.productId &&
        device.vendorId === deviceId.vendorId,
    );

  const [dropdownOpen, setDropdownOpen] = useState(false);
  const [dropdownRef, setDropdownRef] = useState<HTMLElement | null>(null);

  const [weightLoad, loadWeight] = useLoader<void>();

  const addDevice = async () => {
    const [device] = await navigator.hid.requestDevice({
      filters: SCALE_FILTERS,
    });
    if (!device) {
      return;
    }
    localStorage.setItem(
      SCALE_KEY,
      jsonSerializer(deviceIdFormat).write(device),
    );
    return device;
  };

  const fetchWeight = (device?: HIDDevice) =>
    loadWeight(async () => {
      if (!device) {
        device = await addDevice();
        if (!device) {
          return;
        }
      }
      await alertOnFailure("Failed to read weight from scale")(async () => {
        const weight = await scaleReadWeight(device!);
        onWeightChange({
          unit:
            weight.unit === ScaleWeightUnit.OUNCE
              ? WeightUnit.OUNCE
              : WeightUnit.GRAM,
          value: weight.value,
        });
      });
    });

  return (
    <RedoButtonDropdown
      disabled={!navigator.hid} // TODO: tooltip to indicate why disabled
      dropdownOpen={dropdownOpen}
      hierarchy={RedoButtonHierarchy.SECONDARY}
      onClick={() => fetchWeight(device)}
      pending={weightLoad.pending}
      ref={setDropdownRef}
      setDropdownOpen={setDropdownOpen}
      size={RedoButtonSize.EXTRA_SMALL}
      text="Use scale"
    >
      <RedoCommandMenu
        anchor={dropdownRef}
        items={[
          ...devices.map((d) => ({
            Icon: d === device ? CheckIcon : undefined,
            onClick() {
              localStorage.setItem(
                "scale",
                jsonSerializer(deviceIdFormat).write(d),
              );
              fetchWeight(d);
            },
            text: d.productName,
          })),
          { Icon: PlusIcon, text: "Add scale...", onClick: addDevice },
        ]}
        open={dropdownOpen}
        setOpen={setDropdownOpen}
      />
    </RedoButtonDropdown>
  );
});
