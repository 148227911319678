import { ConversationPlatform } from "@redotech/redo-model/conversation";
import { Step as ModelStep } from "@redotech/redo-model/return-flow";
import {
  Condition,
  Condition as ModelCondition,
} from "@redotech/redo-model/return-flow/condition";
import { Button, ButtonSize, ButtonTheme } from "@redotech/redo-web/button";
import { Divider } from "@redotech/redo-web/divider";
import { BlockLayout } from "@redotech/redo-web/flowchart";
import InfoIcon from "@redotech/redo-web/icon-old/info.svg";
import { LabeledInput } from "@redotech/redo-web/labeled-input";
import { produce } from "immer";
import { SetStateAction, memo } from "react";
import {
  AFTER_HOURS,
  Details as ConditionDetails,
  State as ConditionState,
  ConditionType,
} from "../return-flow/condition";
import {
  StepDownstream,
  StepId,
  StepType,
  StepTypeDetailsProps,
} from "../return-flow/step";
import { MESSAGE_BODY_MATCHES } from "./conditions/message-body-matches";
import { SENT_FROM_EMAIL } from "./conditions/sent-from-email";
import { SUBJECT_LINE } from "./conditions/subject-line";
import { RECEIVED_AT_EMAIL } from "./received-at-email";
import { TICKET_CHANNEL } from "./ticket-channel";
import { TOPIC } from "./topic";

export interface State {
  customTitle?: string;
  conditions: {
    conditionType: ConditionType<any, any> | undefined;
    conditionState?: any;
    customTitle?: string;
  }[];
  nextTrue: StepId | undefined;
  nextFalse: StepId | undefined;
}

const Details = memo(function Details({
  stepSelect,
  state,
  setState,
  flowType,
}: StepTypeDetailsProps<State>) {
  const handleAddCondition = () => {
    setState((oldState) => {
      return {
        ...oldState,
        conditions: [
          ...oldState.conditions,
          {
            conditionType: TICKET_CHANNEL,
            conditionState: TICKET_CHANNEL.fromModel({
              type: Condition.TICKET_CHANNEL,
              channels: [ConversationPlatform.INSTAGRAM],
            }),
          },
        ],
      };
    });
  };
  const handleDeleteCondition = (index: number) => {
    setState((oldState) => {
      return {
        ...oldState,
        conditions: oldState.conditions.filter((_, ind) => ind !== index),
      };
    });
  };
  return (
    <>
      {state.conditions.map((condition, index) => (
        <>
          <Divider />
          <ConditionDetails
            flowType={flowType}
            onDeleteChild={() => handleDeleteCondition(index)}
            setState={(
              updateSingleCondition: SetStateAction<ConditionState>,
            ) => {
              let updatedCondition: ConditionState;
              if (typeof updateSingleCondition === "function") {
                const updateConditionState = updateSingleCondition(condition);
                if (typeof updateConditionState.conditionState === "function") {
                  const updatedConditionState =
                    updateConditionState.conditionState(
                      condition.conditionState,
                    );
                  updatedCondition = {
                    ...updatedConditionState,
                    conditionState: updatedConditionState,
                  };
                } else {
                  updatedCondition = updateConditionState;
                }
              } else {
                updatedCondition = updateSingleCondition;
              }
              setState((oldState) => {
                return {
                  ...oldState,
                  conditions: oldState.conditions.map((cond, ind) => {
                    if (index === ind) {
                      return updatedCondition;
                    } else {
                      return cond;
                    }
                  }),
                };
              });
            }}
            state={condition}
            stepSelect={stepSelect}
          />
        </>
      ))}
      <LabeledInput
        errors={
          state.conditions.length > 0
            ? []
            : ["At least one condition is required"]
        }
        label=""
      >
        <Button
          onClick={handleAddCondition}
          size={ButtonSize.MEDIUM}
          theme={ButtonTheme.PRIMARY}
        >
          + Add condition
        </Button>
      </LabeledInput>
      <LabeledInput
        errors={
          state.nextTrue || state.nextFalse
            ? []
            : ["At least one of these is required"]
        }
        label="If matches, then"
      >
        {stepSelect({
          value: state.nextTrue,
          valueChange: (nextTrue) =>
            setState((state) => ({ ...state, nextTrue })),
        })}
      </LabeledInput>
      <LabeledInput
        errors={
          state.nextTrue || state.nextFalse
            ? []
            : ["At least one of these is required"]
        }
        label="If does not match, then"
      >
        {stepSelect({
          value: state.nextFalse,
          valueChange: (nextFalse) =>
            setState((state) => ({ ...state, nextFalse })),
        })}
      </LabeledInput>
    </>
  );
});

export const MULTIPLE_CONDITIONS: StepType<
  State,
  { conditions: ModelStep.Condition[]; nextTrue?: number; nextFalse?: number }
> = {
  Details,
  customTitle(state) {
    return state.customTitle;
  },
  Icon: InfoIcon,
  description(state) {
    return state.conditions
      .map((condition) => {
        return condition.conditionType?.name || "One or more conditions";
      })
      .join(", ");
  },
  downstream(state) {
    const result: StepDownstream[] = [];
    if (state.nextFalse !== undefined) {
      result.push({ id: state.nextFalse, label: "False" });
    }
    if (state.nextTrue !== undefined) {
      result.push({ id: state.nextTrue, label: "True" });
    }
    return result;
  },
  empty: { conditions: [], nextFalse: undefined, nextTrue: undefined },
  fromModel(model, id) {
    return {
      conditions: model.conditions.map((cond) => {
        if ((cond.condition.type as symbol) === Condition.AFTER_HOURS) {
          return {
            customTitle: cond.customTitle,
            conditionType: AFTER_HOURS,
            conditionState: AFTER_HOURS.fromModel(
              cond.condition as ModelCondition.AfterHours,
            ),
          };
        } else if (
          (cond.condition.type as symbol) === Condition.TICKET_CHANNEL
        ) {
          return {
            customTitle: cond.customTitle,
            conditionType: TICKET_CHANNEL,
            conditionState: TICKET_CHANNEL.fromModel(
              cond.condition as unknown as ModelCondition.TicketChannel,
            ),
          };
        } else if ((cond.condition.type as symbol) === Condition.SUBJECT_LINE) {
          return {
            customTitle: cond.customTitle,
            conditionType: SUBJECT_LINE,
            conditionState: SUBJECT_LINE.fromModel(
              cond.condition as unknown as ModelCondition.SubjectLine,
            ),
          };
        } else if (
          (cond.condition.type as symbol) === Condition.MESSAGE_BODY_MATCHES
        ) {
          return {
            customTitle: cond.customTitle,
            conditionType: MESSAGE_BODY_MATCHES,
            conditionState: MESSAGE_BODY_MATCHES.fromModel(
              cond.condition as unknown as ModelCondition.MessageBodyMatches,
            ),
          };
        } else if ((cond.condition.type as symbol) === Condition.TOPIC) {
          return {
            customTitle: cond.customTitle,
            conditionType: TOPIC,
            conditionState: TOPIC.fromModel(
              cond.condition as unknown as ModelCondition.Topic,
            ),
          };
        } else if (
          (cond.condition.type as symbol) === Condition.RECEIVED_AT_EMAIL
        ) {
          return {
            customTitle: cond.customTitle,
            conditionType: RECEIVED_AT_EMAIL,
            conditionState: RECEIVED_AT_EMAIL.fromModel(
              cond.condition as unknown as ModelCondition.ReceivedAtEmail,
            ),
          };
        } else if (
          (cond.condition.type as symbol) === Condition.SENT_FROM_EMAIL
        ) {
          return {
            customTitle: cond.customTitle,
            conditionType: SENT_FROM_EMAIL,
            conditionState: SENT_FROM_EMAIL.fromModel(
              cond.condition as unknown as ModelCondition.SentFromEmail,
            ),
          };
        } else {
          return {
            customTitle: cond.customTitle,
            conditionType: TICKET_CHANNEL,
            conditionState: TICKET_CHANNEL.fromModel({
              type: Condition.TICKET_CHANNEL,
              channels: [ConversationPlatform.INSTAGRAM],
            }),
          };
        }
      }),
      nextTrue: model.nextTrue?.toString(),
      nextFalse: model.nextFalse?.toString(),
    };
  },
  layout() {
    return BlockLayout.FULL;
  },
  stepDeleted(state, stepId) {
    return produce(state, (state) => {
      if (state.nextFalse === stepId) {
        state.nextFalse = undefined;
      }
      if (state.nextTrue === stepId) {
        state.nextTrue = undefined;
      }
    });
  },
  title: "Conditions",
  toModel(state, id) {
    return {
      type: ModelStep.MULTIPLE_CONDITIONS,
      conditions: state.conditions.map((cond) => {
        return {
          type: ModelStep.CONDITION,
          condition: cond.conditionType!.toModel(cond.conditionState),
        };
      }),
      nextTrue: state.nextTrue ? id(state.nextTrue) : undefined,
      nextFalse: state.nextFalse ? id(state.nextFalse!) : undefined,
    };
  },
  valid(state) {
    return (
      (state.nextTrue !== undefined || state.nextFalse !== undefined) &&
      state.conditions.length > 0
    );
  },
};
