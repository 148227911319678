import { assertNever } from "@redotech/util/type";
import { FulfillmentGroupStatus } from "./fulfillment-group-status";

// This is a copy of the FulfillmentOrderStatus enum from @redotech/shopify-client/admin.graphql, but that can also be used in redo/model and therefore the frontend
export enum RedoShopifyFulfillmentOrderStatus {
  /** The fulfillment order has been cancelled by the merchant. */
  Cancelled = "CANCELLED",
  /** The fulfillment order has been completed and closed. */
  Closed = "CLOSED",
  /** The fulfillment order cannot be completed as requested. */
  Incomplete = "INCOMPLETE",
  /** The fulfillment order is being processed. */
  InProgress = "IN_PROGRESS",
  /** The fulfillment order is on hold. The fulfillment process can't be initiated until the hold on the fulfillment order is released. */
  OnHold = "ON_HOLD",
  /** The fulfillment order is ready for fulfillment. */
  Open = "OPEN",
  /** The fulfillment order is deferred and will be ready for fulfillment after the date and time specified in `fulfill_at`. */
  Scheduled = "SCHEDULED",
}

export function fulfillmentOrderStatusToFulfillmentGroupStatus(
  status: RedoShopifyFulfillmentOrderStatus,
): FulfillmentGroupStatus {
  switch (status) {
    case RedoShopifyFulfillmentOrderStatus.Cancelled:
      return FulfillmentGroupStatus.Cancelled;
    case RedoShopifyFulfillmentOrderStatus.Closed:
      return FulfillmentGroupStatus.Closed;
    case RedoShopifyFulfillmentOrderStatus.Incomplete:
      return FulfillmentGroupStatus.Incomplete;
    case RedoShopifyFulfillmentOrderStatus.InProgress:
      return FulfillmentGroupStatus.Open; // Right now we just treat in progress as open
    case RedoShopifyFulfillmentOrderStatus.Open:
      return FulfillmentGroupStatus.Open;
    case RedoShopifyFulfillmentOrderStatus.OnHold:
      return FulfillmentGroupStatus.OnHold;
    case RedoShopifyFulfillmentOrderStatus.Scheduled:
      return FulfillmentGroupStatus.Scheduled;
    default:
      assertNever(status);
  }
}
