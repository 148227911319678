import { z } from "zod";
import { zExt } from "./common/zod-util";
import { GetUser, GetUserZodSchema } from "./user";

export const TimelineEventZodSchema = z.object({
  customer: z
    .object({
      firstName: z.string().nullish(),
      lastName: z.string().nullish(),
      email: z.string().nullish(),
      gravatarUrl: z.string().nullish(),
    })
    .nullish(),
  user: z.union([zExt.objectId(), GetUserZodSchema]).nullish(),
  image: z.string().nullish(),
  isShipment: z.boolean(),
  _id: zExt.objectId(),
  message: z.string().nullish(),
  link: z
    .object({
      message: z.string().nullish(),
      orderNumber: z.string().nullish(),
      url: z.string().nullish(),
    })
    .nullish(),
  subMessage: z.string().nullish(),
  isSystem: z.boolean().nullish(),
  updatedAt: z.date(),
  createdAt: z.date().nullish(),
});
export interface TimelineEvent {
  customer?: {
    firstName: string | null;
    lastName: string | null;
    email: string | null;
    gravatarUrl: string | null;
  };
  user?: string | GetUser;
  image?: string;
  isShipment: boolean;
  _id?: string;
  message?: string;
  link?: { message: string; orderNumber: string; url: string };
  subMessage?: string;
  isSystem: boolean;
  updatedAt: string;
  createdAt: string;
}
