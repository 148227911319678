import {
  createLazyContext,
  useLazyContextProvider,
  useRequiredContext,
} from "@redotech/react-util/context";
import { CustomerTagWithId } from "@redotech/redo-model/customer";
import { memo, ReactNode } from "react";
import { TeamContext } from "../../app/team";
import { RedoMerchantClientContext } from "../../client/context";
import { getCustomerTagsOfTeam } from "../../client/customer-tags";

export const CustomerTagsContext = createLazyContext<CustomerTagWithId[]>();

export const CustomerTagsService = memo(function CustomerTagsService({
  children,
}: {
  children: ReactNode | ReactNode[];
}) {
  const client = useRequiredContext(RedoMerchantClientContext);

  const team = useRequiredContext(TeamContext);

  const loadState = useLazyContextProvider(async (signal) => {
    const teamTags = await getCustomerTagsOfTeam(
      client,
      { teamId: team._id },
      signal,
    );
    const tagIds = teamTags.map((tag) => {
      return {
        tagId: tag._id,
        name: tag.name,
        pillTheme: tag.pillTheme,
        source: tag.source,
      };
    });
    return tagIds.sort((a, b) => a.name.localeCompare(b.name));
  });

  return (
    <CustomerTagsContext.internalContext.Provider value={loadState}>
      {children}
    </CustomerTagsContext.internalContext.Provider>
  );
});
