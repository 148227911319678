// extracted by mini-css-extract-plugin
export var box = "_2o-0";
export var check = "_2o-1";
export var checkboxButton = "_2o-6";
export var checkboxGroup = "_2o-5";
export var disabled = "_2o-7";
export var ghost = "_2o-9";
export var input = "_2o-2";
export var label = "_2o-4";
export var normal = "_2o-8";
export var uncheck = "_2o-3";