import { ClickAwayListener } from "@mui/material";
import { genericMemo } from "@redotech/react-util/component";
import { useSearch } from "@redotech/react-util/search";
import Fuse from "fuse.js";
import { ReactNode, useState } from "react";
import { RedoList, RedoListItem } from "./arbiter-components/list/redo-list";
import { RedoListItemSize } from "./arbiter-components/list/redo-list-item";
import { Divider } from "./divider";
import { Dropdown } from "./dropdown";
import { Flex } from "./flex";
import * as tagInputCss from "./item-dropdown-multi-select.module.css";

export const ItemDropdownMultiSelect = genericMemo(
  function ItemDropdownMultiSelect<T>({
    itemLabel,
    itemLabelPlural,
    openOnRender,
    dropdownAnchor,
    items,
    isItemSelected,
    onItemClick,
    children,
    onCreateItem,
    onMenuSelect,
    setClosedOnRender,
  }: {
    itemLabel: string;
    itemLabelPlural?: string;
    openOnRender: boolean;
    dropdownAnchor: HTMLElement | null;
    items: T[];
    isItemSelected: (item: T) => boolean;
    onItemClick: (item: T) => void;
    children: (item: T) => ReactNode;
    onCreateItem?: () => void;
    onMenuSelect?: (item: T) => void;
    setClosedOnRender: () => void;
  }) {
    const [searchText, setSearchText] = useState("");

    const searcher = new Fuse<T>([], { keys: ["name"], threshold: 0.3 });
    const filteredItems = useSearch(searcher, items, searchText);

    const [inputRef, setInputRef] = useState<HTMLInputElement | null>(null);
    const [focusedIndex, setFocusedIndex] = useState<number | undefined>();

    const dropdownItems: RedoListItem<T | "create">[] = filteredItems.map(
      (item) => {
        if (onMenuSelect) {
          return {
            value: item,
            menu: {
              onClick: () => {
                setClosedOnRender();
                onMenuSelect(item);
              },
            },
          };
        }
        return { value: item };
      },
    );

    const isDropdownItemSelected = (item: RedoListItem<T>) =>
      isItemSelected(item.value);

    if (onCreateItem) {
      dropdownItems.push({ value: "create" });
    }

    const onDropdownItemClick = (item: RedoListItem<T>) => {
      if (item.value === "create") {
        setClosedOnRender();
        onCreateItem?.();
      } else {
        onItemClick(item.value);
      }
    };

    const dropdownItemRenderer = (item: RedoListItem<T>) => {
      if (item.value === "create") {
        return <div>Create</div>;
      }
      return children(item.value);
    };

    return (
      <ClickAwayListener
        onClickAway={() => {
          setClosedOnRender();
        }}
      >
        <Dropdown
          anchor={dropdownAnchor}
          fitToAnchor={false}
          open={openOnRender}
        >
          <Flex className={tagInputCss.dropdown} dir="column" gap="sm">
            <input
              autoFocus
              className={tagInputCss.input}
              disabled={false}
              onChange={(event) => setSearchText(event.target.value)}
              placeholder={
                itemLabelPlural
                  ? `Search ${itemLabelPlural}...`
                  : `Search ${itemLabel}s...`
              }
              ref={setInputRef}
              value={searchText}
            />
            <Divider />
            <div className={tagInputCss.list}>
              <RedoList
                focusedIndex={focusedIndex}
                isItemSelected={isDropdownItemSelected}
                items={dropdownItems}
                itemSelected={onDropdownItemClick}
                refToListenTo={inputRef}
                setFocusedIndex={setFocusedIndex}
                size={RedoListItemSize.SMALL}
              >
                {dropdownItemRenderer}
              </RedoList>
            </div>
          </Flex>
        </Dropdown>
      </ClickAwayListener>
    );
  },
);
