// extracted by mini-css-extract-plugin
export var baseCustomerActivityCardArrowRight = "_9u-f";
export var baseCustomerActivityCardArrowRightContainer = "_9u-e";
export var baseCustomerActivityCardContainer = "_9u-c";
export var baseCustomerActivityCardContainerHovered = "_9u-d";
export var conversationActivityDataConversationIconContainer = "_9u-5";
export var customerActivityDataClaimIcon = "_9u-8";
export var customerActivityDataClaimIconContainer = "_9u-7";
export var customerActivityDataConversationIcon = "_9u-6";
export var customerActivityDataConversationIconContainer = "_9u-4";
export var customerActivityDataOrderIcon = "_9u-1";
export var customerActivityDataOrderIconContainer = "_9u-0";
export var customerActivityDataReturnIcon = "_9u-3";
export var customerActivityDataReturnIconContainer = "_9u-2";
export var customerActivityShoppingIconContainer = "_9u-9";
export var messageBubble = "_9u-a";
export var messageBubbleText = "_9u-b";