import { z } from "zod";
import { zExt } from "../common/zod-util";
import { Provider } from "../order";
import { PermissionEntrySchema } from "../order-schema";
import { ItemOrderBy } from "../outbound-labels";
import { ShipmentRatesSchema } from "../outbound-labels/outbound-labels";
import {
  getParcelWeightGrams,
  Parcel,
  ParcelSchema,
} from "../outbound-labels/parcel";
import {
  addWeight,
  convertWeight,
  MoneySchema,
  Weight,
  WeightSchema,
  WeightUnit,
} from "../outbound-labels/util";
import { TagSchema } from "../tag";
import { RedoShopifyDeliveryMethodType } from "./fulfillment-delivery-method-type";
import { FulfillmentGroupStatus } from "./fulfillment-group-status";
import { FulfillmentLineItem } from "./fulfillment-line-item";
import {
  FulfillmentOrderAddressSchema,
  VerifiedAddressSchema,
} from "./fulfillment-order-address";
import {
  FulfillmentOrderLineItem,
  FulfillmentOrderLineItemSchema,
} from "./fulfillment-order-line-item";
import { RedoShopifyFulfillmentOrderStatus } from "./fulfillment-order-status";
import { PrintStatusSchema } from "./fullfilment-print-status";
import {
  RedoFulfillmentSchema,
  ShipmentPackageSchema,
} from "./outbound-shipment";
import { RiskSchema } from "./shopify-fulfillment-schemas";

export const FulfillmentOrderSchema = z.object({
  provider: z.nativeEnum(Provider),
  externalId: z.string(),
  originAddress: FulfillmentOrderAddressSchema,
  verifiedOriginAddress: VerifiedAddressSchema.nullish(),
  deliveryMethod: z.object({
    name: z.string().nullish(),
    code: z.string().nullish(),
    kind: z.nativeEnum(RedoShopifyDeliveryMethodType),
    instructions: z.string().nullish(),
    phone: z.string().nullish(),
  }),
  destinationAddress: FulfillmentOrderAddressSchema,
  verifiedDestinationAddress: VerifiedAddressSchema.nullish(),
  createdAt: z.date(),
  updatedAt: z.date(),
  fulfillAt: z.date().nullish(),
  fulfillBy: z.date().nullish(),
  lineItems: z.array(FulfillmentOrderLineItemSchema),
  order: z
    .object({
      id: z.string(),
      redoOrderId: z.string().nullish(),
      name: z.string(),
      risk: RiskSchema.nullish(),
      tags: z.array(z.string()).nullish(),
      note: z.string(),
      customAttributes: z
        .array(z.object({ key: z.string(), value: z.string() }))
        .nullish(),
      createdAt: z.date(),
      shippingCost: MoneySchema,
      closed: z.boolean().nullish(),
      closedAt: z.date().nullish(),
    })
    .nullish(),
  customer: z
    .object({
      id: z.string(),
      name: z.string(),
      tags: z.array(z.string()).nullish(),
      numOrders: z.number(),
      email: z.string().nullish(),
      phone: z.string().nullish(),
      note: z.string().nullish(),
    })
    .nullish(),
  status: z.nativeEnum(RedoShopifyFulfillmentOrderStatus),
  raw: z.any(),
});

export type FulfillmentOrder = z.infer<typeof FulfillmentOrderSchema>;

export const FulfillmentGroupSummary = z.object({
  totalItemQuantity: z.number(),
  unfulfilledItemQuantity: z.number(),
  totalSkuCount: z.number(),
  totalWeightGrams: z.number(),
  unfulfilledWeightGrams: z.number(),
  totalWeightWithParcelGrams: z.number().nullish(),
  totalPrice: MoneySchema,
  totalPriceInt: z.number().nullish(),
  totalTax: MoneySchema,
  totalTaxInt: z.number().nullish(),
  totalShippingCost: MoneySchema,
  totalShippingCostInt: z.number().nullish(),
  providerCreatedAt: z.date(),
  providerUpdatedAt: z.date(),
  updatedAt: z.date().nullish(),
});

export const FulfillmentGroupSchema = z.object({
  _id: zExt.objectId(),
  team: zExt.objectId(),
  summary: FulfillmentGroupSummary,
  status: z.nativeEnum(FulfillmentGroupStatus).nullish(),
  fulfillmentOrders: z.array(FulfillmentOrderSchema),
  shipmentRates: ShipmentRatesSchema.nullish(),
  overrideWeight: WeightSchema.nullish(),
  displayWeightGrams: z.number().nullish(),
  selectedParcel: ParcelSchema.nullish(),
  selectedShippingRateId: z.string().nullish(),
  createdAt: z.date(),
  updatedAt: z.date(),
  tags: z.array(TagSchema).nullish(),
  noteToBuyer: z.string().nullish(),
  internalNote: z.string().nullish(),
  assignedUserId: zExt.objectId().nullish(),
  shipment: ShipmentPackageSchema.nullish(),
  fulfillments: z.array(RedoFulfillmentSchema).nullish(),
  printStatus: PrintStatusSchema.nullish(),
});

export type FulfillmentGroup = z.infer<typeof FulfillmentGroupSchema>;

export const OMSUserSchema = z.object({
  roles: z.array(z.string()),
  _id: zExt.objectId(),
  email: z.string(),
  createdAt: z.coerce.date(),
  firstName: z.string(),
  lastName: z.string(),
  name: z.string(),
  updatedAt: z.coerce.date(),
  team: zExt.objectId(),
  permissions: z.array(PermissionEntrySchema).nullish(),
});
export type OMSUser = z.infer<typeof OMSUserSchema>;

export const FulfillmentOrderDataSchema = FulfillmentGroupSummary.extend({
  _id: z.string(),
  orderNumbers: z.array(z.string()),
  tags: z.array(TagSchema).nullish(),
  orders: z.array(
    z.object({
      redoOrderId: z.string().nullish(),
      name: z.string(),
      tags: z.array(z.string()),
    }),
  ),
  shippingMethodNames: z.array(z.string()),
  items: z.array(FulfillmentOrderLineItemSchema),
  originAddress: FulfillmentOrderAddressSchema,
  verifiedOriginAddress: VerifiedAddressSchema.nullish(),
  destinationAddress: FulfillmentOrderAddressSchema,
  verifiedDestinationAddress: VerifiedAddressSchema.nullish(),
  status: z.nativeEnum(FulfillmentGroupStatus).nullish(),
  selectedParcel: ParcelSchema.nullish(),
  availableShipmentRates: ShipmentRatesSchema.nullish(),
  selectedShippingRateId: z.string().nullish(),
  overrideWeight: WeightSchema.nullish(),
  displayWeightGrams: z.number().nullish(),
  assignedUser: OMSUserSchema.nullish(),
  fulfillments: z.array(RedoFulfillmentSchema).nullish(),
  shipment: ShipmentPackageSchema.nullish(),
  noteToBuyer: z.string().nullish(),
  noteFromBuyer: z.string().nullish(),
  internalNote: z.string().nullish(),
  customAttributes: z.array(z.object({ key: z.string(), value: z.string() })),
  customerName: z.string().nullish(),
  customerEmail: z.string().nullish(),
  printStatus: PrintStatusSchema.nullish(),
  orderSource: z.nativeEnum(Provider).nullish(),
  risk: RiskSchema.nullish(),
});

export type FulfillmentOrderData = z.infer<typeof FulfillmentOrderDataSchema>;

export function sortFulfillmentOrderLineItems(
  orderBy: ItemOrderBy,
  items: FulfillmentOrderLineItem[],
  selectedStatus: FulfillmentGroupStatus,
): FulfillmentOrderLineItem[] {
  switch (orderBy) {
    case ItemOrderBy.QuantityDesc:
      return items.sort((a, b) =>
        selectedStatus === FulfillmentGroupStatus.Closed
          ? b.totalQuantity -
            b.unfulfilledQuantity -
            (a.totalQuantity - a.unfulfilledQuantity)
          : b.unfulfilledQuantity - a.unfulfilledQuantity,
      );
    case ItemOrderBy.QuantityAsc:
      return items.sort((a, b) =>
        selectedStatus === FulfillmentGroupStatus.Closed
          ? a.totalQuantity -
            a.unfulfilledQuantity -
            (b.totalQuantity - b.unfulfilledQuantity)
          : a.unfulfilledQuantity - b.unfulfilledQuantity,
      );
    case ItemOrderBy.NameAsc:
      return items.sort((a, b) => a.title.localeCompare(b.title));
    case ItemOrderBy.NameDesc:
      return items.sort((a, b) => b.title.localeCompare(a.title));
    case ItemOrderBy.TotalPriceAsc:
      return items.sort(
        (a, b) => Number(a.totalPrice.amount) - Number(b.totalPrice.amount),
      );
    case ItemOrderBy.TotalPriceDesc:
      return items.sort(
        (a, b) => Number(b.totalPrice.amount) - Number(a.totalPrice.amount),
      );
    case ItemOrderBy.UnitPriceAsc:
      return items.sort(
        (a, b) => Number(a.unitPrice.amount) - Number(b.unitPrice.amount),
      );
    case ItemOrderBy.UnitPriceDesc:
      return items.sort(
        (a, b) => Number(b.unitPrice.amount) - Number(a.unitPrice.amount),
      );
  }
}

export function sortFulfillmentLineItems(
  orderBy: ItemOrderBy,
  items: FulfillmentLineItem[],
): FulfillmentLineItem[] {
  switch (orderBy) {
    case ItemOrderBy.QuantityDesc:
      return items.sort((a, b) => b.quantity - a.quantity);
    case ItemOrderBy.QuantityAsc:
      return items.sort((a, b) => a.quantity - b.quantity);
    case ItemOrderBy.NameAsc:
      return items.sort((a, b) => a.title.localeCompare(b.title));
    case ItemOrderBy.NameDesc:
      return items.sort((a, b) => b.title.localeCompare(a.title));
    case ItemOrderBy.TotalPriceAsc:
      return items.sort(
        (a, b) => Number(a.totalPrice.amount) - Number(b.totalPrice.amount),
      );
    case ItemOrderBy.TotalPriceDesc:
      return items.sort(
        (a, b) => Number(b.totalPrice.amount) - Number(a.totalPrice.amount),
      );
    case ItemOrderBy.UnitPriceAsc:
      return items.sort(
        (a, b) =>
          Number(a.originalUnitPrice.amount) -
          Number(b.originalUnitPrice.amount),
      );
    case ItemOrderBy.UnitPriceDesc:
      return items.sort(
        (a, b) =>
          Number(b.originalUnitPrice.amount) -
          Number(a.originalUnitPrice.amount),
      );
  }
}

export function getWeightWithParcelGrams(
  totalWeightGrams: number,
  parcel: Parcel | null | undefined,
): Weight {
  const orderWeight: Weight = {
    unit: WeightUnit.GRAM,
    value: totalWeightGrams,
  };

  if (!parcel) {
    return orderWeight;
  }

  const parcelWeight = getParcelWeightGrams(parcel);

  const total = parcelWeight
    ? addWeight(parcelWeight, orderWeight)
    : orderWeight;

  return convertWeight(total, WeightUnit.GRAM);
}

export function getDisplayWeightGrams(
  overrideWeight: Weight | null | undefined,
  totalWeightWithParcelGrams: number,
): number {
  return overrideWeight
    ? convertWeight(overrideWeight, WeightUnit.GRAM).value
    : totalWeightWithParcelGrams;
}
