// extracted by mini-css-extract-plugin
export var actions = "_1l-8";
export var actionsButton = "_1l-a";
export var authenticateButton = "_1l-r";
export var buttonContent = "_1l-9";
export var buttons = "_1l-s";
export var carrierHeader = "_1l-m";
export var carrierLogo = "_1l-n";
export var carrierParcelDimensions = "_1l-q";
export var carrierParcelName = "_1l-p";
export var carrierParcelsForm = "_1l-o";
export var checkboxContainer = "_1l-l";
export var checkboxSubtext = "_1l-j";
export var checkboxText = "_1l-i";
export var dimensions = "_1l-7";
export var editDimensions = "_1l-e";
export var editUnit = "_1l-g";
export var editWeight = "_1l-f";
export var image = "_1l-4";
export var mainCard = "_1l-0";
export var mainInfo = "_1l-3";
export var modal = "_1l-b";
export var modalActions = "_1l-h";
export var modalButton = "_1l-d";
export var modalForm = "_1l-c";
export var name = "_1l-6";
export var parcel = "_1l-2";
export var parcels = "_1l-1";
export var pillContainer = "_1l-k";
export var text = "_1l-5";