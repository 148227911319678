import { useRequiredContext } from "@redotech/react-util/context";
import {
  useSearch,
  useSearchBackend,
  UseSearchBackendResultType,
} from "@redotech/react-util/search";
import {
  RedoTagDelimeter,
  RedoTagInputField,
} from "@redotech/redo-web/arbiter-components/input/redo-tag-input-field";
import { isValidEmail } from "@redotech/util/email";
import { assertNever } from "@redotech/util/type";
import {
  Dispatch,
  memo,
  SetStateAction,
  useEffect,
  useMemo,
  useState,
} from "react";
import { RedoMerchantClientContext } from "../../client/context";

import { ClickAwayListener } from "@mui/material";
import { RedoInputSize } from "@redotech/redo-web/arbiter-components/input/base-redo-text-input";
import {
  RedoList,
  RedoListItemSelectedSource,
} from "@redotech/redo-web/arbiter-components/list/redo-list";
import { RedoListItemSize } from "@redotech/redo-web/arbiter-components/list/redo-list-item";
import { Dropdown } from "@redotech/redo-web/dropdown";
import Fuse from "fuse.js";
import { TeamContext } from "../../app/team";
import { getCustomerSearch } from "../../client/shopify";

const validateNewInputs = (
  emailCandidates: readonly string[],
): readonly string[] => {
  return emailCandidates.filter(isValidEmail);
};

const userFuse = new Fuse([], { keys: ["email"], threshold: 0.5 });

export const EmailListChipInput = memo(function EmailListChipInput({
  title,
  infoText,
  emailsToShow,
  setEmails,
}: {
  title: string;
  infoText: string;
  emailsToShow: readonly string[];
  setEmails: Dispatch<SetStateAction<readonly string[]>>;
}) {
  const [searchMenuOpen, setSearchMenuOpen] = useState<boolean>(false);

  const team = useRequiredContext(TeamContext);
  const client = useRequiredContext(RedoMerchantClientContext);
  const [searchText, setSearchText] = useState("");

  useEffect(() => {
    setSearchMenuOpen(searchText.length > 0);
  }, [searchText]);

  const usersForSearch = useMemo(() => {
    return team.users.filter((user) => user.email);
  }, []);

  const memoizedCustomerSearch = useMemo(() => {
    return (searchText: string, abortSignal: AbortSignal) =>
      getCustomerSearch(client, {
        search: searchText,
        signal: abortSignal,
      }).then((res) => res.customers);
  }, []);

  const customerSearchResults = useSearchBackend({
    memoizedDocFetcher: memoizedCustomerSearch,
    searchText,
  });

  const [focusedIndex, setFocusedIndex] = useState<number | undefined>(
    undefined,
  );

  const userSearchResults = useSearch(userFuse, usersForSearch, searchText);

  const [menuRef, setMenuRef] = useState<HTMLDivElement | null>(null);

  const searchResultList = useMemo(() => {
    const resultsToShow = [
      ...userSearchResults,
      ...(customerSearchResults.results || []),
    ]
      .filter(
        (result: any) => validateNewInputs([result.email || ""]).length > 0,
      )
      .filter((result: any) => !emailsToShow.includes(result.email))
      .map((result: any) => ({ value: { type: "email", text: result.email } }));

    switch (customerSearchResults.type) {
      case UseSearchBackendResultType.ERROR:
        return [
          {
            value: { type: "msg", text: "Error loading autocomplete results" },
          },
        ];
      case UseSearchBackendResultType.DEBOUNCING:
      case UseSearchBackendResultType.SEARCH_PENDING:
        return [
          { value: { type: "msg", text: "Loading..." } },
          ...resultsToShow,
        ];
      case UseSearchBackendResultType.RESOLVED:
        return [
          ...(!resultsToShow?.length
            ? [{ value: { type: "msg", text: "No results" } }]
            : []),
          ...resultsToShow,
        ];
      default:
        assertNever(customerSearchResults.type);
    }
  }, [customerSearchResults.trigger]);

  return (
    <div ref={setMenuRef}>
      <RedoTagInputField
        delimeter={RedoTagDelimeter.BOTH}
        infoTooltip={infoText}
        inputValue={searchText}
        label={title}
        placeholder="Type an email and press enter"
        setInputValue={setSearchText}
        setTags={(newTagSet) => {
          setEmails(() => [...new Set(validateNewInputs([...newTagSet]))]);
        }}
        size={RedoInputSize.SMALL}
        tags={emailsToShow.map((email) => ({ text: email, id: email }))}
      />
      <ClickAwayListener onClickAway={() => setSearchMenuOpen(false)}>
        <Dropdown anchor={menuRef} fitToAnchor open={searchMenuOpen}>
          <RedoList
            focusedIndex={focusedIndex}
            items={searchResultList}
            itemSelected={function (
              item,
              source: RedoListItemSelectedSource,
            ): void {
              if (item.value.type !== "email") {
                return;
              }
              setEmails((emailsToShow) => [
                ...new Set(
                  validateNewInputs([...emailsToShow, item.value.text]),
                ),
              ]);
              setSearchText("");
            }}
            refToListenTo={menuRef}
            setFocusedIndex={setFocusedIndex}
            size={RedoListItemSize.SMALL}
          >
            {(item) => {
              return <span>{item.value.text}</span>;
            }}
          </RedoList>
        </Dropdown>
      </ClickAwayListener>
    </div>
  );
});

export const recipientDescriptions = {
  toTitle: "To",
  toInfo: "The primary recipients of the email.",
  ccTitle: "CC",
  bccTitle: "BCC",
  ccInfo:
    "A CC (Carbon Copy) address in an email allows you to send a copy of the message to additional recipients, making their email addresses visible to all recipients.",
  bccInfo:
    "A BCC (Blind Carbon Copy) address enables you to send an email to multiple recipients without revealing their email addresses to each other, ensuring privacy and confidentiality.",
};
