import { breadcrumbSlot } from "@redotech/redo-web/breadcrumb";
import { Navigate, RouteObject } from "react-router-dom";

const hideHeader = (url: string): boolean => {
  return /customers\/[a-f0-9]{24}/.test(url) || /customers\/about/.test(url);
};

const hideNavbar = (url: string): boolean => {
  return /customers\/[a-f0-9]{24}/.test(url);
};

const customerBreadcrumb = breadcrumbSlot();

export const customerRoutes: RouteObject[] = [
  {
    handle: { breadcrumb: "Customers" },
    async lazy() {
      const { Page } = await import("../app/page");
      return {
        element: <Page hideHeader={hideHeader} hideNavbar={hideNavbar} />,
      };
    },
    children: [
      {
        index: true,
        async lazy() {
          const { Customers } = await import("./customers");
          return { element: <Customers /> };
        },
      },
      {
        path: "about",
        async lazy() {
          const { AboutCustomerAccounts } = await import(
            "./about-customer-accounts"
          );
          return { element: <AboutCustomerAccounts /> };
        },
      },
      {
        handle: { breadcrumb: customerBreadcrumb },
        path: ":customerId",
        children: [
          { index: true, element: <Navigate replace to="orders" /> },
          {
            path: "",
            async lazy() {
              const { CustomerDetail } = await import(
                "./customer-detail/customer-detail"
              );
              return { element: <CustomerDetail /> };
            },
            children: [
              {
                path: "orders",
                async lazy() {
                  const { CustomerOrders } = await import(
                    "./customer-detail/customer-orders"
                  );
                  return { Component: CustomerOrders };
                },
              },
              {
                path: "returns",
                async lazy() {
                  const { CustomerReturns } = await import(
                    "./customer-detail/customer-returns"
                  );
                  return { Component: CustomerReturns };
                },
              },
              {
                path: "claims",
                async lazy() {
                  const { CustomerClaims } = await import(
                    "./customer-detail/customer-claims"
                  );
                  return { Component: CustomerClaims };
                },
              },
              {
                path: "support",
                async lazy() {
                  const { CustomerSupport } = await import(
                    "./customer-detail/customer-support"
                  );
                  return { Component: CustomerSupport };
                },
              },
            ],
          },
        ],
      },
    ],
  },
];
