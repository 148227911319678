export type Gid = `gid://shopify/${string}/${number}`;
export function getNumericIdFromGid(gid: Gid): `${number}` {
  return gid.split("/").pop()! as `${number}`; // still returned as a string - don't use for math
}

const gidRegex = /^gid:\/\/shopify\/(\w+)\/(\d+)$/;

function isGid(id: string): id is Gid {
  return gidRegex.test(id);
}

/**
 * Best effort that handles cases where we erroneously stored the gid instead of the id.
 */
export function parseNumericIdFromShopifyId(id: string): `${number}` {
  if (isGid(id)) {
    return getNumericIdFromGid(id);
  } else {
    return id as `${number}`;
  }
}

export enum ShopifyIdType {
  Customer = "Customer",
  Order = "Order",
  Product = "Product",
  ProductVariant = "ProductVariant",
  Collection = "Collection",
  DraftOrder = "DraftOrder",
}

export function convertShopifyIdToGid(
  gidOrNumericId: string,
  type: ShopifyIdType,
): Gid {
  if (isGid(gidOrNumericId)) {
    return gidOrNumericId;
  }
  return `gid://shopify/${type}/${gidOrNumericId}` as Gid;
}
