import * as classNames from "classnames";
import {
  ForwardedRef,
  forwardRef,
  JSXElementConstructor,
  memo,
  MouseEvent,
  ReactNode,
} from "react";
import ChevronDownSvg from "../../arbiter-icon/chevron-down_filled.svg";
import ChevronUpSvg from "../../arbiter-icon/chevron-up_filled.svg";
import { Flex } from "../../flex";
import {
  RedoButton,
  RedoButtonHierarchy,
  RedoButtonSize,
  RedoButtonTheme,
} from "./redo-button";
import * as redoButtonCss from "./redo-button.module.css";

/**
 * @param children -- this should be your dropdown component already wrapped in the dropdown
 */
export interface RedoDropdownButtonProps {
  hierarchy?: RedoButtonHierarchy;
  text: string;
  onClick(event: MouseEvent): void;
  size?: RedoButtonSize;
  IconLeading?: JSXElementConstructor<any>;
  disabled?: boolean;
  pending?: boolean;
  children: ReactNode;
  dropdownOpen: boolean;
  setDropdownOpen(value: boolean): void;
  theme?: RedoButtonTheme;
  mainButtonStretchHorizontal?: boolean;
  refSetToContainer?: boolean;
}

export const RedoButtonDropdown = memo(
  forwardRef(function RedoButtonDropdown(
    {
      hierarchy = RedoButtonHierarchy.PRIMARY,
      size,
      IconLeading,
      disabled,
      pending,
      text,
      onClick,
      children,
      dropdownOpen,
      setDropdownOpen,
      theme = RedoButtonTheme.NORMAL,
      mainButtonStretchHorizontal = false,
      refSetToContainer = false,
    }: RedoDropdownButtonProps,
    ref: ForwardedRef<HTMLButtonElement>,
  ) {
    return (
      <>
        <Flex
          className={classNames(redoButtonCss.dropdownButton)}
          gap="none"
          ref={refSetToContainer ? ref : undefined}
        >
          <RedoButton
            disabled={disabled}
            flexGrow={mainButtonStretchHorizontal ? 1 : undefined}
            hierarchy={hierarchy}
            IconLeading={IconLeading}
            onClick={onClick}
            pending={pending}
            size={size}
            text={text}
            theme={theme}
          />
          <RedoButton
            disabled={disabled || pending}
            hierarchy={hierarchy}
            IconLeading={dropdownOpen ? ChevronUpSvg : ChevronDownSvg}
            onClick={() => {
              setDropdownOpen(!dropdownOpen);
            }}
            ref={refSetToContainer ? undefined : ref}
            size={size}
            theme={theme}
          />
        </Flex>
        {children}
      </>
    );
  }),
);
