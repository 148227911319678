// extracted by mini-css-extract-plugin
export var danger = "_3c-6";
export var lightPink = "_3c-a";
export var medium = "_3c-j";
export var nearSquare = "_3c-m";
export var neutralBlue = "_3c-8";
export var neutralYellow = "_3c-7";
export var noCapitalize = "_3c-1";
export var normal = "_3c-2";
export var pill = "_3c-0";
export var primary = "_3c-3";
export var primaryLight = "_3c-4";
export var shadow = "_3c-n";
export var small = "_3c-k";
export var solidBlack = "_3c-i";
export var solidGreen = "_3c-d";
export var solidLightBlue = "_3c-f";
export var solidNavy = "_3c-e";
export var solidOrange = "_3c-h";
export var solidPink = "_3c-g";
export var solidRed = "_3c-b";
export var solidWhite = "_3c-9";
export var solidYellow = "_3c-c";
export var success = "_3c-5";
export var xsmall = "_3c-l";