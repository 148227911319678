// extracted by mini-css-extract-plugin
export var attachment = "_bb-a";
export var emailContainer = "_bb-4";
export var emailContentPaddedContainer = "_bb-b";
export var emailHeader = "_bb-6";
export var emailInfoIcon = "_bb-9";
export var emailShortenedSummary = "_bb-e";
export var infoDropdown = "_bb-0";
export var infoRowBoundedContent = "_bb-d";
export var internal = "_bb-5";
export var merchant = "_bb-8";
export var messageInputContainer = "_bb-h";
export var normal = "_bb-7";
export var replyCard = "_bb-f";
export var shadowDomWrapper = "_bb-3";
export var subtleButton = "_bb-1";
export var subtleButtonIconContainer = "_bb-2";
export var toggleViewRepliesButton = "_bb-c";
export var wrappingText = "_bb-g";