import { OutboundRate } from "@redotech/redo-model/outbound-labels/outbound-labels";
import { Flex } from "@redotech/redo-web/flex";
import PackageIcon from "@redotech/redo-web/icon-old/package.svg";
import { Text } from "@redotech/redo-web/text";
import * as classNames from "classnames";
import { memo, useMemo } from "react";
import {
  getCarrierLogoUrl,
  getPrettyCarrierName,
  getPrettyServiceName,
} from "../../utils/outbound-labels-utils";
import * as styles from "./shipping-selection-cell.module.css";

export const ShippingSelectionCell = memo(function ShippingSelectionCell({
  selectedRate,
  hideDeliveryDays,
  labelWhenNoRate,
}: {
  selectedRate: OutboundRate | null;
  hideDeliveryDays?: boolean;
  labelWhenNoRate?: string;
}) {
  const logoUrl = useMemo(() => {
    return selectedRate ? getCarrierLogoUrl(selectedRate?.carrier) : "";
  }, [selectedRate?.carrier]);

  const carrierName = useMemo(() => {
    return selectedRate ? getPrettyCarrierName(selectedRate?.carrier) : "";
  }, [selectedRate]);

  const serviceName = useMemo(() => {
    return selectedRate ? getPrettyServiceName(selectedRate?.service) : "";
  }, [selectedRate]);

  const deliveryDays = useMemo(() => {
    if (!selectedRate?.delivery_days) {
      return "";
    }
    return ` - ${selectedRate.delivery_days} business days`;
  }, [selectedRate]);

  return (
    <Flex align="center" pl="lg">
      {selectedRate ? (
        <>
          {logoUrl !== "" && (
            <img className={classNames(styles.nameLogo)} src={logoUrl} />
          )}
          {logoUrl === "" && (
            <PackageIcon
              classNames={classNames(styles.nameLogo)}
              height={40}
              width={40}
            />
          )}
          <Text fontSize="xs">
            {carrierName} {serviceName}
            {!hideDeliveryDays && (
              <Text as="span" fontWeight="thin" textColor="tertiary">
                {" "}
                - ${selectedRate.rate}
                {deliveryDays}
              </Text>
            )}
          </Text>
        </>
      ) : (
        <Text fontSize="xs" fontWeight="medium" textColor="tertiary">
          {labelWhenNoRate ?? "No rate selected"}
        </Text>
      )}
    </Flex>
  );
});
