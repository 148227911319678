import { IterableMap, genericMemo } from "@redotech/react-util/component";
import * as classNames from "classnames";
import { Key, ReactElement, ReactNode, useEffect, useState } from "react";
import * as expandableDropdownCss from "./expandable-dropdown.module.css";
import Check from "./icon-old/check.svg";
import ChevronDown from "./icon-old/chevron-down.svg";

export interface ExpandableDropdownProps<T> {
  children(option: T): ReactNode;
  header: ReactNode;
  keyFn?(option: T, index: number): Key;
  id?: string;
  options: readonly T[][] | readonly T[];
  className?: string;
  value: T | undefined;
  valueChange(value: T | undefined): void;
  overrideOpen?: boolean;
  onOpen?(id: Key): void;
}

export const ExpandableDropdownGroup = genericMemo(
  function ExpandableDropdownGroup<T>({
    children,
  }: {
    children: ((props: any) => ReactElement | null)[];
  }) {
    // Only one dropdown can be open at a time
    const [openDropdownIndex, setOpenDropdownIndex] = useState<
      number | undefined
    >(undefined);
    const onOpen = (index: number) => {
      setOpenDropdownIndex(index);
    };

    return (
      <div className={expandableDropdownCss.dropdownGroup}>
        {children.map((Dropdown, index) => (
          <Dropdown
            id={index}
            key={index}
            onOpen={onOpen}
            overrideOpen={openDropdownIndex === index}
          />
        ))}
      </div>
    );
  },
);

export const ExpandableDropdown = genericMemo(function ExpandableDropdown<T>({
  children,
  header,
  keyFn = (_, index) => index,
  id,
  options,
  className,
  value,
  valueChange,
  overrideOpen,
  onOpen,
}: ExpandableDropdownProps<T>) {
  const [open, setOpen] = useState(overrideOpen || false);

  useEffect(() => {
    if (overrideOpen !== undefined) {
      setOpen(overrideOpen);
    }
  }, [overrideOpen]);

  useEffect(() => {
    if (open && onOpen && id !== undefined) {
      onOpen(id);
    }
    // FIXME
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [open]);

  const height = 72 * options.length;

  const getDropdownOption = (option: T, index: number) => {
    return (
      <div
        className={classNames(
          className,
          expandableDropdownCss.item,
          expandableDropdownCss.option,
          index !== options.length - 1
            ? expandableDropdownCss.borderBottom
            : "",
        )}
        onClick={() => valueChange(option)}
      >
        {children(option)}
        <div className={expandableDropdownCss.iconContainer}>
          <Check style={{ opacity: value === option ? "1" : "0" }} />
        </div>
      </div>
    );
  };

  return (
    <div
      className={classNames(
        expandableDropdownCss.dropdown,
        expandableDropdownCss.borderBottom,
      )}
    >
      <div
        className={expandableDropdownCss.item}
        onClick={() => setOpen(!open)}
      >
        <div>{header}</div>
        <div className={expandableDropdownCss.iconContainer}>
          <ChevronDown
            className={expandableDropdownCss.chevron}
            height={20}
            style={{ transform: `rotate(${open ? "180deg" : "0deg"})` }}
            width={20}
          />
        </div>
      </div>
      <div
        className={expandableDropdownCss.dropdownItems}
        style={{ height: open ? `${height}px` : "0px" }}
      >
        <IterableMap
          items={
            Array.isArray(options[0]) ? (options as T[][]) : [options as T[]]
          }
          keyFn={(_, index) => index}
        >
          {(options) => (
            <IterableMap items={options} keyFn={keyFn}>
              {getDropdownOption}
            </IterableMap>
          )}
        </IterableMap>
      </div>
    </div>
  );
});
