// extracted by mini-css-extract-plugin
export var centered = "_2r-6";
export var flexible = "_2r-5";
export var footer = "_2r-k";
export var footerBorder = "_2r-l";
export var header = "_2r-c";
export var headerBorder = "_2r-d";
export var headerDefault = "_2r-g";
export var headerMedium = "_2r-h";
export var headerPadding = "_2r-e";
export var iconAndTitle = "_2r-f";
export var large = "_2r-4";
export var largePadding = "_2r-a";
export var medium = "_2r-3";
export var mediumPadding = "_2r-9";
export var modal = "_2r-0";
export var modalContainer = "_2r-b";
export var noPadding = "_2r-7";
export var skinny = "_2r-1";
export var small = "_2r-2";
export var smallPadding = "_2r-8";
export var subtitle = "_2r-j";
export var title = "_2r-i";