import { Tag } from "@redotech/redo-model/tag";
import { RedoBadgeSize } from "@redotech/redo-web/arbiter-components/badge/redo-badge";
import { Dropdown } from "@redotech/redo-web/dropdown";
import { Flex } from "@redotech/redo-web/flex";
import { PillTheme } from "@redotech/redo-web/pill";
import { TagPill } from "@redotech/redo-web/tags/tag-pill";
import { memo, useLayoutEffect, useRef, useState } from "react";
import * as tagListCss from "./tag-list.module.css";

export const TagList = memo(function TagList({
  tags,
  hoverToShow = false,
}: {
  tags: Tag[];
  hoverToShow?: boolean;
}) {
  const hiddenRef = useRef<HTMLDivElement>(null);
  const [tagsToShow, setTagsToShow] = useState<number>(0);
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const [dropdownAnchor, setDropdownAnchor] = useState<HTMLElement | null>(
    null,
  );
  const timeoutRef = useRef<NodeJS.Timeout>();

  useLayoutEffect(() => {
    if (!hiddenRef.current) return;

    const calculateVisibleTags = () => {
      const parentWidth = hiddenRef.current?.parentElement?.clientWidth || 0;
      const tagElements = Array.from(
        hiddenRef.current?.children[0]?.children || [],
      );
      const padding = 8;
      let accumulatedWidth = 0;
      let visibleCount = 0;

      const overflowPillWidth = 40;
      const availableWidth =
        parentWidth - (tags.length > visibleCount ? overflowPillWidth : 0);

      for (const tagElement of tagElements) {
        const tagWidth = tagElement.clientWidth + padding;
        if (accumulatedWidth + tagWidth > availableWidth) break;

        accumulatedWidth += tagWidth;
        visibleCount++;
      }

      setTagsToShow(visibleCount);
    };
    calculateVisibleTags();

    const resizeObserver = new ResizeObserver(calculateVisibleTags);
    const parentElement = hiddenRef.current.parentElement;
    if (parentElement) {
      resizeObserver.observe(parentElement);
    }

    return () => resizeObserver.disconnect();
  }, [tags.length]);

  return (
    <>
      <span className={tagListCss.hidden} ref={hiddenRef}>
        <Flex gap="md">
          {tags.map((tag) => (
            <TagPill
              badgeSize={RedoBadgeSize.X_SMALL}
              key={tag.name}
              tag={tag}
            />
          ))}
        </Flex>
      </span>
      <Flex
        gap="md"
        onMouseEnter={() => {
          timeoutRef.current = setTimeout(() => {
            setIsDropdownOpen(true);
          }, 700);
        }}
        onMouseLeave={() => {
          if (timeoutRef.current) {
            clearTimeout(timeoutRef.current);
          }
          setIsDropdownOpen(false);
        }}
        ref={setDropdownAnchor}
      >
        {tags.slice(0, tagsToShow).map((tag) => (
          <TagPill badgeSize={RedoBadgeSize.X_SMALL} key={tag.name} tag={tag} />
        ))}
        {tags.length > tagsToShow && (
          <TagPill
            badgeSize={RedoBadgeSize.X_SMALL}
            tag={{
              name: `+${tags.length - tagsToShow}`,
              pillTheme: PillTheme.NORMAL,
            }}
          />
        )}
      </Flex>
      {tags.length > tagsToShow && hoverToShow && (
        <Dropdown
          anchor={dropdownAnchor}
          fitToAnchor={false}
          open={isDropdownOpen}
        >
          <Flex className={tagListCss.dropdown} dir="column" gap="md">
            {tags.map((tag) => (
              <TagPill
                key={tag.name}
                tag={{ name: tag.name, pillTheme: PillTheme.NORMAL }}
              />
            ))}
          </Flex>
        </Dropdown>
      )}
    </>
  );
});
