import {
  Condition,
  Condition as ModelCondition,
} from "@redotech/redo-model/return-flow/condition";
import { ChipDelimiter, ChipInput } from "@redotech/redo-web/chip-input";
import { LabeledInput } from "@redotech/redo-web/labeled-input";
import { SelectDropdown } from "@redotech/redo-web/select-dropdown";
import { ConditionType } from "../../return-flow/condition";

type State = Omit<Condition.SentFromEmail, "type">;

export const SENT_FROM_EMAIL: ConditionType<State, Condition.SentFromEmail> = {
  name: "Email sent from",
  description() {
    return "The email address of the sender of the email";
  },
  Details({ state, setState, disabled }) {
    return (
      <>
        <LabeledInput label="Sender email address(es)">
          <ChipInput
            delimiter={ChipDelimiter.NEWLINE}
            disabled={disabled}
            showPlaceholderWithoutFocus
            value={state.emails}
            valueChange={(e) => {
              if (e) {
                setState({ ...state, emails: e as typeof state.emails });
              }
            }}
          />
        </LabeledInput>
        <LabeledInput label="Match mode">
          <SelectDropdown
            disabled={disabled}
            options={["INCLUDES", "IS_EXACTLY"]}
            value={state.matchMode}
            valueChange={(e) => {
              if (e) {
                setState({ ...state, matchMode: e as typeof state.matchMode });
              }
            }}
          >
            {(mode) => (
              <p>
                {mode === "IS_EXACTLY"
                  ? "Email matches exactly"
                  : "Email contains"}
              </p>
            )}
          </SelectDropdown>
        </LabeledInput>
      </>
    );
  },
  empty: { emails: [], matchMode: "INCLUDES" },
  fromModel(model) {
    return { emails: model.emails, matchMode: model.matchMode };
  },
  toModel(state) {
    return {
      type: ModelCondition.SENT_FROM_EMAIL,
      emails: state.emails,
      matchMode: state.matchMode,
    };
  },
  valid(state) {
    return !!state.emails.length;
  },
};
