import * as amplitude from "@amplitude/analytics-browser";
import { useRequiredContext } from "@redotech/react-util/context";
import { useTriggerLoad } from "@redotech/react-util/load";
import {
  RETURN_TYPE_ROUTE,
  ReturnTypeEnum,
  type Return,
} from "@redotech/redo-model/return";
import {
  FlowType,
  returnFlowJsonFormat,
} from "@redotech/redo-model/return-flow";
import { alertOnFailure } from "@redotech/redo-web/alert";
import {
  BlockConfig,
  EdgeConfig,
  Flowchart,
  Selection,
  SelectionMode,
  SelectionTheme,
} from "@redotech/redo-web/flowchart";
import { LoadingRedoAnimation } from "@redotech/redo-web/loading-redo-animation";
import { Modal, ModalSize, PaddingAmount } from "@redotech/redo-web/modal";
import { memo, useMemo, useRef } from "react";
import { useNavigate } from "react-router-dom";
import { TeamContext } from "../../app/team";
import { RedoMerchantClientContext } from "../../client/context";
import { resetReturn } from "../../client/return";
import {
  FLOW_TYPES,
  modelToSteps,
} from "../../setting/flow-editor/flow-editor";
import { REJECT } from "../../setting/return-flow/reject";
import * as returnFlowCss from "../../setting/return-flow/return-flow.module.css";
import * as returnCss from "../return.module.css";
import { returnTypeName } from "../util";

export const ResetModal = memo(function ResetModal({
  open,
  onClose,
  return: return_,
  preventNavigate = false,
}: {
  open: boolean;
  onClose(): void;
  return: Return;
  preventNavigate?: boolean;
}) {
  const team = useRequiredContext(TeamContext);

  const client = useRequiredContext(RedoMerchantClientContext);
  const navigate = useNavigate();
  const selected = useRef<Selection>();
  const [resetLoad, doReset] = useTriggerLoad(async (signal) => {
    if (!selected.current) {
      return false;
    }

    return await alertOnFailure("Reseting return failed")(async () => {
      await resetReturn(client, {
        step: +selected.current!,
        returnId: return_.id,
      });
      amplitude.logEvent("reset-return-support", {
        team: team._id,
        returnId: return_._id,
      });

      if (!preventNavigate) {
        navigate(`/${RETURN_TYPE_ROUTE[return_.type]}`);
      } else {
        onClose();
      }

      return true;
    });
  });

  const steps = useMemo(() => {
    switch (return_.type) {
      case ReturnTypeEnum.CLAIM:
        return modelToSteps(
          returnFlowJsonFormat.read(team.settings.claimFlow),
          FLOW_TYPES.CLAIM,
        );
      case ReturnTypeEnum.WARRANTY:
        return modelToSteps(
          returnFlowJsonFormat.read(team.settings.warrantyFlow),
          FLOW_TYPES.WARRANTY,
        );
      default:
        return modelToSteps(
          returnFlowJsonFormat.read(team.settings.returnFlow),
          FLOW_TYPES.RETURN,
        );
    }
  }, [team]);

  const selectionMode: SelectionMode = useMemo(
    () => ({
      enabled: (id) => steps.get(id)!.type !== REJECT,
      theme: SelectionTheme.HIGHLIGHT,
    }),
    [steps],
  );

  const blocks: BlockConfig[] = [...steps.entries()].map(([id, step]) => ({
    error: !step.valid(FlowType.RETURN),
    layout: step.layout(),
    title: step.title(),
    description: step.description(),
    icon: <step.Icon className={returnFlowCss.icon} />,
    id,
  }));

  const edges: EdgeConfig[] = [...steps.entries()].flatMap(([id, step]) =>
    step
      .downstream()
      .map((downstream) => ({
        id: `${id}.${downstream.id}`,
        from: id,
        to: downstream.id,
        label: downstream.label,
      })),
  );

  return (
    <Modal
      onClose={onClose}
      open={open}
      paddingAmount={PaddingAmount.NONE}
      size={ModalSize.LARGE}
      title={`Reset ${returnTypeName(return_.type)}`}
    >
      <div className={returnCss.paddedModalContent}>
        {resetLoad.value ? (
          <p>The items have been reset.</p>
        ) : resetLoad.pending ? (
          <div className={returnCss.animationContainer}>
            <LoadingRedoAnimation />
          </div>
        ) : (
          <>
            <p>
              This will delete the current {returnTypeName(return_.type)}
              {return_.shipment ? <> and void the shipping label</> : null}. The
              customer will be able to select the items again in the{" "}
              {returnTypeName(return_.type)} portal.
            </p>
            <p>
              Choose the step of the {returnTypeName(return_.type)} flow to
              reset the items to. When {returnTypeName(return_.type)}ing the
              items, the customer will start at this step in the{" "}
              {returnTypeName(return_.type)} portal.
            </p>
            <Flowchart
              blocks={blocks}
              edges={edges}
              onSelect={(s) => {
                selected.current = s;
                doReset();
              }}
              selectionMode={selectionMode}
              showBorder
            />
          </>
        )}
      </div>
    </Modal>
  );
});
