import { useRequiredContext } from "@redotech/react-util/context";
import { createContext, useCallback, useEffect, useReducer } from "react";
import { MerchantAppSetting } from "../setting/settings";
import { useSearchModel } from "./search-model-context";
import type { SearchResult } from "./types";

const CACHE_VERSION = "1";
const CACHE_KEY = `settings-embeddings-${CACHE_VERSION}`;
const SIMILARITY_THRESHOLD = 0.1;

interface CachedEmbedding {
  embedding: number[];
  textHash: string;
}

interface CachedData {
  version: string;
  embeddings: Record<string, CachedEmbedding>;
  settingIds: string[];
}

type IndexingStatus = "idle" | "processing" | "complete" | "error";

interface SettingSearchState {
  embeddings: Record<string, number[]>;
  indexingStatus: Record<string, { status: IndexingStatus; error?: Error }>;
  progress: { completed: number; total: number } | null;
}

type SettingSearchAction =
  | { type: "START_INDEXING"; payload: { total: number } }
  | {
      type: "EMBEDDING_COMPLETE";
      payload: { settingId: string; embedding: number[] };
    }
  | { type: "EMBEDDING_ERROR"; payload: { settingId: string; error: Error } }
  | { type: "INDEXING_COMPLETE" }
  | { type: "ERROR"; payload: Error };

const SettingSearchContext = createContext<
  | {
      state: SettingSearchState;
      search: (query: string) => Promise<SearchResult[]>;
    }
  | undefined
>(undefined);

function generateSimpleHash(text: string): string {
  let hash = 0;
  for (let i = 0; i < text.length; i++) {
    const char = text.charCodeAt(i);
    hash = (hash << 5) - hash + char;
    hash = hash & hash;
  }
  return hash.toString(36);
}

function cosineSimilarity(a: number[], b: number[]): number {
  const dotProduct = a.reduce((sum, val, i) => sum + val * b[i], 0);

  const magnitudeA = Math.sqrt(a.reduce((sum, val) => sum + val * val, 0));
  const magnitudeB = Math.sqrt(b.reduce((sum, val) => sum + val * val, 0));

  if (magnitudeA === 0 || magnitudeB === 0) {
    console.warn("Zero magnitude vector detected");
    return 0;
  }

  const similarity = dotProduct / (magnitudeA * magnitudeB);

  console.debug({
    dotProduct,
    magnitudeA,
    magnitudeB,
    similarity,
    vectorLengths: { a: a.length, b: b.length },
  });

  return similarity;
}

function settingSearchReducer(
  state: SettingSearchState,
  action: SettingSearchAction,
): SettingSearchState {
  switch (action.type) {
    case "START_INDEXING":
      return {
        ...state,
        progress: { completed: 0, total: action.payload.total },
      };

    case "EMBEDDING_COMPLETE": {
      const newProgress = state.progress
        ? { ...state.progress, completed: state.progress.completed + 1 }
        : null;

      return {
        ...state,
        embeddings: {
          ...state.embeddings,
          [action.payload.settingId]: action.payload.embedding,
        },
        indexingStatus: {
          ...state.indexingStatus,
          [action.payload.settingId]: { status: "complete" },
        },
        progress: newProgress,
      };
    }

    case "EMBEDDING_ERROR":
      console.warn(
        `Error embedding setting ${action.payload.settingId}:`,
        action.payload.error,
      );
      return {
        ...state,
        indexingStatus: {
          ...state.indexingStatus,
          [action.payload.settingId]: {
            status: "error",
            error: action.payload.error,
          },
        },
      };

    case "INDEXING_COMPLETE":
      return { ...state, progress: null };

    case "ERROR":
      console.error("Global indexing error:", action.payload);
      return {
        ...state,
        progress: null,
        indexingStatus: Object.keys(state.indexingStatus).reduce(
          (acc, key) => ({
            ...acc,
            [key]: { status: "error", error: action.payload },
          }),
          {},
        ),
      };

    default:
      return state;
  }
}

interface GlobalSearchProviderProps {
  children: React.ReactNode;
  settings: MerchantAppSetting[];
}

export const GlobalSearchProvider: React.FC<GlobalSearchProviderProps> = ({
  children,
  settings,
}) => {
  const [state, dispatch] = useReducer(settingSearchReducer, {
    embeddings: {},
    indexingStatus: {},
    progress: null,
  });

  const searchModel = useSearchModel();

  const generateSearchableText = (setting: MerchantAppSetting): string => {
    return [setting.title, setting.description, ...(setting.keywords || [])]
      .join(" ")
      .toLowerCase()
      .trim();
  };

  const loadCache = useCallback((): CachedData | null => {
    try {
      const cached = localStorage.getItem(CACHE_KEY);
      if (!cached) {
        return null;
      }

      const cachedData = JSON.parse(cached) as CachedData;

      if (cachedData.version !== CACHE_VERSION) {
        localStorage.removeItem(CACHE_KEY);
        return null;
      }

      return cachedData;
    } catch (error) {
      console.error("Error loading cache:", error);
      localStorage.removeItem(CACHE_KEY);
      return null;
    }
  }, []);

  const saveCache = useCallback(
    (settingId: string, embedding: number[], textHash: string) => {
      try {
        const currentCache = loadCache() || {
          version: CACHE_VERSION,
          embeddings: {},
          settingIds: [],
        };

        const newCache: CachedData = {
          ...currentCache,
          embeddings: {
            ...currentCache.embeddings,
            [settingId]: { embedding, textHash },
          },
          settingIds: Array.from(
            new Set([...currentCache.settingIds, settingId]),
          ),
        };

        localStorage.setItem(CACHE_KEY, JSON.stringify(newCache));
      } catch (error) {
        console.error("Error saving to cache:", error);
      }
    },
    [loadCache],
  );

  useEffect(() => {
    const processSettings = async () => {
      const cache = loadCache();
      dispatch({ type: "START_INDEXING", payload: { total: settings.length } });

      for (const setting of settings) {
        try {
          const searchableText = generateSearchableText(setting);
          const textHash = generateSimpleHash(searchableText);

          const cachedEmbedding = cache?.embeddings[setting.id];
          if (cachedEmbedding && cachedEmbedding.textHash === textHash) {
            dispatch({
              type: "EMBEDDING_COMPLETE",
              payload: {
                settingId: setting.id,
                embedding: cachedEmbedding.embedding,
              },
            });
            continue;
          }

          const embedding = await searchModel.getEmbedding(searchableText);
          saveCache(setting.id, embedding, textHash);

          dispatch({
            type: "EMBEDDING_COMPLETE",
            payload: { settingId: setting.id, embedding },
          });
        } catch (error) {
          dispatch({
            type: "EMBEDDING_ERROR",
            payload: { settingId: setting.id, error: error as Error },
          });
        }
      }

      dispatch({ type: "INDEXING_COMPLETE" });
    };

    void processSettings();
  }, [settings, searchModel, loadCache, saveCache]);

  const search = async (query: string): Promise<SearchResult[]> => {
    if (!query?.trim() || !settings.length) {
      return [];
    }

    const trimmedQuery = query.trim().toLowerCase();

    const keywordResults = new Set(
      settings.filter((setting) => {
        const queryKeywords = trimmedQuery
          .split(" ")
          .filter((term) => term.length > 3);

        return queryKeywords.some(
          (term) =>
            setting.title.toLowerCase().includes(term) ||
            setting.description.toLowerCase().includes(term) ||
            setting.keywords?.some((keyword) =>
              keyword.toLowerCase().includes(term),
            ),
        );
      }),
    );

    try {
      const queryEmbedding = await searchModel.getEmbedding(trimmedQuery);

      const semanticResults = settings.map((setting): SearchResult | null => {
        const settingEmbedding = state.embeddings[setting.id];
        if (!settingEmbedding) return null;

        const similarity = cosineSimilarity(queryEmbedding, settingEmbedding);

        if (
          similarity <= SIMILARITY_THRESHOLD &&
          !keywordResults.has(setting)
        ) {
          return null;
        }

        return {
          ...setting,
          score: similarity,
          matches: [
            {
              field: keywordResults.has(setting)
                ? "keyword+semantic"
                : "semantic",
              term: trimmedQuery,
            },
          ],
        };
      });

      const results = semanticResults
        .filter((result): result is SearchResult => result !== null)
        .sort((a, b) => (b.score ?? 0) - (a.score ?? 0));

      return results;
    } catch (error) {
      console.error("Search failed:", error);
      return Array.from(keywordResults).map((result) => ({
        ...result,
        score: 1,
        matches: [{ field: "keyword", term: trimmedQuery }],
      }));
    }
  };

  return (
    <SettingSearchContext.Provider value={{ state, search }}>
      {children}
    </SettingSearchContext.Provider>
  );
};

export const useSettingSearch = () => useRequiredContext(SettingSearchContext);
