import { ReportSchema } from "@redotech/redo-model/analytics/reports/report";
import { z } from "zod";

export const getReport = {
  input: z.object({ _id: z.string() }),
  output: ReportSchema.nullable(),
};

export const getReports = {
  input: z.object({}),
  output: z.array(ReportSchema),
};

export const createReport = {
  input: ReportSchema.omit({
    _id: true,
    team: true, // The team is set to the current team
    createdAt: true,
    updatedAt: true,
  }),
  output: ReportSchema,
};

export const updateReport = {
  input: ReportSchema.omit({
    team: true, // The team is set to the current team
    createdAt: true,
    updatedAt: true,
  }),
  output: ReportSchema,
};

export const deleteReport = {
  input: z.object({ _id: z.string() }),
  output: z.object({}),
};
