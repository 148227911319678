import { FulfillmentOrderData } from "@redotech/redo-model/fulfillments/fulfillment-group";
import { FulfillmentOrderAddress } from "@redotech/redo-model/fulfillments/fulfillment-order-address";
import { SortDirection } from "@redotech/redo-model/tables/table";
import { RedoTableTextCell } from "@redotech/redo-web/arbiter-components/tables/redo-table-cells";
import { ColumnAlignment } from "@redotech/redo-web/table";
import { Column2 } from "@redotech/redo-web/table2";
import { Tooltip } from "@redotech/redo-web/tooltip/tooltip";

function addressToString(address: FulfillmentOrderAddress) {
  return `${address.address1 || ""} ${address.address2 || ""} ${address.city || ""} ${address.province || ""} ${address.zip || ""} ${address.countryCode || ""}`;
}

export const FulfillmentLocationColumn: Column2<FulfillmentOrderData> = {
  key: "fulfillment-location",
  title: "Fulfillment location",
  alignment: ColumnAlignment.LEFT,
  renderNormalCell: ({ row }) => (
    <Tooltip title={addressToString(row.originAddress)}>
      <div>
        <RedoTableTextCell text={row.originAddress.name || ""} />
      </div>
    </Tooltip>
  ),
  width: 240,
  sort: SortDirection.ASC,
};
