import { Flex } from "@redotech/redo-web/flex";
import { Text } from "@redotech/redo-web/text";
import { memo } from "react";

export const OrderItemsCell = memo(function OrderItemsCell({
  totalItemQuantity,
}: {
  totalItemQuantity: number;
}) {
  return (
    <Flex dir="column" p="xl">
      <Text fontSize="sm">{String(totalItemQuantity)}</Text>
    </Flex>
  );
});
