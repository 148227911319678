import { useRequiredContext } from "@redotech/react-util/context";
import { FulfillmentGroupStatus } from "@redotech/redo-model/fulfillments/fulfillment-group-status";
import { SortDirection, TableSort } from "@redotech/redo-model/tables/table";
import { AdvancedFilterType } from "@redotech/redo-model/views/advanced-filters/generic-advanced-filter-data";
import { StringFilterOperator } from "@redotech/redo-model/views/advanced-filters/string-filter";
import { OutboundSystemView } from "@redotech/redo-model/views/default-outbound-views";
import {
  AdvancedFilterData,
  DEFAULT_PAGE_SIZE,
  viewTypes,
} from "@redotech/redo-model/views/views";
import { deepIntersectionEquals } from "@redotech/util/equal";
import {
  createContext,
  Dispatch,
  memo,
  ReactNode,
  SetStateAction,
  useEffect,
  useState,
} from "react";
import { Location, useLocation } from "react-router-dom";
import { OutboundViewsContext } from "../app/outbound-views";

const toTitleCase = (str: string) => {
  return str
    .trim()
    .replace(/\w\S*/g, (txt) =>
      txt.length > 2
        ? txt.charAt(0).toUpperCase() + txt.substring(1).toLowerCase()
        : txt,
    );
};

export const sortDefault: TableSort = {
  direction: SortDirection.DESC,
  key: "createdAt",
};

const completeFilter: AdvancedFilterData = {
  name: "status",
  type: AdvancedFilterType.STRING,
  value: toTitleCase(FulfillmentGroupStatus.Closed),
  operator: StringFilterOperator.EQUALS,
};

const incompleteFilter: AdvancedFilterData = {
  name: "status",
  type: AdvancedFilterType.STRING,
  value: toTitleCase(FulfillmentGroupStatus.Open),
  operator: StringFilterOperator.EQUALS,
};

// These must match the column definitions found in the table, else "Save as
const defaultColumns = [
  { key: "split-accordian", width: 15, hidden: false },
  { key: "selected", width: 24, hidden: false },
  { key: "order-name", width: 120, hidden: false },
  { key: "customer", width: 160, hidden: false },
  { key: "order-date", width: 120, hidden: false },
  { key: "total", width: 100, hidden: false },
  { key: "quantity", width: 80, hidden: false },
  { key: "weight", width: 80, hidden: false },
  { key: "parcel-selection", width: 160, hidden: false },
  { key: "shippingSelection", width: 320, hidden: false },
  { key: "order-age", width: 100, hidden: false },
  { key: "assigned-user", width: 120, hidden: false },
  { key: "destination-country", width: 80, hidden: false },
  { key: "rate", width: 80, hidden: false },
  { key: "item-name", width: 80, hidden: true },
  { key: "item-sku", width: 80, hidden: false },
  { key: "requested-service", width: 160, hidden: false },
  { key: "shippingPaid", width: 120, hidden: false },
];

const StaticSystemViews: Record<
  OutboundSystemView,
  viewTypes["fulfillment-groups"]
> = {
  [OutboundSystemView.AWAITING_SHIPMENT]: {
    table: "fulfillment-groups",
    name: OutboundSystemView.AWAITING_SHIPMENT,
    filters: [incompleteFilter],
    columns: defaultColumns,
    pageSize: DEFAULT_PAGE_SIZE,
    sort: sortDefault,
  },
  [OutboundSystemView.SHIPMENTS]: {
    table: "fulfillment-groups",
    name: OutboundSystemView.SHIPMENTS,
    filters: [completeFilter],
    columns: defaultColumns,
    pageSize: DEFAULT_PAGE_SIZE,
    sort: sortDefault,
  },
  [OutboundSystemView.CREATE]: {
    table: "fulfillment-groups",
    name: OutboundSystemView.CREATE,
    filters: [],
    columns: defaultColumns,
    pageSize: DEFAULT_PAGE_SIZE,
    sort: sortDefault,
  },
};

export const ActiveOutboundViewContext = createContext<
  viewTypes["fulfillment-groups"]
>(StaticSystemViews[OutboundSystemView.AWAITING_SHIPMENT]);

export const SetEditingOutboundViewContext = createContext<
  Dispatch<SetStateAction<boolean>>
>(() => {});
export const EditingOutboundViewContext = createContext<boolean>(false);

export const OutboundActiveViewContextProvider = memo(
  function OutboundActiveViewContextProvider({
    children,
  }: {
    children: ReactNode | ReactNode[];
  }) {
    const views = useRequiredContext(OutboundViewsContext);
    const location: Location = useLocation();

    const [editingView, setEditingView] = useState<boolean>(false);

    const [currentView, setCurrentView] = useState<
      viewTypes["fulfillment-groups"]
    >(
      getViewFromLocation(location, [
        ...getStaticViews(views.teamViews || []),
        ...(views.teamViews || []).filter(
          (view) =>
            !Object.values(OutboundSystemView).includes(
              view.name as OutboundSystemView,
            ),
        ),
      ]),
    );

    useEffect(() => {
      if (currentView.name === OutboundSystemView.CREATE) {
        setEditingView(true);
      } else {
        setEditingView(false);
      }
    }, [currentView]);

    useEffect(() => {
      const newViewToSet = getViewFromLocation(location, [
        ...getStaticViews(views.teamViews || []),
        ...(views.teamViews || []).filter(
          (view) =>
            !Object.values(OutboundSystemView).includes(
              view.name as OutboundSystemView,
            ),
        ),
      ]);

      if (deepIntersectionEquals(newViewToSet, currentView)) {
        return;
      }

      setCurrentView(newViewToSet);
    }, [location, views, currentView]);

    return (
      <EditingOutboundViewContext.Provider value={editingView}>
        <SetEditingOutboundViewContext.Provider value={setEditingView}>
          <ActiveOutboundViewContext.Provider value={currentView}>
            {children}
          </ActiveOutboundViewContext.Provider>
        </SetEditingOutboundViewContext.Provider>
      </EditingOutboundViewContext.Provider>
    );
  },
);

function getStaticViews(
  teamViews: viewTypes["fulfillment-groups"][],
): viewTypes["fulfillment-groups"][] {
  return Object.values(StaticSystemViews).map((view) => {
    const teamView = teamViews.find((tv) => tv.name === view.name);
    if (teamView) {
      return teamView;
    }
    return view;
  });
}

function getViewFromLocation(
  location: Location,
  viewOptions: viewTypes["fulfillment-groups"][],
): viewTypes["fulfillment-groups"] {
  const viewNameMatch = location.pathname.match(
    /\/orders\/outbound-labels\/(.*)/,
  );
  const rawViewName = viewNameMatch ? viewNameMatch[1] : "";
  const decodedViewName = decodeURIComponent(rawViewName);
  const view = viewOptions.find((view) => view.name === decodedViewName);

  if (!view) {
    console.log("Could not determine view from url. Using default view");
  }

  const newViewToSet =
    view || StaticSystemViews[OutboundSystemView.AWAITING_SHIPMENT];

  return { ...newViewToSet };
}
