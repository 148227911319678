// extracted by mini-css-extract-plugin
export var box = "_3u-1";
export var checked = "_3u-5";
export var descriptionSpacer = "_3u-9";
export var disabled = "_3u-4";
export var icon = "_3u-6";
export var input = "_3u-0";
export var labelSpacer = "_3u-8";
export var medium = "_3u-3";
export var small = "_3u-2";
export var text = "_3u-7";