// extracted by mini-css-extract-plugin
export var createdDate = "_ag-1";
export var divider = "_ag-2";
export var headerDivider = "_ag-3";
export var messagesCard = "_ag-0";
export var messagesContainer = "_ag-7";
export var messagesContainerWrapper = "_ag-4";
export var pointerCapturingButtonBoxes = "_ag-a";
export var showLeftPanelButton = "_ag-9";
export var showMoreButton = "_ag-c";
export var showMoreButtonContainer = "_ag-b";
export var summaryTitle = "_ag-6";
export var systemMessageCardWrapper = "_ag-d";
export var topButtons = "_ag-8";
export var withPadding = "_ag-5";