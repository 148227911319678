import { array, nativeEnum, number, object, string, z } from "zod";
import { ResponseLength } from "../concierge-conversation/concierge-conversation-message-definition";
import { CustomerWidgetChipOptions } from "../customer-widget/customer-widget";

export const DEFAULT_QUESTION_PROMPT_COUNT = 1;

export const ConciergeBehaviorSettingsParser = object({
  name: string().max(100).optional(),
  toneOfVoice: string().max(500).optional(),
  writingExamples: array(string().max(1000)).optional(),
  brandDescription: string().max(1000).optional(),
  customerDescription: string().max(1000).optional(),
  outputLength: nativeEnum(ResponseLength).optional(),
});

export type ConciergeBehaviorSettings = z.infer<
  typeof ConciergeBehaviorSettingsParser
>;

export const ConciergeProductFormSettingsParser = object({
  numQuestionPrompts: number().min(0).max(5).optional(),
});

const EmailSignUpParser = object({
  incentivize: z.boolean(),
  buttonLabel: z.string(),
  signUpDiscountAmount: z.string(),
});

export const conciergeEmailSignUpDefault = {
  incentivize: false,
  buttonLabel: "Earn a 15% discount when you sign in",
  signUpDiscountAmount: "15",
};

export const proactiveChipEngagementDefault = true;

export const ConciergeCustomerWidgetSettingsParser = object({
  chips: z
    .record(z.nativeEnum(CustomerWidgetChipOptions), z.string())
    .optional(),
  emailSignUp: EmailSignUpParser.optional(),
  proactiveChipEngagement: z.boolean().optional(),
});

const GuardrailSettingsParser = object({
  storeContactInformation: string().optional(),
});

export const ConciergeGeneralSettingsParser = object({
  brandAndVoiceSettings: ConciergeBehaviorSettingsParser,
  productFormSettings: ConciergeProductFormSettingsParser,
  customerWidgetSettings: ConciergeCustomerWidgetSettingsParser,
  guardrailSettings: GuardrailSettingsParser,
});

export type ConciergeGeneralSettings = z.infer<
  typeof ConciergeGeneralSettingsParser
>;

export const SelfServeOnboardingSettingsParser = object({
  contactStep: z.object({ name: z.string() }),
  skillsStep: z.object({
    backInStockNotifications: z.object({ enabled: z.boolean() }),
    orderTracking: z.object({ enabled: z.boolean() }),
  }),
  personalityStep: z.object({
    toneOfVoice: z.string(),
    brandDescription: z.string(),
    customerDescription: z.string(),
  }),
  customerWidgetStep: z.object({
    color: z.string(),
    promptSettings: z.object({
      selectedChips: z.array(z.nativeEnum(CustomerWidgetChipOptions)),
      otherChipText: z.string(),
      proactiveChipEngagement: z.boolean(),
    }),
  }),
});

export type SelfServeOnboardingSettings = z.infer<
  typeof SelfServeOnboardingSettingsParser
>;
