import { FulfillmentOrderData } from "@redotech/redo-model/fulfillments/fulfillment-group";
import { ColumnAlignment } from "@redotech/redo-web/table";
import { Column2 } from "@redotech/redo-web/table2";
import { SelectedParcelCell } from "../cells/selected-parcel-cell";

export const ParcelSelectionColumn: Column2<FulfillmentOrderData> = {
  key: "parcel-selection",
  title: "Package",
  alignment: ColumnAlignment.LEFT,
  renderNormalCell: ({ row }) => (
    <SelectedParcelCell selectedParcel={row.selectedParcel ?? null} />
  ),
  onClick: (e: React.MouseEvent<HTMLDivElement>) => {
    e.stopPropagation();
  },
  width: 160,
};
