import { zExt } from "@redotech/rpc/ext";
import { z } from "zod";

// TODO @jsterner30 We have an order schema, but if we use it and the actual type is incorrect this will fail.
// We need a more standard order model to use, then we can strongly type this.
// For now I will cast the output array to Order typescript type in redo-model on the front end.
export const getOrders = {
  input: z.object({ orderIds: z.array(z.string()), teamId: z.string() }),
  output: z.object({
    orders: z.array(z.object({ _id: zExt.objectId() }).catchall(z.any())),
  }),
};
