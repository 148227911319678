import type { InferRpcDefinition } from "@redotech/rpc/definition";
import { buyLabels } from "./schema/buy-labels";
import { createEodManifestForAll } from "./schema/create-eod-manifest-for-all";
import { createEodManifestForIds } from "./schema/create-eod-manifest-for-ids";
import { createFulfillmentOrders } from "./schema/create-fulfillment-orders";
import { createShippingMethod } from "./schema/create-shipping-method";
import { deleteFulfillmentGroupView } from "./schema/delete-fulfillment-group-view";
import { deleteParcel } from "./schema/delete-parcel";
import { deleteShippingMethod } from "./schema/delete-shipping-method";
import { getBatchHistory } from "./schema/get-batch-history";
import { getBatchHistoryCount } from "./schema/get-batch-history-count";
import { getCarrierParcels } from "./schema/get-carrier-parcels";
import { getEndOfDayManifests } from "./schema/get-end-of-day-manifests";
import { getEodManifestOptions } from "./schema/get-eod-manifest-options";
import { getFulfillmentGroup } from "./schema/get-fulfillment-group";
import { getFulfillmentGroupSummaries } from "./schema/get-fulfillment-group-summaries";
import { getFulfillmentGroupSummaryCount } from "./schema/get-fulfillment-group-summary-count";
import { getFulfillmentGroupUniqueValues } from "./schema/get-fulfillment-group-unique-values";
import { getFulfillmentGroupViews } from "./schema/get-fulfillment-group-views";
import { getOriginAddresses } from "./schema/get-origin-addresses";
import { getOutboundBalance } from "./schema/get-outbound-balance";
import { getParcels } from "./schema/get-parcels";
import { getShippingMethods } from "./schema/get-shipping-methods";
import { getShippingZones } from "./schema/get-shipping-zones";
import { hasCarrierServices } from "./schema/has-carrier-services";
import { createPreset } from "./schema/presets/create-preset";
import { deletePreset } from "./schema/presets/delete-preset";
import { getPresets } from "./schema/presets/get-presets";
import { updatePreset } from "./schema/presets/update-preset";
import { printCommercialInvoices } from "./schema/print-commercial-invoice";
import { printLabels } from "./schema/print-labels";
import { printPackingSlip } from "./schema/print-packing-slip";
import { printPickList } from "./schema/print-pick-list";
import { saveView } from "./schema/save-view";
import { startBalanceReload } from "./schema/start-balance-reload";
import { updateFulfillmentGroups } from "./schema/update-fulfillment-groups";
import { updateShippingMethod } from "./schema/update-shipping-method";
import { upsertParcels } from "./schema/upsert-parcels";
import { voidShipments } from "./schema/void-shipments";

export const rpcDefinition = {
  getFulfillmentGroupUniqueValues,
  buyLabels,
  createFulfillmentOrders,
  deleteParcel,
  getCarrierParcels,
  getFulfillmentGroup,
  getFulfillmentGroupSummaries,
  getFulfillmentGroupSummaryCount,
  getBatchHistory,
  getOutboundBalance,
  saveView,
  getParcels,
  printPackingSlip,
  printLabels,
  printPickList,
  printCommercialInvoices,
  updateFulfillmentGroups,
  createShippingMethod,
  deleteShippingMethod,
  getShippingMethods,
  updateShippingMethod,
  getShippingZones,
  upsertParcels,
  hasCarrierServices,
  getFulfillmentGroupViews,
  deleteFulfillmentGroupView,
  getBatchHistoryCount,
  getEodManifestOptions,
  createEodManifestForAll,
  createEodManifestForIds,
  getEndOfDayManifests,
  getOriginAddresses,
  getPresets,
  deletePreset,
  createPreset,
  updatePreset,
  startBalanceReload,
  voidShipments,
};

export type OutboundLabelsRpcDefinition = InferRpcDefinition<
  typeof rpcDefinition
>;
