import { IterableMap } from "@redotech/react-util/component";
import { useHandler } from "@redotech/react-util/hook";
import { Step as ModelStep, Payer } from "@redotech/redo-model/return-flow";
import {
  ReturnOptionMethod,
  StoreCreditType,
} from "@redotech/redo-model/return-flow/return-option";
import { AdvancedExchanges, Team } from "@redotech/redo-model/team";
import { Permission, permitted } from "@redotech/redo-model/user";
import {
  Button,
  ButtonSize,
  ButtonTheme,
  IconButton,
} from "@redotech/redo-web/button";
import { CollapseSubsection } from "@redotech/redo-web/card";
import { Checkbox, CheckboxGroup } from "@redotech/redo-web/checkbox";
import { Divider } from "@redotech/redo-web/divider";
import { BlockLayout } from "@redotech/redo-web/flowchart";
import * as gridCss from "@redotech/redo-web/grid.module.css";
import LeftArrowIcon from "@redotech/redo-web/icon-old/left-arrow.svg";
import RefreshIcon from "@redotech/redo-web/icon-old/refresh.svg";
import TrashIcon from "@redotech/redo-web/icon-old/trash.svg";
import { LabeledInput } from "@redotech/redo-web/labeled-input";
import { SelectDropdown } from "@redotech/redo-web/select-dropdown";
import { Switch } from "@redotech/redo-web/switch";
import { InputTheme, TextInput } from "@redotech/redo-web/text-input";
import * as classNames from "classnames";
import { produce } from "immer";
import { memo, useContext } from "react";
import { TeamContext } from "../../app/team";
import { UserContext } from "../../app/user";
import { RequiredMessage } from "./required-message";
import * as returnCss from "./return.module.css";
import { StepType, StepTypeDetailsProps } from "./step";

export interface State extends Omit<ModelStep.Return, "type" | "options"> {
  options: (Omit<
    ModelStep.Return["options"][number],
    "method" | "adjustment"
  > & {
    type: ReturnOptionMethod["type"] | undefined;
    creditType: StoreCreditType;
    adjustmentFlat: string;
    adjustmentPercent: string;
  })[];
}

function valid(state: State) {
  const valid =
    !!state.options.length &&
    state.options.every(
      (option) =>
        !isNaN(parseFloat(option.adjustmentFlat)) &&
        !isNaN(parseFloat(option.adjustmentPercent)),
    );
  return valid;
}

export const returnOptionMethods: ReturnOptionMethod["type"][] = [
  ReturnOptionMethod.EXCHANGE,
  ReturnOptionMethod.STORE_CREDIT,
  ReturnOptionMethod.REFUND,
];

function returnOptionMethod(
  value: ReturnOptionMethod["type"] | undefined,
): string | undefined {
  switch (value) {
    case ReturnOptionMethod.STORE_CREDIT:
      return "Store credit";
    case ReturnOptionMethod.EXCHANGE:
      return "Exchange";
    case ReturnOptionMethod.REFUND:
      return "Refund";
  }
  return undefined;
}

export const AdjustmentElement = memo(function AdjustmentElement({
  adjustmentFlat,
  adjustmentPercent,
  handleAdjustmentFlat,
  handleAdjustmentPercent,
  canEditSettings,
}: {
  adjustmentFlat: string;
  adjustmentPercent: string;
  handleAdjustmentFlat(value: string): void;
  handleAdjustmentPercent(value: string): void;
  canEditSettings: boolean;
}) {
  // Run this whenever the user moves the focus off the element
  // Truncates the value to 2 decimal places (monetary value)
  const formatAdjustmentFlat = useHandler((value: string) => {
    handleAdjustmentFlat((+value).toFixed(2));
  });
  const formatAdjustmentPercent = useHandler((value: string) => {
    if (isNaN(parseFloat(value))) {
      handleAdjustmentPercent("0");
    }
  });

  return (
    <div className={gridCss.grid}>
      <div className={classNames(gridCss.span12, gridCss.span6L)}>
        <LabeledInput label="Adjustment">
          <TextInput
            allowInvalidNumbers
            error={isNaN(parseFloat(adjustmentFlat))}
            onChange={handleAdjustmentFlat}
            onFocus={(focused) =>
              !focused && formatAdjustmentFlat(adjustmentFlat)
            }
            prefix="$"
            readonly={!canEditSettings}
            theme={InputTheme.FORM}
            type="number"
            value={adjustmentFlat}
          />
        </LabeledInput>
      </div>
      <div className={classNames(gridCss.span12, gridCss.span6L)}>
        <LabeledInput label="Adjustment %">
          <TextInput
            allowInvalidNumbers
            error={isNaN(parseFloat(adjustmentPercent))}
            onChange={handleAdjustmentPercent}
            onFocus={(focused) =>
              !focused && formatAdjustmentPercent(adjustmentPercent)
            }
            readonly={!canEditSettings}
            suffix="%"
            theme={InputTheme.FORM}
            type="number"
            value={adjustmentPercent}
          />
        </LabeledInput>
      </div>
    </div>
  );
});

const Details = memo(function Details({
  state,
  setState,
}: StepTypeDetailsProps<State>) {
  const team = useContext(TeamContext);
  const user = useContext(UserContext);

  const canEditSettings =
    !!user && permitted(user.permissions, Permission.EDIT_SETTINGS);

  const handleAdd = useHandler(() => {
    setState((state) => ({
      ...state,
      flatShipping: false,
      flatShippingRate: "0",
      options: [
        ...state.options,
        {
          type: undefined,
          creditType: StoreCreditType.GIFT_CARD,
          labelPayer: Payer.CUSTOMER,
          ship: true,
          exchangeTypes: {
            sameItem: true,
            variant: true,
            exchangeGroups: true,
            advanced: true,
          },
          chargeCustomerForNewOrderShipping: false,
          adjustmentFlat: "0",
          adjustmentPercent: "0",
        },
      ],
    }));
  });

  const handleFlatShipping = useHandler((value: boolean) => {
    setState((state) =>
      produce(state, (state) => {
        state.flatShipping = value;
      }),
    );
  });

  const handleFlatShippingRate = useHandler((value: string) => {
    setState((state) =>
      produce(state, (state) => {
        state.flatShippingRate = value;
      }),
    );
  });

  // Contexts required to be defined
  if (!team) {
    return null;
  }

  return (
    <>
      <IterableMap items={state.options} keyFn={(_, index) => index}>
        {(option, index) => (
          <StateOptionComponent
            canEditSettings={canEditSettings}
            index={index}
            option={option}
            setState={setState}
            state={state}
            team={team}
          />
        )}
      </IterableMap>
      <Divider />
      <div>
        <Button
          disabled={!canEditSettings}
          onClick={handleAdd}
          size={ButtonSize.SMALL}
          theme={ButtonTheme.OUTLINED}
        >
          + Add option
        </Button>
      </div>
      {!state.options.length && (
        <RequiredMessage>Must have at least one option</RequiredMessage>
      )}
      <Divider />
      <CollapseSubsection title="Advanced return options">
        <LabeledInput
          description="Charge customers a flat cost for return shipping labels. Merchant will receive/pay for the difference between actual cost."
          label="Flat shipping cost"
        >
          <Switch
            disabled={!canEditSettings}
            onChange={handleFlatShipping}
            value={state.flatShipping || false}
          />
        </LabeledInput>
        <LabeledInput label="Flat Shipping Rate">
          <TextInput
            onChange={handleFlatShippingRate}
            prefix="$"
            readonly={!canEditSettings}
            theme={InputTheme.FORM}
            value={state.flatShippingRate || "0"}
          />
        </LabeledInput>
      </CollapseSubsection>
    </>
  );
});

const StateOptionComponent = memo(function StateOptionComponent({
  team,
  state,
  setState,
  canEditSettings,
  option,
  index,
}: {
  team: Team;
  state: StepTypeDetailsProps<State>["state"];
  setState: StepTypeDetailsProps<State>["setState"];
  canEditSettings: boolean;
  option: State["options"][number];
  index: number;
}) {
  const handleTypeChange = useHandler((type: ReturnOptionMethod["type"]) =>
    setState((state) =>
      produce(state, (state) => {
        state.options[index].type = type;
      }),
    ),
  );
  const handleAdjustmentFlat = useHandler((value: string) => {
    setState((state) =>
      produce(state, (state) => {
        state.options[index].adjustmentFlat = value;
      }),
    );
  });
  const handleAdjustmentPercent = useHandler((value: string) => {
    setState((state) =>
      produce(state, (state) => {
        state.options[index].adjustmentPercent = value;
      }),
    );
  });
  const handleGreenReturn = useHandler((value: boolean) => {
    setState((state) =>
      produce(state, (state) => {
        state.options[index].ship = !value;
      }),
    );
  });
  const handleSafetyNet = useHandler((value: boolean) => {
    setState((state) =>
      produce(state, (state) => {
        state.options[index].labelPayer = value
          ? Payer.MERCHANT
          : Payer.CUSTOMER;
      }),
    );
  });
  const handleExchangeTypeSameItem = useHandler((value: boolean) => {
    setState((state) =>
      produce(state, (state) => {
        state.options[index].exchangeTypes.sameItem = value;
      }),
    );
  });
  const handleExchangeTypeVariant = useHandler((value: boolean) => {
    setState((state) =>
      produce(state, (state) => {
        state.options[index].exchangeTypes.variant = value;
      }),
    );
  });
  const handleExchangeTypeExchangeGroups = useHandler((value: boolean) => {
    setState((state) =>
      produce(state, (state) => {
        state.options[index].exchangeTypes.exchangeGroups = value;
      }),
    );
  });
  const handleExchangeTypeAdvanced = useHandler((value: boolean) => {
    setState((state) =>
      produce(state, (state) => {
        state.options[index].exchangeTypes.advanced = value;
      }),
    );
  });
  const handleExchangeShipping = useHandler((value: boolean) => {
    setState((state) =>
      produce(state, (state) => {
        state.options[index].chargeCustomerForNewOrderShipping = value;
      }),
    );
  });
  const handleUp = useHandler(() => {
    setState((state) =>
      produce(state, (state) => {
        const options = state.options.splice(index, 1);
        state.options.splice(index - 1, 0, ...options);
      }),
    );
  });
  const handleDown = useHandler(() => {
    setState((state) =>
      produce(state, (state) => {
        const options = state.options.splice(index, 1);
        state.options.splice(index + 1, 0, ...options);
      }),
    );
  });
  const handleDelete = useHandler(() => {
    setState((state) =>
      produce(state, (state) => {
        state.options.splice(index, 1);
      }),
    );
  });
  return (
    <>
      <Divider />
      <LabeledInput
        label={
          <div className={returnCss.label}>
            <div>Option #{index + 1}</div>
            {canEditSettings && (
              <>
                <IconButton
                  disabled={!index}
                  onClick={handleUp}
                  size={ButtonSize.SMALL}
                >
                  <LeftArrowIcon className={returnCss.upArrow} />
                </IconButton>
                <IconButton
                  disabled={index === state.options.length - 1}
                  onClick={handleDown}
                  size={ButtonSize.SMALL}
                >
                  <LeftArrowIcon className={returnCss.downArrow} />
                </IconButton>
                <IconButton onClick={handleDelete} size={ButtonSize.SMALL}>
                  <TrashIcon />
                </IconButton>
              </>
            )}
          </div>
        }
      >
        <SelectDropdown
          disabled={!canEditSettings}
          options={returnOptionMethods}
          value={option.type}
          valueChange={handleTypeChange}
        >
          {returnOptionMethod}
        </SelectDropdown>
      </LabeledInput>
      <AdjustmentElement
        adjustmentFlat={option.adjustmentFlat}
        adjustmentPercent={option.adjustmentPercent}
        canEditSettings={canEditSettings}
        handleAdjustmentFlat={handleAdjustmentFlat}
        handleAdjustmentPercent={handleAdjustmentPercent}
      />
      <CollapseSubsection title="Advanced options">
        <LabeledInput
          description="Customer does not send item back."
          label="Green return"
        >
          <Switch
            disabled={!canEditSettings}
            onChange={handleGreenReturn}
            value={!option.ship}
          />
        </LabeledInput>
        <LabeledInput
          description="Merchant pays for labels not covered by Redo."
          label="Safety net"
        >
          <Switch
            disabled={!canEditSettings}
            onChange={handleSafetyNet}
            value={option.labelPayer === Payer.MERCHANT}
          />
        </LabeledInput>
        {state.options[index].type === ReturnOptionMethod.EXCHANGE && (
          <>
            <LabeledInput
              description={
                <>
                  <p>Select what types of exchanges to allow.</p>
                  {option.exchangeTypes!.advanced &&
                    team.settings.exchanges.advancedExchanges ===
                      AdvancedExchanges.DISABLED && (
                      <p>
                        ⚠ Advanced exchanges are disabled globally in your
                        Exchanges settings.
                      </p>
                    )}
                </>
              }
              label="Allow exchange types"
            >
              <CheckboxGroup>
                <Checkbox
                  disabled={!canEditSettings}
                  onChange={handleExchangeTypeSameItem}
                  value={option.exchangeTypes!.sameItem}
                >
                  Same item
                </Checkbox>
                <Checkbox
                  disabled={!canEditSettings}
                  onChange={handleExchangeTypeVariant}
                  value={option.exchangeTypes!.variant}
                >
                  Variant items
                </Checkbox>
                <Checkbox
                  disabled={!canEditSettings}
                  onChange={handleExchangeTypeExchangeGroups}
                  value={option.exchangeTypes!.exchangeGroups}
                >
                  Exchange groups
                </Checkbox>
                <Checkbox
                  disabled={!canEditSettings}
                  onChange={handleExchangeTypeAdvanced}
                  value={option.exchangeTypes!.advanced}
                >
                  Any item
                </Checkbox>
              </CheckboxGroup>
            </LabeledInput>
            <LabeledInput
              description="If enabled, customer will be charged for outgoing shipping of exchange orders."
              label="Charge customer for new order shipping"
            >
              <Switch
                disabled={!canEditSettings}
                onChange={handleExchangeShipping}
                value={option.chargeCustomerForNewOrderShipping ?? false}
              />
            </LabeledInput>
          </>
        )}
      </CollapseSubsection>
    </>
  );
});

export const RETURN: StepType<State, ModelStep.Return> = {
  Details: Details,
  customTitle(state) {
    return state.customTitle;
  },
  title: "Return",
  valid,
  Icon: RefreshIcon,
  description(state: State) {
    return state.options
      .filter(Boolean)
      .map(
        (option) =>
          ({
            [ReturnOptionMethod.STORE_CREDIT]: "Store credit",
            [ReturnOptionMethod.EXCHANGE]: "Exchange",
            [ReturnOptionMethod.REFUND]: "Refund",
            [ReturnOptionMethod.REPAIR]: "Repair",
          })[option.type!],
      )
      .join(", ");
  },
  downstream() {
    return [];
  },
  empty: { options: [] },
  layout() {
    return BlockLayout.FULL;
  },
  stepDeleted(state) {
    return state;
  },
  toModel(state: State): ModelStep.Return {
    return {
      customTitle: state.customTitle,
      type: ModelStep.RETURN,
      options: state.options.map((option) => {
        const method = getModelReturnOptionMethod(
          option.type!,
          option.creditType!,
        );
        return {
          adjustment: {
            flat: +option.adjustmentFlat!,
            proportion: +option.adjustmentPercent! / 100 + 1,
          },
          labelPayer: option.labelPayer,
          method,
          ship: option.ship,
          exchangeTypes: option.exchangeTypes,
          chargeCustomerForNewOrderShipping:
            option.chargeCustomerForNewOrderShipping,
        };
      }),
      flatShipping: state.flatShipping,
      flatShippingRate: state.flatShippingRate,
    };
  },
  fromModel(model: ModelStep.Return): State {
    return {
      customTitle: model.customTitle,
      options: model.options.map((option) => ({
        adjustmentFlat: String(option.adjustment.flat),
        adjustmentPercent: String(
          round((option.adjustment.proportion - 1) * 100),
        ),
        creditType:
          "creditType" in option.method
            ? option.method.creditType
            : StoreCreditType.GIFT_CARD,
        labelPayer: option.labelPayer,
        type: option.method.type,
        ship: option.ship,
        exchangeTypes: option.exchangeTypes,
        chargeCustomerForNewOrderShipping:
          option.chargeCustomerForNewOrderShipping,
      })),
      flatShipping: model.flatShipping,
      flatShippingRate: model.flatShippingRate,
    };
  },
};

function round(number: number) {
  return Math.round(number * 100) / 100;
}

export function getModelReturnOptionMethod(
  type: ReturnOptionMethod["type"],
  creditType: StoreCreditType,
): ReturnOptionMethod {
  switch (type) {
    case ReturnOptionMethod.STORE_CREDIT:
      return { type: ReturnOptionMethod.STORE_CREDIT, creditType: creditType };
    case ReturnOptionMethod.EXCHANGE:
      return { type: ReturnOptionMethod.EXCHANGE };
    case ReturnOptionMethod.REFUND:
      return { type: ReturnOptionMethod.REFUND };
    case ReturnOptionMethod.REPAIR:
      return { type: ReturnOptionMethod.REPAIR };
  }
}
