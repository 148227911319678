import { EndOfDayManifestSchema } from "@redotech/redo-model/fulfillments/scan-form";
import { z } from "zod";

export const createEodManifestForAll = {
  input: z.object({
    carrier: z.string(),
    closeAllOn: z.date(),
    addressName: z.string(),
  }),
  output: z.object({ manifest: EndOfDayManifestSchema }),
};
