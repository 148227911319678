import { Address } from "@redotech/redo-model/team";
import { Flex } from "@redotech/redo-web/flex";
import { Text } from "@redotech/redo-web/text";
import { memo } from "react";

export const AddressDisplay = memo(function AddressDisplay({
  address,
  hideName = false,
}: {
  address: Address;
  hideName?: boolean;
}) {
  return (
    <Flex dir="column" gap="xs">
      {!hideName && (
        <Text fontSize="xs" fontWeight="regular">
          {address.name}
        </Text>
      )}
      <Text fontSize="xs" fontWeight="regular">
        {address.street1}
      </Text>
      {address.street2 && (
        <Text fontSize="xs" fontWeight="regular">
          {address.street2}
        </Text>
      )}
      <Text fontSize="xs" fontWeight="regular">
        {address.city}, {address.state} {address.zip}
      </Text>
      <Text fontSize="xs" fontWeight="regular">
        {address.country}
      </Text>
    </Flex>
  );
});
