import { EndOfDayManifestSchema } from "@redotech/redo-model/fulfillments/scan-form";
import { PaginationCursorSchema } from "@redotech/redo-model/tables/table";
import { z } from "zod";

export const getEndOfDayManifests = {
  input: z.object({
    pageSize: z.number(),
    pageNumber: z.number(),
    paginationCursor: PaginationCursorSchema.nullish(),
  }),
  output: z.object({
    endOfDayManifests: z.array(EndOfDayManifestSchema),
    paginationCursor: PaginationCursorSchema.nullish(),
  }),
};
