import { z } from "zod";
import { salesChannelSchema } from "./sales-channel";

export const getSalesChannelOptions = {
  input: z.object({}),
  output: z
    .object({ salesChannelOptions: z.array(salesChannelSchema) })
    .nullable(),
};

export type SalesChannel = z.infer<typeof salesChannelSchema>;
