import { z } from "zod";

export const productConciergeMetricSchema = z.object({
  current: z.number(),
  previous: z.number(),
});

export type ProductConciergeMetric = z.infer<
  typeof productConciergeMetricSchema
>;

export const productWithConciergeMetricsSchema = z.object({
  id: z.string(),
  title: z.object({ text: z.string(), imageSrc: z.string().optional() }),
  pdpInteractions: productConciergeMetricSchema,
  suggestedProducts: productConciergeMetricSchema,
  backInStockSubscriptions: productConciergeMetricSchema,
  addedToCart: productConciergeMetricSchema,
});

export type ProductWithConciergeMetrics = z.infer<
  typeof productWithConciergeMetricsSchema
>;
