import { Step } from "@redotech/redo-model/return-flow";
import SlashIcon from "@redotech/redo-web/arbiter-icon/slash-octagon_filled.svg";
import { BlockLayout } from "@redotech/redo-web/flowchart";
import { produce } from "immer";
import { memo } from "react";
import { StepId, StepType, StepTypeDetailsProps } from "./step";

export interface State {}

function valid(state: State) {
  return true;
}

const Details = memo(function Details({
  state,
  stepSelect,
  setState,
}: StepTypeDetailsProps<State>) {
  return null;
});

export const DO_NOTHING: StepType<State, Step.DoNothing> = {
  Details,
  customTitle(state) {
    return "Do nothing";
  },
  title: "Do nothing",
  valid,
  Icon: SlashIcon,
  description(state: State) {
    return "Do nothing";
  },
  empty: {},
  downstream(state) {
    return [];
  },
  stepDeleted(state, stepId) {
    return produce(state, (state) => {});
  },
  layout() {
    return BlockLayout.FULL;
  },
  toModel(state, id): Step.DoNothing {
    return { type: Step.DO_NOTHING };
  },
  fromModel(model: Step.DoNothing, id: (id: number) => StepId) {
    return {};
  },
};
