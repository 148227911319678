import type { PillTheme } from "../pill-theme";

export enum ConversationTagSource {
  OUTLOOK = "outlook",
  GMAIL = "gmail",
  REDO_SYSTEM = "redo_system",
  MERCHANT_DEFINED = "merchant_defined",
  IMPORTED_BY_ZENDESK_MACRO = "imported_by_zendesk_macro",
}

export interface ConversationTagRecord {
  _id: string;
  teamId: string;
  name: string;
  pillTheme: PillTheme;
  source: ConversationTagSource;
}

export function isTagEditable(tag: { source: ConversationTagSource }): boolean {
  return tag.source === ConversationTagSource.MERCHANT_DEFINED;
}

export interface LegacyTagRecord {
  name: string;
  pillTheme?: PillTheme;
}
