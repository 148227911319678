import type { PopperPlacementType } from "@mui/base/Popper";
import { ClickAwayListener } from "@mui/material";
import { genericMemo } from "@redotech/react-util/component";
import { useHandler } from "@redotech/react-util/hook";
import { ReactNode, useEffect, useState } from "react";
import { Dropdown } from "../../dropdown";
import { RedoListItem } from "../list/redo-list";
import { RedoListItemSize, RedoListItemVariant } from "../list/redo-list-item";
import { RedoMultiselectList } from "../list/redo-multiselect-list";

export const RedoMultiSelectDropdown = genericMemo(
  function RedoMultiSelectDropdown<T>({
    dropdownButtonRef,
    options,
    setSelectedOptions,
    children,
    selectedOptions,
    keyFn,
    onDropdownToggled,
    size,
    fitToAnchor = false,
    placement,
    selectionVariant,
    loading,
    constrainHeight,
  }: {
    dropdownButtonRef: HTMLButtonElement | null;
    options: RedoListItem<T>[];
    setSelectedOptions(value: RedoListItem<T>[]): void;
    children(item: RedoListItem<T>): ReactNode;
    selectedOptions: RedoListItem<T>[];
    keyFn?: (item: RedoListItem<T>) => string | number;
    size?: RedoListItemSize;
    fitToAnchor?: boolean;
    onDropdownToggled?: (isOpen: boolean) => void;
    placement?: PopperPlacementType;
    selectionVariant?: RedoListItemVariant;
    loading?: boolean;
    constrainHeight?: boolean;
  }) {
    const [dropdownOpen, setDropdownOpen] = useState(false);

    const [focusedIndex, setFocusedIndex] = useState<number | undefined>(
      undefined,
    );

    const toggleDropdownOpen = useHandler(() => {
      setDropdownOpen((prev) => {
        const newOpen = !prev;
        onDropdownToggled?.(newOpen);
        return newOpen;
      });
    });

    /**
     * Tech debt to fix: Lift this state to the parents.
     *
     * This was initially made to reduce some dropdown opening boilerplate,
     * but it means that the events are not handled in react's event system,
     * which can lead to bugs.
     */
    useEffect(() => {
      if (!dropdownButtonRef) {
        return;
      }
      dropdownButtonRef.addEventListener("click", toggleDropdownOpen);
      return () => {
        dropdownButtonRef.removeEventListener("click", toggleDropdownOpen);
      };
    }, [dropdownButtonRef, toggleDropdownOpen]);

    return (
      <>
        {dropdownOpen && (
          <ClickAwayListener onClickAway={toggleDropdownOpen}>
            <Dropdown
              anchor={dropdownButtonRef}
              constrainHeight={constrainHeight}
              fitToAnchor={fitToAnchor}
              flexProps={{ p: "none", gap: "none" }}
              open
              placement={placement}
            >
              <RedoMultiselectList
                focusedIndex={focusedIndex}
                keyFn={keyFn}
                loading={loading}
                options={options}
                refToListenTo={dropdownButtonRef}
                selectedOptions={selectedOptions}
                selectionVariant={selectionVariant}
                setFocusedIndex={setFocusedIndex}
                setSelectedOptions={setSelectedOptions}
                size={size}
              >
                {children}
              </RedoMultiselectList>
            </Dropdown>
          </ClickAwayListener>
        )}
      </>
    );
  },
);
