import { FulfillmentOrderUpdate } from "@redotech/merchant-sdk/outbound-labels-rpc/schema/update-fulfillment-groups";
import { FulfillmentOrderData } from "@redotech/redo-model/fulfillments/fulfillment-group";
import { RedoTextareaInputField } from "@redotech/redo-web/arbiter-components/input/redo-textarea-input-field";
import {
  RedoModal,
  RedoModalSize,
} from "@redotech/redo-web/arbiter-components/modal/redo-modal";
import { RedoTableTextCell } from "@redotech/redo-web/arbiter-components/tables/redo-table-cells";
import { Tooltip } from "@redotech/redo-web/tooltip/tooltip";
import { memo, useState } from "react";
import * as noteCellModule from "./note-cell.module.css";
function formatter(kind: "internalNote" | "noteToBuyer" | "noteFromBuyer") {
  if (kind === "internalNote") {
    return "Internal note";
  }
  if (kind === "noteToBuyer") {
    return "Note to buyer";
  }
  return "Note from buyer";
}

export const NoteCell = memo(function NoteCell({
  row,
  isEditable,
  kind,
  handleUpdates,
}: {
  row: FulfillmentOrderData;
  isEditable: boolean;
  kind: "internalNote" | "noteToBuyer" | "noteFromBuyer";
  handleUpdates: (updates: FulfillmentOrderUpdate[]) => void;
}) {
  const [isBeingEdited, setIsBeingEdited] = useState(false);
  const [noteText, setNoteText] = useState(row[kind] ?? "");

  const title = formatter(kind);

  return isBeingEdited ? (
    <div
      onClick={(e) => {
        e.stopPropagation();
      }}
    >
      <RedoModal
        modalSize={RedoModalSize.SMALL}
        onModalCloseRequested={() => setIsBeingEdited(false)}
        primaryButton={{
          text: "Save",
          onClick: () => {
            handleUpdates([{ fulfillmentGroupId: row._id, [kind]: noteText }]);
            setIsBeingEdited(false);
          },
        }}
        secondaryButton={{
          text: "Cancel",
          onClick: () => setIsBeingEdited(false),
        }}
        title={`Edit ${title.toLowerCase()}`}
      >
        <RedoTextareaInputField
          label={title}
          placeholder={`Enter ${title.toLowerCase()}...`}
          setValue={setNoteText}
          value={noteText}
        />
      </RedoModal>
    </div>
  ) : (
    <Tooltip
      className={noteCellModule.noteFromBuyerColumn}
      placement="bottom-start"
      title={noteText}
    >
      <div
        onClick={(e) => {
          if (isEditable) {
            setIsBeingEdited(true);
          }
          e.stopPropagation();
        }}
      >
        <RedoTableTextCell text={noteText ?? ""} />
      </div>
    </Tooltip>
  );
});
