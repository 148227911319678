import { OMSUser } from "@redotech/redo-model/fulfillments/fulfillment-group";
import { ArrayStringFilterOperator } from "@redotech/redo-model/views/advanced-filters/array-string-filter";
import { AdvancedFilterType } from "@redotech/redo-model/views/advanced-filters/generic-advanced-filter-data";
import {
  AdvancedTableFilter,
  ArrayStringTableFilter,
} from "@redotech/redo-web/advanced-filters/advanced-filter";
import UserIcon from "@redotech/redo-web/arbiter-icon/user-01.svg";

export const OMSUserFilter = (
  fetcher: (
    { filters }: { filters: AdvancedTableFilter[] },
    signal: AbortSignal,
  ) => Promise<string[]>,
  users: OMSUser[],
): ArrayStringTableFilter<OMSUser> => ({
  type: AdvancedFilterType.ARRAY_TO_STRING,
  data: {
    name: "assignees",
    type: AdvancedFilterType.ARRAY_TO_STRING,
    value: [],
    operator: ArrayStringFilterOperator.INCLUDES,
  },
  operators: [
    ArrayStringFilterOperator.INCLUDES,
    ArrayStringFilterOperator.EXCLUDES,
  ],
  itemLabel: "user",
  Icon: UserIcon,
  values: users,
  stringify: (item: OMSUser) => {
    return item._id;
  },
  display: (item: OMSUser) => {
    return item.name;
  },
});
