import {
  type OMSUser,
  OMSUserSchema,
} from "@redotech/redo-model/fulfillments/fulfillment-group";
import type { Team } from "@redotech/redo-model/team";

export const getTeamOMSUsers = (team: Team): OMSUser[] => {
  return (team?.users || []).reduce((acc: OMSUser[], user) => {
    try {
      acc.push(OMSUserSchema.parse(user.user));
      return acc;
    } catch (error) {
      console.warn("Failed to parse user", error);
      return acc;
    }
  }, []);
};
