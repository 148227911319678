import { useLazyContext } from "@redotech/react-util/context";
import { useHandler } from "@redotech/react-util/hook";
import { ConversationPlatform } from "@redotech/redo-model/conversation";
import { Step as ModelStep } from "@redotech/redo-model/return-flow";
import { EmailInfo } from "@redotech/redo-model/team";
import { Permission, permitted } from "@redotech/redo-model/user";
import { CollapseSection } from "@redotech/redo-web/card";
import { BlockLayout } from "@redotech/redo-web/flowchart";
import UserIcon from "@redotech/redo-web/icon-old/user.svg";
import { LabeledInput } from "@redotech/redo-web/labeled-input";
import { SelectDropdown } from "@redotech/redo-web/select-dropdown";
import Quill from "quill";
import { memo, useContext, useEffect, useRef, useState } from "react";
import { UserContext } from "../../app/user";
import { EmailIntegrationsContext } from "../../services/support/email-integrations";
import { StepType, StepTypeDetailsProps } from "../return-flow/step";
import { containsImg, StepRichTextEditor } from "./step-rich-text";

export interface State {
  message: string;
  messageHtml?: string;
  emailPromptMessage: string;
  emailPromptMessageHtml?: string;
  followUpMessage: string;
  followUpMessageHtml?: string;
  channel: ConversationPlatform;
  customTitle?: string;
  emailToSendAs?: EmailInfo;
}

const options: ConversationPlatform[] = [
  ConversationPlatform.EMAIL,
  ConversationPlatform.REDO_CHAT,
];

const Details = memo(function Details({
  state,
  setState,
}: StepTypeDetailsProps<State>) {
  const user = useContext(UserContext);
  const canEditSettings =
    !!user && permitted(user.permissions, Permission.EDIT_SETTINGS);
  const handleChannelChange = useHandler((channel: ConversationPlatform) => {
    setState((state) => ({ ...state, channel }));
  });
  const handleEmailToSendAsChange = useHandler((emailToSendAs?: EmailInfo) => {
    setState((state) => ({ ...state, emailToSendAs }));
  });
  const [availableEmails, setAvailableEmails] = useState<EmailInfo[]>([]);

  useEffect(() => {
    if (state.channel === ConversationPlatform.EMAIL) {
      if (
        availableEmails.length > 0 &&
        (!state.emailToSendAs || state.emailToSendAs.email === "")
      ) {
        handleEmailToSendAsChange(availableEmails[0]);
      }
    } else if (state.channel === ConversationPlatform.REDO_CHAT) {
      handleEmailToSendAsChange(undefined);
    }
  }, [availableEmails, state.emailToSendAs, state.channel, setState]);

  const messageQuillRef = useRef<Quill | undefined>(undefined);
  const emailPromptQuillRef = useRef<Quill | undefined>(undefined);
  const followUpQuillRef = useRef<Quill | undefined>(undefined);

  const [emailIntegrationsLoad] = useLazyContext(EmailIntegrationsContext);

  useEffect(() => {
    if (!emailIntegrationsLoad.value) {
      return;
    }
    appendEmails(
      emailIntegrationsLoad.value.customIntegrations.map((integration) => {
        return {
          email: integration.email,
          name: integration.name,
          integrationKind: "custom",
        };
      }),
    );
    appendEmails(
      emailIntegrationsLoad.value.gmailIntegrations.map((integration) => {
        return {
          email: integration.email,
          name: integration.name,
          integrationKind: "gmail",
        };
      }),
    );
    appendEmails(
      emailIntegrationsLoad.value.outlookIntegrations.map((integration) => {
        return {
          email: integration.email,
          name: integration.name,
          integrationKind: "outlook",
        };
      }),
    );
  }, [emailIntegrationsLoad.value]);

  const appendEmails = (emails: EmailInfo[]) => {
    setAvailableEmails((oldAvailableEmails) => {
      const newEmails = [...oldAvailableEmails];
      emails.forEach((email: EmailInfo) => {
        if (!newEmails.some((existing) => existing.email === email.email)) {
          newEmails.push(email);
        }
      });
      return [...newEmails];
    });
  };

  const handleMessageChange = useHandler(() => {
    if (!messageQuillRef.current) {
      return;
    }
    let message = messageQuillRef.current.getText();
    let messageHtml: string | undefined =
      messageQuillRef.current.getSemanticHTML();
    if (message === "\n") {
      message = "";
    }
    if (messageHtml === "<p></p>") {
      messageHtml = undefined;
    }

    if (messageHtml !== state.messageHtml || message !== state.message) {
      setState((state) => ({ ...state, message, messageHtml }));
    }
  });

  const handleEmailPromptChange = useHandler(() => {
    if (!emailPromptQuillRef.current) {
      return;
    }
    let emailPromptMessage = emailPromptQuillRef.current.getText();
    let emailPromptMessageHtml: string | undefined =
      emailPromptQuillRef.current.getSemanticHTML();
    if (emailPromptMessage === "\n") {
      emailPromptMessage = "";
    }
    if (emailPromptMessageHtml === "<p></p>") {
      emailPromptMessageHtml = undefined;
    }

    if (
      emailPromptMessageHtml !== state.emailPromptMessageHtml ||
      emailPromptMessage !== state.emailPromptMessage
    ) {
      setState((state) => ({
        ...state,
        emailPromptMessage,
        emailPromptMessageHtml,
      }));
    }
  });

  const handleFollowUpChange = useHandler(() => {
    if (!followUpQuillRef.current) {
      return;
    }
    let followUpMessage = followUpQuillRef.current.getText();
    let followUpMessageHtml: string | undefined =
      followUpQuillRef.current.getSemanticHTML();
    if (followUpMessage === "\n") {
      followUpMessage = "";
    }
    if (followUpMessageHtml === "<p></p>") {
      followUpMessageHtml = undefined;
    }

    if (
      followUpMessageHtml !== state.followUpMessageHtml ||
      followUpMessage !== state.followUpMessage
    ) {
      setState((state) => ({ ...state, followUpMessage, followUpMessageHtml }));
    }
  });

  return (
    <>
      {(availableEmails.length || 0) > 0 ? (
        <LabeledInput label="Channel">
          <SelectDropdown
            disabled={!canEditSettings}
            options={options}
            value={state.channel}
            valueChange={handleChannelChange}
          >
            {(option) => option.charAt(0).toUpperCase() + option.slice(1)}
          </SelectDropdown>
        </LabeledInput>
      ) : null}
      {state.channel === ConversationPlatform.EMAIL ? (
        <>
          <LabeledInput label="Email to send as">
            <SelectDropdown
              disabled={!canEditSettings}
              options={availableEmails}
              value={state.emailToSendAs}
              valueChange={handleEmailToSendAsChange}
            >
              {(email) => email.email}
            </SelectDropdown>
          </LabeledInput>
          <CollapseSection
            defaultCollapsed={false}
            title="If customer email is known"
          >
            <LabeledInput
              description="Message to show to the customer letting them know they will be contacted by email"
              errors={
                containsImg(state.messageHtml) ? ["Cannot contain images"] : []
              }
              label="Message"
            >
              <StepRichTextEditor
                htmlValue={state.messageHtml}
                onTextChange={handleMessageChange}
                quillRef={messageQuillRef}
                readOnly={!canEditSettings}
                textValue={state.message}
              />
            </LabeledInput>
          </CollapseSection>
        </>
      ) : (
        <LabeledInput
          description="Message to show to the customer while they wait for customer support agent"
          errors={
            containsImg(state.messageHtml) ? ["Cannot contain images"] : []
          }
          label="Message"
        >
          <StepRichTextEditor
            htmlValue={state.messageHtml}
            onTextChange={handleMessageChange}
            quillRef={messageQuillRef}
            readOnly={!canEditSettings}
            textValue={state.message}
          />
        </LabeledInput>
      )}
      {state.channel === ConversationPlatform.EMAIL ? (
        <CollapseSection
          defaultCollapsed={false}
          title="If customer email is unknown"
        >
          <LabeledInput
            description="If the customer's email hasn't yet been collected in the Chat Flow, this message will be sent first to ask them for one"
            errors={
              containsImg(state.emailPromptMessageHtml)
                ? ["Cannot contain images"]
                : []
            }
            label="Email collection message"
          >
            <StepRichTextEditor
              htmlValue={state.emailPromptMessageHtml}
              onTextChange={handleEmailPromptChange}
              quillRef={emailPromptQuillRef}
              readOnly={!canEditSettings}
              textValue={state.emailPromptMessage}
            />
          </LabeledInput>
          <LabeledInput
            description="Message to show to the customer, after they've entered their email, letting them know they will be contacted by email"
            errors={
              containsImg(state.followUpMessageHtml)
                ? ["Cannot contain images"]
                : []
            }
            label="Follow-up message"
          >
            <StepRichTextEditor
              htmlValue={state.followUpMessageHtml}
              onTextChange={handleFollowUpChange}
              quillRef={followUpQuillRef}
              readOnly={!canEditSettings}
              textValue={state.followUpMessage}
            />
          </LabeledInput>
        </CollapseSection>
      ) : null}
    </>
  );
});

export const ASSIGN_TO_AGENT: StepType<State, ModelStep.AssignToAgent> = {
  Details,
  customTitle(state) {
    return state.customTitle;
  },
  description(state: State) {
    return state.message;
  },
  downstream() {
    return [];
  },
  empty: {
    message:
      "Please wait while I connect you with a customer support representative.",
    messageHtml: undefined,
    emailPromptMessage:
      "We will contact you by email as soon as possible! What is the best email address at which we can contact you?",
    emailPromptMessageHtml: undefined,
    followUpMessage:
      "Thank you! We will contact you at the email address you provided shortly.",
    followUpMessageHtml: undefined,
    channel: ConversationPlatform.REDO_CHAT,
    emailToSendAs: { email: "", name: "", integrationKind: "" },
  },
  layout() {
    return BlockLayout.FULL;
  },
  stepDeleted(state) {
    return state;
  },
  title: "Assign to support agent",
  valid(state) {
    return (
      !!state.message &&
      !containsImg(state.messageHtml) &&
      !containsImg(state.emailPromptMessageHtml) &&
      !containsImg(state.followUpMessageHtml) &&
      (state.channel === ConversationPlatform.REDO_CHAT ||
        (state.channel === ConversationPlatform.EMAIL &&
          !!state.emailToSendAs?.email))
    );
  },
  fromModel(model: ModelStep.AssignToAgent) {
    return {
      customTitle: model.customTitle,
      message: model.message,
      messageHtml: model.messageHtml,
      emailPromptMessage: model.emailPromptMessage,
      emailPromptMessageHtml: model.emailPromptMessageHtml,
      followUpMessage: model.followUpMessage,
      followUpMessageHtml: model.followUpMessageHtml,
      channel: model.channel,
      emailToSendAs: model.emailToSendAs,
    };
  },
  toModel(state: State) {
    return {
      customTitle: state.customTitle,
      type: ModelStep.ASSIGN_TO_AGENT,
      message: state.message,
      messageHtml: state.messageHtml,
      emailPromptMessage: state.emailPromptMessage,
      emailPromptMessageHtml: state.emailPromptMessageHtml,
      followUpMessage: state.followUpMessage,
      followUpMessageHtml: state.followUpMessageHtml,
      channel: state.channel,
      emailToSendAs: state.emailToSendAs,
    };
  },
  Icon: UserIcon,
};
