// extracted by mini-css-extract-plugin
export var bubble = "_3e-0";
export var container = "_3e-3";
export var contrastBorder = "_3e-4";
export var greyOut = "_3e-2";
export var initialsWrapper = "_3e-1";
export var large = "_3e-9";
export var medium = "_3e-8";
export var mediumSmall = "_3e-7";
export var micro = "_3e-5";
export var small = "_3e-6";
export var tiny = "_3e-b";
export var xTiny = "_3e-a";