import { IconButton } from "@mui/material";
import { useHandler } from "@redotech/react-util/hook";
import { FlowType, Step } from "@redotech/redo-model/return-flow";
import { TriggerType } from "@redotech/redo-model/return-flow/trigger";
import { Permission, permitted } from "@redotech/redo-model/user";
import { BlockLayout } from "@redotech/redo-web/flowchart";
import QuestionIcon from "@redotech/redo-web/icon-old/question.svg";
import { LabeledInput } from "@redotech/redo-web/labeled-input";
import { SelectDropdown } from "@redotech/redo-web/select-dropdown";
import { Tooltip } from "@redotech/redo-web/tooltip/tooltip";
import { produce } from "immer";
import { memo, useContext } from "react";
import { UserContext } from "../../app/user";
import { StepDownstream, StepId, StepType, StepTypeDetailsProps } from "./step";

const getTriggers = (flowType: FlowType): TriggerType[] => {
  if (flowType === FlowType.RULE) {
    return [
      TriggerType.CONVERSATION_MESSAGE_CREATED,
      TriggerType.CONVERSATION_CREATED,
      TriggerType.CHAT_CONVERTED_TO_EMAIL,
    ];
  } else {
    return [];
  }
};

export interface State {
  triggerType: TriggerType;
  next: StepId | undefined;
  customTitle?: string;
}

function valid(state: State) {
  return !!state.next && !!state.triggerType;
}

const getInfo = (type: TriggerType) => {
  if (type === TriggerType.CONVERSATION_CREATED) {
    return "This only fires once for a ticket, when it is created.";
  } else if (type === TriggerType.CONVERSATION_MESSAGE_CREATED) {
    return "This fires when a ticket is created, and every time the customer sends a new message";
  }
  return null;
};

export function triggerTypeLabelWithTooltip(type: TriggerType) {
  const labelText = triggerTypeLabel(type);
  const info = getInfo(type);
  return info ? (
    <div>
      <Tooltip title={info}>
        <IconButton>
          <QuestionIcon />
        </IconButton>
      </Tooltip>
      {labelText}
    </div>
  ) : (
    labelText
  );
}

export function triggerTypeLabel(type: TriggerType | string) {
  return type.charAt(0).toUpperCase() + type.slice(1).replaceAll("_", " ");
}

const Details = memo(function Details({
  stepSelect,
  state,
  setState,
  flowType,
}: StepTypeDetailsProps<State>) {
  const user = useContext(UserContext);
  const canEditSettings =
    !!user && permitted(user.permissions, Permission.EDIT_SETTINGS);
  const nextStepChange = useHandler((next: StepId | undefined) => {
    setState((state) => ({ ...state, next }));
  });
  const handleType = useHandler(async (triggerType: TriggerType) => {
    setState((state) => ({ ...state, triggerType }));
  });

  const triggers = getTriggers(flowType);
  return (
    <>
      <LabeledInput label="Type">
        <SelectDropdown
          disabled={!canEditSettings}
          options={triggers}
          value={state.triggerType}
          valueChange={handleType}
        >
          {(option) => triggerTypeLabelWithTooltip(option)}
        </SelectDropdown>
      </LabeledInput>
      <LabeledInput errors={!state.next ? ["Required"] : []} label="Next step">
        {stepSelect({ value: state.next, valueChange: nextStepChange })}
      </LabeledInput>
    </>
  );
});

export const TRIGGER: StepType<State, Step.Trigger> = {
  Details,
  customTitle(state) {
    return triggerTypeLabel(state.triggerType);
  },
  displayTitle(state) {
    return triggerTypeLabel(state.triggerType);
  },
  valid,
  title: "Trigger",
  Icon: () => null,
  description() {
    return "";
  },
  start(state) {
    return state.next;
  },
  empty: { triggerType: TriggerType.ORDER_CREATED, next: undefined },
  downstream(state) {
    const result: StepDownstream[] = [];
    if (state.next !== undefined) {
      result.push({ id: state.next });
    }
    return result;
  },
  stepDeleted(state, stepId) {
    return produce(state, (state) => {
      if (state.next === stepId) {
        state.next = undefined;
      }
    });
  },
  layout() {
    return BlockLayout.COMPACT;
  },
  toModel(state, id): Step.Trigger {
    return {
      customTitle: state.customTitle,
      type: Step.TRIGGER,
      triggerType: state.triggerType,
      next: id(state.next!),
    };
  },
  fromModel(model: Step.Trigger, id) {
    return {
      customTitle: model.customTitle,
      triggerType: model.triggerType,
      next: id(model.next),
    };
  },
};
