import { genericMemo } from "@redotech/react-util/component";
import { ArrayPredicateExpression } from "@redotech/redo-model/advanced-flow/expressions/array-expression";
import { BooleanEvaluationExpression } from "@redotech/redo-model/advanced-flow/expressions/boolean-expression";
import { SchemaBooleanExpression } from "@redotech/redo-model/advanced-flow/expressions/index";
import { TextMatchExpression } from "@redotech/redo-model/advanced-flow/expressions/text-expression";
import { GenericDataType } from "@redotech/redo-model/advanced-flow/type-system/data-types/generic-types";
import { Schema } from "@redotech/redo-model/advanced-flow/type-system/schema";
import { DeepPartial } from "@redotech/util/type";
import { ArrayInspector } from "./array-inspector";
import { BooleanInspector } from "./boolean-inspector";
import { EnumInspector } from "./enum-inspector";
import { TextInspector } from "./text-inspector";

export function camelToTitleCase(str: string) {
  return str
    .replace(/([a-z])([A-Z])/g, "$1 $2") // camelCase to camel Case
    .replace(/^./, (s) => s.toUpperCase())
    .replace("-", " ")
    .trim();
}

// more generic version of camelToTitleCase
export function titleCase(str: string) {
  return str
    .split(/[-_]/)
    .map((s) => camelToTitleCase(s))
    .join(" ");
}

export function removeGenericFromType(type: GenericDataType) {
  return type
    .replaceAll("Multiple ", "")
    .replaceAll("Maybe ", "")
    .trim() as GenericDataType;
}

export const FieldInspector = genericMemo(function FieldInspector({
  schema,
  dataType,
  expression,
  setExpression,
}: {
  schema: Schema;
  dataType: GenericDataType;
  expression: DeepPartial<SchemaBooleanExpression>;
  setExpression: (expression: DeepPartial<SchemaBooleanExpression>) => void;
}) {
  if (dataType.startsWith("Multiple")) {
    return (
      <ArrayInspector
        dataType={dataType}
        expression={expression as DeepPartial<ArrayPredicateExpression>}
        schema={schema}
        setExpression={setExpression}
      />
    );
  }
  if (dataType === "Text" || dataType === "Url") {
    return (
      <TextInspector
        dataType={dataType}
        expression={expression as DeepPartial<TextMatchExpression>}
        schema={schema}
        setExpression={setExpression}
      />
    );
  }
  if (dataType === "Boolean") {
    return (
      <BooleanInspector
        dataType={dataType}
        expression={expression as DeepPartial<BooleanEvaluationExpression>}
        schema={schema}
        setExpression={setExpression}
      />
    );
  }
  if (dataType === "Enum") {
    return (
      <EnumInspector
        dataType={dataType}
        expression={expression as DeepPartial<TextMatchExpression>}
        schema={schema}
        setExpression={setExpression}
      />
    );
  }
  return <>Unsupported step type {(console.error(dataType), dataType)}</>;
});
