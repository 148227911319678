import { sortFulfillmentOrderLineItems } from "@redotech/redo-model/fulfillments/fulfillment-group";
import { FulfillmentGroupStatus } from "@redotech/redo-model/fulfillments/fulfillment-group-status";
import { FulfillmentOrderLineItem } from "@redotech/redo-model/fulfillments/fulfillment-order-line-item";
import { ItemOrderBy } from "@redotech/redo-model/outbound-labels";
import { Team } from "@redotech/redo-model/team";
import { Flex } from "@redotech/redo-web/flex";
import { Text } from "@redotech/redo-web/text";
import { Tooltip } from "@redotech/redo-web/tooltip/tooltip";
import * as classNames from "classnames";
import { memo } from "react";
import * as styles from "./items-table.module.css";

export const ItemsTable = memo(function ItemsTable({
  items,
  selectedStatus,
  team,
  disableTooltip = false,
}: {
  items: FulfillmentOrderLineItem[];
  selectedStatus: FulfillmentGroupStatus;
  team: Team | undefined;
  disableTooltip?: boolean;
}) {
  return (
    <Flex
      borderColor="primary"
      borderStyle="solid"
      borderWidth="1px"
      dir="column"
      gap="none"
      radius="md"
    >
      <Flex dir="column" flex="1" gap="none">
        {sortFulfillmentOrderLineItems(
          team?.settings?.outboundLabels?.documents?.pickList?.orderBy ??
            ItemOrderBy.QuantityDesc,
          items,
          selectedStatus,
        ).map((item, index) => (
          <Flex
            borderBottomWidth="1px"
            borderColor="primary"
            borderStyle={index !== items.length - 1 ? "solid" : "none"}
            gap="none"
            key={item.externalId}
            pb="sm"
            pt="sm"
          >
            <Flex
              className={styles.titleColumn}
              flexDirection="row"
              gap="none"
              maxW="xxs"
              pl="sm"
              pr="sm"
            >
              <Flex pl="sm" pr="sm">
                <img
                  className={classNames(styles.productImage)}
                  src={item?.imageUrl ?? undefined}
                />
              </Flex>
              <Tooltip
                title={disableTooltip ? "" : item.productTitle || item.title}
              >
                <Flex
                  flexDirection="column"
                  gap="none"
                  justify="center"
                  pl="sm"
                  pr="sm"
                >
                  <Text
                    className={styles.ellipsesOverflow}
                    fontSize="xs"
                    fontWeight="medium"
                  >
                    {item.productTitle || item.title}
                  </Text>
                  {item?.variantTitle !== "Default Title" && (
                    <Text
                      className={styles.ellipsesOverflow}
                      fontSize="xxs"
                      textColor="tertiary"
                    >
                      {item.variantTitle.replace(" / ", ", ")}
                    </Text>
                  )}
                </Flex>
              </Tooltip>
            </Flex>
            <Flex
              flexDirection="column"
              gap="none"
              justify="center"
              pl="sm"
              pr="sm"
            >
              <Flex justify="flex-end">
                <Text
                  fontSize="xs"
                  textColor="primary"
                >{`$${item.unitDiscountedPrice.amount}`}</Text>
              </Flex>
              <Flex justify="flex-end">
                <Text fontSize="xxs" textColor="tertiary">
                  {`Quantity: ${item.totalQuantity}`}
                </Text>
              </Flex>
            </Flex>
          </Flex>
        ))}
      </Flex>
    </Flex>
  );
});
