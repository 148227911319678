import { UploadedFile } from "./file/uploaded-file";

export type CreateMacro = Omit<Macro, "_id">;

export interface Macro {
  _id: string;
  team: any;
  name: string;
  content: string;
  htmlContent: string;
  tagsToAdd?: string[];
  statusToSet?: MacroStatusToSet;
  emailSubjectToSet?: string | undefined;
  snoozeDuration?: SnoozeDuration;
  attachments?: UploadedFile[];

  /** Internal note automation */
  shouldAddNote?: boolean;
  noteToAddContent?: string;
  noteToAddHtmlContent?: string;
  noteToAddUsersMentioned?: string[] /** User IDs */;

  /** Forwarding message automation */
  shouldForwardMessage?: boolean;
  messageToForwardExtraContent?: string;
  messageToForwardEmailAddress?: string /** One well-formed email address */;
}

/** The subset of Macro properties that are for one of the macro automations. */
export type MacroAutomationsData = Omit<
  Macro,
  "team" | "name" | "content" | "_id" | "htmlContent"
>;

/**
 * The names of the macro automations. Any place that handles macro automations
 * should use Record<MacroAutomation, ...> to enforce consideration of all macro automations.
 * */
export enum MacroAutomation {
  SET_STATUS = "setStatus",
  ADD_TAGS = "addTags",
  CHANGE_EMAIL_SUBJECT = "changeEmailSubject",
  ADD_INTERNAL_NOTE = "addInternalNote",
  FORWARD_MESSAGE = "forwardMessage",
}

export function isAtLeastOneMacroAutomationActive(
  automations: MacroAutomationsData,
): boolean {
  const checkIsThisMacroAutomationActive: Record<MacroAutomation, boolean> = {
    [MacroAutomation.SET_STATUS]: !!automations.statusToSet,
    [MacroAutomation.ADD_TAGS]: !!automations.tagsToAdd?.length,
    [MacroAutomation.CHANGE_EMAIL_SUBJECT]: !!automations.emailSubjectToSet,
    [MacroAutomation.ADD_INTERNAL_NOTE]: !!automations.shouldAddNote,
    [MacroAutomation.FORWARD_MESSAGE]: !!automations.shouldForwardMessage,
  };
  return Object.values(checkIsThisMacroAutomationActive).some(Boolean);
}

/**
 * Macro status to set is NOT the same as
 * the conversation status field.
 *
 * The conversation is either open or closed.
 * Snoozed logic is handled by the snoozedUntil field.
 */
export enum MacroStatusToSet {
  OPEN = "open",
  SNOOZED = "snoozed",
  CLOSED = "closed",
}

export enum SnoozeDuration {
  SAME_DAY = "same_day",
  ONE_DAY = "one_day",
  TWO_DAYS = "two_days",
  SATURDAY = "saturday",
  MONDAY = "monday",
}

export interface MacroVariable {
  id: string;
  variable: string;
  displayName: string;
}

export const MACRO_VARIABLES: MacroVariable[] = [
  { id: "currentDate", variable: "current_date", displayName: "Current Date" },
  {
    id: "customerFirstName",
    variable: "customer_first_name",
    displayName: "Customer First Name",
  },
  {
    id: "customerFullName",
    variable: "customer_full_name",
    displayName: "Customer Full Name",
  },
  {
    id: "customerLastName",
    variable: "customer_last_name",
    displayName: "Customer Last Name",
  },
  {
    id: "customerEmail",
    variable: "customer_email",
    displayName: "Customer Email",
  },
  {
    id: "agentFirstName",
    variable: "agent_first_name",
    displayName: "Agent First Name",
  },
  {
    id: "agentFullName",
    variable: "agent_full_name",
    displayName: "Agent Full Name",
  },
  {
    id: "agentLastName",
    variable: "agent_last_name",
    displayName: "Agent Last Name",
  },
  {
    id: "recentClaimCreatedDate",
    variable: "recent_claim_created_date",
    displayName: "Recent Claim Created Date",
  },
  {
    id: "recentClaimProcessedDate",
    variable: "recent_claim_processed_date",
    displayName: "Recent Claim Processed Date",
  },
  {
    id: "recentClaimStatus",
    variable: "recent_claim_status",
    displayName: "Recent Claim Status",
  },
  {
    id: "recentOrderDate",
    variable: "recent_order_date",
    displayName: "Recent Order Date",
  },
  {
    id: "recentOrderDeliveryStatus",
    variable: "recent_order_delivery_status",
    displayName: "Recent Order Delivery Status",
  },
  {
    id: "recentOrderFulfillmentStatus",
    variable: "recent_order_fulfillment_status",
    displayName: "Recent Order Fulfillment Status",
  },
  {
    id: "recentReturnStatus",
    variable: "recent_return_status",
    displayName: "Recent Return Status",
  },
  {
    id: "recentOrderPrice",
    variable: "recent_order_price",
    displayName: "Recent Order Price",
  },
  {
    id: "recentOrderReturnedDate",
    variable: "recent_order_returned_date",
    displayName: "Recent Order Returned Date",
  },
  {
    id: "recentOrderShippingAddress",
    variable: "recent_order_shipping_address",
    displayName: "Recent Order Shipping Address",
  },
  {
    id: "recentOrderShippingDate",
    variable: "recent_order_shipping_date",
    displayName: "Recent Order Shipping Date",
  },
  {
    id: "recentOrderTrackingNumber",
    variable: "recent_order_tracking_number",
    displayName: "Recent Order Tracking Number",
  },
  {
    id: "recentOrderTrackingURL",
    variable: "recent_order_tracking_url_link",
    displayName: "Recent Order Tracking URL Link",
  },
  // Order name is the prefixed order number. We originally offered recent order number,
  // so for backwards compatibility, the variable name is the same
  {
    id: "recentOrderName",
    variable: "recent_order_number",
    displayName: "Recent Order Number",
  },
  {
    id: "recentReturnCreatedDate",
    variable: "recent_return_created_date",
    displayName: "Recent Return Created Date",
  },
  {
    id: "recentReturnProcessedDate",
    variable: "recent_return_processed_date",
    displayName: "Recent Return Processed Date",
  },
  { id: "storeName", variable: "store_name", displayName: "Store Name" },
];

export const macroVariableNames = () => {
  return MACRO_VARIABLES.map((macroVariable) => macroVariable.variable);
};

export const locateVariables = (text: string) => {
  const variableNames = [];
  for (const name of macroVariableNames()) {
    if (text.includes(name)) {
      variableNames.push(name);
    }
  }
  return variableNames;
};

export const getVariableInfo = (variable: string) => {
  const matchingVariables = MACRO_VARIABLES.filter(
    (macroVariable) => macroVariable.variable === variable,
  );
  return matchingVariables.length ? matchingVariables[0] : null;
};

/**
 * External macro variables that need to be converted to redo variables
 * the value for redoVariable is what will replace the external variable in translation
 * https://support.reamaze.com/kb/internal-team-productivity/dynamic-variables-in-automated-responses
 */
export const REAMAZE_MACRO_VARIABLE_TO_REDO_VARIABLE = [
  {
    reamazeVariable: "{{ customer.friendly_name }}",
    redoVariable: "customer_first_name",
  },
  {
    // Remaze does not support this
    redoVariable: "customer_last_name",
  },
  {
    reamazeVariable: "{{ customer.name }}",
    redoVariable: "customer_full_name",
  },
  { reamazeVariable: "{{ customer.email }}", redoVariable: "customer_email" },
  {
    reamazeVariable: "{{ staff.friendly_name }}",
    redoVariable: "agent_first_name",
  },
  {
    // Remaze does not support this
    redoVariable: "agent_last_name",
  },
  { reamazeVariable: "{{ staff.name }}", redoVariable: "agent_full_name" },
  {
    reamazeVariable: "{{ integrations.shopify.orders[0].order_number }}",
    redoVariable: "recent_order_number",
  },
  {
    reamazeVariable:
      "{{ integrations.shopify.orders[0].fulfillments[0].created_at }}",
    redoVariable: "recent_order_date",
  },
  {
    reamazeVariable:
      "{{ integrations.shopify.orders[0].fulfillments[0].tracking_url }}",
    redoVariable: "recent_order_tracking_url_link",
  },
  {
    reamazeVariable:
      "{{ integrations.shopify.orders[0].fulfillments[0].tracking_number }}",
    redoVariable: "recent_order_tracking_number",
  },
  {
    // Remaze does not support this
    redoVariable: "recent_order_delivery_status",
  },
  {
    // Remaze does not support this
    redoVariable: "recent_order_shipping_date",
  },
  {
    // Remaze does not support this
    redoVariable: "recent_order_shipping_address",
  },
  {
    // Remaze does not support this
    redoVariable: "recent_order_shipping_address",
  },
];

/**
 * External macro variables that need to be converted to redo variables
 * the value for redoVariable is what will replace the external variable in translation
 * https://support.zendesk.com/hc/en-us/articles/4408886858138-Zendesk-Support-placeholders-reference
 */
export const ZENDESK_MACRO_VARIABLE_TO_REDO_VARIABLE = [
  {
    zendeskVariable: "{{ticket.requester.first_name}}",
    redoVariable: "customer_first_name",
  },
  {
    zendeskVariable: "{{ticket.requester.lastname}}",
    redoVariable: "customer_last_name",
  },
  {
    zendeskVariable: "{{ticket.requester.name}}",
    redoVariable: "customer_full_name",
  },
  {
    zendeskVariable: "{{ticket.requester.email}}",
    redoVariable: "customer_email",
  },
  {
    zendeskVariable: "{{current_user.firstname}}",
    redoVariable: "agent_first_name",
  },
  {
    zendeskVariable: "{{current_user.lastname}}",
    redoVariable: "agent_last_name",
  },
  { zendeskVariable: "{{current_user.name}}", redoVariable: "agent_full_name" },
  {
    zendeskVariable: "{{shopify.last_order_number}}",
    redoVariable: "recent_order_number",
  },
  {
    zendeskVariable: "[[shopify?.order?.name]]",
    redoVariable: "recent_order_number",
  },
  // TODO we need to get this macro still
  { redoVariable: "recent_order_date" },
  {
    zendeskVariable: "[[shopify?.order?.fulfillments[0]?.tracking_url]]",
    redoVariable: "recent_order_tracking_url_link",
  },
  // TODO we need to get this macro still
  { redoVariable: "recent_order_tracking_number" },
  // TODO we need to get this macro still
  { redoVariable: "recent_order_delivery_status" },
  // TODO we need to get this macro still
  { redoVariable: "recent_order_shipping_date" },
  {
    zendeskVariable: "[[shopify?.order?.shipping_address?.address1]]",
    redoVariable: "recent_order_shipping_address",
  },
  // We will just remove these address ones as we will replace address1 with full address
  {
    zendeskVariable: "[[shopify?.order?.shipping_address?.address2]]",
    redoVariable: "",
  },
  {
    zendeskVariable: "[[shopify?.order?.shipping_address?.city]]",
    redoVariable: "",
  },
  {
    zendeskVariable: "[[shopify?.order?.shipping_address?.province]]",
    redoVariable: "",
  },
  {
    zendeskVariable: "[[shopify?.order?.shipping_address?.zip]]",
    redoVariable: "",
  },
];

// External gorgias macro variables that need to be converted to redo variables
// the value for redoVariable is what will replace the external variable in translation
export const GORGIAS_MACRO_VARIABLE_TO_REDO_VARIABLE = [
  {
    gorgiasVariable: "{{ticket.customer.firstname}}",
    redoVariable: "customer_first_name",
  },
  {
    gorgiasVariable: "{{ticket.customer.lastname}}",
    redoVariable: "customer_last_name",
  },
  {
    gorgiasVariable: "{{ticket.customer.name}}",
    redoVariable: "customer_full_name",
  },
  {
    gorgiasVariable: "{{ticket.customer.email}}",
    redoVariable: "customer_email",
  },
  {
    gorgiasVariable: "{{current_user.firstname}}",
    redoVariable: "agent_first_name",
  },
  {
    gorgiasVariable: "{{current_user.lastname}}",
    redoVariable: "agent_last_name",
  },
  { gorgiasVariable: "{{current_user.name}}", redoVariable: "agent_full_name" },
  {
    gorgiasVariable: "{{ticket.customer.integrations.shopify.orders[0].name}}",
    redoVariable: "recent_order_number",
  },
  {
    gorgiasVariable:
      "{{ticket.customer.integrations.shopify.orders[0].created_at|datetime_format(&quot;MMMM d, YYYY&quot;)}}",
    redoVariable: "recent_order_date",
  },
  {
    gorgiasVariable:
      "{{ticket.customer.integrations.shopify.orders[0].fulfillments[0].tracking_url}}",
    redoVariable: "recent_order_tracking_url_link",
  },
  {
    gorgiasVariable:
      "{{ticket.customer.integrations.shopify.orders[0].fulfillments[0].tracking_number}}",
    redoVariable: "recent_order_tracking_number",
  },
  {
    gorgiasVariable:
      "{{ticket.customer.integrations.shopify.orders[0].fulfillments[0].shipment_status}}",
    redoVariable: "recent_order_delivery_status",
  },
  // Don't have a redo variable for these yet, so will leave commented here if we ever add it, we will leave as is for these and the user will have to add manually
  // {
  //   gorgiasVariable: "{{ticket.customer.integrations.shopify.orders[0].order_status_url}}",
  //   redoVariable: "none",
  // }
  // {
  //   gorgiasVariable: "{{ticket.customer.integrations.shopify.orders[0].shipping_address.country}}",
  //   redoVariable: "none",
  // }
  {
    gorgiasVariable:
      "{{ticket.customer.integrations.shopify.orders[0].fulfillments[0].created_at|datetime_format(&quot;MMMM d, YYYY&quot;)}}",
    redoVariable: "recent_order_shipping_date",
  },
  {
    gorgiasVariable:
      "{{ticket.customer.integrations.shopify.orders[0].shipping_address.address1}}",
    redoVariable: "recent_order_shipping_address",
  },
  // We will just remove these address ones as we will replace address1 with full address
  {
    gorgiasVariable:
      " {{ticket.customer.integrations.shopify.orders[0].shipping_address.address2}}, {{ticket.customer.integrations.shopify.orders[0].shipping_address.zip}} {{ticket.customer.integrations.shopify.orders[0].shipping_address.city}} {{ticket.customer.integrations.shopify.orders[0].shipping_address.province}}",
    redoVariable: "",
  },
  {
    gorgiasVariable:
      " {{ticket.customer.integrations.shopify.orders[0].shipping_address.address2}}, {{ticket.customer.integrations.shopify.orders[0].shipping_address.zip}} {{ticket.customer.integrations.shopify.orders[0].shipping_address.city}} {{ticket.customer.integrations.shopify.orders[0].shipping_address.province}}",
    redoVariable: "",
  },
];

export function getMacroVariableFormat(variable: string) {
  return `<span style="background-color: rgb(235, 233, 253); color: rgb(95, 69, 226);">${variable}</span>`;
}
