import { getDateString } from "@redotech/util/date";
import { assertNever } from "@redotech/util/type";
import { z } from "zod";
import { DynamicCustomerGroup } from "../../customers/customer-group-definition";
import {
  TimeframeCondition,
  TimeframeConditionType,
} from "./segment-timeframe";
import { timeframeSchema } from "./segment-timeframe-zod-schema";
import {
  ActivityCount,
  ActivityCountType,
  ConjunctionMode,
  CustomerActivityCondition,
  CustomerActivityType,
  CustomerCharacteristicType,
  CustomerAttributeCondition as OriginalCustomerAttributeCondition,
  Segment,
  SegmentCondition,
  SegmentConditionBlock,
  SegmentConditionType,
} from "./segment-types";
import {
  NumericCompareOperator,
  BooleanCondition as OriginalBooleanCondition,
  DateCondition as OriginalDateCondition,
  NumericCondition as OriginalNumericCondition,
  TokenCondition as OriginalTokenCondition,
  TokenListCondition as OriginalTokenListCondition,
  WhereCondition as OriginalWhereCondition,
  WhereConditionDataType,
} from "./segment-where-condition";
import {
  BooleanCondition,
  DateCondition,
  NumericCondition,
  TokenCondition,
  TokenListCondition,
  whereConditionSchema,
} from "./segment-where-condition-zod-schema";

// Common schemas
// const aggregationTypeSchema = z.enum(["count", "sum", "avg", "min", "max"]);
const aggregationTypeSchema = z.literal("count");

// Value schemas
const constantValueSchema = z.object({
  type: z.literal("constant"),
  value: z.union([z.string(), z.number()]),
});

const aggregationValueSchema = z.object({
  type: z.literal("aggregation"),
  aggregation: aggregationTypeSchema,
  options: z.null(),
});

const eventOperatorSchema = z.nativeEnum(NumericCompareOperator);

// Condition schemas
const baseConditionSchema = z.object({
  type: z.nativeEnum(SegmentConditionType),
});

// Individual condition schemas
const customerAttributeConditionSchema = baseConditionSchema.extend({
  type: z.literal(SegmentConditionType.CUSTOMER_ATTRIBUTE),
  whereCondition: whereConditionSchema,
});

const customerEventCountSchema = z.object({
  operator: eventOperatorSchema,
  value: z.number(),
});

const customerEventConditionSchema = baseConditionSchema.extend({
  type: z.literal(SegmentConditionType.CUSTOMER_ACTIVITY),
  event: z.nativeEnum(CustomerActivityType),
  count: customerEventCountSchema,
  timeframe: timeframeSchema,
  event_filters: z.array(whereConditionSchema),
});

// Union of all condition types
const queryConditionSchema = z.discriminatedUnion("type", [
  customerAttributeConditionSchema,
  customerEventConditionSchema,
]);

const conditionBlockSchema = z.object({
  operator: z.nativeEnum(ConjunctionMode),
  conditions: z.array(queryConditionSchema),
});
export type ConditionBlock = z.infer<typeof conditionBlockSchema>;

export const segmentSchema = z.object({
  conjunction: z.nativeEnum(ConjunctionMode),
  conditionBlocks: z.array(conditionBlockSchema),
});

// Export type definitions
export type EventOperator = z.infer<typeof eventOperatorSchema>;
export type AggregationType = z.infer<typeof aggregationTypeSchema>;
export type ConstantValue = z.infer<typeof constantValueSchema>;
export type AggregationValue = z.infer<typeof aggregationValueSchema>;
export type Timeframe = z.infer<typeof timeframeSchema>;
export type CustomerAttributeCondition = z.infer<
  typeof customerAttributeConditionSchema
>;
export type CustomerEventCondition = z.infer<
  typeof customerEventConditionSchema
>;
export type CustomerEventCount = z.infer<typeof customerEventCountSchema>;
export type QueryCondition = z.infer<typeof queryConditionSchema>;
export type SegmentQuery = z.infer<typeof segmentSchema>;

export function segmentToZod(segment: Segment): SegmentQuery {
  return {
    conjunction: segment.mode,
    conditionBlocks: segment.conditionBlocks.map(conditionBlockToZod),
  };
}

export function segmentFromZod(segment: DynamicCustomerGroup): Segment {
  return {
    name: segment.name,
    lastUpdated: segment.updatedAt,
    mode: segment.conditions.conjunction,
    conditionBlocks: segment.conditions.conditionBlocks.map(
      conditionBlockFromZod,
    ),
    initialStatistics: {
      eligibleCustomers: segment.count,
      eligibleEmailSubscribers: segment.emailSubscriberCount,
      eligibleSmsSubscribers: segment.smsSubscriberCount,
    },
  };
}

export function conditionBlockToZod(
  conditionBlock: SegmentConditionBlock,
): ConditionBlock {
  return {
    operator: conditionBlock.mode,
    conditions: conditionBlock.conditions.map(segmentConditionToZod),
  };
}

function conditionBlockFromZod(
  conditionBlock: ConditionBlock,
): SegmentConditionBlock {
  return {
    mode: conditionBlock.operator,
    conditions: conditionBlock.conditions.map(segmentConditionFromZod),
  };
}

function segmentConditionToZod(
  segmentCondition: SegmentCondition,
): QueryCondition {
  switch (segmentCondition.type) {
    case SegmentConditionType.CUSTOMER_ATTRIBUTE:
      return customerAttributeConditionToZod(segmentCondition);
    case SegmentConditionType.CUSTOMER_ACTIVITY:
      return customerActivityConditionToZod(segmentCondition);
    default:
      assertNever(segmentCondition);
  }
}

function segmentConditionFromZod(condition: QueryCondition): SegmentCondition {
  switch (condition.type) {
    case SegmentConditionType.CUSTOMER_ATTRIBUTE:
      return customerAttributeConditionFromZod(condition);
    case SegmentConditionType.CUSTOMER_ACTIVITY:
      return customerActivityConditionFromZod(condition);
    default:
      assertNever(condition);
  }
}

function customerAttributeConditionToZod(
  condition: OriginalCustomerAttributeCondition,
): QueryCondition {
  return {
    type: SegmentConditionType.CUSTOMER_ATTRIBUTE,
    whereCondition: whereConditionToZod(condition.whereCondition),
  };
}

function customerActivityConditionToZod(
  condition: CustomerActivityCondition,
): QueryCondition {
  return {
    type: SegmentConditionType.CUSTOMER_ACTIVITY,
    event: condition.activityType,
    count: activityCountToZod(condition.count),
    timeframe: timeframeToZod(condition.timeframe),
    event_filters: condition.whereConditions.map(whereConditionToZod),
  };
}

function customerAttributeConditionFromZod(
  condition: CustomerAttributeCondition,
): OriginalCustomerAttributeCondition {
  return {
    type: SegmentConditionType.CUSTOMER_ATTRIBUTE,
    whereCondition: whereConditionFromZod(
      condition.whereCondition,
    ) as OriginalWhereCondition<CustomerCharacteristicType>,
  };
}

function customerActivityConditionFromZod(
  condition: CustomerEventCondition,
): CustomerActivityCondition {
  return {
    type: SegmentConditionType.CUSTOMER_ACTIVITY,
    activityType: condition.event,
    count: activityCountFromZod(condition.count),
    timeframe: timeframeFromZod(condition.timeframe),
    whereConditions: condition.event_filters.map(
      whereConditionFromZod,
    ) as OriginalWhereCondition<CustomerActivityType>[],
  };
}

function whereConditionToZod(
  condition: OriginalWhereCondition<string>,
): z.infer<typeof whereConditionSchema> {
  switch (condition.type) {
    case WhereConditionDataType.TOKEN:
      return tokenWhereConditionToZod(condition);
    case WhereConditionDataType.NUMERIC:
      return numericWhereConditionToZod(condition);
    case WhereConditionDataType.BOOLEAN:
      return booleanWhereConditionToZod(condition);
    case WhereConditionDataType.DATE:
      return dateWhereConditionToZod(condition);
    case WhereConditionDataType.TOKEN_LIST:
      return tokenListWhereConditionToZod(condition);
    default:
      assertNever(condition);
  }
}

function whereConditionFromZod(
  condition: z.infer<typeof whereConditionSchema>,
): OriginalWhereCondition<string> {
  switch (condition.type) {
    case WhereConditionDataType.TOKEN:
      return tokenWhereConditionFromZod(condition);
    case WhereConditionDataType.NUMERIC:
      return numericWhereConditionFromZod(condition);
    case WhereConditionDataType.BOOLEAN:
      return booleanWhereConditionFromZod(condition);
    case WhereConditionDataType.DATE:
      return dateWhereConditionFromZod(condition);
    case WhereConditionDataType.TOKEN_LIST:
      return tokenListWhereConditionFromZod(condition);
    default:
      assertNever(condition);
  }
}

function tokenWhereConditionFromZod(
  condition: TokenCondition,
): OriginalTokenCondition<string> {
  return condition;
}

function numericWhereConditionFromZod(
  condition: NumericCondition,
): OriginalNumericCondition<string> {
  return condition;
}

function booleanWhereConditionFromZod(
  condition: BooleanCondition,
): OriginalBooleanCondition<string> {
  return condition;
}

function tokenListWhereConditionFromZod(
  condition: TokenListCondition,
): OriginalTokenListCondition<string> {
  return condition as OriginalTokenListCondition<string>;
}

function tokenWhereConditionToZod(
  condition: OriginalTokenCondition<string>,
): TokenCondition {
  return condition;
}

function numericWhereConditionToZod(
  condition: OriginalNumericCondition<string>,
): NumericCondition {
  return condition;
}

function booleanWhereConditionToZod(
  condition: OriginalBooleanCondition<string>,
): BooleanCondition {
  return condition;
}

function tokenListWhereConditionToZod(
  condition: OriginalTokenListCondition<string>,
): TokenListCondition {
  return condition;
}

function dateWhereConditionToZod(
  condition: OriginalDateCondition<string>,
): DateCondition {
  return {
    type: WhereConditionDataType.DATE,
    dimension: condition.dimension,
    comparison: timeframeToZod(condition.comparison),
  };
}

function dateWhereConditionFromZod(
  condition: DateCondition,
): OriginalDateCondition<string> {
  return {
    type: WhereConditionDataType.DATE,
    dimension: condition.dimension,
    comparison: timeframeFromZod(condition.comparison),
  };
}

function timeframeToZod(
  condition: TimeframeCondition,
): z.infer<typeof timeframeSchema> {
  switch (condition.type) {
    case TimeframeConditionType.BEFORE_NOW_RELATIVE:
      return {
        type: TimeframeConditionType.BEFORE_NOW_RELATIVE,
        options: { value: condition.value, units: condition.units },
      };
    case TimeframeConditionType.ON:
      return {
        type: TimeframeConditionType.ON,
        options: { date: getDateString(condition.date) },
      };
    case TimeframeConditionType.BEFORE_RELATIVE:
      return {
        type: TimeframeConditionType.BEFORE_RELATIVE,
        options: { value: condition.value, units: condition.units },
      };
    case TimeframeConditionType.BEFORE:
      return {
        type: TimeframeConditionType.BEFORE,
        options: { date: getDateString(condition.date) },
      };
    case TimeframeConditionType.AFTER:
      return {
        type: TimeframeConditionType.AFTER,
        options: { date: getDateString(condition.date) },
      };
    case TimeframeConditionType.ALL_TIME:
      return { type: TimeframeConditionType.ALL_TIME, options: null };
    case TimeframeConditionType.TODAY:
      return { type: TimeframeConditionType.TODAY, options: null };
    case TimeframeConditionType.BETWEEN_RELATIVE:
      return {
        type: TimeframeConditionType.BETWEEN_RELATIVE,
        options: {
          start: condition.start,
          end: condition.end,
          units: condition.units,
        },
      };
    case TimeframeConditionType.BETWEEN_DATES:
      return {
        type: TimeframeConditionType.BETWEEN_DATES,
        options: {
          range: condition.range.map(getDateString) as [string, string],
        },
      };
    case TimeframeConditionType.AUTOMATION_START:
      return {
        type: TimeframeConditionType.AUTOMATION_START,
        options: {
          automationStartTime: condition.automationStartTime.toISOString(),
        },
      };
    default:
      assertNever(condition);
  }
}

function timeframeFromZod(
  condition: z.infer<typeof timeframeSchema>,
): TimeframeCondition {
  switch (condition.type) {
    case TimeframeConditionType.BEFORE_NOW_RELATIVE:
      return {
        type: TimeframeConditionType.BEFORE_NOW_RELATIVE,
        value: condition.options.value,
        units: condition.options.units,
      };
    case TimeframeConditionType.ON:
      return {
        type: TimeframeConditionType.ON,
        date: new Date(condition.options.date),
      };
    case TimeframeConditionType.BEFORE_RELATIVE:
      return {
        type: TimeframeConditionType.BEFORE_RELATIVE,
        value: condition.options.value,
        units: condition.options.units,
      };
    case TimeframeConditionType.BEFORE:
      return {
        type: TimeframeConditionType.BEFORE,
        date: new Date(condition.options.date),
      };
    case TimeframeConditionType.AFTER:
      return {
        type: TimeframeConditionType.AFTER,
        date: new Date(condition.options.date),
      };
    case TimeframeConditionType.ALL_TIME:
      return { type: TimeframeConditionType.ALL_TIME };
    case TimeframeConditionType.TODAY:
      return { type: TimeframeConditionType.TODAY };
    case TimeframeConditionType.BETWEEN_RELATIVE:
      return {
        type: TimeframeConditionType.BETWEEN_RELATIVE,
        start: condition.options.start,
        end: condition.options.end,
        units: condition.options.units,
      };
    case TimeframeConditionType.BETWEEN_DATES:
      return {
        type: TimeframeConditionType.BETWEEN_DATES,
        range: condition.options.range.map((date) => new Date(date)) as [
          Date,
          Date,
        ],
      };
    case TimeframeConditionType.AUTOMATION_START:
      return {
        type: TimeframeConditionType.AUTOMATION_START,
        automationStartTime: new Date(condition.options.automationStartTime),
      };
    default:
      assertNever(condition);
  }
}

function activityCountToZod(
  count: ActivityCount,
): z.infer<typeof customerEventCountSchema> {
  switch (count.type) {
    case ActivityCountType.AT_LEAST_ONCE:
      return { operator: NumericCompareOperator.GT, value: 0 };
    case ActivityCountType.ZERO_TIMES:
      return { operator: NumericCompareOperator.EQ, value: 0 };
    case ActivityCountType.N_TIMES:
      return { operator: NumericCompareOperator.EQ, value: count.n };
    case ActivityCountType.NOT_N_TIMES:
      return { operator: NumericCompareOperator.NEQ, value: count.n };
    case ActivityCountType.AT_LEAST_N:
      return { operator: NumericCompareOperator.GTE, value: count.n };
    case ActivityCountType.GREATER_THAN_N:
      return { operator: NumericCompareOperator.GT, value: count.n };
    case ActivityCountType.LESS_THAN_N:
      return { operator: NumericCompareOperator.LT, value: count.n };
    case ActivityCountType.AT_MOST_N:
      return { operator: NumericCompareOperator.LTE, value: count.n };
    default:
      assertNever(count);
  }
}

function activityCountFromZod(
  count: z.infer<typeof customerEventCountSchema>,
): ActivityCount {
  switch (count.operator) {
    case NumericCompareOperator.GT:
      return count.value === 0
        ? { type: ActivityCountType.AT_LEAST_ONCE }
        : { type: ActivityCountType.GREATER_THAN_N, n: count.value };
    case NumericCompareOperator.EQ:
      return count.value === 0
        ? { type: ActivityCountType.ZERO_TIMES }
        : { type: ActivityCountType.N_TIMES, n: count.value };
    case NumericCompareOperator.NEQ:
      return { type: ActivityCountType.NOT_N_TIMES, n: count.value };
    case NumericCompareOperator.GTE:
      return { type: ActivityCountType.AT_LEAST_N, n: count.value };
    case NumericCompareOperator.LT:
      return { type: ActivityCountType.LESS_THAN_N, n: count.value };
    case NumericCompareOperator.LTE:
      return { type: ActivityCountType.AT_MOST_N, n: count.value };
    default:
      assertNever(count.operator);
  }
}
