import { Condition } from "./condition";
import { Resource } from "./resource";

export function withAbortListener(
  signal: AbortSignal | undefined,
  listener: (reason: any) => void,
): Resource<void> {
  const onAbort = () => listener(signal!.reason);
  return async (fn) => {
    signal?.addEventListener("abort", onAbort);
    try {
      return await fn();
    } finally {
      signal?.removeEventListener("abort", onAbort);
    }
  };
}

export function abortChild(signal?: AbortSignal): Resource<AbortController> {
  return async (fn) => {
    const controller = new AbortController();
    return await withAbortListener(signal, (reason) =>
      controller.abort(reason),
    )(() => fn(controller));
  };
}

export async function awaitAbort<T>(abort?: AbortSignal) {
  const condition = new Condition();
  await withAbortListener(abort, condition.reject)(() => condition.result);
}

export async function awaitAbortable<T>(
  promise: Promise<T>,
  abort?: AbortSignal,
): Promise<T> {
  const condition = new Condition<void>();
  return await withAbortListener(abort, (reason) => condition.reject(reason))(
    () => <Promise<T>>Promise.race([promise, abort]),
  );
}

/**
 * Combine AbortSignals, aborting on the first one.
 */
export function abortRace(aborts: Iterable<AbortSignal>): AbortSignal {
  const controller = new AbortController();
  const cleanups: (() => void)[] = [];
  const cleanup = () => {
    for (const cleanup of cleanups) {
      cleanup();
    }
  };
  for (const abort of aborts) {
    if (abort.aborted) {
      controller.abort(abort.reason);
      cleanup();
      break;
    }
    const listener = () => {
      controller.abort(abort.reason);
      for (const cleanup of cleanups) {
        cleanup();
      }
    };
    abort.addEventListener("abort", listener);
    cleanups.push(() => abort.removeEventListener("abort", listener));
  }
  return controller.signal;
}

export function abortTimeout(timeout: Temporal.Duration): AbortSignal {
  return AbortSignal.timeout(timeout.total("milliseconds"));
}
