import { z } from "zod";

export const getTeamConciergeKnowledge = {
  input: z.object({ filters: z.object({ searchText: z.string().optional() }) }),
  output: z.array(
    z.object({
      _id: z.string(),
      title: z.string(),
      knowledge: z.string(),
      whenToUse: z.string(),
      enabled: z.boolean(),
      teamId: z.string(),
      createdAt: z.string(),
    }),
  ),
};

export type GetTeamConciergeKnowledgeOutput = z.infer<
  typeof getTeamConciergeKnowledge.output
>;
