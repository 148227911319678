import { ConversationFiltersV3, FiltersStatus } from "./conversation-filters";
import { SystemView } from "./system-view";

export function scrubFilterFieldsThatArentStoredInTheDatabase(
  filters: ConversationFiltersV3,
  viewName?: string,
): ConversationFiltersV3 {
  const filtersCopy = { ...filters };
  delete filtersCopy.search;
  delete filtersCopy.customerEmail;
  delete filtersCopy.status;

  /** If it's a system default view, we don't store the sort direction in the database */
  if (viewName && (Object.values(SystemView) as string[]).includes(viewName)) {
    delete filtersCopy.sort;
  }
  return filtersCopy;
}

function addOpenStatus(filters: ConversationFiltersV3): ConversationFiltersV3 {
  return { ...filters, status: FiltersStatus.OPEN };
}

export function formatFiltersForDatabase(
  filters: ConversationFiltersV3,
): ConversationFiltersV3 {
  return addOpenStatus(scrubFilterFieldsThatArentStoredInTheDatabase(filters));
}
