import {
  Carriers,
  FedexServices,
  getAllServicesArray,
  UpsServices,
  UspsServices,
} from "@redotech/redo-model/fulfillments/fulfillment-carriers-and-services";
import { FulfillmentOrderData } from "@redotech/redo-model/fulfillments/fulfillment-group";
import { Parcel } from "@redotech/redo-model/outbound-labels/parcel";
import { Rate } from "@redotech/redo-model/shipment";
import { CURRENCY_FORMAT } from "@redotech/redo-web/currency";
import { assertNever } from "@redotech/util/type";
import { formatParcelName } from "../../setting/parcels";

export function getPriceWithUpcharge(rate: Rate): number {
  return +(rate?.rate ?? 0);
}

export function getCarrierLogoUrl(carrier: string): string {
  if (!carrier) {
    return "";
  }
  if (carrier.toLowerCase().includes("ups")) {
    return "https://redoapi-prod.s3.amazonaws.com/ups.png";
  } else if (carrier.toLowerCase().includes("fedex")) {
    return "https://redoapi-prod.s3.amazonaws.com/fedex.png";
  } else if (carrier.toLowerCase().includes("usps")) {
    return "https://redoapi-prod.s3.amazonaws.com/usps.png";
  } else if (carrier.toLowerCase().includes("dhl")) {
    return "https://redoapi-prod.s3.amazonaws.com/dhl.png";
  } else if (carrier.toLowerCase().includes("sendle")) {
    return "https://redoapi-prod.s3.amazonaws.com/sendle.png";
  }
  return "";
}

export const isSplit = (row: FulfillmentOrderData) => {
  // TODO: figure this out
  return false;
};

export const isMerged = (row: FulfillmentOrderData) => {
  // TODO: figure this out too
  return false;
};

export const getOrderName = (row: FulfillmentOrderData): string => {
  return row.orderNumbers[0] ?? "";
};

export const getOrderAge = (row: FulfillmentOrderData): string => {
  const orderDate = getOrderDate(row);

  const now = Temporal.Now.instant();
  const orderInstant = Temporal.Instant.from(orderDate.toISOString());
  const diff = now.since(orderInstant);

  const totalMinutes = diff.total({ unit: "minutes" });
  const totalHours = diff.total({ unit: "hours" });
  const totalDays = diff.total({ unit: "days" });

  if (totalMinutes < 60) {
    return `${Math.floor(totalMinutes)} minute${totalMinutes === 1 ? "" : "s"}`;
  }

  if (totalHours < 48) {
    return `${Math.floor(totalHours)} hour${totalHours === 1 ? "" : "s"}`;
  }

  return `${Math.floor(totalDays)} day${totalDays === 1 ? "" : "s"}`;
};

export const getCustomerName = (row: FulfillmentOrderData): string => {
  return row.customerName ?? "";
};

export const getOrderDate = (row: FulfillmentOrderData) => {
  return row.providerCreatedAt;
};

export const getOrderTotal = (row: FulfillmentOrderData) => {
  return row.totalPrice.amount;
};

export const getOrderCurrency = (row: FulfillmentOrderData) => {
  return row.totalPrice.currency;
};

export const getNumItems = (row: FulfillmentOrderData) => {
  return row.totalItemQuantity;
};

export const getCustomerShippingMethod = (row: FulfillmentOrderData) => {
  return row.shippingMethodNames[0];
};

export const getCustomerShippingPrice = (row: FulfillmentOrderData) => {
  return CURRENCY_FORMAT(row.totalShippingCost.currency).format(
    Number(row.totalShippingCost.amount),
  );
};

export const getParcelName = (parcel: Parcel) => {
  return "carrier" in parcel ? formatParcelName(parcel.name) : parcel.name;
};

export const formatDateString = (isoDate: string | undefined): string => {
  if (!isoDate) {
    return "";
  }
  try {
    return new Date(isoDate).toLocaleString("default", {
      month: "short",
      day: "numeric",
      year: "numeric",
    });
  } catch (e) {
    console.error(e);
    return "";
  }
};

// Not sure what the long term plan here is, but the idea is that when we get something like "USPSReturns" as the carrier name, we want to display "USPS"
export const getPrettyCarrierName = (carrier: string) => {
  if (
    !Object.values(Carriers)
      .map((c) => c.toString())
      .includes(carrier)
  ) {
    // We haven't added this carrier to the enums yet, so we don't have a pretty name
    return carrier;
  }

  const typedCarrier: Carriers = carrier as Carriers;

  switch (typedCarrier) {
    case Carriers.USPS_RETURNS:
    case Carriers.USPS:
      return "USPS";
    case Carriers.UPS:
      return "UPS";
    case Carriers.FEDEX_SMART_POST:
    case Carriers.FEDEX:
      return "FedEx";
    default:
      assertNever(typedCarrier);
  }
};

export const getPrettyServiceName = (service: string) => {
  const knownServices: string[] = getAllServicesArray().map((a) =>
    a.toString(),
  );

  if (!knownServices.includes(service as any)) {
    // We haven't added this service to the enums yet, so we don't have a pretty name
    return service;
  }

  const typedService = service as FedexServices | UpsServices | UspsServices;

  switch (typedService) {
    case FedexServices.FEDEX_INTERNATIONAL_PRIORITY_EXPRESS:
      return "International Priority Express";
    case FedexServices.FEDEX_INTERNATIONAL_CONNECT_PLUS:
      return "International Connect Plus";
    case FedexServices.INTERNATIONAL_FIRST:
      return "International First";
    case FedexServices.FEDEX_GROUND:
      return "Ground";
    case FedexServices.FEDEX_2_DAY:
      return "2 Day";
    case FedexServices.PRIORITY_OVERNIGHT:
      return "Priority Overnight";
    case FedexServices.FEDEX_FIRST_FREIGHT:
      return "First Freight";
    case FedexServices.FEDEX_EXPRESS_SAVER:
      return "Express Saver";
    case FedexServices.FEDEX_3_DAY_FREIGHT:
      return "3 Day Freight";
    case FedexServices.FEDEX_2_DAY_AM:
      return "2 Day AM";
    case FedexServices.FEDEX_2_DAY_FREIGHT:
      return "2 Day Freight";
    case FedexServices.INTERNATIONAL_ECONOMY:
      return "International Economy";
    case FedexServices.FIRST_OVERNIGHT:
      return "First Overnight";
    case FedexServices.STANDARD_OVERNIGHT:
      return "Standard Overnight";
    case FedexServices.SMART_POST:
      return "Ground"; // "Smart Post" has a bad reputation. It's actually ground shipping. Show that
    case FedexServices.FEDEX_1_DAY_FREIGHT:
      return "1 Day Freight";
    case FedexServices.INTERNATIONAL_PRIORITY:
      return "International Priority";
    case FedexServices.FEDEX_INTERNATIONAL_PRIORITY:
      return "International Priority";
    case UpsServices.Ground:
      return "Ground";
    case UpsServices.NextDayAir:
      return "Next Day Air";
    case UpsServices.ThreeDaySelect:
      return "3 Day Select";
    case UpsServices.SecondDayAirAM:
      return "2nd Day Air AM";
    case UpsServices.UPSSaver:
      return "UPS Saver";
    case UpsServices.NextDayAirEarlyAM:
      return "Next Day Air Early AM";
    case UpsServices.ExpressPlus:
      return "Express Plus";
    case UpsServices.Express:
      return "Express";
    case UpsServices.NextDayAirSaver:
      return "Next Day Air Saver";
    case UpsServices.UPSStandard:
      return "UPS Standard";
    case UpsServices.SecondDayAir:
      return "2nd Day Air";
    case UpsServices.Expedited:
      return "Expedited";
    case UspsServices.GroundAdvantageReturn:
      return "Ground Advantage Return";
    case UspsServices.ExpressMailInternational:
      return "Express Mail International";
    case UspsServices.PriorityMailInternational:
      return "Priority Mail International";
    case UspsServices.FirstClassPackageInternationalService:
      return "First Class Package International Service";
    case UspsServices.GroundAdvantage:
      return "Ground Advantage";
    case UspsServices.PriorityMailReturn:
      return "Priority Mail Return";
    case UspsServices.Priority:
      return "Priority";
    case UspsServices.Express:
      return "Express";
    case UspsServices.PriorityMailExpressReturn:
      return "Priority Mail Express Return";
    default:
      assertNever(typedService);
  }
};
