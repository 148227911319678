import { zExt } from "@redotech/rpc/ext";
import { z } from "zod";
import { SortDirection } from "../../tables/table";
import { ReportFilterSchema } from "./filters/report-filter";
import { getValidFilterTypes } from "./filters/report-filter-type";
import { ReportDataType } from "./report-data-type";

// Mirror all changes to this file in redo/server/src/models/Report.ts

const ReportScheduleSchema = z.object({
  enabled: z.boolean(),
  emailAddresses: z.array(z.string()),
  cron: z.string(),
  nextReport: z.date().optional(),
});

export const ReportSchema = z
  .object({
    _id: zExt.objectId(),
    team: zExt.objectId(),
    name: z.string(),
    dataType: z.nativeEnum(ReportDataType),
    columns: z.array(z.string()),
    sortKey: z.string().optional(),
    sortDirection: z.nativeEnum(SortDirection).optional(),
    filters: z.array(ReportFilterSchema),
    schedule: ReportScheduleSchema.optional(),
    createdAt: z.date(),
    updatedAt: z.date(),
  })
  // Enforce that filters are valid for the data type
  .superRefine(({ dataType, filters }, ctx) => {
    const validTypes = getValidFilterTypes(dataType);
    const invalidFilters = filters.filter(
      (filter) => !validTypes.includes(filter.type),
    );

    for (const filter of invalidFilters) {
      ctx.addIssue({
        code: z.ZodIssueCode.invalid_enum_value,
        message: `Invalid filter type "${filter.type}" for data type "${dataType}"`,
        received: filter.type,
        options: validTypes,
      });
    }
  })
  .innerType();

/** Front-end type */
export type Report = z.infer<typeof ReportSchema>;
