import { TimeframeCondition } from "./segment-timeframe";

export type WhereCondition<DIM extends string> =
  | TokenCondition<DIM>
  | NumericCondition<DIM>
  | BooleanCondition<DIM>
  | DateCondition<DIM>
  | TokenListCondition<DIM>;

// We might want to create dedicated currency and percentage data types
export enum WhereConditionDataType {
  TOKEN = "token",
  NUMERIC = "numeric",
  BOOLEAN = "boolean",
  DATE = "date",
  TOKEN_LIST = "token_list",
}

export enum ComparisonType {
  BOOLEAN = "boolean",
  LIST = "list",
  NUMERIC = "numeric",
  TOKEN = "token",
}

export interface Condition<DIM extends string, C> {
  type: WhereConditionDataType;
  dimension: DIM;
  comparison: C;
}

export enum TokenCompareOperators {
  // EQ = "EQ",
  // NEQ = "NEQ",
  NONE = "NONE",
  ANY = "ANY",
}

// interface SingleTokenCompare {
//   type: ComparisonType.TOKEN;
//   operator: TokenCompareOperators.EQ | TokenCompareOperators.NEQ;
//   value: string;
// }

interface ListTokenCompare {
  type: ComparisonType.TOKEN;
  operator: TokenCompareOperators.NONE | TokenCompareOperators.ANY;
  values: string[];
}

// type TokenCompare = SingleTokenCompare | ListTokenCompare;

export type TokenCompare = ListTokenCompare;

export interface TokenCondition<DIM extends string>
  extends Condition<DIM, TokenCompare> {
  type: WhereConditionDataType.TOKEN;
}

export enum NumericCompareOperator {
  EQ = "eq",
  GT = "gt",
  LT = "lt",
  GTE = "gte",
  LTE = "lte",
  NEQ = "neq",
}

export interface NumericCompare {
  type: ComparisonType.NUMERIC;
  operator: NumericCompareOperator;
  value: number;
}

export interface NumericCondition<DIM extends string>
  extends Condition<DIM, NumericCompare> {
  type: WhereConditionDataType.NUMERIC;
}

export interface BooleanCompare {
  type: ComparisonType.BOOLEAN;
  value: boolean;
}

export interface BooleanCondition<DIM extends string>
  extends Condition<DIM, BooleanCompare> {
  type: WhereConditionDataType.BOOLEAN;
}

export interface DateCondition<DIM extends string>
  extends Condition<DIM, TimeframeCondition> {
  type: WhereConditionDataType.DATE;
}

export enum ListCompareOperators {
  ANY = "any",
  NONE = "none",
  ALL = "all",
}

export interface ListCompare<T> {
  type: ComparisonType.LIST;
  operator: ListCompareOperators;
  values: T[];
}

export interface TokenListCondition<DIM extends string>
  extends Condition<DIM, ListCompare<string>> {
  type: WhereConditionDataType.TOKEN_LIST;
}
