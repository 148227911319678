// extracted by mini-css-extract-plugin
export var alternate = "_38-h";
export var black = "_38-3";
export var blue = "_38-d";
export var blueLight = "_38-c";
export var brand = "_38-7";
export var error = "_38-9";
export var gray = "_38-6";
export var grayBlue = "_38-b";
export var iconWrapper = "_38-4";
export var indigo = "_38-e";
export var orange = "_38-g";
export var pink = "_38-f";
export var primary = "_38-0";
export var purple = "_38-8";
export var success = "_38-5";
export var warning = "_38-a";
export var white = "_38-1";
export var whiteSuccess = "_38-2";