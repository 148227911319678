// extracted by mini-css-extract-plugin
export var active = "_1n-d";
export var blink = "_1n-e";
export var container = "_1n-5";
export var controls = "_1n-0";
export var controlsRight = "_1n-1";
export var fakeCursor = "_1n-c";
export var hidden = "_1n-b";
export var quillContainerSmall = "_1n-7";
export var quillEditor = "_1n-6";
export var quillEditorSmall = "_1n-8";
export var quillFormatButtons = "_1n-a";
export var quillToolbar = "_1n-9";
export var removePadding = "_1n-2";
export var state = "_1n-3";
export var stateTitle = "_1n-4";