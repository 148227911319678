// extracted by mini-css-extract-plugin
export var container = "_o-1";
export var infoText = "_o-5";
export var insightDetails = "_o-6";
export var insightSummary = "_o-3";
export var metric = "_o-9";
export var metricChange = "_o-a";
export var metricsContainer = "_o-8";
export var missingInsights = "_o-0";
export var selected = "_o-4";
export var spinnerContainer = "_o-2";
export var title = "_o-7";