export enum RedoShopifyFulfillmentStatus {
  /** The fulfillment was canceled. */
  Cancelled = "CANCELLED",
  /** There was an error with the fulfillment request. */
  Error = "ERROR",
  /** The fulfillment request failed. */
  Failure = "FAILURE",
  /**
   * The third-party fulfillment service has acknowledged the fulfillment and is processing it.
   * @deprecated This is a legacy status and is due to be deprecated.
   */
  Open = "OPEN",
  /**
   * Shopify has created the fulfillment and is waiting for the third-party fulfillment service to transition it to `open` or `success`.
   * @deprecated This is a legacy status and is due to be deprecated.
   */
  Pending = "PENDING",
  /** The fulfillment was completed successfully. */
  Success = "SUCCESS",
}
