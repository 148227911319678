import { z } from "zod";
import { FaqArticleDbParser, FaqCollectionDbParser } from "./faq-db-parser";

export const faqThemeBodySchema = z.object({
  brandKitOverrides: z.object({ pageBackgroundColor: z.boolean() }),
  homeBackgroundImageUrl: z.string().nullish(),
  overlayColor: z.string(),
  overlayOpacity: z.number(),
  headlineText: z.string(),
  headlineTextColor: z.string(),
  pageBackgroundColor: z.string(),
  displayCollectionIcon: z.boolean(),
  displayCollectionSubtext: z.boolean(),
  collectionBackgroundColor: z.string(),
  collectionBorderColor: z.string(),
  collectionCornerRadius: z.number(),
});

export type IFaqTheme = z.infer<typeof faqThemeBodySchema>;

export function defaultFaqTheme(): IFaqTheme {
  return {
    brandKitOverrides: { pageBackgroundColor: false },
    overlayColor: "#ffffff",
    overlayOpacity: 0,
    headlineText: "Help center",
    headlineTextColor: "#141414",
    pageBackgroundColor: "#fafafa",
    displayCollectionIcon: false,
    displayCollectionSubtext: true,
    collectionBackgroundColor: "#ffffff",
    collectionBorderColor: "#d6d6d6",
    collectionCornerRadius: 8,
  };
}

export const faqCollectionBodySchema = FaqCollectionDbParser.extend({
  _id: z.string(),
  team: z.string(),
  articles: z.array(z.string()),
});

export const faqArticleBodySchema = FaqArticleDbParser.extend({
  _id: z.string(),
  team: z.string(),
  faqCollection: z.string(),
});
