// extracted by mini-css-extract-plugin
export var buttons = "_57-0";
export var buttonsActive = "_57-h";
export var cancelConfirm = "_57-1";
export var closeButton = "_57-2";
export var column = "_57-3";
export var container = "_57-4";
export var detailHeader = "_57-6";
export var detailTitle = "_57-7";
export var detailsContent = "_57-5";
export var header = "_57-8";
export var headerWithClose = "_57-9";
export var icon = "_57-a";
export var infoIcon = "_57-c";
export var informationContainer = "_57-b";
export var sideDrawer = "_57-d";
export var sideDrawerOpen = "_57-e";
export var stickyFooter = "_57-f";
export var typeOption = "_57-g";