import { CreateSupportAiSubscriptionBody } from "@redotech/redo-model/support/billing/create-support-ai-subscription-body";
import { RedoMerchantClient } from ".";

export type GetSupportAIArgs = {
  type: "conversationReplySuggestion" | "conversationSummary";
  conversationId?: string;
  signal?: AbortSignal;
};

export type GetSupportAiResult = { text: string; cost: number };

export async function getSupportAiResponse(
  client: RedoMerchantClient,
  {
    type,
    conversationId,
    signal,
    prevMessageId,
  }: {
    type: string;
    conversationId?: string;
    signal?: AbortSignal;
    prevMessageId: string;
  },
): Promise<{ text: string; cost: number }> {
  const response = await client.client.post(
    "support/ai/response",
    { type, conversationId, prevMessageId },
    { headers: client.authorization(), signal },
  );
  return response.data;
}

/**
 * POST /support/ai/create-subscription
 */
export async function chargeForAiMessages(
  client: RedoMerchantClient,
  body: CreateSupportAiSubscriptionBody,
  signal?: AbortSignal,
): Promise<{ confirmationUrl: string }> {
  const response = await client.client.post(
    "support/ai/create-subscription",
    body,
    { headers: client.authorization(), signal },
  );
  return response.data;
}

export async function getSupportAISummary(
  client: RedoMerchantClient,
  { type, conversationId, signal }: GetSupportAIArgs,
): Promise<GetSupportAiResult | "error"> {
  try {
    const response = await client.client.post(
      "support/ai/summary",
      { type, conversationId },
      { headers: client.authorization(), signal, timeout: 15000 },
    );
    return response.data;
  } catch {
    return "error";
  }
}
