import { segmentSchema } from "@redotech/redo-model/marketing/segments/segment-zod-schema";
import { z } from "zod";

export const getSegmentCount = {
  input: segmentSchema,
  output: z.object({
    allCount: z.number(),
    emailCount: z.number(),
    smsCount: z.number(),
  }),
};
