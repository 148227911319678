import { isControlKeyPressed } from "@redotech/react-util/html-events";
import { MouseEvent } from "react";

/**
 * @param deselectedItems -- This is for use with select all mode,
 * because we don't actually have the full list of items in select all mode
 * (because of table lazy loading), we keep track of what items
 * are deselected as opposed to which are selected.
 *
 * @returns the delta of items to add and remove from the selection
 */
export function getSelectionDelta<T>({
  mouseEvent,
  idx,
  items,
  multiSelectAnchorStart,
  multiSelectAnchorEnd,
  selectedItems,
  deselectedItems,
  selectAllMode,
  setMultiSelectAnchorEnd,
  setMultiSelectAnchorStart,
  itemsEqual,
}: {
  mouseEvent: MouseEvent<HTMLTableRowElement>;
  idx: number;
  items: T[];
  selectedItems: T[];
  deselectedItems: T[];
  itemsEqual: (item1: T, item2: T) => boolean;
  multiSelectAnchorStart: number | undefined;
  multiSelectAnchorEnd: number | undefined;
  setMultiSelectAnchorStart: (idx: number | undefined) => void;
  setMultiSelectAnchorEnd: (idx: number | undefined) => void;
  selectAllMode: boolean;
}): { itemsToAdd: T[]; itemsToRemove: T[] } {
  if (isControlKeyPressed(mouseEvent) || mouseEvent.shiftKey) {
    mouseEvent.stopPropagation();
  }

  const item: T | undefined = items[idx];

  if (item === undefined) {
    console.error("Clicked on an item that does not exist");
    return { itemsToAdd: [], itemsToRemove: [] };
  }

  if (isControlKeyPressed(mouseEvent)) {
    setMultiSelectAnchorStart(idx);
    setMultiSelectAnchorEnd(undefined);

    const selectedOutsideSelectAllMode =
      !selectAllMode &&
      selectedItems.some((selectedItem) => itemsEqual(selectedItem, item));
    const selectedInsideSelectAllMode =
      selectAllMode &&
      !deselectedItems.some((deselectedItem) =>
        itemsEqual(deselectedItem, item),
      );

    const isCurrentlySelected =
      selectedOutsideSelectAllMode || selectedInsideSelectAllMode;
    if (isCurrentlySelected) {
      return { itemsToAdd: [], itemsToRemove: [item] };
    } else {
      return { itemsToAdd: [item], itemsToRemove: [] };
    }
  } else if (mouseEvent.shiftKey) {
    if (multiSelectAnchorStart === undefined) {
      setMultiSelectAnchorStart(idx);
      return { itemsToAdd: [item], itemsToRemove: [] };
    }

    const itemsToAdd = new Set<T>();
    const itemsToRemove = new Set<T>();

    // Deselect old ending slice
    if (multiSelectAnchorEnd !== undefined) {
      const start = Math.min(multiSelectAnchorStart, multiSelectAnchorEnd);
      const end = Math.max(multiSelectAnchorStart, multiSelectAnchorEnd);
      const slice = items.slice(start, end + 1);
      slice.forEach((item) => itemsToRemove.add(item));
    }
    setMultiSelectAnchorEnd(idx);
    const start = Math.min(multiSelectAnchorStart, idx);
    const end = Math.max(multiSelectAnchorStart, idx);
    const slice = items.slice(start, end + 1);

    slice.forEach((item) => itemsToAdd.add(item));
    slice.forEach((item) => itemsToRemove.delete(item));

    return {
      itemsToAdd: Array.from(itemsToAdd),
      itemsToRemove: Array.from(itemsToRemove),
    };
  } else {
    return { itemsToAdd: [item], itemsToRemove: selectedItems };
  }
}
