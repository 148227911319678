import { z } from "zod";

export const RepairFee = z.object({ title: z.string(), fee: z.string() });

export type RepairFee = z.infer<typeof RepairFee>;

export const RepairInvoiceDetails = z.object({
  name: z.string(),
  total: z.number(),
  paid: z.boolean(),
  fees: z.array(RepairFee),
});

export const getRepairInvoiceDetails = {
  input: z.object({ returnId: z.string() }),
  output: RepairInvoiceDetails,
};
