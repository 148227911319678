import { FulfillmentOrderData } from "@redotech/redo-model/fulfillments/fulfillment-group";
import {
  RedoTablePaddedCell,
  RedoTableTextCell,
} from "@redotech/redo-web/arbiter-components/tables/redo-table-cells";
import { Link } from "@redotech/redo-web/link";
import { ColumnAlignment } from "@redotech/redo-web/table";
import { Column2 } from "@redotech/redo-web/table2";
import { Text } from "@redotech/redo-web/text";
import { getOrderName } from "../../utils/outbound-labels-utils";

export const OrderNameColumn: Column2<FulfillmentOrderData> = {
  key: "order-name",
  title: "Order",
  alignment: ColumnAlignment.LEFT,
  renderNormalCell: ({ row }) => {
    if (row.orders?.length) {
      return (
        <RedoTablePaddedCell>
          {row.orders
            .filter((o) => !!o.name)
            .map((order, i) =>
              order.redoOrderId ? (
                <Link
                  key={i}
                  onClick={(e) => e.stopPropagation()}
                  to={`../../${order.redoOrderId}`}
                >
                  <Text fontSize="xs" fontWeight="medium" textColor="primary">
                    {order.name || "Unknown"}
                  </Text>
                </Link>
              ) : (
                <Text
                  fontSize="xs"
                  fontWeight="medium"
                  key={i}
                  textColor="primary"
                >
                  {order.name || "Unknown"}
                </Text>
              ),
            )}
        </RedoTablePaddedCell>
      );
    } else {
      return <RedoTableTextCell text={getOrderName(row)} />;
    }
  },
  width: 120,
};
