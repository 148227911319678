import { FulfillmentOrderDataSchema } from "@redotech/redo-model/fulfillments/fulfillment-group";
import { OmsTableInputSchema } from "@redotech/redo-model/outbound-labels/fulfillment-group-summary-input";
import { PaginationCursorSchema } from "@redotech/redo-model/tables/table";
import { z } from "zod";

export const getFulfillmentGroupSummaries = {
  input: OmsTableInputSchema.extend({
    pageNumber: z.number(),
    pageSize: z.number(),
    paginationCursor: PaginationCursorSchema.nullish(),
  }),
  output: z.object({
    fulfillmentGroups: z.array(FulfillmentOrderDataSchema),
    paginationCursor: PaginationCursorSchema.nullish(),
  }),
};
