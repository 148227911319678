import * as amplitude from "@amplitude/analytics-browser";
import { ClickAwayListener } from "@mui/material";
import { useRequiredContext } from "@redotech/react-util/context";
import { queryStringToFilters } from "@redotech/redo-model/conversation-filters/conversation-filter-query";
import {
  AssigneesFilterType,
  ChannelFilterType,
  ConversationTagFilterType,
  CustomerTagsFilterType,
  DateFilterQueryType,
  FilterGroupFilterOption,
  FiltersStatus,
} from "@redotech/redo-model/conversation-filters/conversation-filters";
import {
  RedoButton,
  RedoButtonHierarchy,
} from "@redotech/redo-web/arbiter-components/buttons/redo-button";
import { RedoTextInput } from "@redotech/redo-web/arbiter-components/input/redo-text-input";
import { RedoListItem } from "@redotech/redo-web/arbiter-components/list/redo-list";
import { RedoListItemSize } from "@redotech/redo-web/arbiter-components/list/redo-list-item";
import { RedoSingleSelectDropdown } from "@redotech/redo-web/arbiter-components/select-dropdown/redo-single-select-dropdown";
import ChevronDownIcon from "@redotech/redo-web/arbiter-icon/chevron-down_filled.svg";
import FilterLinesIcon from "@redotech/redo-web/arbiter-icon/filter-lines_filled.svg";
import {
  ConversationFilterOptionToIcon,
  ConversationFilterOptionToName,
} from "@redotech/redo-web/conversation-filters/conversation-filter-icons";
import { Flex } from "@redotech/redo-web/flex";
import { Text } from "@redotech/redo-web/text";
import { assertNever } from "@redotech/util/type";
import { memo, useContext, useEffect, useRef, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { useDebounce } from "usehooks-ts";
import { RedoList } from "../../../../web/src/arbiter-components/list/redo-list";
import { Dropdown } from "../../../../web/src/dropdown";
import { TeamContext } from "../../app/team";
import { ActiveViewContext } from "../conversations-table-filters/active-view-context";
import { ActiveViewConversationCountsContext } from "../conversations-table-filters/conversation-counts-context";
import {
  AdvancedFiltersContext,
  HaveFiltersResolvedOnInitialPageLoadContext,
  SetAdvancedFiltersContext,
  SetUniqueFiltersContext,
  UniqueFiltersContext,
} from "../conversations-table-filters/filters-context";
import * as conversationsTableHeaderFilterControls from "./conversations-table-header-filter-controls.module.css";

export const ConversationsTableHeaderFilterControls = memo(
  function ConversationsTableHeaderFilterControls() {
    const currentUniqueFilters = useContext(UniqueFiltersContext);
    const setUniqueFilters = useContext(SetUniqueFiltersContext);
    const advancedFilters = useContext(AdvancedFiltersContext);
    const setAdvancedFilters = useContext(SetAdvancedFiltersContext);
    const conversationCounts = useContext(ActiveViewConversationCountsContext);
    const activeView = useContext(ActiveViewContext);

    const location = useLocation();
    const navigate = useNavigate();

    const haveFiltersResolvedOnInitialPageLoad = useContext(
      HaveFiltersResolvedOnInitialPageLoadContext,
    );

    function initSearchString() {
      if (!haveFiltersResolvedOnInitialPageLoad) {
        return "";
      }
      const searchParams = new URLSearchParams(location.search);
      const rawFilters = searchParams.get("filters");
      if (rawFilters) {
        const filters = queryStringToFilters(rawFilters);
        if (filters.search) {
          return filters.search;
        }
      }
      return "";
    }

    const [searchString, setSearchString] = useState<string>(initSearchString);

    useEffect(() => {
      setSearchString(initSearchString());
    }, [haveFiltersResolvedOnInitialPageLoad, activeView]);

    const debouncedSearch = useDebounce(searchString, 500);

    const team = useRequiredContext(TeamContext);

    useEffect(() => {
      const urlParams = new URLSearchParams(location.search);

      const initialSearch = urlParams.get("initialSearch");
      if (initialSearch) {
        urlParams.delete("initialSearch");
        navigate({ search: urlParams.toString() });
        setSearchString(initialSearch);
      }
    }, [location]);

    const isNewFocusedSearch = useRef(false);
    const onFocus = () => {
      isNewFocusedSearch.current = true;
    };

    const onBlur = () => {
      isNewFocusedSearch.current = false;
    };

    useEffect(() => {
      if (searchString.length && isNewFocusedSearch.current) {
        amplitude.logEvent("search-conversation", {
          mode: "view",
          searchString,
          activeView,
        });
        isNewFocusedSearch.current = false;
      }
    }, [debouncedSearch, isNewFocusedSearch]);

    useEffect(() => {
      if (!haveFiltersResolvedOnInitialPageLoad) {
        return;
      }
      const currentSearch = currentUniqueFilters.search || "";
      if (currentSearch !== debouncedSearch) {
        setUniqueFilters({ ...currentUniqueFilters, search: debouncedSearch });
      }
    }, [
      debouncedSearch,
      setUniqueFilters,
      currentUniqueFilters,
      haveFiltersResolvedOnInitialPageLoad,
    ]);

    const [filtersDropdownButtonRef, setFiltersDropdownButtonRef] =
      useState<HTMLButtonElement | null>(null);

    const [statusFilterDropdownRef, setStatusFilterDropdownRef] =
      useState<HTMLButtonElement | null>(null);

    function addFilter(filter: FilterGroupFilterOption) {
      switch (filter) {
        case FilterGroupFilterOption.ASSIGNEES:
          setAdvancedFilters((old) => [
            ...old,
            {
              type: FilterGroupFilterOption.ASSIGNEES,
              value: null,
              query: AssigneesFilterType.INCLUDES,
            },
          ]);
          break;
        case FilterGroupFilterOption.CHANNELS:
          setAdvancedFilters((old) => [
            ...old,
            {
              type: FilterGroupFilterOption.CHANNELS,
              value: null,
              query: ChannelFilterType.INCLUDES,
            },
          ]);
          break;
        case FilterGroupFilterOption.READ:
          setAdvancedFilters((old) => [
            ...old,
            { type: FilterGroupFilterOption.READ, value: null },
          ]);
          break;
        case FilterGroupFilterOption.CONVERSATION_TAGS:
          setAdvancedFilters((old) => [
            ...old,
            {
              type: FilterGroupFilterOption.CONVERSATION_TAGS,
              value: null,
              query: ConversationTagFilterType.ANY_OF,
            },
          ]);
          break;
        case FilterGroupFilterOption.WORDS:
          setAdvancedFilters((old) => [
            ...old,
            { type: FilterGroupFilterOption.WORDS, value: null },
          ]);
          break;
        case FilterGroupFilterOption.CUSTOMER_TAGS:
          setAdvancedFilters((old) => [
            ...old,
            {
              type: FilterGroupFilterOption.CUSTOMER_TAGS,
              value: null,
              query: CustomerTagsFilterType.ANY_OF,
            },
          ]);
          break;
        case FilterGroupFilterOption.CREATED_DATE:
          setAdvancedFilters((old) => [
            ...old,
            {
              type: FilterGroupFilterOption.CREATED_DATE,
              value: null,
              query: DateFilterQueryType.WITHIN,
            },
          ]);
          break;
        case FilterGroupFilterOption.LAST_RESPONSE_AT:
          setAdvancedFilters((old) => [
            ...old,
            {
              type: FilterGroupFilterOption.LAST_RESPONSE_AT,
              value: null,
              query: DateFilterQueryType.WITHIN,
            },
          ]);
          break;
        case FilterGroupFilterOption.CLOSED_DATE:
          setAdvancedFilters((old) => [
            ...old,
            {
              type: FilterGroupFilterOption.CLOSED_DATE,
              value: null,
              query: DateFilterQueryType.WITHIN,
            },
          ]);
          break;
        case FilterGroupFilterOption.MENTIONS:
          setAdvancedFilters((old) => [
            ...old,
            { type: FilterGroupFilterOption.MENTIONS, value: null },
          ]);
          break;
        default:
          assertNever(filter);
      }
      setFiltersDropdownOpen(false);
    }

    const options: RedoListItem<FilterGroupFilterOption>[] = Object.values(
      FilterGroupFilterOption,
    ).map((value) => ({ value }));

    const [filtersDropdownOpen, setFiltersDropdownOpen] = useState(false);

    const [focusedIndex, setFocusedIndex] = useState<number | undefined>(
      undefined,
    );

    const filtersDropdown = filtersDropdownOpen && (
      <ClickAwayListener onClickAway={() => setFiltersDropdownOpen(false)}>
        <Dropdown
          anchor={filtersDropdownButtonRef}
          fitToAnchor={false}
          open={filtersDropdownOpen}
        >
          <RedoList
            focusedIndex={focusedIndex}
            items={options}
            itemSelected={(item) => addFilter(item.value)}
            refToListenTo={filtersDropdownButtonRef}
            setFocusedIndex={setFocusedIndex}
            size={RedoListItemSize.SMALL}
          >
            {(option) => {
              const Icon = ConversationFilterOptionToIcon[option.value];
              return (
                <Flex align="center">
                  <Flex
                    className={
                      conversationsTableHeaderFilterControls.filterDropdownIcon
                    }
                  >
                    <Icon />
                  </Flex>
                  <Text fontSize="xs">
                    {ConversationFilterOptionToName[option.value]}
                  </Text>
                </Flex>
              );
            }}
          </RedoList>
        </Dropdown>
      </ClickAwayListener>
    );

    const statusOptions: RedoListItem<FiltersStatus | "all">[] = [
      { value: FiltersStatus.OPEN },
      { value: FiltersStatus.CLOSED },
      ...(team.settings.support?.useInProgressStatus
        ? [{ value: FiltersStatus.IN_PROGRESS }]
        : []),
      { value: FiltersStatus.SNOOZED },
    ];
    statusOptions.push({ value: "all" });

    const statusDropdown = (
      <RedoSingleSelectDropdown
        dropdownButtonRef={statusFilterDropdownRef}
        options={statusOptions}
        optionSelected={(status: RedoListItem<FiltersStatus | "all">) => {
          setUniqueFilters({
            ...currentUniqueFilters,
            status: status.value === "all" ? null : status.value,
          });
        }}
        selectedItem={
          currentUniqueFilters.status
            ? { value: currentUniqueFilters.status }
            : { value: "all" }
        }
        size={RedoListItemSize.SMALL}
      >
        {(option: RedoListItem<FiltersStatus | "all">) => {
          return <Text fontSize="xs">{getItemDisplay(option.value)}</Text>;
        }}
      </RedoSingleSelectDropdown>
    );

    function getItemDisplay(status: FiltersStatus | "all"): string {
      const count = conversationCounts
        ? (conversationCounts[`${activeView.name}-${status}`] ?? 0)
        : 0;
      if (status === "all") {
        return `All (${count})`;
      } else if (status === FiltersStatus.OPEN) {
        return `Open (${count})`;
      } else if (status === FiltersStatus.CLOSED) {
        return `Closed (${count})`;
      } else if (status === FiltersStatus.SNOOZED) {
        return `Snoozed (${count})`;
      } else if (status === FiltersStatus.IN_PROGRESS) {
        return `In progress (${count})`;
      } else {
        return assertNever(status);
      }
    }

    const isAlreadyInSearchAll =
      !currentUniqueFilters.status && advancedFilters.length === 0;

    return (
      <>
        {filtersDropdown}
        {statusDropdown}
        <Flex dir="column" gap="lg">
          <Flex gap="md">
            <RedoTextInput
              onBlur={onBlur}
              onFocus={onFocus}
              placeholder="Search"
              setValue={setSearchString}
              value={searchString || ""}
            />
            <RedoButton
              hierarchy={RedoButtonHierarchy.SECONDARY}
              IconLeading={FilterLinesIcon}
              onClick={() => {
                setFiltersDropdownOpen((old) => !old);
              }}
              ref={setFiltersDropdownButtonRef}
            />
            <RedoButton
              hierarchy={RedoButtonHierarchy.SECONDARY}
              IconTrailing={ChevronDownIcon}
              onClick={() => {}}
              ref={setStatusFilterDropdownRef}
              text={getItemDisplay(currentUniqueFilters.status || "all")}
            />
            {debouncedSearch && !isAlreadyInSearchAll && (
              <RedoButton
                hierarchy={RedoButtonHierarchy.TERTIARY}
                onClick={() => {
                  setAdvancedFilters([]);
                  setUniqueFilters({ search: debouncedSearch, status: null });
                }}
                text="Search all"
              />
            )}
          </Flex>
        </Flex>
      </>
    );
  },
);
