import { RedoListItem } from "@redotech/redo-web/arbiter-components/list/redo-list";
import {
  RedoDropdownInputSize,
  RedoSingleSelectDropdownInput,
} from "@redotech/redo-web/arbiter-components/select-dropdown/redo-single-select-dropdown-input";
import { Flex } from "@redotech/redo-web/flex";
import { InputSize } from "@redotech/redo-web/input";
import { LabeledInput, LabelSize } from "@redotech/redo-web/labeled-input";
import { Text } from "@redotech/redo-web/text";
import { InputLines, TextInput } from "@redotech/redo-web/text-input";
import { memo } from "react";
import * as editItemDispositionCss from "./edit-item-disposition.module.css";

export const EditItemDisposition = memo(function EditItemDisposition({
  grade,
  outcome,
  notes,
}: {
  grade: {
    options: string[];
    selectedOption: string | undefined;
    setSelectedOption: (value: string) => void;
  };
  outcome: {
    options: string[];
    selectedOption: string | undefined;
    setSelectedOption: (value: string) => void;
  };
  notes: { value: string; setValue: (value: string) => void };
}) {
  return (
    <Flex dir="column">
      <Flex className={editItemDispositionCss.itemDetailsGradeOutcomeContainer}>
        <LabeledInput
          className={editItemDispositionCss.itemDetailsLabeledInput}
          label="Grade"
          size={LabelSize.EXTRA_SMALL}
        >
          <RedoSingleSelectDropdownInput
            options={grade.options.map((grade) => ({ value: grade }))}
            optionSelected={(option: RedoListItem<string>) =>
              grade.setSelectedOption(option.value)
            }
            placeholder="Select"
            selectedItem={
              grade.selectedOption ? { value: grade.selectedOption } : undefined
            }
            size={RedoDropdownInputSize.SMALL}
          >
            {(item: RedoListItem<string>) => <Text>{item.value}</Text>}
          </RedoSingleSelectDropdownInput>
        </LabeledInput>
        <LabeledInput
          className={editItemDispositionCss.itemDetailsLabeledInput}
          label="Outcome"
          size={LabelSize.EXTRA_SMALL}
        >
          <RedoSingleSelectDropdownInput
            options={outcome.options.map((outcome) => ({ value: outcome }))}
            optionSelected={(option: RedoListItem<string>) =>
              outcome.setSelectedOption(option.value)
            }
            placeholder="Select"
            selectedItem={
              outcome.selectedOption
                ? { value: outcome.selectedOption }
                : undefined
            }
            size={RedoDropdownInputSize.SMALL}
          >
            {(item: RedoListItem<string>) => <Text>{item.value}</Text>}
          </RedoSingleSelectDropdownInput>
        </LabeledInput>
      </Flex>
      <LabeledInput
        className={editItemDispositionCss.itemDetailsLabeledInput}
        label="Notes"
        size={LabelSize.EXTRA_SMALL}
      >
        <TextInput
          lines={InputLines.MULTI}
          onChange={notes.setValue}
          size={InputSize.EXTRA_SMALL}
          value={notes.value}
        />
      </LabeledInput>
    </Flex>
  );
});
