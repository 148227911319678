import { useHandler } from "@redotech/react-util/hook";
import { SortDirection } from "@redotech/redo-model/tables/table";
import { assertNever } from "@redotech/util/type";
import * as classNames from "classnames";
import * as React from "react";
import ArrowDownSvg from "../../arbiter-icon/arrow-down_filled.svg";
import ArrowUpSvg from "../../arbiter-icon/arrow-up_filled.svg";
import HelpCircleSvg from "../../arbiter-icon/help-circle.svg";
import { Flex } from "../../flex";
import { Text } from "../../text";
import { Tooltip } from "../../tooltip/tooltip";
import { RedoCheckbox, RedoCheckboxSize } from "../checkbox/redo-checkbox";
import * as redoTableHeaderCellsCss from "./redo-table-header-cells.module.css";

export enum StandardRedoTableHeaderCellArrowMode {
  ASCENDING = "ascending",
  DESCENDING = "descending",
  NOT_SET = "not-set",
  NO_ARROW = "no-arrow",
}

export const sortDirectionToArrowMode: Record<
  SortDirection,
  StandardRedoTableHeaderCellArrowMode
> = {
  [SortDirection.ASC]: StandardRedoTableHeaderCellArrowMode.ASCENDING,
  [SortDirection.DESC]: StandardRedoTableHeaderCellArrowMode.DESCENDING,
};

export const StandardRedoTableHeaderCell = ({
  headerText,
  helpIconTooltip,
  arrowIcon = StandardRedoTableHeaderCellArrowMode.NO_ARROW,
  onSortClick,
}: {
  headerText: string;
  helpIconTooltip?: string;
  arrowIcon?: StandardRedoTableHeaderCellArrowMode;
  onSortClick?: () => void;
}) => {
  const [hovered, setHovered] = React.useState(false);

  const onMouseLeaves = useHandler(() => setHovered(false));
  const onMouseEnters = useHandler(() => setHovered(true));
  let arrow = undefined;
  switch (arrowIcon) {
    case StandardRedoTableHeaderCellArrowMode.ASCENDING:
      arrow = (
        <button
          className={redoTableHeaderCellsCss.arrowButton}
          onClick={onSortClick}
        >
          <ArrowUpSvg
            className={classNames(
              redoTableHeaderCellsCss.icon,
              redoTableHeaderCellsCss.arrow,
            )}
          />
        </button>
      );
      break;
    case StandardRedoTableHeaderCellArrowMode.DESCENDING:
      arrow = (
        <button
          className={redoTableHeaderCellsCss.arrowButton}
          onClick={onSortClick}
        >
          <ArrowDownSvg
            className={classNames(
              redoTableHeaderCellsCss.icon,
              redoTableHeaderCellsCss.arrow,
            )}
          />
        </button>
      );
      break;
    case StandardRedoTableHeaderCellArrowMode.NOT_SET:
    case StandardRedoTableHeaderCellArrowMode.NO_ARROW:
      if (hovered) {
        arrow = (
          <div
            className={classNames(
              redoTableHeaderCellsCss.icon,
              redoTableHeaderCellsCss.arrow,
            )}
          >
            <ArrowDownSvg />
          </div>
        );
      }
      break;
    default:
      assertNever(arrowIcon);
  }
  const text = (
    <Text fontSize="xxs" fontWeight="medium" textColor="tertiary">
      {headerText}
    </Text>
  );

  return (
    <Flex
      align="center"
      className={classNames(redoTableHeaderCellsCss.headerCell)}
      dir="row"
      gap="xs"
      grow="1"
      overflow="hidden"
      p="xl"
    >
      {onSortClick ? (
        <button
          onClick={onSortClick}
          onMouseEnter={onMouseEnters}
          onMouseLeave={onMouseLeaves}
        >
          {text}
        </button>
      ) : (
        <>{text}</>
      )}
      {helpIconTooltip && (
        <Tooltip title={helpIconTooltip}>
          <div
            className={classNames(
              redoTableHeaderCellsCss.icon,
              redoTableHeaderCellsCss.help,
            )}
          >
            <HelpCircleSvg />
          </div>
        </Tooltip>
      )}
      {arrow}
    </Flex>
  );
};

export const RedoTableCheckboxHeaderCell = ({
  numRows,
  rowsSelected,
  setRowsSelected,
}: {
  numRows: number;
  rowsSelected: boolean[];
  setRowsSelected: (value: boolean[]) => void;
}) => {
  return (
    <Flex
      align="center"
      className={classNames(redoTableHeaderCellsCss.headerCell)}
      justify="center"
      p="xl"
    >
      <RedoCheckbox
        setValue={(value) => {
          const truthiness = value === "indeterminate" ? false : value;
          setRowsSelected(Array.from({ length: numRows }, () => truthiness));
        }}
        size={RedoCheckboxSize.SMALL}
        value={rowsSelected.every((value) => value)}
      />
    </Flex>
  );
};

export function EmptyHeaderCell() {
  return <span className={classNames(redoTableHeaderCellsCss.headerCell)} />;
}
