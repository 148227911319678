import ArrowUpRightIcon from "@redotech/redo-web/arbiter-icon/arrow-up-right_filled.svg";
import { Text } from "../../text";
import { TextSizeValue } from "../../theme/typography";
import * as containerCss from "./redo-external-link.module.css";
export function RedoExternalLink({
  url,
  text,
  showIcon = true,
  fontSize = "xs",
}: {
  url?: string | undefined;
  text?: string;
  fontSize?: TextSizeValue;
  showIcon?: boolean;
  newTab?: boolean;
  eyeIcon?: boolean;
}) {
  return (
    <a
      className={containerCss.container}
      href={url}
      rel="noreferrer"
      target="_blank"
    >
      <Text
        className={containerCss.text}
        fontSize={fontSize}
        fontWeight="regular"
      >
        {text}
      </Text>
      {showIcon && <ArrowUpRightIcon className={containerCss.icon} />}
    </a>
  );
}
