import { Popper } from "@mui/material";
import { FulfillmentGroupStatus } from "@redotech/redo-model/fulfillments/fulfillment-group-status";
import { FulfillmentOrderLineItem } from "@redotech/redo-model/fulfillments/fulfillment-order-line-item";
import OverflowIcon from "@redotech/redo-web/arbiter-icon/layers-two-01.svg";
import { ButtonSize, IconButton } from "@redotech/redo-web/button";
import { Flex } from "@redotech/redo-web/flex";
import { Text } from "@redotech/redo-web/text";
import { memo, useContext, useRef, useState } from "react";
import { TeamContext } from "../../../app/team";
import { ItemsTable } from "../../common/items-table";
import * as itemNameCellCss from "./item-name-cell.module.css";

export const ItemNameCell = memo(function ItemNameCell({
  lineItems,
}: {
  lineItems: FulfillmentOrderLineItem[];
}) {
  const team = useContext(TeamContext);
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const [dropdownAnchor, setDropdownAnchor] = useState<HTMLElement | null>(
    null,
  );
  const timeoutRef = useRef<NodeJS.Timeout>();

  if (lineItems.length === 0) {
    return null;
  }

  return (
    <>
      <Flex
        align="center"
        dir="row"
        justify="space-between"
        onMouseEnter={() => {
          timeoutRef.current = setTimeout(() => {
            setIsDropdownOpen(true);
          }, 700);
        }}
        onMouseLeave={() => {
          if (timeoutRef.current) {
            clearTimeout(timeoutRef.current);
          }
          setIsDropdownOpen(false);
        }}
        p="lg"
        ref={setDropdownAnchor}
      >
        <Text fontSize="xs" textColor="secondary">
          {lineItems.length > 1
            ? `${lineItems.length} items`
            : lineItems[0].title}
        </Text>
        <IconButton disabled size={ButtonSize.SMALL}>
          <OverflowIcon />
        </IconButton>
      </Flex>
      <Popper anchorEl={dropdownAnchor} open={isDropdownOpen}>
        <div className={itemNameCellCss.dropdown}>
          <ItemsTable
            disableTooltip
            items={lineItems}
            selectedStatus={FulfillmentGroupStatus.Open}
            team={team}
          />
        </div>
      </Popper>
    </>
  );
});
