import { FulfillmentOrderData } from "@redotech/redo-model/fulfillments/fulfillment-group";
import { ColumnAlignment } from "@redotech/redo-web/table";
import { Column2 } from "@redotech/redo-web/table2";
import { StringListCell } from "../cells/string-list-cell";

export const ItemSKUColumn: Column2<FulfillmentOrderData> = {
  key: "item-sku",
  title: "Item SKU",
  renderNormalCell: ({ row }) => {
    const skus = row.items.map((item) => item.sku ?? "");
    return <StringListCell displayName="item SKU" strings={skus} />;
  },
  width: 80,
  alignment: ColumnAlignment.LEFT,
};
