import { array, date, nativeEnum, object, string, union, z } from "zod";
import { zExt } from "../../common/zod-util";
import { BillingStatus } from "../concierge-conversation-message-definition";
import { conciergeConversationMessageSchema } from "./message";
import { contextSchema } from "./product-details";

export const conciergeConversationSchema = object({
  _id: zExt.objectId(),
  team: zExt.objectId(),
  email: string().optional(),
  context: contextSchema.optional(),
  messages: array(conciergeConversationMessageSchema),
  lastMessageCreatedAt: date(),
  createdAt: date(),
  updatedAt: date(),
  billingStatus: nativeEnum(BillingStatus).optional(),
  categories: array(string()).optional(),
  /**
   * @deprecated
   */
  convertedToSupportConversation: z.boolean().optional(),
  convertedToSupport: z
    .object({
      converted: z.boolean(),
      supportConversationId: zExt.objectId().optional(),
    })
    .optional(),
});
export type ConciergeConversation = z.infer<typeof conciergeConversationSchema>;

export const getConciergeResponseSchema = {
  input: object({
    conciergeConversationId: string().optional(),
    initialConciergeMessages: array(string()).optional(),
    message: string(),
    currentProductInfo: object({
      productId: string(),
      productName: string(),
      variantId: string().optional(),
    }).optional(),
  }),
  output: object({
    messages: array(conciergeConversationMessageSchema),
    newConversationBeforeResponse: union([
      conciergeConversationSchema,
      z.null(),
    ]),
  }),
};
export type GetConciergeResponseInput = z.infer<
  typeof getConciergeResponseSchema.input
>;
export type GetConciergeResponseOutput = z.infer<
  typeof getConciergeResponseSchema.output
>;

export const conciergeConversationListItemSchema = object({
  _id: zExt.objectId(),
  recentMessageText: string(),
  lastMessageCreatedAt: date(),
});

export type ConciergeConversationListItem = z.infer<
  typeof conciergeConversationListItemSchema
>;

export function isConciergeConversationListItem(
  chatInfo: any,
): chatInfo is ConciergeConversationListItem {
  return conciergeConversationListItemSchema.safeParse(chatInfo).success;
}
