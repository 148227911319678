import { useRequiredContext } from "@redotech/react-util/context";
import { useLoad } from "@redotech/react-util/load";
import { CampaignWithCustomerGroups } from "@redotech/redo-model/campaigns/campaign-definition";
import {
  RedoBadge,
  RedoBadgeColor,
  RedoBadgeSize,
} from "@redotech/redo-web/arbiter-components/badge/redo-badge";
import {
  RedoInputSize,
  RedoInputState,
} from "@redotech/redo-web/arbiter-components/input/base-redo-text-input";
import { RedoTextInput } from "@redotech/redo-web/arbiter-components/input/redo-text-input";
import { Flex } from "@redotech/redo-web/flex";
import { Text } from "@redotech/redo-web/text";
import { memo } from "react";
import { RedoMarketingRpcClientContext } from "../../app/redo-marketing-rpc-client-provider";
import { SmsPhone } from "../sms-builder/common/sms-phone";
import { getSmsBuilderDefault } from "../sms-builder/sms-builder-types";

interface SmsSummaryState {}

export const SmsSummaryCard = memo(function SmsSummaryCard({
  campaign,
  editor,
}: {
  campaign?: CampaignWithCustomerGroups | null;
  editor?: {
    state: SmsSummaryState;
    setState: (state: SmsSummaryState) => void;
  };
}) {
  const marketingRpcClient = useRequiredContext(RedoMarketingRpcClientContext);

  const phoneNumberInfoLoad = useLoad(
    async (signal) => {
      return await marketingRpcClient.getTeamPhoneNumber({}, { signal });
    },
    [marketingRpcClient],
  );

  const fromNumber = phoneNumberInfoLoad.value?.number;

  return (
    <Flex
      bgColor="primary"
      borderColor="primary"
      borderStyle="solid"
      borderWidth="1px"
      dir="column"
      p="3xl"
      radius="xl"
    >
      <Text fontSize="sm" fontWeight="semibold">
        {campaign?.finishedAt ? "SMS content" : "SMS preview"}
      </Text>
      <Flex gap="3xl" grow={1}>
        <Flex py="3xl">
          <SmsPhone
            autoShortenLinks={campaign?.smsTemplate?.autoShortenLinks ?? true}
            content={
              campaign?.smsTemplate?.content || getSmsBuilderDefault().content
            }
            imageSrc={campaign?.smsTemplate?.imageSrc}
          />
        </Flex>
        <Flex basis="0" bgColor="primary" dir="column" grow={1} p="6xl">
          <Flex dir="column" gap="xl" grow={1} justify="center">
            <Flex dir="column" gap="xxs">
              <Text fontSize="sm" fontWeight="semibold">
                From Phone Number
              </Text>
              <RedoTextInput
                setValue={() => {}}
                size={RedoInputSize.SMALL}
                state={RedoInputState.READONLY}
                value={fromNumber || ""}
              />
            </Flex>
            <Flex dir="column" gap="xxs">
              <Text fontSize="sm" fontWeight="semibold">
                Included segments
              </Text>
              <Flex gap="xs" wrap="wrap">
                {campaign?.customerGroups?.map((cg) => (
                  <RedoBadge
                    color={RedoBadgeColor.GRAY}
                    key={cg._id}
                    size={RedoBadgeSize.SMALL}
                    text={cg.name}
                  />
                )) || null}
              </Flex>
            </Flex>
            <Flex dir="column" gap="xxs">
              <Text fontSize="sm" fontWeight="semibold">
                {campaign?.finishedAt ? "Recipients" : "Audience"}
              </Text>
              <RedoTextInput
                setValue={() => {}}
                size={RedoInputSize.SMALL}
                state={RedoInputState.READONLY}
                value={(
                  campaign?.customerGroups
                    ?.map((cg) => cg.count)
                    .reduce((a, b) => (a || 0) + (b || 0), 0) || 0
                ).toString()}
              />
            </Flex>
            {campaign?.finishedAt && (
              <Flex dir="column" gap="xxs">
                <Text fontSize="sm" fontWeight="semibold">
                  Sent
                </Text>
                <RedoTextInput
                  setValue={() => {}}
                  size={RedoInputSize.SMALL}
                  state={RedoInputState.READONLY}
                  value={campaign.finishedAt
                    .toTemporalInstant()
                    .toLocaleString("en-US", {
                      year: "numeric",
                      month: "short",
                      day: "numeric",
                      hour: "numeric",
                      minute: "2-digit",
                      hour12: true,
                    })}
                />
              </Flex>
            )}
          </Flex>
        </Flex>
      </Flex>
    </Flex>
  );
});
