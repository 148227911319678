import { useHover } from "@redotech/react-util/hover";
import ArrowRightIcon from "@redotech/redo-web/arbiter-icon/arrow-right_filled.svg";
import { formatTimeAgo } from "@redotech/redo-web/date-utils";
import { Flex } from "@redotech/redo-web/flex";
import { Text } from "@redotech/redo-web/text";
import { memo, ReactNode, useState } from "react";
import * as customerActivityCardCss from "./activity-card.module.css";

export const BaseActivityCard = memo(function BaseActivityCard({
  topLeftElement,
  title,
  timestamp,
  extraDetailsElement,
  onClick,
}: {
  topLeftElement: ReactNode;
  title?: string;
  timestamp: Date;
  extraDetailsElement?: ReactNode | ReactNode[] | undefined;
  onClick: (() => void) | undefined;
}) {
  const [elementRef, setElementRef] = useState<HTMLElement | null>(null);
  const isHovered = useHover(elementRef);

  return (
    <Flex
      className={`${customerActivityCardCss.baseCustomerActivityCardContainer} ${
        isHovered
          ? customerActivityCardCss.baseCustomerActivityCardContainerHovered
          : undefined
      }`}
      dir="column"
      gap="none"
      onClick={onClick}
      p="md"
      ref={setElementRef}
    >
      <Flex align="center" justify="space-between" pb="md">
        {topLeftElement}
        {!!onClick && isHovered ? (
          <Flex
            align="center"
            className={
              customerActivityCardCss.baseCustomerActivityCardArrowRightContainer
            }
            justify="center"
          >
            <ArrowRightIcon
              className={
                customerActivityCardCss.baseCustomerActivityCardArrowRight
              }
            />
          </Flex>
        ) : undefined}
      </Flex>
      {title && (
        <Text fontSize="md" textColor="primary">
          {title}
        </Text>
      )}
      {extraDetailsElement}
      <Text fontSize="xs" textColor="tertiary">
        {formatTimeAgo(timestamp.toISOString())}
      </Text>
    </Flex>
  );
});
