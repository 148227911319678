import { CurrencyCode } from "@redotech/shopify-client/storefront.graphql";
import { defineSchema, SchemaInstance } from "../../type-system/schema";

try {
  // eslint-disable-next-line
  require("temporal-polyfill/global"); // useful for storybook (since it uses webpack not bazel)
} catch (e) {
  // do nothing, this is only for storybook
}

const discountCodeSchema = defineSchema({
  fields: {
    discountCode: {
      dataType: "Maybe Text",
      documentation: "The discount code to shared with the customer.",
    },
  },
});

export const baseMarketingSignupSchema = defineSchema({
  fields: {
    redoCustomerId: {
      dataType: "Metadata",
      documentation: "The Redo customer ID.",
    },
    inferredCustomerName: {
      dataType: "Text",
      documentation:
        'The inferred name of the customer who signed up for marketing messages. When the subscriber is anonymous, it defaults to "Customer".',
    },
    alreadySubscribed: {
      dataType: "Boolean",
      documentation:
        "Whether the customer was already subscribed to marketing messages.",
    },
  },
});

export const emailMarketingSignupSchema = defineSchema({
  fields: {
    ...baseMarketingSignupSchema.fields,
    ...discountCodeSchema.fields,
    unsubscribeLink: {
      dataType: "Maybe Url",
      documentation:
        "The link to unsubscribe from email updates. Only displays when the customer is subscribed",
    },
    oneClickUnsubscribeLink: {
      dataType: "Maybe Url",
      documentation:
        "The link to unsubscribe from email updates with one click for email delivery compliance. Only displays when the customer is subscribed",
    },
    customerEmail: {
      dataType: "Email",
      documentation:
        "The email address of the customer who signed up for marketing emails.",
    },
  },
});

export const baseMarketingSchemaWithoutInteractiveCart = defineSchema({
  fields: {
    redoCustomerId: {
      dataType: "Metadata",
      documentation: "The Redo customer ID.",
    },
    inferredCustomerName: {
      dataType: "Text",
      documentation:
        'The inferred name of the customer. When the subscriber is anonymous, it defaults to "Customer".',
    },
  },
});

export const baseMarketingSchema = defineSchema({
  fields: {
    ...baseMarketingSchemaWithoutInteractiveCart.fields,
    interactiveCart: {
      dataType: "Maybe Interactive Cart", // only gets populated if needed
      documentation: "Product recommendations inside of an interactive cart.",
    },
  },
});

export const baseMarketingEmailSchema = defineSchema({
  fields: {
    ...baseMarketingSchema.fields,
    customerEmail: {
      dataType: "Email",
      documentation: "The email address of the customer.",
    },
    unsubscribeLink: {
      dataType: "Maybe Url",
      documentation:
        "The link to unsubscribe from email updates. Only displays when the customer is subscribed",
    },
    oneClickUnsubscribeLink: {
      dataType: "Maybe Url",
      documentation:
        "The link to unsubscribe from email updates with one click for email delivery compliance. Only displays when the customer is subscribed",
    },
  },
});

const baseAbandonmentSchema = defineSchema({
  fields: {
    ...baseMarketingSchemaWithoutInteractiveCart.fields,
    isAbandonment: {
      dataType: "Metadata",
      documentation: "A flag to indicate that this is an abandonment.",
    },
    unsubscribeLink: {
      dataType: "Maybe Url",
      documentation:
        "The link to unsubscribe from email updates. Only displays when the customer is subscribed",
    },
    oneClickUnsubscribeLink: {
      dataType: "Maybe Url",
      documentation:
        "The link to unsubscribe from email updates with one click for email delivery compliance. Only displays when the customer is subscribed",
    },
  },
});

export const baseMarketingCartAbandomentSchema = defineSchema({
  fields: {
    ...baseAbandonmentSchema.fields,
    isAbandonment: {
      dataType: "Metadata",
      documentation: "A flag to indicate that this is an abandonment.",
    },
    time: {
      dataType: "DateTime",
      documentation: "The time the last item was added to the cart.",
    },
    isCartAbandoned: {
      dataType: "Boolean",
      documentation:
        "If the customer has not begun the checkout process or checked out since updating their cart.",
    },
    customerEmail: {
      dataType: "Maybe Email",
      documentation:
        "The email address of the customer who abandoned the cart.",
    },
    customerPhone: {
      dataType: "Maybe Phone",
      documentation: "The phone number of the customer who abandoned the cart.",
    },
    interactiveCart: {
      dataType: "Maybe Interactive Cart",
      documentation: "The cart that was abandoned.",
    },
  },
});

export const baseMarketingBrowseAbandomentSchema = defineSchema({
  fields: {
    ...baseAbandonmentSchema.fields,
    isAbandonment: {
      dataType: "Metadata",
      documentation: "A flag to indicate that this is an abandonment.",
    },
    time: {
      dataType: "DateTime",
      documentation: "When the browsing last occurred.",
    },
    isBrowseAbandoned: {
      dataType: "Boolean",
      documentation:
        "If the customer has not added anything to the cart or placed an order since browsing.",
    },
    customerEmail: {
      dataType: "Email",
      documentation:
        "The email address of the customer who abandoned their browsing session.",
    },
    customerPhone: {
      dataType: "Maybe Phone",
      documentation:
        "The phone number of the customer who abandoned their browsing session.",
    },
    browseAbandonmentData: {
      dataType: "Browse Abandonment",
      documentation: "The product variants viewed by the customer.",
    },
    interactiveCart: {
      dataType: "Maybe Interactive Cart", // only gets populated if needed
      documentation: "Product recommendations inside of an interactive cart.",
    },
  },
});

const dummyInteractiveCart = (generatedByRedo: boolean) => ({
  cart: {
    id: "preview",
    apiUrl: "https://example.com/api/cart",
    widgetSlug: "widget-slug",
    teamId: "team-id",
    storeName: "Example Store",
    storeUrl: "https://example.com",
    checkoutUrl: "https://example.com/checkout",
    generatedByRedo,
    productIdsForRecommendations: [],
    productIdsAlreadyInCart: [],
  },
});

const exampleBaseMarketingSchemaInstance: SchemaInstance<
  typeof baseMarketingSchema
> = {
  redoCustomerId: "abc123",
  inferredCustomerName: "Customer",
  interactiveCart: dummyInteractiveCart(true),
};

export const baseMarketingCheckoutAbandonmentSchema = defineSchema({
  fields: {
    ...baseAbandonmentSchema.fields,
    isAbandonment: {
      dataType: "Metadata",
      documentation: "A flag to indicate that this is an abandonment.",
    },
    time: {
      dataType: "DateTime",
      documentation: "When the last customer action on checkout occurred.",
    },
    isCheckoutAbandoned: {
      dataType: "Boolean",
      documentation:
        "If the customer has not placed an order since starting checkout.",
    },
    customerEmail: {
      dataType: "Maybe Email",
      documentation:
        "The email address of the customer who abandoned their browsing session.",
    },
    customerPhone: {
      dataType: "Maybe Phone",
      documentation:
        "The phone number of the customer who abandoned their browsing session.",
    },
    checkout: {
      dataType: "Shopify Checkout",
      documentation: "Data about the customer's checkout session",
    },
    interactiveCart: {
      dataType: "Maybe Interactive Cart",
      documentation: "The cart that was abandoned.",
    },
  },
});

const exampleAbandonmentSchemaInstance: SchemaInstance<
  typeof baseAbandonmentSchema
> = {
  ...exampleBaseMarketingSchemaInstance,
  isAbandonment: true,
  unsubscribeLink: "https://example.com/unsubscribe",
  oneClickUnsubscribeLink: "https://example.com/one-click-unsubscribe",
};

export const exampleBaseMarketingCheckoutAbandonmentSchemaInstance: SchemaInstance<
  typeof baseMarketingCheckoutAbandonmentSchema
> = {
  ...exampleAbandonmentSchemaInstance,
  isAbandonment: true,
  time: Temporal.Now.instant(),
  isCheckoutAbandoned: true,
  customerEmail: "john.doe@example.com",
  customerPhone: "+1234567890",
  checkout: {
    attributes: [],
    billingAddress: null,
    currencyCode: CurrencyCode.Usd,
    discountApplications: [],
    email: "john.doe@example.com",
    lineItems: [],
    order: null,
    shippingAddress: null,
    shippingLine: null,
    subtotalPrice: { amount: 100, currencyCode: CurrencyCode.Usd },
    phone: "+1234567890",
    token: "",
    totalPrice: { amount: 105, currencyCode: CurrencyCode.Usd },
    totalTax: { amount: 5, currencyCode: CurrencyCode.Usd },
    transactions: [],
  },
  interactiveCart: dummyInteractiveCart(false),
};

export const exampleBaseMarketingBrowseAbandomentSchemaInstance: SchemaInstance<
  typeof baseMarketingBrowseAbandomentSchema
> = {
  ...exampleAbandonmentSchemaInstance,
  customerEmail: "john.doe@example.com",
  customerPhone: "+1234567890",
  isBrowseAbandoned: true,
  isAbandonment: true,
  time: Temporal.Now.instant(),
  browseAbandonmentData: {
    viewedProducts: [
      {
        id: "123",
        image: null,
        price: { amount: 100, currencyCode: CurrencyCode.Usd },
        product: {
          id: "123",
          title: "T-Shirt",
          type: null,
          untranslatedTitle: "T-Shirt",
          url: "",
          vendor: "",
        },
        sku: "123",
        title: "Red T-Shirt",
        untranslatedTitle: "Red T-Shirt",
      },
    ],
  },
  interactiveCart: dummyInteractiveCart(false),
};

export const exampleBaseMarketingCartAbandomentSchemaInstance: SchemaInstance<
  typeof baseMarketingCartAbandomentSchema
> = {
  ...exampleAbandonmentSchemaInstance,
  customerEmail: "john.doe@example.com",
  customerPhone: "+1234567890",
  isCartAbandoned: true,
  isAbandonment: true,
  time: Temporal.Now.instant(),
  interactiveCart: dummyInteractiveCart(false),
};

export const exampleBaseMarketingEmailSchemaInstance: SchemaInstance<
  typeof baseMarketingEmailSchema
> = {
  redoCustomerId: "abc123",
  inferredCustomerName: "Customer",
  customerEmail: "john@fake.com",
  unsubscribeLink: "https://example.com/unsubscribe",
  oneClickUnsubscribeLink: "https://example.com/one-click-unsubscribe",
  interactiveCart: dummyInteractiveCart(true),
};

export const baseMarketingSmsSchema = defineSchema({
  fields: {
    ...baseMarketingSchemaWithoutInteractiveCart.fields,
    customerPhoneNumber: {
      dataType: "Phone",
      documentation: "The phone number of the customer.",
    },
    customer: { dataType: "Customer", documentation: "The customer object." },
  },
});

const exampleBaseMarketingSignupSchema: SchemaInstance<
  typeof baseMarketingSignupSchema
> = {
  redoCustomerId: "abc123",
  inferredCustomerName: "Customer",
  alreadySubscribed: false,
};

export const exampleEmailMarketingSignupSchema: SchemaInstance<
  typeof emailMarketingSignupSchema
> = {
  ...exampleBaseMarketingSignupSchema,
  discountCode: "EMAILABCD",
  customerEmail: "john@fake.com",
  unsubscribeLink: "https://example.com/unsubscribe",
  oneClickUnsubscribeLink: "https://example.com/one-click-unsubscribe",
};

export const smsMarketingSignupSchema = defineSchema({
  fields: {
    ...baseMarketingSchemaWithoutInteractiveCart.fields,
    customerPhoneNumber: {
      dataType: "Phone",
      documentation:
        "The phone number of the customer who signed up for marketing SMS.",
    },
  },
});

export const exampleSmsMarketingSignupSchema: SchemaInstance<
  typeof smsMarketingSignupSchema
> = { ...exampleBaseMarketingSignupSchema, customerPhoneNumber: "+1234567890" };

export const smsMarketingConfirmedSchema = defineSchema({
  fields: {
    ...baseMarketingSignupSchema.fields,
    ...discountCodeSchema.fields,
    customerPhoneNumber: {
      dataType: "Phone",
      documentation:
        "The phone number of the customer who confirmed their marketing SMS subscription.",
    },
  },
});

export const exampleSmsMarketingConfirmedSchema: SchemaInstance<
  typeof smsMarketingConfirmedSchema
> = {
  ...exampleBaseMarketingSignupSchema,
  discountCode: "SMSABCD",
  customerPhoneNumber: "+1234567890",
};
