import type {
  MerchantAppReturn,
  NeedsActionCounts,
  ReturnAddress,
  ReturnType,
  ReturnsFacets,
} from "@redotech/redo-model/return";
import { DispositionFilter } from "@redotech/redo-model/return-search-filters";
import { Activity } from "@redotech/redo-model/returns/activity";
import { GetUser } from "@redotech/redo-model/user";
import { AxiosHeaders } from "axios";
import { RedoMerchantClient } from ".";
import { REDO_MERCHANT_SERVER_URL } from "../config";
import { ApprovalProductInfo } from "../return/return-modals/approve-modal";
import { AllLabelsValue } from "../return/return-modals/create-labels-modal";
import { ReturnWithYofi } from "../return/returns";

/**
 * POST /returns/:return_id/update-new-order-address
 */
export async function updateNewOrderAddress(
  client: RedoMerchantClient,
  {
    returnId,
    first_name,
    last_name,
    address1,
    address2,
    city,
    province_code,
    zip,
    country_code,
    signal,
  }: {
    returnId: string;
    first_name: string;
    last_name: string;
    address1: string;
    address2?: string;
    city: string;
    province_code?: string;
    zip: string;
    country_code: string;
    signal?: AbortSignal;
  },
): Promise<void> {
  await client.client.post(
    `returns/${encodeURIComponent(returnId)}/update-new-order-address`,
    {
      first_name,
      last_name,
      address1,
      address2,
      city,
      province_code,
      zip,
      country_code,
    },
    { headers: client.authorization(), signal },
  );
}

/**
 * POST /returns/:return_id/approve/:product_id
 */
export async function approveReturn(
  client: RedoMerchantClient,
  {
    returnId,
    customerNotes,
    orderTags,
    products,
    signal,
    selectedAddress,
    multipleLabels,
  }: {
    customerNotes?: string;
    returnId: string;
    orderTags?: readonly string[];
    products: ApprovalProductInfo[];
    signal?: AbortSignal;
    selectedAddress?: ReturnAddress;
    multipleLabels?: AllLabelsValue;
  },
): Promise<void> {
  // add to this
  await client.client.post(
    `returns/${encodeURIComponent(returnId)}/approve`,
    { customerNotes, products, selectedAddress, orderTags, multipleLabels },
    { headers: client.authorization(), signal },
  );
}

/**
 * POST /returns/:return_id/close
 */
export async function closeReturn(
  client: RedoMerchantClient,
  { returnId, signal }: { returnId: string; signal?: AbortSignal },
): Promise<void> {
  await client.client.post(
    `returns/${encodeURIComponent(returnId)}/close`,
    {},
    { headers: client.authorization(), signal },
  );
}

/**
 * POST /returns/:return_id/reapprove
 */
export async function reapproveReturn(
  client: RedoMerchantClient,
  {
    returnId,
    customerNotes,
    orderTags,
    products,
    signal,
    selectedAddress,
    multipleLabels,
  }: {
    returnId: string;
    customerNotes?: string;
    orderTags?: readonly string[];
    products: ApprovalProductInfo[];
    signal?: AbortSignal;
    selectedAddress?: ReturnAddress;
    multipleLabels?: AllLabelsValue;
  },
): Promise<void> {
  await client.client.post(
    `returns/${encodeURIComponent(returnId)}/reapprove`,
    { customerNotes, products, selectedAddress, orderTags, multipleLabels },
    { headers: client.authorization(), signal },
  );
}

/**
 * POST /returns/:return_id/reopen
 */
export async function reopenReturn(
  client: RedoMerchantClient,
  { returnId, signal }: { returnId: string; signal?: AbortSignal },
): Promise<void> {
  await client.client.post(
    `returns/${encodeURIComponent(returnId)}/reopen`,
    {},
    { headers: client.authorization(), signal },
  );
}

/**
 * POST /returns/:return_id/reset
 */
export async function resetReturn(
  client: RedoMerchantClient,
  {
    returnId,
    step,
    signal,
  }: { returnId: string; step: number; signal?: AbortSignal },
): Promise<void> {
  await client.client.post(
    `returns/${encodeURIComponent(returnId)}/reset`,
    { step },
    { headers: client.authorization(), signal },
  );
}

/**
 * POST /returns/:return_id/reject
 */
export async function disapproveReturn(
  client: RedoMerchantClient,
  {
    returnId,
    customerNotes,
    signal,
    user,
  }: {
    customerNotes?: string;
    returnId: string;
    signal?: AbortSignal;
    user?: GetUser;
  },
): Promise<void> {
  await client.client.post(
    `returns/${encodeURIComponent(returnId)}/reject`,
    { customerNotes, user },
    { headers: client.authorization(), signal },
  );
}

/**
 * POST /returns/:return_id/cancel
 */
export async function cancelReturn(
  client: RedoMerchantClient,
  {
    returnId,
    customerRecoveryAmount = 0,
    signal,
  }: { returnId: string; customerRecoveryAmount: number; signal?: AbortSignal },
): Promise<void> {
  await client.client.post(
    `returns/${encodeURIComponent(returnId)}/cancel`,
    { customerRecoveryAmount },
    { headers: client.authorization(), signal },
  );
}

/**
 * POST /returns/:return_id/flag
 */
export async function flagReturn(
  client: RedoMerchantClient,
  { returnId, signal }: { returnId: string; signal?: AbortSignal },
): Promise<void> {
  await client.client.post(
    `returns/${encodeURIComponent(returnId)}/flag`,
    {},
    { headers: client.authorization(), signal },
  );
}

/**
 * POST /returns/:return_id/unflag
 */
export async function unflagReturn(
  client: RedoMerchantClient,
  { returnId, signal }: { returnId: string; signal?: AbortSignal },
): Promise<void> {
  await client.client.post(
    `returns/${encodeURIComponent(returnId)}/unflag`,
    {},
    { headers: client.authorization(), signal },
  );
}

/**
 * GET /returns/:id
 */
export async function getReturn(
  client: RedoMerchantClient,
  { id, signal }: { id: string; signal?: AbortSignal },
): Promise<MerchantAppReturn> {
  const response = await client.client.get(
    `returns/${encodeURIComponent(id)}`,
    { headers: client.authorization(), signal },
  );
  return response.data;
}

/**
 * PUT /returns/:id/exchange-order
 */
export async function updateExchangeOrder(
  client: RedoMerchantClient,
  {
    id,
    newVariantIds,
    signal,
  }: { id: string; newVariantIds: string[]; signal?: AbortSignal },
): Promise<any> {
  return await client.client.put(
    `returns/${encodeURIComponent(id)}/exchange-order`,
    { newVariantIds },
    { headers: client.authorization(), signal },
  );
}

/**
 * POST /returns/:return_id/products/:product_id/return-value
 */
export async function changeProductReturnValue(
  client: RedoMerchantClient,
  {
    returnId,
    productId,
    newValue,
    signal,
  }: {
    returnId: string;
    productId: string;
    newValue: number;
    signal?: AbortSignal;
  },
) {
  await client.client.post(
    `returns/${encodeURIComponent(returnId)}/products/${encodeURIComponent(
      productId,
    )}/return-value`,
    { newValue },
    { headers: client.authorization(), signal },
  );
}

/**
 * POST /returns/:return_id/products/:product_id/return-type
 */
export async function changeProductReturnType(
  client: RedoMerchantClient,
  {
    returnId,
    productId,
    newType,
    variantId,
    newItemVariantIds,
    signal,
  }: {
    returnId: string;
    productId: string;
    newType: string;
    variantId?: string;
    newItemVariantIds?: string[];
    signal?: AbortSignal;
  },
) {
  await client.client.post(
    `returns/${encodeURIComponent(returnId)}/products/${encodeURIComponent(
      productId,
    )}/return-type`,
    { newType, exchangeForVariantId: variantId, newItemVariantIds },
    { headers: client.authorization(), signal },
  );
}

/**
 * GET /returns/:return_id/yofi-jwt
 */
export async function getYofiJwt(
  client: RedoMerchantClient,
  { signal }: { signal?: AbortSignal },
): Promise<string> {
  const response = await client.client.get(`yofi-jwt`, {
    headers: client.authorization(),
    signal,
  });
  return response.data;
}

/**
 * GET /returns
 */
export async function getReturns(
  client: RedoMerchantClient,
  {
    status,
    pageContinue,
    pageSize = 20,
    signal,
    search,
    sort,
    dateRange,
    returnType,
    barcode,
    type = "return",
    email,
    dispositionFilter,
  }: {
    status?: string;
    pageContinue?: string;
    pageSize?: number;
    sort?: { key: string; direction: string };
    signal?: AbortSignal;
    search?: string;
    dateRange?: string;
    returnType?: string;
    type?: ReturnType;
    barcode?: string;
    email?: string;
    page?: number;
    dispositionFilter?: DispositionFilter;
  },
  // ReturnWithYofi is an extension of Return to not side-effect the original Return type
): Promise<{ data: ReturnWithYofi[]; pageNext: string | undefined }> {
  const response = await client.client.get("returns", {
    headers: {
      ...client.authorization(),
      "X-Page-Continue": pageContinue,
      "X-Page-Size": pageSize,
    },
    params: {
      status,
      search,
      sort: sort && `${sort.key}:${sort.direction}`,
      dateRange,
      returnType,
      type,
      barcode,
      email,
      dispositionFilter,
    },
    signal,
  });
  return {
    data: response.data,
    pageNext:
      <string>(<AxiosHeaders>response.headers).get("X-Page-Next") || undefined,
  };
}

/**
 * GET /returns/facets
 */
export async function getReturnsFacets(
  client: RedoMerchantClient,
  {
    search,
    dateRange,
    returnType,
    type = "return",
    email,
    signal,
  }: {
    search?: string;
    returnType?: string;
    dateRange?: string;
    type?: "return" | "claim" | "warranty" | "managed_claim";
    email?: string;
    signal?: AbortSignal;
  },
): Promise<ReturnsFacets> {
  const response = await client.client.get(`returns/facets`, {
    headers: client.authorization(),
    params: { search, type, returnType, dateRange, email },
    signal,
  });
  return response.data;
}

/**
 * GET /returns/needs-action-count
 */
export async function getReturnsNeedingActionCount(
  client: RedoMerchantClient,
  { signal }: { signal?: AbortSignal },
): Promise<NeedsActionCounts> {
  const response = await client.client.get(`returns/needs-action-count`, {
    headers: client.authorization(),
    signal,
  });

  return response.data;
}

/**
 * POST /returns/:return_id/process
 */
export async function processReturn(
  client: RedoMerchantClient,
  {
    returnId,
    signal,
    products,
    customerRecoveryAmount = 0,
    options,
    user,
    customerNotes,
    orderTags,
  }: {
    returnId: string;
    products: {
      _id: string;
      refund: number;
      restock: boolean;
      merchantGrade?: string;
      merchantOutcome?: string;
      merchantNotes?: string;
    }[];
    customerRecoveryAmount: number;
    options: {
      sendNotifications: boolean;
      refundOriginalShipping: boolean;
      refundInstantExchangeRecoveryFee: boolean;
      waiveDraftFee?: boolean;
    };
    signal?: AbortSignal;
    user?: GetUser;
    customerNotes?: string;
    orderTags?: readonly string[];
  },
): Promise<void> {
  await client.client.post(
    `returns/${encodeURIComponent(returnId)}/process`,
    {
      products,
      options,
      user,
      customerRecoveryAmount,
      customerNotes,
      orderTags,
    },
    { headers: client.authorization(), signal },
  );
}

/**
 * PUT /returns/:return_id/resend-invoice
 */
export async function resendInvoiceEmail(
  client: RedoMerchantClient,
  {
    returnId,
    signal,
    emailOverride,
  }: { returnId: string; signal?: AbortSignal; emailOverride?: string },
): Promise<void> {
  await client.client.put(
    `returns/${encodeURIComponent(returnId)}/resend-invoice`,
    { emailOverride },
    { headers: client.authorization(), signal },
  );
}

/**
 * PUT /returns/:return_id/filed-with-carrier
 */
export async function updateFiledWithCarrier(
  client: RedoMerchantClient,
  {
    returnId,
    filedWithCarrier,
    signal,
  }: { returnId: string; filedWithCarrier: boolean; signal?: AbortSignal },
): Promise<void> {
  await client.client.put(
    `returns/${encodeURIComponent(returnId)}/filed-with-carrier`,
    { filedWithCarrier },
    { headers: client.authorization(), signal },
  );
}

/**
 * GET /returns/:return_id/activity-stream
 * Returns a stream that notifies changes to individual users in a return (DOES NOT RETURN ALL THE ACTIVITIES OF A RETURN)
 */
export async function activitiesStream(
  client: RedoMerchantClient,
  returnId: string,
  signal: AbortSignal,
) {
  //I couldn't figure out a way to use client.client with streams
  //See /redo/merchant-app/src/support/utils -> getConversationsStream for Math.random() explanation
  const url = `${REDO_MERCHANT_SERVER_URL}/returns/${encodeURIComponent(returnId)}/activity-stream?rand=${Math.random()}`;
  return fetch(url, {
    signal,
    headers: client.authorization() as { Authorization: string },
  });
}

/**
 * POST returns/:return_id/return-activity
 */

export async function updateReturnActivity(
  client: RedoMerchantClient,
  returnId: string,
): Promise<void> {
  await client.client.post(
    `returns/${encodeURIComponent(returnId)}/return-activity`,
    {},
    { headers: client.authorization() },
  );
}

/**
 * GET returns/:return_id/activities
 */

export async function getActivitiesForReturn(
  client: RedoMerchantClient,
  returnId: string,
): Promise<Activity[]> {
  return (
    await client.client.get(
      `returns/${encodeURIComponent(returnId)}/activities`,
      { headers: client.authorization() },
    )
  ).data;
}
