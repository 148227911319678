import { Fade } from "@mui/material";
import {
  RedoButton,
  RedoButtonHierarchy,
  RedoButtonSize,
} from "@redotech/redo-web/arbiter-components/buttons/redo-button";
import XIcon from "@redotech/redo-web/arbiter-icon/x-close.svg";
import { Flex } from "@redotech/redo-web/flex";
import { Text } from "@redotech/redo-web/text";
import {
  createContext,
  memo,
  ReactNode,
  useContext,
  useEffect,
  useRef,
  useState,
} from "react";
import * as alertCss from "./alert.module.css";

export const AlertContext = createContext({
  currentAlerts: [] as { id: string; element: ReactNode }[],
  addNewAlert: ({
    title,
    type,
    primaryButtonText,
    primaryButtonAction,
    primaryButtonCancelsAction,
    secondaryButtonText,
    secondaryButtonAction,
    secondaryButtonCancelsAction,
    description,
    canceledDescription,
    secondsToLive,
    actionCallback,
  }: {
    title: string;
    type: string;
    primaryButtonText?: string;
    primaryButtonAction?: () => void;
    primaryButtonCancelsAction?: boolean;
    secondaryButtonText?: string;
    secondaryButtonAction?: () => void;
    secondaryButtonCancelsAction?: boolean;
    description?: string;
    canceledDescription?: string;
    secondsToLive?: number;
    actionCallback?: () => void;
  }) => {},
});

export const AlertContextProvider = ({ children }: { children: ReactNode }) => {
  const [currentAlerts, setCurrentAlerts] = useState<
    { id: string; element: ReactNode }[]
  >([]);

  const handleRemove = (
    id: string,
    completeAction: boolean,
    actionCallback?: () => void,
  ) => {
    setCurrentAlerts((currentAlerts) =>
      currentAlerts.filter((alert) => alert.id !== id),
    );
    actionCallback && completeAction && actionCallback();
  };

  const addNewAlert = ({
    title,
    type,
    primaryButtonText,
    primaryButtonAction,
    primaryButtonCancelsAction,
    secondaryButtonText,
    secondaryButtonAction,
    secondaryButtonCancelsAction,
    description,
    canceledDescription,
    secondsToLive,
    actionCallback,
  }: {
    title: string;
    type: string;
    primaryButtonText?: string;
    primaryButtonAction?: () => void;
    primaryButtonCancelsAction?: boolean;
    secondaryButtonText?: string;
    secondaryButtonAction?: () => void;
    secondaryButtonCancelsAction?: boolean;
    description?: string;
    canceledDescription?: string;
    secondsToLive?: number;
    actionCallback?: () => void;
  }) => {
    const id = new Date().getTime().toString();
    const newAlert = (
      <Alert
        actionCallback={actionCallback}
        canceledDescription={canceledDescription}
        description={description}
        handleRemove={handleRemove}
        id={id}
        primaryButtonAction={primaryButtonAction}
        primaryButtonCancelsAction={primaryButtonCancelsAction}
        primaryButtonText={primaryButtonText}
        secondaryButtonAction={secondaryButtonAction}
        secondaryButtonCancelsAction={secondaryButtonCancelsAction}
        secondaryButtonText={secondaryButtonText}
        secondsToLive={secondsToLive}
        title={title}
        type={type}
      />
    );
    setCurrentAlerts([...currentAlerts, { id, element: newAlert }]);
  };

  return (
    <AlertContext.Provider value={{ currentAlerts, addNewAlert }}>
      {children}
    </AlertContext.Provider>
  );
};

const Alert = memo(function Alert({
  id,
  title,
  handleRemove,
  secondsToLive,
  type = "info",
  primaryButtonText,
  primaryButtonAction,
  primaryButtonCancelsAction,
  secondaryButtonText,
  secondaryButtonAction,
  secondaryButtonCancelsAction,
  description,
  canceledDescription,
  actionCallback,
}: {
  id: string;
  title: string;
  handleRemove: (
    id: string,
    completeAction: boolean,
    actionCallback?: () => void,
  ) => void;
  secondsToLive?: number;
  type: string;
  primaryButtonText?: string;
  primaryButtonAction?: () => void;
  primaryButtonCancelsAction?: boolean;
  secondaryButtonText?: string;
  secondaryButtonAction?: () => void;
  secondaryButtonCancelsAction?: boolean;
  description?: string;
  canceledDescription?: string;
  actionCallback?: () => void;
}) {
  const progressBarRef = useRef<HTMLDivElement | null>(null);
  const cardRef = useRef<HTMLDivElement | null>(null);
  const [completeAction, setCompleteAction] = useState(true);
  const [triggerRemove, setTriggerRemove] = useState(false);

  useEffect(() => {
    if (progressBarRef.current) {
      progressBarRef.current.style.setProperty(
        "--duration",
        secondsToLive?.toString() || "10",
      );
    }
  }, [progressBarRef.current]);

  useEffect(() => {
    if (cardRef.current && secondsToLive) {
      cardRef.current.style.setProperty(
        "--delay",
        (secondsToLive - 0.4).toString(),
      );
    }
  }, [cardRef.current]);

  const doRemove = () => {
    handleRemove(id, completeAction, actionCallback);
  };

  // Remove alert after secondsToLive
  useEffect(() => {
    if (triggerRemove) {
      doRemove();
    }
  }, [triggerRemove]);

  useEffect(() => {
    if (secondsToLive) {
      setTimeout(() => {
        setTriggerRemove(true);
      }, secondsToLive * 1000);
    }
  }, []);

  return (
    <Fade in timeout={500}>
      <div>
        <div className={alertCss.card} ref={cardRef}>
          <Flex dir="column" gap="xl">
            <Flex dir="column" gap="xs">
              <Flex align="center" justify="space-between">
                <Text fontSize="sm" fontWeight="bold">
                  {completeAction === false
                    ? canceledDescription || "Action canceled"
                    : title}
                </Text>
                <RedoButton
                  hierarchy={RedoButtonHierarchy.TERTIARY}
                  IconLeading={XIcon}
                  onClick={doRemove}
                  size={RedoButtonSize.SMALL}
                />
              </Flex>
              {description && completeAction && <Text>{description}</Text>}
            </Flex>
            <Flex>
              {secondaryButtonText &&
                (!secondaryButtonCancelsAction || completeAction) && (
                  <RedoButton
                    hierarchy={RedoButtonHierarchy.SECONDARY}
                    onClick={() => {
                      if (secondaryButtonCancelsAction) {
                        setCompleteAction(false);
                      }
                      secondaryButtonAction && secondaryButtonAction();
                    }}
                    size={RedoButtonSize.SMALL}
                    text={secondaryButtonText}
                  />
                )}
              {primaryButtonText &&
                (!primaryButtonCancelsAction || completeAction) && (
                  <RedoButton
                    hierarchy={RedoButtonHierarchy.PRIMARY}
                    onClick={() => {
                      if (primaryButtonCancelsAction) {
                        setCompleteAction(false);
                      }
                      primaryButtonAction && primaryButtonAction();
                    }}
                    size={RedoButtonSize.SMALL}
                    text={primaryButtonText}
                  />
                )}
            </Flex>
          </Flex>
          <div
            className={
              completeAction === false ? alertCss.hidden : alertCss.progressBar
            }
            id={`${id}-progress`}
            ref={progressBarRef}
          />
        </div>
      </div>
    </Fade>
  );
});

export const AlertContent = memo(function Page() {
  const { currentAlerts } = useContext(AlertContext);

  return (
    <div className={alertCss.alertCards}>
      {currentAlerts.map((alert) => {
        return <div key={alert.id}>{alert.element}</div>;
      })}
    </div>
  );
});
