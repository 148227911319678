// extracted by mini-css-extract-plugin
export var actionButton = "_b8-0";
export var bubble = "_b8-2";
export var bubbleContext = "_b8-6";
export var bubbleItems = "_b8-7";
export var customer = "_b8-3";
export var divider = "_b8-8";
export var fileList = "_b8-9";
export var infoAbove = "_b8-a";
export var internal = "_b8-4";
export var lightActionButton = "_b8-1";
export var link = "_b8-b";
export var merchant = "_b8-5";
export var message = "_b8-c";
export var profilePicture = "_b8-e";
export var quillToolbarContainer = "_b8-f";
export var senderDate = "_b8-g";
export var senderDateCustomer = "_b8-h";
export var senderName = "_b8-d";
export var time = "_b8-i";
export var uploadedImage = "_b8-j";
export var username = "_b8-k";
export var xsmall = "_b8-l";