import { FulfillmentOrderData } from "@redotech/redo-model/fulfillments/fulfillment-group";
import { FulfillmentGroupStatus } from "@redotech/redo-model/fulfillments/fulfillment-group-status";
import { SortDirection } from "@redotech/redo-model/tables/table";
import { RedoTableTextCell } from "@redotech/redo-web/arbiter-components/tables/redo-table-cells";
import { ColumnAlignment } from "@redotech/redo-web/table";
import { Column2 } from "@redotech/redo-web/table2";
import { formatDateString } from "../../utils/outbound-labels-utils";

export const ShippedDateColumn: Column2<FulfillmentOrderData> = {
  alignment: ColumnAlignment.LEFT,
  key: "shipped-date",
  title: "Shipped date",
  renderNormalCell: ({ row }: { row: FulfillmentOrderData }) => (
    <RedoTableTextCell
      text={
        row.status === FulfillmentGroupStatus.Closed
          ? formatDateString(row.shipment?.label?.labelDate?.toISOString())
          : ""
      }
    />
  ),
  width: 120,
  sort: SortDirection.DESC,
};
