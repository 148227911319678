export enum TimeframeConditionType {
  BEFORE_NOW_RELATIVE = "before-now-relative",
  AFTER = "after",
  BEFORE = "before",
  BETWEEN_RELATIVE = "between-relative",
  BETWEEN_DATES = "between-dates",
  BEFORE_RELATIVE = "before-relative",
  ALL_TIME = "all-time",
  ON = "on",
  TODAY = "today",
  AUTOMATION_START = "automation-start",
}

export type TimeframeCondition =
  | BeforeTimeframeCondition
  | AfterTimeframeCondition
  | AllTimeTimeframeCondition
  | BetweenRelativeTimeframeCondition
  | BetweenDatesTimeframeCondition
  | BeforeRelativeTimeframeCondition
  | BeforeNowRelativeTimeframeCondition
  | OnTimeframeCondition
  | TodayTimeframeCondition
  | AutomationStartTimeframeCondition;

export interface BeforeNowRelativeTimeframeCondition {
  type: TimeframeConditionType.BEFORE_NOW_RELATIVE;
  value: number;
  units: TimeframeUnit;
}

export interface OnTimeframeCondition {
  type: TimeframeConditionType.ON;
  date: Date;
}

export interface BeforeRelativeTimeframeCondition {
  type: TimeframeConditionType.BEFORE_RELATIVE;
  value: number;
  units: TimeframeUnit;
}
export interface BeforeTimeframeCondition {
  type: TimeframeConditionType.BEFORE;
  date: Date;
}

export interface AfterTimeframeCondition {
  type: TimeframeConditionType.AFTER;
  date: Date;
}

export interface AllTimeTimeframeCondition {
  type: TimeframeConditionType.ALL_TIME;
}

export interface TodayTimeframeCondition {
  type: TimeframeConditionType.TODAY;
}

export interface AutomationStartTimeframeCondition {
  type: TimeframeConditionType.AUTOMATION_START;
  automationStartTime: Date;
}

export enum TimeframeUnit {
  HOUR = "hour",
  DAY = "day",
  WEEK = "week",
}

export const timeframeUnitToTemporalDurationLike: Record<
  TimeframeUnit,
  keyof Temporal.DurationLike
> = { hour: "hours", day: "days", week: "weeks" };

export interface BetweenRelativeTimeframeCondition {
  type: TimeframeConditionType.BETWEEN_RELATIVE;
  start: number;
  end: number;
  units: TimeframeUnit;
}

export interface BetweenDatesTimeframeCondition {
  type: TimeframeConditionType.BETWEEN_DATES;
  range: [Date, Date];
}
