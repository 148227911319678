import type { Team } from "../team";
import { UtmParameters } from "../utm";
import { PackingSlip } from "./packing-slip-definition";

export namespace PaddingSize {
  export const SMALL = 8;
  export const MEDIUM = 16;
  export const LARGE = 32;
}

export enum FontWeight {
  NORMAL = "normal",
  BOLD = "bold",
}

export type Padding = {
  top: number;
  right: number;
  bottom: number;
  left: number;
};

export enum Alignment {
  LEFT = "left",
  CENTER = "center",
  RIGHT = "right",
}

export enum FontFamily {
  ARIAL = "Arial",
  COURIER_NEW = "Courier New",
  GEORGIA = "Georgia",
  LUCIDA_SANS_UNICODE = "Lucida Sans Unicode",
  TAHOMA = "Tahoma",
  TIMES_NEW_ROMAN = "Times New Roman",
  TREBUCHET_MS = "Trebuchet MS",
  VERDANA = "Verdana",
}

export const FONT_FAMILIES = [
  FontFamily.ARIAL,
  FontFamily.COURIER_NEW,
  FontFamily.GEORGIA,
  FontFamily.LUCIDA_SANS_UNICODE,
  FontFamily.TAHOMA,
  FontFamily.TIMES_NEW_ROMAN,
  FontFamily.TREBUCHET_MS,
  FontFamily.VERDANA,
] as const;

export enum OrderItemSort {
  ORDER_NUMBER = "order-number",
}

export enum OrderItemTableColumnType {
  BIN_NUMBER = "bin-number",
  ITEM_THUMBNAIL = "item-thumbnail",
  ITEM_NAME_AND_DESCRIPTION = "item-name-and-description",
  QUANTITY = "quantity",
  PRICE = "price",
  SKU = "sku",
  UPC = "upc",
  VENDOR_ID = "vendor-id",
}

export type OrderItemTableColumn = {
  type: OrderItemTableColumnType;
  enabled: boolean;
};

export type OrderItemTableColumnData = {
  binNumber: string;
  itemThumbnail: string;
  itemName: string;
  itemDescription: string;
  quantity: number;
  price: number;
  sku: string;
  upc: string;
  vendorId: string;
};

export const orderItemTableColumnTypeToLabel: Record<
  OrderItemTableColumnType,
  string
> = {
  [OrderItemTableColumnType.BIN_NUMBER]: "Bin Number",
  [OrderItemTableColumnType.ITEM_THUMBNAIL]: "Product Image",
  [OrderItemTableColumnType.ITEM_NAME_AND_DESCRIPTION]: "Product Name",
  [OrderItemTableColumnType.QUANTITY]: "Quantity",
  [OrderItemTableColumnType.PRICE]: "Price",
  [OrderItemTableColumnType.SKU]: "SKU",
  [OrderItemTableColumnType.UPC]: "UPC",
  [OrderItemTableColumnType.VENDOR_ID]: "Vendor ID",
};

export enum PackingSlipBlockType {
  IMAGE = "image",
  TEXT = "text",
  SPACER = "spacer",
  LINE = "line",
  COLUMN = "column",
  BILLING_ADDRESS = "billing-address",
  COMPANY_ADDRESS = "company-address",
  CONTACT_INFORMATION = "contact-information",
  //NOTE_TO_CUSTOMER = "note-to-customer", can this not be a text block?
  ORDER_BARCODE = "order-barcode",
  ORDER_DATE = "order-date",
  ORDER_ITEMS = "order-items",
  ORDER_DETAILS = "order-details",
  //OUT_OF_STOCK_ITEMS = "out-of-stock-items", need more definition
  PAYMENT_BREAKDOWN = "payment-breakdown",
  PAYMENT_METHOD = "payment-method",
  //PURCHASE_ORDER_NUMBER = "purchase-order-number", need more definition
  PACKAGE_WEIGHT = "package-weight",
  //PROMOTIONAL_MESSAGE = "promotional-message", can this not be a text block?
  RETURN_POLICY = "return-policy", //assuming this preconfigured
  SHIPPING_ADDRESS = "shipping-address",
  SHIPPING_METHOD = "shipping-method",
  //SPECIAL_INSTRUCTIONS = "special-instructions", can this not be a text block?
  TRACKING_NUMBER = "tracking-number",
}

export namespace Section {
  interface BaseSection {
    blockId: string;
    duplicate?: () => Section;
    sectionPadding: Padding;
  }

  export interface BillingAddress extends BaseSection {
    type: PackingSlipBlockType.BILLING_ADDRESS;
    fontFamily: FontFamily;
    fontSize: number;
    textColor: string;
  }

  export interface Column extends BaseSection {
    type: PackingSlipBlockType.COLUMN;
    columns: SectionWithoutColumn[];
    columnCount: number;
    sectionColor: string;
    gap: number;
  }

  export interface CompanyAddress extends BaseSection {
    type: PackingSlipBlockType.COMPANY_ADDRESS;
    fontFamily: FontFamily;
    fontSize: number;
    textColor: string;
  }

  export interface ContactInformation extends BaseSection {
    type: PackingSlipBlockType.CONTACT_INFORMATION;
    fontFamily: FontFamily;
    fontSize: number;
    textColor: string;
    showName: boolean;
    showAddress: boolean;
    showPhone: boolean;
    showEmail: boolean;
  }

  export interface OrderBarcode extends BaseSection {
    type: PackingSlipBlockType.ORDER_BARCODE;
  }

  export interface OrderDate extends BaseSection {
    type: PackingSlipBlockType.ORDER_DATE;
    fontFamily: FontFamily;
    fontSize: number;
    textColor: string;
  }

  export interface OrderItems extends BaseSection {
    type: PackingSlipBlockType.ORDER_ITEMS;
    sort: OrderItemSort;
    tableColumns: OrderItemTableColumn[];
  }

  export interface OrderDetails extends BaseSection {
    type: PackingSlipBlockType.ORDER_DETAILS;
    showRecipientInformation: boolean;
    showOrderNumber: boolean;
    showOrderDate: boolean;
    showShippingService: boolean;
  }

  export interface PaymentBreakdown extends BaseSection {
    type: PackingSlipBlockType.PAYMENT_BREAKDOWN;
    fontFamily: FontFamily;
    fontSize: number;
    textColor: string;
  }

  export interface PaymentMethod extends BaseSection {
    type: PackingSlipBlockType.PAYMENT_METHOD;
    showSubtotal: boolean;
    showPaymentMethod: boolean;
    showPaymentDate: boolean;
  }

  export interface PackageWeight extends BaseSection {
    type: PackingSlipBlockType.PACKAGE_WEIGHT;
    fontFamily: FontFamily;
    fontSize: number;
    textColor: string;
  }

  export interface ReturnPolicy extends BaseSection {
    type: PackingSlipBlockType.RETURN_POLICY;
    fontFamily: FontFamily;
    fontSize: number;
    textColor: string;
  }

  export interface ShippingAddress extends BaseSection {
    type: PackingSlipBlockType.SHIPPING_ADDRESS;
    fontFamily: FontFamily;
    fontSize: number;
    textColor: string;
  }

  export interface ShippingMethod extends BaseSection {
    type: PackingSlipBlockType.SHIPPING_METHOD;
    fontFamily: FontFamily;
    fontSize: number;
    textColor: string;
  }

  export interface TrackingNumber extends BaseSection {
    type: PackingSlipBlockType.TRACKING_NUMBER;
    fontFamily: FontFamily;
    fontSize: number;
    textColor: string;
  }

  export interface Text extends BaseSection {
    type: PackingSlipBlockType.TEXT;
    textColor: string;
    fontSize: number;
    fontFamily: FontFamily;
    sectionColor: string;
    linkColor: string;
    text: string;
  }

  export interface Line extends BaseSection {
    type: PackingSlipBlockType.LINE;
    color: string;
    sectionColor: string;
  }

  export interface Spacer extends BaseSection {
    type: PackingSlipBlockType.SPACER;
    sectionColor: string;
    height: number;
  }

  export interface Image extends BaseSection {
    type: PackingSlipBlockType.IMAGE;
    imageUrl: string;
    sectionColor: string;
    showCaption: boolean;
    caption?: string;
    altText?: string;
    clickthroughUrl?: string;
    aspectRatio?: number;
  }
}

export type SectionWithoutColumn =
  | Section.Text
  | Section.Line
  | Section.Spacer
  | Section.Image
  | Section.BillingAddress
  | Section.CompanyAddress
  | Section.ContactInformation
  | Section.OrderBarcode
  | Section.OrderDate
  | Section.OrderItems
  | Section.OrderDetails
  | Section.PaymentBreakdown
  | Section.PaymentMethod
  | Section.PackageWeight
  | Section.ReturnPolicy
  | Section.ShippingAddress
  | Section.ShippingMethod
  | Section.TrackingNumber;

export type Section = SectionWithoutColumn | Section.Column;

export interface SharedProps {
  team: Team;
  template: Omit<PackingSlip, "sections" | "team">;
  isBuilder: boolean;
  blockId?: string;
  utm: UtmParameters;
}

export class PackingSlipUtmParameters extends UtmParameters {
  constructor(campaign?: string, contact?: string) {
    super({ source: "redo", medium: "packing-slip", campaign, contact });
  }

  override getSource(): string {
    return super.getSource() ?? "";
  }

  override getMedium(): string {
    return super.getMedium() ?? "";
  }
}

export enum ScreenSize {
  DESKTOP = "desktop",
  MOBILE = "mobile",
}
