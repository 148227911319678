import { SearchByOption } from "@redotech/redo-model/support/voice/voice-types";
import { array, boolean, object, string, z } from "zod";

export const searchTelnyxPhoneNumbers = {
  input: object({
    country: string(),
    type: string(),
    searchBy: z.nativeEnum(SearchByOption),
    searchField: string(),
  }),
  output: object({
    phoneNumbers: array(
      object({
        best_effort: boolean(),
        cost_information: object({
          currency: string(),
          monthly_cost: string(),
          upfront_cost: string(),
        }),
        features: array(object({ name: string() })),
        phone_number: string(),
        phone_number_type: string(),
        quickship: boolean(),
        record_type: string(),
        region_information: array(
          object({ region_name: string(), region_type: string() }),
        ),
        reservable: boolean(),
        vanity_format: string().nullable(),
      }),
    ),
  }),
};

export type SearchTelnyxPhoneNumbersInput = z.infer<
  typeof searchTelnyxPhoneNumbers.input
>;
export type SearchTelnyxPhoneNumbersOutput = z.infer<
  typeof searchTelnyxPhoneNumbers.output
>;
