import { object, string, z } from "zod";

export const purchaseVoiceNumber = {
  input: object({ phoneNumber: string() }),
  output: object({}),
};

export type PurchaseVoiceNumberInput = z.infer<
  typeof purchaseVoiceNumber.input
>;
export type PurchaseVoiceNumberOutput = z.infer<
  typeof purchaseVoiceNumber.output
>;
