import { DateFilterOperator } from "@redotech/redo-model/views/advanced-filters/date-filter";
import { AdvancedFilterType } from "@redotech/redo-model/views/advanced-filters/generic-advanced-filter-data";
import { AdvancedTableFilter } from "@redotech/redo-web/advanced-filters/advanced-filter";
import CalendarIcon from "@redotech/redo-web/arbiter-icon/calendar.svg";

export const CreationDateFilter: AdvancedTableFilter = {
  type: AdvancedFilterType.DATE,
  Icon: CalendarIcon,
  data: {
    name: "createdAt",
    type: AdvancedFilterType.DATE,
    value: null,
    operator: DateFilterOperator.AFTER,
  },
};
