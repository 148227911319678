// extracted by mini-css-extract-plugin
export var accent = "_2s-4";
export var add = "_2s-j";
export var addIcon = "_2s-k";
export var deleteIcon = "_2s-i";
export var disabled = "_2s-9";
export var ellipsis = "_2s-f";
export var error = "_2s-3";
export var form = "_2s-5";
export var hideFocus = "_2s-2";
export var icon = "_2s-7";
export var input = "_2s-e";
export var listItem = "_2s-h";
export var mediumHeight = "_2s-b";
export var noBorder = "_2s-8";
export var noRadius = "_2s-1";
export var shortHeight = "_2s-a";
export var tallHeight = "_2s-c";
export var textInput = "_2s-0";
export var vertical = "_2s-g";
export var veryTallHeight = "_2s-d";
export var widget = "_2s-6";