import { genericMemo } from "@redotech/react-util/component";
import { Dispatch, ReactNode, SetStateAction } from "react";
import { RedoList, RedoListItem } from "./redo-list";
import { RedoListItemSize, RedoListItemVariant } from "./redo-list-item";

export const RedoMultiselectList = genericMemo(function RedoMultiselectList<T>({
  refToListenTo,
  options,
  setSelectedOptions,
  children,
  selectedOptions,
  keyFn,
  size,
  selectionVariant,
  loading,
  focusedIndex,
  setFocusedIndex,
}: {
  refToListenTo: HTMLElement | null;
  focusedIndex: number | undefined;
  setFocusedIndex: Dispatch<SetStateAction<number | undefined>>;
  options: RedoListItem<T>[];
  setSelectedOptions(value: RedoListItem<T>[]): void;
  children(item: RedoListItem<T>): ReactNode;
  selectedOptions: RedoListItem<T>[];
  keyFn?: (item: RedoListItem<T>) => string | number;
  size?: RedoListItemSize;
  selectionVariant?: RedoListItemVariant;
  loading?: boolean;
}) {
  function itemsEqual(item1: RedoListItem<T>, item2: RedoListItem<T>) {
    if (keyFn) {
      return keyFn(item1) === keyFn(item2);
    }
    return item1.value === item2.value;
  }

  function isOptionSelected(item: RedoListItem<T>) {
    return selectedOptions.some((selectedItem) =>
      itemsEqual(item, selectedItem),
    );
  }

  function handleItemToggled(item: RedoListItem<T>) {
    const newSelectedOptions = isOptionSelected(item)
      ? selectedOptions.filter(
          (selectedItem) => !itemsEqual(selectedItem, item),
        )
      : [...selectedOptions, item];
    setSelectedOptions(newSelectedOptions);
  }

  return (
    <RedoList
      focusedIndex={focusedIndex}
      gap="sm"
      isItemSelected={isOptionSelected}
      items={options}
      itemSelected={handleItemToggled}
      itemsLoading={loading}
      keyFn={keyFn}
      px="md"
      py="sm"
      refToListenTo={refToListenTo}
      selectionVariant={selectionVariant}
      setFocusedIndex={setFocusedIndex}
      size={size}
    >
      {(item) => children(item)}
    </RedoList>
  );
});
