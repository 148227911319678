// extracted by mini-css-extract-plugin
export var active = "_1i-d";
export var blink = "_1i-e";
export var container = "_1i-5";
export var controls = "_1i-0";
export var controlsRight = "_1i-1";
export var fakeCursor = "_1i-c";
export var hidden = "_1i-b";
export var quillContainerSmall = "_1i-7";
export var quillEditor = "_1i-6";
export var quillEditorSmall = "_1i-8";
export var quillFormatButtons = "_1i-a";
export var quillToolbar = "_1i-9";
export var removePadding = "_1i-2";
export var state = "_1i-3";
export var stateTitle = "_1i-4";