import {
  createContext,
  Fragment,
  memo,
  ReactNode,
  useEffect,
  useState,
} from "react";
import { useEventListener } from "./event";

export const HidContext = createContext<HIDDevice[]>([]);

export const HidProvider = memo(
  !navigator.hid
    ? Fragment
    : function HidProvider({
        children,
      }: {
        children: ReactNode | ReactNode[];
      }) {
        const [devices, setDevices] = useState<HIDDevice[]>([]);
        useEffect(() => {
          void (async () => {
            setDevices(await navigator.hid.getDevices());
          })();
        }, []);
        useEventListener(navigator.hid, "connect", async () => {
          setDevices(await navigator.hid.getDevices());
        });
        useEventListener(navigator.hid, "disconnect", async () => {
          setDevices(await navigator.hid.getDevices());
        });
        return (
          <HidContext.Provider value={devices}>{children}</HidContext.Provider>
        );
      },
);
