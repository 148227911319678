// This is a copy of the FulfillmentOrderAction enum from @redotech/shopify-client/admin.graphql, but that can also be used in redo/model and therefore the frontend
export enum RedoShopifyFulfillmentOrderAction {
  /** Cancels a fulfillment order. The corresponding mutation for this action is `fulfillmentOrderCancel`. */
  CancelFulfillmentOrder = "CANCEL_FULFILLMENT_ORDER",
  /** Creates a fulfillment for selected line items in the fulfillment order. The corresponding mutation for this action is `fulfillmentCreateV2`. */
  CreateFulfillment = "CREATE_FULFILLMENT",
  /** Opens an external URL to initiate the fulfillment process outside Shopify. This action should be paired with `FulfillmentOrderSupportedAction.externalUrl`. */
  External = "EXTERNAL",
  /** Applies a fulfillment hold on the fulfillment order. The corresponding mutation for this action is `fulfillmentOrderHold`. */
  Hold = "HOLD",
  /** Marks the fulfillment order as open. The corresponding mutation for this action is `fulfillmentOrderOpen`. */
  MarkAsOpen = "MARK_AS_OPEN",
  /** Merges a fulfillment order. The corresponding mutation for this action is `fulfillmentOrderMerge`. */
  Merge = "MERGE",
  /** Moves a fulfillment order. The corresponding mutation for this action is `fulfillmentOrderMove`. */
  Move = "MOVE",
  /** Releases the fulfillment hold on the fulfillment order. The corresponding mutation for this action is `fulfillmentOrderReleaseHold`. */
  ReleaseHold = "RELEASE_HOLD",
  /** Sends a cancellation request to the fulfillment service of a fulfillment order. The corresponding mutation for this action is `fulfillmentOrderSubmitCancellationRequest`. */
  RequestCancellation = "REQUEST_CANCELLATION",
  /** Sends a request for fulfilling selected line items in a fulfillment order to a fulfillment service. The corresponding mutation for this action is `fulfillmentOrderSubmitFulfillmentRequest`. */
  RequestFulfillment = "REQUEST_FULFILLMENT",
  /** Splits a fulfillment order. The corresponding mutation for this action is `fulfillmentOrderSplit`. */
  Split = "SPLIT",
}
