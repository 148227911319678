// extracted by mini-css-extract-plugin
export var checkbox = "_4l-0";
export var circle = "_4l-3";
export var horizontalContainer = "_4l-7";
export var sm = "_4l-5";
export var switch0 = "_4l-2";
export var switchDisabled = "_4l-8";
export var switchEnabled = "_4l-1";
export var textSwitchContainer = "_4l-9";
export var textSwitchOption = "_4l-a";
export var textSwitchOptionSelected = "_4l-b";
export var verticalContainer = "_4l-6";
export var xs = "_4l-4";