import "react-calendar/dist/Calendar.css";
import "react-time-picker/dist/TimePicker.css";

import * as amplitude from "@amplitude/analytics-browser";
import { useRequiredContext } from "@redotech/react-util/context";
import { ExpandedConversation } from "@redotech/redo-model/conversation";
import { Button, ButtonTheme } from "@redotech/redo-web/button";
import { CollapseSubsection } from "@redotech/redo-web/card";
import { DateRangeValue } from "@redotech/redo-web/date-range";
import { getDateString } from "@redotech/redo-web/date-utils";
import { Modal, ModalSize } from "@redotech/redo-web/modal";
import { ProgressBar } from "@redotech/redo-web/progress-bar";
import { TextInput } from "@redotech/redo-web/text-input";
import { Dispatch, memo, SetStateAction, useState } from "react";
import Calendar from "react-calendar";
import { View } from "react-calendar/dist/cjs/shared/types";
import TimePicker from "react-time-picker";
import { RedoMerchantClientContext } from "../../client/context";
import {
  bulkSnoozeConversations,
  updateConversation,
} from "../../client/conversations";
import "../calendar.module.css";
import { UpdateConversationStateContext } from "../context/update-conversations-context";
import { getNextBatch } from "./../utils";
import * as snoozeModalCss from "./snooze-modal.module.css";

export const SnoozeModal = memo(function SnoozeModal({
  open,
  setOpen,
  conversations,
  setActiveConversation,
  setConversationsInProximity,
  inDetailView,
  cleanupFn,
  selectAllInfo,
  customOnOptionClick,
  nextConversationInList,
  prevConversationInList,
}: {
  open: boolean;
  setOpen(val: boolean): void;
  conversations: ExpandedConversation[];
  setActiveConversation: (
    conversation: ExpandedConversation | undefined,
  ) => void;
  setConversationsInProximity?: Dispatch<
    SetStateAction<ExpandedConversation[]>
  >;
  inDetailView: boolean;
  cleanupFn?: (clearSelected?: boolean) => void;
  selectAllInfo?: {
    count: number;
    deselectedConversations: ExpandedConversation[];
    bulkActionIterator: AsyncIterator<{
      conversations: ExpandedConversation[];
    }>;
    setBlockRefresh: (val: boolean) => void;
  };
  customOnOptionClick?: (snoozeDate: Date) => void;
  nextConversationInList?: ExpandedConversation;
  prevConversationInList?: ExpandedConversation;
}) {
  const client = useRequiredContext(RedoMerchantClientContext);
  const { removeConversation } = useRequiredContext(
    UpdateConversationStateContext,
  );
  const [dateTimeValue, setDateTimeValue] = useState<Date | null>(
    conversations.length === 1 &&
      conversations[0].snoozedUntil &&
      conversations[0].snoozedUntil > Date()
      ? new Date(conversations[0].snoozedUntil)
      : null,
  );
  const [snoozingSelectAll, setSnoozingSelectAll] = useState(false);
  const [numDone, setNumDone] = useState(0);

  const nextMonday = new Date();
  nextMonday.setDate(
    nextMonday.getDate() + ((1 + 7 - nextMonday.getDay()) % 7 || 7),
  );
  nextMonday.setHours(8, 0, 0);

  const nextSaturday = new Date();
  nextSaturday.setDate(
    nextSaturday.getDate() + ((6 + 7 - nextSaturday.getDay()) % 7 || 7),
  );
  nextSaturday.setHours(8, 0, 0);

  const twoDays = new Date();
  twoDays.setDate(twoDays.getDate() + 2);
  twoDays.setHours(8, 0, 0);

  const tomorrow = new Date();
  tomorrow.setDate(tomorrow.getDate() + 1);
  tomorrow.setHours(8, 0, 0);

  const today = new Date();
  today.setHours(18, 0, 0);

  const now = new Date();
  const presetDates: { label: string; date: Date }[] = [
    { label: "Tomorrow", date: tomorrow },
    { label: "Two days", date: twoDays },
  ];
  if (now < today) {
    presetDates.push({ label: "Later today", date: today });
  }
  if (
    !presetDates.some(
      (presetDate) => presetDate.date.getTime() === nextSaturday.getTime(),
    )
  ) {
    presetDates.push({
      label: [6, 0].includes(now.getDay()) ? "Next weekend" : "This weekend",
      date: nextSaturday,
    });
  }
  if (
    !presetDates.some(
      (presetDate) => presetDate.date.getTime() === nextMonday.getTime(),
    )
  ) {
    presetDates.push({ label: "Next Monday", date: nextMonday });
  }

  const sortedPresetDates = presetDates.sort(
    (a, b) => a.date.getTime() - b.date.getTime(),
  );

  const handleSetSnooze = async (snoozedUntil: Date) => {
    if (conversations.length === 1) {
      setConversationsInProximity &&
        setConversationsInProximity((prev: ExpandedConversation[]) => [
          ...prev.filter((conv) => conversations[0]._id !== conv._id),
        ]);
      void updateConversation(client, conversations[0], {
        snoozedUntil: snoozedUntil.toISOString(),
      });
      removeConversation(conversations[0]);
      amplitude.logEvent("snooze-conversation", {
        mode: "single",
        conversationIds: [conversations[0]._id],
        channels: [conversations[0].platform],
      });
      inDetailView &&
        setActiveConversation(nextConversationInList || prevConversationInList);
    } else if (conversations.length > 1) {
      const conversationIds = conversations
        .filter((conversation) => conversation.status !== "closed")
        .map((conversation) => conversation._id);
      if (conversationIds.length > 0) {
        await bulkSnoozeConversations(client, {
          conversationIds,
          snoozedUntil: snoozedUntil.toISOString(),
        });
        const channels = [
          ...new Set(
            conversations.map((conversation) => conversation.platform),
          ),
        ];
        amplitude.logEvent("snooze-conversation", {
          mode: "multiple",
          conversationIds,
          channels,
        });
      }
    }
    cleanupFn && cleanupFn(true);
  };

  const snoozeBatch = async (
    snoozedUntil: Date,
  ): Promise<{ batchSize: number; done: boolean }> => {
    if (!selectAllInfo) return { batchSize: 0, done: true };
    const { done, value } = await getNextBatch(
      selectAllInfo.bulkActionIterator,
    );
    if (done) {
      return { batchSize: 0, done };
    }
    const conversationIds = value.conversations
      .filter(
        (conversation) =>
          conversation.status !== "closed" &&
          !selectAllInfo.deselectedConversations.some(
            (deselectedConversation) =>
              deselectedConversation._id === conversation._id,
          ),
      )
      .map((conversation) => conversation._id);
    if (conversationIds.length > 0) {
      await bulkSnoozeConversations(client, {
        conversationIds,
        snoozedUntil: snoozedUntil.toISOString(),
      });
    }
    return { batchSize: value.conversations.length, done: false };
  };

  // This function synchronously snoozes each page of conversations.
  // If speed becomes an issue, we could asynchronously snooze each page.
  const handleSetSnoozeSelectAll = async (snoozedUntil: Date) => {
    if (!selectAllInfo) return;
    setSnoozingSelectAll(true);
    selectAllInfo.setBlockRefresh(true);
    let done = false;
    do {
      const result = await snoozeBatch(snoozedUntil);
      setNumDone((prevNumDone) => prevNumDone + result.batchSize);
      done = result.done;
    } while (!done);
    amplitude.logEvent("snooze-conversation", { mode: "all" });
    setSnoozingSelectAll(false);
    selectAllInfo.setBlockRefresh(false);
    cleanupFn && cleanupFn(true);
  };

  const handleOptionClick = async (snoozeDate: Date | undefined) => {
    if (snoozeDate) {
      if (customOnOptionClick) {
        customOnOptionClick(snoozeDate);
      } else {
        selectAllInfo
          ? await handleSetSnoozeSelectAll(snoozeDate)
          : await handleSetSnooze(snoozeDate);
      }
      setOpen(false);
      setDateTimeValue(null);
    }
  };

  const formatDate = (date: Date | null) => {
    if (date) {
      return getDateString(date);
    } else {
      return "";
    }
  };

  const handleSetCalendar = (date: Date | null) => {
    if (!date) {
      setDateTimeValue(null);
      return;
    }
    const newDate = new Date(
      date.getFullYear(),
      date.getMonth(),
      date.getDate(),
    );
    if (dateTimeValue) {
      newDate.setHours(dateTimeValue.getHours());
      newDate.setMinutes(dateTimeValue.getMinutes());
    }
    setDateTimeValue(newDate);
  };

  const handleSetClock = (time: string | null) => {
    if (!time) {
      setDateTimeValue(null);
      return;
    }
    const newDate = new Date();
    const timePieces = time.split(":");
    const hours = timePieces[0];
    const minutes = timePieces[1];
    newDate.setHours(Number(hours));
    newDate.setMinutes(Number(minutes));
    if (dateTimeValue) {
      newDate.setFullYear(dateTimeValue.getFullYear());
      newDate.setDate(dateTimeValue.getDate());
      newDate.setMonth(dateTimeValue.getMonth());
    }
    setDateTimeValue(newDate);
  };

  const handleCustomDateSubmit = async (
    e: React.FormEvent<HTMLFormElement>,
  ) => {
    e.preventDefault();
    if (dateTimeValue) {
      selectAllInfo
        ? await handleSetSnoozeSelectAll(dateTimeValue)
        : await handleSetSnooze(dateTimeValue);
    }
    setOpen(false);
    setDateTimeValue(null);
  };

  return (
    <Modal
      onClose={() => {
        cleanupFn && cleanupFn();
        setOpen(false);
      }}
      open={open}
      showHeaderBorder={false}
      size={ModalSize.SMALL}
      title={
        snoozingSelectAll
          ? "Snoozing tickets"
          : `Snooze ${
              selectAllInfo
                ? "all selected tickets "
                : conversations.length > 1
                  ? conversations?.length + " tickets "
                  : ""
            }until`
      }
    >
      {snoozingSelectAll ? (
        <ProgressBar
          value={(numDone / (selectAllInfo?.count || numDone)) * 100}
        />
      ) : (
        <div className={snoozeModalCss.wrapper}>
          {sortedPresetDates.map((presetDate) => (
            <Button
              key={presetDate.label}
              onClick={async () => {
                await handleOptionClick(presetDate.date);
              }}
            >
              <SnoozeOptionText
                text={presetDate.label}
                time={presetDate.date}
              />
            </Button>
          ))}
          <hr className={snoozeModalCss.line} />
          <div className={snoozeModalCss.collapseContainer}>
            <CollapseSubsection defaultCollapsed title="Pick date & time">
              <div className={snoozeModalCss.dateTimeWrapper}>
                <form onSubmit={handleCustomDateSubmit}>
                  <Calendar
                    onChange={(value: DateRangeValue) =>
                      !Array.isArray(value) && handleSetCalendar(value)
                    }
                    tileClassName={({
                      date,
                      view,
                    }: {
                      date: Date;
                      view: View;
                    }) => {
                      if (view === "month") {
                        const now = new Date();
                        const today = new Date(
                          now.getFullYear(),
                          now.getMonth(),
                          now.getDate(),
                        );
                        const tileDate = new Date(
                          date.getFullYear(),
                          date.getMonth(),
                          date.getDate(),
                        );
                        if (tileDate < today) {
                          return snoozeModalCss.disabledTile;
                        } else if (tileDate.getTime() === today.getTime()) {
                          return snoozeModalCss.todayTile;
                        }
                      }
                      return undefined;
                    }}
                    tileDisabled={({
                      date,
                      view,
                    }: {
                      date: Date;
                      view: View;
                    }) => {
                      if (view === "month") {
                        const today = new Date();
                        const todayWithoutTime = new Date(
                          today.getFullYear(),
                          today.getMonth(),
                          today.getDate(),
                        );
                        return date < todayWithoutTime;
                      }
                      return false;
                    }}
                    value={dateTimeValue}
                  />
                  <div className={snoozeModalCss.dateTimeInputContainer}>
                    <TextInput readonly value={formatDate(dateTimeValue)} />
                    <TimePicker
                      disableClock
                      onChange={handleSetClock}
                      value={dateTimeValue}
                    />
                  </div>
                  <div className={snoozeModalCss.dateTimeButtonsContainer}>
                    <Button theme={ButtonTheme.PRIMARY} type="submit">
                      Save
                    </Button>
                  </div>
                </form>
              </div>
            </CollapseSubsection>
          </div>
        </div>
      )}
    </Modal>
  );
});

export const SnoozeOptionText = memo(function SnoozeOptionText({
  text,
  time,
}: {
  text: string;
  time: Date;
}) {
  const formattedTime = time.toLocaleString([], {
    weekday: "short",
    hour: "numeric",
    minute: "2-digit",
    hour12: true,
  });
  return (
    <div className={snoozeModalCss.snoozeOption}>
      <p>{text}</p>
      <p className={snoozeModalCss.time}>{formattedTime}</p>
    </div>
  );
});
