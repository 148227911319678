import { Drawer } from "@mui/material";
import { Currency } from "@redotech/money/currencies";
import { useRequiredContext } from "@redotech/react-util/context";
import { useHandler } from "@redotech/react-util/hook";
import { CreateFulfillmentOrder } from "@redotech/redo-model/fulfillments/create-fulfillment-order";
import { RedoShopifyCountryCode } from "@redotech/redo-model/fulfillments/fulfillment-country-code";
import { USStatePostalCode } from "@redotech/redo-model/fulfillments/fulfillment-group-state-codes";
import { toast } from "@redotech/redo-web/alert";
import {
  RedoButton,
  RedoButtonHierarchy,
  RedoButtonSize,
  RedoButtonTheme,
} from "@redotech/redo-web/arbiter-components/buttons/redo-button";
import { RedoDatePicker } from "@redotech/redo-web/arbiter-components/date-picker/redo-date-picker";
import { RedoIncrementDecrement } from "@redotech/redo-web/arbiter-components/increment-decrement/redo-increment-decrement";
import { RedoInputSize } from "@redotech/redo-web/arbiter-components/input/base-redo-text-input";
import { RedoTextInput } from "@redotech/redo-web/arbiter-components/input/redo-text-input";
import {
  RedoDropdownInputSize,
  RedoSingleSelectDropdownInput,
} from "@redotech/redo-web/arbiter-components/select-dropdown/redo-single-select-dropdown-input";
import XIcon from "@redotech/redo-web/arbiter-icon/x-close.svg";
import { IconButton } from "@redotech/redo-web/button";
import { Flex } from "@redotech/redo-web/flex";
import { Text } from "@redotech/redo-web/text";
import { memo, useState } from "react";
import { RedoOutboundLabelsRpcClientContext } from "../../app/redo-outbound-labels-rpc-client-provider";
import * as styles from "./create-order-slideout.module.css";
import { ItemTable } from "./item-table";

export const CreateOrderSlideout = memo(function CreateOrderSlideout({
  onClose,
  open,
  triggerRefresh,
}: {
  onClose: () => void;
  open: boolean;
  triggerRefresh: () => void;
}) {
  const rpcClient = useRequiredContext(RedoOutboundLabelsRpcClientContext);

  const [createOrderInfo, setCreateOrderInfo] =
    useState<CreateFulfillmentOrder>({
      address: {
        name: "",
        address1: "",
        address2: "",
        city: "",
        company: "",
        countryCode: RedoShopifyCountryCode.Us,
        email: "",
        phone: "",
        province: USStatePostalCode.UT,
        zip: "",
      },
      orderInfo: {
        orderNumber: "",
        orderDate: new Date(),
        paidDate: new Date(),
        shippingPaid: { amount: "0", currency: Currency.USD },
        taxPaid: { amount: "0", currency: Currency.USD },
        totalPaid: { amount: "0", currency: Currency.USD },
        items: [],
      },
    });

  const createDisabled =
    createOrderInfo.address.name.length === 0 ||
    !createOrderInfo.address.address1 ||
    !createOrderInfo.address.city ||
    !createOrderInfo.address.zip ||
    !createOrderInfo.address.countryCode;

  const [createLoading, setCreateLoading] = useState(false);

  const createOrderClick = useHandler(async () => {
    try {
      setCreateLoading(true);
      // Update the total prices based off of the changes to the quantity
      // Unit discount price and total discount price are the same as the unit price and total price
      const data: CreateFulfillmentOrder = {
        ...createOrderInfo,
        orderInfo: {
          ...createOrderInfo.orderInfo,
          items: createOrderInfo.orderInfo.items.map((item) => ({
            ...item,
            unitDiscountedPrice: {
              amount: item.unitPrice.amount,
              currency: item.unitPrice.currency,
            },
            totalPrice: {
              amount: (
                Number(item.unitPrice.amount) * item.totalQuantity
              ).toString(),
              currency: item.unitPrice.currency,
            },
            totalDiscountedPrice: {
              amount: (
                Number(item.unitPrice.amount) * item.totalQuantity
              ).toString(),
              currency: item.unitPrice.currency,
            },
            unfulfilledQuantity: item.totalQuantity,
          })),
        },
      };
      const success = await rpcClient.createFulfillmentOrders({
        fulfillmentOrders: [data],
      });
      if (success) {
        toast("Order created", { variant: "success" });
        onClose();
        triggerRefresh();
      } else {
        toast("Failed to create order", { variant: "error" });
      }
    } catch (error) {
      toast("Failed to create order", { variant: "error" });
      console.error(error);
    } finally {
      setCreateLoading(false);
    }
  });

  return (
    <Drawer
      anchor="right"
      onClose={onClose}
      open={open}
      sx={{ "& .MuiDrawer-paper": { overflow: "hidden" } }}
    >
      <Flex className={styles.drawerContainer} dir="column">
        <Flex className={styles.header} dir="column" gap="none">
          <Flex
            align="flex-start"
            borderBottomWidth="1px"
            borderColor="primary"
            borderStyle="solid"
            dir="row"
            justify="space-between"
            p="3xl"
          >
            <Flex dir="column" gap="xs">
              <Text fontSize="xl" fontWeight="semibold">
                Create new order
              </Text>
            </Flex>
            <IconButton onClick={onClose}>
              <XIcon />
            </IconButton>
          </Flex>
        </Flex>
        <Flex className={styles.content} dir="column" gap="none" w="full">
          <Flex dir="row" gap="none" justify="space-between" w="full">
            <Flex
              className={styles.createOrderColumn}
              dir="column"
              flex="1"
              gap="3xl"
              p="3xl"
            >
              <Text fontSize="md" fontWeight="semibold">
                Recipient information
              </Text>
              <RedoTextInput
                label="Name"
                placeholder="Enter name"
                setValue={(value) => {
                  setCreateOrderInfo((prev) => {
                    return {
                      ...prev,
                      address: { ...prev.address, name: value },
                    };
                  });
                }}
                value={createOrderInfo?.address.name ?? ""}
              />
              <RedoTextInput
                label="Company"
                placeholder="Enter company"
                setValue={(value) => {
                  setCreateOrderInfo((prev) => {
                    return {
                      ...prev,
                      address: { ...prev.address, company: value },
                    };
                  });
                }}
                value={createOrderInfo?.address.company ?? ""}
              />
              <RedoSingleSelectDropdownInput<RedoShopifyCountryCode>
                disabled
                label="Country"
                options={Object.values(RedoShopifyCountryCode).map((code) => ({
                  value: code,
                }))}
                optionSelected={(country) => {
                  setCreateOrderInfo((prev) => {
                    return {
                      ...prev,
                      address: { ...prev.address, countryCode: country.value },
                    };
                  });
                }}
                selectedItem={{
                  value:
                    createOrderInfo.address.countryCode ??
                    RedoShopifyCountryCode.Us,
                }}
                size={RedoDropdownInputSize.SMALL}
              >
                {(item) => (
                  <Text
                    fontSize="sm"
                    overflow="hidden"
                    textOverflow="ellipsis"
                    whiteSpace="nowrap"
                  >
                    {item.value}
                  </Text>
                )}
              </RedoSingleSelectDropdownInput>
              <RedoTextInput
                label="Address"
                placeholder="Enter address"
                setValue={(value) => {
                  setCreateOrderInfo((prev) => ({
                    ...prev,
                    address: { ...prev.address, address1: value },
                  }));
                }}
                value={createOrderInfo?.address.address1 ?? ""}
              />
              <RedoTextInput
                label="Apartment, suite, etc. (optional)"
                placeholder="Enter your number"
                setValue={(value) => {
                  setCreateOrderInfo((prev) => ({
                    ...prev,
                    address: { ...prev.address, address2: value },
                  }));
                }}
                value={createOrderInfo?.address.address2 ?? ""}
              />
              <Flex dir="row">
                <RedoTextInput
                  label="City"
                  placeholder="Enter your city"
                  setValue={(value) => {
                    setCreateOrderInfo((prev) => ({
                      ...prev,
                      address: { ...prev.address, city: value },
                    }));
                  }}
                  value={createOrderInfo?.address.city ?? ""}
                />
                <RedoSingleSelectDropdownInput<USStatePostalCode | string>
                  label="State"
                  options={Object.values(USStatePostalCode).map((code) => ({
                    label: code,
                    value: code,
                  }))}
                  optionSelected={(state) => {
                    setCreateOrderInfo((prev) => {
                      return {
                        ...prev,
                        address: { ...prev.address, province: state.value },
                      };
                    });
                  }}
                  selectedItem={
                    createOrderInfo.address.province
                      ? { value: createOrderInfo.address.province }
                      : undefined
                  }
                  size={RedoDropdownInputSize.SMALL}
                >
                  {(item) => (
                    <Text fontSize="xs" style={{ width: "40px" }}>
                      {item.value}
                    </Text>
                  )}
                </RedoSingleSelectDropdownInput>
                <RedoTextInput
                  label="Zip"
                  placeholder="Enter your zip"
                  setValue={(value) => {
                    setCreateOrderInfo((prev) => ({
                      ...prev,
                      address: { ...prev.address, zip: value },
                    }));
                  }}
                  value={createOrderInfo?.address.zip ?? ""}
                />
              </Flex>
              <RedoTextInput
                label="Phone number (optional)"
                placeholder="Enter your phone number"
                setValue={(value) => {
                  setCreateOrderInfo((prev) => ({
                    ...prev,
                    address: { ...prev.address, phone: value },
                  }));
                }}
                value={createOrderInfo?.address.phone ?? ""}
              />
              <RedoTextInput
                label="Email (optional)"
                placeholder="olivia@getredo.com"
                setValue={(value) => {
                  setCreateOrderInfo((prev) => ({
                    ...prev,
                    address: { ...prev.address, email: value },
                  }));
                }}
                value={createOrderInfo?.address.email ?? ""}
              />
            </Flex>
            <Flex
              bgColor="tertiary"
              className={styles.createOrderColumn}
              dir="column"
              flex="1"
              gap="3xl"
              p="3xl"
            >
              <Text fontSize="md" fontWeight="semibold">
                Order details
              </Text>
              <RedoTextInput
                label="Order #"
                placeholder="Order number will be generated if not provided"
                setValue={(value) => {
                  setCreateOrderInfo((prev) => ({
                    ...prev,
                    orderInfo: { ...prev.orderInfo, orderNumber: value },
                  }));
                }}
                value={createOrderInfo?.orderInfo.orderNumber ?? ""}
              />
              <Flex dir="column" gap="xs">
                <Text fontSize="xs" textColor="tertiary">
                  Order date
                </Text>
                <RedoDatePicker
                  buttonSize={RedoButtonSize.SMALL}
                  date={createOrderInfo.orderInfo.orderDate}
                  maxDate={new Date()}
                  setDate={(date) => {
                    setCreateOrderInfo((prev) => ({
                      ...prev,
                      orderInfo: { ...prev.orderInfo, orderDate: date },
                    }));
                  }}
                />
              </Flex>
              <Flex dir="column" gap="xs">
                <Text fontSize="xs" textColor="tertiary">
                  Paid date
                </Text>
                <RedoDatePicker
                  buttonSize={RedoButtonSize.SMALL}
                  date={createOrderInfo.orderInfo.paidDate}
                  maxDate={new Date()}
                  setDate={(date) => {
                    setCreateOrderInfo((prev) => ({
                      ...prev,
                      orderInfo: { ...prev.orderInfo, paidDate: date },
                    }));
                  }}
                />
              </Flex>
              <RedoIncrementDecrement
                fullWidth
                label="Shipping paid (USD)"
                min={0}
                setValue={(value) => {
                  setCreateOrderInfo((prev) => ({
                    ...prev,
                    orderInfo: {
                      ...prev.orderInfo,
                      shippingPaid: {
                        amount: value.toString(),
                        currency: Currency.USD,
                      },
                      totalPaid: {
                        amount: (
                          Number(value) +
                          Number(createOrderInfo.orderInfo.taxPaid.amount)
                        ).toString(),
                        currency: Currency.USD,
                      },
                    },
                  }));
                }}
                size={RedoInputSize.SMALL}
                value={Number(createOrderInfo?.orderInfo.shippingPaid.amount)}
              />
              <RedoIncrementDecrement
                fullWidth
                label="Tax paid (USD)"
                min={0}
                setValue={(value) => {
                  setCreateOrderInfo((prev) => ({
                    ...prev,
                    orderInfo: {
                      ...prev.orderInfo,
                      taxPaid: {
                        amount: value.toString(),
                        currency: Currency.USD,
                      },
                      totalPaid: {
                        amount: (
                          Number(value) +
                          Number(createOrderInfo.orderInfo.shippingPaid.amount)
                        ).toString(),
                        currency: Currency.USD,
                      },
                    },
                  }));
                }}
                size={RedoInputSize.SMALL}
                value={Number(createOrderInfo?.orderInfo.taxPaid.amount)}
              />
              <RedoIncrementDecrement
                fullWidth
                label="Total paid (USD)"
                min={0}
                setValue={(value) => {
                  setCreateOrderInfo((prev) => ({
                    ...prev,
                    orderInfo: {
                      ...prev.orderInfo,
                      totalPaid: {
                        amount: value.toString(),
                        currency: Currency.USD,
                      },
                    },
                  }));
                }}
                size={RedoInputSize.SMALL}
                value={Number(createOrderInfo?.orderInfo.totalPaid.amount)}
              />
            </Flex>
          </Flex>
          <ItemTable
            onItemsChange={(items) => {
              setCreateOrderInfo((prev) => {
                const newCreateOrderInfo = {
                  ...prev,
                  orderInfo: { ...prev.orderInfo, items },
                };
                return newCreateOrderInfo;
              });
            }}
          />
        </Flex>
        <Flex
          align="center"
          borderBottomWidth="1px"
          borderColor="primary"
          borderStyle="solid"
          borderTopWidth="1px"
          className={styles.footer}
          dir="row"
          justify="space-between"
          p="3xl"
          w="full"
        >
          <RedoButton
            className={styles.cancelButton}
            hierarchy={RedoButtonHierarchy.SECONDARY}
            onClick={onClose}
            size={RedoButtonSize.REGULAR}
            text="Cancel"
            theme={RedoButtonTheme.NORMAL}
          />
          <RedoButton
            className={styles.createButton}
            disabled={createDisabled}
            hierarchy={RedoButtonHierarchy.PRIMARY}
            onClick={createOrderClick}
            pending={createLoading}
            size={RedoButtonSize.REGULAR}
            text="Create order"
            theme={RedoButtonTheme.NORMAL}
            type="submit"
          />
        </Flex>
      </Flex>
    </Drawer>
  );
});
