// extracted by mini-css-extract-plugin
export var brand = "_2n-9";
export var button = "_2n-0";
export var chevron = "_2n-v";
export var chevronButton = "_2n-w";
export var content = "_2n-n";
export var danger = "_2n-g";
export var dangerOutlined = "_2n-h";
export var dark = "_2n-l";
export var darkTheme = "_2n-c";
export var extraSmall = "_2n-4";
export var fullWidth = "_2n-8";
export var ghost = "_2n-d";
export var gray = "_2n-t";
export var icon = "_2n-p";
export var iconButton = "_2n-s";
export var iconButtonBorder = "_2n-x";
export var iconButtonBorderDark = "_2n-y";
export var iconButtonBorderLight = "_2n-z";
export var iconLeft = "_2n-q";
export var iconRight = "_2n-r";
export var large = "_2n-1";
export var light = "_2n-m";
export var medium = "_2n-2";
export var micro = "_2n-5";
export var nano = "_2n-7";
export var outlined = "_2n-k";
export var pending = "_2n-a";
export var primary = "_2n-i";
export var shadow = "_2n-o";
export var small = "_2n-3";
export var solidLight = "_2n-e";
export var solidLightBrand = "_2n-f";
export var spinner = "_2n-u";
export var transparent = "_2n-j";
export var warning = "_2n-b";
export var wideNano = "_2n-6";