import { FulfillmentOrderData } from "@redotech/redo-model/fulfillments/fulfillment-group";
import { RedoTableTextCell } from "@redotech/redo-web/arbiter-components/tables/redo-table-cells";
import { ColumnAlignment } from "@redotech/redo-web/table";
import { Column2 } from "@redotech/redo-web/table2";

export const DestinationCountryColumn: Column2<FulfillmentOrderData> = {
  key: "destination-country",
  title: "Country",
  renderNormalCell: ({ row }: { row: FulfillmentOrderData }) => (
    <RedoTableTextCell text={row.destinationAddress.countryCode ?? ""} />
  ),
  alignment: ColumnAlignment.LEFT,
  width: 80,
};
