import { getWarrantyEligibleProductsSchema as getWarrantyEligibleProducts } from "@redotech/redo-model/warranties/get-warranty-eligible-products-schema";
import { submitWarrantyRegistrationSchema as submitWarrantyRegistration } from "@redotech/redo-model/warranties/submit-warranty-registration-schema";
import type { InferRpcDefinition } from "@redotech/rpc/definition";
import { createAdvancedFlow } from "./schema/advanced-flows/create-advanced-flow";
import { deleteAdvancedFlow } from "./schema/advanced-flows/delete-advanced-flow";
import { getAdvancedFlowById } from "./schema/advanced-flows/get-advanced-flow-by-id";
import { getAdvancedFlowCount } from "./schema/advanced-flows/get-advanced-flow-count";
import { getAdvancedFlows } from "./schema/advanced-flows/get-advanced-flows";
import {
  createReport,
  deleteReport,
  getReport,
  getReports,
  updateReport,
} from "./schema/analytics/reports";
import { calculateNewOrderValue } from "./schema/calculate-new-order-value";
import { deleteCarrierAccount } from "./schema/carrier-accounts/delete-carrier-account";
import { createCarrierAccount } from "./schema/carrier-accounts/dhl-carrier-account";
import { getCarrierAccounts } from "./schema/carrier-accounts/get-carrier-accounts";
import { disconnectRivoIntegration } from "./schema/customer-accounts/rivo/disconnect-rivo-integration";
import { getRivoIntegration } from "./schema/customer-accounts/rivo/get-rivo-integration";
import { setRivoIntegration } from "./schema/customer-accounts/rivo/set-rivo-integration";
import { getNativeAccountsSettings } from "./schema/customer-accounts/storefront-display-settings/get-native-accounts-settings";
import { updateNativeAccountsSettings } from "./schema/customer-accounts/storefront-display-settings/update-native-accounts-settings";
import { updateCustomerWidgetSettings } from "./schema/customer-widget/update-customer-widget-settings";
import { getOrCreateCustomerFromPhoneNumber } from "./schema/customer/get-or-create-customer-from-phone-number";
import { createEndShipper } from "./schema/end-shippers/create-end-shipper";
import { getEndShippers } from "./schema/end-shippers/get-end-shippers";
import { updateEndShipper } from "./schema/end-shippers/update-end-shipper";
import { createArticle } from "./schema/faq/create-article";
import { createCollection } from "./schema/faq/create-collection";
import { deleteArticle } from "./schema/faq/delete-article";
import { deleteCollection } from "./schema/faq/delete-collection";
import { getFaq } from "./schema/faq/get-faq";
import { updateArticle } from "./schema/faq/update-article";
import { updateCollection } from "./schema/faq/update-collection";
import { updateTheme } from "./schema/faq/update-theme";
import { getBundlesData } from "./schema/get-bundles-data";
import { getCustomerAccountsMetricsForOverview } from "./schema/get-customer-accounts-metrics-for-overview";
import { getEmailTemplateNames } from "./schema/get-email-template-names";
import { getManagedClaimStatus } from "./schema/get-managed-claim-status";
import { getOrders } from "./schema/get-orders";
import { getOriginDiscounts } from "./schema/get-origin-discounts";
import { getRepairInvoiceDetails } from "./schema/get-repair-invoice-details";
import { getReturnsInstantExchangeRecoveryHistory } from "./schema/get-returns-instant-exchange-recovery-history";
import { getSaveForLaterStorefrontDisplaySettings } from "./schema/get-save-for-later-storefront-display-settings";
import { getWarehouseForProduct } from "./schema/get-warehouse-for-product";
import { getIndustryCategoryComparisonStats } from "./schema/industry-category/get-industry-category-comparison-stats";
import { getValidIndustryCategories } from "./schema/industry-category/get-valid-industry-categories";
import { issueStoreCreditToCustomer } from "./schema/issue-store-credit-to-customer";
import { createMerchantPolicy } from "./schema/merchant-policies/create-merchant-policy";
import { deleteMerchantPolicy } from "./schema/merchant-policies/delete-merchant-policy";
import { editMerchantPolicy } from "./schema/merchant-policies/edit-merchant-policy";
import { getMerchantPolicies } from "./schema/merchant-policies/get-merchant-policies";
import { addOutboundFunds } from "./schema/outbound-labels/add-outbound-funds";
import { processReturn } from "./schema/process-return";
import { getProduct } from "./schema/products/get-product";
import { searchProducts } from "./schema/products/search-products";
import { recreateDiscounts } from "./schema/recreate-discounts";
import { removeVariantExchangeItem } from "./schema/remove-variant-exchange-item";
import { resendEmail } from "./schema/resend-email";
import { restockItemsForReturn } from "./schema/restock/restock-items-for-return";
import { setReturnItemDisposition } from "./schema/return-item-disposition/set-return-item-disposition";
import { getRedoSalesChannels } from "./schema/sales-channels/get-redo-sales-channels";
import { getSalesChannelOptions } from "./schema/sales-channels/get-sales-channel-options";
import { setRedoSalesChannels } from "./schema/sales-channels/set-redo-sales-channels";
import { searchProductVariants } from "./schema/search-product-variants";
import { sendRepairInvoice } from "./schema/send-repair-invoice";
import { createCallAvailability } from "./schema/support/create-call-availability";
import {
  getCsatResponsesForConversation,
  getCsatResponsesForTeam,
  getCsatResponsesForTeamCount,
} from "./schema/support/csat";
import { deleteRecordingFromCallRecord } from "./schema/support/delete-recording-from-call-record";
import { getCallRecordWithTranscript } from "./schema/support/get-call-record-with-transcript";
import { getTeamCallAvailabilities } from "./schema/support/get-team-call-availabilities";
import { manuallyConvertChatToEmail } from "./schema/support/manually-convert-chat-to-email";
import { getPhoneNumberForTeam } from "./schema/support/phone-number";
import { polishMessage } from "./schema/support/polish-message";
import { purchaseVoiceNumber } from "./schema/support/purchase-voice-number";
import { searchTelnyxPhoneNumbers } from "./schema/support/search-telnyx-phone-numbers";
import { getTelnyxTelephonyToken } from "./schema/support/telnyx-telephony-token";
import { toggleCallBeingRecorded } from "./schema/support/toggle-call-being-recorded";
import { toggleCallOnHold } from "./schema/support/toggle-call-on-hold";
import { triggerConciergeResponse } from "./schema/support/trigger-concierge-response";
import { deleteTag } from "./schema/tags/delete-tag";
import { getTeamTags } from "./schema/tags/get-team-tags";
import { upsertTag } from "./schema/tags/upsert-tag";
import { updateNewOrderItems } from "./schema/update-new-order-items";
import { updateRepairInvoice } from "./schema/update-repair-invoice";
import { updateSaveForLaterStorefrontDisplaySettings } from "./schema/update-save-for-later-storefront-display-settings";
import { getUpsellPageAnalytics } from "./schema/upsell-page-analytics.ts/get-upsell-page-analytics";
import { createUpsellPage } from "./schema/upsell-page/create-upsell-page";
import { deleteUpsellPage } from "./schema/upsell-page/delete-upsell-page";
import { getActiveUpsellPageForTeam } from "./schema/upsell-page/get-active-upsell-page-for-team";
import { getUpsellPage } from "./schema/upsell-page/get-upsell-page";
import { updateUpsellPage } from "./schema/upsell-page/update-upsell-page";

const upsellPage1 = { createUpsellPage, deleteUpsellPage, updateUpsellPage };

const upsellPage2 = {
  getUpsellPage,
  //getUpsellPagesForTeam,
};

const upsellPage3 = { getActiveUpsellPageForTeam };

const upsellPageAnalytics = { getUpsellPageAnalytics };

const industryCategory = {
  getValidIndustryCategories,
  getIndustryCategoryComparisonStats,
};

const returnItemDisposition = { setReturnItemDisposition };

const redoMerchantRPC1 = {
  issueStoreCreditToCustomer,
  getCustomerAccountsMetricsForOverview,
  getSaveForLaterStorefrontDisplaySettings,
  updateSaveForLaterStorefrontDisplaySettings,
  getNativeAccountsSettings,
  updateNativeAccountsSettings,
  resendEmail,
  getEmailTemplateNames,
  getReturnsInstantExchangeRecoveryHistory,
  getOrders,
  getReport,
  getReports,
  createReport,
  updateReport,
  deleteReport,
  processReturn,
  getWarehouseForProduct,
};

const support = {
  createCallAvailability,
  deleteRecordingFromCallRecord,
  getCallRecordWithTranscript,
  getTeamCallAvailabilities,
  searchTelnyxPhoneNumbers,
  polishMessage,
  purchaseVoiceNumber,
  getCsatResponsesForConversation,
  getCsatResponsesForTeam,
  getCsatResponsesForTeamCount,
  getPhoneNumberForTeam,
  getTelnyxTelephonyToken,
  toggleCallOnHold,
  toggleCallBeingRecorded,
  triggerConciergeResponse,
  manuallyConvertChatToEmail,
};

const customer = { getOrCreateCustomerFromPhoneNumber };

const customerWidget = { updateCustomerWidgetSettings };

const faq = {
  getFaq,
  createArticle,
  updateArticle,
  updateTheme,
  createCollection,
  updateCollection,
  deleteArticle,
  deleteCollection,
};

const restock = { restockItemsForReturn };

const redoMerchantRPC2 = {
  getSalesChannelOptions,
  getRedoSalesChannels,
  setRedoSalesChannels,
  updateNewOrderItems,
  removeVariantExchangeItem,
  getBundlesData,
  searchProductVariants,
  getWarrantyEligibleProducts,
  submitWarrantyRegistration,
  getRivoIntegration,
  setRivoIntegration,
  disconnectRivoIntegration,
  calculateNewOrderValue,
  getOriginDiscounts,
  recreateDiscounts,
  getAdvancedFlowById,
  getAdvancedFlows,
  createAdvancedFlow,
  getAdvancedFlowCount,
  deleteAdvancedFlow,
};

const products = { getProduct, searchProducts };

const merchantPolicies = {
  getMerchantPolicies,
  deleteMerchantPolicy,
  createMerchantPolicy,
  editMerchantPolicy,
};

const managedClaims = { getManagedClaimStatus };

const repairs = {
  getRepairInvoiceDetails,
  sendRepairInvoice,
  updateRepairInvoice,
};

const orderManagement = {
  createCarrierAccount,
  getCarrierAccounts,
  deleteCarrierAccount,
  addOutboundFunds,
  getEndShippers,
  updateEndShipper,
  createEndShipper,
};

const tags = { getTeamTags, upsertTag, deleteTag };

/** This type is necessary because redoMerchantRPC is too
 *  big for typescript to infer its type. */
type RpcDefinition = typeof redoMerchantRPC1 &
  typeof redoMerchantRPC2 &
  typeof upsellPage1 &
  typeof upsellPage2 &
  typeof upsellPage3 &
  typeof upsellPageAnalytics &
  typeof industryCategory &
  typeof returnItemDisposition &
  typeof merchantPolicies &
  typeof support &
  typeof faq &
  typeof restock &
  typeof managedClaims &
  typeof customer &
  typeof customerWidget &
  typeof repairs &
  typeof orderManagement &
  typeof tags &
  typeof products;

export const rpcDefinition: RpcDefinition = {
  ...redoMerchantRPC1,
  ...redoMerchantRPC2,
  ...upsellPage1,
  ...upsellPage2,
  ...upsellPage3,
  ...upsellPageAnalytics,
  ...industryCategory,
  ...returnItemDisposition,
  ...merchantPolicies,
  ...support,
  ...faq,
  ...restock,
  ...managedClaims,
  ...customer,
  ...customerWidget,
  ...repairs,
  ...orderManagement,
  ...tags,
  ...products,
};

export type RedoMerchantRpcDefinition = InferRpcDefinition<
  typeof rpcDefinition
>;
