import * as amplitude from "@amplitude/analytics-browser";
import Alert from "@mui/material/Alert";
import Snackbar from "@mui/material/Snackbar";
import { useRequiredContext } from "@redotech/react-util/context";
import { Order } from "@redotech/redo-model/order";
import { Button, ButtonTheme } from "@redotech/redo-web/button";
import { ExternalLink } from "@redotech/redo-web/link";
import { Modal, ModalSize, PaddingAmount } from "@redotech/redo-web/modal";
import { OrderLineItem } from "@redotech/redo-web/order-line-item";
import { ShippingContactInfo } from "@redotech/redo-web/shipping-contact-info";
import { memo, useState } from "react";
import { RedoMerchantClientContext } from "../../client/context";
import { duplicateOrder } from "../../client/order";
import * as duplicateOrderModalCss from "./duplicate-order-modal.module.css";

export const DuplicateOrderModal = memo(function DuplicateOrderModal({
  open,
  setOpen,
  order,
}: {
  open: boolean;
  setOpen: (open: boolean) => void;
  order: Order;
}) {
  const [pending, setPending] = useState(false);
  const [successModalOpen, setSuccessModalOpen] = useState(false);
  const [draftOrderUrl, setDraftOrderUrl] = useState<string>();
  const [errorMessage, setErrorMessage] = useState<string>("");
  const [showErrorMessage, setShowErrorMessage] = useState(false);
  const client = useRequiredContext(RedoMerchantClientContext);
  const lineItems = order.shopify?.line_items;
  const handleDuplicate = async () => {
    setPending(true);
    const response = await duplicateOrder(client, { orderId: order._id });
    amplitude.logEvent("duplicate-order", { shopifyOrderId: order.shopify_id });
    setPending(false);
    if (response.draftOrderUrl) {
      setDraftOrderUrl(response.draftOrderUrl);
      setSuccessModalOpen(true);
    } else {
      setErrorMessage("Failed to duplicate order");
      setShowErrorMessage(true);
      handleClose();
    }
  };
  const handleClose = () => {
    setPending(false);
    setOpen(false);
    setSuccessModalOpen(false);
  };
  const Footer = memo(function Footer() {
    return (
      <div className={duplicateOrderModalCss.footer}>
        <Button onClick={handleClose} theme={ButtonTheme.OUTLINED}>
          Cancel
        </Button>
        <Button
          onClick={() => handleDuplicate()}
          pending={pending}
          theme={ButtonTheme.PRIMARY}
        >
          Duplicate order
        </Button>
      </div>
    );
  });
  const SuccessModalFooter = memo(function SuccessModalFooter() {
    return (
      <div className={duplicateOrderModalCss.actionButtonsContainer}>
        <Button onClick={handleClose} theme={ButtonTheme.OUTLINED}>
          Close
        </Button>
      </div>
    );
  });
  return (
    <>
      <Modal
        footer={<Footer />}
        onClose={handleClose}
        open={open}
        paddingAmount={PaddingAmount.NONE}
        showFooterBorder
        size={ModalSize.MEDIUM}
        title="Duplicate order"
      >
        <div className={duplicateOrderModalCss.modalContent}>
          <div className={duplicateOrderModalCss.orderName}>
            Order {order.shopify.name}
          </div>
          <div className={duplicateOrderModalCss.lineItems}>
            {lineItems.map((lineItem, index) => (
              <OrderLineItem
                index={index}
                key={lineItem.id}
                lineItem={lineItem}
              />
            ))}
          </div>
          <ShippingContactInfo order={order} />
        </div>
      </Modal>
      <Modal
        footer={<SuccessModalFooter />}
        onClose={handleClose}
        open={successModalOpen}
        size={ModalSize.SMALL}
        title="Order successfully duplicated"
      >
        <div className={duplicateOrderModalCss.orderName}>
          A draft order was created
        </div>
        <p>
          Send invoices and manage it on{" "}
          <ExternalLink url={draftOrderUrl}>Shopify</ExternalLink>
        </p>
      </Modal>
      {showErrorMessage && (
        <Snackbar
          autoHideDuration={10000}
          onClose={() => setShowErrorMessage(false)}
          open={showErrorMessage}
        >
          <Alert onClose={() => setShowErrorMessage(false)} severity="error">
            {errorMessage}
          </Alert>
        </Snackbar>
      )}
    </>
  );
});
