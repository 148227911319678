import { FulfillmentOrderData } from "@redotech/redo-model/fulfillments/fulfillment-group";
import { RedoTableTextCell } from "@redotech/redo-web/arbiter-components/tables/redo-table-cells";
import { ColumnAlignment } from "@redotech/redo-web/table";
import { Column2 } from "@redotech/redo-web/table2";

export const RequestedServiceColumn: Column2<FulfillmentOrderData> = {
  key: "requested-service",
  title: "Requested service",
  alignment: ColumnAlignment.LEFT,
  renderNormalCell: ({ row }) => (
    <RedoTableTextCell text={row.shippingMethodNames?.[0] ?? ""} />
  ),
  width: 160,
};
