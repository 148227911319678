import { zExt } from "@redotech/redo-model/common/zod-util";
import { DiscountGroupType } from "@redotech/redo-model/discount";
import { baseDiscountSchema } from "@redotech/redo-model/discount/discount-db-parser";
import { z } from "zod";

export const getDiscountsByTeam = {
  input: z.object({
    teamId: zExt.objectId(),
    groupType: z.nativeEnum(DiscountGroupType).optional(),
  }),
  output: z.array(
    z.intersection(z.object({ _id: zExt.objectId() }), baseDiscountSchema),
  ),
};
