import { IterableMap } from "@redotech/react-util/component";
import * as classNames from "classnames";
import { ReactElement, memo } from "react";
import * as actionMenuCss from "./action-menu.module.css";

export namespace Alignment {
  export const HORIZONTAL = Symbol("Horizontal");
  export const VERTICAL = Symbol("Vertical");
}

export type Alignment = typeof Alignment.HORIZONTAL | typeof Alignment.VERTICAL;

export type ActionMenuItem = {
  Icon(props: any): ReactElement;
  id: string;
  textColor?: string;
  text?: string;
  onClick: () => void;
};

export const ActionMenu = memo(function ActionMenu({
  open,
  setOpen,
  items,
  alignment = Alignment.VERTICAL,
  width,
  height,
  showDividers = true,
  clickAwayListener = true,
  left,
  right,
  top,
  bottom,
}: {
  open: boolean;
  setOpen: (open: boolean) => void;
  items: Array<ActionMenuItem>;
  alignment?: Alignment;
  width?: number;
  height?: number;
  showDividers?: boolean;
  clickAwayListener?: boolean;
  left?: number;
  right?: number;
  top?: number;
  bottom?: number;
}) {
  if (!open) {
    return null;
  }
  return (
    <div
      className={classNames(
        actionMenuCss.main,
        alignment === Alignment.HORIZONTAL
          ? actionMenuCss.horizontal
          : actionMenuCss.vertical,
      )}
      style={{
        width: width ? `${width}px` : undefined,
        height: height ? `${height}px` : undefined,
        left: left ? `${left}px` : undefined,
        right: right ? `${right}px` : undefined,
        top: top ? `${top}px` : undefined,
        bottom: bottom ? `${bottom}px` : undefined,
      }}
    >
      {clickAwayListener && (
        <div
          className={actionMenuCss.clickAwayListener}
          onClick={() => setOpen(false)}
        />
      )}
      <IterableMap items={items} keyFn={(_, index) => index}>
        {(item, index) => {
          const dividerClass =
            showDividers && index < items.length - 1
              ? alignment === Alignment.VERTICAL
                ? actionMenuCss.dividerBottom
                : actionMenuCss.dividerRight
              : "";
          return (
            <div
              className={classNames(actionMenuCss.item, dividerClass)}
              key={index}
              onClick={item.onClick}
              style={{ color: item.textColor || "inherit" }}
            >
              {item.Icon && <item.Icon height={20} width={20} />}
              {item.text}
            </div>
          );
        }}
      </IterableMap>
    </div>
  );
});
