import { z } from "zod";
import { ConversationTagSource } from "./conversation-tags/conversation-tag-record";
import { CustomerTagSource } from "./customer-tags/customer-tag-record";
import { PillTheme } from "./pill-theme";

export enum TagKind {
  FULFILLMENT_GROUP = "fulfillment-group",
  CONVERSATION = "conversation",
  CUSTOMER = "customer",
}

export enum FulfillmentGroupTagSource {
  MERCHANT = "merchant",
  REDO = "redo",
}

export const TagKindSchema = z.nativeEnum(TagKind);
export const PillThemeSchema = z.nativeEnum(PillTheme);
export const CustomerTagSourceSchema = z.nativeEnum(CustomerTagSource);
export const ConversationTagSourceSchema = z.nativeEnum(ConversationTagSource);
export const FulfillmentGroupTagSourceSchema = z.nativeEnum(
  FulfillmentGroupTagSource,
);

export const TagSchema = z.object({
  name: z.string(),
  pillTheme: PillThemeSchema,
  source: z.union([
    CustomerTagSourceSchema,
    ConversationTagSourceSchema,
    FulfillmentGroupTagSourceSchema,
  ]),
  kind: TagKindSchema,
});

export type Tag = z.infer<typeof TagSchema>;
