// extracted by mini-css-extract-plugin
export var chip = "_2l-9";
export var chipLabel = "_2l-a";
export var close = "_2l-7";
export var closeIcon = "_2l-8";
export var error = "_2l-6";
export var infoTooltip = "_2l-2";
export var innerContainer = "_2l-1";
export var input = "_2l-b";
export var medium = "_2l-5";
export var outerContainer = "_2l-0";
export var small = "_2l-4";
export var xSmall = "_2l-3";