import { MarketingChannel } from "@redotech/redo-model/marketing";
import { zExt } from "@redotech/rpc/ext";
import { z } from "zod";

export const getEstimatedCampaignRecipientCount = {
  input: z.object({
    groups: z.array(zExt.objectId()),
    subscriptionType: z.nativeEnum(MarketingChannel),
  }),
  output: z.object({ count: z.number() }),
};
