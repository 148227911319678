export enum Step {
  NAME = "name",
  CHIPS = "chips",
  PERSONALITY = "personality",
  KNOWLEDGE = "knowledge",
  CUSTOMER_WIDGET = "customer-widget",
  STOREFRONT_SETUP = "storefront-setup",
}

export const stepsOrdered = [
  Step.NAME,
  Step.CHIPS,
  Step.PERSONALITY,
  Step.KNOWLEDGE,
  Step.CUSTOMER_WIDGET,
  Step.STOREFRONT_SETUP,
] as const;
