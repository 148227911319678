import { GetUser } from "@redotech/redo-model/user";
import { createContext } from "react";

export type UserContextValue = GetUser | undefined;
export const UserContext = createContext<UserContextValue>(undefined);
UserContext.displayName = "UserContext";

export const ReloadUserContext = createContext<(() => void) | undefined>(
  undefined,
);
ReloadUserContext.displayName = "ReloadUserContext";
