import { CustomerWidgetChipOptions } from "@redotech/redo-model/customer-widget/customer-widget";
import { z } from "zod";

export const updateCustomerWidgetSettings = {
  input: z.object({
    widgetColor: z.string(),
    selectedChips: z.array(z.nativeEnum(CustomerWidgetChipOptions)),
    otherChipText: z.string().optional(),
  }),
  output: z.null(),
};
