// extracted by mini-css-extract-plugin
export var chevron = "_4v-a";
export var disabled = "_4v-9";
export var icon = "_4v-b";
export var input = "_4v-0";
export var open = "_4v-7";
export var placeholder = "_4v-8";
export var regular = "_4v-3";
export var singleSelectInput = "_4v-1 _4v-0";
export var small = "_4v-2";
export var spinnerContainer = "_4v-c";
export var tag = "_4v-5";
export var tagsContainer = "_4v-6";
export var tagsInput = "_4v-4 _4v-0";