import { FulfillmentOrderData } from "@redotech/redo-model/fulfillments/fulfillment-group";
import {
  FulfillmentOrderAddress,
  VerifiedAddress,
} from "@redotech/redo-model/fulfillments/fulfillment-order-address";
import {
  RedoBadge,
  RedoBadgeColor,
  RedoBadgeSize,
} from "@redotech/redo-web/arbiter-components/badge/redo-badge";
import AlertTriangleIcon from "@redotech/redo-web/arbiter-icon/alert-triangle.svg";
import DotIcon from "@redotech/redo-web/arbiter-icon/dot-01.svg";
import { Flex } from "@redotech/redo-web/flex";
import { ColumnAlignment } from "@redotech/redo-web/table";
import { Column2 } from "@redotech/redo-web/table2";
import { Tooltip } from "@redotech/redo-web/tooltip/tooltip";

function addressesAreDifferent(
  address1: FulfillmentOrderAddress | VerifiedAddress | null | undefined,
  address2: FulfillmentOrderAddress | VerifiedAddress | null | undefined,
) {
  if (!address1 || !address2) {
    return false;
  }

  return (
    address1.address1?.toLowerCase() !== address2.address1?.toLowerCase() ||
    (address1.address2 || "").toLowerCase() !==
      (address2.address2 || "").toLowerCase() ||
    (address1.city || "").toLowerCase() !==
      (address2.city || "").toLowerCase() ||
    address1.province?.toLowerCase() !== address2.province?.toLowerCase() ||
    address1.zip?.slice(0, 5).toLowerCase() !==
      address2.zip?.slice(0, 5).toLowerCase() ||
    address1.countryCode?.toLowerCase() !== address2.countryCode?.toLowerCase()
  );
}

function formatAddress(
  address: FulfillmentOrderAddress | VerifiedAddress | null | undefined,
) {
  if (!address) {
    return "";
  }

  return `${address.address1}\n${address.address2 ? `${address.address2}\n` : ""}${address.city} ${address.province} ${address.zip} ${address.countryCode}`;
}

export const AddressVerificationColumn: Column2<FulfillmentOrderData> = {
  alignment: ColumnAlignment.LEFT,
  key: "address-verification",
  title: "Address Verification",
  renderNormalCell: ({ row }: { row: FulfillmentOrderData }) => (
    <Flex pl="lg">
      {row.destinationAddress.verified ? (
        addressesAreDifferent(
          row.destinationAddress,
          row.verifiedDestinationAddress,
        ) ? (
          <Tooltip
            placement="bottom-start"
            title={`Suggested Address:\n${formatAddress(row.verifiedDestinationAddress)}`}
          >
            <div>
              <RedoBadge
                color={RedoBadgeColor.WARNING}
                segmentLeading={{ type: "icon", Icon: DotIcon }}
                size={RedoBadgeSize.X_SMALL}
                text="Needs Verification"
              />
            </div>
          </Tooltip>
        ) : (
          <RedoBadge
            color={RedoBadgeColor.SUCCESS}
            segmentLeading={{ type: "icon", Icon: DotIcon }}
            size={RedoBadgeSize.X_SMALL}
            text="Verified"
          />
        )
      ) : (
        <Tooltip
          placement="bottom-start"
          title={row.destinationAddress.messages?.join(", ") ?? ""}
        >
          <div>
            <RedoBadge
              color={RedoBadgeColor.ERROR}
              segmentLeading={{ type: "icon", Icon: AlertTriangleIcon }}
              size={RedoBadgeSize.X_SMALL}
              text="Error"
            />
          </div>
        </Tooltip>
      )}
    </Flex>
  ),
  width: 160,
};
