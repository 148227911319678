import { FulfillmentOrderUpdate } from "@redotech/merchant-sdk/outbound-labels-rpc/schema/update-fulfillment-groups";
import { FulfillmentOrderData } from "@redotech/redo-model/fulfillments/fulfillment-group";
import { PrintKind } from "@redotech/redo-model/fulfillments/fullfilment-print-status";
import { SortDirection } from "@redotech/redo-model/tables/table";
import { ColumnAlignment } from "@redotech/redo-web/table";
import { Column2 } from "@redotech/redo-web/table2";
import { IsPrintedCell } from "../cells/is-printed-cell";

export const printKindToTitle = {
  [PrintKind.PICK_LIST]: "Pick List",
  [PrintKind.PACKING_SLIP]: "Packing Slip",
  [PrintKind.LABEL]: "Label",
};

const printKindToKey = {
  [PrintKind.PICK_LIST]: "pick-list",
  [PrintKind.PACKING_SLIP]: "packing-slip",
  [PrintKind.LABEL]: "label",
};

export const isPrintedColumn = function isPrintedColumn(
  kind: PrintKind,
  updateRowData: (row: FulfillmentOrderUpdate[]) => void,
): Column2<FulfillmentOrderData> {
  return {
    key: `is-${printKindToKey[kind]}-printed`,
    title: `${printKindToTitle[kind]} Printed`,
    alignment: ColumnAlignment.LEFT,
    renderNormalCell: ({ row }) => {
      return (
        <IsPrintedCell kind={kind} row={row} updateRowData={updateRowData} />
      );
    },
    width: 120,
    sort: SortDirection.ASC,
  };
};
