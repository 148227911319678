import { RedoTableTextCell } from "@redotech/redo-web/arbiter-components/tables/redo-table-cells";
import { Dropdown } from "@redotech/redo-web/dropdown";
import { Text } from "@redotech/redo-web/text";
import { memo, useRef, useState } from "react";

export const StringListCell = memo(function StringListCell({
  strings,
  displayName,
}: {
  strings: string[];
  displayName: string;
}) {
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const [dropdownAnchor, setDropdownAnchor] = useState<HTMLDivElement | null>(
    null,
  );
  const timeoutRef = useRef<NodeJS.Timeout>();

  if (strings.length === 1) {
    return <RedoTableTextCell text={strings[0]} />;
  }

  return (
    <>
      <div
        onMouseEnter={() => {
          timeoutRef.current = setTimeout(() => {
            setIsDropdownOpen(true);
          }, 700);
        }}
        onMouseLeave={() => {
          if (timeoutRef.current) {
            clearTimeout(timeoutRef.current);
          }
          setIsDropdownOpen(false);
        }}
        ref={setDropdownAnchor}
      >
        <RedoTableTextCell text={`${strings.length} ${displayName}s`} />
      </div>
      <Dropdown
        anchor={dropdownAnchor}
        fitToAnchor={false}
        open={isDropdownOpen}
      >
        {strings.map((string) => (
          <Text fontSize="xs" key={string} textColor="secondary">
            {string}
          </Text>
        ))}
      </Dropdown>
    </>
  );
});
