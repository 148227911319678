import { ClickAwayListener, Popper } from "@mui/material";
import CalendarIcon from "@redotech/redo-web/arbiter-icon/calendar-date.svg";
import { memo, useState } from "react";
import Calendar from "react-calendar";
import { getDateString } from "../../date-utils";
import { Flex } from "../../flex";
import {
  RedoButton,
  RedoButtonHierarchy,
  RedoButtonSize,
} from "../buttons/redo-button";

// CSS imports must be ordered
import "react-time-picker/dist/TimePicker.css";
//
import "@wojtekmaj/react-daterange-picker/dist/DateRangePicker.css";
//
import "react-calendar/dist/Calendar.css";
//
import { useHandler } from "@redotech/react-util/hook";
import "@redotech/redo-web/date-range.module.css";
import { sortDatesNewestToOldest } from "@redotech/util/date";
import { MaybeDate } from "../../date-picker";

export interface RedoDatePickerProps {
  date: Date | undefined;
  disabled?: boolean;
  setDate: (date: Date) => void;
  buttonSize?: RedoButtonSize;
  minDate?: Date;
  maxDate?: Date;
}
/**
 *
 * INCOMPLETE
 *
 * The date picker needs to be updated to be arbiter, but this provides
 * the arbiter compliant button, and makes a standard interface to use
 */
export const RedoDatePicker = memo(function RedoDatePicker({
  date,
  disabled,
  setDate,
  minDate,
  maxDate,
  buttonSize = RedoButtonSize.LARGE,
}: RedoDatePickerProps) {
  const buttonDisplay = date ? getDateString(date) : "Select date";

  const [dropdownAnchor, setDropdownAnchor] = useState<HTMLElement | null>(
    null,
  );

  const [pickerOpen, setPickerOpen] = useState(false);

  const handleSetCustomDate = useHandler((value: Date) => {
    setDate(value);
    setPickerOpen(false);
  });

  const handleClick = useHandler((e: React.MouseEvent) => {
    e.stopPropagation();
    setPickerOpen((open) => !open);
  });

  return (
    <>
      <RedoButton
        centerItems={false}
        disabled={disabled}
        hierarchy={RedoButtonHierarchy.SECONDARY}
        IconLeading={CalendarIcon}
        onClick={handleClick}
        ref={setDropdownAnchor}
        size={buttonSize}
        text={buttonDisplay}
      />
      <ClickAwayListener onClickAway={() => setPickerOpen(false)}>
        <Popper
          anchorEl={dropdownAnchor}
          modifiers={[{ name: "offset", options: { offset: [0, 8] } }]}
          open={pickerOpen}
        >
          <Flex>
            <Calendar
              maxDate={maxDate}
              minDate={minDate}
              minDetail="year"
              onChange={handleSetCustomDate}
              selectRange={false}
              value={date}
            />
          </Flex>
        </Popper>
      </ClickAwayListener>
    </>
  );
});

export interface RedoDateRangePickerProps {
  dateRange: [Date, Date] | undefined;
  setDateRange: (dateRange: [Date, Date]) => void;
  buttonSize?: RedoButtonSize;
  minDate?: Date;
  maxDate?: Date;
}

/**
 *
 * INCOMPLETE
 *
 * The date picker needs to be updated to be arbiter, but this provides
 * the arbiter compliant button, and makes a standard interface to use
 */
export const RedoDateRangePicker = memo(function RedoDateRangePicker({
  dateRange,
  setDateRange,
  buttonSize,
  minDate,
  maxDate,
}: RedoDateRangePickerProps) {
  const buttonDisplay = dateRange
    ? `${getDateString(dateRange[0])} - ${getDateString(dateRange[1])}`
    : "Select dates";

  const [dropdownAnchor, setDropdownAnchor] = useState<HTMLElement | null>(
    null,
  );

  const [pickerOpen, setPickerOpen] = useState(false);

  const handleSetCustomDate = useHandler((value: [MaybeDate, MaybeDate]) => {
    if (value[0] && value[1]) {
      const sorted = sortDatesNewestToOldest(value as Date[]) as [Date, Date];
      setDateRange(sorted);
      setPickerOpen(false);
    }
  });

  return (
    <>
      <Flex>
        <RedoButton
          hierarchy={RedoButtonHierarchy.SECONDARY}
          IconLeading={CalendarIcon}
          onClick={(e) => {
            e.stopPropagation();
            setPickerOpen((open) => !open);
          }}
          ref={setDropdownAnchor}
          size={buttonSize}
          text={buttonDisplay}
        />
      </Flex>
      <ClickAwayListener onClickAway={() => setPickerOpen(false)}>
        <Popper
          anchorEl={dropdownAnchor}
          modifiers={[{ name: "offset", options: { offset: [0, 8] } }]}
          open={pickerOpen}
        >
          <Flex>
            <Calendar
              maxDate={maxDate}
              minDate={minDate}
              minDetail="year"
              onChange={handleSetCustomDate}
              selectRange
            />
          </Flex>
        </Popper>
      </ClickAwayListener>
    </>
  );
});
