// extracted by mini-css-extract-plugin
export var bubbleContent = "_7z-0";
export var dark = "_7z-2";
export var dot = "_7z-7";
export var firstDot = "_7z-9";
export var left = "_7z-3";
export var light = "_7z-1";
export var loadingBubble = "_7z-5";
export var loadingDot = "_7z-c";
export var loadingDotContainer = "_7z-6";
export var moveUpDown = "_7z-8";
export var right = "_7z-4";
export var secondDot = "_7z-a";
export var thirdDot = "_7z-b";