import { genericMemo } from "@redotech/react-util/component";
import {
  StringMatchOperator,
  TextMatchExpression,
  textMatchOperators,
} from "@redotech/redo-model/advanced-flow/expressions/text-expression";
import { Schema } from "@redotech/redo-model/advanced-flow/type-system/schema";
import { RedoTagInputField } from "@redotech/redo-web/arbiter-components/input/redo-tag-input-field";
import { RedoListItem } from "@redotech/redo-web/arbiter-components/list/redo-list";
import {
  RedoDropdownInputSize,
  RedoSingleSelectDropdownInput,
} from "@redotech/redo-web/arbiter-components/select-dropdown/redo-single-select-dropdown-input";
import { filterTruthy } from "@redotech/util/array";
import { DeepPartial } from "@redotech/util/type";
import { useEffect, useMemo, useState } from "react";
import { camelToTitleCase } from ".";

const textMatchOperatorItems: RedoListItem<StringMatchOperator>[] =
  textMatchOperators.map((operator) => ({ value: operator }));

export const TextInspector = genericMemo(function TextInspector({
  schema,
  dataType,
  expression,
  setExpression,
}: {
  schema: Schema;
  dataType: "Text" | "Url";
  expression: DeepPartial<TextMatchExpression>;
  setExpression: (expression: DeepPartial<TextMatchExpression>) => void;
}) {
  useEffect(() => {
    if (!expression.type) {
      setExpression({ ...expression, type: "text_match" });
    }
    if (!expression.operator) {
      setExpression({ ...expression, operator: "equals" });
    }
  }, [expression]);

  const selectedItem = useMemo(() => {
    return expression.operator ? { value: expression.operator } : undefined;
  }, [expression.operator]);

  const [tagInputValue, setTagInputValue] = useState<string>("");

  const tags = useMemo(() => {
    return filterTruthy(
      expression.matchValues?.map((value) =>
        value ? { text: value } : undefined,
      ) ?? [],
    );
  }, [expression.matchValues]);

  return (
    <>
      <RedoSingleSelectDropdownInput<StringMatchOperator>
        label="Text match type"
        options={textMatchOperatorItems}
        optionSelected={(operator) => {
          setExpression({ ...expression, operator: operator.value });
        }}
        selectedItem={selectedItem}
        size={RedoDropdownInputSize.SMALL}
      >
        {({ value }) => camelToTitleCase(value)}
      </RedoSingleSelectDropdownInput>

      <RedoTagInputField
        inputValue={tagInputValue}
        label="Values"
        setInputValue={setTagInputValue}
        setTags={(tags) => setExpression({ ...expression, matchValues: tags })}
        tags={tags}
      />
    </>
  );
});
