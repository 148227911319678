import { FulfillmentOrderUpdate } from "@redotech/merchant-sdk/outbound-labels-rpc/schema/update-fulfillment-groups";
import { useRequiredContext } from "@redotech/react-util/context";
import { useHandler } from "@redotech/react-util/hook";
import { LoadState } from "@redotech/react-util/load";
import { FulfillmentOrderData } from "@redotech/redo-model/fulfillments/fulfillment-group";
import { PillTheme } from "@redotech/redo-model/pill-theme";
import {
  FulfillmentGroupTagSource,
  Tag,
  TagKind,
} from "@redotech/redo-model/tag";
import {
  RedoModal,
  RedoModalSize,
} from "@redotech/redo-web/arbiter-components/modal/redo-modal";
import { Flex } from "@redotech/redo-web/flex";
import { ItemDropdownMultiSelect } from "@redotech/redo-web/item-dropdown-multi-select";
import { CreateTagModal } from "@redotech/redo-web/tags/create-tag-modal";
import { TagPill } from "@redotech/redo-web/tags/tag-pill";
import { useState } from "react";
import { RedoMerchantRpcClientContext } from "../../../app/redo-merchant-rpc-client-provider";
import { TagList } from "../../common/tag-list";

function getUniqueTags(tags: Tag[]) {
  const set = new Set();
  return tags.filter((tag) => {
    if (set.has(tag.name)) {
      return;
    }
    set.add(tag.name);
    return tag;
  });
}

export const Tags = ({
  data,
  updateData,
  tagWindowOpen,
  setTagWindowOpen,
  availableTagsLoad,
  getAvailableTags,
}: {
  data: FulfillmentOrderData;
  updateData: (updates: FulfillmentOrderUpdate[]) => void;
  tagWindowOpen: string | null;
  setTagWindowOpen: (tagWindowOpen: string | null) => void;
  availableTagsLoad: LoadState<Tag[] | undefined>;
  getAvailableTags: (signal?: AbortSignal) => void;
}) => {
  const rpcClient = useRequiredContext(RedoMerchantRpcClientContext);
  const [dropdownAnchor, setDropdownAnchor] = useState<HTMLElement | null>(
    null,
  );
  const [tagBeingDeleted, setTagBeingDeleted] = useState<Tag | undefined>();
  const [createTagModalOpen, setCreateTagModalOpen] = useState(false);
  const currentTags = data.tags ? [...data.tags] : [];

  const selectDropdownOpen = tagWindowOpen === data._id;

  const availableTags = availableTagsLoad.value ?? [];

  function tagIsSelected(tag: Tag) {
    return currentTags.some((current) => current.name === tag.name);
  }
  function removeTagFromFulfillmentGroup(tag: Tag) {
    const newTags = currentTags.filter((current) => current.name !== tag.name);
    updateData([{ tags: newTags, fulfillmentGroupId: data._id }]);
  }

  function addTagToFulfillmentGroup(tag: Tag) {
    const newTags = [...currentTags, tag];
    updateData([{ tags: newTags, fulfillmentGroupId: data._id }]);
  }

  function onItemSelected(tag: Tag) {
    if (tagIsSelected(tag)) {
      removeTagFromFulfillmentGroup(tag);
    } else {
      addTagToFulfillmentGroup(tag);
    }
  }

  const addNewTagButton = {
    name: "Add tag",
    pillTheme: PillTheme.NORMAL,
    source: FulfillmentGroupTagSource.MERCHANT,
    kind: TagKind.FULFILLMENT_GROUP,
  };

  const existingTags = (
    <div
      onClick={(e) => {
        e.stopPropagation();
        setTagWindowOpen(data._id);
      }}
      ref={setDropdownAnchor}
    >
      <TagList
        tags={currentTags.length > 0 ? currentTags : [addNewTagButton]}
      />
    </div>
  );

  const uniqueTags = getUniqueTags([...(data.tags ?? []), ...availableTags]);

  const dropdownItems = (
    <ItemDropdownMultiSelect<Tag>
      dropdownAnchor={dropdownAnchor}
      isItemSelected={tagIsSelected}
      itemLabel="tag"
      items={uniqueTags}
      onCreateItem={() => setCreateTagModalOpen(true)}
      onItemClick={onItemSelected}
      onMenuSelect={setTagBeingDeleted}
      openOnRender={selectDropdownOpen}
      setClosedOnRender={() => {
        setTagWindowOpen(null);
      }}
    >
      {(item) => <TagPill tag={item} />}
    </ItemDropdownMultiSelect>
  );

  const handleSubmit = useHandler(
    async ({ name, pillTheme }: { name: string; pillTheme: PillTheme }) => {
      setTagWindowOpen(data._id);
      setCreateTagModalOpen(false);
      addTagToFulfillmentGroup({
        name,
        pillTheme,
        source: FulfillmentGroupTagSource.MERCHANT,
        kind: TagKind.FULFILLMENT_GROUP,
      });

      await rpcClient.upsertTag({
        tag: {
          name,
          kind: TagKind.FULFILLMENT_GROUP,
          pillTheme,
          source: FulfillmentGroupTagSource.MERCHANT,
        },
      });
      getAvailableTags();
    },
  );

  const handleDelete = useHandler(async (tag: Tag) => {
    removeTagFromFulfillmentGroup(tag);
    await rpcClient.deleteTag({ name: tag.name, kind: tag.kind });
    getAvailableTags();
  });

  const deleteModal = tagBeingDeleted && (
    <RedoModal
      modalSize={RedoModalSize.SMALL}
      onModalCloseRequested={() => {
        setTagBeingDeleted(undefined);
      }}
      primaryButton={{
        text: "Delete",
        onClick: async () => {
          await handleDelete(tagBeingDeleted);
          setTagBeingDeleted(undefined);
        },
      }}
      secondaryButton={{
        text: "Cancel",
        onClick: () => {
          setTagBeingDeleted(undefined);
        },
      }}
      subtitle="Tag will not be removed from current orders"
      title="Remove tag"
    />
  );

  const createModal = createTagModalOpen && (
    <div onClick={(e) => e.stopPropagation()}>
      <CreateTagModal
        cancelClicked={() => {
          setTagWindowOpen(data._id);
          setCreateTagModalOpen(false);
        }}
        handleCreateTag={handleSubmit}
      />
    </div>
  );

  return (
    <div>
      {existingTags}
      {dropdownItems}
      {createModal}
      {deleteModal}
    </div>
  );
};

export const TagsCell = ({
  rowData,
  updateRowData,
  tagWindowOpen,
  setTagWindowOpen,
  availableTagsLoad,
  getAvailableTags,
}: {
  rowData: FulfillmentOrderData;
  updateRowData: (rowData: FulfillmentOrderUpdate[]) => void;
  tagWindowOpen: string | null;
  setTagWindowOpen: (tagWindowOpen: string | null) => void;
  availableTagsLoad: LoadState<Tag[] | undefined>;
  getAvailableTags: (signal?: AbortSignal) => void;
}) => {
  return (
    <Flex
      onClick={(e) => {
        e.stopPropagation();
      }}
      p="lg"
    >
      <Tags
        availableTagsLoad={availableTagsLoad}
        data={rowData}
        getAvailableTags={getAvailableTags}
        setTagWindowOpen={setTagWindowOpen}
        tagWindowOpen={tagWindowOpen}
        updateData={updateRowData}
      />
    </Flex>
  );
};
