import { campaignRecipientSchema } from "@redotech/redo-model/campaigns/campaign-recipient-definition";
import { campaignRecipientColumnKeys } from "@redotech/redo-model/tables/campaign-recipients-list-sort";
import { SortDirection } from "@redotech/redo-model/tables/table";
import { z } from "zod";

export const getCampaignRecipientsCount = {
  input: z.object({ campaignId: z.string() }),
  output: z.object({ count: z.number() }),
};

export const paginateCampaignRecipients = {
  input: z.object({
    campaignId: z.string(),
    lastItem: z.optional(campaignRecipientSchema),
    sort: z.optional(
      z.object({
        direction: z.nativeEnum(SortDirection),
        key: z.enum(campaignRecipientColumnKeys),
      }),
    ),
    limit: z.number().optional(),
  }),
  output: z.object({ data: z.array(campaignRecipientSchema) }),
};
