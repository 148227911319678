// extracted by mini-css-extract-plugin
export var actionButton = "_bv-1";
export var actions = "_bv-0";
export var copyableField = "_bv-2";
export var icon = "_bv-3";
export var overflowVisible = "_bv-4";
export var pillWrapper = "_bv-5";
export var productDetails = "_bv-7";
export var productPillsContainer = "_bv-8";
export var productTopDetails = "_bv-9";
export var products = "_bv-6";
export var returnDetails = "_bv-a";
export var strong = "_bv-b";
export var variantQuantityRow = "_bv-c";