// extracted by mini-css-extract-plugin
export var active = "_bc-b";
export var arrow = "_bc-8";
export var arrowContainer = "_bc-4";
export var container = "_bc-0";
export var disabled = "_bc-5";
export var dot = "_bc-a";
export var dotContainer = "_bc-9";
export var leftArrowContainer = "_bc-6 _bc-4";
export var rightArrowContainer = "_bc-7 _bc-4";
export var slide = "_bc-3";
export var sliderContainer = "_bc-1";
export var sliderOverflow = "_bc-2";