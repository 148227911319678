import { FulfillmentGroupStatus } from "@redotech/redo-model/fulfillments/fulfillment-group-status";
import { z } from "zod";

export const printPickList = {
  input: z.union([
    z.object({
      fulfillmentGroupIds: z.array(z.string()),
      selectedStatus: z.nativeEnum(FulfillmentGroupStatus),
    }),
    z.object({
      batchId: z.string(),
      selectedStatus: z.nativeEnum(FulfillmentGroupStatus),
    }),
  ]),
  output: z.object({ url: z.string() }),
};
