import { CSVFile, CSVRow, processCSV } from "@redotech/csv/csv-parser";
import { Currency } from "@redotech/money/currencies";
import {
  CreateFulfillmentOrder,
  CreateFulfillmentOrderSchema,
} from "@redotech/redo-model/fulfillments/create-fulfillment-order";
import { RedoShopifyCountryCode } from "@redotech/redo-model/fulfillments/fulfillment-country-code";

export async function parseApparelMagicCsv(
  file: File,
): Promise<CreateFulfillmentOrder[]> {
  const data: CSVFile = await processCSV(file);

  const fulfillmentOrders: CreateFulfillmentOrder[] = data.rows
    .map((row) => {
      try {
        return validateRow(row);
      } catch (error) {
        console.error(error);
        return null;
      }
    })
    .filter((row) => row !== null);

  return fulfillmentOrders;
}

function validateRow(row: CSVRow): CreateFulfillmentOrder {
  const name = row[ApparelMagicCsvColumns.NAME] ?? "";
  const address1 = row[ApparelMagicCsvColumns.STREET_ADDRESS] ?? "";
  const address2 = row[ApparelMagicCsvColumns.ADDRESS_2] ?? "";
  const city = row[ApparelMagicCsvColumns.CITY] ?? "";
  const state = row[ApparelMagicCsvColumns.STATE] ?? "";
  const zip = row[ApparelMagicCsvColumns.ZIP] ?? "";

  if (!name || !address1 || !city || !state || !zip) {
    throw new Error("Failed to parse row");
  }

  const data: CreateFulfillmentOrder = {
    address: {
      countryCode: RedoShopifyCountryCode.Us,
      name,
      address1,
      address2,
      city,
      province: state,
      zip,
    },
    orderInfo: {
      orderNumber: "",
      orderDate: new Date(),
      paidDate: new Date(),
      shippingPaid: { amount: "0", currency: Currency.USD },
      taxPaid: { amount: "0", currency: Currency.USD },
      totalPaid: { amount: "0", currency: Currency.USD },
      items: [],
    },
  };
  return CreateFulfillmentOrderSchema.parse(data);
}

export enum ApparelMagicCsvColumns {
  NAME = "Name*",
  STREET_ADDRESS = "Street Address*",
  ADDRESS_2 = "Address 2",
  CITY = "City*",
  STATE = "State*",
  ZIP = "Zip*",
  DIMS = "Dims (in)",
  WEIGHT = "Weight (lbs)",
}
