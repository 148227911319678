import { ConciergeProductKnowledgeParser } from "@redotech/redo-model/concierge-settings/product-knowledge";
import { z } from "zod";

export const updateProductSettings = {
  input: z.object({
    identifier: z.string(),
    hidden: z.boolean(),
    knowledge: ConciergeProductKnowledgeParser,
  }),
  output: z.null(),
};
