// extracted by mini-css-extract-plugin
export var animationContainer = "_8o-0";
export var conversationContainer = "_8o-1";
export var detailsContent = "_8o-2";
export var message = "_8o-3";
export var modalContent = "_8o-4";
export var modalFooter = "_8o-6";
export var modalHeader = "_8o-5";
export var name = "_8o-7";
export var optionContainer = "_8o-8";
export var optionTitle = "_8o-9";
export var pillContainer = "_8o-a";
export var selectedConversationsContainer = "_8o-b";
export var selectedOptionContainer = "_8o-c";