import * as amplitude from "@amplitude/analytics-browser";
import { ExpandedConversation } from "@redotech/redo-model/conversation";
import { Team } from "@redotech/redo-model/team";
import { RedoMerchantClient } from "../../client";
import { updateConversation } from "../../client/conversations";

export async function markConversationAsRead(
  client: RedoMerchantClient,
  conversation: ExpandedConversation,
  team: Team,
  viewingUserId: string,
): Promise<ExpandedConversation> {
  const result = await setIsConversationRead(
    client,
    conversation,
    team,
    viewingUserId,
    { isRead: true },
  );
  amplitude.logEvent("view-conversation", {
    conversationId: conversation._id,
    channel: conversation.platform,
  });
  return result;
}

export async function markConversationAsUnread(
  client: RedoMerchantClient,
  conversation: ExpandedConversation,
  team: Team,
  viewingUserId: string,
): Promise<ExpandedConversation> {
  const result = await setIsConversationRead(
    client,
    conversation,
    team,
    viewingUserId,
    { isRead: false },
  );
  amplitude.logEvent("markUnread-conversation", {
    conversationId: conversation._id,
    channel: conversation.platform,
  });
  return result;
}

async function setIsConversationRead(
  client: RedoMerchantClient,
  conversation: ExpandedConversation,
  team: Team,
  viewingUserId: string,
  { isRead }: { isRead: boolean },
): Promise<ExpandedConversation> {
  // Don't allow Redo employees to affect the 'read' status of conversations of teams they don't belong to
  if (!viewingUserBelongsToTeam(viewingUserId, team)) {
    return conversation;
  }
  // Don't send request if it wouldn't change the read status
  if (conversation.read === isRead) {
    return conversation;
  }
  // Eagerly update conversation in the meantime
  conversation.read = isRead;
  const updatedConversation = await updateConversation(client, conversation, {
    read: isRead,
  });
  return updatedConversation.data;
}

function viewingUserBelongsToTeam(viewingUserId: string, team: Team): boolean {
  return team.users.some((user) => {
    const userId = typeof user.user == "string" ? user.user : user.user._id;
    return userId === viewingUserId;
  });
}
