import { RedoListItem } from "@redotech/redo-web/arbiter-components/list/redo-list";
import {
  RedoDropdownInputSize,
  RedoSingleSelectDropdownInput,
} from "@redotech/redo-web/arbiter-components/select-dropdown/redo-single-select-dropdown-input";
import { Flex } from "@redotech/redo-web/flex";
import PackageIcon from "@redotech/redo-web/icon-old/package.svg";
import { Text } from "@redotech/redo-web/text";
import { OverflowTooltip } from "@redotech/redo-web/tooltip/overflow-tooltip";
import * as classNames from "classnames";
import { memo, useMemo, useState } from "react";
import {
  getCarrierLogoUrl,
  getPrettyCarrierName,
  getPrettyServiceName,
} from "../utils/outbound-labels-utils";
import * as styles from "./shipment-rate-selection-dropdown.module.css";

export interface OutboundRateOption {
  key?: string;
  carrier: string;
  service: string;
  rate?: number;
  currency?: string;
  deliveryDays?: string;
}

export const ShipmentRateSelectionDropdown = memo(
  function ShipmentRateSelectionDropdown({
    label,
    options,
    selectedRate,
    setSelectedRate,
    loading,
    mixedRates,
  }: {
    label?: string;
    options: readonly OutboundRateOption[];
    selectedRate: OutboundRateOption | null;
    mixedRates: boolean;
    setSelectedRate: (rate: OutboundRateOption) => void;
    loading?: boolean;
  }) {
    const onRateChange = (rate: RedoListItem<OutboundRateOption>) => {
      setSelectedRate(rate.value);
    };

    return (
      <RedoSingleSelectDropdownInput<OutboundRateOption>
        disabled={loading}
        label={label}
        options={options.map((rate) => ({
          value: rate,
          label: `${rate.carrier} ${rate.service}`,
        }))}
        optionSelected={onRateChange}
        placeholder={mixedRates ? "{mixed}" : "Select shipment rate"}
        selectedItem={selectedRate ? { value: selectedRate } : undefined}
        size={RedoDropdownInputSize.REGULAR}
      >
        {(option) => (
          <ShipmentRateSelectionDropdownOption option={option.value} />
        )}
      </RedoSingleSelectDropdownInput>
    );
  },
);

export const ShipmentRateSelectionDropdownOption = memo(
  function ShipmentRateSelectionDropdownOption({
    option,
  }: {
    option: OutboundRateOption | null;
  }) {
    const logoUrl = useMemo(() => {
      return option ? getCarrierLogoUrl(option?.carrier) : "";
    }, [option?.carrier]);

    const [overflowRef, setOverflowRef] = useState<HTMLDivElement | null>(null);

    const labelText = (option: OutboundRateOption | null) => {
      if (!option) {
        return "";
      }
      const rateText = option?.rate
        ? ` - $${Number(option.rate).toFixed(2)}`
        : "";
      return `${getPrettyCarrierName(option?.carrier)} ${getPrettyServiceName(option?.service)}${rateText}`;
    };

    const deliveryText = (option: OutboundRateOption | null) => {
      if (!option) {
        return "";
      }
      return `${
        option?.deliveryDays
          ? `${option?.deliveryDays} business day${option?.deliveryDays === "1" ? "" : "s"}`
          : ""
      }`;
    };

    return (
      <OverflowTooltip
        overflowRef={overflowRef}
        tooltipProps={{ title: labelText(option) }}
      >
        <Flex className={styles.selection}>
          {option ? (
            <Flex gap="lg" ref={setOverflowRef}>
              <Flex
                className={styles.logoContainer}
                flexDirection="column"
                justify="center"
              >
                {logoUrl !== "" && (
                  <img className={classNames(styles.nameLogo)} src={logoUrl} />
                )}
                {logoUrl === "" && (
                  <PackageIcon
                    classNames={classNames(styles.nameLogo)}
                    height={40}
                    width={40}
                  />
                )}
              </Flex>
              <Flex flexDirection="column" gap="none">
                <Text fontSize="sm">{labelText(option)}</Text>
                <Text fontSize="xs" textColor="secondary">
                  {deliveryText(option)}
                </Text>
              </Flex>
            </Flex>
          ) : (
            <div className={styles.placeholder}>No rate selected</div>
          )}
        </Flex>
      </OverflowTooltip>
    );
  },
);
