/** returnTypeHere -> Return type here */
function camelCaseToSemanticSpacedDisplay(text: string) {
  return text
    .replace(/([A-Z])/g, " $1")
    .replace(/^./, (match) => match.toUpperCase())
    .replace(/\s\w/g, (match) => match.toLowerCase());
}

/** snake_case_here -> Snake case here */
function snakeCaseToSemanticSpacedDisplay(text: string) {
  return text
    .replace(/_/g, " ")
    .replace(/^./, (match) => match.toUpperCase())
    .replace(/\s\w/g, (match) => match.toLowerCase());
}

/** Does camelcase first, then snakecase (since snakecase won't touch a de-camelized string, but vice versa will) */
export function variableToSemanticDisplay(text: string) {
  return [text]
    .map(camelCaseToSemanticSpacedDisplay)
    .map(snakeCaseToSemanticSpacedDisplay)
    .pop();
}

/** @param n - The index of the word to transform. If negative, it will transform the nth word from the end. */
export function transformNthWord(
  text: string,
  n: number,
  transform: (word: string) => string,
) {
  const indexToTransform = n < 0 ? text.split(" ").length + n : n;
  return text
    .split(" ")
    .map((word, index) => (index === indexToTransform ? transform(word) : word))
    .join(" ");
}
