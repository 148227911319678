import { Order } from "@redotech/redo-model/order";
import { CopyableText } from "@redotech/redo-web/copyable-text";
import { Flex } from "@redotech/redo-web/flex";
import { Text } from "@redotech/redo-web/text";
import { unique } from "@redotech/util/array";
import { memo } from "react";
import { z } from "zod";

const shippingLinesValidator = z.array(
  z.object({ title: z.string(), price: z.string() }),
);

export const OrderDetailsShippingMethod = memo(
  function ShippingMethodInformation({ order }: { order: Order }) {
    const parsedItem = shippingLinesValidator.safeParse(
      order.shopify.shipping_lines,
    );
    if (!parsedItem.success) {
      return null;
    }

    const shippingLines = parsedItem.data;

    const uniqueShippingLines = unique(
      shippingLines,
      (line) => `${line.title}-${line.price}`,
    );

    if (uniqueShippingLines.length === 0) {
      return null;
    }

    const title =
      uniqueShippingLines.length === 1 ? "Shipping method" : "Shipping methods";

    return (
      <Flex align="stretch" dir="column" grow="1">
        <Text fontSize="xs" fontWeight="medium" textColor="tertiary">
          {title}
        </Text>
        {uniqueShippingLines.map((shippingLine, idx) => {
          const price = `$${shippingLine.price}`;

          return (
            <CopyableText
              fontSize="xs"
              fontWeight="medium"
              key={idx}
              textColor="primary"
            >
              {`${shippingLine.title} - ${price}`}
            </CopyableText>
          );
        })}
      </Flex>
    );
  },
);
