import { z } from "zod";

export const getConciergeKnowledge = {
  input: z.object({ _id: z.string() }).strict(),
  output: z.object({
    _id: z.string(),
    title: z.string(),
    knowledge: z.string(),
    whenToUse: z.string(),
    enabled: z.boolean(),
    teamId: z.string(),
  }),
};
