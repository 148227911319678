import { FulfillmentGroupStatus } from "@redotech/redo-model/fulfillments/fulfillment-group-status";
import { AdvancedFilterType } from "@redotech/redo-model/views/advanced-filters/generic-advanced-filter-data";
import { StringFilterOperator } from "@redotech/redo-model/views/advanced-filters/string-filter";
import { AdvancedTableFilter } from "@redotech/redo-web/advanced-filters/advanced-filter";
import StatusIcon from "@redotech/redo-web/arbiter-icon/status.svg";

export const StatusFilter: AdvancedTableFilter = {
  type: AdvancedFilterType.STRING,
  Icon: StatusIcon,
  operators: [StringFilterOperator.EQUALS],
  values: Object.keys(FulfillmentGroupStatus),
  data: {
    name: "status",
    type: AdvancedFilterType.STRING,
    value: null,
    operator: StringFilterOperator.EQUALS,
  },
};
