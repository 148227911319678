// This is a copy of the FulfillmentOrderRequestStatus enum from @redotech/shopify-client/admin.graphql, but that can also be used in redo/model and therefore the frontend
export enum RedoShopifyFulfillmentOrderRequestStatus {
  /** The fulfillment service accepted the merchant's fulfillment request. */
  Accepted = "ACCEPTED",
  /** The fulfillment service accepted the merchant's fulfillment cancellation request. */
  CancellationAccepted = "CANCELLATION_ACCEPTED",
  /** The fulfillment service rejected the merchant's fulfillment cancellation request. */
  CancellationRejected = "CANCELLATION_REJECTED",
  /** The merchant requested a cancellation of the fulfillment request for this fulfillment order. */
  CancellationRequested = "CANCELLATION_REQUESTED",
  /** The fulfillment service closed the fulfillment order without completing it. */
  Closed = "CLOSED",
  /** The fulfillment service rejected the merchant's fulfillment request. */
  Rejected = "REJECTED",
  /** The merchant requested fulfillment for this fulfillment order. */
  Submitted = "SUBMITTED",
  /**
   * The initial request status for the newly-created fulfillment orders. This is the only valid
   * request status for fulfillment orders that aren't assigned to a fulfillment service.
   */
  Unsubmitted = "UNSUBMITTED",
}
