import {
  genericForwardRefMemo,
  genericMemo,
} from "@redotech/react-util/component";
import ChevronDownIcon from "@redotech/redo-web/arbiter-icon/chevron-down_filled.svg";
import ChevronUpIcon from "@redotech/redo-web/arbiter-icon/chevron-up_filled.svg";
import * as classNames from "classnames";
import * as isEqual from "lodash/isEqual";
import { ForwardedRef, ReactNode, useState } from "react";
import { Flex } from "../../flex";
import { ArbiterIconSvg, Icon } from "../../icon";
import { Text } from "../../text";
import { TextSizeValue } from "../../theme/typography";
import { RedoListItem } from "../list/redo-list";
import { RedoListItemSize } from "../list/redo-list-item";
import * as redoDropdownInputCss from "./redo-dropdown-input.module.css";
import { RedoSingleSelectDropdown } from "./redo-single-select-dropdown";

export enum RedoDropdownInputSize {
  SMALL = "sm",
  REGULAR = "md",
}

export const RedoSingleSelectDropdownInput = genericMemo(
  function RedoSingleSelectDropdownInput<T>({
    options,
    optionSelected,
    children,
    selectedItem,
    size = RedoDropdownInputSize.REGULAR,
    disabled = false,
    placeholder,
    className,
    fitToAnchor = true,
    label,
    description,
    icon,
    flexGrow = true,
    equals = (a: T, b: T) => isEqual(a, b),
    loading = false,
  }: {
    size?: RedoDropdownInputSize;
    options: RedoListItem<T>[];
    optionSelected(value: RedoListItem<T>): void;
    children(item: RedoListItem<T>): ReactNode;
    selectedItem?: RedoListItem<T>;
    disabled?: boolean;
    placeholder?: string;
    className?: string;
    fitToAnchor?: boolean;
    label?: string;
    description?: string;
    icon?: ArbiterIconSvg;
    flexGrow?: boolean;
    equals?: (a: T, b: T) => boolean;
    loading?: boolean;
  }) {
    const [dropdownButtonRef, setDropdownButtonRef] =
      useState<HTMLButtonElement | null>(null);
    const [dropdownOpen, setDropdownOpen] = useState(false);

    const descriptorFontSize = sizeToDescriptorTextProps[size];

    return (
      <>
        <Flex dir="column" gap="sm" grow={flexGrow ? 1 : undefined}>
          {label && (
            <Text
              fontSize={descriptorFontSize}
              fontWeight="medium"
              textColor="secondary"
            >
              {label}
            </Text>
          )}
          <RedoSingleSelectDropdownInputButton
            className={className}
            disabled={disabled}
            icon={icon}
            isDropdownOpen={dropdownOpen}
            placeholder={placeholder}
            ref={setDropdownButtonRef}
            selectedItem={selectedItem}
            size={size}
          >
            {children}
          </RedoSingleSelectDropdownInputButton>
          {description && (
            <Text fontSize={descriptorFontSize} textColor="tertiary">
              {description}
            </Text>
          )}
        </Flex>
        <RedoSingleSelectDropdown
          dropdownButtonRef={dropdownButtonRef}
          fitToAnchor={fitToAnchor}
          itemsLoading={loading}
          onDropdownToggled={setDropdownOpen}
          options={options}
          optionSelected={optionSelected}
          selectedItem={options.find((item) =>
            selectedItem ? equals(item.value, selectedItem.value) : false,
          )}
          size={
            size === RedoDropdownInputSize.REGULAR
              ? RedoListItemSize.MEDIUM
              : RedoListItemSize.SMALL
          }
        >
          {(item) => children(item)}
        </RedoSingleSelectDropdown>
      </>
    );
  },
);

interface RedoSingleSelectDropdownInputButtonProps<T> {
  size?: RedoDropdownInputSize;
  isDropdownOpen: boolean;
  children(item: RedoListItem<T>): ReactNode;
  selectedItem?: RedoListItem<T>;
  disabled?: boolean;
  placeholder?: string;
  className?: string;
  onClick?: () => void;
  icon?: ArbiterIconSvg;
  ref?: any;
}

export const RedoSingleSelectDropdownInputButton = genericForwardRefMemo(
  function RedoSingleSelectDropdownInputButton<T>(
    {
      children,
      selectedItem,
      isDropdownOpen,
      size = RedoDropdownInputSize.REGULAR,
      disabled = false,
      placeholder,
      className,
      icon,
      onClick,
    }: RedoSingleSelectDropdownInputButtonProps<T>,
    ref: ForwardedRef<HTMLButtonElement>,
  ) {
    const sizeClass =
      size === RedoDropdownInputSize.REGULAR
        ? redoDropdownInputCss.regular
        : redoDropdownInputCss.small;

    return (
      <button
        className={classNames(
          className,
          redoDropdownInputCss.singleSelectInput,
          sizeClass,
          isDropdownOpen ? redoDropdownInputCss.open : "",
          disabled ? redoDropdownInputCss.disabled : "",
        )}
        disabled={disabled}
        onClick={onClick}
        ref={ref}
        type="button"
      >
        <Flex align="center" dir="row" gap="sm">
          {icon && (
            <Icon
              arbiterIconSvg={icon}
              className={redoDropdownInputCss.icon}
              color="ghost"
            />
          )}
          {selectedItem ? (
            children(selectedItem)
          ) : (
            <div className={redoDropdownInputCss.placeholder}>
              {placeholder}
            </div>
          )}
        </Flex>
        <Icon
          arbiterIconSvg={isDropdownOpen ? ChevronUpIcon : ChevronDownIcon}
          className={redoDropdownInputCss.chevron}
          color="ghost"
        />
      </button>
    );
  },
);

const sizeToDescriptorTextProps: Record<RedoDropdownInputSize, TextSizeValue> =
  {
    [RedoDropdownInputSize.SMALL]: "xs",
    [RedoDropdownInputSize.REGULAR]: "sm",
  };
