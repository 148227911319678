// extracted by mini-css-extract-plugin
export var actionButton = "_7n-t";
export var buttonContainer = "_7n-0";
export var buttonContainer2 = "_7n-1";
export var buttonSection = "_7n-2";
export var details = "_7n-3";
export var dropdownItem = "_7n-5";
export var dropdownItemsContainer = "_7n-4";
export var fileInput = "_7n-r";
export var headerButton = "_7n-6";
export var icon = "_7n-7";
export var input = "_7n-8";
export var macroListItem = "_7n-9";
export var mainContent = "_7n-a";
export var microText = "_7n-b";
export var modal = "_7n-w";
export var optionsButton = "_7n-c";
export var paperclip = "_7n-s";
export var pillContent = "_7n-d";
export var preview = "_7n-e";
export var preview2 = "_7n-f";
export var quillEditor = "_7n-q";
export var selectedMacro = "_7n-g";
export var sidebar = "_7n-h";
export var sidebarHeader = "_7n-j";
export var sidebarList = "_7n-i";
export var sidebarListItem = "_7n-k";
export var sidebarSearch = "_7n-l";
export var snoozeDurationWrapper = "_7n-v";
export var statusPillContainer = "_7n-m";
export var tagsContainer = "_7n-n";
export var unselectedMacro = "_7n-o";
export var variable = "_7n-u";
export var wrapper = "_7n-p";