import { z } from "zod";

export const ConciergeConversationCategory = z.object({
  category: z.string(),
  count: z.number(),
});
export type ConciergeConversationCategory = z.infer<
  typeof ConciergeConversationCategory
>;

export const CONCIERGE_CONVERSATION_CATEGORIES = [
  "Order status",
  "Returns or exchanges",
  "Stock availability",
  "Shipping information",
  "Size and fit",
  "Style",
  "Warranty information",
  "General product question",
] as const;
