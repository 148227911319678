import * as classNames from "classnames";
import "keen-slider/keen-slider.min.css";
import { useKeenSlider } from "keen-slider/react";
import { memo, ReactNode, useState } from "react";
import ChevronLeftIcon from "./arbiter-icon/chevron-left_filled.svg";
import ChevronRightIcon from "./arbiter-icon/chevron-right_filled.svg";
import * as carouselCss from "./carousel.module.css";
import { Flex } from "./flex";
import { StyleProps } from "./styles";
import { SpacingValue } from "./theme/box";
import { spacingPx } from "./theme/spacing";

interface CarouselProps extends Omit<StyleProps, "gap"> {
  children: ReactNode[];
  numberVisible?: number;
  showOverflow?: boolean;
  /** The gap between the carousel and the page dots */
  verticalGap?: SpacingValue;
  showArrows?: boolean;
  gap?: SpacingValue;
}

/** Uses keen slider: https://keen-slider.io
 * If you need to extend it, check for examples of what you want to do at https://keen-slider.io/examples
 */
export const Carousel = memo(function Carousel({
  children,
  numberVisible,
  showOverflow,
  gap,
  verticalGap,
  showArrows,
  ...rest
}: CarouselProps) {
  const visible = numberVisible || 2.2;
  const [loaded, setLoaded] = useState(false);
  const [currentSlide, setCurrentSlide] = useState(0);
  const [sliderRef, instanceRef] = useKeenSlider(
    {
      selector: ".keen-slider__slide",
      loop: false,
      mode: "free-snap",
      slides: {
        perView: visible,
        spacing: gap ? spacingPx(gap) : spacingPx("md"),
      },
      slideChanged(slider) {
        setCurrentSlide(slider.track.details.rel);
      },
      created() {
        setLoaded(true);
      },
    },
    [],
  );

  return (
    <Flex className={carouselCss.container} dir="column" gap={verticalGap}>
      <Flex className={carouselCss.sliderContainer}>
        <Flex
          className={classNames("keen-slider", {
            [carouselCss.sliderOverflow]: showOverflow,
          })}
          ref={sliderRef}
          {...rest}
          gap="none"
        >
          {children.map((child, index) => (
            <Flex
              className={classNames("keen-slider__slide", carouselCss.slide)}
              key={index}
            >
              {child}
            </Flex>
          ))}
        </Flex>
        {loaded && instanceRef.current && showArrows && (
          <>
            {/* Left arrow */}
            <Flex
              align="center"
              className={classNames(carouselCss.leftArrowContainer, {
                [carouselCss.disabled]: currentSlide === 0,
              })}
              justify="center"
              onClick={(_) => {
                instanceRef.current?.prev();
              }}
              radius="full"
              w="5xl"
            >
              <ChevronLeftIcon className={carouselCss.arrow} />
            </Flex>
            <Flex
              align="center"
              className={classNames(carouselCss.rightArrowContainer, {
                [carouselCss.disabled]:
                  currentSlide ===
                  instanceRef.current.track.details.slides.length -
                    Math.floor(visible),
              })}
              justify="center"
              onClick={(_) => {
                instanceRef.current?.next();
              }}
              radius="full"
              w="5xl"
            >
              <ChevronRightIcon className={carouselCss.arrow} />
            </Flex>
          </>
        )}
      </Flex>
      {loaded && instanceRef.current && (
        <Flex
          dir="row"
          /**  This is smaller than the gap specified in figma (https://www.figma.com/design/XgayIFnancesjHRUOMIfUf/Merge-support-tickets?node-id=0-1&t=WzphleZUgPCagPnY-0)
           *   to allow us to make the hitbox on the dots a bit bigger than the dots themselves.
           */
          gap="xs"
          justify="center"
        >
          {[...Array(instanceRef.current.track.details?.slides.length).keys()]
            .slice(
              0,
              instanceRef.current.track.details?.slides.length -
                Math.floor(visible) +
                1,
            )
            .map((idx) => {
              return (
                <Flex
                  align="center"
                  className={carouselCss.dotContainer}
                  justify="center"
                  key={idx}
                  onClick={() => {
                    instanceRef.current?.moveToIdx(idx);
                  }}
                >
                  <div
                    className={classNames(carouselCss.dot, {
                      [carouselCss.active]: currentSlide === idx,
                    })}
                  />
                </Flex>
              );
            })}
        </Flex>
      )}
    </Flex>
  );
});
