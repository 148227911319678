import { ConversationTagRecord } from "@redotech/redo-model/conversation-tags/conversation-tag-record";
import { CreateConversationTagBody } from "@redotech/redo-model/conversation-tags/create-conversation-tag-body";
import { PatchConversationTagBody } from "@redotech/redo-model/conversation-tags/patch-conversation-tag-body";
import { RedoMerchantClient } from ".";

export async function getConversationTagsOfTeam(
  client: RedoMerchantClient,
  { teamId }: { teamId: string },
  signal?: AbortSignal,
): Promise<ConversationTagRecord[]> {
  const response = await client.client.get(
    `conversation-tags/teams/${teamId}/conversation-tags`,
    { headers: client.authorization(), signal },
  );
  return response.data;
}

/**
 * @param reloadCallback forces the caller to think about what needs reloading after updating a tag
 */
export async function patchConversationTag(
  client: RedoMerchantClient,
  tagId: string,
  body: PatchConversationTagBody,
  reloadCallback: () => void | Promise<void>,
  signal?: AbortSignal,
): Promise<ConversationTagRecord> {
  const response = await client.client.patch(
    `conversation-tags/${tagId}`,
    body,
    { headers: client.authorization(), signal },
  );
  await reloadCallback();
  return response.data;
}

/**
 * @param reloadCallback forces the caller to think about what needs reloading after making a tag
 */
export async function postConversationTag(
  client: RedoMerchantClient,
  body: CreateConversationTagBody,
  reloadCallback: () => void | Promise<void>,
  signal?: AbortSignal,
): Promise<ConversationTagRecord> {
  const response = await client.client.post(`conversation-tags`, body, {
    headers: client.authorization(),
    signal,
  });
  await reloadCallback();
  return response.data;
}

/**
 * @param reloadCallback forces the caller to think about what needs reloading after deleting a tag
 */
export async function deleteConversationTag(
  client: RedoMerchantClient,
  tagId: string,
  reloadCallback: () => void | Promise<void>,
  signal?: AbortSignal,
): Promise<void> {
  await client.client.delete(`conversation-tags/${tagId}`, {
    headers: client.authorization(),
    signal,
  });
  await reloadCallback();
}
