import { viewTypes } from "@redotech/redo-model/views/views";
import {
  RedoModal,
  RedoModalButtonProps,
  RedoModalTheme,
} from "@redotech/redo-web/arbiter-components/modal/redo-modal";
import { memo } from "react";

export const DeleteTableViewModal = memo(function DeleteTableViewModal({
  open,
  setOpen,
  view,
  onDelete,
}: {
  open: boolean;
  setOpen: (open: boolean) => void;
  view: viewTypes[keyof viewTypes];
  onDelete: () => void;
}) {
  const primaryButtonProps: RedoModalButtonProps = {
    onClick: onDelete,
    text: "Delete",
  };

  const secondaryButtonProps: RedoModalButtonProps = {
    onClick: () => setOpen(false),
    text: "Cancel",
  };

  const modalTitle = `Delete view - ${view.name}`;

  return (
    <RedoModal
      isOpen={open}
      onModalCloseRequested={() => setOpen(false)}
      primaryButton={primaryButtonProps}
      secondaryButton={secondaryButtonProps}
      subtitle="Are you sure you want to delete this view?"
      theme={RedoModalTheme.ERROR}
      title={modalTitle}
    />
  );
});
