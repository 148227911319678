// This is a copy of the FulfillmentHoldReason enum from @redotech/shopify-client/admin.graphql, but that can also be used in redo/model and therefore the frontend
export enum RedoShopifyFulfillmentHoldReason {
  /** The fulfillment hold is applied because payment is pending. */
  AwaitingPayment = "AWAITING_PAYMENT",
  /** The fulfillment hold is applied because of return items not yet received during an exchange. */
  AwaitingReturnItems = "AWAITING_RETURN_ITEMS",
  /** The fulfillment hold is applied because of a high risk of fraud. */
  HighRiskOfFraud = "HIGH_RISK_OF_FRAUD",
  /** The fulfillment hold is applied because of an incorrect address. */
  IncorrectAddress = "INCORRECT_ADDRESS",
  /** The fulfillment hold is applied because inventory is out of stock. */
  InventoryOutOfStock = "INVENTORY_OUT_OF_STOCK",
  /** The fulfillment hold is applied because of a post purchase upsell offer. */
  OnlineStorePostPurchaseCrossSell = "ONLINE_STORE_POST_PURCHASE_CROSS_SELL",
  /** The fulfillment hold is applied for another reason. */
  Other = "OTHER",
  /** The fulfillment hold is applied because of an unknown delivery date. */
  UnknownDeliveryDate = "UNKNOWN_DELIVERY_DATE",
}
