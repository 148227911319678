// extracted by mini-css-extract-plugin
export var background = "_2x-6";
export var containerMedium = "_2x-0";
export var containerMicro = "_2x-2";
export var containerSmall = "_2x-1";
export var dropdown = "_2x-5";
export var dropdownLabel = "_2x-a";
export var enter = "_2x-7";
export var exitActive = "_2x-8";
export var label = "_2x-3";
export var option = "_2x-9";
export var select = "_2x-4";