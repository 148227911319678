import { Currency, getNarrowCurrencySymbol } from "@redotech/money/currencies";
import { useRequiredContext } from "@redotech/react-util/context";
import { useInput } from "@redotech/react-util/form";
import { LoadState, useTriggerLoad } from "@redotech/react-util/load";
import type {
  Product,
  ProductZodSchema,
  Return,
} from "@redotech/redo-model/return";
import { RESTOCK_STRATEGY } from "@redotech/redo-model/return";
import {
  ProductTotals,
  ReturnTotals,
} from "@redotech/redo-model/return-totals-calculator";
import { alertOnFailure } from "@redotech/redo-web/alert";
import { Button, ButtonTheme } from "@redotech/redo-web/button";
import { CurrencyContext } from "@redotech/redo-web/currency";
import { Flex } from "@redotech/redo-web/flex";
import { InputSize } from "@redotech/redo-web/input";
import { LabeledInput, LabelSize } from "@redotech/redo-web/labeled-input";
import { Modal, ModalSize, PaddingAmount } from "@redotech/redo-web/modal";
import { Pill, PillSize } from "@redotech/redo-web/pill";
import { Text } from "@redotech/redo-web/text";
import { InputTheme, TextInput } from "@redotech/redo-web/text-input";
import { GroupInput, groupInput, Input, input } from "@redotech/ui/form";
import { sleep } from "@redotech/util/schedule";
import { memo, useContext, useId, useMemo, useState } from "react";
import { z } from "zod";
import { TeamContext } from "../../app/team";
import { RedoMerchantClientContext } from "../../client/context";
import { changeProductReturnValue } from "../../client/return";
import * as returnCss from "../return.module.css";
import { isVariantExchange, returnKindNameProduct } from "../util";
import { getDefaultRestockForProduct } from "./common/util";
import * as editItemModalCss from "./edit-item-modal.module.css";

type EditItemInputType = GroupInput<{
  restock: Input<boolean>;
  refund: Input<string>;
}>;

export const getProductItemGroupInputWithDefault = ({
  item,
  return_,
  price,
  priceBeforeRestockingFee,
  formatCurrency,
  initialValueDefaults,
}: {
  item: z.infer<typeof ProductZodSchema>;
  return_: Return;
  price: number;
  priceBeforeRestockingFee: number;
  formatCurrency: (value: number) => string;
  initialValueDefaults?: { grade?: string; outcome?: string };
}) => {
  const _groupInputProvider = groupInput({
    restock: input<boolean>(),
    refund: input<string>({
      validator: (value) => {
        const errors: string[] = [];
        if (!value || isNaN(+value)) {
          errors.push("Must be a number");
        } else if (+value < 0) {
          errors.push("Must not be negative");
        } else if (
          Math.round(priceBeforeRestockingFee * 100) / 100 < +value &&
          item.strategy === "refund"
        ) {
          errors.push(
            `Refund value must not be greater than ${formatCurrency(
              priceBeforeRestockingFee,
            )}`,
          );
        }
        return errors;
      },
    }),
    grade: input<string>(),
    outcome: input<string>(),
    notes: input<string>(),
  });
  const initialValue = {
    restock: !RESTOCK_STRATEGY[item.strategy]
      ? false
      : getDefaultRestockForProduct(item.reason, return_.team.settings),
    refund: String(Math.round(price * 100) / 100),
    grade: item.merchant_grade ?? initialValueDefaults?.grade ?? "",
    outcome: item.merchant_outcome ?? initialValueDefaults?.outcome ?? "",
    notes: item.merchant_notes ?? "",
  };

  return { groupInputProvider: _groupInputProvider, initialValue };
};

export const EditItemRefundValue = memo(function EditItemRefundValue({
  item,
  input_,
}: {
  item: Product;
  input_: EditItemInputType;
}) {
  const team = useContext(TeamContext);

  const [focus, setFocus] = useState(false);

  const inputLabel = useMemo(() => {
    if (item.strategy === "refund") {
      return "Refund amount";
    } else if (isVariantExchange(item)) {
      return "Exchange value";
    } else if (item.strategy === "repair") {
      return "Repair item value";
    } else {
      return "Store credit";
    }
  }, [item]);

  return (
    <Flex dir="column" flex={1}>
      <LabeledInput
        className={editItemModalCss.editItemRefundLabeledInput}
        label={inputLabel}
        size={LabelSize.EXTRA_SMALL}
      >
        <TextInput
          disabled={item.strategy === "repair"}
          error={input_.changed && !focus && !!input_.allErrors.length}
          onChange={input_.inputs.refund.setValue}
          onFocus={setFocus}
          prefix={getNarrowCurrencySymbol(
            team?._shopify.currency || Currency.USD,
          )}
          size={InputSize.EXTRA_SMALL}
          theme={InputTheme.FORM}
          type="number"
          value={input_.inputs.refund.value}
        />
      </LabeledInput>
      {!focus &&
        input_.allErrors.map((error: any, index: any) => (
          <Text fontSize="sm" key={index} textColor="error">
            {error}
          </Text>
        ))}
    </Flex>
  );
});

const EditItemModalContent = memo(function EditItemModalContent({
  item,
  return_,
  formatCurrency,
  price,
  input_,
}: {
  item: Product;
  return_: Return;
  formatCurrency: (value: number) => string;
  price: number;
  input_: EditItemInputType;
}) {
  return (
    <div className={returnCss.modalProduct}>
      <div className={returnCss.modalProductInfo}>
        <img className={returnCss.modalImage} src={item.images[0]} />
        <dl className={returnCss.itemProperties}>
          <div className={returnCss.itemMain}>
            <div className={returnCss.itemHeaderSmall}>
              {item.product_title}
            </div>

            <div className={returnCss.itemOptions}>{item.variant_title}</div>

            <div className={returnCss.itemProperty}>
              <dt className={returnCss.itemPropertyName}>Reason</dt>
              <dd className={returnCss.itemPropertyValue}>{item.reason}</dd>
            </div>
          </div>
        </dl>
        <div className={returnCss.modalProductInfoRight}>
          {return_ && formatCurrency(price)}
          <div>
            <div className={returnCss.itemType}>
              <Pill size={PillSize.SMALL}>
                {returnKindNameProduct(item, return_.provision)}
              </Pill>
            </div>
          </div>
        </div>
      </div>
      <EditItemRefundValue input_={input_} item={item} />
    </div>
  );
});

const ModalFooter = memo(function ModalFooter({
  input_,
  onClose,
  onSaveClick,
  id,
  adjustValueLoad,
}: {
  input_: EditItemInputType;
  onClose: (refresh: boolean) => void;
  onSaveClick: () => void;
  id: string;
  adjustValueLoad: LoadState<boolean | undefined>;
}) {
  return (
    <div className={returnCss.selectItemsModalButtons}>
      <div className={returnCss.selectItemsModalRightButtons}>
        <Button
          className={returnCss.modalButton}
          form={id}
          onClick={async () => {
            onClose(false);
          }}
          theme={ButtonTheme.OUTLINED}
        >
          Cancel
        </Button>
        <Button
          className={returnCss.modalButton}
          disabled={!!input_?.allErrors?.length}
          form={id}
          onClick={onSaveClick}
          pending={adjustValueLoad.pending}
          theme={ButtonTheme.PRIMARY}
        >
          Save changes
        </Button>
      </div>
    </div>
  );
});

export const EditItemModal = memo(function EditItemModal({
  open,
  onClose,
  reload,
  item,
  return_,
  returnTotals,
}: {
  open: boolean;
  onClose: (refresh: boolean) => void;
  reload: () => void;
  item: Product;
  return_: Return;
  returnTotals: ReturnTotals;
}) {
  const client = useRequiredContext(RedoMerchantClientContext);
  const { formatCurrency } = useContext(CurrencyContext);
  const id = useId();

  const productTotals = useMemo(() => {
    return (returnTotals.productTotals || []).find(
      (_productTotals: ProductTotals) =>
        _productTotals.product?._id === item._id,
    );
  }, [returnTotals]);

  const price = useMemo(() => productTotals?.finalPrice || 0, [productTotals]);

  const priceBeforeRestockingFee = useMemo(() => {
    const priceAdjustment = productTotals?.priceAdjustment || 0;
    return priceAdjustment < 0 ? price - priceAdjustment : price;
  }, [productTotals, price]);

  const { groupInputProvider, initialValue } =
    getProductItemGroupInputWithDefault({
      item,
      return_,
      price,
      priceBeforeRestockingFee,
      formatCurrency,
    });
  const input_ = useInput(groupInputProvider, initialValue);

  const [adjustValueLoad, doAdjustValue] = useTriggerLoad((signal) =>
    alertOnFailure("Adjusting product value failed")(async () => {
      try {
        await changeProductReturnValue(client, {
          returnId: return_.id,
          signal,
          productId: item._id,
          newValue: +input_.inputs.refund.value,
        });
        await sleep(Temporal.Duration.from({ seconds: 2 }));
        reload();
      } catch (error: any) {
        throw new Error(error);
      }

      onClose(true);
      return true;
    }),
  );

  const footer = useMemo(() => {
    return (
      <ModalFooter
        adjustValueLoad={adjustValueLoad}
        id={id}
        input_={input_}
        onClose={onClose}
        onSaveClick={() => doAdjustValue()}
      />
    );
  }, [input_, id, adjustValueLoad]);

  return (
    <Modal
      footer={footer}
      onClose={() => {
        onClose(false);
      }}
      open={open}
      paddingAmount={PaddingAmount.SMALL}
      showHeaderBorder={false}
      size={ModalSize.MEDIUM}
      title="Edit item"
    >
      <EditItemModalContent
        formatCurrency={formatCurrency}
        input_={input_}
        item={item}
        price={price}
        return_={return_}
      />
    </Modal>
  );
});
