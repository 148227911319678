import { RedoMerchantClient } from "./index";

export async function getGmailIntegrations(
  client: RedoMerchantClient,
  { signal }: { signal?: AbortSignal } = {},
): Promise<any> {
  const response = await client.client.get("support/gmail", {
    headers: client.authorization(),
    signal,
  });
  return response.data;
}

export async function deleteGmailIntegration(
  client: RedoMerchantClient,
  {
    integrationId,
    signal,
    deleteConversations,
  }: {
    integrationId: string;
    deleteConversations: boolean;
    signal?: AbortSignal;
  },
): Promise<void> {
  const params = new URLSearchParams();
  params.set("deleteConversations", deleteConversations.toString());
  const response = await client.client.delete(
    `support/gmail/${integrationId}`,
    { headers: client.authorization(), params, signal },
  );
  return response.data;
}
