// extracted by mini-css-extract-plugin
export var actionButton = "_bw-1";
export var actions = "_bw-0";
export var copyableField = "_bw-2";
export var detailColumn = "_bw-3";
export var detailRow = "_bw-4";
export var dropdownTitle = "_bw-5";
export var fulfillmentDetails = "_bw-7";
export var fulfillments = "_bw-6";
export var icon = "_bw-8";
export var imgContainer = "_bw-9";
export var lineItems = "_bw-a";
export var link = "_bw-p";
export var orderCard = "_bw-k";
export var orderCollapseButton = "_bw-l";
export var orderCollapseButtonIcon = "_bw-m";
export var orderDetails = "_bw-b";
export var orderPillsContainer = "_bw-c";
export var pillWrapper = "_bw-d";
export var product = "_bw-e";
export var productDetails = "_bw-g";
export var productTopDetails = "_bw-h";
export var products = "_bw-f";
export var strong = "_bw-i";
export var tightText = "_bw-o";
export var tooltipHidden = "_bw-q";
export var variantQuantityRow = "_bw-j";
export var wrappingText = "_bw-n";