// extracted by mini-css-extract-plugin
export var addButton = "_7a-0";
export var border = "_7a-8";
export var check = "_7a-h";
export var danger = "_7a-6";
export var dropdownContainer = "_7a-1";
export var editModalBody = "_7a-j";
export var footerContainer = "_7a-i";
export var fullWidth = "_7a-a";
export var hide = "_7a-9";
export var icon = "_7a-3";
export var input = "_7a-7";
export var inputFlex = "_7a-b";
export var inputWrapper = "_7a-c";
export var manualFocus = "_7a-5";
export var optionButton = "_7a-4";
export var pillWrapper = "_7a-e";
export var pointer = "_7a-2";
export var showOverflow = "_7a-f";
export var tagsFlex = "_7a-d";
export var xButton = "_7a-g";