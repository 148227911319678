// extracted by mini-css-extract-plugin
export var actions = "_1v-8";
export var actionsButton = "_1v-a";
export var authenticateButton = "_1v-r";
export var buttonContent = "_1v-9";
export var buttons = "_1v-s";
export var carrierHeader = "_1v-m";
export var carrierLogo = "_1v-n";
export var carrierParcelDimensions = "_1v-q";
export var carrierParcelName = "_1v-p";
export var carrierParcelsForm = "_1v-o";
export var checkboxContainer = "_1v-l";
export var checkboxSubtext = "_1v-j";
export var checkboxText = "_1v-i";
export var dimensions = "_1v-7";
export var editDimensions = "_1v-e";
export var editUnit = "_1v-g";
export var editWeight = "_1v-f";
export var image = "_1v-4";
export var mainCard = "_1v-0";
export var mainInfo = "_1v-3";
export var modal = "_1v-b";
export var modalActions = "_1v-h";
export var modalButton = "_1v-d";
export var modalForm = "_1v-c";
export var name = "_1v-6";
export var parcel = "_1v-2";
export var parcels = "_1v-1";
export var pillContainer = "_1v-k";
export var text = "_1v-5";