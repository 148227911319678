// extracted by mini-css-extract-plugin
export var container = "_2q-0";
export var error = "_2q-9";
export var label = "_2q-5";
export var light = "_2q-6";
export var md = "_2q-3";
export var sm = "_2q-2";
export var thin = "_2q-8";
export var thinBold = "_2q-7";
export var vertical = "_2q-4";
export var xs = "_2q-1";