import { z } from "zod";
import {
  AdvancedFilterType,
  createAdvancedFilterDataSchema,
  GenericFilterBuilder,
} from "./generic-advanced-filter-data";

export enum BooleanFilterOperator {
  IS = "is",
  IS_NOT = "is_not",
}
const BooleanFilterOperatorSchema = z.nativeEnum(BooleanFilterOperator);

const BooleanFilterValueSchema = z.boolean();

export const BooleanFilterDataSchema = createAdvancedFilterDataSchema(
  AdvancedFilterType.BOOLEAN,
  BooleanFilterValueSchema,
  BooleanFilterOperatorSchema,
);
export type BooleanFilterData = z.infer<typeof BooleanFilterDataSchema>;

export const BooleanFilterBuilder: GenericFilterBuilder<
  boolean,
  BooleanFilterOperator,
  BooleanFilterData
> = {
  type: AdvancedFilterType.BOOLEAN,
  valueSchema: BooleanFilterValueSchema,
  operatorSchema: BooleanFilterOperatorSchema,
  schema: BooleanFilterDataSchema,
  buildAtlasSearchQuery: (
    filter,
    atlasPath,
    searchCompound,
    useObjectId = false,
  ) => {
    const { value } = filter;

    if (value === null || value === undefined) {
      return searchCompound;
    }

    // the below code assumes that !exists is equivalent to false
    if (filter.operator === BooleanFilterOperator.IS) {
      if (value) {
        searchCompound.filter.push({
          equals: { path: atlasPath, value: filter.value },
        });
      } else if (!value) {
        searchCompound.should.push({
          compound: { mustNot: [{ exists: { path: atlasPath } }] },
        });
        searchCompound.should.push({
          compound: {
            filter: [
              { exists: { path: atlasPath } },
              { equals: { path: atlasPath, value: filter.value } },
            ],
          },
        });
        searchCompound.minimumShouldMatch =
          (searchCompound?.minimumShouldMatch ?? 0) + 1;
      }
    }
    if (filter.operator === BooleanFilterOperator.IS_NOT) {
      if (value) {
        searchCompound.mustNot.push({
          equals: { path: atlasPath, value: filter.value },
        });
      } else if (!value) {
        searchCompound.mustNot.push({
          equals: { path: atlasPath, value: filter.value },
        });
        searchCompound.filter.push({ exists: { path: atlasPath } });
      }
    }
    return searchCompound;
  },
  readFromString: (name, operatorValueEncoded, defaultFilter) => {
    try {
      const decoded = decodeURIComponent(operatorValueEncoded);
      const operatorValue = decoded.split("::");

      const operator = operatorValue?.[0];
      const value = operatorValue?.[1];

      if (!operator) {
        return defaultFilter;
      }

      const parsedOperator = BooleanFilterOperatorSchema.safeParse(operator);
      if (!parsedOperator.success) {
        console.warn("Invalid operator type");
        return undefined;
      }

      if (value === undefined || value === null) {
        return {
          type: AdvancedFilterType.BOOLEAN,
          name,
          value: null,
          operator: parsedOperator.data,
        };
      }

      const valueParsed = BooleanFilterValueSchema.safeParse(
        value.trim() === "true",
      );

      if (!valueParsed.success) {
        console.warn("Invalid value type for BooleanFilter");
        return undefined;
      }

      return {
        type: AdvancedFilterType.BOOLEAN,
        name,
        value: valueParsed.data,
        operator: parsedOperator.data,
      };
    } catch (error) {
      console.error("Error parsing BooleanFilter", error);
      return undefined;
    }
  },
  writeToString: (filter: BooleanFilterData | undefined) => {
    try {
      if (!filter) {
        return "";
      }
      const { operator, value } = filter;
      const parsedValue = BooleanFilterValueSchema.safeParse(value);

      if (parsedValue.success) {
        return `${operator}::${parsedValue.data}`;
      } else {
        return `${operator}::`;
      }
    } catch (error) {
      console.error("Error writing BooleanFilter", error);
      return "";
    }
  },
  isPartial: (filter) => {
    return !filter.operator || filter.value == null;
  },
};
