import { AdvancedFilterType } from "@redotech/redo-model/views/advanced-filters/generic-advanced-filter-data";
import { NumberFilterOperator } from "@redotech/redo-model/views/advanced-filters/number-filter";
import { AdvancedTableFilter } from "@redotech/redo-web/advanced-filters/advanced-filter";
import BoxIcon from "@redotech/redo-web/arbiter-icon/box-01.svg";
export const WeightFilter: AdvancedTableFilter = {
  type: AdvancedFilterType.NUMBER,
  min: 0,
  suffix: "lbs",
  modifier: 28.3495,
  data: {
    modifier: 28.3495,
    name: "parcelWeight",
    type: AdvancedFilterType.NUMBER,
    value: null,
    operator: NumberFilterOperator.GREATER_THAN,
  },
  step: 1,
  secondField: { scalar: 16, min: 0, max: 15, suffix: "oz", step: 1 },
  Icon: BoxIcon,
};
