import { PresetSchema } from "@redotech/redo-model/outbound-labels/presets";
import { zExt } from "@redotech/rpc/ext";
import { z } from "zod";

export const updatePreset = {
  input: z.object({
    _id: zExt.objectId(),
    preset: PresetSchema.omit({ _id: true }),
  }),
  output: z.object({ success: z.boolean() }),
};
