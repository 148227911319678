import { DateRange } from "@redotech/redo-model/concierge-conversation/analytics";
import { z } from "zod";

const timeSeriesPoint = z.object({ date: z.date(), count: z.number() });

const timeSeriesGroup = z.object({
  numberOfTicketsResolved: z.array(timeSeriesPoint),
});

const aiMetrics = z.object({
  ticketAutomationRate: z.number(),
  totalTicketsAutomated: z.number(),
  averageResolutionTimeMs: z.number(),
  medianResolutionTimeMs: z.number(),
  averageResponseTimeMs: z.number(),
  medianResponseTimeMs: z.number(),
  averageCsatScore: z.number(),
  costSavingsAnalysis: z.object({
    assumptions: z.object({
      annualSalary: z.number(),
      weeksPerYear: z.number(),
      ticketsPerDay: z.number(),
      workDaysPerWeek: z.number(),
      source: z.string(),
    }),
    savings: z.number(),
  }),
});

const humanMetrics = z.object({
  totalTicketsResolved: z.number(),
  averageResolutionTimeMs: z.number(),
  medianResolutionTimeMs: z.number(),
  averageResponseTimeMs: z.number(),
  medianResponseTimeMs: z.number(),
  averageCsatScore: z.number(),
});

const supportMetrics = z.object({
  aiMetrics: aiMetrics,
  humanMetrics: humanMetrics,
  aiTimeSeries: timeSeriesGroup,
  humanTimeSeries: timeSeriesGroup,
});

export const getSupportDashboardMetrics = {
  input: z.object({ dateRange: z.nativeEnum(DateRange) }),
  output: z.object({ current: supportMetrics, previous: supportMetrics }),
};
