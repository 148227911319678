import { AdvancedFilterType } from "@redotech/redo-model/views/advanced-filters/generic-advanced-filter-data";
import { NumberFilterOperator } from "@redotech/redo-model/views/advanced-filters/number-filter";
import { AdvancedTableFilter } from "@redotech/redo-web/advanced-filters/advanced-filter";
import ShoppingBagIcon from "@redotech/redo-web/arbiter-icon/shopping-bag-01.svg";

export const ItemQuantityFilter: AdvancedTableFilter = {
  type: AdvancedFilterType.NUMBER,
  Icon: ShoppingBagIcon,
  min: 0,
  data: {
    name: "itemQuantity",
    type: AdvancedFilterType.NUMBER,
    value: null,
    operator: NumberFilterOperator.GREATER_THAN,
  },
};
