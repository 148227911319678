import { FulfillmentOrderAddress } from "@redotech/redo-model/fulfillments/fulfillment-order-address";
import { Flex } from "@redotech/redo-web/flex";
import { Text } from "@redotech/redo-web/text";
import { memo } from "react";

export const OrderFulfillmentAddress = memo(function OrderFulfillmentAddress({
  address,
  onEdit,
}: {
  address: FulfillmentOrderAddress;
  onEdit: () => void;
}) {
  return (
    <Flex dir="column" gap="xs">
      <Text fontSize="xs" pb="xs" textColor="secondary">
        Shipping address
      </Text>
      <Flex dir="column" gap="none">
        {address?.name && (
          <Text fontSize="xs" textColor="primary">
            {address.name}
          </Text>
        )}
        {address?.address1 && (
          <Text fontSize="xs" textColor="primary">
            {address.address1}
          </Text>
        )}
        {address?.address2 && (
          <Text fontSize="xs" textColor="primary">
            {address.address2}
          </Text>
        )}
        {(address?.city || address?.province || address?.zip) && (
          <Text fontSize="xs" textColor="primary">
            {address.city}
            {address.province && `, ${address.province}`} {address.zip}
          </Text>
        )}
        {address?.countryCode && (
          <Text fontSize="xs" textColor="primary">
            {address.countryCode}
          </Text>
        )}
        {address?.phone && (
          <Text fontSize="xs" textColor="primary">
            {address.phone}
          </Text>
        )}
        {/* TODO for them to edit this we need the edit modal. But the styles for the edit button are here */}
        {/* <Text
          className={styles.editButton}
          fontSize="xs"
          fontWeight="thin"
          hoverTextColor="primary"
          onClick={onEdit}
          pt="xs"
          textColor="tertiary"
        >
          Edit
        </Text> */}
      </Flex>
    </Flex>
  );
});
