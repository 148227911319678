import { FulfillmentOrderData } from "@redotech/redo-model/fulfillments/fulfillment-group";
import { RedoTableTextCell } from "@redotech/redo-web/arbiter-components/tables/redo-table-cells";
import { ColumnAlignment } from "@redotech/redo-web/table";
import { Column2 } from "@redotech/redo-web/table2";

export const AssignedUserColumn: Column2<FulfillmentOrderData> = {
  key: "assigned-user",
  title: "Assigned user",
  alignment: ColumnAlignment.LEFT,
  renderNormalCell: ({ row }) => (
    <RedoTableTextCell text={row.assignedUser?.name || ""} />
  ),
  width: 120,
};
