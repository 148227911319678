import { useRequiredContext } from "@redotech/react-util/context";
import { useInput } from "@redotech/react-util/form";
import { useHandler } from "@redotech/react-util/hook";
import { useTriggerLoad } from "@redotech/react-util/load";
import { getNumNonRedoItemsInOrders, Order } from "@redotech/redo-model/order";
import type { Product, Return } from "@redotech/redo-model/return";
import {
  CompensationMethod,
  ReturnedProductStatus,
} from "@redotech/redo-model/return";
import {
  ProductTotals,
  ReturnTotals,
  ReturnTotalsCalculator,
} from "@redotech/redo-model/return-totals-calculator";
import {
  canRefundInstantExchangeRecoveryCharge,
  getRefundableInstantExchangeRecoveryPaymentIntent,
} from "@redotech/redo-model/returns/exchange-recovery";
import { alertOnFailure } from "@redotech/redo-web/alert";
import {
  RedoButton,
  RedoButtonHierarchy,
  RedoButtonSize,
  RedoButtonTheme,
} from "@redotech/redo-web/arbiter-components/buttons/redo-button";
import AlertCircle from "@redotech/redo-web/arbiter-icon/alert-circle.svg";
import ChevronDown from "@redotech/redo-web/arbiter-icon/chevron-down_filled.svg";
import ChevronRight from "@redotech/redo-web/arbiter-icon/chevron-right_filled.svg";
import HelpCircle from "@redotech/redo-web/arbiter-icon/help-circle.svg";
import { Checkbox } from "@redotech/redo-web/checkbox";
import { FormChipInput } from "@redotech/redo-web/chip-input";
import { CURRENCY_FORMAT, CurrencyContext } from "@redotech/redo-web/currency";
import { Divider } from "@redotech/redo-web/divider";
import { Flex } from "@redotech/redo-web/flex";
import {
  LabelOrientation,
  LabelPosition,
} from "@redotech/redo-web/labeled-input";
import { Modal, ModalSize, PaddingAmount } from "@redotech/redo-web/modal";
import { FormSwitch, SwitchSize } from "@redotech/redo-web/switch";
import { Text } from "@redotech/redo-web/text";
import { InputLines, TextInput } from "@redotech/redo-web/text-input";
import { Tooltip } from "@redotech/redo-web/tooltip/tooltip";
import { GroupInput, groupInput, Input, input } from "@redotech/ui/form";
import { sleep } from "@redotech/util/schedule";
import { AxiosError } from "axios";
import * as classNames from "classnames";
import {
  FormEventHandler,
  memo,
  useContext,
  useId,
  useMemo,
  useState,
} from "react";
import { RedoMerchantRpcClientContext } from "../../app/redo-merchant-rpc-client-provider";
import { TeamContext } from "../../app/team";
import { UserContext } from "../../app/user";
import { EditItemDisposition } from "../edit-item-disposition/edit-item-disposition";
import * as returnCss from "../return.module.css";
import {
  getProductsByShippingGroupTitle,
  hasEditReturnPermissions,
  returnTypeName,
} from "../util";
import { ProductItemSummaryRow } from "./common/product-item-summary-row";
import { getPriceDetailsForProduct, ItemRestockState } from "./common/util";
import {
  EditItemRefundValue,
  getProductItemGroupInputWithDefault,
} from "./edit-item-modal";
import * as processModalCss from "./process-modal.module.css";

type ProductItemInputType = GroupInput<{
  restock: Input<boolean>;
  refund: Input<string>;
  grade: Input<string>;
  outcome: Input<string>;
  notes: Input<string>;
}>;

type ProductToProcess = {
  _id: string;
  restock: boolean;
  reject: boolean;
  refund: string;
};

const EDIT_STRATEGY: { [key: string]: boolean } = {
  exchange: true,
  refund: true,
  store_credit: true,
  repair: false,
};

export const ProcessModal = memo(function ProcessModal({
  return: return_,
  open,
  onClose,
  reload,
  isProcessing,
  setIsProcessing,
  returnTotals,
  orders,
}: {
  open: boolean;
  onClose(refresh: boolean): void;
  return: Return;
  reload(): void;
  isProcessing: boolean;
  setIsProcessing(isProcessing: boolean): void;
  returnTotals: ReturnTotals;
  orders: Order[];
}) {
  const user = useContext(UserContext);
  const team = useContext(TeamContext);
  const client = useRequiredContext(RedoMerchantRpcClientContext);

  const [submitLoad, doSubmit] = useTriggerLoad((signal) =>
    alertOnFailure(`Processing ${returnTypeName(return_.type)} failed`)(
      async () => {
        try {
          setIsProcessing(true);
          const itemDispositionEnabled =
            !!team?.settings.returnItemDisposition?.enabled;
          const products = selectedItems.map((item) => {
            const itemIsAlreadyRestocked = !!return_.products.find(
              (value) => value._id === item._id,
            )?.hasBeenRestockedOnPlatform;
            return {
              _id: item._id,
              restock:
                rejectItems || itemIsAlreadyRestocked
                  ? false
                  : inputs.get(item._id)!.inputs.restock.value,
              returnValue: +inputs.get(item._id)!.inputs.refund.value,
              reject: rejectItems,
              merchantGrade: itemDispositionEnabled
                ? inputs.get(item._id)!.inputs.grade.value
                : undefined,
              merchantOutcome: itemDispositionEnabled
                ? inputs.get(item._id)!.inputs.outcome.value
                : undefined,
              merchantNotes: itemDispositionEnabled
                ? inputs.get(item._id)!.inputs.notes.value
                : undefined,
            };
          });

          await client.processReturn({
            returnId: return_.id,
            products,
            customerRecoveryAmount: summaryTotals?.recoveryFee || 0,
            options: {
              sendNotifications: notifyCustomer.value,
              refundOriginalShipping: refundOriginalShipping.value,
              refundInstantExchangeRecoveryFee:
                refundInstantExchangeRecoveryFee.value,
              waiveDraftFee: waiveDraftFee.value,
            },
            userId: user!._id,
            customerNotes: note.value,
            orderTags: orderTags.value as string[],
          });
          await sleep(Temporal.Duration.from({ seconds: 2 }));
          reload();
        } catch (error: any) {
          if (error instanceof AxiosError) {
            console.error(error.response?.data);
            if (error.response?.data?.description) {
              throw new Error(error.response.data.description);
            }
          }
          throw error;
        } finally {
          setIsProcessing(false);
        }
        return true;
      },
    ),
  );

  const saveEdits = async () => {
    if (!onSummaryPage && !rejectItems) {
      await new Promise((resolve) => setTimeout(resolve, 50));
      for (const product of return_.products) {
        const productToProcess = selectedItems.find(
          (productToProcess: ProductToProcess) =>
            productToProcess._id === product._id,
        );
        const productTotals = (returnTotals?.productTotals || []).find(
          (productTotals: ProductTotals) =>
            productTotals.product?._id === product._id,
        );
        if (productTotals) {
          const inputValue = inputs.get(product._id)!.inputs.refund.value;
          if (
            productToProcess &&
            productTotals.finalPrice.toFixed(2) != inputValue
          ) {
            product.merchant_adjustment = (
              +inputValue -
              productTotals.finalPrice +
              productTotals.merchantAdjustment
            ).toFixed(2);
          } else if (!productTotals.merchantAdjustment) {
            product.merchant_adjustment = undefined;
          }
        }
      }

      const orders: Record<string, any> = {};
      for (const product of return_.products) {
        if (!orders[product.order._id.toString()]) {
          orders[product.order._id.toString()] = product.order;
        }
      }
      const totalsCalculator = new ReturnTotalsCalculator({
        return_,
        order: return_.products[0].order as any,
        orders: Object.values(orders) as any,
        team: return_.team,
      });
      const nonZeroValueExchange =
        team?.settings.exchanges?.nonZeroValueExchange &&
        return_.type === "claim" &&
        return_.advancedExchangeItems.length > 0;
      const totals = totalsCalculator.getTotalsForProducts(
        selectedItems,
        false,
        nonZeroValueExchange,
      );
      setSummaryTotals(totals);

      setOnSummaryPage(true);
    }
  };

  const [onSummaryPage, setOnSummaryPage] = useState<boolean>(false);
  const [rejectItems, setRejectItems] = useState<boolean>(false);
  const { formatCurrency } = useContext(CurrencyContext);

  const handleSubmit: FormEventHandler = useHandler((e) => {
    e.preventDefault();
    doSubmit();
  });

  const input_ = useInput(
    groupInput({
      refundInstantExchangeRecoveryFee: input<boolean>(),
      notifyCustomer: input<boolean>(),
      refundOriginalShipping: input<boolean>(),
      note: input<string>(),
      waiveDraftFee: input<boolean>(),
      orderTags: input<readonly string[]>(),
    }),
    {
      refundInstantExchangeRecoveryFee: false,
      notifyCustomer: return_.team.settings.notifyCustomerDefault,
      refundOriginalShipping: false,
      note: "",
      waiveDraftFee: false,
      orderTags: [],
    },
  );
  const {
    notifyCustomer,
    note,
    waiveDraftFee,
    orderTags,
    refundInstantExchangeRecoveryFee,
    refundOriginalShipping,
  } = input_.inputs;

  const inputs = new Map<string, ProductItemInputType>();

  const [summaryTotals, setSummaryTotals] = useState<ReturnTotals>();

  const originalShippingDetails = useMemo(() => {
    const numItemsInOriginalOrder = getNumNonRedoItemsInOrders(orders);
    const canRefundOriginalShipping = !!team?.settings.refundOriginalShipping;
    const originalShippingPrice = summaryTotals?.totalOrderShipping;
    const refundOriginalShippingInitial =
      canRefundOriginalShipping &&
      !!originalShippingPrice &&
      Number(originalShippingPrice) > 0 &&
      return_.returnType.every((type) => type === CompensationMethod.REFUND) &&
      numItemsInOriginalOrder === 1;
    refundOriginalShipping.setValue(refundOriginalShippingInitial);

    return {
      numItemsInOriginalOrder,
      canRefundOriginalShipping,
      originalShippingPrice,
    };
  }, [summaryTotals]);

  const {
    numItemsInOriginalOrder,
    canRefundOriginalShipping,
    originalShippingPrice,
  } = originalShippingDetails ?? {
    numItemsInOriginalOrder: 0,
    canRefundOriginalShipping: false,
    originalShippingPrice: 0,
  };

  for (const product of return_.products) {
    const { price, priceBeforeRestockingFee } = getPriceDetailsForProduct(
      product,
      returnTotals,
    );

    const { groupInputProvider, initialValue } =
      getProductItemGroupInputWithDefault({
        item: product,
        return_,
        price,
        priceBeforeRestockingFee,
        formatCurrency,
        initialValueDefaults: {
          grade: team?.settings.returnItemDisposition?.gradeOptions[0],
          outcome: team?.settings.returnItemDisposition?.outcomeOptions[0],
        },
      });
    // FIXME React hooks cannot be used in loops!
    // eslint-disable-next-line react-hooks/rules-of-hooks
    const input_ = useInput(groupInputProvider, initialValue);
    inputs.set(product._id, input_);
  }

  const [selectedItems, setSelectedItems] = useState<
    (ProductToProcess & { line_item_id: string })[]
  >(
    return_.products
      .filter(
        (product) =>
          ![
            ReturnedProductStatus.COMPLETE,
            ReturnedProductStatus.REJECTED,
            ReturnedProductStatus.PENDING,
          ].includes(product.status),
      )
      .map((item) => {
        return {
          _id: item._id,
          restock: inputs.get(item._id)!.inputs.restock.value,
          refund: inputs.get(item._id)!.inputs.refund.value,
          reject: false,
          line_item_id: item.line_item_id,
        };
      }),
  );
  const id = useId();
  const hasInputErrors = !!Array.from(inputs.entries())
    .filter(([itemId, _]) =>
      selectedItems.some((selectedItem) => selectedItem._id === itemId),
    )
    .some(([_, value]) => value.allErrors?.length);
  const footer = submitLoad.value ? (
    <RedoButton
      hierarchy={RedoButtonHierarchy.SECONDARY}
      onClick={() => {
        onClose(!!submitLoad.value);
      }}
      size={RedoButtonSize.LARGE}
      text="Ok"
    />
  ) : (
    <div className={returnCss.selectItemsModalButtons}>
      {!onSummaryPage && !rejectItems && (
        <RedoButton
          disabled={hasInputErrors || !selectedItems.length}
          hierarchy={RedoButtonHierarchy.SECONDARY}
          onClick={() => {
            setRejectItems(true);
          }}
          size={RedoButtonSize.LARGE}
          text="Reject items"
          theme={RedoButtonTheme.DESTRUCTIVE}
        />
      )}

      <div
        className={
          rejectItems
            ? classNames(
                returnCss.selectItemsModalRightButtons,
                returnCss.centerButtons,
              )
            : returnCss.selectItemsModalRightButtons
        }
      >
        <RedoButton
          disabled={isProcessing}
          form={id}
          hierarchy={RedoButtonHierarchy.SECONDARY}
          onClick={async () => {
            if (onSummaryPage) {
              await new Promise((resolve) => setTimeout(resolve, 50));
              setSummaryTotals(undefined);
              setOnSummaryPage(false);
            } else if (rejectItems) {
              await new Promise((resolve) => setTimeout(resolve, 50));
              setRejectItems(false);
            } else {
              onClose(!!submitLoad.value);
            }
          }}
          size={RedoButtonSize.LARGE}
          text={onSummaryPage ? "Back" : rejectItems ? "No, go back" : "Cancel"}
          type={onSummaryPage || rejectItems ? "button" : "reset"}
        />
        <RedoButton
          disabled={hasInputErrors || !selectedItems.length}
          form={id}
          hierarchy={RedoButtonHierarchy.PRIMARY}
          onClick={saveEdits}
          pending={submitLoad.pending}
          size={RedoButtonSize.LARGE}
          text={
            onSummaryPage
              ? "Process items"
              : rejectItems
                ? "Yes, proceed"
                : "Continue"
          }
          theme={
            rejectItems ? RedoButtonTheme.DESTRUCTIVE : RedoButtonTheme.NORMAL
          }
          type={onSummaryPage || rejectItems ? "submit" : "button"}
        />
      </div>
    </div>
  );

  const summaryModal = () => {
    const _canRefundInstantExchangeRecoveryCharge =
      canRefundInstantExchangeRecoveryCharge({
        paymentIntents: return_.paymentIntents || [],
        provision: return_.provision,
      });
    const refundInstantExchangeRecoveryFeePaymentIntent =
      getRefundableInstantExchangeRecoveryPaymentIntent({
        paymentIntents: return_.paymentIntents || [],
        provision: return_.provision,
      });
    const refundInstantExchangeRecoveryFeeHelpText =
      refundInstantExchangeRecoveryFeePaymentIntent?.amount_USD
        ? " " +
          CURRENCY_FORMAT().format(
            refundInstantExchangeRecoveryFeePaymentIntent.amount_USD / 100,
          )
        : "";

    return (
      summaryTotals && (
        <>
          <table className={returnCss.table}>
            <tbody>
              <tr>
                <th className={returnCss.tableHeader}>Original price</th>
                <td className={returnCss.tableCell}>
                  {return_ &&
                    formatCurrency(
                      summaryTotals.totalRawReturnValue +
                        summaryTotals.totalDiscount,
                    )}
                </td>
              </tr>
              <tr>
                <th className={returnCss.tableHeader}>Discounts</th>
                <td className={returnCss.tableCell}>
                  {return_ && formatCurrency(summaryTotals.totalDiscount)}
                </td>
              </tr>
              <tr>
                <th className={returnCss.tableHeader}>Taxes</th>
                <td className={returnCss.tableCell}>
                  {return_ && formatCurrency(summaryTotals.totalTaxes)}
                </td>
              </tr>
              <tr>
                <th className={returnCss.tableHeader}>
                  {0 <= summaryTotals.totalAdjustmentValue ? (
                    <>Upsell</>
                  ) : (
                    <>Fees</>
                  )}
                </th>
                <td className={returnCss.tableCell}>
                  {return_ &&
                    formatCurrency(
                      Math.abs(summaryTotals.totalAdjustmentValue),
                    )}
                </td>
              </tr>
            </tbody>
          </table>
          <Divider />
          <table className={returnCss.table}>
            <tbody>
              <tr>
                <th className={returnCss.tableHeader}>
                  {returnTypeName(return_?.type, true)} value
                </th>
                <td className={returnCss.tableCell}>
                  {formatCurrency(summaryTotals.totalReturnValue)}
                </td>
              </tr>
              {summaryTotals?.newOrderValue > 0 && (
                <tr>
                  <th className={returnCss.tableHeader}>New order value</th>
                  <td className={returnCss.tableCell}>
                    {formatCurrency(summaryTotals.newOrderValue)}
                  </td>
                </tr>
              )}
              {return_?.labelDeductedFromCredit && !!return_.totals.fee && (
                <tr>
                  <th className={returnCss.tableHeader}>
                    Return shipping label
                  </th>
                  <td className={returnCss.tableCell}>
                    {return_ && formatCurrency(return_.totals.fee)}
                  </td>
                </tr>
              )}
              {summaryTotals.totalStoreCredit > 0 && (
                <tr>
                  <th className={returnCss.tableHeader}>Store credit</th>
                  <td className={returnCss.tableCell}>
                    {formatCurrency(summaryTotals.totalStoreCredit)}
                  </td>
                </tr>
              )}
              {summaryTotals.totalRefund > 0 && (
                <tr>
                  <th className={returnCss.tableHeader}>Refund</th>
                  <td className={returnCss.tableCell}>
                    {formatCurrency(summaryTotals.totalRefund)}
                  </td>
                </tr>
              )}
              {summaryTotals.charge > 0 && (
                <tr>
                  <th className={returnCss.tableHeader}>
                    {/* See https://github.com/redoapp/redo/pull/2990 for why this is only an estimate */}
                    Estimated draft order fee
                  </th>
                  <td className={returnCss.tableCell}>
                    {formatCurrency(summaryTotals.displayCharge)}
                  </td>
                </tr>
              )}
              {return_?.stripe && summaryTotals.recoveryFee != 0 && (
                <>
                  <tr>
                    <th className={returnCss.tableHeader}>Recovery charge</th>
                    <td className={returnCss.tableCell}>
                      {formatCurrency(summaryTotals.recoveryFee)}
                    </td>
                  </tr>
                  <tr className={returnCss.helpText}>
                    Amount to be recovered from customer if there was an issue
                    with the exchange.
                  </tr>
                </>
              )}
            </tbody>
          </table>
          <Flex flexDirection="column" gap="md" mx="xs" pt="lg">
            {!submitLoad.value && (
              <>
                {summaryTotals.charge > 0 &&
                  return_.draftOrderId == undefined &&
                  return_.exchangeOrder.length == 0 && (
                    <FormSwitch
                      input={waiveDraftFee}
                      label="Waive draft order fee"
                    />
                  )}
                {_canRefundInstantExchangeRecoveryCharge && (
                  <Flex>
                    <FormSwitch
                      input={refundInstantExchangeRecoveryFee}
                      label="Refund instant exchange order"
                    />
                    <Tooltip
                      placement="top"
                      subtitle={`The customer originally selected an instant exchange, but failed to return the item(s) and was charged${refundInstantExchangeRecoveryFeeHelpText}. After being charged, the customer returned the item(s) and is eligible for a refund.`}
                      title="Refund instant exchange order"
                    >
                      <div className={processModalCss.helpCircleIconContainer}>
                        <HelpCircle
                          className={processModalCss.helpCircleIcon}
                        />
                      </div>
                    </Tooltip>
                  </Flex>
                )}
                <FormSwitch
                  input={notifyCustomer}
                  label="Notify customer"
                  orientation={LabelOrientation.VERTICAL}
                  position={LabelPosition.RIGHT}
                  size={SwitchSize.SMALL}
                >
                  Send a notification to the customer.
                </FormSwitch>
                {canRefundOriginalShipping && (
                  <FormSwitch
                    input={refundOriginalShipping}
                    label="Refund original shipping"
                    orientation={LabelOrientation.VERTICAL}
                    position={LabelPosition.RIGHT}
                    size={SwitchSize.SMALL}
                  >
                    <Text>
                      Refund the original shipping cost (
                      {formatCurrency(originalShippingPrice ?? 0)}).
                    </Text>
                    {numItemsInOriginalOrder > 1 && (
                      <Text>
                        Total items in original order: {numItemsInOriginalOrder}
                      </Text>
                    )}
                  </FormSwitch>
                )}
                {notifyCustomer.value && (
                  <TextInput
                    lines={InputLines.MULTI}
                    maxLength={200}
                    onChange={note.setValue}
                    placeholder="Note to customer"
                    value={note.value}
                  />
                )}
                <FormChipInput
                  description="Add tags to the original order"
                  input={orderTags}
                  label="Order tags"
                  trimWhitespace
                />
              </>
            )}
          </Flex>
        </>
      )
    );
  };

  return (
    <Modal
      footer={footer}
      hideCloseButton={isProcessing}
      onClose={() => {
        onClose(submitLoad.value as boolean);
      }}
      open={open}
      paddingAmount={PaddingAmount.MEDIUM}
      showHeaderBorder={false}
      size={ModalSize.SMALL}
      subtitle={
        submitLoad.value || onSummaryPage || rejectItems
          ? ""
          : "Choose which items you want to process or reject."
      }
      title={rejectItems ? "Reject items" : "Process items"}
    >
      <Flex className={processModalCss.modalContent} dir="column">
        <form
          id={id}
          onReset={() => {
            onClose(!!submitLoad.value);
          }}
          onSubmit={handleSubmit}
        >
          {submitLoad.value ? (
            <p>
              The return items have been{" "}
              {rejectItems ? "rejected" : "processed"}.
            </p>
          ) : rejectItems ? (
            <p>Are you sure you want to reject the selected items?</p>
          ) : onSummaryPage ? (
            summaryModal()
          ) : (
            <SelectItemsModal
              inputs={inputs}
              return_={return_}
              selectedItems={selectedItems}
              setSelectedItems={setSelectedItems}
            />
          )}
        </form>
      </Flex>
    </Modal>
  );
});

const SelectItemsModal = memo(function SelectItemsModal({
  return_,
  selectedItems,
  setSelectedItems,
  inputs,
}: {
  return_: Return;
  selectedItems: (ProductToProcess & { line_item_id: string })[];
  setSelectedItems: (
    selectedItems: (ProductToProcess & { line_item_id: string })[],
  ) => void;
  inputs: Map<string, any>;
}) {
  const productsByGroupName = useMemo(() => {
    return getProductsByShippingGroupTitle(return_);
  }, [return_]);

  // update this to handle 1 product multiple shipments
  if (return_.shipmentGroups && return_.shipmentGroups.length > 1) {
    return (
      <>
        {productsByGroupName.map((productsWithName, groupNumber) => {
          const products = productsWithName.products.filter(
            (product) =>
              !["rejected", "complete", "pending"].includes(product.status),
          );
          return (
            <div key={groupNumber}>
              <h3>{productsWithName.groupName}</h3>
              {products.map((item) => (
                <Flex dir="column" gap="none" key={item._id}>
                  <ProductItem
                    input_={inputs.get(item._id)}
                    item={item}
                    return_={return_}
                    selectedItems={selectedItems}
                    setSelectedItems={setSelectedItems}
                  />
                  <div className={processModalCss.divider}>
                    <Divider />
                  </div>
                </Flex>
              ))}
            </div>
          );
        })}
      </>
    );
  }
  return (
    <>
      {return_.products
        .filter(
          (product) =>
            !["rejected", "complete", "pending"].includes(product.status),
        )
        .map((item) => (
          <Flex dir="column" gap="none" key={item._id}>
            <ProductItem
              input_={inputs.get(item._id)}
              item={item}
              return_={return_}
              selectedItems={selectedItems}
              setSelectedItems={setSelectedItems}
            />
            <div className={processModalCss.divider}>
              <Divider />
            </div>
          </Flex>
        ))}
    </>
  );
});

const ProductItem = memo(function ProductItem({
  item,
  selectedItems,
  setSelectedItems,
  input_,
  return_,
}: {
  item: Product;
  selectedItems: (ProductToProcess & { line_item_id: string })[];
  setSelectedItems: (
    selectedItems: (ProductToProcess & { line_item_id: string })[],
  ) => void;
  input_: ProductItemInputType;
  return_: Return;
}) {
  const team = useContext(TeamContext);
  const user = useContext(UserContext);

  const [isDetailVisible, setIsDetailVisible] = useState(false);

  const handleCheckboxChange = useHandler((selected: boolean) => {
    const selectedItem = selectedItems.find(
      (product) => product._id === item._id,
    );
    if (selectedItem) {
      selectedItems.splice(selectedItems.indexOf(selectedItem), 1);
    } else {
      selectedItems.push({
        _id: item._id,
        restock: input_.inputs.restock.value,
        refund: input_.inputs.refund.value,
        reject: false,
        line_item_id: item.line_item_id,
      });
    }
    setSelectedItems([...selectedItems]);
  });

  const isItemSelected = useMemo(
    () =>
      selectedItems.find((product) => product._id === item._id) !== undefined,
    [selectedItems, item._id],
  );

  const hasInputErrors = useMemo(() => {
    return !!input_.allErrors?.length;
  }, [input_]);

  return (
    <Flex gap="3xl">
      <Flex className={processModalCss.checkboxContainer}>
        <Checkbox onChange={handleCheckboxChange} value={isItemSelected} />
      </Flex>
      <Flex dir="column" flex={1} gap="lg">
        <ProductItemSummaryRow
          item={item}
          refundAmount={input_.inputs.refund.value}
          restockSwitch={{
            itemRestockState: item.hasBeenRestockedOnPlatform
              ? ItemRestockState.ALREADY_RESTOCKED
              : ItemRestockState.ABLE_TO_RESTOCK,
            hideSwitch: !isItemSelected,
            value: input_.inputs.restock.value,
            setValue: input_.inputs.restock.setValue,
          }}
          return_={return_}
        />
        {isItemSelected && (
          <Flex dir="column">
            <Flex align="center" gap="xs">
              <RedoButton
                hierarchy={RedoButtonHierarchy.LINK_GRAY}
                IconLeading={isDetailVisible ? ChevronDown : ChevronRight}
                onClick={() => setIsDetailVisible(!isDetailVisible)}
                text="Details"
              />
              {hasInputErrors && (
                <AlertCircle className={processModalCss.alertCircle} />
              )}
            </Flex>
            {isDetailVisible && (
              <Flex dir="column">
                {EDIT_STRATEGY[item.strategy] &&
                  hasEditReturnPermissions(team, user) && (
                    <EditItemRefundValue input_={input_} item={item} />
                  )}
                {team?.settings.returnItemDisposition?.enabled && (
                  <EditItemDisposition
                    grade={{
                      options: team.settings.returnItemDisposition.gradeOptions,
                      selectedOption: input_.inputs.grade.value,
                      setSelectedOption: input_.inputs.grade.setValue,
                    }}
                    notes={{
                      value: input_.inputs.notes.value,
                      setValue: input_.inputs.notes.setValue,
                    }}
                    outcome={{
                      options:
                        team.settings.returnItemDisposition.outcomeOptions,
                      selectedOption: input_.inputs.outcome.value,
                      setSelectedOption: input_.inputs.outcome.setValue,
                    }}
                  />
                )}
              </Flex>
            )}
          </Flex>
        )}
      </Flex>
    </Flex>
  );
});
