// extracted by mini-css-extract-plugin
export var acitonButtons = "_7r-0";
export var block = "_7r-1";
export var blockContent = "_7r-7";
export var blockDescription = "_7r-8";
export var blockIcon = "_7r-9";
export var blockTitle = "_7r-6";
export var border = "_7r-a";
export var center = "_7r-k";
export var edge = "_7r-b";
export var edgeLabel = "_7r-c";
export var edgePath = "_7r-d";
export var error = "_7r-e";
export var flowchart = "_7r-f";
export var flowchartContent = "_7r-g";
export var highlighted = "_7r-3";
export var hover = "_7r-4";
export var icon = "_7r-h";
export var left = "_7r-l";
export var navButton = "_7r-i";
export var navButtons = "_7r-j";
export var right = "_7r-m";
export var selected = "_7r-2";
export var small = "_7r-5";