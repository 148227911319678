import { FulfillmentOrderData } from "@redotech/redo-model/fulfillments/fulfillment-group";
import { SortDirection } from "@redotech/redo-model/tables/table";
import { RedoTableTextCell } from "@redotech/redo-web/arbiter-components/tables/redo-table-cells";
import { ColumnAlignment } from "@redotech/redo-web/table";
import { Column2 } from "@redotech/redo-web/table2";
import { getOrderTotal } from "../../utils/outbound-labels-utils";

export const OrderTotalColumn: Column2<FulfillmentOrderData> = {
  key: "total",
  title: "Total",
  alignment: ColumnAlignment.LEFT,
  renderNormalCell: ({ row }) => (
    <RedoTableTextCell text={`$${Number(getOrderTotal(row)).toFixed(2)}`} />
  ),
  width: 100,
  sort: SortDirection.DESC,
};
