import { PaymentSchedule } from "@redotech/redo-model/balance/balances";
import { z, null as zNull } from "zod";

export const getOutboundBalance = {
  input: zNull(),
  output: z.object({
    balance: z.string(),
    paymentSchedule: z.nativeEnum(PaymentSchedule),
  }),
};
