export const DESKTOP_WIDTH_PX = 600;
export const MOBILE_WIDTH_PX = 375;

/**
 * Convert a width in pixels to a percentage of the desktop width.
 * Makes padding responsive, e.g., so images don't get squashed on mobile.
 *
 * @param pixels The width in pixels.
 * @returns The width as a percentage of the desktop width.
 * @example
 * widthPixelsToPercentage(300); // 50
 */
export function widthPixelsToPercentage(pixels: number) {
  return (pixels / DESKTOP_WIDTH_PX) * 100;
}
