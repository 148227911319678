import * as skeletonCss from "./skeleton.module.css";

export function SkeletonBlock() {
  return <div className={skeletonCss.skeleton} />;
}

export function SkeletonText({
  length,
}: {
  length: number;
  animated?: boolean;
}) {
  return <span className={skeletonCss.skeletonText}>{"_".repeat(length)}</span>;
}
