// extracted by mini-css-extract-plugin
export var grid = "_a-0";
export var span1 = "_a-2";
export var span10 = "_a-b";
export var span10L = "_a-z";
export var span10M = "_a-n";
export var span10Xl = "_a-1b";
export var span11 = "_a-c";
export var span11L = "_a-10";
export var span11M = "_a-o";
export var span11Xl = "_a-1c";
export var span12 = "_a-d";
export var span12L = "_a-11";
export var span12M = "_a-p";
export var span12Xl = "_a-1d";
export var span1L = "_a-q";
export var span1M = "_a-e";
export var span1Xl = "_a-12";
export var span2 = "_a-3";
export var span2L = "_a-r";
export var span2M = "_a-f";
export var span2Xl = "_a-13";
export var span3 = "_a-4";
export var span3L = "_a-s";
export var span3M = "_a-g";
export var span3Xl = "_a-14";
export var span4 = "_a-5";
export var span4L = "_a-t";
export var span4M = "_a-h";
export var span4Xl = "_a-15";
export var span5 = "_a-6";
export var span5L = "_a-u";
export var span5M = "_a-i";
export var span5Xl = "_a-16";
export var span6 = "_a-7";
export var span6L = "_a-v";
export var span6M = "_a-j";
export var span6Xl = "_a-17";
export var span7 = "_a-8";
export var span7L = "_a-w";
export var span7M = "_a-k";
export var span7Xl = "_a-18";
export var span8 = "_a-9";
export var span8L = "_a-x";
export var span8M = "_a-l";
export var span8Xl = "_a-19";
export var span9 = "_a-a";
export var span9L = "_a-y";
export var span9M = "_a-m";
export var span9Xl = "_a-1a";
export var stretch = "_a-1";