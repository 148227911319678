import { z } from "zod";
import { zExt } from "../common/zod-util";

export const RedoScanFormSchema = z.object({
  id: z.string(),
  externalCreatedAt: z.date(),
  externalUpdatedAt: z.date(),
  trackingCodes: z.array(z.string()),
  address: z.object({
    id: z.string(),
    name: z.string().nullish(),
    street1: z.string().nullish(),
    street2: z.string().nullish(),
    city: z.string().nullish(),
    state: z.string().nullish(),
    zip: z.string().nullish(),
    country: z.string().nullish(),
    phone: z.string().nullish(),
    email: z.string().nullish(),
    carrierFacility: z.string().nullish(),
    residential: z.boolean().nullish(),
    federalTaxId: z.string().nullish(),
    stateTaxId: z.string().nullish(),
  }),
  status: z.string(),
  message: z.string().nullish(),
  formUrl: z.string(),
  formFileType: z.string().nullish(),
  batchId: z.string().nullish(),
  confirmation: z.string().nullish(),
  shipmentIds: z.array(z.string()),
});

export type RedoScanForm = z.infer<typeof RedoScanFormSchema>;

export const EndOfDayManifestSchema = z.object({
  _id: zExt.objectId(),
  team: zExt.objectId(),
  carrier: z.string(),
  fulfillmentGroupIds: z.array(zExt.objectId()),
  scanForm: RedoScanFormSchema,
});

export type EndOfDayManifest = z.infer<typeof EndOfDayManifestSchema>;

export const CreateEndOfDayManifestSchema = EndOfDayManifestSchema.omit({
  _id: true,
});

export type CreateEndOfDayManifest = z.infer<
  typeof CreateEndOfDayManifestSchema
>;
