import { CustomerEventType } from "../../customer-event/customer-event-definition";
import { TimeframeCondition } from "./segment-timeframe";
import { WhereCondition } from "./segment-where-condition";

export interface Segment {
  name: string;
  lastUpdated: Date;
  mode: ConjunctionMode;
  conditionBlocks: SegmentConditionBlock[];
  initialStatistics: SegmentSubscriberStatistics;
}

export interface SegmentSubscriberStatistics {
  eligibleCustomers: number | undefined;
  eligibleEmailSubscribers: number | undefined;
  eligibleSmsSubscribers: number | undefined;
  // subscriberAOV: number;
}

export enum ConjunctionMode {
  AND = "AND",
  OR = "OR",
}

export enum SegmentConditionCategory {
  ACCOUNT = "account",
  MARKETING = "marketing",
  SHOPPING = "shopping",
}

export enum CustomerCharacteristicType {
  SUBSCRIBED_TO_EMAIL = "subscribed-to-email",
  SUBSCRIBED_TO_SMS = "subscribed-to-sms",
  CREATED_TIME = "created-time",
  CUSTOMER_TAGS = "customer-tags",
  STATIC_SEGMENT_MEMBERSHIP = "static-segment-membership",
  // CITY = "city",
  // STATE_REGION = "state-region",
  // COUNTRY = "country",
  // ZIP_POSTAL_CODE = "zip-postal-code",
}

export enum CustomerDataSource {
  REDO = "Redo",
  SHOPIFY = "Shopify",
}

export enum CustomerActivityType {
  RECEIVED_EMAIL = "received-email",
  OPENED_EMAIL = "opened-email",
  CLICKED_EMAIL = "clicked-email",
  RECEIVED_TEXT = "received-text",
  CLICKED_TEXT = "clicked-text",
  VIEWED_PRODUCT = "viewed-product",
  ADDED_PRODUCT_TO_CART = "added-product-to-cart",
  ORDER_PLACED = "order-placed",
  CHECKOUT_STARTED = "checkout-started",
  ACTIVE_ON_SITE = "active-on-site",
  COLLECTION_VIEWED = "collection-viewed",
}

export const customerActivityTypeToCustomerEventType: Record<
  CustomerActivityType,
  CustomerEventType
> = {
  [CustomerActivityType.RECEIVED_EMAIL]: CustomerEventType.OUTREACH_DELIVERY,
  [CustomerActivityType.OPENED_EMAIL]: CustomerEventType.OUTREACH_OPEN,
  [CustomerActivityType.CLICKED_EMAIL]: CustomerEventType.OUTREACH_CLICK,
  [CustomerActivityType.RECEIVED_TEXT]: CustomerEventType.OUTREACH_DELIVERY,
  [CustomerActivityType.CLICKED_TEXT]: CustomerEventType.OUTREACH_CLICK,
  [CustomerActivityType.VIEWED_PRODUCT]: CustomerEventType.PRODUCT_VIEWED,
  [CustomerActivityType.ADDED_PRODUCT_TO_CART]:
    CustomerEventType.PRODUCT_ADDED_TO_CART,
  [CustomerActivityType.ORDER_PLACED]: CustomerEventType.ORDER_CREATED,
  [CustomerActivityType.CHECKOUT_STARTED]: CustomerEventType.CHECKOUT_STARTED,
  [CustomerActivityType.ACTIVE_ON_SITE]: CustomerEventType.STORE_PAGE_VIEWED,
  [CustomerActivityType.COLLECTION_VIEWED]: CustomerEventType.COLLECTION_VIEWED,
};

export interface SegmentConditionBlock {
  mode: ConjunctionMode;
  conditions: SegmentCondition[];
}

export enum SegmentConditionType {
  CUSTOMER_ATTRIBUTE = "customer_attribute",
  CUSTOMER_ACTIVITY = "customer_activity",
  // CUSTOMER_MEMBERSHIP = "customer_membership",
}

export type SegmentCondition =
  | CustomerAttributeCondition
  | CustomerActivityCondition;

export interface CustomerAttributeCondition {
  type: SegmentConditionType.CUSTOMER_ATTRIBUTE;
  whereCondition: WhereCondition<CustomerCharacteristicType>;
}

export enum ActivityCountType {
  AT_LEAST_ONCE = "at_least_once",
  ZERO_TIMES = "zero_times",
  N_TIMES = "n_times",
  NOT_N_TIMES = "not_n_times",
  AT_LEAST_N = "at_least_n",
  GREATER_THAN_N = "greater_than_n",
  LESS_THAN_N = "less_than_n",
  AT_MOST_N = "at_most_n",
}

export function isConstantActivityCountType(
  type: ActivityCountType,
): type is ConstantActivityCount["type"] {
  return (
    type === ActivityCountType.AT_LEAST_ONCE ||
    type === ActivityCountType.ZERO_TIMES
  );
}

export function isConstantActivityCount(activityCount: {
  type: ActivityCountType;
}): activityCount is ConstantActivityCount {
  return isConstantActivityCountType(activityCount.type);
}

export type ConstantActivityCount = {
  type: ActivityCountType.AT_LEAST_ONCE | ActivityCountType.ZERO_TIMES;
};

export type NTimesActivityCount = {
  type: Exclude<
    ActivityCountType,
    ActivityCountType.AT_LEAST_ONCE | ActivityCountType.ZERO_TIMES
  >;
  n: number;
};

export type ActivityCount = ConstantActivityCount | NTimesActivityCount;

export interface CustomerActivityCondition {
  type: SegmentConditionType.CUSTOMER_ACTIVITY;
  activityType: CustomerActivityType;
  count: ActivityCount;
  timeframe: TimeframeCondition;
  whereConditions: WhereCondition<CustomerActivityType>[];
}
