import { zExt } from "@redotech/redo-model/common/zod-util";
import {
  baseDiscountSchema,
  baseSchema,
  discountSettingsSchema,
} from "@redotech/redo-model/discount/discount-db-parser";
import { z } from "zod";

export const discountSchema = baseDiscountSchema;

export const createDiscount = {
  input: z.object({
    discountEntry: z.object({ ...baseSchema }),
    discountSettings: discountSettingsSchema,
  }),
  output: z.object({ _id: zExt.objectId() }),
};
