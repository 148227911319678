// extracted by mini-css-extract-plugin
export var alignBaseline = "_3-8";
export var alignCenter = "_3-4";
export var alignFlexEnd = "_3-6";
export var alignFlexStart = "_3-5";
export var alignSelfBaseline = "_3-d";
export var alignSelfCenter = "_3-9";
export var alignSelfFlexEnd = "_3-b";
export var alignSelfFlexStart = "_3-a";
export var alignSelfStretch = "_3-c";
export var alignStretch = "_3-7";
export var column = "_3-3";
export var flex = "_3-0";
export var justifyCenter = "_3-e";
export var justifyFlexEnd = "_3-g";
export var justifyFlexStart = "_3-f";
export var justifySpaceAround = "_3-i";
export var justifySpaceBetween = "_3-h";
export var justifySpaceEvenly = "_3-j";
export var reverse = "_3-2";
export var row = "_3-1";
export var wrapNowrap = "_3-l";
export var wrapWrap = "_3-k";
export var wrapWrapReverse = "_3-m";