import { FulfillmentOrderData } from "@redotech/redo-model/fulfillments/fulfillment-group";
import { RedoTableTextCell } from "@redotech/redo-web/arbiter-components/tables/redo-table-cells";
import { ColumnAlignment } from "@redotech/redo-web/table";
import { Column2 } from "@redotech/redo-web/table2";

export const ShippingPaidColumn: Column2<FulfillmentOrderData> = {
  alignment: ColumnAlignment.LEFT,
  key: "shippingPaid",
  title: "Shipping paid",
  renderNormalCell: ({ row }: { row: FulfillmentOrderData }) => (
    <RedoTableTextCell text={`$${row.totalShippingCost.amount}`} />
  ),
  onClick: (e: React.MouseEvent<HTMLDivElement>) => {
    e.stopPropagation();
  },
  width: 120,
  hidden: false,
};
