import MuiModal from "@mui/material/Modal";
import { useRequiredContext } from "@redotech/react-util/context";
import { useTriggerLoad } from "@redotech/react-util/load";
import { Trackable } from "@redotech/redo-model/trackable";
import {
  RedoButtonCloseX,
  ButtonSize as XButtonSize,
} from "@redotech/redo-web/arbiter-components/redo-button-close-x";
import VerticalDots from "@redotech/redo-web/arbiter-icon/dots-vertical_filled.svg";
import MailSvg from "@redotech/redo-web/arbiter-icon/mail-01.svg";
import { Button, ButtonSize, ButtonTheme } from "@redotech/redo-web/button";
import { ButtonDropdown } from "@redotech/redo-web/button-dropdown";
import { Card } from "@redotech/redo-web/card";
import { getDateTimeString } from "@redotech/redo-web/date-utils";
import { Flex } from "@redotech/redo-web/flex";
import { Icon } from "@redotech/redo-web/icon";
import { Text } from "@redotech/redo-web/text";
import { memo, useMemo, useState } from "react";
import { useNavigate } from "react-router-dom";
import { RedoMerchantRpcClientContext } from "../app/redo-merchant-rpc-client-provider";
import * as timelineCss from "./timeline.module.css";

// To add more event types create a discriminated union with the first object but leave the time field &'ed with the entire union
type TimelineEventProps = {
  type: "EmailSent";
  templateId: string;
  templateName?: string;
  previewURL?: string;
  orderId: string;
} & { time: Date };

export const ActivityTimeline = memo(function ActivityTimeline({
  trackable,
  emailTemplateIdToNameMap,
}: {
  trackable: Trackable;
  emailTemplateIdToNameMap?: Map<string, string>;
}) {
  const timelineEvents: TimelineEventProps[] = useMemo(() => {
    const timelineEventsBuilder =
      trackable.trackingEmailsSent?.map<TimelineEventProps>((emailInfo) => ({
        type: "EmailSent",
        time: new Date(emailInfo.sentAt),
        templateId: emailInfo.emailId,
        templateName: emailTemplateIdToNameMap?.get(emailInfo.emailId),
        previewURL: emailInfo.s3URL,
        orderId: trackable.id,
      })) ?? [];
    timelineEventsBuilder.sort((a, b) => a.time.getTime() - b.time.getTime());
    return timelineEventsBuilder;
  }, [trackable]);
  return (
    <Flex dir="column" gap="xl" grow={1}>
      <Text fontSize="lg">Activity</Text>
      <Card gap="none" p="xl">
        <Flex grow={1}>
          <Flex dir="column" gap="xs" grow={1}>
            {timelineEvents.map((props, index) => (
              // index should be a fine key here because the timeline is sorted and should only ever grow
              <TimelineEvent {...props} key={index} />
            ))}
            {timelineEvents.length === 0 && (
              <Flex dir="row" gap="xs">
                <Icon arbiterIconSvg={MailSvg} color="ghost" size="medium" />
                <Flex dir="column" gap="xs">
                  <Text fontSize="md" fontWeight="semibold">
                    No emails have been sent yet
                  </Text>
                  <Text fontSize="sm">--</Text>
                </Flex>
              </Flex>
            )}
          </Flex>
        </Flex>
      </Card>
    </Flex>
  );
});

const TimelineEvent = memo(function TimelineEvent(props: TimelineEventProps) {
  return (
    <Flex grow={1}>
      <Flex dir="row" grow={1}>
        {props.type === "EmailSent" && (
          <>
            <Icon arbiterIconSvg={MailSvg} color="ghost" size="medium" />
            <div>
              <Text fontSize="md" fontWeight="semibold">
                Email Sent
                {props.templateName ? `: ${props.templateName}` : ""}
              </Text>
              <Text fontSize="sm">{getDateTimeString(props.time)}</Text>
            </div>
            <EmailEventActionDropdown {...props} />
          </>
        )}
      </Flex>
    </Flex>
  );
});

function EmailEventActionDropdown({
  previewURL,
  templateId,
  orderId,
}: {
  previewURL?: string;
  templateId: string;
  orderId: string;
}) {
  const [openEmailPreview, setOpenEmailPreview] = useState(false);
  const navigate = useNavigate();
  const [dropdownOpen, setDropdownOpen] = useState(false);

  return (
    <div className={timelineCss.eventActionDots}>
      <ButtonDropdown
        dropdown={
          <>
            {!!previewURL && (
              <>
                <Button
                  onClick={() => {
                    setDropdownOpen(false);
                    setOpenEmailPreview(true);
                  }}
                  theme={ButtonTheme.GHOST}
                >
                  Show Preview
                </Button>
                <ResendEmailButton
                  emailURL={previewURL}
                  orderId={orderId}
                  templateId={templateId}
                />
              </>
            )}
            <Button
              onClick={() => {
                setDropdownOpen(false);
                navigate(`../../settings/email-templates/${templateId}`);
              }}
              theme={ButtonTheme.GHOST}
            >
              Go to Template
            </Button>
          </>
        }
        multipleClicks
        open={dropdownOpen}
        restrictDropdownSizeToParent={false}
        setOpen={setDropdownOpen}
        size={ButtonSize.NANO}
        theme={ButtonTheme.GHOST}
      >
        <VerticalDots />
      </ButtonDropdown>
      {previewURL && (
        <EmailPreviewModal
          onClose={() => {
            setDropdownOpen(false);
            setOpenEmailPreview(false);
          }}
          open={openEmailPreview}
          previewURL={previewURL}
        />
      )}
    </div>
  );
}

function ResendEmailButton({
  emailURL,
  orderId,
  templateId,
}: {
  emailURL: string;
  orderId: string;
  templateId: string;
}) {
  const rpcClient = useRequiredContext(RedoMerchantRpcClientContext);
  const [resendingEmailAction, triggerResendEmailAction] = useTriggerLoad(
    async () => {
      return await rpcClient.resendEmail({ emailURL, orderId, templateId });
    },
  );

  return (
    <Button
      onClick={() => triggerResendEmailAction()}
      pending={resendingEmailAction.pending}
      theme={ButtonTheme.GHOST}
    >
      Resend Email
      {resendingEmailAction.error && <Text color="red">Failed to Send</Text>}
      {resendingEmailAction.value === "success" && (
        <Text color="green">Sent</Text>
      )}
    </Button>
  );
}

function EmailPreviewModal({
  open,
  onClose,
  previewURL,
}: {
  open: boolean;
  onClose: () => void;
  previewURL: string;
}) {
  return (
    <MuiModal onClose={onClose} open={open}>
      <div className={timelineCss.modal}>
        <div className={timelineCss.floatUpperRight}>
          <RedoButtonCloseX
            onclick={onClose}
            onDarkBackground
            size={XButtonSize.LARGE}
          />
        </div>
        <iframe className={timelineCss.emailPreview} src={previewURL} />
      </div>
    </MuiModal>
  );
}
