// extracted by mini-css-extract-plugin
export var checkbox = "_4m-0";
export var circle = "_4m-3";
export var horizontalContainer = "_4m-7";
export var sm = "_4m-5";
export var switch0 = "_4m-2";
export var switchDisabled = "_4m-8";
export var switchEnabled = "_4m-1";
export var textSwitchContainer = "_4m-9";
export var textSwitchOption = "_4m-a";
export var textSwitchOptionSelected = "_4m-b";
export var verticalContainer = "_4m-6";
export var xs = "_4m-4";