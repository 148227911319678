import type { InferRpcDefinition } from "@redotech/rpc/definition";
import { getReviews } from "./schema/get-reviews";
import { getReviewsAverageByProject } from "./schema/get-reviews-average-by-project";
import { getReviewsByProductId } from "./schema/get-reviews-by-product-id";
import { getReviewsByTeamId } from "./schema/get-reviews-by-team-id";
import { createReviewForm } from "./schema/review-form/create-review-form";
import { deleteReviewForm } from "./schema/review-form/delete-review-form";
import { getActiveReviewFormForTeam } from "./schema/review-form/get-active-review-form-for-team";
import { getReviewForm } from "./schema/review-form/get-review-form";
import { getReviewFormsForTeam } from "./schema/review-form/get-review-forms-for-team";
import { updateReviewForm } from "./schema/review-form/update-review-form";
import { updateReview } from "./schema/update-review";

export const rpcDefinition = {
  createReviewForm,
  deleteReviewForm,
  updateReviewForm,
  getReviewForm,
  getReviewFormsForTeam,
  getActiveReviewFormForTeam,
  getReviewsByProductId,
  getReviewsByTeamId,
  getReviews,
  updateReview,
  getReviewsAverageByProject,
};

export type RedoReviewsRpcDefinition = InferRpcDefinition<typeof rpcDefinition>;
