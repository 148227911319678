// extracted by mini-css-extract-plugin
export var container = "_c-1";
export var infoText = "_c-5";
export var insightDetails = "_c-6";
export var insightSummary = "_c-3";
export var metric = "_c-9";
export var metricChange = "_c-a";
export var metricsContainer = "_c-8";
export var missingInsights = "_c-0";
export var selected = "_c-4";
export var spinnerContainer = "_c-2";
export var title = "_c-7";